import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { StandardChat, chatGroups } from "../../../models/data/application.data";

@Pipe({ name: 'standardChatsListPipe' })
export class StandardChatsListPipe implements PipeTransform {
  constructor() { }

  public transform(standardChatsList: StandardChat[], chatGroup: chatGroups) {
    if (Functions.IsNullOrUndefined(standardChatsList) || standardChatsList.length <= 0)
      return [];
    else {
      return standardChatsList.filter((aStandardChat: StandardChat) => aStandardChat.group == chatGroup)
        .sort(function (a: StandardChat, b: StandardChat) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });
    }
  }
}
