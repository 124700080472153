import { LocationArea, LocationRoot, LocationChat, LocationInsideChat, StandardChat, DBVersionType } from './../../../../models/data/application.data';
import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-edit-location-area',
  templateUrl: 'cPanel-edit-location-area.component.html',
  styleUrls: ['cPanel-edit-location-area.component.less'],
})
export class CPanelEditLocationAreaComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelEditLocation";
  //#region - modal variables
  public isLocationModalVisible: boolean = false;
  public modalFrom: string = "";
  public modalRowId: string = "";
  public modalText: string = "";
  public config: any = {
    height: 550,
    menubar: false,
    theme: 'modern',
    plugins: [
      'advlist autolink link image imagetools lists charmap hr',
      'wordcount visualblocks visualchars code',
      'table contextmenu textcolor paste colorpicker',
    ],
    toolbar1:
      'bold italic underline strikethrough | undo redo | blockquote numlist bullist | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect | forecolor backcolor',
    toolbar2:
      'cut copy paste | outdent indent | link unlink image table | hr removeformat | subscript superscript | charmap | code',
    image_advtab: true,
    imagetools_toolbar:
      'rotateleft rotateright | flipv fliph | editimage imageoptions',
  };
  //#endregion - modal variables

  //#region - chat connection variables
  public syncDone: boolean = false;
  private chatCollection: AngularFirestoreCollection<StandardChat>;
  private chatCollectionObservable: Observable<StandardChat[]>;
  private chatSubscription: Subscription;
  private standardChat: StandardChat[] = [];
  //#endregion - chat connection variables

  //#region - rules connection variables
  private locationCollection: AngularFirestoreCollection<LocationRoot>;
  private locationCollectionObservable: Observable<LocationRoot[]>;
  private locationSubscription: Subscription;
  public dataSource: LocalDataSource;
  //#endregion - rules connection variables

  //#region - top level
  public locationRoot: LocationRoot;
  public locationsArea: LocationArea[] = [];
  public selectedLocationAreaNumericID: number;
  public selectedLocationArea: LocationArea;
  public settingsLocationArea = {
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    actions: {
      add: false,
      delete: false,
      custom: [
        {
          name: 'description',
          title: '<i class="material-icons icon-del access-pg">description</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Location'
      },
      sequence: {
        title: 'Sequenza'
      }
    }
  };
  //#endregion - top level

  //#region - inner level
  public selectedLocationChatID: string;
  public selectedLocationChat: LocationChat;
  public dataSourceLocationChat: LocalDataSource;
  public settingsLocationChat = {
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    actions: {
      add: false,
      delete: false,
      custom: [
        {
          name: 'description',
          title: '<i class="material-icons icon-del access-pg">description</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Location'
      },
      sequence: {
        title: 'Sequenza'
      }
    }
  };

  //#endregion - inner level

  //#region - depper level
  public selectedLocationInsideChatID: string;
  public selectedLocationInsideChat: LocationInsideChat;
  public dataSourceLocationInsideChat: LocalDataSource;
  public settingsLocationInsideChat = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    actions: {
      custom: [
        {
          name: 'description',
          title: '<i class="material-icons icon-del access-pg">description</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Paragrafo'
      },
      sequence: {
        title: 'Sequenza'
      }
    }
  };
  //#endregion - depper level

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {

  }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();
    this.dataSourceLocationChat = new LocalDataSource();
    this.dataSourceLocationInsideChat = new LocalDataSource();

    // Firestore chats
    this.chatCollection = this.afs.collection<StandardChat>('standardChat');
    this.chatCollectionObservable = this.chatCollection.valueChanges();
    this.chatSubscription = this.chatCollectionObservable.subscribe((data) => {
      // this.chatSubscription = this.firestore.query('standardChat').on().subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all standard chats cpanel", self.moduleName, "standardChat", (data as any[]).length);
      }

      self.standardChat = data;
    });

    // Firestore
    this.locationCollection = this.afs.collection<LocationRoot>('locations');
    this.locationCollectionObservable = this.locationCollection.valueChanges();
    this.locationSubscription = this.locationCollectionObservable.subscribe((data) => {
      // this.locationSubscription = this.firestore.query('locations').on().subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all locations chats cpanel", self.moduleName, "locations", (data as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.length <= 0)
        return;

      self.locationRoot = data[0];
      self.locationsArea = self.locationRoot.locationsAreas;

      //automatic sync with chat data
      if (self.syncDone == false)
        self.syncWithChat();

      self.dataSource = new LocalDataSource();
      self.dataSourceLocationChat = new LocalDataSource();
      self.dataSourceLocationInsideChat = new LocalDataSource();

      self.dataSource.setSort([{ field: 'sequence', direction: 'asc' }]);
      self.dataSourceLocationChat.setSort([{ field: 'name', direction: 'asc' }]);
      self.dataSourceLocationInsideChat.setSort([{ field: 'name', direction: 'asc' }]);

      self.dataSource.load(self.locationsArea);

      if (Functions.IsNullOrUndefined(self.selectedLocationArea) == false) {
        self.selectedLocationArea = self.locationsArea.find((aLocationArea: LocationArea) => aLocationArea.numericID == parseInt(self.selectedLocationAreaNumericID.toString()));
        self.dataSourceLocationChat.load(self.selectedLocationArea.subLocations);
      }

      if (Functions.IsNullOrUndefined(self.selectedLocationChat) == false) {
        self.selectedLocationChat = self.selectedLocationArea.subLocations.find((aSubArea: LocationChat) => aSubArea.chatUid == self.selectedLocationChatID);
        self.dataSourceLocationInsideChat.load(self.selectedLocationChat.chatInsideLocations);
      }

      // if (Functions.IsNullOrUndefined(self.selectedRule) == false) {
      //   self.selectedRule = Object.assign({}, self.selectedSubArea.rules.find((aParagrafo: Rule) => aParagrafo.uid == self.selectedRuleID));
      // }

    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.chatSubscription) == false)
      this.chatSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.locationSubscription) == false)
      this.locationSubscription.unsubscribe();
  }

  //#region - Location Area
  onEditConfirm(event) {
    event.confirm.resolve();

    let newLocationArea: LocationArea = new LocationArea();
    newLocationArea.name = event.newData.name;
    newLocationArea.sequence = parseInt(event.newData.sequence);
    newLocationArea.numericID = parseInt(event.newData.numericID);
    newLocationArea.subLocations = event.newData.subLocations;
    newLocationArea.text = event.newData.text;


    const newLocationsArea: LocationArea[] = [];
    for (let index: number = 0; index < this.locationRoot.locationsAreas.length; index++) {
      const currentLocation: LocationArea = this.locationRoot.locationsAreas[index];
      if (currentLocation.numericID == newLocationArea.numericID) {
        newLocationsArea.push(newLocationArea);
      } else {
        newLocationsArea.push(currentLocation);
      }
    }

    this.locationRoot.locationsAreas = newLocationsArea;

    // if (Functions.IsNullOrUndefined(event.newData.sottosezioni) == false) {
    //   newMacroArea.sottosezioni = event.newData.sottosezioni;
    // }

    let self = this;
    let locationRootJSON = JSON.parse(JSON.stringify(this.locationRoot));
    const locationToBeUpdated: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + locationRootJSON.uid);
    locationToBeUpdated.update(locationRootJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(locationRootJSON.uid, DBVersionType.locations);
        //clear under
        this.selectedLocationArea = undefined;
        this.selectedLocationAreaNumericID = undefined;
        this.selectedLocationChat = undefined;
        this.selectedLocationChatID = undefined;
        this.selectedLocationInsideChat = undefined;
        this.selectedLocationInsideChatID = undefined;
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('locations/' + locationRootJSON.uid, locationRootJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(locationRootJSON.uid, DBVersionType.locations);
    //   //clear under
    //   this.selectedLocationArea = undefined;
    //   this.selectedLocationAreaNumericID = undefined;
    //   this.selectedLocationChat = undefined;
    //   this.selectedLocationChatID = undefined;
    //   this.selectedLocationInsideChat = undefined;
    //   this.selectedLocationInsideChatID = undefined;
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onLocationAreaChange(locationArea: LocationArea) {
    if (Functions.IsNullOrUndefined(locationArea) == false) {
      this.selectedLocationArea = locationArea;
      this.dataSourceLocationChat.load(locationArea.subLocations || []);
      this.selectedLocationChat = undefined;
    } else {
      this.selectedLocationArea = undefined;
      this.selectedLocationChat = undefined;
      this.dataSourceLocationChat = new LocalDataSource();
    }
  }
  //#endregion


  //#region - Location chat

  onEditLocationChatConfirm(event) {
    event.confirm.resolve();

    let locationChat: LocationChat = new LocationChat();
    locationChat.name = event.newData.name;
    locationChat.chatUid = event.newData.chatUid;
    locationChat.sequence = parseInt(event.newData.sequence);
    locationChat.text = event.newData.text;
    locationChat.chatInsideLocations = event.newData.chatInsideLocations;

    const newLocationChat: LocationChat[] = [];
    this.selectedLocationArea.subLocations.forEach((aLocationChat: LocationChat) => {
      if (aLocationChat.chatUid == locationChat.chatUid) {
        newLocationChat.push(locationChat);
      } else {
        newLocationChat.push(aLocationChat);
      }
    });

    this.selectedLocationArea.subLocations = newLocationChat;

    let self = this;
    let locationRootDataJSON = JSON.parse(JSON.stringify(this.locationRoot));
    const locationToBeUpdated: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + locationRootDataJSON.uid);
    locationToBeUpdated.update(locationRootDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
        //clear under
        this.selectedLocationArea = undefined;
        this.selectedLocationAreaNumericID = undefined;
        this.selectedLocationChat = undefined;
        this.selectedLocationChatID = undefined;
        this.selectedLocationInsideChat = undefined;
        this.selectedLocationInsideChatID = undefined;
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });


    // this.firestore.write('locations/' + locationRootDataJSON.uid, locationRootDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
    //   //clear under
    //   this.selectedLocationArea = undefined;
    //   this.selectedLocationAreaNumericID = undefined;
    //   this.selectedLocationChat = undefined;
    //   this.selectedLocationChatID = undefined;
    //   this.selectedLocationInsideChat = undefined;
    //   this.selectedLocationInsideChatID = undefined;
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onLocationChatChange(locationChat: LocationChat) {
    if (Functions.IsNullOrUndefined(locationChat) == false) {
      this.selectedLocationChat = locationChat;
      this.dataSourceLocationInsideChat.load(locationChat.chatInsideLocations || []);
      this.selectedLocationInsideChat = undefined;
    } else {
      this.selectedLocationChat = undefined;
      this.selectedLocationInsideChat = undefined;
      this.dataSourceLocationInsideChat = new LocalDataSource();
    }
  }
  //#endregion


  //#region - Location Inside Chat
  onDeleteRuleConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la location interna selezionata e tutto il suo contenuto?')) {
      event.confirm.resolve();

      this.selectedLocationChat.chatInsideLocations = this.selectedLocationChat.chatInsideLocations.filter((anInsideLocation: LocationInsideChat) => {
        anInsideLocation.uid != event.data.uid;
      });

      this.selectedLocationArea.subLocations = this.selectedLocationArea.subLocations
        .map((aChatLocation: LocationChat) => {
          if (aChatLocation.chatUid != this.selectedLocationChat.chatUid)
            return aChatLocation;
          else
            return this.selectedLocationChat;
        });

      let self = this;
      let locationRootDataJSON = JSON.parse(JSON.stringify(this.locationRoot));
      const locationToBeUpdated: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + locationRootDataJSON.uid);
      locationToBeUpdated.update(locationRootDataJSON)
        .then(() => {
          self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
          //clear under
          this.selectedLocationInsideChat = undefined;
          this.selectedLocationInsideChatID = undefined;
          self.toastr.success('Elemento Creato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });

      // this.firestore.write('locations/' + locationRootDataJSON.uid, locationRootDataJSON).subscribe(() => {
      //   self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
      //   //clear under
      //   this.selectedLocationInsideChat = undefined;
      //   this.selectedLocationInsideChatID = undefined;
      //   self.toastr.success('Elemento Creato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

  onCreateRuleConfirm(event) {
    event.confirm.resolve();

    let newLocationInsideChat: LocationInsideChat = new LocationInsideChat();
    newLocationInsideChat.name = event.newData.name;
    newLocationInsideChat.sequence = parseInt(event.newData.sequence);
    newLocationInsideChat.text = "";
    newLocationInsideChat.uid = Functions.CreateGuid();

    this.selectedLocationChat.chatInsideLocations.push(newLocationInsideChat);

    this.selectedLocationArea.subLocations = this.selectedLocationArea.subLocations
      .map((aChatLocation: LocationChat) => {
        if (aChatLocation.chatUid != this.selectedLocationChat.chatUid)
          return aChatLocation;
        else
          return this.selectedLocationChat;
      });

    let self = this;
    let locationRootDataJSON = JSON.parse(JSON.stringify(this.locationRoot));
    const locationToBeUpdated: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + locationRootDataJSON.uid);
    locationToBeUpdated.update(locationRootDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
        //clear under
        this.selectedLocationInsideChat = undefined;
        this.selectedLocationInsideChatID = undefined;
        self.toastr.success('Elemento Creato!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('locations/' + locationRootDataJSON.uid, locationRootDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
    //   //clear under
    //   this.selectedLocationInsideChat = undefined;
    //   this.selectedLocationInsideChatID = undefined;
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onEditRuleConfirm(event) {
    event.confirm.resolve();

    let updatedLocationInsideChat: LocationInsideChat = new LocationInsideChat();
    updatedLocationInsideChat.name = event.newData.name;
    updatedLocationInsideChat.sequence = parseInt(event.newData.sequence);
    updatedLocationInsideChat.text = event.newData.text;
    updatedLocationInsideChat.uid = event.newData.uid;

    this.selectedLocationChat.chatInsideLocations = this.selectedLocationChat.chatInsideLocations.filter((anInsideLocation: LocationInsideChat) => anInsideLocation.uid != event.data.uid);

    this.selectedLocationChat.chatInsideLocations.push(updatedLocationInsideChat);

    this.selectedLocationArea.subLocations = this.selectedLocationArea.subLocations
      .map((aChatLocation: LocationChat) => {
        if (aChatLocation.chatUid != this.selectedLocationChat.chatUid)
          return aChatLocation;
        else
          return this.selectedLocationChat;
      });

    let self = this;
    let locationRootDataJSON = JSON.parse(JSON.stringify(this.locationRoot));
    const locationToBeUpdated: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + locationRootDataJSON.uid);
    locationToBeUpdated.update(locationRootDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
        //clear under
        this.selectedLocationInsideChat = undefined;
        this.selectedLocationInsideChatID = undefined;
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('locations/' + locationRootDataJSON.uid, locationRootDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(locationRootDataJSON.uid, DBVersionType.locations);
    //   //clear under
    //   this.selectedLocationInsideChat = undefined;
    //   this.selectedLocationInsideChatID = undefined;
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }
  //#endregion


  private syncWithChat() {
    this.syncDone == true;

    for (let index: number = 0; index < this.locationRoot.locationsAreas.length; index++) {
      const currentLocationArea: LocationArea = this.locationRoot.locationsAreas[index];
      if (currentLocationArea.numericID == -1)
        continue;

      const updatedLocationChats: LocationChat[] = [];
      const locationChats: LocationChat[] = currentLocationArea.subLocations;
      const locationChatMap: Map<string, LocationChat> = new Map();
      for (const aLocationChat of locationChats) {
        locationChatMap.set(aLocationChat.chatUid, aLocationChat);
      }

      const chats: StandardChat[] = this.standardChat.filter((aChat: StandardChat) => aChat.group == currentLocationArea.numericID);
      for (const aChat of chats) {
        const isPresent: LocationChat = locationChatMap.get(aChat.uid);
        if (Functions.IsNullOrUndefined(isPresent) == false) {
          // already present
          updatedLocationChats.push(isPresent);
        } else {
          const newLocationChat: LocationChat = new LocationChat();
          newLocationChat.chatInsideLocations = [];
          newLocationChat.chatUid = aChat.uid;
          newLocationChat.name = aChat.name;
          newLocationChat.sequence = 0;
          newLocationChat.text = "";
          updatedLocationChats.push(newLocationChat);
        }
      }

      currentLocationArea.subLocations = updatedLocationChats;
    }

    // after sync we have to save it
    let locationRootJSON = JSON.parse(JSON.stringify(this.locationRoot));
    const self: this = this;
    const locationToBeUpdated: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + locationRootJSON.uid);
    locationToBeUpdated.update(locationRootJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(locationRootJSON.uid, DBVersionType.locations);
        this.toastr.success('Sincronizzaione con chat eseguita.');
      })
      .catch((error: any) => {
        this.toastr.error('Ops, sincronizzazione con chat fallita.', 'Errore');
      });

    // this.firestore.write('locations/' + locationRootJSON.uid, locationRootJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(locationRootJSON.uid, DBVersionType.locations);
    //   this.toastr.success('Sincronizzaione con chat eseguita.');
    // }, (error) => {
    //   this.toastr.error('Ops, sincronizzazione con chat fallita.', 'Errore');
    // });
  }

  public onCustom(event: any, fromTable: string) {
    this.modalFrom = fromTable;

    if (fromTable == "area") {
      this.modalRowId = event.data.numericID.toString();
    } else if (fromTable == "chat") {
      this.modalRowId = event.data.chatUid;
    } else if (fromTable == "inside") {
      this.modalRowId = event.data.uid;
    }

    this.modalText = event.data.text;
    this.isLocationModalVisible = true;
  }

  public okModal() {
    this.isLocationModalVisible = false;

    if (this.modalFrom == "area") {
      // adding description to area
      const newLocationsArea: LocationArea[] = [];
      for (let index: number = 0; index < this.locationRoot.locationsAreas.length; index++) {
        const currentLocation: LocationArea = this.locationRoot.locationsAreas[index];
        if (currentLocation.numericID == parseInt(this.modalRowId)) {
          currentLocation.text = this.modalText;
        }
        newLocationsArea.push(currentLocation);
      }

      this.locationRoot.locationsAreas = newLocationsArea;

    } else if (this.modalFrom == "chat") {
      // adding description to location chat
      const newLocationChat: LocationChat[] = [];
      this.selectedLocationArea.subLocations.forEach((aLocationChat: LocationChat) => {
        if (aLocationChat.chatUid == this.modalRowId) {
          aLocationChat.text = this.modalText;
        }
        newLocationChat.push(aLocationChat);
      });

      this.selectedLocationArea.subLocations = newLocationChat;

    } else if (this.modalFrom == "inside") {
      // adding decription to Location inside
      const newLocationInsideChat: LocationInsideChat[] = [];
      this.selectedLocationChat.chatInsideLocations.forEach((aLocationInsideChat: LocationInsideChat) => {
        if (aLocationInsideChat.uid == this.modalRowId) {
          aLocationInsideChat.text = this.modalText;
        }
        newLocationInsideChat.push(aLocationInsideChat);
      });

      this.selectedLocationChat.chatInsideLocations = newLocationInsideChat;
    }

    let self = this;
    let locationRootJSON = JSON.parse(JSON.stringify(this.locationRoot));
    const locationToBeUpdated: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + locationRootJSON.uid);
    locationToBeUpdated.update(locationRootJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(locationRootJSON.uid, DBVersionType.locations);
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('locations/' + locationRootJSON.uid, locationRootJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(locationRootJSON.uid, DBVersionType.locations);
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public closeModal() {
    this.modalFrom = "";
    this.modalRowId = "";
    this.modalText = "";
    this.isLocationModalVisible = false;
  }

}
