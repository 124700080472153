import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as composedSelector from './../../../selectors/composed.selectors';
import * as layout from './../../../actions/layout';
import { Observable, Subscription } from 'rxjs';
import { RulesSezione, RulesSottoSezione, Rule, LocationRoot, uiLocation, DBVersionType } from '../../../models/data/application.data';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { IncrementalService } from '../../../services/incremental.service';


// Utilities
@Component({
  selector: 'locations-component',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.less']
})

export class LocationsComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly moduleName: string = "LocationsComponent";

  @Output() emitClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public rulesContainerMinHeight: number = 0;
  public computedLocations$: Observable<uiLocation[]>;
  private locationSubscription: Subscription;
  public selectedLocation: uiLocation = undefined;


  private selectedSottoSezione: RulesSottoSezione = undefined;
  private selectedParagrafi: string[] = [];

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private incrementalService: IncrementalService
  ) {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.locations, true);

    this.computedLocations$ = this.store.select(composedSelector.getComputedLocation);

    const self: this = this;
    this.locationSubscription = this.computedLocations$.subscribe((aLocation: uiLocation[]) => {
      if (Functions.IsNullOrUndefined(aLocation) == false && aLocation.length > 0)
        self.selectedLocation = aLocation[0];
    });
  }

  public ngOnInit() {

  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.locationSubscription) == false) {
      this.locationSubscription.unsubscribe();
    }
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  selectLocation(location: uiLocation) {
    this.selectedLocation = location;
  }


  backToHomeInterna() {
    this.store.dispatch(new layout.ToggleLocationsAction(false));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    let minHeight: number = (windowsHeight / 100) * 60;
    if (minHeight <= 350)
      minHeight = 350;

    if (minHeight > 522)
      minHeight = 522;

    this.rulesContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }
}
