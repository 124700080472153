import { CharacterSheetData, DBVersionType } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import * as characterSelector from './../../../selectors/character.selectors';
import * as layout from './../../../actions/layout';
import * as chat from './../../../actions/chat';
import * as character from './../../../actions/character';
import { MapState, LayoutState } from '../../../modules/utilities/utilities.constants';
import { AuthenticationService } from '../../../services/authentication.service';
import { Role } from '../../../models/data/application.data';
import { FirestoreService } from '../../../services/firestore.service';
import { IncrementalService } from '../../../services/incremental.service';

// Utilities
@Component({
  selector: 'uffici-volti-type-component',
  templateUrl: './uffici-volti-type.component.html',
  styleUrls: ['./uffici-volti-type.component.less']
})

export class UfficiVoltiTypeComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiVoltiTypeComponent";

  public voltiState: number = 0;
  allLetters: string[] = ["#", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  public allVolti$: Observable<any>;
  public selectedLetter: string = "#";
  public sortBy: number = 1;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private firestoreService: FirestoreService,
    private incrementalService: IncrementalService
  ) {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.volti, true);

    this.allVolti$ = this.store.select(characterSelector.getVoltiAnagrafica);
  }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleVoltiTypeAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public goToScheda(uid: string) {
    this.store.dispatch(new character.SelectplayerID(uid));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: true, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
  }

  public clickLetter(letter: string) {
    this.selectedLetter = letter;

    const self: this = this;
    setTimeout(() => {
      self.cdr.markForCheck();
    }, 0);
  }

  public clickHeader(order: number) {
    this.sortBy = order;

    const self: this = this;
    setTimeout(() => {
      self.cdr.markForCheck();
    }, 0);
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
