import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Volto, VoltoType, UserPresence, PresencePlayerStatus } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { map, first } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-gestione-volto',
  templateUrl: 'cPanel-gestione-volto.component.html',
  styleUrls: ['cPanel-gestione-volto.component.less'],
})
export class CPanelGestioneVoltoComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelGestioneVolto";
  //#region - other variables

  @Input() public voltiType: VoltoType;
  public mergedDataSource: LocalDataSource;
  public mergedData: any[] = [];
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Personaggio',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      },
      vName: {
        title: 'Nome Volto'
      },
      vSur: {
        title: 'Cognome Volto'
      },
      vNick: {
        title: 'Secondo Nome Volto'
      }
    }
  };
  //#region - other variables

  //#region - users data
  // private usersCollection: AngularFirestoreCollection<CharacterSheetData>;
  // private usersCollectionObservable: Observable<CharacterSheetData[]>;
  // private usersSubscription: Subscription;
  public charactersMap: Map<string, PresencePlayerStatus>; //uid, name
  public usersPresence$: Observable<UserPresence[]>;
  private usersPresenceSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  //#region - users data

  //#region - volti data
  private voltiCollection: AngularFirestoreCollection<Volto>;
  private voltiCollectionObservable: Observable<Volto[]>;
  private voltiSubscription: Subscription;
  public volti: Volto[] = [];
  //#endregion - volti data

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService,
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.mergedDataSource = new LocalDataSource();

    //#region - firestore get users
    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersPresenceSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }),
        first()
      ).subscribe((data: PresencePlayerStatus[]) => {
        self.characters = data;
        self.charactersMap = new Map();
        for (let index: number = 0; index < data.length; index++) {
          self.charactersMap.set(data[index].playerUID, data[index]);
        }

        self.createMergedUserBMsData();
      });

    //#endregion - firestore get users

    let voltiIntType: number = 0;
    if (this.voltiType == VoltoType.Maschio) {
      voltiIntType = 0;
    } else if (this.voltiType == VoltoType.Femmina) {
      voltiIntType = 1;
    }

    //#region - firestore get volti
    this.voltiCollection = this.afs.collection<Volto>('volti', ref => ref.where('type', '==', voltiIntType));
    this.voltiCollectionObservable = this.voltiCollection.valueChanges();
    this.voltiSubscription = this.voltiCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all standard volti cpanel", self.moduleName, "volti", (data as any[]).length);
      }

      self.volti = data;

      self.createMergedUserBMsData();
    });
    //#endregion - firestore get volti

  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.voltiSubscription) == false) {
      this.voltiSubscription.unsubscribe();
    }
    // if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
    //   this.usersSubscription.unsubscribe();
    // }
    if (Functions.IsNullOrUndefined(this.usersPresenceSubscription) == false) {
      this.usersPresenceSubscription.unsubscribe();
    }
  }

  public onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      let self = this;
      this.cPanelService.removeDB("volti", event.data.uid);
      // this.firestore.remove('volti/' + event.data.uid).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });;
    } else {
      event.confirm.reject();
    }
  }

  public onCreateConfirm(event) {
    event.newData.uid = Functions.CreateGuid();
    event.confirm.resolve();

    //save on firebase
    let newVoltoData: Volto = new Volto();
    newVoltoData.uid = event.newData.uid;
    newVoltoData.uidPG = event.newData.name;
    newVoltoData.vName = event.newData.vName;
    newVoltoData.vSur = event.newData.vSur;
    newVoltoData.vNick = event.newData.vNick;

    if (this.voltiType == VoltoType.Maschio) {
      newVoltoData.type = VoltoType.Maschio;
    } else if (this.voltiType == VoltoType.Femmina) {
      newVoltoData.type = VoltoType.Femmina;
    }

    let self = this;
    let newVoltoDataJSON = JSON.parse(JSON.stringify(newVoltoData));
    this.cPanelService.writeOnDB("volti", newVoltoDataJSON.uid, newVoltoDataJSON);
    // this.firestore.write('volti/' + newVoltoDataJSON.uid, newVoltoDataJSON).subscribe(() => {
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public onEditConfirm(event) {
    event.confirm.resolve();

    //save on firebase
    let newVoltoData: Volto = new Volto();
    newVoltoData.uid = event.newData.uid;

    const oldCharacterPresence: PresencePlayerStatus = this.charactersMap.get(event.newData.uidPG);
    if (oldCharacterPresence.name == event.newData.name || Functions.IsStringEmpty(event.newData.name)) {
      newVoltoData.uidPG = event.newData.uidPG;
    } else {
      newVoltoData.uidPG = event.newData.name;
    }

    newVoltoData.vName = event.newData.vName;
    newVoltoData.vSur = event.newData.vSur;
    newVoltoData.vNick = event.newData.vNick;

    if (this.voltiType == VoltoType.Maschio) {
      newVoltoData.type = VoltoType.Maschio;
    } else if (this.voltiType == VoltoType.Femmina) {
      newVoltoData.type = VoltoType.Femmina;
    }

    let self = this;
    let newVoltoDataJSON = JSON.parse(JSON.stringify(newVoltoData));
    this.cPanelService.writeOnDB("volti", newVoltoDataJSON.uid, newVoltoDataJSON);
    // this.firestore.write('volti/' + newVoltoDataJSON.uid, newVoltoDataJSON).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public createMergedUserBMsData() {

    if (Functions.IsNullOrUndefined(this.volti))
      return;

    if (Functions.IsNullOrUndefined(this.charactersMap) || this.charactersMap.size == 0)
      return;

    let voltiToBeDisplayed: any[] = [];

    for (let index: number = 0; index < this.volti.length; index++) {
      let pgName: string = "";
      let character: PresencePlayerStatus = this.charactersMap.get(this.volti[index].uidPG);
      if (Functions.IsNullOrUndefined(character) == false) {
        pgName = character.name;
      }
      voltiToBeDisplayed.push(
        {
          uid: this.volti[index].uid,
          uidPG: this.volti[index].uidPG,
          name: pgName,
          vName: this.volti[index].vName,
          vSur: this.volti[index].vSur,
          vNick: this.volti[index].vNick,
        }
      )
    }
    this.mergedData = voltiToBeDisplayed;
    this.mergedDataSource.load(voltiToBeDisplayed);

    this.setUpBMNameEditorList();
  }

  public setUpBMNameEditorList() {
    let voltiIntType: number = 0;
    if (this.voltiType == VoltoType.Maschio) {
      voltiIntType = 0;
    } else if (this.voltiType == VoltoType.Femmina) {
      voltiIntType = 1;
    }

    let pgWithVoltoSet: Set<string> = new Set();

    for (let index: number = 0; index < this.mergedData.length; index++) {
      let uidPG: string = this.mergedData[index].uidPG;
      if (Functions.IsNullOrUndefined(uidPG) == false && Functions.IsStringEmpty(uidPG) == false)
        pgWithVoltoSet.add(this.mergedData[index].uidPG);
    }

    //available pg name list volti add
    let filteredNameList: any[] = [];
    this.charactersMap.forEach((character: PresencePlayerStatus, uid: string) => {
      if (pgWithVoltoSet.has(uid) == false && (character.sex == 2 || character.sex == voltiIntType)) {
        filteredNameList.push(
          { value: uid, title: character.name }
        )
      }
    });

    // sorting alphabetically
    filteredNameList = filteredNameList.sort(function (a, b) {
      var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settings.columns.name.editor.config.list = filteredNameList;
    this.settings = Object.assign({}, this.settings);
  }

}
