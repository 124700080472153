import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutrSelector from './../../../selectors/layout.selectors';
import * as layout from './../../../actions/layout';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import * as chat from './../../../actions/chat';
import { CharacterSheetData, UserPresence, DBVersionType } from '../../../models/data/application.data';
import { LayoutState } from '../../../modules/utilities/utilities.constants';
import { IncrementalService } from 'src/app/services/incremental.service';

// Utilities
@Component({
  selector: 'uffici-maps-component',
  templateUrl: './uffici-maps.component.html',
  styleUrls: ['./uffici-maps.component.less']
})

export class UfficiMapsComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiMapsComponent";

  allChats$: Observable<any[]>;
  expandedAreas: any = {};

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;


  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private incrementalService: IncrementalService
    ) {
    this.incrementalService.getCompleteCollection(DBVersionType.standardChats, true);
    this.allChats$ = this.store.select(layoutrSelector.getStandardChatsDividedByArea);
  }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiMappaAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public expandChatArea(uidArea: string) {
    if (Functions.IsNullOrUndefined(this.expandedAreas) == false && this.expandedAreas[uidArea] == uidArea) {
      this.expandedAreas[uidArea] = undefined;
    } else {
      this.expandedAreas[uidArea] = uidArea;
    }
  }

  public goToChat(chatUid: string) {
    if (Functions.IsStringEmpty(chatUid))
      return;

    // i'm trying to go in a chat
    this.store.dispatch(new chat.StoreSelectedChat(""));

    //#region - avoid double chat
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    const allPresenceStatus: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const myPresenceStatus: UserPresence = allPresenceStatus.find((aUser: UserPresence) => Functions.IsNullOrUndefined(aUser.state) === false && aUser.state.playerUID == characterSheet.uid);

    // Safety Check
    if (Functions.IsNullOrUndefined(myPresenceStatus) || Functions.IsNullOrUndefined(myPresenceStatus.connections))
      return;

    // let's normalize area
    let areasAsArray = Object.keys(myPresenceStatus.connections).map(function (key) {
      return myPresenceStatus.connections[key];
    });
    const removeDouble: Set<string> = new Set(areasAsArray);
    areasAsArray = Array.from(removeDouble.values());

    if (areasAsArray.length > 1) {
      // more then one tab open in different areas
      alert("Sei già in una chat, non puoi entrare in due chat contemporaneamente. Se pensi vi sia un errore contatta lo staff.");
      return;
    }
    //#endregion

    this.store.dispatch(new chat.StoreSelectedChat(chatUid));
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Chat));
    this.store.dispatch(new layout.ToggleUfficiAction(false));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
