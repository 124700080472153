import { RaceIcons, Race } from './../../../models/data/application.data';
import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'raceIconPipe' })
export class RaceIConPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  public transform(raceID: Race, raceLVL: number, raceIcons: RaceIcons[]) {
    // safety check
    if (Functions.IsNullOrUndefined(raceID) == true) {
      return "";
    }


    // safety check
    if (Functions.IsNullOrUndefined(raceIcons) == true || raceIcons.length <= 0) {
      return "";
    }

    const foundedIcon: RaceIcons = raceIcons.find((aRaceIcon: RaceIcons) => aRaceIcon.raceID == raceID);
    //safetyCheck
    if (Functions.IsNullOrUndefined(foundedIcon) == true) {
      return "";
    }

    let iconToReturn: string = "";
    switch (raceLVL) {
      case 1:
        iconToReturn = "url(" + foundedIcon.iconUrlLvl1 + ")";
        break;
      case 2:
        iconToReturn = "url(" + foundedIcon.iconUrlLvl2 + ")";
        break;
      case 3:
        iconToReturn = "url(" + foundedIcon.iconUrlLvl3 + ")";
        break;
      case 4:
        iconToReturn = "url(" + foundedIcon.iconUrlLvl4 + ")";
        break;
      case 5:
        iconToReturn = "url(" + foundedIcon.iconUrlLvl5 + ")";
        break;
      default:
        iconToReturn = "url(" + foundedIcon.iconUrlLvl1 + ")";
        break;
    }

    return this.sanitizer.bypassSecurityTrustStyle(iconToReturn);
  }
}
