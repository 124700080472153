import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { Skill, CharacterSkill, CharacterSheetData, CharacterItems, Item, Stats } from '../../../../models/data/application.data';
import { CharacterState, ChatSkill, CharacterActivatedSkill, CharacterActivatedItem, ChatItem, BHealth } from '../../models/chat-message.models';
import { Functions } from '../../../utilities/functions/utilities.functions';
import * as layout from './../../../../actions/layout';
import * as chatSelector from './../../../../selectors/chat.selectors';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'chat-item-box',
  templateUrl: './chat-item-box.component.html',
  styleUrls: ['./chat-item-box.component.css']
})
export class ChatItemBoxComponent implements OnInit, OnDestroy {

  private chatCharactersState$: Observable<CharacterState[]>;
  public isQuestModeActive$: Observable<boolean>;
  private chatCharactersStateSubscription: Subscription;
  items: ChatItem[];
  itemsActive: ChatItem[];
  selectedItem: ChatItem = undefined;
  selectedActiveItem: ChatItem = undefined;

  constructor(private chatService: ChatService, private store: Store<fromRoot.State>) {
    this.chatCharactersState$ = this.store.select(chatSelector.getChatCharactersState);
    this.isQuestModeActive$ = this.store.select(chatSelector.getIsQuestActive);
  }

  ngOnInit() {
    let self: this = this;
    this.chatCharactersStateSubscription = this.chatCharactersState$.subscribe((chatCharactersState: CharacterState[]) => {
      self.createMergedUserItemsData();
    });
  }

  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.chatCharactersStateSubscription) == false)
      this.chatCharactersStateSubscription.unsubscribe();
  }

  createMergedUserItemsData() {
    //#region - items available
    // character skills
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    const characterItems: CharacterItems[] = myCharacterSheet.userItems || [];

    // All items
    const allItems: Item[] = fromRoot.getState(this.store).datas.items || [];
    const itemsMap: Map<string, Item> = new Map();
    for (let index: number = 0; index < allItems.length; index++) {
      itemsMap.set(allItems[index].uid, allItems[index]);
    }

    const itemsToBeDisplayed: ChatItem[] = [];
    for (let index: number = 0; index < characterItems.length; index++) {
      const anItem: Item = itemsMap.get(characterItems[index].uid);

      // safety check
      if (Functions.IsNullOrUndefined(anItem))
        continue;

      // Available uses
      const availableUses: number = characterItems[index].remainingUse;
      const maxUses: number = anItem.maxUse;
      const qta: number = characterItems[index].qta;

      // compute skill stats bonus from formula
      const context: any = {
        lvl: myCharacterSheet.lvl,
        str: myCharacterSheet.stats.str,
        agl: myCharacterSheet.stats.agl,
        res: myCharacterSheet.stats.res,
        per: myCharacterSheet.stats.per,
        wsd: myCharacterSheet.stats.wsd,
        wil: myCharacterSheet.stats.wil,
        sal: myCharacterSheet.health,
        salM: myCharacterSheet.mindHealth
      }
      const computed: any = Functions.computeStatsBonus(anItem.statsBonus, context);
      const itemBonus: Stats = computed.stats;
      const itemAddBonus: Stats = computed.statsAdd;
      const healthBonus: BHealth = computed.health;
      let isItemWithAdditingBuff: boolean = false;

      // check if the skill has an additing buff
      if (itemAddBonus.str != 0 || itemAddBonus.agl != 0 || itemAddBonus.per != 0 || itemAddBonus.res != 0 || itemAddBonus.wsd != 0 || itemAddBonus.wil != 0 || healthBonus.salAdd != 0 || healthBonus.salMAdd != 0) {
        isItemWithAdditingBuff = true;
      }

      const anItemToDisplay: ChatItem = new ChatItem(anItem.uid, anItem.name, anItem.description, availableUses, maxUses, qta, itemBonus, itemAddBonus, healthBonus, isItemWithAdditingBuff, computed.durata);
      itemsToBeDisplayed.push(anItemToDisplay);
    }
    //#endregion

    //#region - items active
    const chatCharactersState: CharacterState[] = fromRoot.getState(this.store).chat.chatCharactersState || [];
    let mychatCharacterState: CharacterState = undefined;
    if (chatCharactersState.length > 0) {
      mychatCharacterState = chatCharactersState.find((aCharacterItems: any) => aCharacterItems.uid == fromRoot.getState(this.store).character.myUID);
    }

    let activatedItems: CharacterActivatedItem[] = [];
    if (Functions.IsNullOrUndefined(mychatCharacterState) == false) {
      activatedItems = mychatCharacterState.activatedItem || [];
    }

    const activeItemsToBeDisplayedMap: Map<string, ChatItem> = new Map();
    let activeItemsToBeDisplayed: ChatItem[] = [];
    for (let index: number = 0; index < activatedItems.length; index++) {
      const anItem: Item = itemsMap.get(activatedItems[index].uid);

      // safety check
      if (Functions.IsNullOrUndefined(anItem))
        continue;

      const itemAlreadyPresent: ChatItem = activeItemsToBeDisplayedMap.get(anItem.uid);
      if (Functions.IsNullOrUndefined(itemAlreadyPresent) == false) {
        // item already present
        itemAlreadyPresent.qta++;
      } else {
        // item not yet present
        // compute skill stats bonus from formula
        const context: any = {
          lvl: myCharacterSheet.lvl,
          str: myCharacterSheet.stats.str,
          agl: myCharacterSheet.stats.agl,
          res: myCharacterSheet.stats.res,
          per: myCharacterSheet.stats.per,
          wsd: myCharacterSheet.stats.wsd,
          wil: myCharacterSheet.stats.wil,
          sal: myCharacterSheet.health,
          salM: myCharacterSheet.mindHealth
        }
        const computed: any = Functions.computeStatsBonus(anItem.statsBonus, context);
        const itemBonus: Stats = computed.stats;
        const itemAddBonus: Stats = computed.statsAdd;
        const healthBonus: BHealth = computed.health;

        const anItemToDisplay: ChatItem = new ChatItem(anItem.uid, anItem.name, anItem.description, 0, 0, 1, itemBonus, itemAddBonus, healthBonus, false, computed.durata);
        activeItemsToBeDisplayedMap.set(anItemToDisplay.uid, anItemToDisplay);
      }
    }
    activeItemsToBeDisplayed = Array.from(activeItemsToBeDisplayedMap.values());
    //#endregion

    //#region - remove active items from items available (for items without uses)
    const itemToBeDisplayedFixed: ChatItem[] = [];
    for (let index: number = 0; index < itemsToBeDisplayed.length; index++) {
      const anItem: ChatItem = itemsToBeDisplayed[index];
      if (Functions.IsNullOrUndefined(anItem.maxUses) == true) {
        // items without charges
        const isActive: ChatItem = activeItemsToBeDisplayedMap.get(anItem.uid);
        if (Functions.IsNullOrUndefined(isActive) == false) {
          // is active so we need to check quantity
          if (Functions.IsNullOrUndefined(anItem.qta) == false && anItem.qta > 1) {
            const qtaCorretItem: ChatItem = Object.assign({}, anItem);
            qtaCorretItem.qta = qtaCorretItem.qta--;
            itemToBeDisplayedFixed.push(qtaCorretItem);
          }
          continue;
        }
      }
      itemToBeDisplayedFixed.push(anItem);
    }
    //#endregion - remove active items from items available

    this.items = itemToBeDisplayedFixed;
    this.itemsActive = activeItemsToBeDisplayed;
  }

  selectItem(item: ChatItem) {
    if (Functions.IsNullOrUndefined(this.selectedItem) == false && this.selectedItem.uid == item.uid) {
      this.selectedItem = undefined;
    } else {
      this.selectedItem = item;
    }
  }

  activateItem() {
    if (Functions.IsNullOrUndefined(this.selectedItem) == true) {
      return;
    }

    this.chatService.activateChatItem(this.selectedItem);
    this.closeBox();
  }

  closeBox() {
    this.store.dispatch(new layout.ToggleChatItemBoxAction());
  }

  selectActiveItem(item: ChatItem) {
    if (Functions.IsNullOrUndefined(this.selectedActiveItem) == false && this.selectedActiveItem.uid == item.uid) {
      this.selectedActiveItem = undefined;
    } else {
      this.selectedActiveItem = item;
    }
  }

  disableActiveItem(item: ChatItem) {
    if (Functions.IsNullOrUndefined(item) == true) {
      return;
    }

    this.chatService.disableChatItem(item);
  }

}
