import { Injectable } from '@angular/core';
import { Functions } from '../modules/utilities/functions/utilities.functions';

declare var platform: any;

@Injectable()
export class BrowserService {

  public plaftform: any = null;
  //names
  public NAME_CHROME: string = "Chrome";
  public NAME_INTERNET_EXPLORER: string = "IE";
  public NAME_FIREFOX: string = "Firefox";
  public NAME_SAFARI: string = "Safari";
  public NAME_EDGE: string = "Edge";
  //product names
  public PRODUCT_IPAD: string = "iPad";
  public PRODUCT_IPHONE: string = "iPhone";
  public PRODUCT_WINDOWS_PHONE: string = "Windows Phone ";
  public PRODUCT_ANDROID: string = "Android";
  public USER_AGENT_MOBILE: string = "Mobile";
  //layout engines
  public LAYOUT_TRIDENT: string = "Trident"; // Microsoft IE
  public LAYOUT_WEBKIT: string = "WebKit"; // Apple Safari 
  public LAYOUT_GECKO: string = "Gecko"; // Firefox
  public LAYOUT_BLINK: string = "Blink"; // Chrome
  public LAYOUT_EDGE: string = "EdgeHTML"; // Edge


  // device layout form factor
  // Note: due to the fact that the device detection is made using css media queries the same constants below are also define in the file Variables.less
  public DEVICE_FORMFACTOR_BIG: string = "big-screen";
  public DEVICE_FORMFACTOR_SMALL: string = "small-screen";


  constructor() { }


  public isPhone(): boolean {

    ////Determination if isPhone is forced from location url query string 
    //var isPhone: string = Functions.GetParameterByName(Constants.TXT_QUERYSTRINGPARAM_ISPHONE_NAME);
    //if (isPhone.toLowerCase() === Constants.TXT_QUERYSTRINGPARAM_VALUE_TRUE.toLowerCase())
    //  return true;
    //if (isPhone.toLowerCase() === Constants.TXT_QUERYSTRINGPARAM_VALUE_FALSE.toLowerCase())
    //  return false;

    ////Ipad detection
    //if (this.isIpad())
    //  return false;

    ////Iphone detection
    //if (this.isIphone())
    //  return true;

    ////Windows phone detection
    //if (this.isWindowsPhone())
    //  return true;

    ////Android phone detection
    //if (this.isAndroidPhone())
    //  return true;

    //if (this.isTabletDevice())
    //  return false;

    //Default is not phone
    return false;

  }

  // Check if the current browser is running on small screen devices
  // This check is done using the css media queries
  // User can force IsTablet check by using a specific location url param "isTablet=true" or "isTablet=false"
  // public isTabletDevice(): boolean {
  //   //Determination if isTablet is forced from location url query string 
  //   const isTablet: string = Functions.GetParameterByName(Constants.TXT_QUERYSTRINGPARAM_ISTABLET_NAME);
  //   if (isTablet.toLowerCase() === Constants.TXT_QUERYSTRINGPARAM_VALUE_TRUE.toLowerCase())
  //     return true;
  //   if (isTablet.toLowerCase() === Constants.TXT_QUERYSTRINGPARAM_VALUE_FALSE.toLowerCase())
  //     return false;

  //   if (this.isIpad())
  //     return true;
  //   if (this.isIphone())
  //     return false;

  //   const size: string = window.getComputedStyle(document.body, ':after')
  //     .getPropertyValue('content');
  //   if (size.indexOf(this.DEVICE_FORMFACTOR_SMALL) != -1)
  //     return true;
  //   return false;
  // }

  // Browser name (i.e. 'IE')
  public getName(): string {
    if (Functions.IsNull(platform) == true || Functions.IsUndefined(platform) == true)
      return "";
    else
      return platform.name;
  }

  // Browser version (i.e. '10.0')
  public getVersion(): string {
    if (Functions.IsNull(platform) == true || Functions.IsUndefined(platform) == true)
      return "";
    else
      return platform.version;
  }

  // Browser layout engine (i.e. 'Trident')
  public getLayout(): string {
    if (Functions.IsNull(platform) == true || Functions.IsUndefined(platform) == true)
      return "";
    else
      return platform.layout;
  }

  // operating system (i.e 'Windows Server 2008 R2 / 7 x64')
  public getOperatingSystem(): string {
    if (Functions.IsNull(platform) == true || Functions.IsUndefined(platform) == true)
      return "";
    else
      return platform.os;
  }

  // Product device (i.e. 'Ipad')
  public getProduct(): string {
    if (Functions.IsNull(platform) == true || Functions.IsUndefined(platform) == true)
      return "";
    else
      return platform.product;
  }

  // Manufacturer (i.e. 'Apple')
  public getManufacturer(): string {
    if (Functions.IsNull(platform) == true || Functions.IsUndefined(platform) == true)
      return "";
    else
      return platform.manufacturer;
  }

  // User agent complete description
  public getUserAgent(): string {
    if (Functions.IsNull(platform) == true || Functions.IsUndefined(platform) == true)
      return "";
    else
      return platform.ua;
  }


  // check the current browser 
  public isIpad(): boolean {
    //return false;
    const isIpad: boolean = (this.getProduct() != null && (this.getProduct() == this.PRODUCT_IPAD)) ? true : false;
    return isIpad;
  }

  // Check if the current browser is running on extra small screen devices
  // This check is done using the browser agent properties
  // User can force IsPhone check by using a specific location url param "isPhone=true" or "isPhone=false"
  public isIphone(): boolean {
    //return false;
    const isIphone: boolean = (this.getProduct() != null && (this.getProduct() == this.PRODUCT_IPHONE)) ? true : false;
    return isIphone;
  }

  public isWindowsPhone(): boolean {
    const os: any = this.getOperatingSystem();
    const isWindowsPhone: boolean = (os.family == this.PRODUCT_WINDOWS_PHONE) ? true : false;
    return isWindowsPhone;
  }

  public isAndroidPhone(): boolean {
    const os: any = this.getOperatingSystem();
    const isAndroidPhone: boolean = (
      os.family.indexOf(this.PRODUCT_ANDROID) > -1 &&
      (this.getUserAgent()
        .indexOf(this.USER_AGENT_MOBILE) > -1)) ?
      true : false;
    return isAndroidPhone;
  }

  public isSafari(): boolean {
    const isSafari: boolean = (this.getName() && (this.getName() == this.NAME_SAFARI)) ? true : false;
    return isSafari;
  }

  public isIE(): boolean {
    const isIE: boolean = (this.getName() && (this.getName() == this.NAME_INTERNET_EXPLORER)) ? true : false;
    return isIE;
  }

  public isFirefox(): boolean {
    const isFirefox: boolean = (this.getName() && (this.getName() == this.NAME_FIREFOX)) ? true : false;
    return isFirefox;
  }

  public isChrome(): boolean {
    const isChrome: boolean = (this.getName() && (this.getName() == this.NAME_CHROME)) ? true : false;
    return isChrome;
  }

}
