import { CPanelEsiliaPersonaggioComponent } from './components/cPanel-esilia-personaggio/cPanel-esilia-personaggio.component';
import { HttpClientModule } from '@angular/common/http';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTooltipModule,
  MatListModule,
  MatExpansionModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { CPanelHomeComponent } from './components/cPanel-home/cPanel-home.component';
import { CPanelSidenavComponent } from './components/cPanel-sidenav/cPanel-sidenav.component';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

// Create Material Module with all we need
const MaterialModule: any = [
  MatDatepickerModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatRadioModule,
  MatToolbarModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatExpansionModule
];


// import { QuillModule } from 'ngx-quill';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { FlatpickrModule } from 'angularx-flatpickr';
// import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CPanelArchivioOggettiComponent } from './components/cPanel-archivio-oggetti/cPanel-archivio-oggetti.component';
import { CPanelAssegnaOggettoAPersonaggioComponent } from './components/cPanel-assegna-oggetto-a-personaggio/cPanel-assegna-oggetto-a-personaggio.component';
import { CPanelArchivioSkillsComponent } from './components/cPanel-archivio-skills/cPanel-archivio-skills.component';
import { CPanelArchivioBMComponent } from './components/cPanel-archivio-bm/cPanel-archivio-bm.component';
import { CPanelAssegnaSkillAPersonaggioComponent } from './components/cPanel-assegna-skill-a-personaggio/cPanel-assegna-skill-a-personaggio.component';
import { CPanelAssegnaBMAPersonaggioComponent } from './components/cPanel-assegna-bm-a-personaggio/cPanel-assegna-bm-a-personaggio.component';
import { CPanelCancellaPersonaggioComponent } from './components/cPanel-cancella-personaggio/cPanel-cancella-personaggio.component';
import { CPanelEditCreditsComponent } from './components/cPanel-edit-credits/cPanel-edit-credits.component';
import { CPanelStaffNewsComponent } from './components/cPanel-staff-news/cPanel-staff-news.component';
import { CPanelEditDisclaimerComponent } from './components/cPanel-edit-disclaimer/cPanel-edit-disclaimer.component';
import { CPanelGestioneVoltoComponent } from './components/cPanel-gestione-volto/cPanel-gestione-volto.component';
import { CPanelCreaPersonaggioComponent } from './components/cPanel-crea-personaggio/cPanel-crea-personaggio.component';
import { CPanelEditPersonaggioComponent } from './components/cPanel-edit-personaggio/cPanel-edit-personaggio.component';
import { CPanelGestioneEventiComponent } from './components/cPanel-gestione-eventi/cPanel-gestione-eventi.component';
import { CPanelMeteoComponent } from './components/cPanel-meteo/cPanel-meteo.component';
import { CPanelAssegnaExpComponent } from './components/cPanel-assegna-exp/cPanel-assegna-exp.component';
import { CPanelAssegnaCaricheStaffComponent } from './components/cPanel-assegna-cariche-staff/cPanel-assegna-cariche-staff.component';
import { CPanelRegolamentoComponent } from './components/cPanel-regolamento/cPanel-regolamento.component';
import { CPanelGestioneChatComponent } from './components/cPanel-gestione-chat/cPanel-gestione-chat.component';
import { CPanelService } from './services/cpanel.service';
import { CPanelComandiAvanzatiComponent } from './components/cPanel-comandi-avanzati/cPanel-comandi-avanzati.component';
import { CPanelLogChatComponent } from './components/cPanel-log-chat/cPanel-log-chat.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ChatRoomModule } from '../chatRoom/chatRoom.module';
import { CPanelLogMessaggiComponent } from './components/cPanel-log-messaggi/cPanel-log-messaggi.component';
import { UtilitiesModule } from '../utilities/utilities.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CPanelArchivioClanComponent } from './components/cPanel-archivio-clan/cPanel-archivio-clan.component';
import { CPanelArchivioCorpComponent } from './components/cPanel-archivio-corp/cPanel-archivio-corp.component';
import { CPanelAssegnaClanAPersonaggioComponent } from './components/cPanel-assegna-clan/cPanel-assegna-clan.component';
import { CPanelAssegnaCorpAPersonaggioComponent } from './components/cPanel-assegna-corp/cPanel-assegna-corp.component';
import { CPanelCitazioniComponent } from './components/cPanel-citazioni/cPanel-citazioni.component';
import { CPanelEditStartMessageComponent } from './components/cPanel-edit-start-message/cPanel-edit-start-message.component';
import { CPanelAssegnaMoneyComponent } from './components/cPanel-assegna-money/cPanel-assegna-money.component';
import { CPanelLogsComponent } from './components/cPanel-logs/cPanel-logs.component';
import { CPanelEditLocationAreaComponent } from './components/cPanel-edit-location-area/cPanel-edit-location-area.component';
import { CPanelAssegnaRazzaAPersonaggioComponent } from './components/cPanel-assegna-razza/cPanel-assegna-razza.component';
import { CPanelGestioneSpecialChatComponent } from './components/cPanel-gestione-special-chat/cPanel-gestione-special-chat.component';
import { CPanelIstruzioniChatComponent } from './components/cPanel-istruzioni-chat/cPanel-istruzioni-chat.component';

// 3rd party
import { NgxTinymceModule } from 'ngx-tinymce';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CPanelDebugLogComponent } from './components/cPanel-debug-log/cPanel-debug-log.component';
import { CPanelTriggerManagerComponent } from './components/cPanel-trigger-manager/cPanel-trigger-manager.component';
import { CPanelJobMoneyManagerComponent } from './components/cPanel-job-money-manager/cPanel-job-money-manager.component';
import { CPanelSyncComponent } from './components/cPanel-sync/cPanel-sync.component';
import { CPanelRestoreBckComponent } from './components/cPanel-restore-bck/cPanel-restore-bck.component';
import { BackupFileService } from './services/backup-file.service';
import { CPanelArchivioRazzeComponent } from './components/cPanel-archivio-razze/cPanel-archivio-razze.component';
import { CPanelIpManagerComponent } from './components/cPanel-ip-manager/cPanel-ip-manager.component';
import { CPanelSignUpFormComponent } from './components/cPanel-sign-up-form/cPanel-sign-up-form.component';
import { FormioAppConfig, FormioModule } from 'angular-formio';
registerLocaleData(localeIt);


const AppConfig = {
  appUrl: 'https://eszaddwpozvknlm.form.io',
  apiUrl: 'https://api.form.io'
};
// Make sure we use fontawesome everywhere in Form.io renderers.
// const Formio = require('formiojs').Formio;
// Formio.icons = 'fontawesome';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    Ng2SmartTableModule,
    NgSelectModule,
    // QuillModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FlatpickrModule.forRoot(),
    // Ng2FlatpickrModule,
    AngularMyDatePickerModule,
    ChatRoomModule,
    UtilitiesModule,
    InfiniteScrollModule,
    NgxTinymceModule.forRoot({
      baseURL: '../../../assets/libraries/tinymce/'
    }),
    FormioModule
  ],
  providers: [
    CPanelService,
    BackupFileService,
    {provide: FormioAppConfig, useValue: AppConfig}
  ],
  declarations: [
    CPanelHomeComponent,
    CPanelSidenavComponent,
    CPanelArchivioOggettiComponent,
    CPanelAssegnaOggettoAPersonaggioComponent,
    CPanelEsiliaPersonaggioComponent,
    CPanelArchivioSkillsComponent,
    CPanelArchivioBMComponent,
    CPanelAssegnaSkillAPersonaggioComponent,
    CPanelAssegnaBMAPersonaggioComponent,
    CPanelCancellaPersonaggioComponent,
    CPanelEditCreditsComponent,
    CPanelStaffNewsComponent,
    CPanelEditDisclaimerComponent,
    CPanelGestioneVoltoComponent,
    CPanelCreaPersonaggioComponent,
    CPanelEditPersonaggioComponent,
    CPanelGestioneEventiComponent,
    CPanelMeteoComponent,
    CPanelAssegnaExpComponent,
    CPanelAssegnaCaricheStaffComponent,
    CPanelRegolamentoComponent,
    CPanelGestioneChatComponent,
    CPanelGestioneSpecialChatComponent,
    CPanelComandiAvanzatiComponent,
    CPanelLogChatComponent,
    CPanelLogMessaggiComponent,
    CPanelArchivioClanComponent,
    CPanelArchivioCorpComponent,
    CPanelAssegnaClanAPersonaggioComponent,
    CPanelAssegnaCorpAPersonaggioComponent,
    CPanelCitazioniComponent,
    CPanelEditStartMessageComponent,
    CPanelAssegnaMoneyComponent,
    CPanelLogsComponent,
    CPanelEditLocationAreaComponent,
    CPanelAssegnaRazzaAPersonaggioComponent,
    CPanelIstruzioniChatComponent,
    CPanelDebugLogComponent,
    CPanelTriggerManagerComponent,
    CPanelJobMoneyManagerComponent,
    CPanelSyncComponent,
    CPanelRestoreBckComponent,
    CPanelArchivioRazzeComponent,
    CPanelIpManagerComponent,
    CPanelSignUpFormComponent
  ],
  exports: [
    CPanelHomeComponent,
    CPanelSidenavComponent,
    CPanelArchivioOggettiComponent,
    CPanelAssegnaOggettoAPersonaggioComponent,
    CPanelEsiliaPersonaggioComponent,
    CPanelArchivioSkillsComponent,
    CPanelArchivioBMComponent,
    CPanelAssegnaSkillAPersonaggioComponent,
    CPanelAssegnaBMAPersonaggioComponent,
    CPanelCancellaPersonaggioComponent,
    CPanelEditCreditsComponent,
    CPanelStaffNewsComponent,
    CPanelEditDisclaimerComponent,
    CPanelGestioneVoltoComponent,
    CPanelCreaPersonaggioComponent,
    CPanelEditPersonaggioComponent,
    CPanelGestioneEventiComponent,
    CPanelMeteoComponent,
    CPanelAssegnaExpComponent,
    CPanelAssegnaCaricheStaffComponent,
    CPanelRegolamentoComponent,
    CPanelGestioneChatComponent,
    CPanelComandiAvanzatiComponent,
    CPanelLogChatComponent,
    CPanelCitazioniComponent,
    CPanelEditStartMessageComponent,
    CPanelAssegnaMoneyComponent,
    CPanelLogsComponent,
    CPanelEditLocationAreaComponent,
    CPanelAssegnaRazzaAPersonaggioComponent,
    CPanelDebugLogComponent,
    CPanelTriggerManagerComponent,
    CPanelJobMoneyManagerComponent,
    CPanelSyncComponent,
    CPanelRestoreBckComponent,
    CPanelArchivioRazzeComponent,
    CPanelIpManagerComponent,
    CPanelSignUpFormComponent
  ]
})

export class CPanelModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('cPanel-theme');
  }
}
