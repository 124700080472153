import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Functions } from '../../modules/utilities/functions/utilities.functions';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase/app';
import { map } from 'rxjs/operators';

@Injectable()
export class RestoreGuardService implements CanActivate {

  constructor(
    private afAuth: AngularFireAuth,
    public routerService: Router,
    public location: Location
  ) { }

  public canActivate(): Observable<boolean> {

    return this.afAuth.authState.pipe(map((userAuth: User) => {
      const isAuthenticated: boolean = (Functions.IsNullOrUndefined(userAuth) == false);
      if (isAuthenticated && ((userAuth.email == "fitzroy013@gmail.com") || (userAuth.email == "reginadellacorte@outlook.it") || (userAuth.email == "reddomino92@gmail.com"))) {
        return true;
      } else {
        return false;
      }
    }));
  }
}
