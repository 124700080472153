import { FirestoreService } from './../../../services/firestore.service';
import { MeteoDay } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import * as datasSelector from './../../../selectors/datas.selectors';
import { Subscription } from 'rxjs';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { MoonPhase } from '../../../modules/utilities/functions/moonPhase.functions';

// Utilities
@Component({
  selector: 'meteo-component',
  templateUrl: './meteo.component.html',
  styleUrls: ['./meteo.component.less']
})

export class MeteoComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly moduleName: string = "MeteoComponent";

  public meteoDay$: Observable<MeteoDay>;
  public meteoNote$: Observable<string>;

  private meteoDay: MeteoDay;
  public moonPhaseIcon: any;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(private store: Store<fromRoot.State>, private cdr: ChangeDetectorRef, private firestoreService: FirestoreService) {
    this.meteoDay$ = this.store.select(datasSelector.getMeteo);
    this.meteoNote$ = this.store.select(datasSelector.getMeteoNote);
  }

  public ngOnInit() {
    this.firestoreService.getMeteo();

    // calculate moon phase
    // const currentDate: Date = new Date();
    // this.moonPhaseIcon = Functions.calculateMoonPhase(currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear());

    this.moonPhaseIcon = MoonPhase.getCurrentMoonPhase();

  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public ngOnDestroy() {
    // this.firestoreService.killMeteoSubscription();
  }

  backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiAction(false));
  }

  public onResize(event?: any) {
    // const windowsHeight = window.innerHeight;
    // const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    // let minHeight = windowsHeight - floatingPaddingTop - 80;
    // if (minHeight <= 400)
    //   minHeight = 400;

    // if (minHeight > 400)
    //   minHeight = 400;

    const windowsHeight = window.innerHeight;
    let minHeight: number = (windowsHeight / 100) * 60;
    if (minHeight <= 350)
      minHeight = 350;

    if (minHeight > 450)
      minHeight = 450;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
