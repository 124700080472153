import { CharacterSheetData, Skill, Item, Race, BonusMalus, ItemCat, PresencePlayerUI, StandardChat, UserPresence, PresencePlayerStatus, Volto, VoltoUI, VoltoType, Role, SecretChat, CharacterSkill, Stats, SpecialChat } from './../models/data/application.data';
import { createSelector } from 'reselect';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import { State } from '../reducers/index';
import * as fromCharacter from '../reducers/character';
import * as datasSelector from './datas.selectors';
import * as layoutSelector from './layout.selectors';

/**
 * Layout
 */
export const ModuleName: string = "CharacterSelector";
export const getCharacterState: (state: State) => fromCharacter.State = (state: State) => state.character;
export const getCharacterSheet: any = createSelector(getCharacterState, fromCharacter.getCharacterState);
export const getUserRole: any = createSelector(getCharacterState, fromCharacter.getUserRole);
export const getIsCapoClan: any = createSelector(getCharacterState, fromCharacter.getIsCapoClan);
export const getIsCapoCorp: any = createSelector(getCharacterState, fromCharacter.getIsCapoCorp);
export const getIsCapoRace: any = createSelector(getCharacterState, fromCharacter.getIsCapoRace);
export const getSelectedPlayer: any = createSelector(getCharacterState, fromCharacter.getSelectedPlayer);
export const getUsersPresence: any = createSelector(getCharacterState, fromCharacter.getUsersPresence);
export const getMyUID: any = createSelector(getCharacterState, fromCharacter.getMyUID);
export const getMyAuthData: any = createSelector(getCharacterState, fromCharacter.getMyAuthData);

export const canAccess: any = createSelector(getUserRole, getCharacterSheet,
  (userRole: Role, myCharacterShet: CharacterSheetData) => {
    if (Functions.IsNullOrUndefined(myCharacterShet) == true) {
      return false;
    }

    if (userRole <= 2 || myCharacterShet.isCapoClan || myCharacterShet.isCapoClan || (Functions.IsNullOrUndefined(myCharacterShet.isCapoRace) == false && myCharacterShet.isCapoRace.length > 0))
      return true;

    return false;
  });

export const canRestore: any = createSelector(getMyAuthData,
  (authData: firebase.auth.UserCredential) => {

    // safety check
    if (Functions.IsNullOrUndefined(authData) == true)
      return false;

    if (authData.user.email == "fitzroy013@gmail.com" || authData.user.email == "reginadellacorte@outlook.it" || authData.user.email == "reddomino92@gmail.com") {
      return true;
    } else {
      return false;
    }
  });


export const getComputedSelectedPlayer: any = createSelector(getSelectedPlayer, datasSelector.getSkills, datasSelector.getItems, datasSelector.getBMs, getCharacterSheet,
  (selectedCharacterSheet: CharacterSheetData, allSkills: Skill[], allItems: Item[], allBMs: BonusMalus[], mySheet: CharacterSheetData) => {

    if (Functions.IsNullOrUndefined(selectedCharacterSheet) || Functions.IsNullOrUndefined(mySheet)) {
      return { baseData: new CharacterSheetData(), race: "", skills: [], items: [], bms: [], withHAvatar: false, isMySheet: false };
    }

    const clonedCharacterSheet: CharacterSheetData = Object.assign({}, selectedCharacterSheet);
    const race: string = Race[clonedCharacterSheet.race];
    let computedSkills: any[] = Functions.computeUserSkills(selectedCharacterSheet, allSkills);
    const computedItems: any[] = Functions.computeUserItems(selectedCharacterSheet, allItems);
    const computedBMs: any[] = Functions.computeUserBMs(selectedCharacterSheet, allBMs);

    let armi: any[] = [];
    let artefatti: any[] = [];
    let varie: any[] = [];
    for (let index: number = 0; index < computedItems.length; index++) {
      const currentItem: any = computedItems[index];
      switch (currentItem.itemCat) {
        case ItemCat.armi:
          armi.push(currentItem);
          break;

        case ItemCat.pozioni_artefatti:
          artefatti.push(currentItem);
          break;

        case ItemCat.varie:
          varie.push(currentItem);
          break;

        default:
          varie.push(currentItem);
          break;
      }
    }

    armi = armi.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    artefatti = artefatti.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    varie = varie.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });


    let bonus: any[] = [];
    let malus: any[] = [];
    for (let index: number = 0; index < computedBMs.length; index++) {
      const currentBM: any = computedBMs[index];
      if (currentBM.isBonus) {
        bonus.push(currentBM);
      } else {
        malus.push(currentBM);
      }
    }

    bonus = bonus.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    malus = malus.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    computedSkills = computedSkills.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    // computing sing date
    // if (Functions.IsStringEmpty(clonedCharacterSheet.signDate) == false && Functions.IsNullOrUndefined(clonedCharacterSheet.signDate) == false) {
    //   let signDate: Date = new Date(clonedCharacterSheet.signDate);
    //   let hour: string = Functions.GetDateHour(signDate);
    //   clonedCharacterSheet.signDate = hour;
    // }

    // computing acces date
    // if (Functions.IsStringEmpty(clonedCharacterSheet.access) == false && Functions.IsNullOrUndefined(clonedCharacterSheet.access) == false) {
    //   let access: Date = new Date(clonedCharacterSheet.access);
    //   let hour: string = Functions.GetDateHour(access);
    //   clonedCharacterSheet.access = hour;
    // }



    let withHAvatar: boolean = true;
    if (Functions.IsNullOrUndefined(clonedCharacterSheet.HAvatar) || Functions.IsStringEmpty(clonedCharacterSheet.HAvatar))
      withHAvatar = false;

    let isMySheet: boolean = false;
    if (mySheet.uid == clonedCharacterSheet.uid) {
      isMySheet = true;
    }

    // // set up last access
    // const currentPresence: UserPresence = usersPresence.find((aUSer: UserPresence) => Functions.IsNullOrUndefined(aUSer.state) == false && aUSer.state.playerUID == clonedCharacterSheet.uid);
    // if (Functions.IsNullOrUndefined(currentPresence) == false) {
    //   clonedCharacterSheet.access = currentPresence.state.lastAccess;
    // }

    // safety check stats
    if (Functions.IsNullOrUndefined(clonedCharacterSheet.stats) == true) {
      clonedCharacterSheet.stats = new Stats();
    } else {
      clonedCharacterSheet.stats.str = clonedCharacterSheet.stats.str || 0;
      clonedCharacterSheet.stats.agl = clonedCharacterSheet.stats.agl || 0;
      clonedCharacterSheet.stats.per = clonedCharacterSheet.stats.per || 0;
      clonedCharacterSheet.stats.res = clonedCharacterSheet.stats.res || 0;
      clonedCharacterSheet.stats.wsd = clonedCharacterSheet.stats.wsd || 0;
      clonedCharacterSheet.stats.wil = clonedCharacterSheet.stats.wil || 0;
    }


    return { baseData: clonedCharacterSheet, race: race, skills: computedSkills, armi: armi, artefatti: artefatti, varie: varie, bonus: bonus, malus: malus, withHAvatar: withHAvatar, isMySheet: isMySheet };

  });

export const getBuyableSkils: any = createSelector(getSelectedPlayer, datasSelector.getSkills,
  (selectedCharacterSheet: CharacterSheetData, allSkills: Skill[]) => {
    const myRace: Race = selectedCharacterSheet.race;
    const ownedSkillIDS: Set<string> = new Set();

    for (let index: number = 0; index < selectedCharacterSheet.userSkills.length; index++) {
      const aOwnedSkill: CharacterSkill = selectedCharacterSheet.userSkills[index];

      // mondano quindi le skill possedute hanno un livelo e possono essere acquisite più volte (3 livello massimo)
      // aggiungo solo le skill che possiedo a livello 3 perchè le altre possono essre acquistate più volte
      if (myRace == Race.Mondano && aOwnedSkill.sLv < 3)
        continue;

      ownedSkillIDS.add(aOwnedSkill.uid);
    }

    const buyableSkills: Skill[] = allSkills.filter((aSkill: Skill) => aSkill.race == myRace && ownedSkillIDS.has(aSkill.uid) == false);
    const skillsToReturn: any[] = [];
    for (let index: number = 0; index < buyableSkills.length; index++) {
      const aSkill: Skill = buyableSkills[index];

      //gestione livello
      // character skills
      const characterSkills: CharacterSkill[] = selectedCharacterSheet.userSkills || [];

      // skill grade
      let skillGrade: number = 1;
      let labelSkill: string = " Livello di Razza: ";
      let btnText: string = "Acquista Abilità";

      if (selectedCharacterSheet.race == Race.Mondano) {
        //mondano
        const characterCurrentSkill: CharacterSkill = characterSkills.find(((aCSkill: CharacterSkill) => aCSkill.uid == aSkill.uid));
        let level: any = 0;
        if (Functions.IsUndefined(characterCurrentSkill) == false) {
          level = characterCurrentSkill.sLv;
        }


        skillGrade = parseInt(level.toString());
        labelSkill = " Livello: ";

        if (skillGrade >= 1)
          btnText = "Acquista Livello " + (skillGrade + 1).toString();
      }

      let skillGradeDescription: string[] = [];
      if (selectedCharacterSheet.race != Race.Mondano) {
        skillGradeDescription.push("V" + labelSkill + aSkill.descrLv5);
        skillGradeDescription.push("IV" + labelSkill + aSkill.descrLv4);
      }
      skillGradeDescription.push("III" + labelSkill + aSkill.descrLv3);
      skillGradeDescription.push("II" + labelSkill + aSkill.descrLv2);
      skillGradeDescription.push("I" + labelSkill + aSkill.descrLv1);
      skillGradeDescription = skillGradeDescription.reverse();

      const aSkillToDisplay: any = { uid: aSkill.uid, name: aSkill.name, descr: aSkill.description, grdDescr: skillGradeDescription, nb: aSkill.note, cost: aSkill.cost, buttonText: btnText };
      skillsToReturn.push(aSkillToDisplay);
    }

    skillsToReturn.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    return skillsToReturn;
  });



export const getOnlineUsersCount: any = createSelector(getUsersPresence,
  (userPresence: UserPresence[]) => {
    const OnlineUsers: UserPresence[] = userPresence.filter((aUSer: UserPresence) => Functions.IsNullOrUndefined(aUSer.connections) == false && Functions.IsNullOrUndefined(aUSer.state) == false);
    return OnlineUsers.length;
  });

export const getComputedUsersPresence: any = createSelector(getUsersPresence, layoutSelector.getStandardChatsList, layoutSelector.getSpecialChatsList,
  (userPresence: UserPresence[], chats: StandardChat[], specialChats: SpecialChat[]) => {

    const specialChatMap: Map<string, SpecialChat> = new Map();
    for (const aSpecialChat of specialChats) {
      specialChatMap.set(aSpecialChat.uid, aSpecialChat);
    }

    const positionMap: Map<string, PresencePlayerUI[]> = new Map();
    const allSecretChatsMap: Map<string, SecretChat> = new Map();
    for (let index: number = 0; index < userPresence.length; index++) {
      const currentUser: UserPresence = userPresence[index];

      // add secret chat to anagraphic
      if (Functions.IsNullOrUndefined(currentUser) == false && Functions.IsNullOrUndefined(currentUser.state) == false && Functions.IsNullOrUndefined(currentUser.state.myPrivateChat) == false) {
        allSecretChatsMap.set(currentUser.state.myPrivateChat.uid, currentUser.state.myPrivateChat);
      }

      if (Functions.IsNullOrUndefined(currentUser.connections) == false && Functions.IsNullOrUndefined(currentUser.state) == false) {
        const userToReturnUI: PresencePlayerUI = new PresencePlayerUI(currentUser);

        // let's normalize area
        let areasAsArray = Object.keys(currentUser.connections).map(function (key) {
          return currentUser.connections[key];
        });
        const removeDouble: Set<string> = new Set(areasAsArray);
        areasAsArray = Array.from(removeDouble.values());

        if (areasAsArray.length == 1) {
          // only one connection
          userToReturnUI.currentArea = areasAsArray[0];
        } else {
          // multiple connections
          const filteredAreas: string[] = areasAsArray.filter((aLocation: string) => aLocation != "@@HOME@@");
          if (filteredAreas.length == 0) {
            // all connections are in home
            userToReturnUI.currentArea = "@@HOME@@";
          } else {
            // the associated chat
            userToReturnUI.currentArea = filteredAreas[0];
          }
        }
        // toReturn.push(userToReturnUI);
        let arrayOfPlayers: PresencePlayerUI[] = positionMap.get(userToReturnUI.currentArea);
        if (Functions.IsNullOrUndefined(arrayOfPlayers)) {
          positionMap.set(userToReturnUI.currentArea, [userToReturnUI]);
        } else {
          arrayOfPlayers.push(userToReturnUI);
        }
      }
    }

    //compute standard chat
    const chatMaps: Map<string, StandardChat> = new Map();
    for (let index: number = 0; index < chats.length; index++) {
      const currentArea: StandardChat = chats[index];
      chatMaps.set(currentArea.uid, currentArea);
    }

    let toReturn: any[] = [];
    positionMap.forEach((value: PresencePlayerUI[], key: string) => {
      const anArea: StandardChat = chatMaps.get(key);

      // sort players inside an area
      value = value.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      if (Functions.IsNullOrUndefined(anArea) == false) {
        // standard chat
        const areaToReturn: any = {
          uid: anArea.uid,
          name: anArea.name,
          players: value,
          isPrivate: false
        }
        toReturn.push(areaToReturn);
      } else if (key == "@@HOME@@") {
        // home
        const areaToReturn: any = {
          uid: "",
          name: "In giro per Los Angeles",
          players: value,
          isPrivate: false
        }
        toReturn.push(areaToReturn);
      } else {
        // private chat
        const aSecretChat: SecretChat = allSecretChatsMap.get(key);
        // private chat founded
        if (Functions.IsNullOrUndefined(aSecretChat) == false) {
          const areaToReturn: any = {
            uid: aSecretChat.uid,
            name: aSecretChat.name + " (chat privata)",
            players: value,
            isPrivate: true,
            owner: aSecretChat.ownerUID
          }
          toReturn.push(areaToReturn);
        } else {
          const aSpecialChat: SpecialChat = specialChatMap.get(key);
          // private chat founded
          if (Functions.IsNullOrUndefined(aSpecialChat) == false) {
            const areaToReturn: any = {
              uid: aSpecialChat.uid,
              name: aSpecialChat.name + " (chat speciale)",
              players: value,
              isPrivate: true
            }
            toReturn.push(areaToReturn);
          }
        }
      }
    });

    // sort areas
    toReturn = toReturn.sort(function (a, b) {

      if (a.name == "In giro per Los Angeles") {
        return -1;
      }

      if (b.name == "In giro per Los Angeles") {
        return 1;
      }

      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    return toReturn;
  });

export const getUsersAnagrafica: any = createSelector(getUsersPresence,
  (userPresence: UserPresence[]) => {

    if (Functions.IsNullOrUndefined(userPresence))
      return [];

    let toReturn: any[] = [];
    for (let index: number = 0; index < userPresence.length; index++) {
      const currentUser: UserPresence = userPresence[index];
      if (Functions.IsNullOrUndefined(currentUser.state) == false) {
        const aUserState: PresencePlayerStatus = currentUser.state;

        const before3Month = new Date().getTime() - (3 * (24 * 3600 * 1000));
        const currentOADate = Functions.ToOADate(new Date());
        const lastMesageIsOld = ((aUserState.lastMessageSent - currentOADate) >= before3Month)

        const aUser: any = { uid: aUserState.playerUID, name: aUserState.name, sex: aUserState.sex, isBanned: aUserState.isBanned, isOldMessanger: lastMesageIsOld, race: aUserState.race, lvl: aUserState.lvl, isPng: aUserState.isPng };
        toReturn.push(aUser);
      }
    }

    // sort areas
    toReturn = toReturn.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    return toReturn;
  });



export const getAvailableSecretChats: any = createSelector(getUsersPresence, getCharacterSheet,
  (userPresence: UserPresence[], myCharacterSheet: CharacterSheetData) => {
    if (Functions.IsNullOrUndefined(myCharacterSheet) || Functions.IsNullOrUndefined(userPresence) || userPresence.length <= 0)
      return [];

    const myUID: string = myCharacterSheet.uid;
    const imSuperUser: boolean = myCharacterSheet.role < 3;

    const availableSecretChats: SecretChat[] = [];
    for (let index: number = 0; index < userPresence.length; index++) {
      const currentUserPresence: PresencePlayerStatus = userPresence[index].state;
      if (Functions.IsNullOrUndefined(currentUserPresence) || Functions.IsNullOrUndefined(currentUserPresence.myPrivateChat) || Functions.IsStringEmpty(currentUserPresence.myPrivateChat.uid))
        continue;

      const aPrivateChat: SecretChat = currentUserPresence.myPrivateChat;
      if (imSuperUser || aPrivateChat.ownerUID == myUID) {
        //i'm owner or super user
        availableSecretChats.push(aPrivateChat);
      } else {
        if (Functions.IsNullOrUndefined(aPrivateChat.accessibleByUIDs))
          continue;

        //i was invited
        const invited: string = aPrivateChat.accessibleByUIDs.find((anUID: string) => anUID == myUID);
        if (Functions.IsNullOrUndefined(invited) == false)
          availableSecretChats.push(aPrivateChat);
      }
    }

    return availableSecretChats;
  });

export const getAvailableSpecialChats: any = createSelector(layoutSelector.getSpecialChatsList, getCharacterSheet,
  (specialChats: SpecialChat[], myCharacterSheet: CharacterSheetData) => {
    if (Functions.IsNullOrUndefined(myCharacterSheet) || Functions.IsNullOrUndefined(specialChats) || specialChats.length <= 0)
      return [];

    // se sono gestore vedo tutte le chat
    const imGestore: boolean = myCharacterSheet.role < 2;
    if (imGestore) {
      return specialChats;
    }

    const myUID: string = myCharacterSheet.uid;
    const myRace: Race = myCharacterSheet.race;
    const myClan: string = myCharacterSheet.clan;
    const myCorp: string = myCharacterSheet.corp;
    const myLVL: number = myCharacterSheet.lvl;
    const myOffRole: Role = myCharacterSheet.role;

    const availableSpecialChats: SpecialChat[] = [];
    for (let index: number = 0; index < specialChats.length; index++) {
      const aSpecialChat: SpecialChat = specialChats[index];

      //#region -  check if i satisfy the requirements

      // excluded player
      if (Functions.IsNullOrUndefined(aSpecialChat.excludeCharacters) == false && aSpecialChat.excludeCharacters.length > 0) {
        const excludedPlayers: Set<string> = new Set(aSpecialChat.excludeCharacters);
        if (excludedPlayers.has(myUID))
          continue;
      }

      // included player
      if (Functions.IsNullOrUndefined(aSpecialChat.includeCharacters) == false && aSpecialChat.includeCharacters.length > 0) {
        const includededPlayers: Set<string> = new Set(aSpecialChat.includeCharacters);
        if (includededPlayers.has(myUID)) {
          availableSpecialChats.push(aSpecialChat);
          continue;
        }
      }

      // race
      if (Functions.IsNullOrUndefined(aSpecialChat.allowedToRace) == false && aSpecialChat.allowedToRace.length > 0) {
        const allowedRaceSet: Set<Race> = new Set(aSpecialChat.allowedToRace);
        if (allowedRaceSet.has(myRace) == false) {
          continue;
        }
      }

      // clan
      if (Functions.IsNullOrUndefined(aSpecialChat.allowedToClan) == false && aSpecialChat.allowedToClan.length > 0) {
        const allowedClanSet: Set<string> = new Set(aSpecialChat.allowedToClan);
        if (allowedClanSet.has(myClan) == false) {
          continue;
        }
      }

      // corp
      if (Functions.IsNullOrUndefined(aSpecialChat.allowedToCorp) == false && aSpecialChat.allowedToCorp.length > 0) {
        const allowedCorpSet: Set<string> = new Set(aSpecialChat.allowedToCorp);
        if (allowedCorpSet.has(myCorp) == false) {
          continue;
        }
      }

      // role
      if (Functions.IsNullOrUndefined(aSpecialChat.allowedOffRole) == false && aSpecialChat.allowedOffRole.length > 0) {
        const allowedRoleSet: Set<Role> = new Set(aSpecialChat.allowedOffRole);
        if (allowedRoleSet.has(myOffRole) == false) {
          continue;
        }
      }

      // lvl major or equal of
      if (Functions.IsNullOrUndefined(aSpecialChat.lvlMajorOrEqual) == false) {
        if (myLVL < aSpecialChat.lvlMajorOrEqual) {
          continue;
        }
      }

      // lvl minor or equal of
      if (Functions.IsNullOrUndefined(aSpecialChat.lvlMinorOrEqual) == false) {
        if (myLVL > aSpecialChat.lvlMinorOrEqual) {
          continue;
        }
      }
      //#endregion -  check if i satisfy the requirements

      availableSpecialChats.push(aSpecialChat);
    }

    return availableSpecialChats;
  });

export const getAvailableNotStandardChats: any = createSelector(getAvailableSecretChats, getAvailableSpecialChats,
  (secretChats: SecretChat[], specialChats: SpecialChat[]) => {
    let chatsToDisplay: any[] = [];
    chatsToDisplay = chatsToDisplay.concat(secretChats);
    chatsToDisplay = chatsToDisplay.concat(specialChats);

    chatsToDisplay = chatsToDisplay.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    return chatsToDisplay;
  });



export const getVoltiAnagrafica: any = createSelector(datasSelector.getVoltiList, getUsersPresence,
  (volti: Volto[], userPresence: UserPresence[]) => {

    if (Functions.IsNullOrUndefined(userPresence) || Functions.IsNullOrUndefined(volti))
      return [];

    const presenceCache: Map<string, PresencePlayerStatus> = new Map();
    for (let index: number = 0; index < userPresence.length; index++) {
      const currentUser: UserPresence = userPresence[index];
      if (Functions.IsNullOrUndefined(currentUser) == false && Functions.IsNullOrUndefined(currentUser.state) == false)
        presenceCache.set(currentUser.state.playerUID, currentUser.state);
    }

    let voltiMaschili: VoltoUI[] = [];
    let voltiFemminili: VoltoUI[] = [];
    for (let index: number = 0; index < volti.length; index++) {
      const currentVolto: Volto = volti[index];
      const voltoEntry: VoltoUI = new VoltoUI(currentVolto.uid, currentVolto.vName, currentVolto.vSur, currentVolto.vNick);

      let currentUser: PresencePlayerStatus;
      if (Functions.IsStringEmpty(currentVolto.uid) == false) {
        currentUser = presenceCache.get(currentVolto.uidPG);
        if (Functions.IsNullOrUndefined(currentUser) == false) {
          voltoEntry.uidPG = currentUser.playerUID;
          voltoEntry.pgName = currentUser.name;
        }
      }

      if (currentVolto.type == VoltoType.Femmina)
        voltiFemminili.push(voltoEntry);
      else if (currentVolto.type == VoltoType.Maschio)
        voltiMaschili.push(voltoEntry);
    }

    // sort volti
    voltiMaschili = voltiMaschili.sort(function (a, b) {
      var nameA = a.pgName.toLowerCase(), nameB = b.pgName.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    voltiFemminili = voltiFemminili.sort(function (a, b) {
      var nameA = a.pgName.toLowerCase(), nameB = b.pgName.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    return { voltiMaschili: voltiMaschili, voltiFemminili: voltiFemminili };
  });

export const getStaff: any = createSelector(getUsersPresence,
  (userPresence: UserPresence[]) => {

    let gestori: any[] = [];
    let admin: any[] = [];
    let master: any[] = [];

    let rRazza: any[] = [];
    let rClan: any[] = [];
    let rCorp: any[] = [];

    for (let index: number = 0; index < userPresence.length; index++) {
      const currentUser: UserPresence = userPresence[index];

      if (Functions.IsNullOrUndefined(currentUser.state) == false && (currentUser.state.role < 4 || currentUser.state.isCapoClan || currentUser.state.isCapoCorp || (Functions.IsNullOrUndefined(currentUser.state.isCapoRace) == false && currentUser.state.isCapoRace.length > 0))) {
        const userStaffEntry: any = { name: currentUser.state.name, uid: currentUser.state.playerUID };

        switch (currentUser.state.role) {
          case Role.gestore:
            gestori.push(userStaffEntry);
            break;

          case Role.admin:
            admin.push(userStaffEntry);
            break;

          case Role.master:
            master.push(userStaffEntry);
            break;

          default:
            break;
        }

        if (currentUser.state.isCapoClan) {
          rClan.push(userStaffEntry);
        }

        if (currentUser.state.isCapoCorp) {
          rCorp.push(userStaffEntry);
        }

        if (Functions.IsNullOrUndefined(currentUser.state.isCapoRace) == false && currentUser.state.isCapoRace.length > 0) {
          rRazza.push(userStaffEntry);
        }

      }
    }

    gestori = gestori.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    admin = admin.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    master = master.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    rRazza = rRazza.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    rClan = rClan.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    rCorp = rCorp.sort(function (a, b) {
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });



    return { gestori: gestori, admin: admin, master: master, rRazza: rRazza, rClan: rClan, rCorp: rCorp };
  });

export const getIsFirstEntrance: any = createSelector(getCharacterSheet,
  (myCharacterSheet: CharacterSheetData) => {
    if (Functions.IsNullOrUndefined(myCharacterSheet) || Functions.IsStringEmpty(myCharacterSheet.uid))
      return false;

    if (Functions.IsNullOrUndefined(myCharacterSheet.isFirstEntrance) || myCharacterSheet.isFirstEntrance == true) {
      return true;
    }

    return false;
  });
