import { Corp } from './../../../models/data/application.data';
import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { Clan } from "../../../models/data/application.data";

@Pipe({ name: 'clanPipe' })
export class ClanPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(uidClan: string, clanLvl: string, allClans: Clan[]) {
    const clanLvlNumber: number = parseInt(clanLvl);

    if (Functions.IsStringEmpty(uidClan) || Functions.IsNullOrUndefined(allClans) || allClans.length <= 0 || Functions.IsNullOrUndefined(clanLvlNumber))
      return "";
    else {
      let valueToReturn: string = "";
      const foundedClan: Clan = allClans.find((aClan: Clan) => aClan.uid == uidClan);

      if (Functions.IsNullOrUndefined(foundedClan))
        return "";

      valueToReturn = foundedClan.roles[clanLvlNumber - 1].iconUrl;
      return valueToReturn;
    }
  }
}

@Pipe({ name: 'clanTipPipe' })
export class ClanTipPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(uidClan: string, clanLvl: string, allClans: Clan[]) {
    const clanLvlNumber: number = parseInt(clanLvl);

    if (Functions.IsStringEmpty(uidClan) || Functions.IsNullOrUndefined(allClans) || allClans.length <= 0 || Functions.IsNullOrUndefined(clanLvlNumber))
      return "";
    else {
      let valueToReturn: string = "";
      const foundedClan: Clan = allClans.find((aClan: Clan) => aClan.uid == uidClan);

      if (Functions.IsNullOrUndefined(foundedClan))
        return "";

      valueToReturn = foundedClan.name + " - " + foundedClan.roles[clanLvlNumber - 1].name;
      return valueToReturn;
    }
  }
}


@Pipe({ name: 'corpPipe' })
export class CorpPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(uidCorp: string, corpLvl: string, allCorps: Corp[]) {
    const corpLvlNumber: number = parseInt(corpLvl);

    if (Functions.IsStringEmpty(uidCorp) || Functions.IsNullOrUndefined(allCorps) || allCorps.length <= 0 || Functions.IsNullOrUndefined(corpLvlNumber))
      return "";
    else {
      let valueToReturn: string = "";
      const foundedCorp: Corp = allCorps.find((aCorp: Corp) => aCorp.uid == uidCorp);

      if (Functions.IsNullOrUndefined(foundedCorp))
        return "";

      valueToReturn = foundedCorp.roles[corpLvlNumber - 1].iconUrl;
      return valueToReturn;
    }
  }
}

@Pipe({ name: 'corpTipPipe' })
export class CorpTipPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(uidCorp: string, corpLvl: string, allCorps: Corp[]) {
    const corpLvlNumber: number = parseInt(corpLvl);

    if (Functions.IsStringEmpty(uidCorp) || Functions.IsNullOrUndefined(allCorps) || allCorps.length <= 0 || Functions.IsNullOrUndefined(corpLvlNumber))
      return "";
    else {
      let valueToReturn: string = "";
      const foundedCorp: Corp = allCorps.find((aCorp: Corp) => aCorp.uid == uidCorp);

      if (Functions.IsNullOrUndefined(foundedCorp))
        return "";

      valueToReturn = foundedCorp.name + " - " + foundedCorp.roles[corpLvlNumber - 1].name;
      return valueToReturn;
    }
  }
}
