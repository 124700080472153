import { LocationRoot, RulesSezione, Volto, CharacterSheetData, Clan, Corp, BonusMalus, Skill, Item, MeteoDay, PrivateConversation, SpecialChat, StandardChat } from './application.data';

export class FirestoreBackupState {
  public locations: boolean = false;
  public rules: boolean = false;
  public volti: boolean = false;
  public settings: boolean = false;
  public users: boolean = false;
  public clans: boolean = false;
  public corps: boolean = false;
  public listOfUserNames: boolean = false;
  public bonusMalus: boolean = false;
  public skills: boolean = false;
  public items: boolean = false;
  public haveNewMessages: boolean = false;
  public meteoDays: boolean = false;
  public privateConversations: boolean = false;
  public specialChat: boolean = false;
  public standardChat: boolean = false;
}

export class FirestoreBackup {
  public locations: LocationRoot;
  public rules: RulesSezione[];
  public volti: Volto[];
  public settings: any[];
  public users: CharacterSheetData[];
  public clans: Clan[];
  public corps: Corp[];
  public listOfUserNames: any[];
  public bonusMalus: BonusMalus[];
  public skills: Skill[];
  public items: Item[];
  public haveNewMessages: any[];
  public meteoDays: MeteoDay[];
  public privateConversations: PrivateConversation[];
  public specialChat: SpecialChat[];
  public standardChat: StandardChat[];
}

export class FirebaseBackupState {
  public status: boolean = false;
  public chats: boolean = false;
}

export class FirebaseBackup {
  public status: any[];
  public chats: any[];
}
