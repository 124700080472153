import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSidenavModule,
  MatToolbarModule,
  MatSelectModule,
  MatTooltipModule
} from '@angular/material';
import { SpeechPipe, LocationPipe } from './pipe/speech.pipe';
import { StandardChatsListPipe } from './pipe/standardChatsListFilter.pipe';
import { ConcPrintPipe, DmgPrintPipe } from './pipe/concPrint.pipe';
import { ToolTipPipe } from './pipe/tollTip.pipe';
import { HourPipe, PMDatePipe, PMNamePipe, PMsFilterPipe, PMNamesPipe, PMCutPipe, PMCountPipe } from './pipe/pm.pipe';
import { RulesSortingPipe } from './pipe/rules.pipe';
import { FilterAnagraficaPipe, FilterAnagraficaVoltiPipe, FilterRacePipe, FilterClanPipe, FilterCorpPipe } from './pipe/uffici.pipe';
import { CorpTipPipe, CorpPipe, ClanTipPipe, ClanPipe } from './pipe/affiliation.pipe';
import { ChekStrWeakPipe } from './pipe/chekStrWeak.pipe';
import { KeeperPipe } from './pipe/keeper.pipe';
import { SanitizePipe } from './pipe/sanitize.pipe';
import { RaceIConPipe } from './pipe/raceIcon.pipe';

// Create Material Module with all we need
const MaterialModule: any = [
  MatDatepickerModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatRadioModule,
  MatToolbarModule,
  MatInputModule,
  MatSelectModule
];


@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    BrowserModule
  ],
  providers: [

  ],
  declarations: [
    SpeechPipe,
    StandardChatsListPipe,
    ConcPrintPipe,
    DmgPrintPipe,
    LocationPipe,
    ToolTipPipe,
    HourPipe,
    PMDatePipe,
    PMNamePipe,
    PMNamesPipe,
    PMsFilterPipe,
    PMCutPipe,
    RulesSortingPipe,
    PMCountPipe,
    FilterAnagraficaPipe,
    FilterAnagraficaVoltiPipe,
    FilterRacePipe,
    FilterClanPipe,
    FilterCorpPipe,
    ClanPipe,
    ClanTipPipe,
    CorpPipe,
    CorpTipPipe,
    ChekStrWeakPipe,
    KeeperPipe,
    SanitizePipe,
    RaceIConPipe
  ],
  exports: [
    SpeechPipe,
    StandardChatsListPipe,
    ConcPrintPipe,
    DmgPrintPipe,
    LocationPipe,
    ToolTipPipe,
    HourPipe,
    PMDatePipe,
    PMNamePipe,
    PMNamesPipe,
    PMsFilterPipe,
    PMCutPipe,
    RulesSortingPipe,
    PMCountPipe,
    FilterAnagraficaPipe,
    FilterAnagraficaVoltiPipe,
    FilterRacePipe,
    FilterClanPipe,
    FilterCorpPipe,
    ClanPipe,
    ClanTipPipe,
    CorpPipe,
    CorpTipPipe,
    ChekStrWeakPipe,
    KeeperPipe,
    SanitizePipe,
    RaceIConPipe
  ]
})

export class UtilitiesModule {
  constructor() { }
}
