import { PrivateConversation } from './../../../models/data/application.data';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as pmSelector from './../../../selectors/pm.selectors';
import * as layout from './../../../actions/layout';
import * as pm from './../../../actions/pm';
import { PmService } from '../../../services/pm.service';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';

@Component({
  selector: 'messaggi-ricevuti-box',
  templateUrl: './messaggi-ricevuti.component.html',
  styleUrls: ['./messaggi-ricevuti.component.less']
})
export class MessaggiRicevutiBoxComponent implements OnInit, OnDestroy {

  public initialGet: boolean = true;
  // public showOn: boolean = true;

  public allPMs: PrivateConversation[] = [];
  public myPMConversations$: Observable<PrivateConversation[]>;
  public allMyPMs$: Observable<PrivateConversation[]>;
  public myPMConversationsSubscription: Subscription;
  public isChecked: string[] = [];

  public hasNewMessages$: Observable<string[]>;
  public hasNewMessagesSubscription: Subscription;
  public hasNewMessages: any = {};

  public showOnPMs$: Observable<boolean>;

  constructor(private store: Store<fromRoot.State>, private cd: ChangeDetectorRef, public pmService: PmService) {
    this.myPMConversations$ = this.store.select(pmSelector.getFilteredPMs);
    this.allMyPMs$ = this.store.select(pmSelector.getAllPMs);
    this.hasNewMessages$ = this.store.select(pmSelector.getNewPM);
    this.showOnPMs$ = this.store.select(pmSelector.getShowOnPMs);
  }

  ngOnInit() {
    const self: this = this;

    this.myPMConversationsSubscription = this.myPMConversations$.subscribe((pms: PrivateConversation[]) => {

      if (Functions.IsNullOrUndefined(pms) == true || (pms.length <= 0 && self.initialGet == true)) {
        // i need to ask for data
        self.initialGet = false;
        self.allPMs = [];
        self.pmService.getMyConversation();
      } else {
        self.initialGet = false;
        self.sortPMs(pms);
      }
    });

    this.hasNewMessagesSubscription = this.hasNewMessages$.subscribe((hasNewPMs: string[]) => {
      for (let index: number = 0; index < hasNewPMs.length; index++) {
        const currentNewPM: string = hasNewPMs[index];
        self.hasNewMessages[currentNewPM] = currentNewPM;
      }

      self.sortPMs(self.allPMs);
    });
  }

  private sortPMs(pms: PrivateConversation[]) {
    const self: this = this;
    // sorting pm
    pms.sort(function (a: PrivateConversation, b: PrivateConversation) {
      if (self.hasNewMessages[a.cnvUID] != undefined && self.hasNewMessages[b.cnvUID] == undefined)
        return -1;

      if (self.hasNewMessages[a.cnvUID] == undefined && self.hasNewMessages[b.cnvUID] != undefined)
        return 1;

      const timePA: number = a.lMsgPTS;
      const timePB: number = b.lMsgPTS;

      if (Functions.IsNullOrUndefined(timePA) || Functions.IsNullOrUndefined(timePB)) {
        const timeA: any = a.lMsgTS;
        const timeB: any = b.lMsgTS;

        if (timeA.seconds > timeB.seconds)
          return -1

        if (timeA.seconds < timeB.seconds)
          return 1;

        if (timeA.nanoseconds > timeB.nanoseconds)
          return -1;

        if (timeA.nanoseconds < timeB.nanoseconds)
          return 1;

      } else {
        if (timePA > timePB)
          return -1

        if (timePA < timePA)
          return 1;
      }

      return 0; //default return value (no sorting)
    });

    this.allPMs = pms;
  }


  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.myPMConversationsSubscription) == false)
      this.myPMConversationsSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.hasNewMessagesSubscription) == false)
      this.hasNewMessagesSubscription.unsubscribe();
  }

  public changeValue(event: any, cnvUID: string) {
    if (event.checked) {
      this.isChecked.push(cnvUID);
    } else {
      this.isChecked = this.isChecked.filter((aValue: string) => aValue != cnvUID);
    }
  }

  public openConversation(cnv: PrivateConversation) {
    this.store.dispatch(new pm.SelectPM(cnv));
    this.store.dispatch(new layout.ToggleLeggiMessaggioBoxAction(true));
    this.pmService.setMessageAsReaded(cnv.cnvUID);
  }

  public scriviPM() {
    this.store.dispatch(new layout.ToggleCreaMessaggioBoxAction());
  }

  public cancellaPM() {
    if (this.isChecked.length <= 0)
      return;

    this.pmService.deleteMessagesForMe(this.isChecked);
  }

  public onScroll() {
    this.pmService.getMyConversation();
  }

  public toggleShowList() {
    const showOn: boolean = fromRoot.getState(this.store).pm.showOnPMs;
    this.store.dispatch(new pm.StoreShowOnPMs(!showOn));
  }

}
