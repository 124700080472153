import { CharacterSheetData } from '../models/data/application.data';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as fromRoot from '../reducers';
import { Store } from '@ngrx/store';
import { Injectable, ErrorHandler, Injector, InjectionToken } from '@angular/core';
import 'firebase/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';


@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  private errorLogger: AngularFireList<any>;

  constructor(
    private injector: Injector,
    private afdb: AngularFireDatabase,
    private store: Store<fromRoot.State>
  ) {
    this.errorLogger = this.afdb.list('errorLog');
  }

  public handleError(err: any): void {
    var rollbar = this.injector.get(RollbarService);
    rollbar.error(err.originalError || err);

    console.error("ERROR: ");
    console.error(err);

    // this.logError(err.originalError || err);
    let errorMessage = "Ops, qualcosa è andato storto. Fai uno screenshot di questo messaggio e fallo avere allo staff per autarli a risolvere il problema\n\n";
    errorMessage = errorMessage + err.message + "\n\n";
    errorMessage = errorMessage + err.stack.slice(0, 250);
    alert(errorMessage);
  }

  private logError(err: any) {
    const logID: string = Functions.CreateGuid();
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;

    if (Functions.IsNullOrUndefined(myCharacterSheet) == true) {
      return;
    }

    const errJSON: string = JSON.stringify(err);

    const logEntry: any = {
      userName: myCharacterSheet.name || "",
      userID: myCharacterSheet.uid || "",
      date: Functions.GetCurrentDate(),
      error: errJSON
    };

    this.errorLogger.set(logID, logEntry);
  }

}


//#region - Rollbar config ecc area
import * as Rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: 'ce2b44f666f14ee3b772ffc14820b38f',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
//#endregion
