import { LocalDataSource } from 'ng2-smart-table';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Clan, Role, AffiliationRole, UserPresence, PresencePlayerStatus, State } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-assegna-clan',
  templateUrl: 'cPanel-assegna-clan.component.html',
  styleUrls: ['cPanel-assegna-clan.component.less'],
})
export class CPanelAssegnaClanAPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelAssegnaClan";

  //#region - get users
  public usersPresence$: Observable<UserPresence[]>;
  private usersPresenceSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  //#endregion - get users

  //#region - get clans
  private clansCollection: AngularFirestoreCollection<Clan>;
  private clansCollectionObservable: Observable<Clan[]>;
  private clansSubscription: Subscription;
  public clans: Clan[];
  public clansMap: Map<string, Clan>;
  //#endregion - get clans

  //#region - other variables
  public selectedClanID: string;
  public selectedClan: Clan;
  public myCharacterSheet: CharacterSheetData;
  public mergedDataSource: LocalDataSource;
  public mergedData: any[] = [];
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Personaggio',
        editable: false,
        // valuePrepareFunction: (uid: string) => {
        //   if (Functions.IsNullOrUndefined(this.clansMap.get(uid)))
        //     return ""
        //   else
        //     return this.clansMap.get(uid).name;
        // },
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      },
      clanLvl: {
        title: 'Grado Clan',
        valuePrepareFunction: (uid: number) => {
          if (Functions.IsNullOrUndefined(this.selectedClan) || Functions.IsNullOrUndefined(this.selectedClan.roles) || this.selectedClan.roles.length <= 0) {
            return ""
          } else {
            const foundedRole: AffiliationRole = this.selectedClan.roles.find((aRole: AffiliationRole) => aRole.roleGrade == uid);
            if (Functions.IsNullOrUndefined(foundedRole))
              return "";

            return foundedRole.name;
          }
        },
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      }
    }
  };
  //#region - other variables

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private afdb: AngularFireDatabase,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.myCharacterSheet = fromRoot.getState(this.store).character.myCharacterData;

    if (this.myCharacterSheet.role >= Role.staff) {
      this.selectedClanID = this.myCharacterSheet.clan;
    }

    this.mergedDataSource = new LocalDataSource();

    //#region - get users
    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersPresenceSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }),
        first()
      ).subscribe((data: PresencePlayerStatus[]) => {
        // if (self.cPanelService.isAuditing)
        //   console.log('READ all users cpanel (n° ' + (data as any[]).length + ')');

        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.characters = data;
        self.createMergedUserItemsData();
      });
    //#endregion - get users

    //#region - get clans
    this.clansCollection = this.afs.collection<Clan>('clans');
    this.clansCollectionObservable = this.clansCollection.valueChanges();
    this.clansSubscription = this.clansCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all clans cpanel", self.moduleName, "clans", (data as any[]).length);
      }

      self.clans = data;
      self.clansMap = new Map();
      for (let index: number = 0; index < data.length; index++) {
        self.clansMap.set(data[index].uid, data[index]);

        if (Functions.IsStringEmpty(this.selectedClanID) == false && this.selectedClanID == data[index].uid) {
          this.selectedClan = data[index];
        }
      }
    });
    //#endregion - get clans

  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersPresenceSubscription) == false) {
      this.usersPresenceSubscription.unsubscribe();
    }
    if (Functions.IsNullOrUndefined(this.clansSubscription) == false) {
      this.clansSubscription.unsubscribe();
    }
  }

  public onClanChange(clan: any) {
    if (Functions.IsNullOrUndefined(clan) == true) {
      this.selectedClan = undefined;
      this.selectedClanID = "";
    } else {
      this.selectedClan = clan;
      this.selectedClanID = clan.uid;
    }

    this.createMergedUserItemsData();
    this.setUpGradeEditorList();
  }

  private createMergedUserItemsData() {

    if (Functions.IsNullOrUndefined(this.selectedClanID))
      return;

    if (Functions.IsNullOrUndefined(this.clansMap) || this.clansMap.size == 0)
      return;

    if (Functions.IsNullOrUndefined(this.characters) || this.characters.length == 0)
      return;

    let itemsToBeDisplayed: any[] = [];
    let charactersNotInClan: any[] = [];

    for (let index: number = 0; index < this.characters.length; index++) {
      const aCharacter: PresencePlayerStatus = this.characters[index];
      if (Functions.IsStringEmpty(aCharacter.clan) == false) {
        // is part of a clan let's check if it is of this clan
        if (aCharacter.clan == this.selectedClanID) {
          itemsToBeDisplayed.push(
            {
              uid: aCharacter.playerUID,
              name: aCharacter.name,
              clanLvl: aCharacter.clanLvl,
              uidClan: this.selectedClan.uid
            }
          );
        }
      } else {
        // it's not part of a clan
        charactersNotInClan.push(
          {
            value: aCharacter.playerUID,
            title: aCharacter.name
          }
        );
      }
    }
    this.mergedData = itemsToBeDisplayed;
    this.mergedDataSource.load(itemsToBeDisplayed);

    this.setUpUsersNameEditorList(charactersNotInClan);
  }

  private setUpUsersNameEditorList(charactersNotInClan: any[]) {
    // sorting alphabetically
    charactersNotInClan = charactersNotInClan.sort(function (a, b) {
      var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settings.columns.name.editor.config.list = charactersNotInClan;
    this.settings = Object.assign({}, this.settings);
  }

  private setUpGradeEditorList() {
    if (Functions.IsNullOrUndefined(this.selectedClan) || Functions.IsNullOrUndefined(this.selectedClan.roles)) {
      this.settings.columns.clanLvl.editor.config.list = [];
      this.settings = Object.assign({}, this.settings);
      return;
    }

    let roles: any[] = [];
    for (let index: number = 0; index < this.selectedClan.roles.length; index++) {
      const aRole: AffiliationRole = this.selectedClan.roles[index];
      const uiRole: any = {
        value: aRole.roleGrade,
        title: aRole.roleGrade + " - " + aRole.name
      }
      roles.push(uiRole);
    }

    // sorting alphabetically
    roles = roles.sort(function (a, b) {
      var lvlA = a.roleGrade, lvlB = b.roleGrade;
      if (lvlA < lvlB) //sort string ascending
        return -1;
      if (lvlA > lvlB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settings.columns.clanLvl.editor.config.list = roles;
    this.settings = Object.assign({}, this.settings);
  }

  public onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler elimnare il personaggio selezionato dal clan?')) {
      event.confirm.resolve();
      const selectedCharacterID: string = event.data.uid;

      const self: this = this;
      const playerToEditDoc = this.afs.doc<any>('users/' + selectedCharacterID);
      const playerToEditDocObservable = playerToEditDoc.valueChanges();
      const playerToEditDocSubscription = playerToEditDocObservable.pipe(first())
        .subscribe((selectedCharacter: CharacterSheetData) => {
          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
          }

          if (Functions.IsNullOrUndefined(selectedCharacter))
            return;

          selectedCharacter.clan = "";
          selectedCharacter.clanLvl = -1;
          selectedCharacter.clanMoney = 0;
          let updatedUserJsonData = JSON.parse(JSON.stringify(selectedCharacter));

          //#region - update player
          playerToEditDoc.update(updatedUserJsonData)
            .then(() => {
              self.updatePresence(selectedCharacter);
              self.toastr.success('Personaggio Rimosso!');
            })
            .catch((error: any) => {
              self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
            })
          //#endregion - update player
        });


      // const selectedCharacter: CharacterSheetData = this.characters.find((aCharacter: CharacterSheetData) => aCharacter.uid == selectedCharacterID);
      // if (Functions.IsNullOrUndefined(selectedCharacter))
      //   return;

      // selectedCharacter.clan = "";
      // selectedCharacter.clanLvl = -1;
      // let updatedUserJsonData = JSON.parse(JSON.stringify(selectedCharacter));

      // let self = this;
      // this.firestore.update('users/' + selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
      //   self.presenceService.updateAUserInfos(selectedCharacter, true);
      //   self.toastr.success('Personaggio Rimosso!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });

    } else {
      event.confirm.reject();
    }
  }

  public onCreateConfirm(event) {
    event.confirm.resolve();
    const selectedCharacterID: string = event.newData.name;

    const self: this = this;
    const playerToEditDoc = this.afs.doc<any>('users/' + selectedCharacterID);
    const playerToEditDocObservable = playerToEditDoc.valueChanges();
    const playerToEditDocSubscription = playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
        }

        if (Functions.IsNullOrUndefined(selectedCharacter) || Functions.IsStringEmpty(this.selectedClanID))
          return;

        let computedClanLvl = parseInt(event.newData.clanLvl);
        if (Functions.IsNullOrUndefined(computedClanLvl) || computedClanLvl == NaN)
          return;

        const selectedRole: AffiliationRole = self.selectedClan.roles.find((aRole: AffiliationRole) => aRole.roleGrade == computedClanLvl);
        if (Functions.IsNullOrUndefined(selectedRole) == false) {
          selectedCharacter.corpMoney = selectedRole.money;
        }

        selectedCharacter.clan = this.selectedClanID;
        selectedCharacter.clanLvl = computedClanLvl;
        let updatedUserJsonData = JSON.parse(JSON.stringify(selectedCharacter));

        //#region - update player
        playerToEditDoc.update(updatedUserJsonData)
          .then(() => {
            self.updatePresence(selectedCharacter);
            self.toastr.success('Personaggio Assegnato al clan!');
          })
          .catch((error: any) => {
            self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
          })
        //#endregion - update player
      });


    // const selectedCharacter: CharacterSheetData = this.characters.find((aCharacter: CharacterSheetData) => aCharacter.uid == selectedCharacterID);
    // if (Functions.IsNullOrUndefined(selectedCharacter) || Functions.IsStringEmpty(this.selectedClanID))
    //   return;

    // let computedClanLvl = parseInt(event.newData.clanLvl);
    // if (Functions.IsNullOrUndefined(computedClanLvl) || computedClanLvl == NaN)
    //   return;

    // selectedCharacter.clan = this.selectedClanID;
    // selectedCharacter.clanLvl = computedClanLvl;
    // let updatedUserJsonData = JSON.parse(JSON.stringify(selectedCharacter));

    // let self = this;
    // this.firestore.update('users/' + selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //   self.presenceService.updateAUserInfos(selectedCharacter, true);
    //   self.toastr.success('Personaggio Assegnato al clan!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public onEditConfirm(event) {
    event.confirm.resolve();
    const selectedCharacterID: string = event.data.uid;

    const self: this = this;
    const playerToEditDoc = this.afs.doc<any>('users/' + selectedCharacterID);
    const playerToEditDocObservable = playerToEditDoc.valueChanges();
    const playerToEditDocSubscription = playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
        }

        if (Functions.IsNullOrUndefined(selectedCharacter) || Functions.IsStringEmpty(this.selectedClanID))
          return;

        let computedClanLvl = parseInt(event.newData.clanLvl);
        if (Functions.IsNullOrUndefined(computedClanLvl) || computedClanLvl == NaN)
          return;

        const selectedRole: AffiliationRole = self.selectedClan.roles.find((aRole: AffiliationRole) => aRole.roleGrade == computedClanLvl);
        if (Functions.IsNullOrUndefined(selectedRole) == false) {
          selectedCharacter.corpMoney = selectedRole.money;
        }

        selectedCharacter.clanLvl = computedClanLvl;
        let updatedUserJsonData = JSON.parse(JSON.stringify(selectedCharacter));

        //#region - update player
        playerToEditDoc.update(updatedUserJsonData)
          .then(() => {
            self.updatePresence(selectedCharacter);
            self.toastr.success('Personaggio Assegnato al clan!');
          })
          .catch((error: any) => {
            self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
          })
        //#endregion - update player
      });

    // const selectedCharacter: CharacterSheetData = this.characters.find((aCharacter: CharacterSheetData) => aCharacter.uid == selectedCharacterID);
    // if (Functions.IsNullOrUndefined(selectedCharacter) || Functions.IsStringEmpty(this.selectedClanID))
    //   return;

    // let computedClanLvl = parseInt(event.newData.clanLvl);
    // if (Functions.IsNullOrUndefined(computedClanLvl) || computedClanLvl == NaN)
    //   return;

    // selectedCharacter.clanLvl = computedClanLvl;
    // let updatedUserJsonData = JSON.parse(JSON.stringify(selectedCharacter));

    // let self = this;
    // this.firestore.update('users/' + selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //   self.presenceService.updateAUserInfos(selectedCharacter, true);
    //   self.toastr.success('Personaggio Assegnato al clan!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  private updatePresence(characterData: CharacterSheetData) {
    //#region - update presence status
    const oldPresenceStatus: PresencePlayerStatus = this.characters.find((aCharacterStatus: PresencePlayerStatus) => aCharacterStatus.playerUID == characterData.uid);
    let lastAccess: string = Functions.GetCurrentDate();
    let ip: string = "";
    if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
      lastAccess = (oldPresenceStatus.lastAccess || Functions.GetCurrentDate());
    }
    if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
      ip = (oldPresenceStatus.ip || "");
    }
    const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterData.uid, characterData.race, characterData.role, characterData.MAvatar, characterData.name, characterData.sur, characterData.nick, lastAccess, characterData.sex, (characterData.lvl || 1), (characterData.state || State.libero), (characterData.stateText || ""), characterData.isPng, characterData.isBanned, characterData.lastMessageSent, characterData.myPrivateChat, (characterData.clan || ""), characterData.clanLvl, (characterData.corp || ""), characterData.corpLvl, characterData.isActive, characterData.isCapoClan, characterData.isCapoCorp, characterData.isCapoRace, ip);
    const presenceStatusJSON = JSON.parse(JSON.stringify(presenceSatus));

    const userPresenceDoc = this.afdb.object('status/' + characterData.uid + '/state');
    userPresenceDoc.update(presenceStatusJSON);
    //#endregion - update presence status
  }
}
