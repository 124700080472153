import { CharacterSheetData, Sex, VoltoUI, Volto, VoltoType, DBVersionType } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import * as characterSelector from './../../../selectors/character.selectors';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { FirestoreService } from '../../../services/firestore.service';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { IncrementalService } from '../../../services/incremental.service';
import { first } from 'rxjs/operators';
import { CacheService } from 'src/app/services/cache.service';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

// Utilities
@Component({
  selector: 'uffici-cambia-volto-component',
  templateUrl: './uffici-cambia-volto.component.html',
  styleUrls: ['./uffici-cambia-volto.component.less']
})

export class UfficiCambiaVoltoComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly moduleName: string = "UfficiCambiaVoltoComponent";

  voltoForm = new FormGroup({});
  private allVolti$: Observable<any>;
  voltiSubscription: Subscription;
  alreadyUsed: boolean = false;
  alreadyChanged: boolean = false;
  subscription: Subscription;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private firestoreService: FirestoreService,
    private afs: AngularFirestore,
    private incrementalService: IncrementalService,
    private cacheService: CacheService,
    private debugLogger: DebugLoggerService
  ) {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.volti, true);


    this.allVolti$ = this.store.select(characterSelector.getVoltiAnagrafica);
  }

  public ngOnInit() {
    this.buildForm();
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    const self: this = this;
    this.voltiSubscription = this.allVolti$.subscribe((volti: any) => {
      if (Functions.IsNullOrUndefined(volti) || Functions.IsNullOrUndefined(volti.voltiMaschili) || Functions.IsNullOrUndefined(volti.voltiFemminili)) {
        self.alreadyChanged = false;
        return;
      }

      let voltiToScan: VoltoUI[] = [];
      if (myCharacterSheet.sex == Sex.male) {
        voltiToScan = volti.voltiMaschili;
      } else if (myCharacterSheet.sex == Sex.female) {
        voltiToScan = volti.voltiFemminili;
      }

      if (voltiToScan.length <= 0) {
        self.alreadyChanged = false;
        return;
      }

      const myVolto: VoltoUI = voltiToScan.find((aVolto: VoltoUI) => aVolto.uidPG == myCharacterSheet.uid);
      if (Functions.IsNullOrUndefined(myVolto)) {
        self.alreadyChanged = false;
      } else {
        self.alreadyChanged = true;
      }

    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.voltiSubscription) == false) {
      this.voltiSubscription.unsubscribe();
    }
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleCambiaVoltoAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

  // used to create form structure
  private buildForm() {
    this.voltoForm = this.fb.group({
      'vName': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)
      ]],
      'vSur': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)
      ]],
    });
  }

  public sendVolto() {
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    const vName: string = this.voltoForm.value['vName'];
    const vSur: string = this.voltoForm.value['vSur'];

    if (Functions.IsStringEmpty(vName) || Functions.IsStringEmpty(vSur))
      return;

    if (Functions.IsNullOrUndefined(this.subscription) == false)
      this.subscription.unsubscribe();

    const self: this = this;

    const voltiCollection: AngularFirestoreCollection<Volto> = this.afs.collection<Volto>('volti');
    const voltiCollectionObservable: Observable<Volto[]> = voltiCollection.valueChanges();
    this.subscription = voltiCollectionObservable.pipe(first()).subscribe((data: Volto[]) => {
      // this.subscription = this.firestore.read('volti').skip(1).pipe(first())
      //   .subscribe((data: Volto[]) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, "READ all volti", self.moduleName, "volti", data.length);
      }

      const voltoAlreadyInUse: Volto = data.find((aVolto: Volto) => (aVolto.vName.toLowerCase() == vName.toLowerCase()) && (aVolto.vSur.toLowerCase() == vSur.toLowerCase()));
      if (Functions.IsNullOrUndefined(voltoAlreadyInUse)) {
        // not used yet
        let newVoltoData: Volto = new Volto();
        newVoltoData.uid = Functions.CreateGuid();
        newVoltoData.uidPG = myCharacterSheet.uid
        newVoltoData.vName = vName
        newVoltoData.vSur = vSur
        newVoltoData.vNick = "";

        if (myCharacterSheet.sex == Sex.male) {
          newVoltoData.type = VoltoType.Maschio;
        } else if (myCharacterSheet.sex == Sex.female) {
          newVoltoData.type = VoltoType.Femmina;
        }

        let newVoltoDataJSON = JSON.parse(JSON.stringify(newVoltoData));
        voltiCollection.doc(newVoltoDataJSON.uid).set(newVoltoDataJSON);

        self.alreadyChanged = true;

        // add cache update
       self.cacheService.updateVoltiCache(newVoltoDataJSON.uid);

        // self.firestore.write('volti/' + newVoltoDataJSON.uid, newVoltoDataJSON);
      } else {
        // already in use
        self.alreadyUsed = true;
      }

    });
  }

}
