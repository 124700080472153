import { createSelector } from 'reselect';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import { State } from '../reducers/index';
import * as fromLayout from '../reducers/layout';
import { StandardChat, chatGroups } from '../models/data/application.data';

/**
 * Layout
 */
export const ModuleName: string = "LayoutSelector";
export const getLayoutState: (state: State) => fromLayout.State = (state: State) => state.layout;
export const getLayoutUIState: any = createSelector(getLayoutState, fromLayout.getLayoutUIState);
export const getCPanelState: any = createSelector(getLayoutState, fromLayout.getCPanelState);
export const getMapUIState: any = createSelector(getLayoutState, fromLayout.getMapUIState);
export const getMapSelectedArea: any = createSelector(getLayoutState, fromLayout.getMapSelectedArea);
export const getisCharacterSheetOpen: any = createSelector(getLayoutState, fromLayout.getisCharacterSheetOpen);

export const getisUfficiOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiOpen);
export const getisAnagrafeOpen: any = createSelector(getLayoutState, fromLayout.getisAnagrafeOpen);
export const getisVoltiTypeOpen: any = createSelector(getLayoutState, fromLayout.getisVoltiTypeOpen);
export const getisCambiaVoltoOpen: any = createSelector(getLayoutState, fromLayout.getisCambiaVoltoOpen);
export const getisCambiaNomeOpen: any = createSelector(getLayoutState, fromLayout.getisCambiaNomeOpen);
export const getisUfficiStaffOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiStaffOpen);
export const getisBankOpen: any = createSelector(getLayoutState, fromLayout.getisBankOpen);
export const getisUfficiSecretChatsOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiSecretChatsOpen);
export const getisUfficiCreaSecretChatOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiCreaSecretChatOpen);
export const getisUfficiMappaOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiMappaOpen);
export const getisUfficiRazzeOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiRazzeOpen);
export const getisUfficiClanOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiClanOpen);
export const getisUfficiCorpOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiCorpOpen);
export const getisUfficiCreditsOpen: any = createSelector(getLayoutState, fromLayout.getisUfficiCreditsOpen);

export const getisPresentiOpen: any = createSelector(getLayoutState, fromLayout.getisPresentiOpen);
export const getisChatActionBoxOpen: any = createSelector(getLayoutState, fromLayout.getisChatActionBoxOpen);
export const getisChatInstructionBoxOpen: any = createSelector(getLayoutState, fromLayout.getisChatInstructionBoxOpen);
export const getisChatSkillBoxOpen: any = createSelector(getLayoutState, fromLayout.getisChatSkillBoxOpen);
export const getisChatItemBoxOpen: any = createSelector(getLayoutState, fromLayout.getisChatItemBoxOpen);
export const getisChatSussurroEnable: any = createSelector(getLayoutState, fromLayout.getisChatSussurroEnable);
export const getisChatFateEnable: any = createSelector(getLayoutState, fromLayout.getisChatFateEnable);
export const getisChatDiceBoxOpen: any = createSelector(getLayoutState, fromLayout.getisChatDiceBoxOpen);
export const getisChatDiceResponseBoxOpen: any = createSelector(getLayoutState, fromLayout.getisChatDiceResponseBoxOpen);
export const getIsChatDiceDmgBoxOpen: any = createSelector(getLayoutState, fromLayout.getIsChatDiceDmgBoxOpen);
export const getStandardChatsList: any = createSelector(getLayoutState, fromLayout.getStandardChatsList);
export const getSpecialChatsList: any = createSelector(getLayoutState, fromLayout.getSpecialChatsList);
export const getisChatSchedaTatticaOpen: any = createSelector(getLayoutState, fromLayout.getisChatSchedaTatticaOpen);
export const getisGestisciChatPrivataOpen: any = createSelector(getLayoutState, fromLayout.getisGestisciChatPrivataOpen);
export const getScrollTopFloatingToggle: any = createSelector(getLayoutState, fromLayout.getScrollTopFloatingToggle);

export const getIsCreaMessaggioOpen: any = createSelector(getLayoutState, fromLayout.getIsCreaMessaggioOpen);
export const getIsMessaggiRicevutiOpen: any = createSelector(getLayoutState, fromLayout.getIsMessaggiRicevutiOpen);
export const getIsLeggiMessaggioOpen: any = createSelector(getLayoutState, fromLayout.getIsLeggiMessaggioOpen);

export const getIsRulesOpen: any = createSelector(getLayoutState, fromLayout.getIsRulesOpen);
export const getIsMeteoOpen: any = createSelector(getLayoutState, fromLayout.getIsMeteoOpen);
export const getIsLocationsOpen: any = createSelector(getLayoutState, fromLayout.getIsLocationsOpen);
export const getIsEventsCalendarOpen: any = createSelector(getLayoutState, fromLayout.getIsEventsCalendarOpen);

export const getIsChatModalOpen: any = createSelector(getisChatActionBoxOpen, getisChatDiceBoxOpen, getisChatDiceResponseBoxOpen, getIsChatDiceDmgBoxOpen, getisChatInstructionBoxOpen, getisChatSkillBoxOpen, getisChatItemBoxOpen,
  (isChatActionBoxOpen: boolean, isDiceBoxOpen: boolean, isDiceResponseBoxOpen: boolean, isDiceDmgBoxOpen: boolean, isInstructionBoxOpen: boolean, isSkillBoxOpen: boolean, isItemBoxOpen: boolean) => {
    return isChatActionBoxOpen || isDiceBoxOpen || isDiceResponseBoxOpen || isDiceDmgBoxOpen || isInstructionBoxOpen || isSkillBoxOpen || isItemBoxOpen;
  });

export const getIsSubUfficiOpen: any = createSelector(getisBankOpen, getisUfficiCreditsOpen, getisUfficiMappaOpen, getisUfficiSecretChatsOpen, getisUfficiCreaSecretChatOpen, getisCambiaNomeOpen, getisCambiaVoltoOpen, getisUfficiCorpOpen, getisUfficiClanOpen, getisUfficiRazzeOpen, getisAnagrafeOpen, getisVoltiTypeOpen,
  (isBankOpen: boolean, iscreditsOpen: boolean, isUfficiMappaOpen: boolean, isUfficiSecretChatOpen: boolean, isUfficiCreaSecretChatOpen: boolean, isCambiaNomeOpen: boolean, isCambiaVoltoOpen: boolean, isCorporazioneOpen: boolean, isClanOpen: boolean, isRazzeOpen: boolean, isAnagrafeOpen: boolean, isVoltiOpen: boolean) => {
    return isBankOpen || iscreditsOpen || isUfficiMappaOpen || isUfficiSecretChatOpen || isUfficiCreaSecretChatOpen || isCambiaNomeOpen || isCambiaVoltoOpen || isCorporazioneOpen || isClanOpen || isRazzeOpen || isAnagrafeOpen || isVoltiOpen;
  });

export const getIsHomeModalOpen: any = createSelector(getIsCreaMessaggioOpen, getIsMessaggiRicevutiOpen, getIsLeggiMessaggioOpen, getIsEventsCalendarOpen, getIsMeteoOpen, getIsLocationsOpen, getIsRulesOpen, getisUfficiOpen, getIsSubUfficiOpen, getisUfficiStaffOpen, getisPresentiOpen,
  (isMessaggiOpen: boolean, isMessaggiRicevutiOpen: boolean, isLeggiMessaggioOpen: boolean, isEventsCalendarOpen: boolean, isMeteoOpen: boolean, isLocationOpen: boolean, isRulesOpen: boolean, isUfficiOpen: boolean, isSubUfficiOpen: boolean, isStaffOpen: boolean, isPresentiOpen: boolean) => {
    return isMessaggiOpen || isMessaggiRicevutiOpen || isLeggiMessaggioOpen || isEventsCalendarOpen || isMeteoOpen || isLocationOpen || isRulesOpen || isUfficiOpen || isSubUfficiOpen || isStaffOpen || isPresentiOpen;
  });

export const getStandardChatsDividedByArea: any = createSelector(getStandardChatsList,
  (chatList: StandardChat[]) => {
    if (Functions.IsNullOrUndefined(chatList))
      return [];

    const the_Valley_chats: StandardChat[] = [];
    const north_LA_chats: StandardChat[] = [];
    const west_Side_chats: StandardChat[] = [];
    const hollywood_chats: StandardChat[] = [];
    const downtown_chats: StandardChat[] = [];
    const northeast_chats: StandardChat[] = [];
    const south_LA_chats: StandardChat[] = [];
    const south_Bay_chats: StandardChat[] = [];

    for (let index: number = 0; index < chatList.length; index++) {
      const aChat: StandardChat = chatList[index];
      switch (parseInt(aChat.group.toString())) {
        case chatGroups.The_Valley:
          the_Valley_chats.push(aChat);
          break;
        case chatGroups.Downtown:
          downtown_chats.push(aChat);
          break;
        case chatGroups.South_Bay:
          south_Bay_chats.push(aChat);
          break;
        case chatGroups.Hollywood:
          hollywood_chats.push(aChat);
          break;
        case chatGroups.Northeast:
          northeast_chats.push(aChat);
          break;
        case chatGroups.North_LA:
          north_LA_chats.push(aChat);
          break;
        case chatGroups.South_LA:
          south_LA_chats.push(aChat);
          break;
        case chatGroups.West_Side:
          west_Side_chats.push(aChat);
          break;

        default:
          break;
      }
    }

    the_Valley_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });

    north_LA_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });

    west_Side_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });

    hollywood_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });

    downtown_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });

    northeast_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });

    south_LA_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });

    south_Bay_chats.sort(function (a: StandardChat, b: StandardChat) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });


    const the_Valley: any = { id: 0, name: "The Valley", chats: the_Valley_chats };
    const north_LA: any = { id: 1, name: "North L.A.", chats: north_LA_chats };
    const west_Side: any = { id: 2, name: "West Side", chats: west_Side_chats };
    const hollywood: any = { id: 3, name: "Hollywood", chats: hollywood_chats };
    const downtown: any = { id: 4, name: "Downtown", chats: downtown_chats };
    const northeast: any = { id: 5, name: "Northeast L.A.", chats: northeast_chats };
    const south_LA: any = { id: 6, name: "South L.A.", chats: south_LA_chats };
    const south_Bay: any = { id: 7, name: "South Bay", chats: south_Bay_chats };

    const allMaps: any[] = [];
    allMaps.push(the_Valley);
    allMaps.push(north_LA);
    allMaps.push(west_Side);
    allMaps.push(hollywood);
    allMaps.push(downtown);
    allMaps.push(northeast);
    allMaps.push(south_LA);
    allMaps.push(south_Bay);

    return allMaps;

  });
