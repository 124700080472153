import { Race, PrivateMessage, PrivateConversation, OnOff } from './../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { CharacterSheetData } from '../../../models/data/application.data';
import { Observable } from 'rxjs';
import * as characterSelector from './../../../selectors/character.selectors';
import { LayoutState } from '../../../modules/utilities/utilities.constants';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from "@angular/fire/auth"
import * as firebase from 'firebase/app';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';

@Component({
  selector: 'sign-up-componet',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less']
})
export class SignupComponent implements OnInit, OnDestroy {
  // signupForm: FormGroup;
  signupForm = new FormGroup({});
  public subscriptionPhaseNumber: number = 0;
  public accept: boolean = false;
  public notAccept: boolean = false;
  public myCharacterSheet$: Observable<CharacterSheetData>;

  private disclaimerSubscription: Subscription;
  public disclaimer: string = "";

  regSubscription: Subscription;
  regValue: boolean = false;

  welcomePMSubscription: Subscription;
  tempUpdateSubscription: Subscription;
  raceFormSubscription: Subscription;
  startingPMdata: string;


  form: any = {
    components: []
  };

  submission: any = {
    data: {}
  };

  questionObject: string = "";

  constructor(
    private fb: FormBuilder,
    private afAuth: AngularFireAuth,
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private toastr: ToastrService
  ) {

    let self = this;
    const discalimerDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/disclaimer');
    const discalimerDocObservable: Observable<any> = discalimerDoc.valueChanges();
    this.disclaimerSubscription = discalimerDocObservable.pipe(first()).subscribe((data) => {
      // this.disclaimerSubscription = this.firestore.read('settings/disclaimer').skip(1).subscribe((data) => {
      if (Functions.IsNullOrUndefined(data) == false)
        self.disclaimer = data.value;
    });

    const regBlockedDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/regBlocked');
    const regBlockedDocObservable: Observable<any> = regBlockedDoc.valueChanges();
    this.regSubscription = regBlockedDocObservable.pipe(first()).subscribe((data) => {
      // this.regSubscription = this.firestore.read('settings/regBlocked').skip(1).subscribe((data) => {
      if (Functions.IsNullOrUndefined(data) == false)
      self.regValue = data.value;
    });

    const welcomePMDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/startingPM');
    const welcomePMObservable: Observable<any> = welcomePMDoc.valueChanges();
    this.welcomePMSubscription = welcomePMObservable.pipe(first()).subscribe((data) => {
      // this.welcomePMSubscription = this.firestore.read('settings/startingPM').skip(1).subscribe((data) => {
      if (Functions.IsNullOrUndefined(data) == false)
        self.startingPMdata = data.value;
    });

  }

  ngOnInit() {
    this.buildForm();
    this.myCharacterSheet$ = this.store.select(characterSelector.getCharacterSheet);
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.disclaimerSubscription) == false)
      this.disclaimerSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.regSubscription) == false)
      this.regSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.welcomePMSubscription) == false)
      this.welcomePMSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.raceFormSubscription) == false)
      this.raceFormSubscription.unsubscribe();
  }

  backToHomeEsterna() {
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.HomeEsterna));
  }

  // accepting therm and condition
  accepting() {
    this.accept = !this.accept;
  }

  // used to move between signup phases
  proceed() {
    let toPhase: number = this.subscriptionPhaseNumber + 1;
    switch (toPhase) {
      case 0:
        break;
      case 1:
        if (this.accept == true)
          this.subscriptionPhaseNumber = 1;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        break;
      case 2:
        //#region - mail check
        if (this.signupForm.controls.email.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }

        if (this.signupForm.controls.remail.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }

        if (this.signupForm.value['email'] != this.signupForm.value['remail']) {
          let error: ValidationErrors = { key: "notMatching" }
          this.signupForm.controls.email.setErrors(error);
          this.signupForm.controls.remail.setErrors(error);
          this.subscriptionPhaseNumber = 1;
          break;
        }
        //#endregion - mail check

        //#region - password check
        if (this.signupForm.controls.password.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }

        if (this.signupForm.controls.repassword.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }

        if (this.signupForm.value['password'] != this.signupForm.value['repassword']) {
          let error: ValidationErrors = { key: "notMatching" }
          this.signupForm.controls.password.setErrors(error);
          this.signupForm.controls.repassword.setErrors(error);
          this.subscriptionPhaseNumber = 1;
          break;
        }
        //#endregion - password check

        //#region - datas check
        if (this.signupForm.controls.nome.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }

        if (this.signupForm.controls.cognome.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }

        if (this.signupForm.controls.razza.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }

        if (this.signupForm.controls.sesso.invalid) {
          this.subscriptionPhaseNumber = 1;
          break;
        }
        //#endregion - datas check

        // check if user name already in use
        const self: this = this;
        const pgName: string = (this.signupForm.value['nome'] as string).toLowerCase().trim();
        const nameDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('listOfUserNames/' + pgName);
        const nameDocObservable: Observable<any> = nameDoc.valueChanges();
        let nameSubscription: Subscription = nameDocObservable.pipe(first()).subscribe((data) => {
          // let nameSubscription: Subscription = this.firestore.read('listOfUserNames/' + (this.signupForm.value['nome'] as string).toLowerCase()).skip(1)
          // .subscribe((data) => {

          if (Functions.IsNullOrUndefined(data) === false) {
            //name already in use
            self.signupForm.controls.nome.setErrors(["nome già in useo"]);
            self.toastr.error('Nome già in uso, scegli un altro nome per il tuo personaggio.', 'Errore', {
              timeOut: 10000,
              extendedTimeOut: 4000,
              positionClass: "toast-top-center"
            });
            this.subscriptionPhaseNumber = 1;
          } else {
            const razza: string = Race[parseInt(this.signupForm.value['razza'])];
            const raceFormDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('raceForms/' + razza);
            const raceFormDocObservable: Observable<any> = raceFormDoc.valueChanges();
            const self: this = this;
            this.raceFormSubscription = raceFormDocObservable.pipe(first()).subscribe((data) => {
              // this.disclaimerSubscription = this.firestore.read('settings/disclaimer').skip(1).subscribe((data) => {
              if (Functions.IsNullOrUndefined(data) == false) {
                if (
                  Functions.IsNullOrUndefined(data.form) == false
                  && Functions.IsNullOrUndefined(data.form.components) == false
                  && data.form.components.length > 0
                ) {
                  self.form = data.form;
                  this.subscriptionPhaseNumber = 2;
                } else {
                  //no form, just sign-up
                  this.signUpAndproceed();
                }
              }
            });


            // this.subscriptionPhaseNumber = 2;
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          }
        })

        // const razza: string = Race[parseInt(this.signupForm.value['razza'])];
        // const raceFormDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('raceForms/' + razza);
        // const raceFormDocObservable: Observable<any> = raceFormDoc.valueChanges();
        // const self: this = this;
        // this.raceFormSubscription = raceFormDocObservable.pipe(first()).subscribe((data) => {
        //   // this.disclaimerSubscription = this.firestore.read('settings/disclaimer').skip(1).subscribe((data) => {
        //   if (Functions.IsNullOrUndefined(data) == false) {
        //     self.form = data.form;
        //   } else {
        //     self.back();
        //   }
        // });


        // // this.subscriptionPhaseNumber = 2;
        // document.body.scrollTop = 0; // For Safari
        // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        break;
      case 3:
        this.subscriptionPhaseNumber = 3;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        break;
    }
  }

  back() {
    let toPhase: number = this.subscriptionPhaseNumber - 1;
    if (toPhase < 0)
      toPhase = 0;
    switch (toPhase) {
      case 0:
        this.subscriptionPhaseNumber = 0;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        break;
      case 1:
        this.subscriptionPhaseNumber = 1;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        break;
      case 2:
        // const raceFormDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('raceForms/' + this.signupForm.controls.razza);
        // const raceFormDocObservable: Observable<any> = raceFormDoc.valueChanges();
        // const self: this = this;
        // this.raceFormSubscription = raceFormDocObservable.pipe(first()).subscribe((data) => {
        //   // this.disclaimerSubscription = this.firestore.read('settings/disclaimer').skip(1).subscribe((data) => {
        //   if (Functions.IsNullOrUndefined(data) == false)
        //     self.form = data.form;
        // });


        this.subscriptionPhaseNumber = 2;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        break;
      case 3:
        break;
    }
  }

  signUpAndproceed() {
    let self = this;

    //#region - mail check
    if (this.signupForm.controls.email.invalid) {
      return;
    }

    if (this.signupForm.controls.remail.invalid) {
      return;
    }

    if (this.signupForm.value['email'] != this.signupForm.value['remail']) {
      let error: ValidationErrors = { key: "notMatching" }
      this.signupForm.controls.email.setErrors(error);
      this.signupForm.controls.remail.setErrors(error);
      return;
    }
    //#endregion - mail check

    //#region - password check
    if (this.signupForm.controls.password.invalid) {
      return;
    }

    if (this.signupForm.controls.repassword.invalid) {
      return;
    }

    if (this.signupForm.value['password'] != this.signupForm.value['repassword']) {
      let error: ValidationErrors = { key: "notMatching" }
      this.signupForm.controls.password.setErrors(error);
      this.signupForm.controls.repassword.setErrors(error);
      return;
    }
    //#endregion - password check

    //#region - datas check
    if (this.signupForm.controls.nome.invalid) {
      return;
    }

    if (this.signupForm.controls.cognome.invalid) {
      return;
    }

    if (this.signupForm.controls.razza.invalid) {
      return;
    }

    if (this.signupForm.controls.sesso.invalid) {
      return;
    }
    //#endregion - datas check

    // check if user name already in use
    const pgName: string = (this.signupForm.value['nome'] as string).toLowerCase().trim();
    const nameDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('listOfUserNames/' + pgName);
    const nameDocObservable: Observable<any> = nameDoc.valueChanges();
    let nameSubscription: Subscription = nameDocObservable.pipe(first()).subscribe((data) => {
      // let nameSubscription: Subscription = this.firestore.read('listOfUserNames/' + (this.signupForm.value['nome'] as string).toLowerCase()).skip(1)
      // .subscribe((data) => {

      if (Functions.IsNullOrUndefined(data)) {
        //name not in use
        nameSubscription.unsubscribe();
        self.afAuth.auth.languageCode = "it";

        self.afAuth.auth.createUserWithEmailAndPassword(self.signupForm.value['email'], self.signupForm.value['password'])
          .then((infoes) => {
            //TESTARE INFOES DI RITORNO
            infoes.user.sendEmailVerification(); //TODO: TESTARE!!!
            self.updateUserData(infoes.user)
          })
          .catch((error: any) => {
            if (error.code == "auth/email-already-in-use") {
              self.signupForm.controls.email.setErrors(["email già in useo"]);
              self.toastr.error('Email già in uso, iscriviti con un altra mail o recupera la password.', 'Errore', {
                timeOut: 10000,
                extendedTimeOut: 4000,
                positionClass: "toast-top-center"
              });
            }
          });

        // self.auth.signup(self.signupForm.value['email'], self.signupForm.value['password'])
        //   .subscribe((infoes) => {
        //     self.auth.sendEmailVerification(); //TODO: TESTARE!!!
        //     self.updateUserData(infoes.user)
        //   },
        //     (error) => {
        //       if (error.code == "auth/email-already-in-use") {
        //         self.signupForm.controls.email.setErrors(["email già in useo"]);
        //         self.toastr.error('Email già in uso, iscriviti con un altra mail o recupera la password.', 'Errore', {
        //           timeOut: 10000,
        //           extendedTimeOut: 4000,
        //           positionClass: "toast-top-center"
        //         });
        //       }
        //     });
      } else {
        //name already in use
        self.signupForm.controls.nome.setErrors(["nome già in useo"]);
        self.toastr.error('Nome già in uso, scegli un altro nome per il tuo personaggio.', 'Errore', {
          timeOut: 10000,
          extendedTimeOut: 4000,
          positionClass: "toast-top-center"
        });
      }
    })
  }

  // used to create form structure
  buildForm() {
    this.signupForm = this.fb.group({
      'email': ['', [
        Validators.required,
        Validators.email,
      ]],
      'remail': ['', [
        Validators.required,
        Validators.email,
      ]],
      'password': ['', [
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(8),
        Validators.maxLength(20),
      ]],
      'repassword': ['', [
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(8),
        Validators.maxLength(20),
      ]],
      'nome': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'cognome': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'razza': ['', [
        Validators.required,
        Validators.min(0)
      ]],
      'sesso': ['', [
        Validators.required,
        Validators.min(0),
        Validators.max(2)
      ]]
    });
  }

  goToHome() {
    this.store.dispatch(new layout.GoToHomeInternaAction());
  }

  // Sets user data to firestore after succesful login
  private updateUserData(user: any) {
    let characterData: CharacterSheetData = this.createNewCharacter(user);
    characterData.name = characterData.name.trim();
    let characterDataJSON = JSON.parse(JSON.stringify(characterData));

    const userCollection = this.afs.collection<CharacterSheetData>('users');
    userCollection.doc(user.uid).set(characterDataJSON);

    const userNameCollection = this.afs.collection<any>('listOfUserNames');
    userNameCollection.doc((characterData.name as string).toLowerCase()).set({});
    // this.firestore.write('users/' + user.uid, characterDataJSON);
    // this.firestore.write('listOfUserNames/' + (characterData.name as string).toLowerCase(), {});


    // this.firestore.write('listOfUser/' + user.uid, { name: characterData.name, uid: characterData.uid });
    this.proceed();
  }

  private createNewCharacter(user: any): CharacterSheetData {

    let characterData: CharacterSheetData = new CharacterSheetData();
    characterData.uid = user.uid;
    characterData.name = this.signupForm.value['nome'];
    characterData.sur = this.signupForm.value['cognome'];
    characterData.race = Race.Mondano;
    characterData.sex = parseInt(this.signupForm.value['sesso']);
    characterData.mail = user.email;
    characterData.signDate = Functions.GetCurrentDate();
    characterData.access = Functions.GetCurrentDate();

    characterData.questionObject = this.questionObject;

    //set-up stats
    characterData.stats.agl = 10;
    characterData.stats.per = 10;
    characterData.stats.res = 10;
    characterData.stats.str = 10;
    characterData.stats.wil = 10;
    characterData.stats.wsd = 10;

    return characterData;
  }

  //TODO: id sender???
  private addWelcomePM(myUID: string, idSender: string) {
    // creating message
    const newMsgUID: string = Functions.CreateGuid();
    const dateHour: string = Functions.GetCurrentDate();
    const pm: PrivateMessage = new PrivateMessage(newMsgUID, idSender, dateHour, this.startingPMdata);
    const titolo: string = "Messaggio di benvenuto";

    const newConversationUID: string = Functions.CreateGuid();
    const newConversation: PrivateConversation = new PrivateConversation(newConversationUID, myUID, OnOff.off, titolo, [myUID], [myUID], false);
    newConversation.messages = [pm];
    newConversation.lMsgDT = dateHour;
    newConversation.lMsgPTS = Functions.ToOADate(new Date());

    // normalizing data
    const newConversationJSON = JSON.parse(JSON.stringify(newConversation));

    // adding timestamp
    newConversationJSON.lMsgTS = firebase.firestore.Timestamp.fromDate(new Date());
    // newConversationJSON.lMsgTS = firebase.firestore.FieldValue.serverTimestamp(); // used to make firestore timestamp work

    // saving
    //this.firestoreDB.collection('privateConversations').doc(newConversationUID).set(newConversationJSON);

    const PMConvCollection = this.afs.collection<PrivateConversation>('privateConversations');
    PMConvCollection.doc(newConversationUID).set(newConversationJSON);
    // this.firestore.write('privateConversations/' + newConversationUID, newConversationJSON);

    const self: this = this;
    const newMessagesDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('haveNewMessages/' + myUID);
    const newMessagesDocObservable: Observable<any> = newMessagesDoc.valueChanges();
    this.tempUpdateSubscription = newMessagesDocObservable.pipe(first()).subscribe((hasNew: any) => {
      // this.tempUpdateSubscription = this.firestore.read('haveNewMessages/' + myUID).skip(1).pipe(first())
      //   .subscribe((hasNew: any) => {

      if (Functions.IsNullOrUndefined(hasNew)) {
        hasNew = {
          newMsg: [],
          timeStamp: Functions.ToOADate(new Date())
        };
      }

      let messagesToBeRed: string[] = hasNew.newMsg;
      messagesToBeRed = messagesToBeRed.filter((aMessage: string) => aMessage != newConversationUID);
      messagesToBeRed.push(newConversationUID);
      newMessagesDoc.set({
        newMsg: messagesToBeRed,
        timeStamp: Functions.ToOADate(new Date())
      });
      // self.firestore.write('haveNewMessages/' + myUID, {
      //   newMsg: messagesToBeRed,
      //   timeStamp: Functions.ToOADate(new Date())
      // });

      self.tempUpdateSubscription.unsubscribe();
    });

  }

  // public onSubmit(submission) {
  //   console.log(submission);
  // }

  public checkAndSubscribe() {
    //#region - check if all the mandatory fields have values
    let invalid: boolean = false;
    for (const aComponent of this.form.components) {
      if (invalid === false && aComponent.validate.required === true) {
        // is required so check if value is present

        const value = this.submission.data[aComponent.key];

        if (aComponent.type == "textfield") {
          if (Functions.IsStringEmpty(value) === true) {
            invalid = true;
          }
        } else if (aComponent.type == "textarea") {
          if (Functions.IsStringEmpty(value) === true) {
            invalid = true;
          }
        } else if (aComponent.type == "number") {
          if (Functions.IsNullOrUndefined(value) === true) {
            invalid = true;
          }
          // } else if (aComponent.type == "checkbox") {
          //   if (value === false) {
          //     invalid = true;
          //   }
        } else if (aComponent.type == "radio") {
          if (Functions.IsStringEmpty(value) === true) {
            invalid = true;
          }
        } else if (aComponent.type == "selectboxes") {
          for (const property in value) {
            if (value[property] === false) {
              invalid = true;
            }
          }
        }

      }
    }

    if (invalid === true) {
      return;
    }
    //#endregion - check if all the mandatory fields have values

    //#region - create question object
    let questionObject: string = "";
    for (const aComponent of this.form.components) {
      const value = this.submission.data[aComponent.key];
      if (aComponent.type == "selectboxes") {
        questionObject = questionObject + '<span class="question-label">' + aComponent.label + ':</span><ul class="question-list">';
        for (const property in value) {
          questionObject = questionObject + '<li>' + property + ": " + value[property] + "</li>";
        }
        questionObject = questionObject + "</ul>";
      } else {
        questionObject = questionObject + '<span class="question-label">' + aComponent.label + ":</span> " + value;
      }

      questionObject = questionObject + "\n\n";

    }
    //#endregion - create question object

    this.questionObject = questionObject;

    this.signUpAndproceed();

  }


}
