// Angular & Co.
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CPanelModule } from './modules/cPanel/cPanel.module';
import { ChatRoomModule } from './modules/chatRoom/chatRoom.module';
import { ModalModule } from './modules/lib/modal';
import { UtilitiesModule } from './modules/utilities/utilities.module';

// Effects
import { ChatEffects } from './effects/chat';
import { LayoutEffects } from './effects/layout';
import { CharacterEffects } from './effects/character';

// Mobile Services
import { BrowserService } from './services/browser.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/guards/authGuard.service';
import { AdminGuardService } from './services/guards/adminGuard.service';
import { FirestoreService } from './services/firestore.service';
import { PresenceService } from './services/presence.service';
import { PmService } from './services/pm.service';
import { LogsService } from './services/logs.service';
import { IncrementalService } from './services/incremental.service';
import { CacheService } from './services/cache.service';
import { DebugLoggerService } from './services/debug-logger.service';
import { CustomErrorHandler, RollbarService, rollbarFactory } from './services/errorHandler.service';

// Components
import { AppComponent } from './app.component';
import { HomeEsternaComponent } from './components/esterne/home-esterna/home-esterna.component';
import { MainComponent } from './components/main.component';
import { MapsComponent } from './components/interne/maps/maps.component';
import { SignupComponent } from './components/esterne/singup/signup.component';
import { LoginComponent } from './components/esterne/login/login.component';
import { SchedaPersonaggioComponent } from './components/interne/scheda-personaggio/scheda-personaggio.component';
import { SchedaPersonaggioNewComponent } from './components/interne/scheda-personaggio-new/scheda-personaggio-new.component';
import { HomeInternaComponent } from './components/interne/home-interna/home-interna.component';
import { LoadingComponent } from './components/esterne/loading/loading.component';
import { EditSchedaPersonaggioComponent } from './components/interne/edit-scheda-personaggio/edit-scheda-personaggio.component';
import { LoginErrorComponent } from './components/esterne/login-error/login-error.component';
import { UfficiComponent } from './components/interne/uffici/uffici.component';
import { UfficiGestioneComponent } from './components/interne/uffici-gestione/uffici-gestione.component';
import { PresentiComponent } from './components/interne/presenti/presenti.component';
import { CreaMessaggioBoxComponent } from './components/interne/crea-messaggio/crea-messaggio.component';
import { MessaggiRicevutiBoxComponent } from './components/interne/messaggi-ricevuti/messaggi-ricevuti.component';
import { LeggiMessaggioBoxComponent } from './components/interne/leggi-messaggio/leggi-messaggio.component';
import { RulesComponent } from './components/interne/regolamento-panel/regolamento-panel.component';
import { UfficiVoltiTypeComponent } from './components/interne/uffici-volti-type/uffici-volti-type.component';
import { UfficiCambiaVoltoComponent } from './components/interne/uffici-cambia-volto/uffici-cambia-volto.component';
import { UfficiCambiaNomeComponent } from './components/interne/uffici-cambia-nome/uffici-cambia-nome.component';
import { UfficiStaffComponent } from './components/interne/uffici-staff/uffici-staff.component';
import { UfficiBankComponent } from './components/interne/uffici-bank/uffici-bank.component';
import { UfficiChatPrivateComponent } from './components/interne/uffici-chat-private/uffici-chat-private.component';
import { UfficiCreaChatComponent } from './components/interne/uffici-crea-chat/uffici-crea-chat.component';
import { UfficiMapsComponent } from './components/interne/uffici-maps/uffici-maps.component';
import { UfficiRazzeComponent } from './components/interne/uffici-razze/uffici-razze.component';
import { UfficiClanComponent } from './components/interne/uffici-clan/uffici-clan.component';
import { UfficiCorpComponent } from './components/interne/uffici-corp/uffici-corp.component';
import { UfficiCreditsComponent } from './components/interne/uffici-credits/uffici-credits.component';
import { LocationsComponent } from './components/interne/locations/locations.component';
import { EventsCalendarComponent } from './components/interne/events-calendar/events-calendar.component';
import { MeteoComponent } from './components/interne/meteo/meteo.component';
import { LogoutComponent } from './components/esterne/logout/logout.component';
import { RecuperoPasswordComponent } from './components/esterne/recupero-password/recupero-password.component';
import { CreditsComponent } from './components/esterne/credits/credits.component';

// cache system
// import { NgForageModule, NgForageConfig } from 'ngforage';
import { Driver, NgForageOptions, DEFAULT_CONFIG } from 'ngforage';
const ngfRootOptions: NgForageOptions = {
  name: 'Unveiled',
  driver: [
    Driver.INDEXED_DB,
    Driver.WEB_SQL,
    Driver.LOCAL_STORAGE
  ]
};

// store
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// import { reducers, metaReducers } from "./reducers";
import { reducers } from "./reducers";
import { NgSelectModule } from '@ng-select/ng-select';

// Create Material Module with all we need
import {
  MatDatepickerModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatRadioModule,
  MatToolbarModule,
  MatInputModule,
  MatSliderModule,
  MatExpansionModule,
  MatTabsModule,
  MatSlideToggleModule
} from "@angular/material";

// 3rd party libraries
import { CalendarModule } from 'angular-calendar';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireLite } from 'angularfire-lite';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RestoreGuardService } from './services/guards/restoreGuard.service';
import { FormioAppConfig, FormioModule } from 'angular-formio';


const MaterialModule: any[] = [
  MatDatepickerModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatRadioModule,
  MatToolbarModule,
  MatInputModule,
  MatSelectModule,
  MatSliderModule,
  MatExpansionModule,
  MatTabsModule,
  MatSlideToggleModule
];

// Make sure we use fontawesome everywhere in Form.io renderers.
// const Formio = require('formiojs').Formio;
// Formio.icons = 'fontawesome';
const AppConfig = {
  appUrl: 'https://eszaddwpozvknlm.form.io',
  apiUrl: 'https://api.form.io'
};


@NgModule({
  declarations: [
    AppComponent,
    HomeEsternaComponent,
    HomeInternaComponent,
    MainComponent,
    LoginComponent,
    LogoutComponent,
    SignupComponent,
    RecuperoPasswordComponent,
    CreditsComponent,
    SchedaPersonaggioComponent,
    SchedaPersonaggioNewComponent,
    LoadingComponent,
    MapsComponent,
    EditSchedaPersonaggioComponent,
    LoginErrorComponent,
    PresentiComponent,
    UfficiComponent,
    UfficiGestioneComponent,
    UfficiVoltiTypeComponent,
    UfficiCambiaVoltoComponent,
    UfficiCambiaNomeComponent,
    UfficiStaffComponent,
    UfficiBankComponent,
    UfficiChatPrivateComponent,
    UfficiCreaChatComponent,
    UfficiMapsComponent,
    UfficiRazzeComponent,
    UfficiClanComponent,
    UfficiCorpComponent,
    UfficiCreditsComponent,
    CreaMessaggioBoxComponent,
    MessaggiRicevutiBoxComponent,
    LeggiMessaggioBoxComponent,
    RulesComponent,
    MeteoComponent,
    LocationsComponent,
    EventsCalendarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UtilitiesModule,
    EffectsModule.forRoot([
      LayoutEffects,
      ChatEffects,
      CharacterEffects
    ]),
    // StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forRoot(reducers),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true
    }),
    // AngularFireLite.forRoot(environment.firebaseConfig),
    // NgForageModule.forRoot(),
    CPanelModule,
    ChatRoomModule,
    NgSelectModule,
    ModalModule,
    InfiniteScrollModule,
    CalendarModule,
    FormioModule
  ],
  providers: [
    BrowserService,
    AuthenticationService,
    FirestoreService,
    AuthGuardService,
    AdminGuardService,
    RestoreGuardService,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: DEFAULT_CONFIG, useValue: ngfRootOptions },
    PresenceService,
    PmService,
    LogsService,
    CacheService,
    IncrementalService,
    DebugLoggerService,
    {provide: FormioAppConfig, useValue: AppConfig}
  ],
  bootstrap: [AppComponent]
})


export class AppModule {

  public constructor() {
    // public constructor(ngfConfig: NgForageConfig) {
    // ngfConfig.configure({
    //   name: 'Unveiled'
    // });

  }
}
