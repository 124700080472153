import { LocalDataSource } from 'ng2-smart-table';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Race, CharacterBM, BonusMalus, UserPresence, PresencePlayerStatus } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-assegna-bm-a-personaggio',
  templateUrl: 'cPanel-assegna-bm-a-personaggio.component.html',
  styleUrls: ['cPanel-assegna-bm-a-personaggio.component.less'],
})
export class CPanelAssegnaBMAPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelAssegnaBonusMalus";

  //#region - firestore get users
  // private usersCollection: AngularFirestoreCollection<CharacterSheetData>;
  // private usersCollectionObservable: Observable<CharacterSheetData[]>;
  public usersPresence$: Observable<UserPresence[]>;
  private usersPresenceSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  //#endregion - firestore get users

  //#region - firestore get BM
  private BMCollection: AngularFirestoreCollection<BonusMalus>;
  private BMCollectionObservable: Observable<BonusMalus[]>;
  private BMSubscription: Subscription;
  public BMMap: Map<string, BonusMalus>;
  //#endregion - firestore get BM

  //#region - selected character variables
  private playerToEditDoc: AngularFirestoreDocument<CharacterSheetData>;
  private playerToEditDocObservable: Observable<CharacterSheetData>;
  private playerToEditDocSubscription: Subscription;
  public selectedCharacterID: string;
  public selectedCharacter: CharacterSheetData;
  //#region - selected character variables

  //#region - other variables
  public mergedDataSource: LocalDataSource;
  public mergedData: any[] = [];
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    // edit: {
    //   editButtonContent: '<i class="material-icons icon-edit">edit</i>',
    //   saveButtonContent: '<i class="material-icons icon-check">check</i>',
    //   cancelButtonContent: '<i class="material-icons icon-close">close</i>',
    //   confirmSave: true
    // },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Bonus / Malus',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      },
      race: {
        title: 'Razza',
        editable: false,
        addable: false,
        valuePrepareFunction: (value: string) => { return Race[value]; },
      },
    }
  };
  //#endregion - other variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.mergedDataSource = new LocalDataSource();

    //#region - get users
    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersPresenceSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }),
        first()
      ).subscribe((data: PresencePlayerStatus[]) => {
        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.characters = data;
        self.createMergedUserBMsData();
      });

    //#endregion - get users

    //#region - get BMs
    this.BMCollection = this.afs.collection<BonusMalus>('bonusMalus');
    this.BMCollectionObservable = this.BMCollection.valueChanges();
    this.BMSubscription = this.BMCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all bms cpanel", self.moduleName, "bonusMalus", (data as any[]).length);
      }

      self.BMMap = new Map();
      for (let index: number = 0; index < data.length; index++) {
        self.BMMap.set(data[index].uid, data[index]);
      }
    });
    //#endregion - get BMs
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersPresenceSubscription) == false) {
      this.usersPresenceSubscription.unsubscribe();
    }
    if (Functions.IsNullOrUndefined(this.BMSubscription) == false) {
      this.BMSubscription.unsubscribe();
    }
    if (Functions.IsNullOrUndefined(this.playerToEditDocSubscription) == false) {
      this.playerToEditDocSubscription.unsubscribe();
    }
  }

  public onUserChange(character: PresencePlayerStatus) {
    if (Functions.IsNullOrUndefined(character) == true) {
      this.selectedCharacter = undefined;
      this.createMergedUserBMsData();
      return;
    }

    const self: this = this;
    this.playerToEditDoc = this.afs.doc<any>('users/' + character.playerUID);
    this.playerToEditDocObservable = this.playerToEditDoc.valueChanges();
    this.playerToEditDocSubscription = this.playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all bms cpanel", self.moduleName, "users", 1);
        }

        self.selectedCharacter = selectedCharacter;
        self.createMergedUserBMsData();
      });
  }

  private createMergedUserBMsData() {

    if (Functions.IsNullOrUndefined(this.selectedCharacter))
      return;

    if (Functions.IsNullOrUndefined(this.BMMap) || this.BMMap.size == 0)
      return;

    let selectedCharacterBMs: CharacterBM[] = this.selectedCharacter.userBMs || [];
    let BMsToBeDisplayed: any[] = [];

    for (let index: number = 0; index < selectedCharacterBMs.length; index++) {

      // safety check in case of uid null or empty
      if (Functions.IsStringEmpty(selectedCharacterBMs[index].uid))
        continue;

      // second safety check
      const currentBM: BonusMalus = this.BMMap.get(selectedCharacterBMs[index].uid);
      if (Functions.IsNullOrUndefined(currentBM))
        continue;

      BMsToBeDisplayed.push(
        {
          uid: selectedCharacterBMs[index].uid,
          name: currentBM.name,
          race: currentBM.race
        }
      )
    }

    this.mergedData = BMsToBeDisplayed;
    this.mergedDataSource.load(BMsToBeDisplayed);

    this.setUpBMNameEditorList();
  }

  private setUpBMNameEditorList() {
    let displayedBMIDsSet: Set<string> = new Set();

    for (let index: number = 0; index < this.mergedData.length; index++) {
      displayedBMIDsSet.add(this.mergedData[index].uid);
    }

    //available list add
    let BMs: BonusMalus[] = Array.from(this.BMMap.values());
    let filteredBMs: BonusMalus[] = BMs
      .filter((aBM: BonusMalus) => displayedBMIDsSet.has(aBM.uid) == false && aBM.race == this.selectedCharacter.race && aBM.isNat == true);
    let filteredBMsEditList: any[] = [];

    for (let index: number = 0; index < filteredBMs.length; index++) {
      filteredBMsEditList.push(
        { value: filteredBMs[index].uid, title: filteredBMs[index].name }
      )
    }

    // sorting alphabetically
    filteredBMsEditList = filteredBMsEditList.sort(function (a, b) {
      var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settings.columns.name.editor.config.list = filteredBMsEditList;
    this.settings = Object.assign({}, this.settings);
  }

  public onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      let selectedCharacterBMs: CharacterBM[] = this.selectedCharacter.userBMs || [];
      let newList: CharacterBM[] = selectedCharacterBMs.filter((aCharacterBM: CharacterBM) => aCharacterBM.uid != event.data.uid);
      this.selectedCharacter.userBMs = newList;
      let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));

      let self = this;
      this.playerToEditDoc.update(updatedUserJsonData)
        .then(() => {
          self.toastr.success('Elemento Eliminato!');
          self.playerToEditDocSubscription = self.playerToEditDocObservable.pipe(first())
            .subscribe((selectedCharacter: CharacterSheetData) => {
              if (self.debugLogger.isAuditing) {
                self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
              }

              self.selectedCharacter = selectedCharacter;
              self.createMergedUserBMsData();
            });
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });

    } else {
      event.confirm.reject();
    }
  }

  public onCreateConfirm(event) {
    event.confirm.resolve();

    let selectedCharacterBMs: CharacterBM[] = this.selectedCharacter.userBMs || [];
    let newCharacterBM: CharacterBM = new CharacterBM();
    newCharacterBM.uid = event.newData.name;
    selectedCharacterBMs.push(newCharacterBM);
    this.selectedCharacter.userBMs = selectedCharacterBMs;

    let self = this;
    let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));
    this.playerToEditDoc.update(updatedUserJsonData)
      .then(() => {
        self.toastr.success('Elemento Creato!');
        self.playerToEditDocSubscription = self.playerToEditDocObservable.pipe(first())
          .subscribe((selectedCharacter: CharacterSheetData) => {
            if (self.debugLogger.isAuditing) {
              self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
            }

            self.selectedCharacter = selectedCharacter;
            self.createMergedUserBMsData();
          });
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

    // this.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });

  }

}
