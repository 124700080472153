import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Corp, AffiliationRole } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-archivio-corp',
  templateUrl: 'cPanel-archivio-corp.component.html',
  styleUrls: ['cPanel-archivio-corp.component.less'],
})
export class CPanelArchivioCorpComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelArchivioCorp";

  //#region - modal variables
  public isClassModalVisible: boolean = false;
  public roleArray: AffiliationRole[] = [];
  public roleRowData: any;
  //#endregion - modal variables

  //#region - corp variables
  private corpsCollection: AngularFirestoreCollection<Corp>;
  private corpsCollectionObservable: Observable<Corp[]>;
  private corpSubscription: Subscription;
  public dataSource: LocalDataSource;
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    actions: {
      custom: [
        {
          name: 'setRoles',
          // addButtonContent: '<i class="material-icons icon-add">exposure</i>'
          title: '<i class="material-icons icon-add">bookmarks</i>',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Clan'
      },
      iconUrl: {
        title: 'Icona Clan',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
    }
  };
  //#endregion - corp variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    this.corpsCollection = this.afs.collection<Corp>('corps');
    this.corpsCollectionObservable = this.corpsCollection.valueChanges();
    this.corpSubscription = this.corpsCollectionObservable.subscribe((data) => {

      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all corps cpanel", self.moduleName, "corps", (data as any[]).length);
      }

      self.dataSource.load(data);
    });
  }

  public ngOnDestroy() {
    this.corpSubscription.unsubscribe();
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la corporazione selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.cPanelService.removeDB("corps", event.data.uid);
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event) {
    event.newData.uid = Functions.CreateGuid();
    event.confirm.resolve();

    //save on firebase
    let newCorpData: Corp = new Corp();
    newCorpData.uid = event.newData.uid;
    newCorpData.name = event.newData.name;
    newCorpData.iconUrl = event.newData.iconUrl;

    let newCorpDataJSON = JSON.parse(JSON.stringify(newCorpData));
    this.cPanelService.writeOnDB("corps", newCorpDataJSON.uid, newCorpDataJSON);
  }

  onEditConfirm(event) {
    event.confirm.resolve();

    //update on firebase
    let newCorpData: Corp = new Corp();
    newCorpData.uid = event.newData.uid;
    newCorpData.name = event.newData.name;
    newCorpData.iconUrl = event.newData.iconUrl;
    newCorpData.roles = event.newData.roles;

    let newCorpDataJSON = JSON.parse(JSON.stringify(newCorpData));
    this.cPanelService.writeOnDB("corps", newCorpDataJSON.uid, newCorpDataJSON);
  }

  onCustom(event) {
    this.roleRowData = event.data;
    this.roleArray = event.data.roles;

    if (Functions.IsNullOrUndefined(this.roleArray) == true || this.roleArray.length <= 0) {
      this.roleArray = [];
      this.roleArray.push(new AffiliationRole());
    }
    this.isClassModalVisible = true;
  }

  okModal() {
    const computedRoles: AffiliationRole[] = [];
    for (let index: number = 0; index < this.roleArray.length; index++) {
      const currentRole: AffiliationRole = this.roleArray[index];
      if (Functions.IsStringEmpty(currentRole.name))
        continue;

      currentRole.roleGrade = computedRoles.length + 1;
      computedRoles.push(currentRole);
    }

    //save and reset
    let newCorpData: Corp = new Corp();
    newCorpData.uid = this.roleRowData.uid;
    newCorpData.name = this.roleRowData.name;
    newCorpData.iconUrl = this.roleRowData.iconUrl;
    newCorpData.roles = computedRoles;

    let self = this;
    let newCorpDataJSON = JSON.parse(JSON.stringify(newCorpData));
    this.cPanelService.writeOnDB("corps", newCorpDataJSON.uid, newCorpDataJSON);

    this.isClassModalVisible = false;
    this.roleArray = [];
  }

  closeModal() {
    this.isClassModalVisible = false;
    this.roleArray = [];
  }

  addEmptyRow() {
    if (Functions.IsNullOrUndefined(this.roleArray)) {
      this.roleArray = [];
      this.roleArray.push(new AffiliationRole());
    } else {
      this.roleArray.push(new AffiliationRole());
    }
  }

}
