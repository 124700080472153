import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as datasSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import { Observable } from 'rxjs';
import { RulesSezione, RulesSottoSezione, Rule, DBVersionType } from '../../../models/data/application.data';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { IncrementalService } from '../../../services/incremental.service';


// Utilities
@Component({
  selector: 'rules-component',
  templateUrl: './regolamento-panel.component.html',
  styleUrls: ['./regolamento-panel.component.less']
})

export class RulesComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "RulesComponent";

  @Input() isEsterno: boolean = false;
  @Output() emitClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public rulesContainerMinHeight: number = 0;
  public rules$: Observable<RulesSezione[]>;

  public openedSezione: string[] = [];
  // private selectedSezione: RulesSezione = undefined;
  public selectedSottoSezione: RulesSottoSezione = undefined;
  public selectedParagrafi: string[] = [];

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private incrementalService: IncrementalService
  ) {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.rules, true);

    this.rules$ = this.store.select(datasSelector.getRulesList);
  }

  public ngOnInit() {

  }

  public ngAfterViewInit() {
    this.onResize();
  }

  selectSezione(sezione: RulesSezione) {
    // this.selectedSezione = sezione;
    if (Functions.IsNullOrUndefined(this.openedSezione) == false && this.openedSezione[sezione.uid] == sezione.uid) {
      this.openedSezione[sezione.uid] = undefined;
    } else {
      this.openedSezione[sezione.uid] = sezione.uid;
    }
  }

  selectSottoSezione(sottoSezione: RulesSottoSezione) {
    const clonedSottoSelection: RulesSottoSezione = Object.assign({}, sottoSezione);

    if (this.isEsterno)
      clonedSottoSelection.rules = clonedSottoSelection.rules.filter((aRule: Rule) => Functions.IsNullOrUndefined(aRule.alsoParziale) || aRule.alsoParziale == true);

    this.selectedSottoSezione = clonedSottoSelection;
    this.selectedParagrafi = [];
  }

  selectParagrafo(paragrafo: Rule) {
    if (Functions.IsNullOrUndefined(this.selectedParagrafi) == false && this.selectedParagrafi[paragrafo.uid] == paragrafo.uid) {
      this.selectedParagrafi[paragrafo.uid] = undefined;
    } else {
      this.selectedParagrafi[paragrafo.uid] = paragrafo.uid;
    }
  }

  backToHomeInterna() {
    if (this.isEsterno == false)
      this.store.dispatch(new layout.TogglePresentiAction(false));
    else
      this.emitClose.emit(true);
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    let minHeight: number = (windowsHeight / 100) * 60;
    if (minHeight <= 350)
      minHeight = 350;

    if (minHeight > 522)
      minHeight = 522;

    this.rulesContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }
}
