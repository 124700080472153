import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import { AngularFireList, AngularFireDatabase, AngularFireObject, SnapshotAction } from '@angular/fire/database';
import { ChatMessage, ChatData } from 'src/app/modules/chatRoom/models/chat-message.models';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { map, first } from 'rxjs/operators';
import { PrivateConversation, LogBank, LogPX, LogOgg, LocationRoot, RulesSezione, Volto, CharacterSheetData, Clan, Corp, BonusMalus, Skill, Item, MeteoDay, SpecialChat, StandardChat, UserPresence } from 'src/app/models/data/application.data';
import { FirestoreBackup, FirestoreBackupState, FirebaseBackupState, FirebaseBackup } from 'src/app/models/data/backup.data';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'cPanel-trigger-manager',
  templateUrl: 'cPanel-trigger-manager.component.html',
  styleUrls: ['cPanel-trigger-manager.component.less'],
})
export class CPanelTriggerManagerComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelTriggerManager";

  private lastChatDoc: AngularFirestoreDocument<any>;
  private lastChatObservable: Observable<any>;
  private lastChatSubscription: Subscription;
  public lastChatClean: string;

  private lastPMDoc: AngularFirestoreDocument<any>;
  private lastPMObservable: Observable<any>;
  private lastPMSubscription: Subscription;
  public lastPMClean: string;

  private lastLogDoc: AngularFirestoreDocument<any>;
  private lastLogObservable: Observable<any>;
  private lastLogSubscription: Subscription;
  public lastLogClean: string;

  private lastBackupDoc: AngularFirestoreDocument<any>;
  private lastBackupObservable: Observable<any>;
  private lastBackupSubscription: Subscription;
  public lastBack: string;

  //#region - get chats
  private chatsRef: AngularFireList<any>;
  private chats: any[];
  //#endregion - get chats

  //#region - get PMS
  private pmsCollection: AngularFirestoreCollection<PrivateConversation>;
  private pmsCollectionObservable: Observable<PrivateConversation[]>;
  private pmsSubscription: Subscription;
  private allPMs: PrivateConversation[];
  //#endregion - get PMS

  //#region - get logs
  private logsBankRef: AngularFireList<LogBank>;
  private logsBank: LogBank[];
  private logsBankCleanComplete: boolean = false;
  private logsPXRef: AngularFireList<LogPX>;
  private logsPX: LogPX[];
  private logsPXCleanComplete: boolean = false;
  private logsOggRef: AngularFireList<LogOgg>;
  private logsOgg: LogOgg[];
  private logsOggCleanComplete: boolean = false;
  //#endregion - get logs

  //#region - backup link
  private linkBackupDoc: AngularFirestoreDocument<any>;
  private linkBackupObservable: Observable<any>;
  private linkBackupSubscription: Subscription;
  public lastBackLink: string = "";

  private linkBackup2Doc: AngularFirestoreDocument<any>;
  private linkBackup2Observable: Observable<any>;
  private linkBackup2Subscription: Subscription;
  public lastBack2Link: string = "";
  //#endregion - backup link

  public fsDownload: string = "";
  public fbDownload: string = "";

  constructor(
    private cPanelService: CPanelService,
    private toastr: ToastrService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService,
    private store: Store<fromRoot.State>,
    private afdb: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {
    this.fsDownload = "unveiled-fs-bck-" + Functions.GetCurrentDate();
    this.fbDownload = "unveiled-fb-bck-" + Functions.GetCurrentDate();

    const self: this = this;
    //#region - last chat clean
    this.lastChatDoc = this.afs.doc<any>('settings/lastChatClean');
    this.lastChatObservable = this.lastChatDoc.valueChanges();
    this.lastChatSubscription = this.lastChatObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ Last Chat Clean cPanel", self.moduleName, "settings/lastChatClean", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.lastChatClean = data.value;
      else
        self.lastChatClean = "-";
    });
    //#endregion - last chat clean

    //#region - last PM clean
    this.lastPMDoc = this.afs.doc<any>('settings/lastPMClean');
    this.lastPMObservable = this.lastPMDoc.valueChanges();
    this.lastPMSubscription = this.lastPMObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ Last PM Clean cPanel", self.moduleName, "settings/lastPMClean", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.lastPMClean = data.value;
      else
        self.lastPMClean = "-";
    });
    //#endregion - last PM clean

    //#region - last Log clean
    this.lastLogDoc = this.afs.doc<any>('settings/lastLogClean');
    this.lastLogObservable = this.lastLogDoc.valueChanges();
    this.lastLogSubscription = this.lastLogObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ Last Log Clean cPanel", self.moduleName, "settings/lastLogClean", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.lastLogClean = data.value;
      else
        self.lastLogClean = "-";
    });
    //#endregion - last Log clean

    //#region - last backup
    this.lastBackupDoc = this.afs.doc<any>('settings/lastBackup');
    this.lastBackupObservable = this.lastBackupDoc.valueChanges();
    this.lastBackupSubscription = this.lastBackupObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ Last Backup cPanel", self.moduleName, "settings/lastBackup", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.lastBack = data.value;
      else
        self.lastBack = "-";
    });
    //#endregion - last backup

    //#region - backup link
    this.linkBackupDoc = this.afs.doc<any>('settings/bck1Link');
    this.linkBackupObservable = this.linkBackupDoc.valueChanges();
    this.linkBackupSubscription = this.linkBackupObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ Last Backup link cPanel", self.moduleName, "settings/bck1Link", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.lastBackLink = data.value;
      else
        self.lastBackLink = "-";
    });

    this.linkBackup2Doc = this.afs.doc<any>('settings/bck2Link');
    this.linkBackup2Observable = this.linkBackup2Doc.valueChanges();
    this.linkBackup2Subscription = this.linkBackup2Observable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ Last Backup link 2 cPanel", self.moduleName, "settings/bck2Link", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.lastBack2Link = data.value;
      else
        self.lastBack2Link = "-";
    });
    //#endregion - backup link

  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.lastChatSubscription) == false) {
      this.lastChatSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.lastPMSubscription) == false) {
      this.lastPMSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.lastLogSubscription) == false) {
      this.lastLogSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.pmsSubscription) == false) {
      this.pmsSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.lastBackupSubscription) == false) {
      this.lastBackupSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.linkBackupSubscription) == false) {
      this.linkBackupSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.linkBackup2Subscription) == false) {
      this.linkBackup2Subscription.unsubscribe();
    }
  }

  public cancellaPMs() {
    const self: this = this;
    const before3month = Date.now() - (3 * 31 * 24 * 3600 * 1000);
    var oaDate = (Date.now() - (Date.UTC(1899, 11, 30)) - (3 * 31 * 24 * 3600 * 1000)) / (24 * 60 * 60 * 1000)

    this.pmsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where('lMsgPTS', '<=', oaDate));
    this.pmsCollectionObservable = this.pmsCollection.valueChanges();
    this.pmsSubscription = this.pmsCollectionObservable
      .pipe(first())
      .subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all pms conversation cpanel", self.moduleName, "privateConversations", (data as any[]).length);
        }

        self.allPMs = data;
        self.proceedCancellaPMs();
      });
  }

  public proceedCancellaPMs() {
    for (const aPrivateConversation of this.allPMs) {
      const aPrivateConversationDoc = this.afs.doc<any>('privateConversations/' + aPrivateConversation.cnvUID);
      aPrivateConversationDoc.delete();
    }

    const self: this = this;
    let lastEditValue = JSON.parse(JSON.stringify({ value: Functions.GetCurrentDate() }));
    this.lastPMDoc.set(lastEditValue)
      .then(() => {
        self.toastr.success('Messaggi Privati puliti');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

  }

  public cancellaChat() {
    const self: this = this;
    this.chatsRef = this.afdb.list('/chats/');
    this.chatsRef.snapshotChanges().pipe(
      map((changes) =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      ),
      first()
    ).subscribe((chats) => {
      self.chats = chats;
      self.procedeCancellaChat();
    });
  }

  public procedeCancellaChat() {
    const self: this = this;
    const before3month = Date.now() - (2 * 31 * 24 * 3600 * 1000);

    for (const aChat of this.chats) {
      let dirty: boolean = false;
      for (var aMsg in aChat.messages) {
        if (aChat.messages[aMsg].time < before3month) {
          delete aChat.messages[aMsg];
          dirty = true;
          // aChat.messages[aMsg] = null;
        }
      }

      // aChat.messages = (aChat.messages as ChatMessage[]).filter((aMessage: ChatMessage) => aMessage.time > before3month);
      if (dirty) {
        const normalized = JSON.parse(JSON.stringify(aChat.messages));
        self.chatsRef.update(aChat.key, { messages: normalized });
      }
    }

    let lastEditValue = JSON.parse(JSON.stringify({ value: Functions.GetCurrentDate() }));
    this.lastChatDoc.set(lastEditValue)
      .then(() => {
        self.toastr.success('Messaggi Chat puliti');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })
  }


  public cancellaLogs() {
    this.logsBankCleanComplete = false;
    this.logsPXCleanComplete = false;
    this.logsOggCleanComplete = false;
    this.cancellaLogsBank();
    this.cancellaLogsPX();
    this.cancellaLogsOgg();
  }

  private cancellaLogsBank() {
    const self: this = this;
    this.logsBankRef = this.afdb.list('/logBank/');
    this.logsBankRef.valueChanges().pipe(
      first()
    ).subscribe((logsBank) => {
      self.logsBank = logsBank;
      self.procedeCancellaLogsBank();
    });
  }

  private procedeCancellaLogsBank() {
    const before3month = Date.now() - (3 * 31 * 24 * 3600 * 1000);
    for (const aLog of this.logsBank) {
      if (aLog.logPTS <= before3month) {
        // delete it
        const aRef: AngularFireObject<LogBank> = this.afdb.object('/logBank/' + aLog.id);
        aRef.remove();
      }
    }

    this.logsBankCleanComplete = true;
    if (this.logsBankCleanComplete && this.logsPXCleanComplete && this.logsOggCleanComplete) {
      const self: this = this;
      let lastEditValue = JSON.parse(JSON.stringify({ value: Functions.GetCurrentDate() }));
      this.lastLogDoc.set(lastEditValue)
        .then(() => {
          self.toastr.success('Logs puliti');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })
    }
  }

  private cancellaLogsPX() {
    const self: this = this;
    this.logsPXRef = this.afdb.list('/logPX/');
    this.logsPXRef.valueChanges().pipe(
      first()
    ).subscribe((logsPX) => {
      self.logsPX = logsPX;
      self.procedeCancellaLogsPX();
    });
  }

  private procedeCancellaLogsPX() {
    const before3month = Date.now() - (3 * 31 * 24 * 3600 * 1000);
    for (const aLog of this.logsPX) {
      if (aLog.logPTS <= before3month) {
        // delete it
        const aRef: AngularFireObject<LogPX> = this.afdb.object('/logPX/' + aLog.id);
        aRef.remove();
      }
    }

    this.logsPXCleanComplete = true;
    if (this.logsBankCleanComplete && this.logsPXCleanComplete && this.logsOggCleanComplete) {
      const self: this = this;
      let lastEditValue = JSON.parse(JSON.stringify({ value: Functions.GetCurrentDate() }));
      this.lastLogDoc.set(lastEditValue)
        .then(() => {
          self.toastr.success('Logs puliti');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })
    }
  }

  private cancellaLogsOgg() {
    const self: this = this;
    this.logsOggRef = this.afdb.list('/logOgg/');
    this.logsOggRef.valueChanges().pipe(
      first()
    ).subscribe((logsOgg) => {
      self.logsOgg = logsOgg;
      self.procedeCancellaLogsOgg();
    });
  }

  private procedeCancellaLogsOgg() {
    const before3month = Date.now() - (3 * 31 * 24 * 3600 * 1000);
    for (const aLog of this.logsOgg) {
      if (aLog.logPTS <= before3month) {
        // delete it
        const aRef: AngularFireObject<LogOgg> = this.afdb.object('/logOgg/' + aLog.id);
        aRef.remove();
      }
    }

    this.logsOggCleanComplete = true;
    if (this.logsBankCleanComplete && this.logsPXCleanComplete && this.logsOggCleanComplete) {
      const self: this = this;
      let lastEditValue = JSON.parse(JSON.stringify({ value: Functions.GetCurrentDate() }));
      this.lastLogDoc.set(lastEditValue)
        .then(() => {
          self.toastr.success('Logs puliti');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })
    }
  }

  public backup() {
    this.backupFirestore();
  }

  public backupFirestore() {
    const self: this = this;
    const firestoreBackupProgres: FirestoreBackupState = new FirestoreBackupState();
    const firestoreBackup: FirestoreBackup = new FirestoreBackup();

    //#region - backup locations
    const locationCollection: AngularFirestoreCollection<LocationRoot> = this.afs.collection<LocationRoot>('locations');
    const locationCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = locationCollection.ref.get({ source: 'server' });
    locationCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all locations BACKUP", self.moduleName, "locations", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: LocationRoot[] = [];
      data.forEach(a => {
        const entry: LocationRoot = a.data() as LocationRoot;
        normalizedData.push(entry);
      });

      const locationRoot = normalizedData[0];
      firestoreBackup.locations = locationRoot;
      firestoreBackupProgres.locations = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup locations

    //#region - backup rules
    const rulesCollection: AngularFirestoreCollection<RulesSezione> = this.afs.collection<RulesSezione>('rules');
    const rulesCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = rulesCollection.ref.get({ source: 'server' });
    rulesCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all rules BACKUP", self.moduleName, "rules", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: RulesSezione[] = [];
      data.forEach(a => {
        const entry: RulesSezione = a.data() as RulesSezione;
        normalizedData.push(entry);
      });

      firestoreBackup.rules = normalizedData;
      firestoreBackupProgres.rules = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup rules

    //#region - backup volti
    const voltiCollection: AngularFirestoreCollection<Volto> = this.afs.collection<Volto>('volti');
    const voltiCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = voltiCollection.ref.get({ source: 'server' });
    voltiCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all volti BACKUP", self.moduleName, "volti", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: Volto[] = [];
      data.forEach(a => {
        const entry: Volto = a.data() as Volto;
        normalizedData.push(entry);
      });

      firestoreBackup.volti = normalizedData;
      firestoreBackupProgres.volti = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup volti

    //#region - backup settings
    const settingsCollection: AngularFirestoreCollection<any> = this.afs.collection<any>('settings');
    const settingsCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = settingsCollection.ref.get({ source: 'server' });
    settingsCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all settings BACKUP", self.moduleName, "settings", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: any[] = [];
      data.forEach(a => {
        const entry: any = { key: a.id, ...a.data() };
        normalizedData.push(entry);
      });

      firestoreBackup.settings = normalizedData;
      firestoreBackupProgres.settings = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup settings

    //#region - backup character sheets
    const sheetCollection: AngularFirestoreCollection<CharacterSheetData> = this.afs.collection<CharacterSheetData>('users');
    const sheetCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = sheetCollection.ref.get({ source: 'server' });
    sheetCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all characterSheet BACKUP", self.moduleName, "users", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: CharacterSheetData[] = [];
      data.forEach(a => {
        const entry: CharacterSheetData = a.data() as CharacterSheetData;
        normalizedData.push(entry);
      });

      firestoreBackup.users = normalizedData;
      firestoreBackupProgres.users = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup character sheets

    //#region - backup clans
    const clanCollection: AngularFirestoreCollection<Clan> = this.afs.collection<Clan>('clans');
    const clanCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = clanCollection.ref.get({ source: 'server' });
    clanCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all clans BACKUP", self.moduleName, "clans", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: Clan[] = [];
      data.forEach(a => {
        const entry: Clan = a.data() as Clan;
        normalizedData.push(entry);
      });

      firestoreBackup.clans = normalizedData;
      firestoreBackupProgres.clans = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup clans

    //#region - backup corps
    const corpCollection: AngularFirestoreCollection<Corp> = this.afs.collection<Corp>('corps');
    const corpCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = corpCollection.ref.get({ source: 'server' });
    corpCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all corps BACKUP", self.moduleName, "corps", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: Corp[] = [];
      data.forEach(a => {
        const entry: Corp = a.data() as Corp;
        normalizedData.push(entry);
      });

      firestoreBackup.corps = normalizedData;
      firestoreBackupProgres.corps = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup corps

    //#region - backup listOfUserNames
    const userNamesCollection: AngularFirestoreCollection<any> = this.afs.collection<any>('listOfUserNames');
    const userNamesCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = userNamesCollection.ref.get({ source: 'server' });
    userNamesCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all listOfUserNames BACKUP", self.moduleName, "listOfUserNames", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: any[] = [];
      data.forEach(a => {
        const entry: any = { key: a.id };
        normalizedData.push(entry);
      });

      firestoreBackup.listOfUserNames = normalizedData;
      firestoreBackupProgres.listOfUserNames = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup listOfUserNames

    //#region - backup bonusMalus
    const bmCollection: AngularFirestoreCollection<BonusMalus> = this.afs.collection<BonusMalus>('bonusMalus');
    const bmCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = bmCollection.ref.get({ source: 'server' });
    bmCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all bonusMalus BACKUP", self.moduleName, "bonusMalus", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: BonusMalus[] = [];
      data.forEach(a => {
        const entry: BonusMalus = a.data() as BonusMalus;
        normalizedData.push(entry);
      });

      firestoreBackup.bonusMalus = normalizedData;
      firestoreBackupProgres.bonusMalus = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup bonusMalus

    //#region - backup skills
    const skillsCollection: AngularFirestoreCollection<Skill> = this.afs.collection<Skill>('skills');
    const skillsCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = skillsCollection.ref.get({ source: 'server' });
    skillsCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all skills BACKUP", self.moduleName, "skills", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: Skill[] = [];
      data.forEach(a => {
        const entry: Skill = a.data() as Skill;
        normalizedData.push(entry);
      });

      firestoreBackup.skills = normalizedData;
      firestoreBackupProgres.skills = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup skills

    //#region - backup items
    const itemsCollection: AngularFirestoreCollection<Item> = this.afs.collection<Item>('items');
    const itemsCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = itemsCollection.ref.get({ source: 'server' });
    itemsCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all items BACKUP", self.moduleName, "items", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: Item[] = [];
      data.forEach(a => {
        const entry: Item = a.data() as Item;
        normalizedData.push(entry);
      });

      firestoreBackup.items = normalizedData;
      firestoreBackupProgres.items = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup items

    //#region - backup haveNewMessages
    const hasNewMsgCollection: AngularFirestoreCollection<any> = this.afs.collection<any>('haveNewMessages');
    const hasNewMsgCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = hasNewMsgCollection.ref.get({ source: 'server' });
    hasNewMsgCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all haveNewMessages BACKUP", self.moduleName, "haveNewMessages", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: any[] = [];
      data.forEach(a => {
        const entry: any = { key: a.id, ...a.data() };
        normalizedData.push(entry);
      });

      firestoreBackup.haveNewMessages = normalizedData;
      firestoreBackupProgres.haveNewMessages = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup haveNewMessages

    //#region - backup meteoDays
    const meteoDaysCollection: AngularFirestoreCollection<MeteoDay> = this.afs.collection<MeteoDay>('meteoDays');
    const meteoDaysCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = meteoDaysCollection.ref.get({ source: 'server' });
    meteoDaysCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all meteoDays BACKUP", self.moduleName, "meteoDays", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: MeteoDay[] = [];
      data.forEach(a => {
        const entry: MeteoDay = a.data() as MeteoDay;
        normalizedData.push(entry);
      });

      firestoreBackup.meteoDays = normalizedData;
      firestoreBackupProgres.meteoDays = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup meteoDays

    //#region - backup privateConversations
    const privateConversationsCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations');
    const privateConversationsCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = privateConversationsCollection.ref.get({ source: 'server' });
    privateConversationsCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all privateConversations BACKUP", self.moduleName, "privateConversations", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: PrivateConversation[] = [];
      data.forEach(a => {
        const entry: PrivateConversation = a.data() as PrivateConversation;
        normalizedData.push(entry);
      });

      firestoreBackup.privateConversations = normalizedData;
      firestoreBackupProgres.privateConversations = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup privateConversations

    //#region - backup special chats
    const specialChatCollection: AngularFirestoreCollection<SpecialChat> = this.afs.collection<SpecialChat>('specialChat');
    const specialChatCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = specialChatCollection.ref.get({ source: 'server' });
    specialChatCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all specialChat BACKUP", self.moduleName, "specialChat", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: SpecialChat[] = [];
      data.forEach(a => {
        const entry: SpecialChat = a.data() as SpecialChat;
        normalizedData.push(entry);
      });

      firestoreBackup.specialChat = normalizedData;
      firestoreBackupProgres.specialChat = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup special chats

    //#region - backup chats
    const standardChatCollection: AngularFirestoreCollection<StandardChat> = this.afs.collection<StandardChat>('standardChat');
    const standardChatCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = standardChatCollection.ref.get({ source: 'server' });
    standardChatCollectionObservable.then((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all standardChat BACKUP", self.moduleName, "standardChat", (data.docs as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: StandardChat[] = [];
      data.forEach(a => {
        const entry: StandardChat = a.data() as StandardChat;
        normalizedData.push(entry);
      });

      firestoreBackup.standardChat = normalizedData;
      firestoreBackupProgres.standardChat = true;
      self.createFirestoreJSONfile(firestoreBackup, firestoreBackupProgres);
    });
    //#endregion - backup chats





    // FIREBASE
    //backup status
    //backup chat (?)

  }

  public backupFirebase() {
    const self: this = this;
    const firebaseBackupProgres: FirebaseBackupState = new FirebaseBackupState();
    const firebaseBackup: FirebaseBackup = new FirebaseBackup();

    //#region - backup player status
    const userPresenceCollection: AngularFireList<UserPresence> = this.afdb.list<UserPresence>('/status');
    const userPresenceCollectionObservable: Observable<SnapshotAction<UserPresence>[]> = userPresenceCollection.snapshotChanges();
    const userPresenceCollectionSubscription: Subscription = userPresenceCollectionObservable
      .pipe(first())
      .subscribe((data) => {
        // if (self.debugLogger.isAuditing) {
        //   self.debugLogger.logRead(true, "READ all user presence BACKUP", self.moduleName, "status", (data as any[]).length);
        // }

        if (Functions.IsNullOrUndefined(data) == true || data.length <= 0)
          return;

        const normalizedData: any[] = [];
        data.forEach((a: SnapshotAction<UserPresence>) => {
          const entry: any = a.payload.val() as any;
          entry.key = a.payload.key;
          entry.connections = null;
          normalizedData.push(entry);
        });

        firebaseBackup.status = normalizedData;
        firebaseBackupProgres.status = true;
        self.createFirebaseJSONfile(firebaseBackup, firebaseBackupProgres);
      });
    //#endregion - backup player status

    //#region - backup chat status
    const chatsCollection: AngularFireList<ChatData> = this.afdb.list<ChatData>('/chats');
    const chatsCollectionObservable: Observable<SnapshotAction<ChatData>[]> = chatsCollection.snapshotChanges();
    const chatsCollectionSubscription: Subscription = chatsCollectionObservable
      .pipe(first())
      .subscribe((data) => {
        // if (self.debugLogger.isAuditing) {
        //   self.debugLogger.logRead(true, "READ all chats BACKUP", self.moduleName, "chats", (data as any[]).length);
        // }

        if (Functions.IsNullOrUndefined(data) == true || data.length <= 0)
          return;

        const normalizedData: any[] = [];
        data.forEach((a: SnapshotAction<ChatData>) => {
          const entry: any = a.payload.val() as any;
          entry.key = a.payload.key;
          normalizedData.push(entry);
        });

        firebaseBackup.chats = normalizedData;
        firebaseBackupProgres.chats = true;
        self.createFirebaseJSONfile(firebaseBackup, firebaseBackupProgres);
      });
    //#endregion - backup chat status

  }

  private createFirestoreJSONfile(firestoreBackup: FirestoreBackup, firestoreBackupProgres: FirestoreBackupState) {
    if (
      firestoreBackupProgres.items == false
      || firestoreBackupProgres.listOfUserNames == false
      || firestoreBackupProgres.locations == false
      || firestoreBackupProgres.meteoDays == false
      || firestoreBackupProgres.privateConversations == false
      || firestoreBackupProgres.rules == false
      || firestoreBackupProgres.settings == false
      || firestoreBackupProgres.skills == false
      || firestoreBackupProgres.specialChat == false
      || firestoreBackupProgres.standardChat == false
      || firestoreBackupProgres.users == false
      || firestoreBackupProgres.volti == false
      || firestoreBackupProgres.bonusMalus == false
      || firestoreBackupProgres.clans == false
      || firestoreBackupProgres.corps == false
      || firestoreBackupProgres.haveNewMessages == false
    ) {
      return;
    }


    // var data = this.encode(JSON.stringify(firestoreBackup, null, 4));
    var data = JSON.stringify(firestoreBackup, null, 4);

    // var blob = new Blob([data], {
    //   type: 'application/octet-stream'
    // });

    var blob = new Blob([data], {
      type: 'application/json'
    });

    this.uploadFirestoreBakcup(blob);




    // const url = URL.createObjectURL(blob);
    // var link = document.createElement('a');
    // link.setAttribute('href', url);
    // link.setAttribute('download', 'example.json');

    // var event = document.createEvent('MouseEvents');
    // event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    // link.dispatchEvent(event);
  }

  private createFirebaseJSONfile(firebaseBackup: FirebaseBackup, firebaseBackupProgres: FirebaseBackupState) {
    if (
      firebaseBackupProgres.status == false
      || firebaseBackupProgres.chats == false
    ) {
      return;
    }

    var data = JSON.stringify(firebaseBackup, null, 4);

    var blob = new Blob([data], {
      type: 'application/json'
    });

    this.uploadFirebaseBakcup(blob);
  }

  private encode(jsonString: string) {
    let out = [];
    for (let index = 0; index < jsonString.length; index++) {
      out[index] = jsonString.charCodeAt(index);
    }
    return new Uint8Array(out);
  }

  private uploadFirestoreBakcup(backFileToUpload: any) {
    const self: this = this;
    const filePath: string = 'bck/fs.json';
    const fileRef = this.storage.ref(filePath);
    const uploadTask = fileRef.put(backFileToUpload)
      .then((value: any) => {
        const subscription = fileRef.getDownloadURL()
          .pipe(first())
          .subscribe((value) => {
            self.toastr.success('1/2 - Backup primo database completato!');
            let lastBackLink = JSON.parse(JSON.stringify({ value: value }));
            self.linkBackupDoc.set(lastBackLink)
              .catch((error: any) => {
                self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
              })

            self.backupFirebase();
          });
      });
  }

  private uploadFirebaseBakcup(backFileToUpload: any) {
    const self: this = this;
    const filePath: string = 'bck/fb.json';
    const fileRef = this.storage.ref(filePath);
    const uploadTask = fileRef.put(backFileToUpload)
      .then((value: any) => {
        const subscription = fileRef.getDownloadURL()
          .pipe(first())
          .subscribe((value) => {
            self.toastr.success('2/2 - Backup secondo Database completato!');

            let lastBack2Link = JSON.parse(JSON.stringify({ value: value }));
            self.linkBackup2Doc.set(lastBack2Link)
              .catch((error: any) => {
                self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
              });

            let lastEditValue = JSON.parse(JSON.stringify({ value: Functions.GetCurrentDate() }));
            this.lastBackupDoc.set(lastEditValue)
              .catch((error: any) => {
                self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
              })

          });
      });
  }

  public download(url: string, idNumber: number) {

    // var link = document.createElement("a");
    // if (link.download !== undefined) {
    //   link.setAttribute("href", url);
    //   link.setAttribute("target", "_blank");
    //   link.style.visibility = 'hidden';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }

    // This can be downloaded directly:
    const self: this = this;
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function (event) {
      var blob = xhr.response;

      // var blob = new Blob([contents], {type: application/json});

      var dlink = document.createElement('a');

      if (idNumber == 1) {
        dlink.download = self.fsDownload;
      } else {
        dlink.download = self.fbDownload;
      }
      // dlink.download = name;

      dlink.href = window.URL.createObjectURL(blob);

      document.body.appendChild(dlink);
      dlink.click();

      setTimeout(() => {
        document.body.removeChild(dlink);
        dlink.remove();
      }, 10);

    };
    xhr.open('GET', url);
    xhr.send();
  }

}
