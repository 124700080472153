import { CharacterSheetData } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import * as datasSelector from './../../../selectors/datas.selectors';
import * as characterSelector from './../../../selectors/character.selectors';
import * as pmSelector from './../../../selectors/pm.selectors';
import * as layout from './../../../actions/layout';
import * as chat from './../../../actions/chat';
import * as character from './../../../actions/character';
import { MapState, LayoutState } from '../../../modules/utilities/utilities.constants';
import { AuthenticationService } from '../../../services/authentication.service';
import { Role } from '../../../models/data/application.data';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { PresenceService } from '../../../services/presence.service';
import { PmService } from '../../../services/pm.service';
import { FirestoreService } from '../../../services/firestore.service';
import { ChatService } from 'src/app/modules/chatRoom/services/chat.service';

// Utilities
@Component({
  selector: 'home-interna-component',
  templateUrl: './home-interna.component.html',
  styleUrls: ['./home-interna.component.less']
})

export class HomeInternaComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "HomeInternaComponent";
  @ViewChild('floatingScroller', { static: false }) private floatingScroller: ElementRef;
  // private currentMapState: MapState = MapState.mainMap;

  public layoutUIState$: Observable<LayoutState>;
  public isCharacterSheetOpen$: Observable<boolean>;
  public isUfficiOpen$: Observable<boolean>;
  public isAnagrafeOpen$: Observable<boolean>;
  public isVoltiTypeOpen$: Observable<boolean>;
  public isCambiaVoltoOpen$: Observable<boolean>;
  public isCambiaNomeOpen$: Observable<boolean>;
  public isUfficiStaffOpen$: Observable<boolean>;
  public isBankOpen$: Observable<boolean>;
  public isSecretChatsOpen$: Observable<boolean>;
  public isCreaSecretChatOpen$: Observable<boolean>;
  public isPresentiOpen$: Observable<boolean>;
  public canAccess$: Observable<boolean>;
  public canRestore$: Observable<boolean>;
  public myCharacterSheet$: Observable<CharacterSheetData>
  public isCreaMessaggioOpen$: Observable<boolean>;
  public isMessaggiRicevutiOpen$: Observable<boolean>;
  public scrollTopFloatingToggle$: Observable<boolean>;
  public isLeggiMessaggioOpen$: Observable<boolean>;
  public hasNewMessages$: Observable<string[]>;
  public isRulesOpen$: Observable<boolean>;
  public isMeteoOpen$: Observable<boolean>;
  public getisUfficiMappaOpen$: Observable<boolean>;
  public getisUfficiRazzeOpen$: Observable<boolean>;
  public getisUfficiClanOpen$: Observable<boolean>;
  public getisUfficiCorpOpen$: Observable<boolean>;
  public getisUfficiCreditsOpen$: Observable<boolean>;
  public getIsLocationsOpen$: Observable<boolean>;
  public getIsEventsOpen$: Observable<boolean>;
  public isFirstEntrance$: Observable<boolean>;

  public staffNews$: Observable<any>;
  public entranceMsg$: Observable<any>;

  public onlineUsersCount$: Observable<number>;

  public refreshDowntime: boolean = false;
  public staffNewDialogIsOpen: boolean = false;

  menuContainerMaxWidth: number = 0;
  @ViewChild('menuCont', { static: false }) private menuContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private firestoreService: FirestoreService,
    private presenceService: PresenceService,
    private pmService: PmService,
    private chatService: ChatService
  ) {

    // avoid rich copy text
    document.addEventListener('copy', function (e: any) {
      let selectedText = ""
      if (window.getSelection) {
        selectedText = window.getSelection().toString();
        selectedText = selectedText.replace(/ +(?= )/g, '');
      }
      e.clipboardData.setData('text/plain', selectedText);
      e.preventDefault(); // We want to write our data to the clipboard, not data from any user selection
    });
  }

  public ngOnInit() {
    this.layoutUIState$ = this.store.select(layoutSelector.getLayoutUIState);
    this.isCharacterSheetOpen$ = this.store.select(layoutSelector.getisCharacterSheetOpen);

    this.isUfficiOpen$ = this.store.select(layoutSelector.getisUfficiOpen);
    this.isAnagrafeOpen$ = this.store.select(layoutSelector.getisAnagrafeOpen);
    this.isVoltiTypeOpen$ = this.store.select(layoutSelector.getisVoltiTypeOpen);
    this.isCambiaVoltoOpen$ = this.store.select(layoutSelector.getisCambiaVoltoOpen);
    this.isCambiaNomeOpen$ = this.store.select(layoutSelector.getisCambiaNomeOpen);
    this.isUfficiStaffOpen$ = this.store.select(layoutSelector.getisUfficiStaffOpen);
    this.isBankOpen$ = this.store.select(layoutSelector.getisBankOpen);
    this.isSecretChatsOpen$ = this.store.select(layoutSelector.getisUfficiSecretChatsOpen);
    this.isCreaSecretChatOpen$ = this.store.select(layoutSelector.getisUfficiCreaSecretChatOpen);
    this.getisUfficiMappaOpen$ = this.store.select(layoutSelector.getisUfficiMappaOpen);
    this.getisUfficiRazzeOpen$ = this.store.select(layoutSelector.getisUfficiRazzeOpen);
    this.getisUfficiClanOpen$ = this.store.select(layoutSelector.getisUfficiClanOpen);
    this.getisUfficiCorpOpen$ = this.store.select(layoutSelector.getisUfficiCorpOpen);
    this.getisUfficiCreditsOpen$ = this.store.select(layoutSelector.getisUfficiCreditsOpen);

    this.isPresentiOpen$ = this.store.select(layoutSelector.getisPresentiOpen);
    this.canAccess$ = this.store.select(characterSelector.canAccess);
    this.canRestore$ = this.store.select(characterSelector.canRestore);
    this.myCharacterSheet$ = this.store.select(characterSelector.getCharacterSheet);

    this.isCreaMessaggioOpen$ = this.store.select(layoutSelector.getIsCreaMessaggioOpen);
    this.isMessaggiRicevutiOpen$ = this.store.select(layoutSelector.getIsMessaggiRicevutiOpen);
    this.isLeggiMessaggioOpen$ = this.store.select(layoutSelector.getIsLeggiMessaggioOpen);
    this.hasNewMessages$ = this.store.select(pmSelector.getNewPM);

    this.isRulesOpen$ = this.store.select(layoutSelector.getIsRulesOpen);
    this.isMeteoOpen$ = this.store.select(layoutSelector.getIsMeteoOpen);
    this.getIsEventsOpen$ = this.store.select(layoutSelector.getIsEventsCalendarOpen);
    this.getIsLocationsOpen$ = this.store.select(layoutSelector.getIsLocationsOpen);
    this.isFirstEntrance$ = this.store.select(characterSelector.getIsFirstEntrance);

    this.staffNews$ = this.store.select(datasSelector.getStaffNews);
    this.entranceMsg$ = this.store.select(datasSelector.getFirstEntranceMsg);

    this.scrollTopFloatingToggle$ = this.store.select(layoutSelector.getScrollTopFloatingToggle);

    this.onlineUsersCount$ = this.store.select(characterSelector.getOnlineUsersCount);
    // this.scrollTopFloatingToggle$.skip(1).subscribe((aValue: boolean) => {
    //   // this.scrollToTop();
    // });
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public logout() {
    this.authenticationService.logout();
  }

  test(text: string) {
    console.log(text);
    alert(text);
  }

  goMainMap() {
    this.store.dispatch(new chat.StoreSelectedChat(""));
    this.store.dispatch(new layout.UpdateMapStateAction(MapState.mainMap));
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Maps));
  }

  goToAdminPanel() {
    this.store.dispatch(new layout.GoToCPanelAction());
    // this.store.dispatch(new chat.StoreSelectedChat(""));
    this.store.dispatch(new chat.StoreSelectedChat(""));
  }

  goToRestoreBCK() {
    this.store.dispatch(new layout.GoToRPanelAction());
  }

  goScheda() {
    const myUID: string = fromRoot.getState(this.store).character.myUID;
    this.store.dispatch(new character.SelectplayerID(myUID));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
  }

  goUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction());
  }

  goPresenti() {
    this.store.dispatch(new layout.TogglePresentiAction());
  }

  goLocations() {
    this.store.dispatch(new layout.ToggleLocationsAction());
  }

  goEventi() {
    this.store.dispatch(new layout.ToggleEventsCalendarAction());
  }

  backToHomeInterna() {
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: false, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
  }

  openMessaggi() {
    // this.store.dispatch(new layout.ToggleCreaMessaggioBoxAction());
    this.store.dispatch(new layout.ToggleMessaggiRicevutiBoxAction());
  }

  openRules() {
    this.store.dispatch(new layout.ToggleRulesBoxAction());
  }

  goMeteo() {
    this.store.dispatch(new layout.ToggleMeteoBoxAction());
  }

  refreshState() {
    if (this.refreshDowntime == false) {
      let timeOutTime: number = 120000;

      // if in chat the timeout is 1 minute and not 2 minutes
      const selectedChatID: string = fromRoot.getState(this.store).chat.selectedChat;
      if (Functions.IsStringEmpty(selectedChatID) == false) {
        timeOutTime = 60000;
      }

      this.refreshDowntime = true;
      this.firestoreService.forceRefresh();
      this.presenceService.forceRefresh();
      this.chatService.forceRefresh();

      const self: this = this;
      setTimeout(() => {
        self.refreshDowntime = false;
      }, timeOutTime);
    }
  }

  public onResize(event?: any) {
    let height = this.menuContainer.nativeElement.offsetHeight;
    let width = this.menuContainer.nativeElement.offsetWidth;
    let maxWidht = height * 6.175;
    // if (maxWidht > 1229)
    //   maxWidht = 1229;
    this.menuContainerMaxWidth = maxWidht;
    this.cdr.detectChanges();
  }

  scrollToTop(): void {
    if (Functions.IsNullOrUndefined(this.floatingScroller) == false)
      this.floatingScroller.nativeElement.scrollTop = 0;
  }

  staffNewsClicked() {
    this.staffNewDialogIsOpen = true;
  }

  closeFirstEntranceDialog() {
    const mySheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    mySheet.isFirstEntrance = false;
    this.firestoreService.updateCharacterSheet(undefined, mySheet, false);
  }

}
