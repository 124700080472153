import * as layout from '../actions/layout';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import { LayoutState, CPanelState, MapState } from '../modules/utilities/utilities.constants';
import { StandardChat, chatGroups, SpecialChat } from '../models/data/application.data';

export interface State {
  layoutState: LayoutState;
  mapState: MapState;
  mapSelectedArea: chatGroups;

  cPanelState: CPanelState;
  isCharacterSheetOpen: boolean;
  isPresentiOpen: boolean;

  isUfficiOpen: boolean;
  isAnagrafeOpen: boolean;
  isVoltiTypeOpen: boolean;
  isCambiaVoltoOpen: boolean;
  isCambiaNomeOpen: boolean;
  isUfficiStaffOpen: boolean;
  isBankOpen: boolean;
  isUfficiSecretChatsOpen: boolean;
  isUfficiCreaSecretChatOpen: boolean;
  isUfficiMappaOpen: boolean;
  isUfficiRazzeOpen: boolean;
  isUfficiClanOpen: boolean;
  isUfficiCorpOpen: boolean;
  isUfficiCreditsOpen: boolean;

  isCreaMessaggioOpen: boolean;
  isMessaggiRicevutiOpen: boolean;
  isLeggiMessaggioOpen: boolean;

  isRulesOpen: boolean;
  isLocationsOpen: boolean;
  isEventsCalendarOpen: boolean;
  isMeteoOpen: boolean;

  isChatActionBoxOpen: boolean;
  isChatInstructionBoxOpen: boolean;
  isChatSkillBoxOpen: boolean;
  isChatItemBoxOpen: boolean;
  isChatDiceBoxOpen: boolean;
  isChatDiceResponseBoxOpen: boolean;
  isChatDiceDmgBoxOpen: boolean;
  isSussuraActive: boolean;
  isFateActive: boolean
  isChatSchedaTatticaOpen: boolean;
  isGestisciChatPrivataOpen: boolean;

  standardChatsList: StandardChat[];
  standardChatsV: number;
  stdChatsFullCache: boolean;

  specialChatsList: SpecialChat[];
  specialChatsV: number;
  spcChatsFullCache: boolean;

  scrollTopFloatingToggle: boolean;
}

export const initialState: State = {
  layoutState: LayoutState.HomeEsterna,
  mapState: MapState.mainMap,
  mapSelectedArea: undefined,
  cPanelState: CPanelState.CPanelHome,
  isCharacterSheetOpen: false,
  isUfficiOpen: false,
  isAnagrafeOpen: false,
  isVoltiTypeOpen: false,
  isCambiaVoltoOpen: false,
  isCambiaNomeOpen: false,
  isUfficiStaffOpen: false,
  isBankOpen: false,
  isUfficiSecretChatsOpen: false,
  isUfficiCreaSecretChatOpen: false,
  isUfficiMappaOpen: false,
  isUfficiRazzeOpen: false,
  isUfficiClanOpen: false,
  isUfficiCorpOpen: false,
  isUfficiCreditsOpen: false,
  isPresentiOpen: false,
  isCreaMessaggioOpen: false,
  isMessaggiRicevutiOpen: false,
  isLeggiMessaggioOpen: false,
  isRulesOpen: false,
  isMeteoOpen: false,
  isEventsCalendarOpen: false,
  isLocationsOpen: false,
  isChatActionBoxOpen: false,
  isChatInstructionBoxOpen: false,
  isChatSkillBoxOpen: false,
  isChatItemBoxOpen: false,
  isChatDiceBoxOpen: false,
  isChatDiceResponseBoxOpen: false,
  isChatDiceDmgBoxOpen: false,
  isSussuraActive: false,
  isFateActive: false,
  isChatSchedaTatticaOpen: false,
  isGestisciChatPrivataOpen: false,

  standardChatsList: [],
  standardChatsV: 0,
  stdChatsFullCache: false,

  specialChatsList: [],
  specialChatsV: 0,
  spcChatsFullCache: false,

  scrollTopFloatingToggle: false
};

export function reducer(state: State = initialState, action: layout.Actions): State {
  switch (action.type) {

    case layout.UPDATE_LAYOUT_STATE:
      const newLayoutState: LayoutState = action.payload as LayoutState;

      // check if leaving a chat
      if (state.layoutState == LayoutState.Chat && newLayoutState == LayoutState.Maps) {
        return Object.assign({}, state, {
          layoutState: newLayoutState,
          mapSelectedArea: undefined,
          isChatActionBoxOpen: false,
          isChatInstructionBoxOpen: false,
          isChatSkillBoxOpen: false,
          isChatItemBoxOpen: false,
          isChatDiceBoxOpen: false,
          isChatDiceResponseBoxOpen: false,
          isChatDiceDmgBoxOpen: false,
          isSussuraActive: false,
          isFateActive: false,
          isChatSchedaTatticaOpen: false,
          isGestisciChatPrivataOpen: false,
        });
      }

      return Object.assign({}, state, {
        layoutState: newLayoutState,
        mapSelectedArea: undefined
      });

    case layout.CHANGE_CPANEL_STATE:
      const newCPaneltState: CPanelState = action.payload as CPanelState;
      return Object.assign({}, state, {
        cPanelState: newCPaneltState,
        mapSelectedArea: undefined
      });

    case layout.UPDATE_MAP_STATE:
      const newMapState: MapState = action.payload as MapState;
      return Object.assign({}, state, {
        mapState: newMapState,
        mapSelectedArea: undefined
      });

    case layout.GOTO_HOME_INTERNA:
      // check if leaving cPanel
      if (state.layoutState == LayoutState.CPanel) {
        return Object.assign({}, state, {
          layoutState: LayoutState.Maps,
          cPanelState: CPanelState.CPanelHome
        });
      }

      return Object.assign({}, state, {
        layoutState: LayoutState.Maps
      });

    case layout.GOTO_HOME_ESTERNA:
      return Object.assign({}, state, {
        layoutState: LayoutState.HomeEsterna
      });

    case layout.GOTO_LOGOUT:
      return Object.assign({}, state, {
        layoutState: LayoutState.Logout
      });

    case layout.SET_MAP_SELECTED_AREA:
      const mapSelectedArea: chatGroups = action.payload as chatGroups;
      return Object.assign({}, state, {
        mapSelectedArea: mapSelectedArea
      });

    case layout.TOGGLE_CHARACTER_SHEET:
      const stateCSheetValue: any = action.payload;
      let newPresentiState: boolean = false;
      if (stateCSheetValue.doNotClosePresence) {
        newPresentiState = state.isPresentiOpen;
      }

      let newAnagrafeState: boolean = false;
      if (stateCSheetValue.doNotCloseAnagrafica) {
        newAnagrafeState = state.isAnagrafeOpen;
      }

      let newVoltiState: boolean = false;
      if (stateCSheetValue.doNotCloseVolti) {
        newVoltiState = state.isVoltiTypeOpen;
      }

      let newStaffState: boolean = false;
      if (stateCSheetValue.doNotCloseStaff) {
        newStaffState = state.isUfficiStaffOpen;
      }

      let newRazzeState: boolean = false;
      if (stateCSheetValue.doNotCloseRazze) {
        newRazzeState = state.isUfficiRazzeOpen;
      }

      let newClanState: boolean = false;
      if (stateCSheetValue.doNotCloseClan) {
        newClanState = state.isUfficiClanOpen;
      }

      let newCorpState: boolean = false;
      if (stateCSheetValue.doNotCloseCorp) {
        newCorpState = state.isUfficiCorpOpen;
      }

      return Object.assign({}, state, {
        isCharacterSheetOpen: stateCSheetValue.newState,
        isPresentiOpen: newPresentiState,
        isUfficiOpen: false,
        isAnagrafeOpen: newAnagrafeState,
        isVoltiTypeOpen: newVoltiState,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: newStaffState,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: newRazzeState,
        isUfficiClanOpen: newClanState,
        isUfficiCorpOpen: newCorpState,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI:
      let stateUfficiValue: boolean = action.payload as boolean;
      if(Functions.IsNullOrUndefined(stateUfficiValue) === true) {
        stateUfficiValue = !state.isUfficiOpen;
      }

      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: stateUfficiValue,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_ANAGRAFE:
      const stateAnagrafeValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: stateAnagrafeValue,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_VOLTI_TYPE:
      const stateVoltiTypeValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: stateVoltiTypeValue,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_CAMBIA_VOLTO:
      const stateVoltoCambiaValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: stateVoltoCambiaValue,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_CAMBIA_NOME:
      const stateNomeCambiaValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: stateNomeCambiaValue,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_STAFF:
      const stateUfficiStaffValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: stateUfficiStaffValue,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_BANK:
      const stateUfficiBankValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: stateUfficiBankValue,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_SECRET_CHATS:
      const stateUfficiSecretChatsValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: stateUfficiSecretChatsValue,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_CREA_SECRET_CHAT:
      const stateUfficiCreaSecretChatValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: stateUfficiCreaSecretChatValue,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_MAPPA:
      const stateUfficiMappaValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: stateUfficiMappaValue,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_RAZZE:
      const stateUfficiRazzeValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: stateUfficiRazzeValue,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_CLAN:
      const stateUfficiClanValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: stateUfficiClanValue,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_CORP:
      const stateUfficiCorpValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: stateUfficiCorpValue,
        isUfficiCreditsOpen: false,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_UFFICI_CREDITS:
      const stateUfficiCreditsValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isPresentiOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: stateUfficiCreditsValue,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });


    case layout.TOGGLE_PRESENTI:
      let statePresentiValue: boolean = action.payload as boolean;
      if(Functions.IsNullOrUndefined(statePresentiValue) === true) {
        statePresentiValue = !state.isPresentiOpen;
      }

      return Object.assign({}, state, {
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isPresentiOpen: statePresentiValue,
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        mapSelectedArea: undefined,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_CHAT_ACTION_BOX:
      const newActionValue: boolean = !state.isChatActionBoxOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: newActionValue,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: false
      });

    case layout.TOGGLE_CHAT_INSTRUCTION_BOX:
      const newInstructionValue: boolean = !state.isChatInstructionBoxOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: newInstructionValue
      });

    case layout.TOGGLE_CHAT_SKILL_BOX:
      const newSkillValue: boolean = !state.isChatSkillBoxOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: newSkillValue,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: false
      });

    case layout.TOGGLE_CHAT_ITEM_BOX:
      const newItemValue: boolean = !state.isChatItemBoxOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: newItemValue,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: false
      });

    case layout.TOGGLE_CHAT_DICE_BOX:
      const newDiceValue: boolean = !state.isChatDiceBoxOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: newDiceValue,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: false
      });

    case layout.TOGGLE_CHAT_DICE_RESPONSE_BOX:
      let newDiceResponseValue: boolean = action.payload as boolean;
      if (Functions.IsNullOrUndefined(newDiceResponseValue)) {
        newDiceResponseValue = !state.isChatDiceResponseBoxOpen;
      }
      // const newDiceResponseValue: boolean = !state.isChatDiceResponseBoxOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: newDiceResponseValue,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: false
      });

    case layout.TOGGLE_CHAT_DICE_DMG_BOX:
      let newDiceDmgValue: boolean = action.payload as boolean;
      if (Functions.IsNullOrUndefined(newDiceDmgValue)) {
        newDiceDmgValue = !state.isChatDiceDmgBoxOpen;
      }
      // const newDiceDmgValue: boolean = !state.isChatDiceDmgBoxOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: newDiceDmgValue,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: false
      });

    case layout.TOGGLE_CHAT_SCHEDA_TATTICA:
      const newSchedaTAttivaValue: boolean = (action.payload as boolean) || !state.isChatSchedaTatticaOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: newSchedaTAttivaValue,
        isGestisciChatPrivataOpen: false,
        isChatInstructionBoxOpen: false
      });

    case layout.TOGGLE_GESTISCI_CHAT_PRIVATA:
      const newGestisciChatPrivataValue: boolean = (action.payload as boolean) || !state.isGestisciChatPrivataOpen;
      return Object.assign({}, state, {
        isChatItemBoxOpen: false,
        isChatSkillBoxOpen: false,
        isChatDiceBoxOpen: false,
        isChatDiceResponseBoxOpen: false,
        isChatDiceDmgBoxOpen: false,
        isChatActionBoxOpen: false,
        isChatSchedaTatticaOpen: false,
        isGestisciChatPrivataOpen: newGestisciChatPrivataValue,
        isChatInstructionBoxOpen: false
      });


    case layout.TOGGLE_CHAT_SUSSURRO:
      const newSussurroValue: boolean = (action.payload as boolean) || !state.isSussuraActive;
      return Object.assign({}, state, {
        isSussuraActive: newSussurroValue
      });

    case layout.TOGGLE_CHAT_FATO:
      const newFateValue: boolean = (action.payload as boolean) || !state.isFateActive;
      return Object.assign({}, state, {
        isFateActive: newFateValue
      });

    case layout.STORE_CHATS_LIST_DATA:
      const scPayload: any = action.payload;
      const standardChatsList: StandardChat[] = scPayload.standardChats as StandardChat[];
      const stdChatV: number = scPayload.ver as number;
      const stdChatFullCache: any = scPayload.fullFlag as boolean;
      return Object.assign({}, state, {
        standardChatsList: standardChatsList,
        standardChatsV: stdChatV,
        stdChatsFullCache: stdChatFullCache
      });

    case layout.STORE_SPECIAL_CHATS_LIST_DATA:
      const spcPayload: any = action.payload;
      const specialChatsList: SpecialChat[] = spcPayload.specialChats as SpecialChat[];
      const spcChatV: number = spcPayload.ver as number;
      const spcChatFullCache: any = spcPayload.fullFlag as boolean;
      return Object.assign({}, state, {
        specialChatsList: specialChatsList,
        specialChatsV: spcChatV,
        spcChatsFullCache: spcChatFullCache
      });

    case layout.SCROLL_TO_TOP_FLOATING:
      const scrollTogle: boolean = !state.scrollTopFloatingToggle;
      return Object.assign({}, state, {
        scrollTopFloatingToggle: scrollTogle
      });

    case layout.TOGGLE_CREA_MESSAGGIO_BOX:
      const newisCreaMessaggioOpen: boolean = (action.payload as boolean) || !state.isCreaMessaggioOpen;
      return Object.assign({}, state, {
        isCreaMessaggioOpen: newisCreaMessaggioOpen,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isPresentiOpen: false,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_MESSAGGI_RICEVUTI_BOX:
      const newisMessaggiRicevutiOpen: boolean = (action.payload as boolean) || !state.isMessaggiRicevutiOpen;
      return Object.assign({}, state, {
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: newisMessaggiRicevutiOpen,
        isLeggiMessaggioOpen: false,
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isPresentiOpen: false,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_LEGGI_MESSAGGIO_BOX:
      const newisLeggiMessaggioOpen: boolean = (action.payload as boolean) || !state.isLeggiMessaggioOpen;
      return Object.assign({}, state, {
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: newisLeggiMessaggioOpen,
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isPresentiOpen: false,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_RULES_BOX:
      const newIsRulesOpen: boolean = (action.payload as boolean) || !state.isRulesOpen;
      return Object.assign({}, state, {
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isPresentiOpen: false,
        isRulesOpen: newIsRulesOpen,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_METEO_BOX:
      const newIsMeteoOpen: boolean = (action.payload as boolean) || !state.isMeteoOpen;
      return Object.assign({}, state, {
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isPresentiOpen: false,
        isRulesOpen: false,
        isMeteoOpen: newIsMeteoOpen,
        isLocationsOpen: false,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_LOCATIONS:
      const newIsLocationsOpen: boolean = (action.payload as boolean) || !state.isLocationsOpen;
      return Object.assign({}, state, {
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isPresentiOpen: false,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: newIsLocationsOpen,
        isEventsCalendarOpen: false
      });

    case layout.TOGGLE_EVENTS_CALENDAR:
      const newIsEventsCalendarOpen: boolean = (action.payload as boolean) || !state.isEventsCalendarOpen;
      return Object.assign({}, state, {
        isCreaMessaggioOpen: false,
        isMessaggiRicevutiOpen: false,
        isLeggiMessaggioOpen: false,
        isCharacterSheetOpen: false,
        isUfficiOpen: false,
        isAnagrafeOpen: false,
        isVoltiTypeOpen: false,
        isCambiaVoltoOpen: false,
        isCambiaNomeOpen: false,
        isUfficiStaffOpen: false,
        isBankOpen: false,
        isUfficiSecretChatsOpen: false,
        isUfficiCreaSecretChatOpen: false,
        isUfficiMappaOpen: false,
        isUfficiRazzeOpen: false,
        isUfficiClanOpen: false,
        isUfficiCorpOpen: false,
        isUfficiCreditsOpen: false,
        isPresentiOpen: false,
        isRulesOpen: false,
        isMeteoOpen: false,
        isLocationsOpen: false,
        isEventsCalendarOpen: newIsEventsCalendarOpen
      });


    default: {
      return state;
    }
  }
}

export const getLayoutUIState: (state: State) => LayoutState = (state: State) => state.layoutState;
export const getCPanelState: (state: State) => CPanelState = (state: State) => state.cPanelState;
export const getMapUIState: (state: State) => MapState = (state: State) => state.mapState;
export const getMapSelectedArea: (state: State) => chatGroups = (state: State) => state.mapSelectedArea;
export const getisCharacterSheetOpen: (state: State) => boolean = (state: State) => state.isCharacterSheetOpen;
export const getisUfficiOpen: (state: State) => boolean = (state: State) => state.isUfficiOpen;
export const getisAnagrafeOpen: (state: State) => boolean = (state: State) => state.isAnagrafeOpen;
export const getisVoltiTypeOpen: (state: State) => boolean = (state: State) => state.isVoltiTypeOpen;
export const getisCambiaVoltoOpen: (state: State) => boolean = (state: State) => state.isCambiaVoltoOpen;
export const getisCambiaNomeOpen: (state: State) => boolean = (state: State) => state.isCambiaNomeOpen;
export const getisUfficiStaffOpen: (state: State) => boolean = (state: State) => state.isUfficiStaffOpen;
export const getisBankOpen: (state: State) => boolean = (state: State) => state.isBankOpen;
export const getisUfficiSecretChatsOpen: (state: State) => boolean = (state: State) => state.isUfficiSecretChatsOpen;
export const getisUfficiCreaSecretChatOpen: (state: State) => boolean = (state: State) => state.isUfficiCreaSecretChatOpen;
export const getisUfficiMappaOpen: (state: State) => boolean = (state: State) => state.isUfficiMappaOpen;
export const getisUfficiRazzeOpen: (state: State) => boolean = (state: State) => state.isUfficiRazzeOpen;
export const getisUfficiClanOpen: (state: State) => boolean = (state: State) => state.isUfficiClanOpen;
export const getisUfficiCorpOpen: (state: State) => boolean = (state: State) => state.isUfficiCorpOpen;
export const getisUfficiCreditsOpen: (state: State) => boolean = (state: State) => state.isUfficiCreditsOpen;
export const getisPresentiOpen: (state: State) => boolean = (state: State) => state.isPresentiOpen;
export const getisChatActionBoxOpen: (state: State) => boolean = (state: State) => state.isChatActionBoxOpen;
export const getisChatInstructionBoxOpen: (state: State) => boolean = (state: State) => state.isChatInstructionBoxOpen;
export const getisChatSkillBoxOpen: (state: State) => boolean = (state: State) => state.isChatSkillBoxOpen;
export const getisChatDiceBoxOpen: (state: State) => boolean = (state: State) => state.isChatDiceBoxOpen;
export const getisChatDiceResponseBoxOpen: (state: State) => boolean = (state: State) => state.isChatDiceResponseBoxOpen;
export const getIsChatDiceDmgBoxOpen: (state: State) => boolean = (state: State) => state.isChatDiceDmgBoxOpen;
export const getisChatItemBoxOpen: (state: State) => boolean = (state: State) => state.isChatItemBoxOpen;
export const getisChatSussurroEnable: (state: State) => boolean = (state: State) => state.isSussuraActive;
export const getisChatFateEnable: (state: State) => boolean = (state: State) => state.isFateActive;
export const getisChatSchedaTatticaOpen: (state: State) => boolean = (state: State) => state.isChatSchedaTatticaOpen;
export const getisGestisciChatPrivataOpen: (state: State) => boolean = (state: State) => state.isGestisciChatPrivataOpen;
export const getStandardChatsList: (state: State) => StandardChat[] = (state: State) => state.standardChatsList;
export const getSpecialChatsList: (state: State) => SpecialChat[] = (state: State) => state.specialChatsList;
export const getScrollTopFloatingToggle: (state: State) => boolean = (state: State) => state.scrollTopFloatingToggle;
export const getIsCreaMessaggioOpen: (state: State) => boolean = (state: State) => state.isCreaMessaggioOpen;
export const getIsMessaggiRicevutiOpen: (state: State) => boolean = (state: State) => state.isMessaggiRicevutiOpen;
export const getIsLeggiMessaggioOpen: (state: State) => boolean = (state: State) => state.isLeggiMessaggioOpen;
export const getIsRulesOpen: (state: State) => boolean = (state: State) => state.isRulesOpen;
export const getIsLocationsOpen: (state: State) => boolean = (state: State) => state.isLocationsOpen;
export const getIsEventsCalendarOpen: (state: State) => boolean = (state: State) => state.isEventsCalendarOpen;
export const getIsMeteoOpen: (state: State) => boolean = (state: State) => state.isMeteoOpen;
