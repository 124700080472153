import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import { CharacterSheetData, Clan, Corp, AffiliationRole } from 'src/app/models/data/application.data';
import { first } from 'rxjs/operators';

@Component({
  selector: 'cPanel-job-money-manager',
  templateUrl: 'cPanel-job-money-manager.component.html',
  styleUrls: ['cPanel-job-money-manager.component.less'],
})
export class CPanelJobMoneyManagerComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelJobMoneyManager";
  private lastDoc: AngularFirestoreDocument<any>;
  private lastObservable: Observable<any>;
  private lastSubscription: Subscription;
  public lastMoneyPaied: string;

  //#region - get users
  // private usersCollection: AngularFirestoreCollection<CharacterSheetData>;
  // private usersCollectionObservable: Observable<CharacterSheetData[]>;
  // private usersSubscription: Subscription;
  private allCharacters: CharacterSheetData[];
  private clans: Map<string, Clan>;
  private corps: Map<string, Corp>;
  //#endregion - get users

  constructor(
    public cPanelService: CPanelService,
    private toastr: ToastrService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
    const self: this = this;
    this.lastDoc = this.afs.doc<any>('settings/lastMoneyPaied');
    this.lastObservable = this.lastDoc.valueChanges();
    this.lastSubscription = this.lastObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ Last Money Paied cPanel", self.moduleName, "settings/lastMoneyPaied", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.lastMoneyPaied = data.value;
      else
        self.lastMoneyPaied = "-";
    });
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.lastSubscription) == false) {
      this.lastSubscription.unsubscribe();
    }

    // if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
    //   this.usersSubscription.unsubscribe();
    // }
  }

  public assegna() {
    const self: this = this;
    const sheetCollection: AngularFirestoreCollection<CharacterSheetData> = this.afs.collection<CharacterSheetData>('users');
    const sheetCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = sheetCollection.ref.get({ source: 'server' });
    sheetCollectionObservable.then((data) => {
      // this.usersCollection = this.afs.collection<CharacterSheetData>('users');
      // this.usersCollectionObservable = this.usersCollection.valueChanges();
      // this.usersSubscription = this.usersCollectionObservable
      //   .pipe(first())
      //   .subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all users cpanel", self.moduleName, "users", (data.docs as any[]).length);
      }

      // safety check
      if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
        return;

      const normalizedData: CharacterSheetData[] = [];
      data.forEach(a => {
        const entry: CharacterSheetData = a.data() as CharacterSheetData;
        normalizedData.push(entry);
      });

      self.allCharacters = normalizedData;

      const clansCollection: AngularFirestoreCollection<Clan> = this.afs.collection<Clan>('clans');
      const clansCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = clansCollection.ref.get({ source: 'server' });
      clansCollectionObservable.then((data) => {
        self.clans = new Map();
        data.forEach(a => {
          const entry: Clan = a.data() as Clan;
          self.clans.set(entry.uid, entry);
        });

        const corpsCollection: AngularFirestoreCollection<Corp> = this.afs.collection<Corp>('corps');
        const corpsCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = corpsCollection.ref.get({ source: 'server' });
        corpsCollectionObservable.then((data) => {
          self.corps = new Map();
          data.forEach(a => {
            const entry: Corp = a.data() as Corp;
            self.corps.set(entry.uid, entry);
          });

          self.proceed();
        });
      });
      // self.proceed();
    });
  }

  public proceed() {
    //#region - add stipendi
    for (const aCharacter of this.allCharacters) {
      const characterUpdated: CharacterSheetData = Object.assign({}, aCharacter);

      let clanMoney: number = 0;
      if (Functions.IsStringEmpty(characterUpdated.clan) == false) {
        const aClan: Clan = this.clans.get(characterUpdated.clan);
        if (Functions.IsNullOrUndefined(aClan) == false) {
          const clansRoles: AffiliationRole[] = aClan.roles || [];
          const userRole: AffiliationRole = clansRoles.find((anAffiliationRole: AffiliationRole) => anAffiliationRole.roleGrade == characterUpdated.clanLvl);
          if (Functions.IsNullOrUndefined(userRole) == false) {
            clanMoney = userRole.money || 0;
          }
        }
      }

      let corpMoney: number = 0;
      if (Functions.IsStringEmpty(characterUpdated.corp) == false) {
        const aCorp: Clan = this.corps.get(characterUpdated.corp);
        if (Functions.IsNullOrUndefined(aCorp) == false) {
          const corpsRoles: AffiliationRole[] = aCorp.roles || [];
          const userRole: AffiliationRole = corpsRoles.find((anAffiliationRole: AffiliationRole) => anAffiliationRole.roleGrade == characterUpdated.corpLvl);
          if (Functions.IsNullOrUndefined(userRole) == false) {
            corpMoney = userRole.money || 0;
          }
        }
      }

      characterUpdated.bank = characterUpdated.bank + (characterUpdated.jobMoney || 0) + clanMoney + corpMoney;
      const characterDataJSON = JSON.parse(JSON.stringify(characterUpdated));
      const aplayerToEditDoc = this.afs.doc<any>('users/' + characterUpdated.uid);
      aplayerToEditDoc.update(characterDataJSON);
    }
    //#endregion - add stipendi


    //#region - update setting record
    const self: this = this;
    const lastValueUpdated = JSON.parse(JSON.stringify({ value: Functions.GetCurrentDate() }));
    this.lastDoc.set(lastValueUpdated)
      .then(() => {
        self.toastr.success('Stipendi assegnati!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })
    //#endregion - update setting record
  }

}
