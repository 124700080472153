import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-edit-start-message',
  templateUrl: 'cPanel-edit-start-message.component.html',
  styleUrls: ['cPanel-edit-start-message.component.less'],
})
export class CPanelEditStartMessageComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelEditStartMessage";
  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  //#region - starting message variables
  private startingMessageDoc: AngularFirestoreDocument<any>;
  private startingMessageObservable: Observable<any>;
  public startingMessageSubscription: Subscription;
  public startingMessage: any = { value: "" };
  //#endregion - starting message variables

  //#region - starting PM variables
  private startingPMDoc: AngularFirestoreDocument<any>;
  private startingPMObservable: Observable<any>;
  public startingPMSubscription: Subscription;
  public startingPM: any = { value: "" };
  //#endregion - starting message variables

  public config: any = {
    height: 550,
    menubar: false,
    theme: 'modern',
    plugins: [
      'advlist autolink link image imagetools lists charmap hr',
      'wordcount visualblocks visualchars code',
      'table contextmenu textcolor paste colorpicker',
    ],
    toolbar1:
      'bold italic underline strikethrough | undo redo | blockquote numlist bullist | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect | forecolor backcolor',
    toolbar2:
      'cut copy paste | outdent indent | link unlink image table | hr removeformat | subscript superscript | charmap | code',
    image_advtab: true,
    imagetools_toolbar:
      'rotateleft rotateright | flipv fliph | editimage imageoptions',
  };


  public ngOnInit() {
    let self = this;

    //#region - getting starting message
    this.startingMessageDoc = this.afs.doc<any>('settings/startingMsg');
    this.startingMessageObservable = this.startingMessageDoc.valueChanges();
    this.startingMessageSubscription = this.startingMessageObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ starting msg cPanel", self.moduleName, "settings/startingMsg", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false && Functions.IsNullOrUndefined(data.value) == false)
        self.startingMessage = data;
    });
    //#endregion - getting starting message

    //#region - getting starting PM
    this.startingPMDoc = this.afs.doc<any>('settings/startingPM');
    this.startingPMObservable = this.startingPMDoc.valueChanges();
    this.startingPMSubscription = this.startingPMObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ starting pm cPanel", self.moduleName, "settings/startingPM", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false && Functions.IsNullOrUndefined(data.value) == false)
        self.startingPM = data;
    });
    //#endregion - getting starting PM
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.startingMessageSubscription) == false) {
      this.startingMessageSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.startingPMSubscription) == false) {
      this.startingPMSubscription.unsubscribe();
    }
  }

  salva() {
    if (Functions.IsNullOrUndefined(this.startingMessage) == false) {
      let self = this;
      let newStartingMsg = JSON.parse(JSON.stringify(this.startingMessage));
      this.startingMessageDoc.update(newStartingMsg)
        .then(() => {
          self.toastr.success('Messaggio pubblico di benvenuto salvato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.write('settings/startingMsg', newStartingMsg).subscribe(() => {
      //   self.toastr.success('Messaggio pubblico di benvenuto salvato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
  }

  salvaPM() {
    if (Functions.IsNullOrUndefined(this.startingPM) == false) {
      let self = this;
      let newStartingPM = JSON.parse(JSON.stringify(this.startingPM));
      this.startingPMDoc.update(newStartingPM)
        .then(() => {
          self.toastr.success('Messaggio privato di benvenuto salvato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.write('settings/startingPM', newStartingPM).subscribe(() => {
      //   self.toastr.success('Messaggio privato di benvenuto salvato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
  }
}
