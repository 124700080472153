import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Skill, Race, StatsBonusFormula } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import * as math from 'mathjs';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { map, skip } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-archivio-skills',
  templateUrl: 'cPanel-archivio-skills.component.html',
  styleUrls: ['cPanel-archivio-skills.component.less'],
})
export class CPanelArchivioSkillsComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelArchivioSkill";

  //#region - modal variables
  statsRowData: any;
  isStatsModalVisible: boolean = false;
  strEq: string = "";
  aglEq: string = "";
  resEq: string = "";
  wsdEq: string = "";
  perEq: string = "";
  wilEq: string = "";

  strEqAdd: string = "";
  aglEqAdd: string = "";
  resEqAdd: string = "";
  wsdEqAdd: string = "";
  perEqAdd: string = "";
  wilEqAdd: string = "";

  salEq: string = "";
  salMEq: string = "";
  salEqAdd: string = "";
  salMEqAdd: string = "";

  durataEq: string = "1"

  error: boolean = false;
  //#endregion - modal variables

  //#region - skills variables
  private skillsCollection: AngularFirestoreCollection<Skill>;
  private skillsCollectionObservable: Observable<Skill[]>;
  private skillsSubscription: Subscription;
  public dataSource: LocalDataSource;
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true,
      inputClass: "editing-class"
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true,
      inputClass: "editing-class"
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    actions: {
      custom: [
        {
          name: 'setStats',
          // addButtonContent: '<i class="material-icons icon-add">exposure</i>'
          title: '<i class="material-icons icon-add">exposure</i>',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Skill'
      },
      race: {
        title: 'Razza',
        valuePrepareFunction: (value: string) => { return Race[value]; },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: 0, title: "Nephilim" },
              { value: 1, title: "Warlock" },
              { value: 2, title: "Sidhe" },
              { value: 3, title: "Lycan" },
              { value: 4, title: "Leech" },
              { value: 5, title: "Mondano" },
              { value: 6, title: "Fantasma" },
              { value: 7, title: "Demone" },
              { value: 8, title: "Angelo" },
              { value: 9, title: "Dimenticato" }
            ]
          }
        }
      },
      ico: {
        title: 'Icona',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
      description: {
        title: 'Descrizione',
        editor: {
          type: 'textarea'
        }
      },
      descrLv1: {
        title: 'Descrizione Livello 1',
        editor: {
          type: 'textarea'
        }
      },
      descrLv2: {
        title: 'Descrizione Livello 2',
        editor: {
          type: 'textarea'
        }
      },
      descrLv3: {
        title: 'Descrizione Livello 3',
        editor: {
          type: 'textarea'
        }
      },
      descrLv4: {
        title: 'Descrizione Livello 4',
        editor: {
          type: 'textarea'
        }
      },
      descrLv5: {
        title: 'Descrizione Livello 5',
        editor: {
          type: 'textarea'
        }
      },
      note: {
        title: 'Nota Bene',
        editor: {
          type: 'textarea'
        }
      },
      cost: {
        title: 'Costo Skill'
      }
    }
  };
  //#endregion - skills variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    // const asd = this.afs.collection<Skill>('skills');
    // const qwe = asd.stateChanges().pipe(skip(1)).subscribe((vrtbtr: any) => {
    //   const data = vrtbtr[0].payload.doc.data() as Skill;
    //   const btr = 10;
    // });

    this.skillsCollection = this.afs.collection<Skill>('skills');
    this.skillsCollectionObservable = this.skillsCollection.valueChanges();
    this.skillsSubscription = this.skillsCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all skills cpanel", self.moduleName, "skills", (data as any[]).length);
      }

      self.dataSource.load(data);
    });
  }

  public ngOnDestroy() {
    this.skillsSubscription.unsubscribe();
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.cPanelService.removeDB("skills", event.data.uid);
      // let self = this;
      // this.firestore.remove('skills/' + event.data.uid).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event) {
    event.newData.uid = Functions.CreateGuid();
    event.confirm.resolve();

    //save on firebase
    let newSkillData: Skill = new Skill();
    newSkillData.uid = event.newData.uid;
    newSkillData.name = event.newData.name;
    newSkillData.ico = event.newData.ico;
    newSkillData.race = parseInt(event.newData.race);
    newSkillData.description = event.newData.description;
    newSkillData.descrLv1 = event.newData.descrLv1;
    newSkillData.descrLv2 = event.newData.descrLv2;
    newSkillData.descrLv3 = event.newData.descrLv3;
    newSkillData.descrLv4 = event.newData.descrLv4;
    newSkillData.descrLv5 = event.newData.descrLv5;
    newSkillData.note = event.newData.note;
    newSkillData.cost = event.newData.cost;
    newSkillData.statsBonus = event.newData.statsBonus;

    let self = this;
    let newSkillDataJSON = JSON.parse(JSON.stringify(newSkillData));

    this.cPanelService.writeOnDB("skills", newSkillDataJSON.uid, newSkillDataJSON);
    // this.firestore.write('skills/' + newSkillDataJSON.uid, newSkillDataJSON).subscribe(() => {
    //   // self.updateDBVersion(newSkillDataJSON.uid);
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onEditConfirm(event) {
    event.confirm.resolve();

    //update on firebase
    let newSkillData: Skill = new Skill();
    newSkillData.uid = event.newData.uid;
    newSkillData.name = event.newData.name;
    newSkillData.ico = event.newData.ico;
    newSkillData.race = parseInt(event.newData.race);
    newSkillData.description = event.newData.description;
    newSkillData.descrLv1 = event.newData.descrLv1;
    newSkillData.descrLv2 = event.newData.descrLv2;
    newSkillData.descrLv3 = event.newData.descrLv3;
    newSkillData.descrLv4 = event.newData.descrLv4;
    newSkillData.descrLv5 = event.newData.descrLv5;
    newSkillData.note = event.newData.note;
    newSkillData.cost = event.newData.cost;
    newSkillData.statsBonus = event.newData.statsBonus;

    let self = this;
    let newSkillDataJSON = JSON.parse(JSON.stringify(newSkillData));
    this.cPanelService.writeOnDB("skills", newSkillDataJSON.uid, newSkillDataJSON);

    // this.firestore.write('skills/' + newSkillDataJSON.uid, newSkillDataJSON).subscribe(() => {
    //   // self.updateDBVersion(newSkillDataJSON.uid);
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onCustom(event) {
    this.statsRowData = event.data;
    const statsBonus: StatsBonusFormula = this.statsRowData.statsBonus || new StatsBonusFormula();

    this.strEq = statsBonus.str;
    this.aglEq = statsBonus.agl;
    this.resEq = statsBonus.res;
    this.wsdEq = statsBonus.wsd;
    this.perEq = statsBonus.per;
    this.wilEq = statsBonus.wil;

    this.strEqAdd = statsBonus.strAdd;
    this.aglEqAdd = statsBonus.aglAdd;
    this.resEqAdd = statsBonus.resAdd;
    this.wsdEqAdd = statsBonus.wsdAdd;
    this.perEqAdd = statsBonus.perAdd;
    this.wilEqAdd = statsBonus.wilAdd;

    this.salEq = statsBonus.sal;
    this.salMEq = statsBonus.salM;
    this.salEqAdd = statsBonus.salAdd;
    this.salMEqAdd = statsBonus.salMAdd;

    this.durataEq = statsBonus.durata || "1";
    this.error = false;
    this.isStatsModalVisible = true;
  }

  okModal() {
    this.error = false;
    let testScope = {
      lvl: 1,
      str: 1,
      agl: 1,
      res: 1,
      per: 1,
      wsd: 1,
      wil: 1,
      sal: 1,
      salM: 1
    }

    //transform , to .
    this.strEq = this.strEq.replace(",", ".");
    this.aglEq = this.aglEq.replace(",", ".");
    this.resEq = this.resEq.replace(",", ".");
    this.wsdEq = this.wsdEq.replace(",", ".");
    this.perEq = this.perEq.replace(",", ".");
    this.wilEq = this.wilEq.replace(",", ".");
    this.salEq = this.salEq.replace(",", ".");
    this.salMEq = this.salMEq.replace(",", ".");
    this.durataEq = this.durataEq.replace(",", ".");

    this.strEqAdd = this.strEqAdd.replace(",", ".");
    this.aglEqAdd = this.aglEqAdd.replace(",", ".");
    this.resEqAdd = this.resEqAdd.replace(",", ".");
    this.wsdEqAdd = this.wsdEqAdd.replace(",", ".");
    this.perEqAdd = this.perEqAdd.replace(",", ".");
    this.wilEqAdd = this.wilEqAdd.replace(",", ".");
    this.salEqAdd = this.salEqAdd.replace(",", ".");
    this.salMEqAdd = this.salMEqAdd.replace(",", ".");

    // variables can be read from the scope
    try {
      const resultStr: any = Math.floor(math.evaluate(this.strEq, testScope));
      const resultAgl: any = Math.floor(math.evaluate(this.aglEq, testScope));
      const resultRes: any = Math.floor(math.evaluate(this.resEq, testScope));
      const resultWsd: any = Math.floor(math.evaluate(this.wsdEq, testScope));
      const resultPer: any = Math.floor(math.evaluate(this.perEq, testScope));
      const resultWill: any = Math.floor(math.evaluate(this.wilEq, testScope));

      const resultSal: any = Math.floor(math.evaluate(this.salEq, testScope));
      const resultSalM: any = Math.floor(math.evaluate(this.salMEq, testScope));

      const resultDurata: any = Math.floor(math.evaluate(this.durataEq, testScope));

      const resultStrAdd: any = Math.floor(math.evaluate(this.strEqAdd, testScope));
      const resultAglAdd: any = Math.floor(math.evaluate(this.aglEqAdd, testScope));
      const resultResAdd: any = Math.floor(math.evaluate(this.resEqAdd, testScope));
      const resultWsdAdd: any = Math.floor(math.evaluate(this.wsdEqAdd, testScope));
      const resultPerAdd: any = Math.floor(math.evaluate(this.perEqAdd, testScope));
      const resultWillAdd: any = Math.floor(math.evaluate(this.wilEqAdd, testScope));

      const resultSalAdd: any = Math.floor(math.evaluate(this.salEqAdd, testScope));
      const resultSalMAdd: any = Math.floor(math.evaluate(this.salMEqAdd, testScope));

      if (resultStr == Infinity || resultAgl == Infinity || resultRes == Infinity || resultWsd == Infinity || resultPer == Infinity || resultWill == Infinity) {
        this.error = true;
      }

      if (resultStrAdd == Infinity || resultAglAdd == Infinity || resultResAdd == Infinity || resultWsdAdd == Infinity || resultPerAdd == Infinity || resultWillAdd == Infinity) {
        this.error = true;
      }

      if (resultSal == Infinity || resultSalM == Infinity || resultSalAdd == Infinity || resultSalMAdd == Infinity) {
        this.error = true;
      }

      if (resultDurata == Infinity) {
        this.error = true;
      }

    } catch (error) {
      this.error = true;
    }

    // check error or infinity
    if (this.error == true) {
      return;
    }

    let newBonusStatEquation: StatsBonusFormula = new StatsBonusFormula();
    newBonusStatEquation.str = this.strEq;
    newBonusStatEquation.agl = this.aglEq;
    newBonusStatEquation.res = this.resEq;
    newBonusStatEquation.wsd = this.wsdEq;
    newBonusStatEquation.per = this.perEq;
    newBonusStatEquation.wil = this.wilEq;

    newBonusStatEquation.strAdd = this.strEqAdd;
    newBonusStatEquation.aglAdd = this.aglEqAdd;
    newBonusStatEquation.resAdd = this.resEqAdd;
    newBonusStatEquation.wsdAdd = this.wsdEqAdd;
    newBonusStatEquation.perAdd = this.perEqAdd;
    newBonusStatEquation.wilAdd = this.wilEqAdd;

    newBonusStatEquation.sal = this.salEq;
    newBonusStatEquation.salM = this.salMEq;
    newBonusStatEquation.salAdd = this.salEqAdd;
    newBonusStatEquation.salMAdd = this.salMEqAdd;

    newBonusStatEquation.durata = this.durataEq;

    //save and reset
    let newSkillData: Skill = new Skill();
    newSkillData.uid = this.statsRowData.uid;
    newSkillData.name = this.statsRowData.name;
    newSkillData.ico = this.statsRowData.ico;
    newSkillData.race = parseInt(this.statsRowData.race);
    newSkillData.description = this.statsRowData.description;
    newSkillData.descrLv1 = this.statsRowData.descrLv1;
    newSkillData.descrLv2 = this.statsRowData.descrLv2;
    newSkillData.descrLv3 = this.statsRowData.descrLv3;
    newSkillData.descrLv4 = this.statsRowData.descrLv4;
    newSkillData.descrLv5 = this.statsRowData.descrLv5;
    newSkillData.note = this.statsRowData.note;
    newSkillData.cost = this.statsRowData.cost;
    newSkillData.statsBonus = newBonusStatEquation;

    let self = this;
    let newSkillDataJSON = JSON.parse(JSON.stringify(newSkillData));
    this.cPanelService.writeOnDB("skills", newSkillDataJSON.uid, newSkillDataJSON);
    // this.firestore.write('skills/' + newSkillDataJSON.uid, newSkillDataJSON).subscribe(() => {
    //   // self.updateDBVersion(newSkillDataJSON.uid);
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });

    this.strEq = "";
    this.aglEq = "";
    this.resEq = "";
    this.wsdEq = "";
    this.perEq = "";
    this.wilEq = "";

    this.strEqAdd = "";
    this.aglEqAdd = "";
    this.resEqAdd = "";
    this.wsdEqAdd = "";
    this.perEqAdd = "";
    this.wilEqAdd = "";

    this.salEq = "";
    this.salMEq = "";
    this.salEqAdd = "";
    this.salMEqAdd = "";

    this.durataEq = "";
    this.error = false;
    this.isStatsModalVisible = false;
  }

  closeModal() {
    this.strEq = "";
    this.aglEq = "";
    this.resEq = "";
    this.wsdEq = "";
    this.perEq = "";
    this.wilEq = "";

    this.strEqAdd = "";
    this.aglEqAdd = "";
    this.resEqAdd = "";
    this.wsdEqAdd = "";
    this.perEqAdd = "";
    this.wilEqAdd = "";

    this.salEq = "";
    this.salMEq = "";
    this.salEqAdd = "";
    this.salMEqAdd = "";

    this.durataEq = "";
    this.error = false;
    this.isStatsModalVisible = false;
  }

}
