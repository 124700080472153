import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Item, StatsBonusFormula, ItemCat } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import * as math from 'mathjs';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-archivio-oggetti',
  templateUrl: 'cPanel-archivio-oggetti.component.html',
  styleUrls: ['cPanel-archivio-oggetti.component.less'],
})
export class CPanelArchivioOggettiComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelArchivioOggetti";

  //#region - modal variables
  statsRowData: any;
  isStatsModalVisible: boolean = false;
  strEq: string = "";
  aglEq: string = "";
  resEq: string = "";
  wsdEq: string = "";
  perEq: string = "";
  wilEq: string = "";

  strEqAdd: string = "";
  aglEqAdd: string = "";
  resEqAdd: string = "";
  wsdEqAdd: string = "";
  perEqAdd: string = "";
  wilEqAdd: string = "";

  salEq: string = "";
  salMEq: string = "";
  salEqAdd: string = "";
  salMEqAdd: string = "";

  durataEq: string = "1"

  error: boolean = false;
  //#endregion - modal variables

  //#region - items variables
  private itemsCollection: AngularFirestoreCollection<Item>;
  private itemsCollectionObservable: Observable<Item[]>;
  private itemSubscription: Subscription;
  public dataSource: LocalDataSource;
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    actions: {
      custom: [
        {
          name: 'setStats',
          // addButtonContent: '<i class="material-icons icon-add">exposure</i>'
          title: '<i class="material-icons icon-add">exposure</i>',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Oggetto'
      },
      type: {
        title: 'Tipo di Oggetto'
      },
      itemCat: {
        title: 'Categoria',
        valuePrepareFunction: (value: string) => { return ItemCat[value]; },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: 0, title: "Armi" },
              { value: 1, title: "Pozioni e Artefatti" },
              { value: 2, title: "Varie" }
            ]
          }
        }
      },
      image: {
        title: 'Immagine Oggetto (300x220)',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
      maxUse: {
        title: 'Numero Massimo di Utilizzi',
        defaultValue: 0,
        valuePrepareFunction: (value: number) => {
          if (value == 0)
            return "infiniti"
          else
            return value;
        },
      },
      description: {
        title: 'Descrizione',
        editor: {
          type: 'textarea'
        }
      },
      note: {
        title: 'Nota Bene',
        editor: {
          type: 'textarea'
        }
      },
      cost: {
        title: 'Costo Oggetto'
      }
    }
  };
  //#endregion - items variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    this.itemsCollection = this.afs.collection<Item>('items');
    this.itemsCollectionObservable = this.itemsCollection.valueChanges();
    this.itemSubscription = this.itemsCollectionObservable.subscribe((data) => {

      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all items cpanel", self.moduleName, "items", (data as any[]).length);
      }

      self.dataSource.load(data);
    });
  }

  public ngOnDestroy() {
    this.itemSubscription.unsubscribe();
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.cPanelService.removeDB("items", event.data.uid);
      // let self = this;
      // this.firestore.remove('items/' + event.data.uid).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event) {
    event.newData.uid = Functions.CreateGuid();
    event.confirm.resolve();

    //save on firebase
    let newItemData: Item = new Item();
    newItemData.uid = event.newData.uid;
    newItemData.name = event.newData.name;
    newItemData.type = event.newData.type;
    newItemData.itemCat = parseInt(event.newData.itemCat);
    newItemData.image = event.newData.image;
    newItemData.note = event.newData.note;
    newItemData.cost = event.newData.cost;
    if (event.newData.maxUse == "infiniti" || Functions.IsStringEmpty(event.newData.maxUse)) {
      newItemData.maxUse = null;
    } else {
      newItemData.maxUse = parseInt(event.newData.maxUse);
    }
    newItemData.description = event.newData.description;
    newItemData.statsBonus = event.newData.statsBonus;

    let self = this;
    let newItemDataJSON = JSON.parse(JSON.stringify(newItemData));
    this.cPanelService.writeOnDB("items", newItemDataJSON.uid, newItemDataJSON);
    // this.firestore.write('items/' + newItemDataJSON.uid, newItemDataJSON).subscribe(() => {
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onEditConfirm(event) {
    event.confirm.resolve();

    //update on firebase
    let newItemData: Item = new Item();
    newItemData.uid = event.newData.uid;
    newItemData.name = event.newData.name;
    newItemData.type = event.newData.type;
    newItemData.itemCat = parseInt(event.newData.itemCat);
    newItemData.image = event.newData.image;
    newItemData.note = event.newData.note;
    newItemData.cost = event.newData.cost;
    if (event.newData.maxUse == "infiniti" || Functions.IsStringEmpty(event.newData.maxUse)) {
      newItemData.maxUse = null;
    } else {
      newItemData.maxUse = parseInt(event.newData.maxUse);
    }
    newItemData.description = event.newData.description;
    newItemData.statsBonus = event.newData.statsBonus;

    let self = this;
    let newItemDataJSON = JSON.parse(JSON.stringify(newItemData));
    this.cPanelService.writeOnDB("items", newItemDataJSON.uid, newItemDataJSON);
    // this.firestore.write('items/' + newItemDataJSON.uid, newItemDataJSON).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }


  onCustom(event) {
    this.statsRowData = event.data;
    const statsBonus: StatsBonusFormula = this.statsRowData.statsBonus || new StatsBonusFormula();

    this.strEq = statsBonus.str;
    this.aglEq = statsBonus.agl;
    this.resEq = statsBonus.res;
    this.wsdEq = statsBonus.wsd;
    this.perEq = statsBonus.per;
    this.wilEq = statsBonus.wil;

    this.strEqAdd = statsBonus.strAdd;
    this.aglEqAdd = statsBonus.aglAdd;
    this.resEqAdd = statsBonus.resAdd;
    this.wsdEqAdd = statsBonus.wsdAdd;
    this.perEqAdd = statsBonus.perAdd;
    this.wilEqAdd = statsBonus.wilAdd;

    this.salEq = statsBonus.sal;
    this.salMEq = statsBonus.salM;
    this.salEqAdd = statsBonus.salAdd;
    this.salMEqAdd = statsBonus.salMAdd;

    this.durataEq = statsBonus.durata || "1";
    this.error = false;
    this.isStatsModalVisible = true;
  }

  okModal() {
    this.error = false;
    let testScope = {
      lvl: 1,
      str: 1,
      agl: 1,
      res: 1,
      per: 1,
      wsd: 1,
      wil: 1,
      sal: 1,
      salM: 1
    }

    //transform , to .
    this.strEq = this.strEq.replace(",", ".");
    this.aglEq = this.aglEq.replace(",", ".");
    this.resEq = this.resEq.replace(",", ".");
    this.wsdEq = this.wsdEq.replace(",", ".");
    this.perEq = this.perEq.replace(",", ".");
    this.wilEq = this.wilEq.replace(",", ".");
    this.salEq = this.salEq.replace(",", ".");
    this.salMEq = this.salMEq.replace(",", ".");
    this.durataEq = this.durataEq.replace(",", ".");

    this.strEqAdd = this.strEqAdd.replace(",", ".");
    this.aglEqAdd = this.aglEqAdd.replace(",", ".");
    this.resEqAdd = this.resEqAdd.replace(",", ".");
    this.wsdEqAdd = this.wsdEqAdd.replace(",", ".");
    this.perEqAdd = this.perEqAdd.replace(",", ".");
    this.wilEqAdd = this.wilEqAdd.replace(",", ".");
    this.salEqAdd = this.salEqAdd.replace(",", ".");
    this.salMEqAdd = this.salMEqAdd.replace(",", ".");

    // variables can be read from the scope
    try {
      const resultStr: any = Math.floor(math.evaluate(this.strEq, testScope));
      const resultAgl: any = Math.floor(math.evaluate(this.aglEq, testScope));
      const resultRes: any = Math.floor(math.evaluate(this.resEq, testScope));
      const resultWsd: any = Math.floor(math.evaluate(this.wsdEq, testScope));
      const resultPer: any = Math.floor(math.evaluate(this.perEq, testScope));
      const resultWill: any = Math.floor(math.evaluate(this.wilEq, testScope));

      const resultSal: any = Math.floor(math.evaluate(this.salEq, testScope));
      const resultSalM: any = Math.floor(math.evaluate(this.salMEq, testScope));

      const resultDurata: any = Math.floor(math.evaluate(this.durataEq, testScope));

      const resultStrAdd: any = Math.floor(math.evaluate(this.strEqAdd, testScope));
      const resultAglAdd: any = Math.floor(math.evaluate(this.aglEqAdd, testScope));
      const resultResAdd: any = Math.floor(math.evaluate(this.resEqAdd, testScope));
      const resultWsdAdd: any = Math.floor(math.evaluate(this.wsdEqAdd, testScope));
      const resultPerAdd: any = Math.floor(math.evaluate(this.perEqAdd, testScope));
      const resultWillAdd: any = Math.floor(math.evaluate(this.wilEqAdd, testScope));

      const resultSalAdd: any = Math.floor(math.evaluate(this.salEqAdd, testScope));
      const resultSalMAdd: any = Math.floor(math.evaluate(this.salMEqAdd, testScope));

      if (resultStr == Infinity || resultAgl == Infinity || resultRes == Infinity || resultWsd == Infinity || resultPer == Infinity || resultWill == Infinity) {
        this.error = true;
      }

      if (resultStrAdd == Infinity || resultAglAdd == Infinity || resultResAdd == Infinity || resultWsdAdd == Infinity || resultPerAdd == Infinity || resultWillAdd == Infinity) {
        this.error = true;
      }

      if (resultSal == Infinity || resultSalM == Infinity || resultSalAdd == Infinity || resultSalMAdd == Infinity) {
        this.error = true;
      }

      if (resultDurata == Infinity) {
        this.error = true;
      }

    } catch (error) {
      this.error = true;
    }

    // check error or infinity
    if (this.error == true) {
      return;
    }

    let newBonusStatEquation: StatsBonusFormula = new StatsBonusFormula();
    newBonusStatEquation.str = this.strEq;
    newBonusStatEquation.agl = this.aglEq;
    newBonusStatEquation.res = this.resEq;
    newBonusStatEquation.wsd = this.wsdEq;
    newBonusStatEquation.per = this.perEq;
    newBonusStatEquation.wil = this.wilEq;

    newBonusStatEquation.strAdd = this.strEqAdd;
    newBonusStatEquation.aglAdd = this.aglEqAdd;
    newBonusStatEquation.resAdd = this.resEqAdd;
    newBonusStatEquation.wsdAdd = this.wsdEqAdd;
    newBonusStatEquation.perAdd = this.perEqAdd;
    newBonusStatEquation.wilAdd = this.wilEqAdd;

    newBonusStatEquation.sal = this.salEq;
    newBonusStatEquation.salM = this.salMEq;
    newBonusStatEquation.salAdd = this.salEqAdd;
    newBonusStatEquation.salMAdd = this.salMEqAdd;

    newBonusStatEquation.durata = this.durataEq;

    //save and reset
    let newItemData: Item = new Item();
    newItemData.uid = this.statsRowData.uid;
    newItemData.name = this.statsRowData.name;
    newItemData.type = this.statsRowData.type;
    newItemData.image = this.statsRowData.image;
    newItemData.cost = this.statsRowData.cost;
    if (this.statsRowData.maxUse == "infiniti") {
      newItemData.maxUse = 0;
    } else {
      newItemData.maxUse = parseInt(this.statsRowData.maxUse);
    }
    newItemData.description = this.statsRowData.description;
    newItemData.statsBonus = newBonusStatEquation;


    let self = this;
    let newItemDataJSON = JSON.parse(JSON.stringify(newItemData));
    this.cPanelService.writeOnDB("items", newItemDataJSON.uid, newItemDataJSON);
    // this.firestore.write('items/' + newItemDataJSON.uid, newItemDataJSON).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });

    this.strEq = "";
    this.aglEq = "";
    this.resEq = "";
    this.wsdEq = "";
    this.perEq = "";
    this.wilEq = "";

    this.strEqAdd = "";
    this.aglEqAdd = "";
    this.resEqAdd = "";
    this.wsdEqAdd = "";
    this.perEqAdd = "";
    this.wilEqAdd = "";

    this.salEq = "";
    this.salMEq = "";
    this.salEqAdd = "";
    this.salMEqAdd = "";

    this.durataEq = "";
    this.error = false;
    this.isStatsModalVisible = false;
  }

  closeModal() {
    this.strEq = "";
    this.aglEq = "";
    this.resEq = "";
    this.wsdEq = "";
    this.perEq = "";
    this.wilEq = "";

    this.strEqAdd = "";
    this.aglEqAdd = "";
    this.resEqAdd = "";
    this.wsdEqAdd = "";
    this.perEqAdd = "";
    this.wilEqAdd = "";

    this.salEq = "";
    this.salMEq = "";
    this.salEqAdd = "";
    this.salMEqAdd = "";

    this.durataEq = "";
    this.error = false;
    this.isStatsModalVisible = false;
  }

}
