import { LogsService } from './../../../../services/logs.service';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Stats, Race, PresencePlayerStatus, State } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'cPanel-crea-personaggio',
  templateUrl: 'cPanel-crea-personaggio.component.html',
  styleUrls: ['cPanel-crea-personaggio.component.less'],
})
export class CPanelCreaPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelCreaPersonaggio";
  public pgForm = new FormGroup({});

  private tempAuth: firebase.auth.Auth;
  private tempApp: firebase.app.App;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private logsService: LogsService,
    public cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService,
    private afAuth: AngularFireAuth,
    private afdb: AngularFireDatabase
  ) {
    if (Functions.IsNullOrUndefined(this.tempApp) === true) {
      const mainApp = this.afAuth.auth.app;
      const mainAppOptions = mainApp.options;
      // const asd3 = asd["firebase_"];
      // const asdqwe = firebase.auth();
      // const asdqwe2 = firebase.app().auth();

      this.tempApp = firebase.initializeApp(mainAppOptions, "tempApp");
    }
  }

  public ngOnInit() {
    this.buildForm();
  }

  public ngOnDestroy() {
    this.tempApp.delete();
  }


  public ok() {
    if (this.pgForm.valid == false) {
      Object.keys(this.pgForm.controls).forEach(field => {
        const control = this.pgForm.get(field);
        if (control.valid == false) {
          control.markAsDirty({ onlySelf: true });
        }
      });

    } else {
      let self = this;
      this.cPanelService.waiting = true;
      // save new player

      const pgName: string = (this.pgForm.value['nome'] as string).toLowerCase().trim();
      const nameDoc = this.afs.doc<any>('listOfUserNames/' + pgName);
      const nameDocObservable = nameDoc.valueChanges();
      let nameSubscription: Subscription = nameDocObservable.pipe(first())
        .subscribe((data) => {
          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(true, "READ a user name cPanel", self.moduleName, "listOfUserNames", 1);
          }

          if (Functions.IsNullOrUndefined(data)) {
            //name not in use
            nameSubscription.unsubscribe();
            self.setupUser({ email: this.pgForm.value['mail'], pass: this.pgForm.value['password'] });
            //old methods
            // self.updateUserData({ email: this.pgForm.value['mail'], pass: this.pgForm.value['password'] });
          } else {
            //name already in use
            self.pgForm.controls.nome.setErrors(["nome già in useo"]);
            self.toastr.error('Nome già in uso, scegli un altro nome per il tuo personaggio.', 'Errore', {
              timeOut: 10000,
              extendedTimeOut: 4000,
              positionClass: "toast-top-center"
            });
            self.cPanelService.waiting = false;
          }
        });
    }
  }

  public annulla() {
    this.buildForm();
    window.scrollTo(0, 0);
  }

  private updateUserData(user: any) {
    const self: this = this;
    let characterData: CharacterSheetData = this.createNewCharacter(user);
    // let characterDataJSON = JSON.parse(JSON.stringify(characterData));

    const data = {
      registrationData: {
        email: user.email,
        password: user.pass
      },
      characterSheet: characterData
    };


    //TEST
    // var config = {
    //   apiKey: "apiKey",
    //   authDomain: "projectId.firebaseapp.com",
    //   databaseURL: "https://databaseName.firebaseio.com"
    // };
    // var secondaryApp = firebase.initializeApp(config, "Secondary");

    // secondaryApp.auth().createUserWithEmailAndPassword(em, pwd).then(function (firebaseUser) {
    //   console.log("User " + firebaseUser.uid + " created successfully!");
    //   //I don't know if the next statement is necessary
    //   secondaryApp.auth().signOut();
    //   // secondaryApp.delete();
    // });
    //TEST END - COMMENTARE PARTE SUCCESSIVA PER FARE TEST DI CREAZIONE AUTH


    const dataJSON = JSON.parse(JSON.stringify(data));
    // this is a cloud function that create the user and save the character sheet

    const userCollection = self.afs.collection<any>('createNewUsers');
    userCollection.doc(Functions.CreateGuid()).set(dataJSON)
      .then(() => {
        const myName: string = fromRoot.getState(this.store).character.myCharacterData.name;
        self.logsService.logXP(myName, characterData.name, "0", (characterData.usablePx || 0).toString(), "[CP] creazione personaggio da control panel");
        self.buildForm();

        // update username elist
        const nameCollection = self.afs.collection<any>('listOfUserNames');
        nameCollection.doc((characterData.name as string).toLowerCase()).set({});

        self.toastr.success('Personaggio Creato!');
        self.cPanelService.waiting = false;
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('createNewUsers/' + Functions.CreateGuid(), dataJSON).subscribe(() => {
    //   const myName: string = fromRoot.getState(this.store).character.myCharacterData.name;
    //   self.logsService.logXP(myName, characterData.name, "0", (characterData.usablePx || 0).toString(), "[CP] creazione personaggio da control panel");
    //   self.buildForm();
    //   self.toastr.success('Personaggio Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });


    // this.firestore.write('listOfUserNames/' + (characterData.name as string).toLowerCase(), {});
  }

  private createNewCharacter(user: any): CharacterSheetData {

    let characterData: CharacterSheetData = new CharacterSheetData();
    // characterData.uid = user.uid;
    characterData.mail = this.pgForm.value['mail'];
    characterData.isActive = this.pgForm.value['isActive'];
    characterData.inited = this.pgForm.value['inited'];
    characterData.isBanned = this.pgForm.value['isBanned'];
    characterData.isPng = this.pgForm.value['isPng'];
    characterData.isCapoClan = false; //this.pgForm.value['isCapoClan'];
    characterData.isCapoCorp = false; //this.pgForm.value['isCapoCorp'];
    characterData.isCapoRace = this.pgForm.value['isCapoRace'];
    characterData.name = this.pgForm.value['nome'];
    characterData.sur = this.pgForm.value['cognome'];
    characterData.nick = this.pgForm.value['nick'];
    characterData.sex = parseInt(this.pgForm.value['sesso']);
    characterData.race = Race.Mondano; //parseInt(this.pgForm.value['razza']);
    characterData.role = parseInt(this.pgForm.value['ruolo']);
    characterData.nature = this.pgForm.value['natura'];
    characterData.allinment = this.pgForm.value['allineamento'];
    characterData.marStatus = this.pgForm.value['marStatus'];
    characterData.age = this.pgForm.value['age'];
    characterData.realAge = this.pgForm.value['realAge'];
    characterData.eyes = this.pgForm.value['eyes'];
    characterData.weight = this.pgForm.value['peso'];
    characterData.height = this.pgForm.value['altezza'];
    characterData.hair = this.pgForm.value['hair'];
    characterData.job = this.pgForm.value['job'];
    characterData.clan = ""; //this.pgForm.value['clan'];
    characterData.corp = ""; //this.pgForm.value['corporazione'];
    characterData.offRole = ""; //this.pgForm.value['offRole'];

    if (characterData.inited) {
      characterData.leftPoint = 0;
    }

    // characterData.signDate = this.pgForm.value['signDate'];
    characterData.signDate = Functions.GetCurrentDate();
    characterData.access = Functions.GetCurrentDate();

    characterData.HAvatar = this.pgForm.value['HAvatar'];
    characterData.VAvatar = this.pgForm.value['VAvatar'];
    characterData.MAvatar = this.pgForm.value['MAvatar'];
    characterData.health = this.pgForm.value['health'];
    characterData.mindHealth = this.pgForm.value['mindHealth'];

    characterData.px = this.pgForm.value['px'];
    characterData.usablePx = this.pgForm.value['usablePx'];
    if (characterData.usablePx > characterData.px)
      characterData.px = characterData.usablePx;

    characterData.DMNote = this.pgForm.value['DMNote'];

    // characterData.jobMoney = parseInt(this.pgForm.value['jobMoney']);

    let stats: Stats = new Stats();
    stats.str = parseInt(this.pgForm.value['str']);
    stats.agl = parseInt(this.pgForm.value['agl']);
    stats.res = parseInt(this.pgForm.value['res']);
    stats.wsd = parseInt(this.pgForm.value['wsd']);
    stats.per = parseInt(this.pgForm.value['per']);
    stats.wil = parseInt(this.pgForm.value['wil']);
    characterData.stats = stats;

    characterData.backg = this.pgForm.value['bck'];
    return characterData;
  }

  // used to create form structure
  private buildForm() {
    this.pgForm = this.fb.group({
      'mail': ['', [
        Validators.required
      ]],
      'password': ['', [
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(8),
        Validators.maxLength(20),
      ]],
      'isActive': [false],
      'inited': [false],
      'isBanned': [false],
      'isPng': [false],
      'isCapoClan': [{ value: false, disabled: true }],
      'isCapoCorp': [{ value: false, disabled: true }],
      'isCapoRace': [[]],
      'nome': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'cognome': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'nick': ['', [
        // Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'sesso': [undefined, [
        Validators.required,
        Validators.min(0),
        Validators.max(1)
      ]],
      'razza': [{ value: 'Mondano', disabled: true }],
      'ruolo': ['4', [
        Validators.required,
        Validators.min(0),
        Validators.max(4),
      ]],
      'natura': [''],
      'allineamento': [''],
      'marStatus': [''],
      'age': [''],
      'realAge': [''],
      'eyes': [''],
      'peso': [''],
      'altezza': [''],
      'hair': [''],
      'job': [''],
      'clan': [{ value: '', disabled: true }],
      'corporazione': [{ value: '', disabled: true }],
      'offRole': [{ value: '', disabled: true }],
      'signDate': [{ value: Functions.GetCurrentDate(), disabled: true }, [
        Validators.required
      ]],
      'access': [{ value: '', disabled: true }],
      'HAvatar': [""],
      'VAvatar': [""],
      'MAvatar': [""],
      'health': ['100', [
        Validators.required
      ]],
      'mindHealth': ['100', [
        Validators.required
      ]],
      'px': ['0'],
      'usablePx': ['0'],
      'DMNote': [''],
      'str': ['10', [
        Validators.required
      ]],
      'agl': ['10', [
        Validators.required
      ]],
      'res': ['10', [
        Validators.required
      ]],
      'wsd': ['10', [
        Validators.required
      ]],
      'per': ['10', [
        Validators.required
      ]],
      'wil': ['10', [
        Validators.required
      ]],
      'bck': [''],
    });
  }


  //#region - new system witout cloud functions

  private setupUser(user: any) {
    const self: this = this;
    this.tempAuth = this.tempApp.auth();

    let characterData: CharacterSheetData = this.createNewCharacter(user);

    this.tempAuth.createUserWithEmailAndPassword(user.email, user.pass)
      .then((infoes) => {
        infoes.user.sendEmailVerification();

        const uid: string = infoes.user.uid;
        characterData.uid = uid;
        characterData.name = characterData.name.trim();

        //#region - save character data
        let characterDataJSON = JSON.parse(JSON.stringify(characterData));
        const userCollection = self.afs.collection<CharacterSheetData>('users');
        userCollection.doc(uid).set(characterDataJSON);
        //#endregion - save character data

        //#region - update user names used
        const userNameCollection = self.afs.collection<any>('listOfUserNames');
        userNameCollection.doc((characterData.name as string).toLowerCase()).set({});
        //#endregion - update user names used

        //#region - save user presence
        let lastAccess: string = Functions.GetCurrentDate();
        const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterData.uid, characterData.race, characterData.role, characterData.MAvatar, characterData.name, characterData.sur, characterData.nick, lastAccess, characterData.sex, (characterData.lvl || 1), (characterData.state || State.libero), (characterData.stateText || ""), characterData.isPng, characterData.isBanned, characterData.lastMessageSent, characterData.myPrivateChat, (characterData.clan || ""), characterData.clanLvl, (characterData.corp || ""), characterData.corpLvl, characterData.isActive, characterData.isCapoClan, characterData.isCapoCorp, characterData.isCapoRace, "");
        const presenceStatusJSON = JSON.parse(JSON.stringify(presenceSatus));

        const userPresenceDoc = self.afdb.object('status/' + characterData.uid + '/state');
        userPresenceDoc.update(presenceStatusJSON);
        //#region - save user presence

        self.tempAuth.signOut();
        self.toastr.success('Personaggio Creato!');
        self.cPanelService.waiting = false;
      })
      .catch((error: any) => {
        if (error.code == "auth/email-already-in-use") {
          self.pgForm.controls.nome.setErrors(["email già in useo"]);
          self.toastr.error('email già in uso, scegli una email diversa per il tuo personaggio.', 'Errore', {
            timeOut: 10000,
            extendedTimeOut: 4000,
            positionClass: "toast-top-center"
          });
          self.tempAuth.signOut();
          self.cPanelService.waiting = false;
          return;
        }
      });

  }

  //#endregion - new system witout cloud functions

}
