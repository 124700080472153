import { PrivateConversation, UserPresence, PMUI, UserEntry, PrivateMessage, PrivateMessageUI, OnOff } from './../models/data/application.data';
import { createSelector } from 'reselect';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import { State } from '../reducers/index';
import * as fromPM from '../reducers/pm';
import * as chracterSelector from './character.selectors';



export const ModuleName: string = "PMSelector";
export const getPMState: (state: State) => fromPM.State = (state: State) => state.pm;
export const getAllPMs: any = createSelector(getPMState, fromPM.getPMs);
export const getSelectedPMRaw: any = createSelector(getPMState, fromPM.getSelectedPM);
export const getNewPM: any = createSelector(getPMState, fromPM.getNewPM);
export const getShowOnPMs: any = createSelector(getPMState, fromPM.getShowOnPMs);


export const getFilteredPMs: any = createSelector(getAllPMs, getShowOnPMs,
  (allPMs: PrivateConversation[], showingOn: boolean) => {
    if (Functions.IsNullOrUndefined(allPMs))
      return undefined;

    let filteredCurrent: PrivateConversation[] = [];
    if (showingOn) {
      filteredCurrent = allPMs.filter((aPM: PrivateConversation) => aPM.onOff == OnOff.on);
    } else {
      filteredCurrent = allPMs.filter((aPM: PrivateConversation) => aPM.onOff == OnOff.off);
    }

    return filteredCurrent;
  });



export const getSelectedPM: any = createSelector(getSelectedPMRaw, chracterSelector.getUsersPresence,
  (selectedCNV: PrivateConversation, usersPresence: UserPresence[]) => {
    if(Functions.IsNullOrUndefined(selectedCNV) == true) {
      return new PMUI("", 0, "", new UserEntry(), [], [], false);
    }

    const userPresenceMap: Map<string, UserPresence> = new Map();
    for (let index: number = 0; index < usersPresence.length; index++) {
      const aUser: UserPresence = usersPresence[index];
      if (Functions.IsNullOrUndefined(aUser.state) == false) {
        userPresenceMap.set(aUser.state.playerUID, aUser);
      }
    }

    const mittente: UserEntry = new UserEntry();
    const mittentePresence: UserPresence = userPresenceMap.get(selectedCNV.ownUID);
    mittente.uid = selectedCNV.ownUID;
    mittente.name = (Functions.IsNullOrUndefined(mittentePresence) == false) ? mittentePresence.state.name : "Utente Cancellato";

    const newCNVUI: PMUI = new PMUI(selectedCNV.cnvUID, selectedCNV.onOff, selectedCNV.titolo, mittente, [], selectedCNV.targetsUID, selectedCNV.conRiposta);

    for (let index: number = 0; index < selectedCNV.destUID.length; index++) {
      const aDestUID: string = selectedCNV.destUID[index];
      const aPresent: UserPresence = userPresenceMap.get(aDestUID);

      if (Functions.IsNullOrUndefined(aPresent) || Functions.IsNullOrUndefined(aPresent.state))
        continue;

      const userEntry: UserEntry = new UserEntry();
      userEntry.uid = aPresent.state.playerUID;
      userEntry.name = aPresent.state.name;
      newCNVUI.dest.push(userEntry);
    }

    newCNVUI.messages = [];

    for (let index: number = 0; index < selectedCNV.messages.length; index++) {
      const aMessage: PrivateMessage = selectedCNV.messages[index];
      const aPresent: UserPresence = userPresenceMap.get(aMessage.ownUID);

      if (Functions.IsNullOrUndefined(aPresent))
        continue;

      const owner: UserEntry = new UserEntry();
      owner.uid = aMessage.ownUID;
      owner.name = aPresent.state.name;
      owner.img = aPresent.state.miniAvatarUrl;

      const aMessageUI: PrivateMessageUI = new PrivateMessageUI(aMessage.msgUID, owner, aMessage.dateHour, aMessage.message);
      newCNVUI.messages.push(aMessageUI);
    }

    //sorting messages
    newCNVUI.messages = newCNVUI.messages.reverse();

    return newCNVUI;
  });
