import { MessageType, OnlineUser } from './../modules/chatRoom/models/chat-message.models';
import { createSelector } from 'reselect';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import { State } from '../reducers/index';
import * as fromChat from '../reducers/chat';
import * as chracterSelector from './character.selectors';
import * as layoutSelector from './layout.selectors';
import * as datasSelector from './datas.selectors';
import { CharacterSheetData, Stats, Role, StandardChat, SecretChat, UserEntry, SpecialChat } from '../models/data/application.data';
import { CharacterState, TacticalSheetData, ChatMessage } from '../modules/chatRoom/models/chat-message.models';

/**
 * Layout
 */
export const ModuleName: string = "CharacterSelector";
export const getChatState: (state: State) => fromChat.State = (state: State) => state.chat;
export const getChatMessages: any = createSelector(getChatState, fromChat.getChatMessages);
export const getChatOnlineUsersRaw: any = createSelector(getChatState, fromChat.getChatOnlineUsers);
export const getChatCharactersState: any = createSelector(getChatState, fromChat.getChatCharactersState);
export const getChatStatus: any = createSelector(getChatState, fromChat.getChatStatus);
export const getIsQuestActive: any = createSelector(getChatState, fromChat.getIsQuestActive);
export const getSelectedChat: any = createSelector(getChatState, fromChat.getSelectedChat);
export const getSelectedChatPlayer: any = createSelector(getChatState, fromChat.getSelectedChatPlayer);
export const getChatPendingattacks: any = createSelector(getChatState, fromChat.getChatPendingattacks);
export const getChatInstruction: any = createSelector(getChatState, fromChat.getChatInstruction);

export const getChatOnlineUsers: any = createSelector(getChatOnlineUsersRaw, (onlineUsers: OnlineUser[]) => {
  if (Functions.IsNullOrUndefined(onlineUsers))
    return [];

  let usersToRetunr: OnlineUser[] = Object.assign([], onlineUsers);

  // sorting alphabetically
  usersToRetunr = usersToRetunr.sort(function (a, b) {
    var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
    if (nameA < nameB) //sort string ascending
      return -1;
    if (nameA > nameB)
      return 1;
    return 0; //default return value (no sorting)
  });

  return usersToRetunr;

});

export const getTactialSheetData: any = createSelector(getSelectedChatPlayer, getChatCharactersState, getChatMessages,
  (selectedCharacterSheet: CharacterSheetData, chatCharactersState: CharacterState[], messages: ChatMessage[]) => {

    if (Functions.IsNullOrUndefined(selectedCharacterSheet) == true) {
      const tacticalSheet: TacticalSheetData = new TacticalSheetData();
      tacticalSheet.id = undefined;
      tacticalSheet.health = 0;
      tacticalSheet.mindHealth = 0;
      tacticalSheet.tacticalSheetImg = "";

      tacticalSheet.statsBase = new Stats();
      tacticalSheet.statsBonusItems = new Stats();
      tacticalSheet.statsBonusSkill = new Stats();
      tacticalSheet.statsTotal = new Stats();

      tacticalSheet.receivedDamage = [];
      tacticalSheet.usedSkillsAndItems = [];
      return tacticalSheet;
    }

    let selectedPlayerCharacterState: CharacterState = chatCharactersState.find((aCharacter: any) => aCharacter.uid == selectedCharacterSheet.uid);
    // if (Functions.IsNullOrUndefined(selectedPlayerCharacterState) == false)
    //   selectedPlayerCharacterState = selectedPlayerCharacterState[0];

    const tacticalSheet: TacticalSheetData = new TacticalSheetData();

    tacticalSheet.id = selectedCharacterSheet.uid;
    tacticalSheet.health = selectedCharacterSheet.health;
    tacticalSheet.mindHealth = selectedCharacterSheet.mindHealth;
    tacticalSheet.tacticalSheetImg = selectedCharacterSheet.TAvatar || "";
    tacticalSheet.statsBase = selectedCharacterSheet.stats;

    // stats bonus
    if (Functions.IsNullOrUndefined(selectedPlayerCharacterState) == true) {
      // no buffs for this player
      tacticalSheet.statsBonusItems = new Stats();
      tacticalSheet.statsBonusSkill = new Stats();
    } else {
      // there are buffs
      tacticalSheet.statsBonusItems = selectedPlayerCharacterState.itemsBonusStats;
      tacticalSheet.statsBonusSkill = selectedPlayerCharacterState.skillsBonusStats;
    }

    // stats Total
    tacticalSheet.statsTotal = new Stats();
    tacticalSheet.statsTotal.str = tacticalSheet.statsBase.str + tacticalSheet.statsBonusItems.str + tacticalSheet.statsBonusSkill.str;
    tacticalSheet.statsTotal.agl = tacticalSheet.statsBase.agl + tacticalSheet.statsBonusItems.agl + tacticalSheet.statsBonusSkill.agl;
    tacticalSheet.statsTotal.per = tacticalSheet.statsBase.per + tacticalSheet.statsBonusItems.per + tacticalSheet.statsBonusSkill.per;
    tacticalSheet.statsTotal.res = tacticalSheet.statsBase.res + tacticalSheet.statsBonusItems.res + tacticalSheet.statsBonusSkill.res;
    tacticalSheet.statsTotal.wsd = tacticalSheet.statsBase.wsd + tacticalSheet.statsBonusItems.wsd + tacticalSheet.statsBonusSkill.wsd;
    tacticalSheet.statsTotal.wil = tacticalSheet.statsBase.wil + tacticalSheet.statsBonusItems.wil + tacticalSheet.statsBonusSkill.wil;


    // received damange
    tacticalSheet.receivedDamage = messages.filter((aMessage: any) => aMessage.userID == selectedCharacterSheet.uid && (aMessage.type == MessageType.dmgNotification) && Functions.IsStringEmpty(aMessage.textTactical) == false);
    if (Functions.IsNullOrUndefined(tacticalSheet.receivedDamage)) {
      tacticalSheet.receivedDamage = [];
    } else {
      tacticalSheet.receivedDamage = tacticalSheet.receivedDamage.reverse();
    }

    // used skills and items
    tacticalSheet.usedSkillsAndItems = messages.filter((aMessage: any) => aMessage.userID == selectedCharacterSheet.uid && (aMessage.type == MessageType.skillNotification || aMessage.type == MessageType.itemNotification));
    if (Functions.IsNullOrUndefined(tacticalSheet.usedSkillsAndItems)) {
      tacticalSheet.usedSkillsAndItems = [];
    } else {
      tacticalSheet.usedSkillsAndItems = tacticalSheet.usedSkillsAndItems.reverse();
    }

    return tacticalSheet;
  });

export const isGmInQuest: any = createSelector(getIsQuestActive, chracterSelector.getUserRole,
  (isQuestActive: boolean, cheracterRole: Role) => {

    if (isQuestActive == false)
      return false;

    if (cheracterRole < 3) {
      return true;
    }

    return false;

  });


export const getSelectedChatName: any = createSelector(getSelectedChat, layoutSelector.getStandardChatsList, chracterSelector.getAvailableSecretChats, chracterSelector.getAvailableSpecialChats,
  (selectedChat: string, standardChatsList: StandardChat[], avilableSecretChats: SecretChat[], availableSpecialChats: SpecialChat[]) => {

    if (Functions.IsStringEmpty(selectedChat) || Functions.IsNullOrUndefined(standardChatsList) || standardChatsList.length <= 0)
      return "";

    // standard chat
    const selectedChatItem: StandardChat = standardChatsList.find((aChat: StandardChat) => aChat.uid == selectedChat);
    if (Functions.IsNullOrUndefined(selectedChatItem) == false) {
      return selectedChatItem.name;
    }

    // secret chat
    const selectedSecretChatItem: SecretChat = avilableSecretChats.find((aChat: SecretChat) => aChat.uid == selectedChat);
    if (Functions.IsNullOrUndefined(selectedSecretChatItem) == false) {
      return selectedSecretChatItem.name;
    }

    // special chat
    const selectedSpecialChatItem: SpecialChat = availableSpecialChats.find((aChat: SpecialChat) => aChat.uid == selectedChat);
    if (Functions.IsNullOrUndefined(selectedSpecialChatItem) == false) {
      return selectedSpecialChatItem.name;
    }

    return "";

  });

export const getCanManagePrivateChat: any = createSelector(getSelectedChat, layoutSelector.getStandardChatsList, chracterSelector.getAvailableSecretChats, chracterSelector.getCharacterSheet,
  (selectedChat: string, standardChatsList: StandardChat[], avilableSecretChats: SecretChat[], myCharacterSheet: CharacterSheetData) => {

    if (Functions.IsStringEmpty(selectedChat) || Functions.IsNullOrUndefined(standardChatsList) || standardChatsList.length <= 0 || Functions.IsNullOrUndefined(myCharacterSheet))
      return false;

    const selectedChatItem: StandardChat = standardChatsList.find((aChat: StandardChat) => aChat.uid == selectedChat);
    const selectedSecretChatItem: SecretChat = avilableSecretChats.find((aChat: SecretChat) => aChat.uid == selectedChat);
    if (Functions.IsNullOrUndefined(selectedChatItem) && Functions.IsNullOrUndefined(selectedSecretChatItem))
      return false;

    if (Functions.IsNullOrUndefined(selectedChatItem) == false)
      return false;

    if (Functions.IsNullOrUndefined(selectedSecretChatItem) == false) {
      if (selectedSecretChatItem.ownerUID == myCharacterSheet.uid || myCharacterSheet.role < 3)
        return true;
    }

    return false;
  });

export const getAllAvailableToInvite: any = createSelector(getSelectedChat, chracterSelector.getAvailableSecretChats, chracterSelector.getCharacterSheet, datasSelector.getUsersList,
  (selectedChat: string, avilableSecretChats: SecretChat[], myCharacterSheet: CharacterSheetData, users: UserEntry[]) => {

    if (Functions.IsStringEmpty(selectedChat) || Functions.IsNullOrUndefined(avilableSecretChats) || avilableSecretChats.length <= 0 || Functions.IsNullOrUndefined(myCharacterSheet))
      return [];

    if (Functions.IsNullOrUndefined(users) || users.length <= 0)
      return [];

    const selectedSecretChatItem: SecretChat = avilableSecretChats.find((aChat: SecretChat) => aChat.uid == selectedChat);
    if (Functions.IsNullOrUndefined(selectedSecretChatItem)) {
      return [];
    } else {
      const setAlreadyInvited: Set<string> = new Set(selectedSecretChatItem.accessibleByUIDs);
      let canBeInvited: UserEntry[] = users.filter((aUser: UserEntry) => setAlreadyInvited.has(aUser.uid) == false && aUser.uid != selectedSecretChatItem.ownerUID && aUser.uid != "@@@ALL@@@");

      canBeInvited = canBeInvited.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return canBeInvited;
    }
  });

export const getAllAvailableToRemove: any = createSelector(getSelectedChat, chracterSelector.getAvailableSecretChats, chracterSelector.getCharacterSheet, datasSelector.getUsersList,
  (selectedChat: string, avilableSecretChats: SecretChat[], myCharacterSheet: CharacterSheetData, users: UserEntry[]) => {

    if (Functions.IsStringEmpty(selectedChat) || Functions.IsNullOrUndefined(avilableSecretChats) || avilableSecretChats.length <= 0 || Functions.IsNullOrUndefined(myCharacterSheet))
      return [];

    if (Functions.IsNullOrUndefined(users) || users.length <= 0)
      return [];

    const selectedSecretChatItem: SecretChat = avilableSecretChats.find((aChat: SecretChat) => aChat.uid == selectedChat);
    if (Functions.IsNullOrUndefined(selectedSecretChatItem)) {
      return [];
    } else {
      const setAlreadyInvited: Set<string> = new Set(selectedSecretChatItem.accessibleByUIDs);
      let canBeRemoved: UserEntry[] = users.filter((aUser: UserEntry) => setAlreadyInvited.has(aUser.uid) == true && aUser.uid != selectedSecretChatItem.ownerUID);

      canBeRemoved = canBeRemoved.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return canBeRemoved;
    }
  });
