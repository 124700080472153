import { Functions } from './../../../utilities/functions/utilities.functions';
import { FirebaseCalendarEvent } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

import { DAYS_OF_WEEK } from 'angular-calendar';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
// import { FlatpickrOptions } from 'ng2-flatpickr';
// import Italian from 'flatpickr/dist/l10n/it.js';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { isSameMonth, isSameDay } from 'date-fns';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';


export enum ModalType {
  create = 0,
  edit = 1
}

export class ModalData {
  title: string = "";
  event: FirebaseCalendarEvent;
  tempDate: IMyDateModel;
  type: ModalType;
  uid: string;
}


@Component({
  selector: 'cPanel-gestione-eventi',
  templateUrl: 'cPanel-gestione-eventi.component.html',
  styleUrls: ['cPanel-gestione-eventi.component.less'],
})
export class CPanelGestioneEventiComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelGestioneEventi";
  //#region - calendar variables
  private eventsCollection: AngularFirestoreCollection<FirebaseCalendarEvent>;
  private eventsCollectionObservable: Observable<FirebaseCalendarEvent[]>;
  private eventsSubscription: Subscription;
  public calendarEvents: FirebaseCalendarEvent[] = [];
  public formattedCalendarEvents: CalendarEvent[] = [];
  //#endregion - calendar variables

  //#region - calendar library setting variables
  public view: string = 'month';
  public viewDate: Date = new Date();
  public activeDayIsOpen: boolean = false;


  //#region - date picker variables
  public myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    // inline: true
  };
  // public date: IMyDateModel = null;
  //#endregion - date picker variablesF


  public locale: string = 'it';
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  public refresh: Subject<any> = new Subject();
  public actions: CalendarEventAction[] = [
    {
      label: '<i class="calendar-event-action-icon material-icons icon-edit">edit</i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="calendar-event-action-icon material-icons icon-delete">delete</i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        // this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];
  //#endregion - calendar library setting variables

  //#region - modal variables
  public isModalVisible: boolean = false;
  public modalData: ModalData = new ModalData();
  // public dateToBeEdited: Date = undefined;
  // public datepickerOptions: FlatpickrOptions = {
  //   altFormat: 'j/m/Y - H:i',
  //   altInput: true,
  //   dateFormat: 'Z',
  //   enableTime: true,
  //   time_24hr: true,
  //   locale: Italian.it,
  //   defaultDate: this.dateToBeEdited
  // };
  //#endregion - modal variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }


  public ngOnInit() {
    let self = this;

    this.eventsCollection = this.afs.collection<FirebaseCalendarEvent>('calendarEvents');
    this.eventsCollectionObservable = this.eventsCollection.valueChanges();
    this.eventsSubscription = this.eventsCollectionObservable.subscribe((data: FirebaseCalendarEvent[]) => {
      // this.eventsSubscription = this.firestore.query('calendarEvents').on().subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, "READ all events chats cpanel", self.moduleName, "calendarEvents", (data as any[]).length);
      }


      self.calendarEvents = data;
      self.setUpCalendarEvents();
    });
  }

  public ngOnDestroy() {
    if(Functions.IsNullOrUndefined(this.eventsSubscription) == false) {
      this.eventsSubscription.unsubscribe();
    }
  }

  private setUpCalendarEvents() {
    let events: CalendarEvent[] = [];
    for (let index: number = 0; index < this.calendarEvents.length; index++) {
      const currentCalendarEvent: FirebaseCalendarEvent = this.calendarEvents[index];
      let formattedDate: Date = new Date(currentCalendarEvent.date);
      let hour: string = formattedDate.getHours().toString();
      let minutes: string = formattedDate.getMinutes().toString();
      if (minutes.length == 1) {
        minutes = "0" + minutes
      }

      let aCalendarEvent = {
        title: hour + "." + minutes + " - " + currentCalendarEvent.title,
        color: {
          primary: currentCalendarEvent.color,
          secondary: '#ffffff'
        },
        start: formattedDate,
        draggable: true,
        actions: this.actions,
        uid: currentCalendarEvent.uid
      };
      events.push(aCalendarEvent);
    }
    this.formattedCalendarEvents = events;
    this.refresh.next();
  }

  public eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    let castedEvent: any = event as any;
    let foundedEvent: FirebaseCalendarEvent = this.calendarEvents.find((anEvent: FirebaseCalendarEvent) => anEvent.uid == castedEvent.uid);
    foundedEvent.date = newStart;
    let self = this;
    let newEventDataJSON = JSON.parse(JSON.stringify(foundedEvent));

    const newEventDataDoc: AngularFirestoreDocument<FirebaseCalendarEvent> = this.afs.doc<any>('calendarEvents/' + newEventDataJSON.uid);
    newEventDataDoc.update(newEventDataJSON)
      .then(() => {
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('calendarEvents/' + newEventDataJSON.uid, newEventDataJSON).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  handleEvent(action: string, event): void {
    let self = this;
    if (action == "Deleted") {
      const eventToRemoveDoc: AngularFirestoreDocument<FirebaseCalendarEvent> = this.afs.doc<any>('calendarEvents/' + event.uid);
      eventToRemoveDoc.delete()
        .then(() => {
          self.toastr.success('Elemento Eliminato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });

      // this.firestore.remove('calendarEvents/' + event.uid).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else if (action == "Edited") {
      let foundedEvent: FirebaseCalendarEvent = this.calendarEvents.find((anEvent: FirebaseCalendarEvent) => anEvent.uid == event.uid);
      // let clonedEvent: FirebaseCalendarEvent = Object.assign({}, foundedEvent);
      // clonedEvent.date = new Date(clonedEvent.date);
      this.modalData = new ModalData();
      this.modalData.tempDate = {isRange: false, singleDate: {jsDate: new Date(foundedEvent.date)}};
      this.modalData.title = "Modifica Evento";
      this.modalData.type = ModalType.edit;
      this.modalData.uid = event.uid;
      this.modalData.event = foundedEvent;
      setTimeout(() => {
        self.isModalVisible = true;
      }, 0);
    }
  }

  public dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  public addEvent() {
    this.modalData = new ModalData();
    this.modalData.title = "Aggiungi Evento";
    this.modalData.type = ModalType.create;
    this.modalData.event = new FirebaseCalendarEvent();
    this.modalData.event.color = "#f44336";
    this.modalData.tempDate = null;
    this.isModalVisible = true;
  }

  public closeModal() {
    this.isModalVisible = false;
    this.modalData = new ModalData();
  }

  public okModal() {

    if (Functions.IsNullOrUndefined(this.modalData.event.title) || Functions.IsStringEmpty(this.modalData.event.title))
      return;

    if (Functions.IsNullOrUndefined(this.modalData.tempDate))
      return;

    let self = this;
    if (this.modalData.type == ModalType.create) {
      this.isModalVisible = false;
      let newEvent: FirebaseCalendarEvent = new FirebaseCalendarEvent();
      newEvent.color = this.modalData.event.color;
      newEvent.date = this.modalData.tempDate.singleDate.jsDate;
      newEvent.title = this.modalData.event.title;
      newEvent.uid = Functions.CreateGuid();

      let newEventDataJSON = JSON.parse(JSON.stringify(newEvent));
      this.eventsCollection.doc(newEventDataJSON.uid).set(newEventDataJSON)
        .then(() => {
          self.toastr.success('Elemento Creato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });

      // this.firestore.write('calendarEvents/' + newEventDataJSON.uid, newEventDataJSON).subscribe(() => {
      //   self.toastr.success('Elemento Creato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      this.isModalVisible = false;
      let newEvent: FirebaseCalendarEvent = new FirebaseCalendarEvent();
      newEvent.color = this.modalData.event.color;
      newEvent.date = this.modalData.tempDate.singleDate.jsDate;
      newEvent.title = this.modalData.event.title;
      newEvent.uid = this.modalData.uid;

      let newEventDataJSON = JSON.parse(JSON.stringify(newEvent));
      const newEventDataDoc: AngularFirestoreDocument<FirebaseCalendarEvent> = this.afs.doc<any>('calendarEvents/' + newEventDataJSON.uid);
      newEventDataDoc.update(newEventDataJSON)
        .then(() => {
          self.toastr.success('Modifica Salvata!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });

      // this.firestore.write('calendarEvents/' + newEventDataJSON.uid, newEventDataJSON).subscribe(() => {
      //   self.toastr.success('Modifica Salvata!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
    this.modalData = new ModalData();
  }

}
