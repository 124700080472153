import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer, Store } from '@ngrx/store';
// import { storeFreeze } from 'ngrx-store-freeze';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as fromLayout from './layout';
import * as fromCharacter from './character';
import * as fromDatas from './datas';
import * as fromChat from './chat';
import * as fromPM from './pm';
import { take } from 'rxjs/operators';

export interface State {
    layout: fromLayout.State;
    character: fromCharacter.State;
    datas: fromDatas.State;
    chat: fromChat.State;
    pm: fromPM.State;
    router: fromRouter.RouterReducerState;
}


export const reducers: ActionReducerMap<State> = {
    layout: fromLayout.reducer,
    character: fromCharacter.reducer,
    datas: fromDatas.reducer,
    chat: fromChat.reducer,
    pm: fromPM.reducer,
    router: fromRouter.routerReducer

};


// export function setupMetaReducers() {
//     const isProduction: boolean = true; //isProductionBuild(); disable store freeze because of IE!!
//     const actionAuditing: boolean = isActionAuditing();
//     const actionAndStateAuditing: boolean = isActionAndStateAuditing();

//     if (isProduction) {
//         if (actionAndStateAuditing)
//             return [actionAndStateLog, bulkRestoreReducer];
//         if (actionAuditing)
//             return [actionLog, bulkRestoreReducer];
//         else
//             return [bulkRestoreReducer];
//     }
//     // else {
//     //     if (actionAndStateAuditing)
//     //         return [actionAndStateLog, bulkRestoreReducer];
//     //     if (actionAuditing)
//     //         return [actionLog, bulkRestoreReducer, storeFreeze];
//     //     else
//     //         return [bulkRestoreReducer, storeFreeze];
//     // }
// }


export function getState(store: Store<State>): State {
    let state: State;
    store.pipe(take(1))
        .subscribe((s: State) => state = s);
    return state;
}


// export function isProductionBuild() {
//     const metas: HTMLCollectionOf<HTMLMetaElement> = document.getElementsByTagName('meta');
//     let content: string = "";
//     for (let i: number = 0; i < metas.length; i++) {
//         if (metas[i].getAttribute("name") == "Compilation-Type") {
//             content = metas[i].getAttribute("content");
//             break;
//         }
//     }

//     if (content == 'Production Build')
//         return true;
//     else
//         return false;
// }


// export function isActionAuditing(): boolean {
//     const appConfig: any = (<any>window).appConfig;
//     if (Functions.IsNullOrUndefined(appConfig))
//         return false;
//     else
//         return appConfig.ActionAuditing;
// }


// export function isActionAndStateAuditing(): boolean {
//     const appConfig: any = (<any>window).appConfig;
//     if (Functions.IsNullOrUndefined(appConfig))
//         return false;
//     else
//         return appConfig.ActionAndStateAuditing;
// }


// export function actionLog(reducer: ActionReducer<State>): ActionReducer<State> {
//     return function (state: State, action: any): State {
//         console.log(action);
//         return reducer(state, action);
//     };
// }


// export function actionAndStateLog(reducer: ActionReducer<State>): ActionReducer<State> {
//     return function (state: State, action: any): State {
//         console.log(action);
//         console.log(state);
//         return reducer(state, action);
//     };
// }

// export function bulkRestoreReducer(reducer: ActionReducer<State>): ActionReducer<State> {
//     return function (state: State, action: any): State {
//         // if (action.type == BULK_RESTORE) {
//         //     const newState: any = action.payload;
//         //     state = newState;
//         // }
//         return reducer(state, action);
//     };
// }

// export const metaReducers: MetaReducer<State>[] = setupMetaReducers();
