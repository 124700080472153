import { LocalDataSource } from 'ng2-smart-table';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Role, Race, UserPresence, PresencePlayerStatus, Stats, MAXStats, State } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-assegna-razza',
  templateUrl: 'cPanel-assegna-razza.component.html',
  styleUrls: ['cPanel-assegna-razza.component.less'],
})
export class CPanelAssegnaRazzaAPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPAnelAssegnaRazza";
  //#region - modal variables
  public racesMax: Map<Race, MAXStats> = new Map();
  public selectedCharacter: CharacterSheetData;
  public statsChanges: any;
  public isStatsModalVisible: boolean = false;
  public modalLVL: number = 1;
  public modalAction: string = "";
  public modalError: string = undefined;
  public forcingValue: boolean = false;
  public showForceOption: boolean = true;
  public str: string = "";
  public agl: string = "";
  public res: string = "";
  public wsd: string = "";
  public per: string = "";
  public wil: string = "";
  //#endregion - modal variables

  //#region - get users
  private playerToEditDoc: AngularFirestoreDocument<CharacterSheetData>;
  private usersPresence$: Observable<UserPresence[]>;
  private usersSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  private tempSubscription: Subscription = new Subscription();
  //#region - get users

  //#region - races variables
  public availableRaceIDsSet: Set<number> = new Set([0, 1, 2, 3, 4, 6, 7, 8, 9]);
  public races: any[] = [{ uid: 0, name: "Nephilim" }, { uid: 1, name: "Warlock" }, { uid: 2, name: "Sidhe" }, { uid: 3, name: "Lycan" }, { uid: 4, name: "Leech" }, { uid: 6, name: "Fantasma" }, { uid: 7, name: "Demone" }, { uid: 8, name: "Angelo" }, { uid: 9, name: "Dimenticato" }];
  public racesMap: Map<string, Race>;
  //#endregion - races variables

  //#region - other variables
  public myCharacterSheet: CharacterSheetData;
  public selectedRaceID: number;
  public selectedRace: Race;
  public mergedDataSource: LocalDataSource;
  public mergedData: any[] = [];
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    actions: {
      edit: false,
      delete: false,
      custom: [
        {
          name: 'edit',
          title: '<i class="material-icons icon-del access-pg">accessibility</i> ',
        },
        {
          name: 'remove',
          title: '<i class="material-icons icon-del access-pg">cancel</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Personaggio',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      },
      lvl: {
        title: 'Livello Razza',
        editor: {
          type: 'list',
          config: {
            list: [
              { value: 1, title: "1" },
              { value: 2, title: "2" },
              { value: 3, title: "3" },
              { value: 4, title: "4" },
              { value: 5, title: "5" }
            ]
          }
        }
      }
    }
  };
  //#endregion - other variables

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private afdb: AngularFireDatabase,
    private debugLogger: DebugLoggerService
  ) {
  }


  public ngOnInit() {
    //#region - prepare max stats
    const mondStats: MAXStats = new MAXStats(20);
    this.racesMax.set(Race.Mondano, mondStats);

    const nephilinStats: MAXStats = new MAXStats(30);
    nephilinStats.wsd = 15;
    this.racesMax.set(Race.Nephilim, nephilinStats);

    const leecStats: MAXStats = new MAXStats(50);
    leecStats.res = 15;
    leecStats.str = 30;
    this.racesMax.set(Race.Leech, leecStats);

    const lycanStats: MAXStats = new MAXStats(50);
    lycanStats.wsd = 15;
    lycanStats.wil = 30;
    this.racesMax.set(Race.Lycan, lycanStats);

    const siDheStats: MAXStats = new MAXStats(50);
    siDheStats.wil = 15;
    siDheStats.per = 30;
    this.racesMax.set(Race.Sidhe, siDheStats);

    const warlockStats: MAXStats = new MAXStats(50);
    warlockStats.res = 15;
    warlockStats.agl = 30;
    this.racesMax.set(Race.Warlock, warlockStats);

    const angeloStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Angelo, angeloStats);

    const demoneStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Demone, demoneStats);

    const dimenticatoStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Dimenticato, dimenticatoStats);

    const fantasmaStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Fantasma, fantasmaStats);
    //#endregion - prepare max stats

    let self = this;
    //#region - get users
    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }),
        first()
      )
      .subscribe((data: PresencePlayerStatus[]) => {
        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.characters = data;
        self.createMergedUserItemsData();
      });
    //#endregion - get users

    this.myCharacterSheet = fromRoot.getState(this.store).character.myCharacterData;
    this.showForceOption = (this.myCharacterSheet.role <= Role.admin);

    if (this.myCharacterSheet.role <= Role.gestore) {
      this.availableRaceIDsSet = new Set([0, 1, 2, 3, 4, 6, 7, 8, 9]);
    } else if (Functions.IsNullOrUndefined(this.myCharacterSheet.isCapoRace) == false && this.myCharacterSheet.isCapoRace.length > 0) {
      const toNumberArrayID: number[] = this.myCharacterSheet.isCapoRace.map((aRaceID: any) => parseInt(aRaceID.toString()));
      this.availableRaceIDsSet = new Set(toNumberArrayID);
      this.races = this.races.filter((aRace: any) => this.availableRaceIDsSet.has(aRace.uid));
    }

    this.mergedDataSource = new LocalDataSource();

    // if (this.myCharacterSheet.isCapoRace.length == 1) {
    //   this.onRaceChange(parseInt(this.myCharacterSheet.isCapoRace[0].toString()));
    // }

  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
      this.usersSubscription.unsubscribe();
    }
  }

  public onRaceChange(race: any) {
    if (Functions.IsNullOrUndefined(race) == true) {
      this.selectedRaceID = undefined;
      this.selectedRace = undefined;
    } else {
      this.selectedRaceID = race.uid;
      this.selectedRace = race;
      this.createMergedUserItemsData();
    }
  }

  private createMergedUserItemsData() {

    if (Functions.IsNullOrUndefined(this.selectedRaceID))
      return;

    // if (Functions.IsNullOrUndefined(this.racesMap) || this.racesMap.size == 0)
    //   return;

    if (Functions.IsNullOrUndefined(this.characters) || this.characters.length == 0)
      return;

    let itemsToBeDisplayed: any[] = [];
    let charactersWithoutRace: any[] = [];

    for (let index: number = 0; index < this.characters.length; index++) {
      const aCharacter: PresencePlayerStatus = this.characters[index];
      if (aCharacter.race != Race.Mondano) {
        // is part of a race
        if (aCharacter.race == this.selectedRaceID) {
          itemsToBeDisplayed.push(
            {
              uid: aCharacter.playerUID,
              name: aCharacter.name,
              lvl: aCharacter.lvl,
              raceId: this.selectedRaceID
            }
          );
        }
      } else {
        // it's a mondano
        charactersWithoutRace.push(
          {
            value: aCharacter.playerUID,
            title: aCharacter.name
          }
        );
      }
    }
    this.mergedData = itemsToBeDisplayed;
    this.mergedDataSource.load(itemsToBeDisplayed);

    this.setUpItemNameEditorList(charactersWithoutRace);
  }

  private setUpItemNameEditorList(charactersMondani: any[]) {
    // sorting alphabetically
    charactersMondani = charactersMondani.sort(function (a, b) {
      var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settings.columns.name.editor.config.list = charactersMondani;
    this.settings = Object.assign({}, this.settings);
  }

  public onCreateConfirm(event) {
    const self: this = this;
    event.confirm.resolve();
    const selectedCharacterID: string = event.newData.name;

    this.playerToEditDoc = this.afs.doc<any>('users/' + selectedCharacterID);
    const playerToEditDocObservable = this.playerToEditDoc.valueChanges();
    const playerToEditDocSubscription = playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
        }

        if (Functions.IsNullOrUndefined(selectedCharacter) || self.selectedRaceID < 0)
          return;

        const oldLVL: number = parseInt(selectedCharacter.lvl.toString());
        selectedCharacter.race = self.selectedRaceID;
        selectedCharacter.lvl = parseInt(event.newData.lvl);

        // for strange races do not compute stats values
        if (self.selectedRaceID <= 4) {
          const statsChanges: any = self.computeDeltaStats(selectedCharacter.race, oldLVL, parseInt(selectedCharacter.lvl.toString()), true, false);
          selectedCharacter.stats.str = selectedCharacter.stats.str + statsChanges.stats.str;
          selectedCharacter.stats.agl = selectedCharacter.stats.agl + statsChanges.stats.agl;
          selectedCharacter.stats.res = selectedCharacter.stats.res + statsChanges.stats.res;
          selectedCharacter.stats.per = selectedCharacter.stats.per + statsChanges.stats.per;
          selectedCharacter.stats.wsd = selectedCharacter.stats.wsd + statsChanges.stats.wsd;
          selectedCharacter.stats.wil = selectedCharacter.stats.wil + statsChanges.stats.wil;
          selectedCharacter.leftPoint = selectedCharacter.leftPoint + statsChanges.freePoints;
        }

        selectedCharacter.userSkills = [];
        selectedCharacter.userBMs = [];
        selectedCharacter.nature = "";
        // selectedCharacter.forceBuyableSkillDump = true;

        let updatedUserJsonData = JSON.parse(JSON.stringify(selectedCharacter));

        //#region - update player
        this.playerToEditDoc.update(updatedUserJsonData)
          .then(() => {
            self.updatePresence(selectedCharacter);
            self.toastr.success('Personaggio Assegnato alla Razza!');
          })
          .catch((error: any) => {
            self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
          })
        //#endregion - update player
      });

  }

  public onCustom(event) {
    this.modalAction = event.action;
    const selectedCharacterID: string = event.data.uid;

    const self: this = this;
    this.playerToEditDoc = this.afs.doc<any>('users/' + selectedCharacterID);
    const playerToEditDocObservable = this.playerToEditDoc.valueChanges();
    const playerToEditDocSubscription = playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
        }

        if (Functions.IsNullOrUndefined(selectedCharacter) || self.selectedRaceID < 0)
          return;

        self.selectedCharacter = selectedCharacter;
        if (event.action == "edit") {
          // editing lvl
          self.modalLVL = parseInt(selectedCharacter.lvl.toString());
          if (self.selectedRaceID <= 4) {
            self.statsChanges = this.computeDeltaStats(selectedCharacter.race, parseInt(selectedCharacter.lvl.toString()), parseInt(selectedCharacter.lvl.toString()), false, false);
            self.str = selectedCharacter.stats.str + self.statsChanges.stats.str;
            self.agl = selectedCharacter.stats.agl + self.statsChanges.stats.agl;
            self.res = selectedCharacter.stats.res + self.statsChanges.stats.res;
            self.wsd = selectedCharacter.stats.wsd + self.statsChanges.stats.wsd;
            self.per = selectedCharacter.stats.per + self.statsChanges.stats.per;
            self.wil = selectedCharacter.stats.wil + self.statsChanges.stats.wil;
          }
        } else {
          // removing from race
          if (self.selectedRaceID <= 4) {
            self.statsChanges = this.computeDeltaStats(selectedCharacter.race, parseInt(selectedCharacter.lvl.toString()), 1, false, true);
            self.str = selectedCharacter.stats.str + self.statsChanges.stats.str;
            self.agl = selectedCharacter.stats.agl + self.statsChanges.stats.agl;
            self.res = selectedCharacter.stats.res + self.statsChanges.stats.res;
            self.wsd = selectedCharacter.stats.wsd + self.statsChanges.stats.wsd;
            self.per = selectedCharacter.stats.per + self.statsChanges.stats.per;
            self.wil = selectedCharacter.stats.wil + self.statsChanges.stats.wil;
          }
        }

        if (self.selectedRaceID <= 4) {
          self.isStatsModalVisible = true;
        } else {
          // if special chat apply directly
          if (this.modalAction == "edit") {
            this.applyLvlChange();
          } else {
            this.backToMondane();
          }
        }
      });
  }

  public okModal() {
    if (Functions.IsStringEmpty(this.modalLVL.toString() as any) || parseInt(this.modalLVL.toString()) < 1) {
      this.modalLVL = parseInt(this.selectedCharacter.lvl.toString());
    }

    const lvlCheck: boolean = (parseInt(this.modalLVL.toString()) == parseInt(this.selectedCharacter.lvl.toString()));
    const remainingPoints: number = this.calculateRemainingPoints();
    const errorLeftPointsToRemove: boolean = (parseInt(this.modalLVL.toString()) < parseInt(this.selectedCharacter.lvl.toString())) && (remainingPoints > 0);
    const checkMaxSatisfied: boolean = this.checkMaxStats();

    if (lvlCheck == true) {
      this.modalError = "Nuovo livello uguale al livello precedente.";
    } else if (errorLeftPointsToRemove == true) {
      this.modalError = "Per completare l'azione devi prima sottrarre i punti rimanenti dalle caratteristiche.";
    } else if (checkMaxSatisfied == false) {
      this.modalError = "Le nuove statistiche non rispettano i massimali di razza nelle statistiche.";
    }


    if (this.modalAction == "edit") {
      // editing lvl
      if ((lvlCheck == true) || (errorLeftPointsToRemove == true && this.forcingValue == false) || (checkMaxSatisfied == false))
        return;

      this.applyLvlChange();
    } else {
      // removing from race
      if ((errorLeftPointsToRemove == true && this.forcingValue == false) || (checkMaxSatisfied == false))
        return;

      this.backToMondane();
    }
  }

  public closeModal() {
    this.str = "";
    this.agl = "";
    this.res = "";
    this.wsd = "";
    this.per = "";
    this.wil = "";
    this.statsChanges = undefined;
    this.modalAction = "";
    this.selectedCharacter = undefined;
    this.isStatsModalVisible = false;
  }

  public backToMondane() {
    this.selectedCharacter.race = Race.Mondano;
    this.selectedCharacter.lvl = 1;

    if (this.selectedRaceID <= 4) {
      this.selectedCharacter.stats.str = parseInt(this.str);
      this.selectedCharacter.stats.agl = parseInt(this.agl);
      this.selectedCharacter.stats.res = parseInt(this.res);
      this.selectedCharacter.stats.per = parseInt(this.per);
      this.selectedCharacter.stats.wsd = parseInt(this.wsd);
      this.selectedCharacter.stats.wil = parseInt(this.wil);
    }

    this.selectedCharacter.userBMs = [];
    this.selectedCharacter.userSkills = [];
    this.selectedCharacter.nature = "";
    // this.selectedCharacter.forceBuyableSkillDump = true;

    if (this.selectedRaceID <= 4) {
      const sumStartingStats: number = (this.selectedCharacter.stats.str + this.statsChanges.stats.str) + (this.selectedCharacter.stats.per + this.statsChanges.stats.per) +
        (this.selectedCharacter.stats.agl + this.statsChanges.stats.agl) + (this.selectedCharacter.stats.res + this.statsChanges.stats.res) +
        (this.selectedCharacter.stats.wsd + this.statsChanges.stats.wsd) + (this.selectedCharacter.stats.wil + this.statsChanges.stats.wil);
      const sumFinalStats: number = parseInt(this.str) + parseInt(this.agl) + parseInt(this.res) + parseInt(this.per) + parseInt(this.wsd) + parseInt(this.wil);
      const difference: number = sumStartingStats - sumFinalStats;
      const remainingPoints: number = Math.abs(this.statsChanges.freePoints - difference);
      this.selectedCharacter.leftPoint = parseInt(this.selectedCharacter.leftPoint.toString()) - remainingPoints;
      // safety check
      if (this.selectedCharacter.leftPoint < 0) {
        this.selectedCharacter.leftPoint = 0;
      }
    }

    // this.selectedCharacter.leftPoint = parseInt(this.selectedCharacter.leftPoint.toString()) - parseInt(this.statsChanges.freePoints);

    let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));
    const self: this = this;

    //#region - update player
    this.playerToEditDoc.update(updatedUserJsonData)
      .then(() => {
        self.updatePresence(self.selectedCharacter);
        self.toastr.success('Personaggio rimosso dalla Razza!');
        self.str = "";
        self.agl = "";
        self.res = "";
        self.wsd = "";
        self.per = "";
        self.wil = "";
        self.statsChanges = undefined;
        self.modalAction = "";
        self.selectedCharacter = undefined;
        self.isStatsModalVisible = false;
        self.modalLVL = 1;
        self.modalError = undefined;
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })
    //#endregion - update player

    // this.tempSubscription.add(
    //   self.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //     self.presenceService.updateAUserInfos(self.selectedCharacter, true);
    //     self.toastr.success('Personaggio rimosso dalla Razza!');
    //     self.str = "";
    //     self.agl = "";
    //     self.res = "";
    //     self.wsd = "";
    //     self.per = "";
    //     self.wil = "";
    //     self.statsChanges = undefined;
    //     self.modalAction = "";
    //     self.selectedCharacter = undefined;
    //     self.isStatsModalVisible = false;
    //     self.modalLVL = 1;
    //     self.modalError = undefined;
    //   }, (error) => {
    //     self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    //   })
    // );
  }

  public applyLvlChange() {
    const increasingLVL: boolean = (parseInt(this.modalLVL.toString()) > parseInt(this.selectedCharacter.lvl.toString()));
    this.selectedCharacter.lvl = parseInt(this.modalLVL.toString());

    if (this.selectedRaceID <= 4) {
      this.selectedCharacter.stats.str = parseInt(this.str);
      this.selectedCharacter.stats.agl = parseInt(this.agl);
      this.selectedCharacter.stats.res = parseInt(this.res);
      this.selectedCharacter.stats.per = parseInt(this.per);
      this.selectedCharacter.stats.wsd = parseInt(this.wsd);
      this.selectedCharacter.stats.wil = parseInt(this.wil);
    }

    if (this.selectedRaceID <= 4) {
      if (increasingLVL) {
        this.selectedCharacter.leftPoint = parseInt(this.selectedCharacter.leftPoint.toString()) + parseInt(this.statsChanges.freePoints);
      } else {
        const sumStartingStats: number = (this.selectedCharacter.stats.str + this.statsChanges.stats.str) + (this.selectedCharacter.stats.per + this.statsChanges.stats.per) +
          (this.selectedCharacter.stats.agl + this.statsChanges.stats.agl) + (this.selectedCharacter.stats.res + this.statsChanges.stats.res) +
          (this.selectedCharacter.stats.wsd + this.statsChanges.stats.wsd) + (this.selectedCharacter.stats.wil + this.statsChanges.stats.wil);
        const sumFinalStats: number = parseInt(this.str) + parseInt(this.agl) + parseInt(this.res) + parseInt(this.per) + parseInt(this.wsd) + parseInt(this.wil);
        const difference: number = sumStartingStats - sumFinalStats;
        const remainingPoints: number = Math.abs(this.statsChanges.freePoints - difference);
        this.selectedCharacter.leftPoint = parseInt(this.selectedCharacter.leftPoint.toString()) - remainingPoints;

        // safety check
        if (this.selectedCharacter.leftPoint < 0) {
          this.selectedCharacter.leftPoint = 0;
        }
      }
    }

    let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));
    const self: this = this;

    //#region - update player
    this.playerToEditDoc.update(updatedUserJsonData)
      .then(() => {
        self.updatePresence(self.selectedCharacter);
        self.toastr.success('Livello personaggio modificato!');
        self.str = "";
        self.agl = "";
        self.res = "";
        self.wsd = "";
        self.per = "";
        self.wil = "";
        self.statsChanges = undefined;
        self.modalAction = "";
        self.selectedCharacter = undefined;
        self.isStatsModalVisible = false;
        self.modalLVL = 1;
        self.modalError = undefined;
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })
    //#endregion - update player

    // this.tempSubscription.add(
    //   self.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //     self.presenceService.updateAUserInfos(self.selectedCharacter, true);
    //     self.toastr.success('Livello personaggio modificato!');
    //     self.str = "";
    //     self.agl = "";
    //     self.res = "";
    //     self.wsd = "";
    //     self.per = "";
    //     self.wil = "";
    //     self.statsChanges = undefined;
    //     self.modalAction = "";
    //     self.selectedCharacter = undefined;
    //     self.isStatsModalVisible = false;
    //     self.modalLVL = 1;
    //     self.modalError = undefined;
    //   }, (error) => {
    //     self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    //   })
    // );
  }

  public onLvlChange(lvl: number) {
    this.modalLVL = parseInt(lvl.toString());

    if (this.selectedRaceID <= 4) {
      this.statsChanges = this.computeDeltaStats(this.selectedCharacter.race, parseInt(this.selectedCharacter.lvl.toString()), parseInt(this.modalLVL.toString()), false, false);
      this.str = this.selectedCharacter.stats.str + this.statsChanges.stats.str;
      this.agl = this.selectedCharacter.stats.agl + this.statsChanges.stats.agl;
      this.res = this.selectedCharacter.stats.res + this.statsChanges.stats.res;
      this.wsd = this.selectedCharacter.stats.wsd + this.statsChanges.stats.wsd;
      this.per = this.selectedCharacter.stats.per + this.statsChanges.stats.per;
      this.wil = this.selectedCharacter.stats.wil + this.statsChanges.stats.wil;
    }
  }

  public calculateRemainingPoints() {
    if (Functions.IsStringEmpty(this.modalLVL.toString() as any) || parseInt(this.modalLVL.toString()) < 1) {
      this.modalLVL = parseInt(this.selectedCharacter.lvl.toString());
    }

    const increasingLVL = (parseInt(this.modalLVL.toString()) > parseInt(this.selectedCharacter.lvl.toString()));

    const sumStartingStats: number = (this.selectedCharacter.stats.str + this.statsChanges.stats.str) + (this.selectedCharacter.stats.per + this.statsChanges.stats.per) +
      (this.selectedCharacter.stats.agl + this.statsChanges.stats.agl) + (this.selectedCharacter.stats.res + this.statsChanges.stats.res) +
      (this.selectedCharacter.stats.wsd + this.statsChanges.stats.wsd) + (this.selectedCharacter.stats.wil + this.statsChanges.stats.wil);

    const sumFinalStats: number = parseInt(this.str) + parseInt(this.agl) + parseInt(this.res) + parseInt(this.per) + parseInt(this.wsd) + parseInt(this.wil);
    const difference: number = sumStartingStats - sumFinalStats;

    if (increasingLVL) {
      return Math.abs(this.statsChanges.freePoints - difference);
    } else {
      const remainingPoints: number = Math.abs(this.statsChanges.freePoints - difference);
      return Math.abs(this.selectedCharacter.leftPoint - remainingPoints);
    }
  }

  private computeDeltaStats(race: Race, oldLVL: number, newLVL: number, addedToRace: boolean, removedFromRace: boolean) {
    const statsBonus: Stats = new Stats();
    let freePoints: number = 0;
    let acquiredLVL: number = newLVL - oldLVL;
    if (addedToRace) {
      acquiredLVL = acquiredLVL + 1;
    }

    if (removedFromRace) {
      acquiredLVL = acquiredLVL - 1;
    }

    switch (race) {
      case Race.Nephilim:
        statsBonus.agl = acquiredLVL * 3;
        statsBonus.str = acquiredLVL * 2;
        freePoints = acquiredLVL * 10;
        break;
      case Race.Warlock:
        statsBonus.wsd = acquiredLVL * 5;
        statsBonus.wil = acquiredLVL * 3;
        freePoints = acquiredLVL * 12;
        break;
      case Race.Sidhe:
        statsBonus.res = acquiredLVL * 5;
        statsBonus.wsd = acquiredLVL * 3;
        freePoints = acquiredLVL * 12;
        break;
      case Race.Lycan:
        statsBonus.str = acquiredLVL * 5;
        statsBonus.res = acquiredLVL * 3;
        freePoints = acquiredLVL * 12;
        break;
      case Race.Leech:
        statsBonus.wil = acquiredLVL * 5;
        statsBonus.agl = acquiredLVL * 3;
        freePoints = acquiredLVL * 12;
        break;
    }

    if (addedToRace) {
      freePoints = freePoints + 5;
    }

    if (removedFromRace) {
      freePoints = freePoints - 5;
    }

    //ERRORE!!! TO FIX promosso da mondano a nephilim 3° livello da 5 punti in meno
    if (oldLVL < newLVL && newLVL == 2) {
      freePoints = freePoints + 5;
    } else if (newLVL < oldLVL && newLVL == 1) {
      freePoints = freePoints - 5;
    }

    return { stats: statsBonus, freePoints: Math.abs(freePoints) };
  }

  private checkMaxStats(): boolean {
    if (Functions.IsNullOrUndefined(this.selectedCharacter) == true)
      return false;

    const myRace: Race = this.selectedCharacter.race;
    const mySrtValue: number = parseInt(this.str.toString());
    if (mySrtValue > this.racesMax.get(myRace).str)
      return false;

    const myAglValue: number = parseInt(this.agl.toString());
    if (myAglValue > this.racesMax.get(myRace).agl)
      return false;

    const myResValue: number = parseInt(this.res.toString());
    if (myResValue > this.racesMax.get(myRace).res)
      return false;

    const myWsdValue: number = parseInt(this.wsd.toString());
    if (myWsdValue > this.racesMax.get(myRace).wsd)
      return false;

    const myPerValue: number = parseInt(this.per.toString());
    if (myPerValue > this.racesMax.get(myRace).per)
      return false;

    const myWilValue: number = parseInt(this.wil.toString());
    if (myWilValue > this.racesMax.get(myRace).wil)
      return false;

    return true;
  }

  private updatePresence(characterData: CharacterSheetData) {
    //#region - update presence status
    const oldPresenceStatus: PresencePlayerStatus = this.characters.find((aCharacterStatus: PresencePlayerStatus) => aCharacterStatus.playerUID == characterData.uid);
    let lastAccess: string = Functions.GetCurrentDate();
    let ip: string = "";
    if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
      lastAccess = (oldPresenceStatus.lastAccess || Functions.GetCurrentDate());
    }
    if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
      ip = (oldPresenceStatus.ip || "");
    }
    const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterData.uid, characterData.race, characterData.role, characterData.MAvatar, characterData.name, characterData.sur, characterData.nick, lastAccess, characterData.sex, (parseInt(characterData.lvl.toString()) || 1), (characterData.state || State.libero), (characterData.stateText || ""), characterData.isPng, characterData.isBanned, characterData.lastMessageSent, characterData.myPrivateChat, (characterData.clan || ""), characterData.clanLvl, (characterData.corp || ""), characterData.corpLvl, characterData.isActive, characterData.isCapoClan, characterData.isCapoCorp, characterData.isCapoRace, ip);
    const presenceStatusJSON = JSON.parse(JSON.stringify(presenceSatus));

    const userPresenceDoc = this.afdb.object('status/' + characterData.uid + '/state');
    userPresenceDoc.update(presenceStatusJSON);
    //#endregion - update presence status
  }
}
