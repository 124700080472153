import { Functions } from './../../../utilities/functions/utilities.functions';
import { StandardChat, SecretChat, Role, SpecialChat } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as layoutSelector from './../../../../selectors/layout.selectors';
import * as characterSelector from './../../../../selectors/character.selectors';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { ChatMessage } from '../../../chatRoom/models/chat-message.models';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'cPanel-log-chat',
  templateUrl: 'cPanel-log-chat.component.html',
  styleUrls: ['cPanel-log-chat.component.less'],
})
export class CPanelLogChatComponent implements OnInit, OnDestroy {

  //#region - standard chats variables
  public standardChatsList$: Observable<StandardChat[]>;
  public selectedChat: StandardChat = undefined;
  //#endregion - standard chats variables

  //#region - secrets chats variables
  public availableSecretChats$: Observable<SecretChat[]>;
  public selectedSecretChat: SecretChat = undefined;
  //#region - secrets chats variables

  //#region - standard chats variables
  public specialChatsList$: Observable<SpecialChat[]>;
  public selectedSpecialChat: StandardChat = undefined;
  //#endregion - standard chats variables

  //#region - date picker variables
  public myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    inline: true
  };
  public locale: string = "it";
  public date: IMyDateModel = null;
  //#endregion - date picker variables

  //#region - messages variables
  public messagesCollections: AngularFireList<ChatMessage>;
  public messagesCollectionsObservable: Observable<ChatMessage[]>
  private messagesSubscription: Subscription;
  public messages: ChatMessage[] = undefined;
  //#endregion - messages variables

  //#region - other variables
  public myUID: string = "";
  public myRole: Role;
  //#endregion - other variables

  //#region - deprecated variables
  editingMsgId: string = "";
  editedText: string = "";
  //#endregion - deprecated variables

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private afdb: AngularFireDatabase
  ) {

  }

  public ngOnInit() {
    this.myUID = fromRoot.getState(this.store).character.myCharacterData.uid;
    this.myRole = fromRoot.getState(this.store).character.myCharacterData.role;

    //#region - get standard chats
    this.standardChatsList$ = this.store.select(layoutSelector.getStandardChatsList).pipe(map((chats: StandardChat[]) => {
      const sortedChat: StandardChat[] = chats.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return sortedChat;
    }));
    //#endregion - get standard chats

    //#region - get available secret chats
    this.availableSecretChats$ = this.store.select(characterSelector.getAvailableSecretChats).pipe(map((chats: SecretChat[]) => {
      const sortedChat: SecretChat[] = chats.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return sortedChat;
    }));
    //#endregion - get available secret chats

    //#region - get standard chats
    this.specialChatsList$ = this.store.select(layoutSelector.getSpecialChatsList).pipe(map((chats: SpecialChat[]) => {
      const sortedChat: SpecialChat[] = chats.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return sortedChat;
    }));
    //#endregion - get standard chats
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.messagesSubscription) == false)
      this.messagesSubscription.unsubscribe();
  }

  public onChatChange(chat: StandardChat) {
    this.selectedSecretChat = undefined;
    this.selectedSpecialChat = undefined;
  }

  public onSecretChatChange(secretChat: SecretChat) {
    this.selectedChat = undefined;
    this.selectedSpecialChat = undefined;
  }

  public onSpecialChatChange(secretChat: SpecialChat) {
    this.selectedChat = undefined;
    this.selectedSecretChat = undefined;
  }

  public onDateChanged(event: IMyDateModel): void {
    // date selected
    this.date = event;
    //ask for chat dates
    this.getChatMessages();
  }

  private getChatMessages() {

    const chat: any = this.selectedChat || this.selectedSecretChat || this.selectedSpecialChat;
    if (Functions.IsNullOrUndefined(chat))
      return;

    if (Functions.IsNullOrUndefined(this.date))
      return;

    // const before2Hour = new Date().getTime() - (2 * 3600 * 1000);
    const startingDate: any = this.date.singleDate.jsDate.getTime();
    const endDate: any = startingDate + (24 * 3600 * 1000);

    const self: this = this;
    if (Functions.IsNullOrUndefined(this.messagesSubscription) == false) {
      this.messagesSubscription.unsubscribe();
    }

    //#region - get messages
    this.messagesCollections = this.afdb.list<ChatMessage>('/chats/' + chat + '/messages', ref => ref.orderByChild('time').startAt(startingDate).endAt(endDate));
    this.messagesCollectionsObservable = this.messagesCollections.valueChanges();
    this.messagesSubscription = this.messagesCollectionsObservable.pipe(first()).subscribe((messages: ChatMessage[]) => {
      // this.messagesSubscription = this.realTimeDB.query('chats/' + chat + '/messages').orderByChild('time').startAt(startingDate).endAt(endDate).once('value')
      //   .skip(1).subscribe((messages: ChatMessage[]) => {
      if (Functions.IsNullOrUndefined(messages)) {
        self.messages = [];
      } else {
        let filteredMessages: ChatMessage[] = messages;
        // filteredMessages = filteredMessages.map((aMessage: any) => aMessage[0]);

        filteredMessages = filteredMessages.sort(function (a, b) {
          var timeA = a.time, timeB = b.time;
          if (timeA < timeB) //sort string ascending
            return -1;
          if (timeA > timeB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.messages = filteredMessages;
      }
    });
    //#endregion - get messages
  }

  public delete(message: ChatMessage) {
    if (window.confirm('Sei sicuro di voler cancellare il messaggio selezionato?')) {

      const chat: any = this.selectedChat || this.selectedSecretChat ||this.selectedSpecialChat;
      if (Functions.IsNullOrUndefined(chat))
        return;

      if (Functions.IsNullOrUndefined(message) || Functions.IsStringEmpty(message.msgID))
        return;

      this.messagesCollections.remove(message.msgID);
      this.getChatMessages();
      // this.realTimeDB.remove('chats/' + chat + '/messages/' + message.msgID);
    } else {
      //do nothing;
    }
  }

  public deleteAll() {
    if (window.confirm('Sei sicuro di voler cancellare tutti i messaggi?')) {

      const chat: any = this.selectedChat || this.selectedSecretChat || this.selectedSpecialChat;
      if (Functions.IsNullOrUndefined(chat))
        return;

      if (Functions.IsNullOrUndefined(this.messages) || this.messages.length <= 0)
        return;

      // for (let index: number = 0; index < this.messages.length; index++) {
      //   const aMessage: ChatMessage = this.messages[index];

      //   if (Functions.IsStringEmpty(aMessage.msgID))
      //     continue;

      //   this.realTimeDB.remove('chats/' + chat + '/messages/' + aMessage.msgID);
      // }

      this.messagesCollections.remove();
      this.getChatMessages();

    } else {
      //do nothing;
    }
  }

  //#region - deprecated
  // public editMessage(message: ChatMessage) {
  //   if (this.editingMsgId == message.msgID) {
  //     this.editingMsgId = undefined;
  //     this.editedText = "";
  //   } else {
  //     this.editingMsgId = message.msgID;
  //     this.editedText = message.text;
  //   }
  // }

  // public saveEdit(message: ChatMessage) {
  //   const chat: any = this.selectedChat || this.selectedSecretChat;
  //   if (Functions.IsNullOrUndefined(chat))
  //     return;

  //   if (Functions.IsNullOrUndefined(message) || Functions.IsStringEmpty(message.msgID))
  //     return;

  //   if (Functions.IsStringEmpty(this.editedText))
  //     return;

  //   const editedMessage: ChatMessage = Object.assign({}, message);
  //   editedMessage.text = this.editedText;
  //   this.realTimeDB.update('chats/' + chat + '/messages/' + message.msgID, editedMessage);

  //   const currentMessage: ChatMessage = this.messages.find((aMessage: ChatMessage) => aMessage.msgID == message.msgID);
  //   if (Functions.IsNullOrUndefined(currentMessage) == false) {
  //     currentMessage.text = this.editedText;
  //   }

  //   this.editingMsgId = undefined;
  //   this.editedText = "";
  // }
  //#endregion - deprecated

}
