import { AuthenticationService } from './../../../services/authentication.service';
import { Component, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import * as layout from './../../../actions/layout';
import { LayoutState } from '../../../modules/utilities/utilities.constants';

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements AfterViewInit {

  emailValue: string;
  passwordValue: string;

  loginMaxWidth: number = 0;
  loginHeight: number = 0;
  homeEsternaPaddingTop: number = 0;
  @ViewChild('login', { static: false }) private loginElem: ElementRef;

  constructor(
    public authenticationService: AuthenticationService,
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef
  ) { }


  public ngAfterViewInit() {
    this.onResize();
  }

  backToHomeEsterna() {
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.HomeEsterna));
  }


  login2() {
    if (Functions.IsNullOrUndefined(this.emailValue) || Functions.IsStringEmpty(this.emailValue))
      return;

    if (Functions.IsNullOrUndefined(this.passwordValue) || Functions.IsStringEmpty(this.passwordValue))
      return;

    this.authenticationService.login(this.emailValue, this.passwordValue);
  }

  resetPassword() {
    this.authenticationService.resetPassword(this.emailValue);
  }

  // buildForm() {
  //   this.loginForm = this.fb.group({
  //     'email': ['', [
  //       Validators.required,
  //       Validators.email,
  //     ]],
  //     'password': ['', [
  //       Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
  //       Validators.minLength(6),
  //       Validators.maxLength(25),
  //     ]],
  //   });


  //   this.resetForm = this.fb.group({
  //     'email': ['', [
  //       Validators.required,
  //       Validators.email,
  //     ]]
  //   });
  //   // this.userForm.valueChanges.subscribe((data) => this.onValueChanged(data));
  //   // this.onValueChanged(); // reset validation messages
  // }

  public onResize(event?: any) {
    let windowsHeight = window.innerHeight;
    let windowsWidth = window.innerWidth;

    // let height: number = this.mainMapContainer.nativeElement.offsetHeight;
    let height: number = (windowsWidth / 100) * 70;
    // let width: number = this.mainMapContainer.nativeElement.offsetWidth;
    let parentHeight: number = this.loginElem.nativeElement.parentElement.offsetHeight;
    let parentPadding = (parentHeight / 100) * 14;
    parentHeight = parentHeight - parentPadding;

    if (height > parentHeight)
      height = parentHeight;

    if (height > 600)
      height = 600;

    if (height < 200)
      height = 200;

    let maxWidth = height * 1.333;
    if (maxWidth > 800)
      maxWidth = 800;

    this.loginMaxWidth = maxWidth;
    this.loginHeight = height;
    this.homeEsternaPaddingTop = parentPadding;
    this.cdr.detectChanges();
  }

  public close() {
    this.authenticationService.needVerification = false;
    setTimeout((that) => {
      that.authenticationService.logout(true);
    }, 10, this);
  }

  public rimandaMail() {
    this.authenticationService.sendVerificationEmail();
    this.close();
  }

}
