import { CharacterSheetData } from './../models/data/application.data';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { Subscription } from 'rxjs';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as character from '../actions/character';
import * as layout from '../actions/layout';
import * as chat from '../actions/chat';
import { Observable } from 'rxjs';
import { LayoutState, MapState } from '../modules/utilities/utilities.constants';
import { ToastrService } from 'ngx-toastr';
import { FirestoreService } from './firestore.service';
import { PresenceService } from './presence.service';
import * as characterSelector from './../selectors/character.selectors';
import { AngularFireAuth } from "@angular/fire/auth"
// import firebase = require('firebase');
import { CacheService } from './cache.service';
import { skip } from 'rxjs/operators';
// import * as firebase from 'firebase';
import { auth } from 'firebase/app';
import { ChatService } from '../modules/chatRoom/services/chat.service';
import { PmService } from './pm.service';
import { CPanelService } from '../modules/cPanel/services/cpanel.service';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable()
export class AuthenticationService {

  authSubscription: Subscription;
  readSubscription: Subscription;

  myCharacterSheet$: Observable<CharacterSheetData>
  myCharacterSheetSubscription: Subscription;

  private ipSubscrition: Subscription;
  private ipListSubscription: Subscription;

  public needVerification: boolean = false;

  public preloadedImgs: any[] = [];

  constructor(
    private store: Store<fromRoot.State>,
    public firestoreService: FirestoreService,
    private presenceService: PresenceService,
    private toastr: ToastrService,
    private afAuth: AngularFireAuth,
    private cacheService: CacheService,
    private chatService: ChatService,
    private pmService: PmService,
    private cPanelService: CPanelService,
    private http: HttpClient,
    private afs: AngularFirestore
  ) {
    let self = this;

    this.preloadMainImages();
    this.getIp();

    this.afAuth.auth.languageCode = "it";
    this.afAuth.auth.setPersistence(auth.Auth.Persistence.NONE).then(function () {
      //changing session state
    });

    this.afAuth.auth.onAuthStateChanged((userAuth: any) => {
      // safety check
      if (Functions.IsNullOrUndefined(userAuth) == true)
        return;

      let uid: string = userAuth.uid;

      // this.authSubscription = this.auth.uid().subscribe((uid: string) => {
      if (Functions.IsNullOrUndefined(uid) == false) {

        //uid = "VwJrBWSpoDTiM4ZX5QCTGf5zPx62"; // test impersonating

        // logged - go to home interna
        // self.readSubscription = self.firestore.read('users/' + uid).skip(1).subscribe((userData) => {
        //   self.store.dispatch(new character.StoreUserData({ myCharacter: userData, isAuthenticated: true, uid: uid }));
        //   self.readSubscription.unsubscribe();

        // load cache and update data if needed
        self.cacheService.loadCachedData();

        // download my character sheet
        self.firestoreService.getMyCharacterSheet(uid);

        //#region - ban checker
        if (Functions.IsNullOrUndefined(this.myCharacterSheetSubscription) == false) {
          this.myCharacterSheetSubscription.unsubscribe();
        }

        this.myCharacterSheet$ = self.store.select(characterSelector.getCharacterSheet);
        this.myCharacterSheetSubscription = this.myCharacterSheet$.pipe(skip(1)).subscribe((myCharacterSheet: CharacterSheetData) => {
          const isAutheticated = fromRoot.getState(self.store).character.isAuthenticated;
          if (isAutheticated == true && (Functions.IsNullOrUndefined(myCharacterSheet) || myCharacterSheet.isBanned))
            self.logout();
        });
        //#endregion

        // // set-up presence system
        // self.presenceService.setUpMyPresence(uid);
        // self.presenceService.subscribeUserStatus();

        // if (fromRoot.getState(this.store).layout.layoutState == LayoutState.Login || fromRoot.getState(self.store).layout.layoutState == LayoutState.HomeEsterna) {
        //   self.store.dispatch(new layout.GoToHomeInternaAction());
        // }
        // });
      }

      // Go to home esterna
      self.store.dispatch(new character.StoreUserData({ myCharacter: undefined, isAuthenticated: false, uid: "" }));
      // self.store.dispatch(new layout.GoToHomeEsternaAction());
    }, (err) => {
      // Error
      self.toastr.error('Qualcosa è andato storto, per favore riavvia la pagina.', 'Errore', {
        timeOut: 10000,
        extendedTimeOut: 4000,
        positionClass: "toast-top-center"
      });
    });
  }


  public login(mail: string, password: string) {
    let self = this;
    this.afAuth.auth.signInWithEmailAndPassword(mail, password)
      .then((data: firebase.auth.UserCredential) => {
        self.store.dispatch(new character.StoreAuthData(data));

        if (data.user.emailVerified) {
          // mail verified
          self.store.dispatch(new layout.GoToHomeInternaAction());
        } else {
          // mail not verified
          self.needVerification = true;
        }
      })
      .catch((error: any) => {
        // Error
        self.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.loginError));
      });

    // let subscription: Subscription;
    // subscription = this.auth.signin(mail, password).subscribe((data) => {
    //   self.store.dispatch(new layout.GoToHomeInternaAction());
    //   subscription.unsubscribe();
    // }, (err) => {
    //   // Error
    //   self.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.loginError));
    //   // self.toastr.error('Qualcosa è andato storto, per favore riavvia la pagina.', 'Errore', {
    //   //   timeOut: 10000,
    //   //   extendedTimeOut: 4000,
    //   //   positionClass: "toast-top-center"
    //   // });
    // });
  }

  public logout(doNotGoToLogOutPage: boolean = false) {
    let self: this = this;

    // sync present system
    const uid: string = fromRoot.getState(this.store).character.myUID;
    this.presenceService.logOutMyPresence(uid);
    this.firestoreService.logoutReset();
    this.chatService.unsubscribeFromEverything();
    this.cacheService.logoutReset();
    this.cPanelService.logoutReset();

    // clean and logout
    this.store.dispatch(new character.LogOutClean());
    this.store.dispatch(new chat.LogOutClean());
    this.afAuth.auth.signOut();
    // this.auth.signout();
    if (doNotGoToLogOutPage == false) {
      setTimeout(() => {
        self.store.dispatch(new layout.UpdateMapStateAction(MapState.mainMap));
        self.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Logout));
        self.store.dispatch(new layout.GoToLogoutAction());
      }, 0);
    }
  }

  public resetPassword(mail: string) {
    this.afAuth.auth.sendPasswordResetEmail(mail);
    // this.auth.sendPasswordResetEmail(mail);
  }

  public loginOrProceed() {
    const self: this = this;
    let subscription: Subscription = this.afAuth.authState.subscribe((userAuth: any) => {
      subscription.unsubscribe();
      if (Functions.IsNullOrUndefined(userAuth) == false) {
        self.store.dispatch(new layout.GoToHomeInternaAction());
      } else {
        self.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Login));
      }
    });

    // let subscription: Subscription = this.auth.isAuthenticated().subscribe((isAuthenticated: boolean) => {
    //   subscription.unsubscribe();
    //   if (isAuthenticated == true) {
    //     self.store.dispatch(new layout.GoToHomeInternaAction());
    //   } else {
    //     self.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Login));
    //   }
    // });
  }

  public sendVerificationEmail() {
    const userAuth: firebase.User = this.afAuth.auth.currentUser;
    userAuth.sendEmailVerification()
      .then((data: any) => {
        //DO NOTHING
      })
      .catch((error: any) => {
        // Error
        console.log("verification email error");
      });
  }

  public changePassword(newPass: string) {
    const userAuth: firebase.User = this.afAuth.auth.currentUser;
    userAuth.updatePassword(newPass)
      .then((data: any) => {
        //DO NOTHING
      })
      .catch((error: any) => {
        // Error
        console.log("change password error");
      });
    // this.auth.updatePassword(newPass);
  }

  private preloadMainImages() {
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/esterne/loginEsternaF.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/menu/menu.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/menu/menu_m2.jpg");

    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/mappa_principale.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/mappa_principale_wl.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/mappa_principale_el.jpg");

    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_west_main.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_west_vall.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_west_north.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_west_ws.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_west_holl.jpg");

    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_east_main.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_east_down.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_east_north.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_east_south.jpg");
    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/maps/nmappa_east_sbay.jpg");

    this.preloadedImgs.push((new Image()).src = "https://unveiledgdr.altervista.org/assets/images/esterne/erroreLoginF.jpg");
  }

  private getIp() {
    const self: this = this;
    this.ipSubscrition = this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        self.ipSubscrition.unsubscribe();
        self.presenceService.ip = data.ip;
        self.checkMyip(data.ip);
      })
  }

  private checkMyip(myIP: string) {
    let self = this;
    if (Functions.IsNullOrUndefined(this.ipListSubscription) == true) {
      const ipListDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/ipban');
      const ipListDocObservable: Observable<any> = ipListDoc.valueChanges();
      this.ipListSubscription = ipListDocObservable.subscribe((data) => {
        if (Functions.IsNullOrUndefined(data) == false && Functions.IsNullOrUndefined(data.value) == false && data.value.length > 0) {
          const ipSet: Set<string> = new Set();
          for (const anIPObj of data.value) {
            ipSet.add(anIPObj.ip);
          }

          if (ipSet.has(myIP) == true) {
            window.location.href = 'http://www.google.com';
          }
        }
      });
    }
  }
}
