import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as characterSelector from './../../../selectors/character.selectors';
import { Observable } from 'rxjs';
import * as character from './../../../actions/character';
import { PmService } from '../../../services/pm.service';

// Utilities
@Component({
  selector: 'uffici-staff-component',
  templateUrl: './uffici-staff.component.html',
  styleUrls: ['./uffici-staff.component.less']
})

export class UfficiStaffComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiStaffComponent";

  public staff$: Observable<any>;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(private store: Store<fromRoot.State>, private cdr: ChangeDetectorRef, private fb: FormBuilder, private pmService: PmService) {
    this.staff$ = this.store.select(characterSelector.getStaff);
  }

  public ngOnInit() {

  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiStaffAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public goToScheda(uid: string) {
    this.store.dispatch(new character.SelectplayerID(uid));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: true, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
  }

  public sendPM(userID: string) {
    this.pmService.sendToUID = userID;
    this.store.dispatch(new layout.ToggleCreaMessaggioBoxAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
