import { Component, OnInit, Input, Output } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { User, userDetails } from '../../models/user.model';
import { Functions } from '../../../utilities/functions/utilities.functions';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { Observable } from 'rxjs';
import { OnlineUser } from '../../models/chat-message.models';
import * as layout from './../../../../actions/layout';
import * as chat from './../../../../actions/chat';
import * as chatSelector from './../../../../selectors/chat.selectors';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})

export class UserListComponent {

  getChatOnlineUsers$: Observable<OnlineUser[]>;

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit() {
    this.getChatOnlineUsers$ = this.store.select(chatSelector.getChatOnlineUsers);

    // const userName: string = fromRoot.getState(this.store).character.myCharacterData.name;
    // this.userTest = [{
    //   displayName: userName,
    //   status: "online"
    // }];
  }

  openSchedaTattica(user: OnlineUser) {
    this.store.dispatch(new chat.SelectChatPlayer(user.uid));
  }
}