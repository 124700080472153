import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-staff-news',
  templateUrl: 'cPanel-staff-news.component.html',
  styleUrls: ['cPanel-staff-news.component.less'],
})
export class CPanelStaffNewsComponent implements OnInit, OnDestroy {

  private moduleName: string = "cPanelStaffNews";

  private staffNewsDoc: AngularFirestoreDocument<any>;
  private staffNewsDocObservable: Observable<any>;
  private staffNewsSubscription: Subscription;

  public staffNews: any = { value: "" };
  public config: any = {
    height: 550,
    menubar: false,
    theme: 'modern',
    plugins: [
      'advlist autolink link image imagetools lists charmap hr',
      'wordcount visualblocks visualchars code',
      'table contextmenu textcolor paste colorpicker',
    ],
    toolbar1:
      'bold italic underline strikethrough | undo redo | blockquote numlist bullist | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect | forecolor backcolor',
    toolbar2:
      'cut copy paste | outdent indent | link unlink image table | hr removeformat | subscript superscript | charmap | code',
    image_advtab: true,
    imagetools_toolbar:
      'rotateleft rotateright | flipv fliph | editimage imageoptions',
  };

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.staffNewsDoc = this.afs.doc<any>('settings/staffNews');
    this.staffNewsDocObservable = this.staffNewsDoc.valueChanges();
    this.staffNewsSubscription = this.staffNewsDocObservable.subscribe((data: any) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ staff news cPanel", self.moduleName, "settings/staffNews", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.staffNews = data;
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.staffNewsSubscription) == false) {
      this.staffNewsSubscription.unsubscribe();
    }
  }

  public salva() {
    if (Functions.IsNullOrUndefined(this.staffNews) == false) {
      let self = this;

      //Removing p tags
      this.staffNews.value = (this.staffNews.value as string).replace(/<p>/g, "").replace(/<\/p>/g, "");

      let newStaffNews = JSON.parse(JSON.stringify(this.staffNews));
      this.staffNewsDoc.update(newStaffNews)
        .then(() => {
          self.toastr.success('Staff news salvate!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.write('settings/staffNews', newStaffNews).subscribe(() => {
      //   self.toastr.success('Modifica Salvata!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
  }
}
