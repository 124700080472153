import { CharacterSheetData } from './../models/data/application.data';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as layout from '../actions/layout';
import * as character from '../actions/character';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as fromRoot from './../reducers';
import { IncrementalService } from '../services/incremental.service';
import { Observable, of } from 'rxjs';
import { first, catchError, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from '../services/debug-logger.service';

@Injectable()
export class CharacterEffects {

  private readonly moduleName: string = "CharacterEffect";

  constructor(private actions$: Actions,
    public store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private incrementalService: IncrementalService,
    private debugLogger: DebugLoggerService
  ) { }


  @Effect({ dispatch: false })
  public SelectChatPlayer$: Observable<any> = this.actions$
    .pipe(
      ofType(character.SELECT_PLAYER_ID),
      map((payload: any) => {
        let self = this;
        let userId: string = payload.payload;

        if (userId == fromRoot.getState(this.store).character.myUID) {
          // selecting my sheet
          const myCharacterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);

          //check if i need to download any information
          self.incrementalService.getAdditionalInfoFromSheet(myCharacterSheet);

          self.store.dispatch(new character.SetSelectedPlayer(myCharacterSheet));
        } else {
          // selecting another player sheet
          const selectedCharacterDoc: AngularFirestoreDocument<CharacterSheetData> = this.afs.doc<CharacterSheetData>('users/' + userId);
          const selectedCharacterDocObservable: Observable<CharacterSheetData> = selectedCharacterDoc.valueChanges();
          let characterSheetSubscription = selectedCharacterDocObservable.pipe(first()).subscribe((userData) => {

            // let characterSheetSubscription = this.firestore.read('users/' + userId).skip(1).subscribe((userData) => {
              if (self.debugLogger.isAuditing) {
                self.debugLogger.logRead(false, "READ user chracther effect", self.moduleName, "users", 1);
              }

            characterSheetSubscription.unsubscribe();
            if (Functions.IsNullOrUndefined(userData) == false) {
              //check if i need to download any information
              self.incrementalService.getAdditionalInfoFromSheet(userData);

              self.store.dispatch(new character.SetSelectedPlayer(userData));
            } else {
              self.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: false, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
            }
          });
        }
      }),
      catchError((err: any) => {
        //TODO: gestione errori
        // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
        return of() as Observable<any>;
      }));

}
