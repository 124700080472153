import { RaceIcons } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as characterSelector from './../../../selectors/character.selectors';
import * as datasSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import * as character from './../../../actions/character';

// Utilities
@Component({
  selector: 'uffici-razze-component',
  templateUrl: './uffici-razze.component.html',
  styleUrls: ['./uffici-razze.component.less']
})

export class UfficiRazzeComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiRazzeComponent";

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  public raceIcons$: Observable<RaceIcons[]>;
  public allUsers$: Observable<any>;
  public selectedRace: any;
  public availableRaces: any[] = [
    {
      key: 5,
      name: "Mondani"
    },
    {
      key: 0,
      name: "Nephilim"
    },
    {
      key: 4,
      name: "Leech"
    },
    {
      key: 3,
      name: "Lycan"
    },
    {
      key: 2,
      name: "Sidhe"
    },
    {
      key: 1,
      name: "Warlock"
    },
    {
      key: 6,
      name: "Fantasmi"
    }
  ];

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef
  ) {
    this.allUsers$ = this.store.select(characterSelector.getUsersAnagrafica);
    this.raceIcons$ = this.store.select(datasSelector.getRaceIcons);
  }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiRazzeAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public goToScheda(uid: string) {
    this.store.dispatch(new character.SelectplayerID(uid));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: true, doNotCloseClan: false, doNotCloseCorp: false }));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
