import { CharacterSheetData } from './../models/data/application.data';
import { ChatMessage, CharacterState, OnlineUser, ChatStatus } from './../modules/chatRoom/models/chat-message.models';
import * as chat from '../actions/chat';

export interface State {
  chatMessages: ChatMessage[];
  chatOnlineUsers: OnlineUser[];
  chatCharactersState: CharacterState[];
  chatStatus: ChatStatus;
  seletedChatPlayer: CharacterSheetData;
  selectedChat: string;
  prevChat: string;
  pendingAttacks: ChatMessage[];
  pendingAttackResponse: ChatMessage;
  chatInstruction: any;
}

export const initialState: State = {
  chatMessages: [],
  chatOnlineUsers: [],
  chatCharactersState: [],
  chatStatus: new ChatStatus(),
  seletedChatPlayer: undefined,
  selectedChat: "",
  prevChat: "",
  pendingAttacks: [],
  pendingAttackResponse: undefined,
  chatInstruction: undefined
};

export function reducer(state: State = initialState, action: chat.Actions): State {
  switch (action.type) {

    case chat.STORE_CHAT_MESSAGES:
      const messages: ChatMessage[] = action.payload as ChatMessage[];
      return Object.assign({}, state, {
        chatMessages: messages,
        pendingAttacks: []
      });

    case chat.STORE_NEW_CHAT_MESSAGES:
      const messagesAdded: ChatMessage[] = action.payload as ChatMessage[];
      let updatedMessages: ChatMessage[] = Object.assign([], state.chatMessages);
      updatedMessages = updatedMessages.concat(messagesAdded)
      return Object.assign({}, state, {
        chatMessages: updatedMessages
      });

    case chat.STORE_NEW_CHAT_ATTACKS:
      const attacksAdded: ChatMessage[] = action.payload as ChatMessage[];
      let updatedAttacks: ChatMessage[] = Object.assign([], state.pendingAttacks);
      updatedAttacks = updatedAttacks.concat(attacksAdded)
      return Object.assign({}, state, {
        pendingAttacks: updatedAttacks
      });

    case chat.STORE_RESPONSE_PENDING_ATTACK:
      const responseAttack: ChatMessage = action.payload as ChatMessage;
      return Object.assign({}, state, {
        pendingAttackResponse: responseAttack
      });

    case chat.POP_CHAT_ATTACK:
      let pendAttacks: ChatMessage[] = Object.assign([], state.pendingAttacks);
      pendAttacks.shift();
      return Object.assign({}, state, {
        pendingAttacks: pendAttacks
      });

    case chat.STORE_CHAT_ONLINE_USERS:
      const onUsers: any = action.payload;
      return Object.assign({}, state, {
        chatOnlineUsers: onUsers
      });

    case chat.STORE_CHAT_CHARACTERS_STATE:
      const charactersState: CharacterState[] = action.payload;
      return Object.assign({}, state, {
        chatCharactersState: charactersState
      });

    case chat.STORE_CHAT_STATUS:
      const chatStatus: ChatStatus = action.payload;
      return Object.assign({}, state, {
        chatStatus: chatStatus
      });

    case chat.STORE_SELECTED_CHAT:
      const selectedChatId: string = action.payload as string;
      const prevChat = state.selectedChat;
      return Object.assign({}, state, {
        selectedChat: selectedChatId,
        prevChat: prevChat
      });

    case chat.SET_SELECTED_CHAT_PLAYER:
      const selectedChatPlayerId: CharacterSheetData = action.payload as CharacterSheetData;
      return Object.assign({}, state, {
        seletedChatPlayer: selectedChatPlayerId
      });

    case chat.LOG_OUT_CLEAN:
      return Object.assign({}, state, {
        chatMessages: [],
        chatOnlineUsers: [],
        chatCharactersState: [],
        chatStatus: new ChatStatus(),
        seletedChatPlayer: undefined,
        selectedChat: "",
        prevChat: "",
        pendingAttacks: [],
        pendingAttackResponse: undefined
      });

    case chat.STORE_CHAT_INSTRUCTION:
      const chatInstruction: any = action.payload as any;
      return Object.assign({}, state, {
        chatInstruction: chatInstruction
      });

    default: {
      return state;
    }
  }
}

export const getChatMessages: (state: State) => ChatMessage[] = (state: State) => state.chatMessages;
export const getChatOnlineUsers: (state: State) => OnlineUser[] = (state: State) => state.chatOnlineUsers;
export const getChatCharactersState: (state: State) => CharacterState[] = (state: State) => state.chatCharactersState;
export const getChatStatus: (state: State) => ChatStatus = (state: State) => state.chatStatus;
export const getIsQuestActive: (state: State) => boolean = (state: State) => state.chatStatus.questModeActive;
export const getSelectedChat: (state: State) => string = (state: State) => state.selectedChat;
export const getSelectedChatPlayer: (state: State) => CharacterSheetData = (state: State) => state.seletedChatPlayer;
export const getChatPendingattacks: (state: State) => ChatMessage[] = (state: State) => state.pendingAttacks;
export const getChatInstruction: (state: State) => any = (state: State) => state.chatInstruction;