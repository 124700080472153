import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { OnlineUser } from "../../chatRoom/models/chat-message.models";

@Pipe({ name: 'concPrintPipe' })
export class ConcPrintPipe implements PipeTransform {
  constructor() { }

  public transform(targets: OnlineUser[]) {
    if (Functions.IsNullOrUndefined(targets) || targets.length <= 0)
      return "";
    else {
      let targetsAsString: string = "";
      if (targets.length > 1) {
        targetsAsString = "i cui bersagli sono ";
      } else {
        targetsAsString = "il cui bersaglio è ";
      }
      for (let index: number = 0; index < targets.length; index++) {
        targetsAsString = targetsAsString + targets[index].name;
        if (index + 1 < targets.length) {
          targetsAsString = targetsAsString + ", "
        }
      }
      return targetsAsString;
    }
  }
}

@Pipe({ name: 'dmgPrintPipe' })
export class DmgPrintPipe implements PipeTransform {
  constructor() { }

  public transform(dmgType: string, needCalcDmg: boolean) {

    if (needCalcDmg == false)
      return "";

    if (Functions.IsStringEmpty(dmgType) || dmgType == "-1")
      return "";
    else {
      let stringToReturn: string = "(con richiesta di calcolo del danno di tipo ";
      switch (dmgType) {
        case "0":
          stringToReturn = stringToReturn + " fisico)";
          return stringToReturn;

        case "1":
          stringToReturn = stringToReturn + " mentale)";
          return stringToReturn;

        default:
          return "";
      }
    }
  }
}
