import { MapState, LayoutState, CPanelState } from '../modules/utilities/utilities.constants';
import { BaseAction } from './baseAction';
import { StandardChat, chatGroups, SpecialChat } from '../models/data/application.data';

export const UPDATE_LAYOUT_STATE = "[Layout] Update Layout State";
export const GOTO_HOME_INTERNA = "[Layout] GoTo Home Interna";
export const GOTO_HOME_ESTERNA = "[Layout] GoTo Home Esterna";
export const GOTO_LOGOUT = "[Layout] GoTo Logout";
export const SET_MAP_SELECTED_AREA = "[Layout] Set Map Selected Area";
export const CHANGE_CPANEL_STATE = "[Layout] Change cPanel State";
export const GOTO_C_PANEL = "[Layout] GoTo C Panel";
export const UPDATE_MAP_STATE = "[Layout] Update Map State";
export const TOGGLE_CHARACTER_SHEET = "[Layout] Toggle Character Sheet";
export const TOGGLE_UFFICI = "[Layout] Toggle Uffici";
export const TOGGLE_ANAGRAFE = "[Layout] Toggle Anagrafe";
export const TOGGLE_VOLTI_TYPE = "[Layout] Toggle Volti Type";
export const TOGGLE_CAMBIA_VOLTO = "[Layout] Toggle Cambia Volto";
export const TOGGLE_CAMBIA_NOME = "[Layout] Toggle Cambia Nome";
export const TOGGLE_UFFICI_STAFF = "[Layout] Toggle Uffici Staff";
export const TOGGLE_UFFICI_BANK = "[Layout] Toggle Uffici Bank";
export const TOGGLE_UFFICI_SECRET_CHATS = "[Layout] Toggle Uffici Secret Chats";
export const TOGGLE_UFFICI_CREA_SECRET_CHAT = "[Layout] Toggle Uffici Crea Secret Chat";
export const TOGGLE_UFFICI_MAPPA = "[Layout] Toggle Uffici Mappa";
export const TOGGLE_UFFICI_RAZZE = "[Layout] Toggle Uffici Razze";
export const TOGGLE_UFFICI_CLAN = "[Layout] Toggle Uffici Clam";
export const TOGGLE_UFFICI_CORP = "[Layout] Toggle Uffici Corp";
export const TOGGLE_UFFICI_CREDITS = "[Layout] Toggle Uffici Credits";
export const TOGGLE_PRESENTI = "[Layout] Toggle Presenti";
export const TOGGLE_CHAT_ACTION_BOX = "[Layout] Toggle Chat Action Box";
export const TOGGLE_CHAT_INSTRUCTION_BOX = "[Layout] Toggle Chat Instruction Box";
export const TOGGLE_CHAT_SKILL_BOX = "[Layout] Toggle Chat Skill Box";
export const TOGGLE_CHAT_ITEM_BOX = "[Layout] Toggle Chat Item Box";
export const TOGGLE_CHAT_DICE_BOX = "[Layout] Toggle Chat Dice Box";
export const TOGGLE_CHAT_DICE_RESPONSE_BOX = "[Layout] Toggle Chat Dice Response Box";
export const TOGGLE_CHAT_DICE_DMG_BOX = "[Layout] Toggle Chat Dice Dmg Box";
export const TOGGLE_CHAT_SUSSURRO = "[Layout] Toggle Chat Sussurro";
export const TOGGLE_CHAT_FATO = "[Layout] Toggle Chat Fato";
export const TOGGLE_CHAT_SCHEDA_TATTICA = "[Layout] Toggle Chat Scheda Tattica";
export const STORE_CHATS_LIST_DATA = "[Layout] Store Chats List Data";
export const STORE_SPECIAL_CHATS_LIST_DATA = "[Layout] Store Special Chats List Data";
export const SCROLL_TO_TOP_FLOATING = "[Layout] Scroll to Top Floating";
export const TOGGLE_CREA_MESSAGGIO_BOX = "[Layout] Toggle Crea Messaggio Box";
export const TOGGLE_MESSAGGI_RICEVUTI_BOX = "[Layout] Toggle Messaggi Ricevuti Box";
export const TOGGLE_LEGGI_MESSAGGIO_BOX = "[Layout] Toggle Leggi Messaggio Box";
export const TOGGLE_RULES_BOX = "[Layout] Toggle Rules Box";
export const TOGGLE_GESTISCI_CHAT_PRIVATA = "[Layout] Toggle Gestisci Chat Privata";
export const TOGGLE_LOCATIONS = "[Layout] Toggle Locations";
export const TOGGLE_EVENTS_CALENDAR = "[Layout] Toggle Events Calendar";
export const TOGGLE_METEO_BOX = "[Layout] Toggle Meteo Box";
export const GOTO_R_PANEL = "[Layout] GoTo R Panel";


export type Actions = UpdateLayoutStateAction
  | ChangeCPanelStateAction
  | GoToCPanelAction
  | UpdateMapStateAction
  | GoToHomeInternaAction
  | GoToHomeEsternaAction
  | SetMapSelectedAreaAction
  | ToggleCharacterSheetAction
  | ToggleUfficiAction
  | TogglePresentiAction
  | ToggleChatActionBoxAction
  | ToggleChatInstructionsBoxAction
  | ToggleChatSkillBoxAction
  | ToggleChatItemBoxAction
  | ToggleChatDiceBoxAction
  | ToggleChatDiceResponseBoxAction
  | ToggleChatDiceDmgBoxAction
  | ToggleChatSussurroAction
  | ToggleChatSchedaTatticaAction
  | ToggleChatFatoAction
  | StoreChatsListDataAction
  | ScrollToTopFloatingAction
  | ToggleCreaMessaggioBoxAction
  | ToggleMessaggiRicevutiBoxAction
  | ToggleLeggiMessaggioBoxAction
  | ToggleRulesBoxAction
  | ToggleAnagrafeAction
  | ToggleVoltiTypeAction
  | ToggleCambiaVoltoAction
  | ToggleCambiaNomeAction
  | ToggleUfficiStaffAction
  | ToggleUfficiBankAction
  | ToggleUfficiSecretChatsAction
  | ToggleUfficiCreaSecretChatAction
  | ToggleUfficiMappaAction
  | ToggleGestioneChatPrivataAction
  | ToggleUfficiRazzeAction
  | ToggleUfficiClanAction
  | ToggleUfficiCorpAction
  | ToggleUfficiCreditsAction
  | ToggleLocationsAction
  | ToggleEventsCalendarAction
  | ToggleMeteoBoxAction
  | GoToLogoutAction
  | StoreSpecialChatsListDataAction
  | GoToRPanelAction;

export class UpdateLayoutStateAction implements BaseAction {
  readonly type = UPDATE_LAYOUT_STATE;
  constructor(public payload: LayoutState) { }
}

export class ChangeCPanelStateAction implements BaseAction {
  readonly type = CHANGE_CPANEL_STATE;
  constructor(public payload: CPanelState) { }
}

export class GoToHomeInternaAction implements BaseAction {
  readonly type = GOTO_HOME_INTERNA;
  constructor() { }
}

export class GoToHomeEsternaAction implements BaseAction {
  readonly type = GOTO_HOME_ESTERNA;
  constructor() { }
}

export class GoToLogoutAction implements BaseAction {
  readonly type = GOTO_LOGOUT;
  constructor() { }
}

export class SetMapSelectedAreaAction implements BaseAction {
  readonly type = SET_MAP_SELECTED_AREA;
  constructor(public payload: chatGroups) { }
}

export class GoToCPanelAction implements BaseAction {
  readonly type = GOTO_C_PANEL;
  constructor() { }
}

export class GoToRPanelAction implements BaseAction {
  readonly type = GOTO_R_PANEL;
  constructor() { }
}

export class UpdateMapStateAction implements BaseAction {
  readonly type = UPDATE_MAP_STATE;
  constructor(public payload: MapState) { }
}

export class ToggleCharacterSheetAction implements BaseAction {
  readonly type = TOGGLE_CHARACTER_SHEET;
  constructor(public payload: { newState: boolean, doNotClosePresence: boolean, doNotCloseAnagrafica: boolean, doNotCloseVolti: boolean, doNotCloseStaff: boolean, doNotCloseRazze: boolean, doNotCloseClan: boolean, doNotCloseCorp: boolean }) { }
}

export class ToggleUfficiAction implements BaseAction {
  readonly type = TOGGLE_UFFICI;
  constructor(public payload?: boolean) { }
}

export class ToggleAnagrafeAction implements BaseAction {
  readonly type = TOGGLE_ANAGRAFE;
  constructor(public payload: boolean) { }
}

export class ToggleVoltiTypeAction implements BaseAction {
  readonly type = TOGGLE_VOLTI_TYPE;
  constructor(public payload: boolean) { }
}


export class TogglePresentiAction implements BaseAction {
  readonly type = TOGGLE_PRESENTI;
  constructor(public payload?: boolean) { }
}

export class ToggleChatActionBoxAction implements BaseAction {
  readonly type = TOGGLE_CHAT_ACTION_BOX;
  constructor() { }
}

export class ToggleChatInstructionsBoxAction implements BaseAction {
  readonly type = TOGGLE_CHAT_INSTRUCTION_BOX;
  constructor() { }
}

export class ToggleChatSkillBoxAction implements BaseAction {
  readonly type = TOGGLE_CHAT_SKILL_BOX;
  constructor() { }
}

export class ToggleChatItemBoxAction implements BaseAction {
  readonly type = TOGGLE_CHAT_ITEM_BOX;
  constructor() { }
}

export class ToggleChatDiceBoxAction implements BaseAction {
  readonly type = TOGGLE_CHAT_DICE_BOX;
  constructor() { }
}

export class ToggleChatDiceResponseBoxAction implements BaseAction {
  readonly type = TOGGLE_CHAT_DICE_RESPONSE_BOX;
  constructor(public payload?: boolean) { }
}

export class ToggleChatDiceDmgBoxAction implements BaseAction {
  readonly type = TOGGLE_CHAT_DICE_DMG_BOX;
  constructor(public payload?: boolean) { }
}

export class ToggleChatSussurroAction implements BaseAction {
  readonly type = TOGGLE_CHAT_SUSSURRO;
  constructor(public payload?: boolean) { }
}

export class ToggleChatFatoAction implements BaseAction {
  readonly type = TOGGLE_CHAT_FATO;
  constructor(public payload?: boolean) { }
}

export class ToggleChatSchedaTatticaAction implements BaseAction {
  readonly type = TOGGLE_CHAT_SCHEDA_TATTICA;
  constructor(public payload?: boolean) { }
}

export class StoreChatsListDataAction implements BaseAction {
  readonly type = STORE_CHATS_LIST_DATA;
  constructor(public payload: { standardChats: StandardChat[], ver: number, fullFlag: boolean }) { }
}

export class StoreSpecialChatsListDataAction implements BaseAction {
  readonly type = STORE_SPECIAL_CHATS_LIST_DATA;
  constructor(public payload: { specialChats: SpecialChat[], ver: number, fullFlag: boolean }) { }
}

export class ScrollToTopFloatingAction implements BaseAction {
  readonly type = SCROLL_TO_TOP_FLOATING;
  constructor() { }
}

export class ToggleCreaMessaggioBoxAction implements BaseAction {
  readonly type = TOGGLE_CREA_MESSAGGIO_BOX;
  constructor(public payload?: boolean) { }
}

export class ToggleMessaggiRicevutiBoxAction implements BaseAction {
  readonly type = TOGGLE_MESSAGGI_RICEVUTI_BOX;
  constructor(public payload?: boolean) { }
}

export class ToggleLeggiMessaggioBoxAction implements BaseAction {
  readonly type = TOGGLE_LEGGI_MESSAGGIO_BOX;
  constructor(public payload?: boolean) { }
}

export class ToggleRulesBoxAction implements BaseAction {
  readonly type = TOGGLE_RULES_BOX;
  constructor(public payload?: boolean) { }
}

export class ToggleCambiaVoltoAction implements BaseAction {
  readonly type = TOGGLE_CAMBIA_VOLTO;
  constructor(public payload?: boolean) { }
}

export class ToggleCambiaNomeAction implements BaseAction {
  readonly type = TOGGLE_CAMBIA_NOME;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiStaffAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_STAFF;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiBankAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_BANK;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiSecretChatsAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_SECRET_CHATS;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiCreaSecretChatAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_CREA_SECRET_CHAT;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiMappaAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_MAPPA;
  constructor(public payload?: boolean) { }
}

export class ToggleGestioneChatPrivataAction implements BaseAction {
  readonly type = TOGGLE_GESTISCI_CHAT_PRIVATA;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiRazzeAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_RAZZE;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiClanAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_CLAN;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiCorpAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_CORP;
  constructor(public payload?: boolean) { }
}

export class ToggleUfficiCreditsAction implements BaseAction {
  readonly type = TOGGLE_UFFICI_CREDITS;
  constructor(public payload?: boolean) { }
}

export class ToggleLocationsAction implements BaseAction {
  readonly type = TOGGLE_LOCATIONS;
  constructor(public payload?: boolean) { }
}

export class ToggleEventsCalendarAction implements BaseAction {
  readonly type = TOGGLE_EVENTS_CALENDAR;
  constructor(public payload?: boolean) { }
}

export class ToggleMeteoBoxAction implements BaseAction {
  readonly type = TOGGLE_METEO_BOX;
  constructor(public payload?: boolean) { }
}
