import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { CharacterSheetData, Race, MAXStats } from "../../../models/data/application.data";

@Pipe({ name: 'chekStrWeakPipe' })
export class ChekStrWeakPipe implements PipeTransform {
  private racesMax: Map<Race, MAXStats> = new Map();

  constructor(private store: Store<fromRoot.State>) {
    const mondStats: MAXStats = new MAXStats(20);
    this.racesMax.set(Race.Mondano, mondStats);

    const nephilinStats: MAXStats = new MAXStats(30);
    nephilinStats.wsd = 15;
    this.racesMax.set(Race.Nephilim, nephilinStats);

    const leecStats: MAXStats = new MAXStats(50);
    leecStats.res = 15;
    leecStats.str = 30;
    this.racesMax.set(Race.Leech, leecStats);

    const lycanStats: MAXStats = new MAXStats(50);
    lycanStats.wsd = 15;
    lycanStats.wil = 30;
    this.racesMax.set(Race.Lycan, lycanStats);

    const siDheStats: MAXStats = new MAXStats(50);
    siDheStats.wil = 15;
    siDheStats.per = 30;
    this.racesMax.set(Race.Sidhe, siDheStats);

    const warlockStats: MAXStats = new MAXStats(50);
    warlockStats.res = 15;
    warlockStats.agl = 30;
    this.racesMax.set(Race.Warlock, warlockStats);

    const angeloStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Angelo, angeloStats);

    const demoneStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Demone, demoneStats);

    const dimenticatoStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Dimenticato, dimenticatoStats);

    const fantasmaStats: MAXStats = new MAXStats(90);
    this.racesMax.set(Race.Fantasma, fantasmaStats);

  }



  public transform(stat: string, characterSheet: CharacterSheetData) {

    if (Functions.IsNullOrUndefined(characterSheet) == true || Functions.IsNullOrUndefined(characterSheet.race) == true)
      return false;

    const myRace: Race = characterSheet.race;
    switch (stat) {
      case "str":
        const mySrtValue: number = parseInt(characterSheet.stats.str.toString());
        if (mySrtValue + 1 <= 15)
          return true;

        if (mySrtValue + 1 <= this.racesMax.get(myRace).str)
          return true
        else
          return false;

      case "agl":
        const myAglValue: number = parseInt(characterSheet.stats.agl.toString());
        if (myAglValue + 1 <= 15)
          return true;

        if (myAglValue + 1 <= this.racesMax.get(myRace).agl)
          return true
        else
          return false;


      case "res":
        const myResValue: number = parseInt(characterSheet.stats.res.toString());
        if (myResValue + 1 <= 15)
          return true;

        if (myResValue + 1 <= this.racesMax.get(myRace).res)
          return true
        else
          return false;

      case "wsd":
        const myWsdValue: number = parseInt(characterSheet.stats.wsd.toString());
        if (myWsdValue + 1 <= 15)
          return true;

        if (myWsdValue + 1 <= this.racesMax.get(myRace).wsd)
          return true
        else
          return false;

      case "per":
        const myPerValue: number = parseInt(characterSheet.stats.per.toString());
        if (myPerValue + 1 <= 15)
          return true;

        if (myPerValue + 1 <= this.racesMax.get(myRace).per)
          return true
        else
          return false;

      case "wil":
        const myWilValue: number = parseInt(characterSheet.stats.wil.toString());
        if (myWilValue + 1 <= 15)
          return true;

        if (myWilValue + 1 <= this.racesMax.get(myRace).wil)
          return true
        else
          return false;

      default:
        return false;
    }
  }
}