import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Stats, Race, Clan, Corp, UserPresence, PresencePlayerStatus, State } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-edit-personaggio',
  templateUrl: 'cPanel-edit-personaggio.component.html',
  styleUrls: ['cPanel-edit-personaggio.component.less'],
})
export class CPanelEditPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelEditPersonaggio";

  //#region - select variables
  public selectedCharacterID: string;
  public selectedCharacter: CharacterSheetData;

  public usersPresence$: Observable<UserPresence[]>;
  private usersSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  //#endregion - select variables

  //#region - form variables
  private playerToEditDoc: AngularFirestoreDocument<CharacterSheetData>;
  private playerToEditDocObservable: Observable<CharacterSheetData>;
  private playerToEditDocSubscription: Subscription;
  // private tempSubscription: Subscription = new Subscription();
  public pgForm = new FormGroup({});
  //#endregion - form variables

  public changingMusic: boolean = false;
  public musicFileToUpload: File;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    public cPanelService: CPanelService,
    private afs: AngularFirestore,
    private afdb: AngularFireDatabase,
    private debugLogger: DebugLoggerService,
    private storage: AngularFireStorage
  ) { }


  public ngOnInit() {
    let self: this = this;
    this.buildForm();

    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }))
      .subscribe((data: PresencePlayerStatus[]) => {
        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.characters = data;
      });

  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
      this.usersSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.playerToEditDocSubscription) == false) {
      this.playerToEditDocSubscription.unsubscribe();
    }
  }

  /**
   * player selectio cnahged
   */
  public onUserChange(character: PresencePlayerStatus) {
    const self: this = this;
    if (Functions.IsNullOrUndefined(this.playerToEditDocSubscription) == false) {
      this.playerToEditDocSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(character) == true) {
      self.buildForm();
      return;
    }

    this.playerToEditDoc = this.afs.doc<any>('users/' + character.playerUID);
    this.playerToEditDocObservable = this.playerToEditDoc.valueChanges();
    this.playerToEditDocSubscription = this.playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
        }

        self.selectedCharacter = selectedCharacter;
        self.selectedCharacter.access = character.lastAccess;
        if (self.selectedCharacter != undefined)
          self.setUpForm();
        else
          self.buildForm();
      });
  }

  /**
   * save changes
   */
  public ok() {
    if (this.pgForm.valid == false) {
      Object.keys(this.pgForm.controls).forEach(field => {
        const control = this.pgForm.get(field);
        if (control.valid == false) {
          control.markAsDirty({ onlySelf: true });
        }
      });
    } else {

      this.cPanelService.waiting = true;

      let self: this = this;
      const oldPlayerName: string = this.selectedCharacter.name;

      let characterData: CharacterSheetData = this.updateNewCharacter();
      let characterDataJSON = JSON.parse(JSON.stringify(characterData));
      this.playerToEditDoc.update(characterDataJSON)
        .then(() => {
          //#region - update name list if needed
          if (oldPlayerName != characterData.name) {
            //il nome è stato cambiato quindi devo sistemare la lista dei nomi disponibili
            const nameToRemove = self.afs.doc<any>('listOfUserNames/' + (self.selectedCharacter.name as string).toLowerCase());
            nameToRemove.delete();

            const nameCollection = self.afs.collection<any>('listOfUserNames');
            nameCollection.doc((characterData.name as string).toLowerCase()).set({});
          }
          //#endregion - update name list if needed

          //#region - update presence status
          const oldPresenceStatus: PresencePlayerStatus = self.characters.find((aCharacterStatus: PresencePlayerStatus) => aCharacterStatus.playerUID == characterData.uid);
          let lastAccess: string = Functions.GetCurrentDate();
          let ip: string = "";
          if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
            lastAccess = (oldPresenceStatus.lastAccess || Functions.GetCurrentDate());
          }
          if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
            ip = (oldPresenceStatus.ip || "");
          }
          const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterData.uid, characterData.race, characterData.role, characterData.MAvatar, characterData.name, characterData.sur, characterData.nick, lastAccess, characterData.sex, (characterData.lvl || 1), (characterData.state || State.libero), (characterData.stateText || ""), characterData.isPng, characterData.isBanned, characterData.lastMessageSent, characterData.myPrivateChat, (characterData.clan || ""), characterData.clanLvl, (characterData.corp || ""), characterData.corpLvl, characterData.isActive, characterData.isCapoClan, characterData.isCapoCorp, characterData.isCapoRace, ip);
          const presenceStatusJSON = JSON.parse(JSON.stringify(presenceSatus));

          const userPresenceDoc = self.afdb.object('status/' + characterData.uid + '/state');
          userPresenceDoc.update(presenceStatusJSON);
          //#endregion - update presence status

          self.selectedCharacter = undefined;
          self.selectedCharacterID = undefined;
          self.buildForm();
          window.scrollTo(0, 0);
          self.toastr.success('Modifica Salvata!');
          self.cPanelService.waiting = false;
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.write('users/' + this.selectedCharacter.uid, characterDataJSON).subscribe(() => {
      //   if (self.selectedCharacter.name != characterData.name) {
      //     //il nome è stato cambiato quindi devo sistemare la lista dei nomi disponibili
      //     this.firestore.remove('listOfUserNames/' + (self.selectedCharacter.name as string).toLowerCase());
      //     this.firestore.write('listOfUserNames/' + (characterData.name as string).toLowerCase(), {});

      //     // this.firestore.remove('listOfUser/' + this.selectedCharacter.uid);
      //     // this.firestore.write('listOfUser/' + this.selectedCharacter.uid, { name: characterData.name, uid: characterData.uid });
      //   }
      //   this.presenceService.updateAUserInfos(characterData, true);

      //   self.selectedCharacter = undefined;
      //   self.selectedCharacterID = undefined;
      //   self.buildForm();
      //   window.scrollTo(0, 0);
      //   self.toastr.success('Modifica Salvata!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
  }

  /**
   * undo changes
   */
  public annulla() {
    this.selectedCharacter = undefined;
    this.selectedCharacterID = undefined;
    this.buildForm();
    window.scrollTo(0, 0);
  }

  /**
   * utility methods, update character with changes
   */
  private updateNewCharacter(): CharacterSheetData {

    // let characterData: CharacterSheetData = new CharacterSheetData();
    let characterData: CharacterSheetData = Object.assign({}, this.selectedCharacter);
    // characterData.uid = this.selectedCharacter.uid;
    // characterData.mail = this.selectedCharacter.mail;
    characterData.isActive = this.pgForm.value['isActive'];
    characterData.inited = this.pgForm.value['inited'];
    characterData.isBanned = this.pgForm.value['isBanned'];
    characterData.isPng = this.pgForm.value['isPng'];

    if (Functions.IsStringEmpty(this.selectedCharacter.clan) == false) {
      characterData.isCapoClan = this.pgForm.value['isCapoClan'];
    } else {
      characterData.isCapoClan = this.selectedCharacter.isCapoClan;
    }

    if (Functions.IsStringEmpty(this.selectedCharacter.corp) == false) {
      characterData.isCapoCorp = this.pgForm.value['isCapoCorp'];
    } else {
      characterData.isCapoCorp = this.selectedCharacter.isCapoCorp;
    }

    characterData.isCapoRace = this.pgForm.value['isCapoRace'];
    characterData.name = this.pgForm.value['nome'];
    characterData.sur = this.pgForm.value['cognome'];
    characterData.nick = this.pgForm.value['nick'];
    characterData.sex = parseInt(this.pgForm.value['sesso']);
    // characterData.race = parseInt(this.pgForm.value['razza']);
    characterData.role = parseInt(this.pgForm.value['ruolo']);

    // if (characterData.race != Race.Mondano) {
    //   characterData.lvl = parseInt(this.pgForm.value['lvl']);
    // } else {
    //   characterData.lvl = 1;
    // }
    characterData.nature = this.pgForm.value['natura'];
    characterData.allinment = this.pgForm.value['allineamento'];
    characterData.marStatus = this.pgForm.value['marStatus'];
    characterData.age = this.pgForm.value['age'];
    characterData.realAge = this.pgForm.value['realAge'];
    characterData.eyes = this.pgForm.value['eyes'];
    characterData.weight = this.pgForm.value['peso'];
    characterData.height = this.pgForm.value['altezza'];
    characterData.hair = this.pgForm.value['hair'];
    characterData.job = this.pgForm.value['job'];
    // characterData.clan = this.pgForm.value['clan'];
    // characterData.corp = this.pgForm.value['corporazione'];
    // characterData.offRole = this.pgForm.value['offRole'];

    //#region - compleanno
    let computedgcomp: string;
    let computedMcomp: string;
    let computedAcomp: string;
    if (Functions.IsNullOrUndefined(this.pgForm.value['gcomp']) || this.pgForm.value['gcomp'] == "null") {
      computedgcomp = "";
    } else {
      computedgcomp = this.pgForm.value['gcomp'].toString();
      if (computedgcomp.length == 1) {
        computedgcomp = "0" + computedgcomp;
      }
    }
    if (Functions.IsNullOrUndefined(this.pgForm.value['mcomp']) || this.pgForm.value['mcomp'] == "null") {
      computedMcomp = "";
    } else {
      computedMcomp = this.pgForm.value['mcomp'].toString();
      if (computedMcomp.length == 1) {
        computedMcomp = "0" + computedMcomp;
      }
    }
    if (Functions.IsNullOrUndefined(this.pgForm.value['acomp']) || this.pgForm.value['acomp'] == "null") {
      computedAcomp = "";
    } else {
      computedAcomp = this.pgForm.value['acomp'].toString();
    }

    characterData.birthdate = computedgcomp + "/" + computedMcomp + "/" + computedAcomp;
    //#endregion

    if (characterData.inited) {
      characterData.leftPoint = 0;
    }

    characterData.signDate = this.selectedCharacter.signDate;
    characterData.access = this.selectedCharacter.access;

    characterData.HAvatar = this.pgForm.value['HAvatar'];
    characterData.VAvatar = this.pgForm.value['VAvatar'];
    characterData.MAvatar = this.pgForm.value['MAvatar'];
    characterData.TAvatar = this.pgForm.value['TAvatar'];
    characterData.music = this.pgForm.value['musica'];
    characterData.health = this.pgForm.value['health'];
    characterData.mindHealth = this.pgForm.value['mindHealth'];
    characterData.stasBought = this.pgForm.value['stasBought'];

    characterData.px = this.selectedCharacter.px; //this.pgForm.value['px'];
    characterData.usablePx = this.selectedCharacter.usablePx; //this.pgForm.value['usablePx'];
    characterData.DMNote = this.pgForm.value['DMNote'];

    // characterData.questionObject = this.pgForm.value['questionObject'];

    characterData.jobMoney = parseInt(this.pgForm.value['jobMoney']);

    let stats: Stats = new Stats();
    stats.str = parseInt(this.pgForm.value['str']);
    stats.agl = parseInt(this.pgForm.value['agl']);
    stats.res = parseInt(this.pgForm.value['res']);
    stats.wsd = parseInt(this.pgForm.value['wsd']);
    stats.per = parseInt(this.pgForm.value['per']);
    stats.wil = parseInt(this.pgForm.value['wil']);
    characterData.stats = stats;

    characterData.backg = this.pgForm.value['bck'];
    return characterData;
  }

  // used to create form structure
  private buildForm() {
    this.pgForm = this.fb.group({
      'jobMoney': [''],
      'password': [{ value: '', disabled: true }],
      'isActive': [false],
      'inited': [false],
      'isBanned': [false],
      'isPng': [false],
      'isCapoClan': [false],
      'isCapoCorp': [false],
      'isCapoRace': [[]],
      'nome': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'cognome': ['', [
        // Validators.required,
        // Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'nick': ['', [
        Validators.maxLength(30)
      ]],
      'sesso': [undefined, [
        Validators.required,
        Validators.min(0),
        Validators.max(2)
      ]],
      'razza': ['5', [
        Validators.required,
        Validators.min(0),
        Validators.max(9),
      ]],
      'ruolo': ['4', [
        Validators.required,
        Validators.min(0),
        Validators.max(4),
      ]],
      'natura': [''],
      'allineamento': [''],
      'marStatus': [''],
      'age': [''],
      'realAge': [''],
      'gcomp': [''],
      'mcomp': [''],
      'acomp': [''],
      'eyes': [''],
      'peso': [''],
      'altezza': [''],
      'hair': [''],
      'job': [''],
      'clan': [''],
      'corporazione': [''],
      'offRole': [''],
      'signDate': [{ value: Functions.GetCurrentDate(), disabled: true }, [
        Validators.required
      ]],
      'access': [{ value: '', disabled: true }],
      'HAvatar': [''],
      'VAvatar': [''],
      'MAvatar': [''],
      'TAvatar': [''],
      'health': ['100', [
        Validators.required
      ]],
      'mindHealth': ['100', [
        Validators.required
      ]],
      'stasBought': ['0'],
      'px': [''],
      'usablePx': [''],
      'DMNote': [''],
      // 'questionObject': [''],
      'str': ['10', [
        Validators.required
      ]],
      'agl': ['10', [
        Validators.required
      ]],
      'res': ['10', [
        Validators.required
      ]],
      'wsd': ['10', [
        Validators.required
      ]],
      'per': ['10', [
        Validators.required
      ]],
      'wil': ['10', [
        Validators.required
      ]],
      'bck': [''],
    });
  }

  // used to create form structure
  private setUpForm() {

    let gcomp: string = "";
    let mcomp: string = "";
    let acomp: string = "";
    const birtdate: string = this.selectedCharacter.birthdate;
    if (Functions.IsStringEmpty(birtdate) == false) {
      let splitted: string[] = birtdate.split("/");
      gcomp = splitted[0];
      mcomp = splitted[1];
      acomp = splitted[2];
    }

    let evaluatedClan: string = "";
    if (Functions.IsStringEmpty(this.selectedCharacter.clan) == false) {
      const allClans: Clan[] = fromRoot.getState(this.store).datas.clansList;
      const foundedClan: Clan = allClans.find((aClan: Clan) => aClan.uid == this.selectedCharacter.clan);
      if (Functions.IsNullOrUndefined(foundedClan) == false)
        evaluatedClan = foundedClan.name;
    }

    let evaluatedCorp: string = "";
    if (Functions.IsStringEmpty(this.selectedCharacter.clan) == false) {
      const allCorps: Corp[] = fromRoot.getState(this.store).datas.corpsList;
      const foundedCorp: Corp = allCorps.find((aCorp: Corp) => aCorp.uid == this.selectedCharacter.corp);
      if (Functions.IsNullOrUndefined(foundedCorp) == false)
        evaluatedCorp = foundedCorp.name;
    }

    let evaluatedRace: string = "";
    let race: Race = this.selectedCharacter.race;
    if (Functions.IsNullOrUndefined(race) == true) {
      race = Race.Mondano;
    }
    evaluatedRace = Race[race];


    this.pgForm = this.fb.group({
      'jobMoney': [this.selectedCharacter.jobMoney],
      'lvl': [{ value: (this.selectedCharacter.lvl || 1), disabled: true }],
      'password': [{ value: '', disabled: true }],
      'isActive': [this.selectedCharacter.isActive],
      'inited': [this.selectedCharacter.inited],
      'isBanned': [this.selectedCharacter.isBanned],
      'isPng': [this.selectedCharacter.isPng || false],
      'isCapoClan': [{ value: this.selectedCharacter.isCapoClan || false, disabled: Functions.IsStringEmpty(this.selectedCharacter.clan) }],
      'isCapoCorp': [{ value: this.selectedCharacter.isCapoCorp || false, disabled: Functions.IsStringEmpty(this.selectedCharacter.corp) }],
      'isCapoRace': [this.selectedCharacter.isCapoRace || []],
      'nome': [this.selectedCharacter.name, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'cognome': [this.selectedCharacter.sur, [
        // Validators.required,
        // Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'nick': [this.selectedCharacter.nick, [
        Validators.maxLength(30)
      ]],
      'sesso': [this.selectedCharacter.sex.toString(), [
        Validators.required,
        Validators.min(0),
        Validators.max(1)
      ]],
      'razza': [{ value: evaluatedRace, disabled: true }],
      'ruolo': [this.selectedCharacter.role.toString(), [
        Validators.required,
        Validators.min(0),
        Validators.max(4),
      ]],
      'natura': [this.selectedCharacter.nature],
      'allineamento': [this.selectedCharacter.allinment],
      'marStatus': [this.selectedCharacter.marStatus],
      'age': [this.selectedCharacter.age],
      'realAge': [this.selectedCharacter.realAge],
      'gcomp': [gcomp],
      'mcomp': [mcomp],
      'acomp': [acomp],
      'eyes': [this.selectedCharacter.eyes],
      'peso': [this.selectedCharacter.weight],
      'altezza': [this.selectedCharacter.height],
      'hair': [this.selectedCharacter.hair],
      'job': [this.selectedCharacter.job],
      'clan': [{ value: evaluatedClan, disabled: true }],
      'corporazione': [{ value: evaluatedCorp, disabled: true }],
      'offRole': [{ value: "", disabled: true }],
      'signDate': [{ value: this.selectedCharacter.signDate, disabled: true }, [
        Validators.required
      ]],
      'access': [{ value: this.selectedCharacter.access, disabled: true }],
      'HAvatar': [this.selectedCharacter.HAvatar],
      'VAvatar': [this.selectedCharacter.VAvatar],
      'MAvatar': [this.selectedCharacter.MAvatar],
      'TAvatar': [this.selectedCharacter.TAvatar],
      'musica': [this.selectedCharacter.music || ""],
      'health': [this.selectedCharacter.health, [
        Validators.required
      ]],
      'mindHealth': [this.selectedCharacter.mindHealth, [
        Validators.required
      ]],
      'stasBought': [this.selectedCharacter.stasBought],
      'px': [{ value: this.selectedCharacter.px, disabled: true }],
      'usablePx': [{ value: this.selectedCharacter.usablePx, disabled: true }],
      'DMNote': [this.selectedCharacter.DMNote || ""],
      // 'questionObject': [this.selectedCharacter.questionObject],
      'str': [this.selectedCharacter.stats.str, [
        Validators.required
      ]],
      'agl': [this.selectedCharacter.stats.agl, [
        Validators.required
      ]],
      'res': [this.selectedCharacter.stats.res, [
        Validators.required
      ]],
      'wsd': [this.selectedCharacter.stats.wsd, [
        Validators.required
      ]],
      'per': [this.selectedCharacter.stats.per, [
        Validators.required
      ]],
      'wil': [this.selectedCharacter.stats.wil, [
        Validators.required
      ]],
      'bck': [this.selectedCharacter.backg],
    });
  }

  public handleFileInput(files: FileList) {
    this.musicFileToUpload = files.item(0);
  }

  public confirmUpload() {
    if (Functions.IsNullOrUndefined(this.musicFileToUpload) == true)
      return;

    //#region - check if format and size is ok
    const fileSize = this.musicFileToUpload.size / 1024 / 1024; // in MB
    if (fileSize > 15) {
      alert("Il file eccede il limite consentito (15 MB), scegliere un file meno pesante.");
      return;
    }

    if (this.musicFileToUpload.type.includes("audio") == false) {
      alert("Il formato del file non è valido, scegliere un file diverso.");
      return;
    }
    //#endregion - check if format and size is ok

    const self: this = this;
    this.cPanelService.waiting = true;
    const filePath: string = '/music/' + this.selectedCharacter.uid;
    const fileRef = this.storage.ref(filePath);
    const metadata: firebase.storage.UploadMetadata = {
      cacheControl: 'max-age=172800'
    }

    const uploadTask = fileRef.put(this.musicFileToUpload, metadata)
      .then((value: any) => {
        const subscription = fileRef.getDownloadURL()
          .pipe(first())
          .subscribe((value) => {
            self.pgForm.patchValue({ musica: value });
            self.changingMusic = false;
            self.musicFileToUpload = undefined;
            setTimeout(() => {
              self.cPanelService.waiting = false;
            }, 2000);
          });
      });
  }

}
