import { CharacterSheetData } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import * as characterSelector from './../../../selectors/character.selectors';
import * as layout from './../../../actions/layout';
import * as chat from './../../../actions/chat';
import * as character from './../../../actions/character';
import { MapState, LayoutState } from '../../../modules/utilities/utilities.constants';
import { AuthenticationService } from '../../../services/authentication.service';
import { Role } from '../../../models/data/application.data';

// Utilities
@Component({
  selector: 'uffici-component',
  templateUrl: './uffici.component.html',
  styleUrls: ['./uffici.component.less']
})

export class UfficiComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiComponent";

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(private store: Store<fromRoot.State>, private cdr: ChangeDetectorRef) {

  }

  public ngOnInit() { }

  public ngAfterViewInit() {
    this.onResize();
  }

  backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiAction(false));
  }

  goToAnagrafe() {
    this.store.dispatch(new layout.ToggleAnagrafeAction(true));
  }

  goToVolti() {
    this.store.dispatch(new layout.ToggleVoltiTypeAction(true));
  }

  goToCambiaVolto() {
    this.store.dispatch(new layout.ToggleCambiaVoltoAction(true));
  }

  goToCambiaNome() {
    this.store.dispatch(new layout.ToggleCambiaNomeAction(true));
  }

  goToStaff() {
    this.store.dispatch(new layout.ToggleUfficiStaffAction(true));
  }

  openBank() {
    this.store.dispatch(new layout.ToggleUfficiBankAction(true));
  }

  openSecretChats() {
    this.store.dispatch(new layout.ToggleUfficiSecretChatsAction(true));
  }

  openMappe() {
    this.store.dispatch(new layout.ToggleUfficiMappaAction(true));
  }

  goToRace() {
    this.store.dispatch(new layout.ToggleUfficiRazzeAction(true));
  }

  goToClan() {
    this.store.dispatch(new layout.ToggleUfficiClanAction(true));
  }

  goToCorp() {
    this.store.dispatch(new layout.ToggleUfficiCorpAction(true));
  }

  goToCredits() {
    this.store.dispatch(new layout.ToggleUfficiCreditsAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
