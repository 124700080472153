import { LayoutState } from '../modules/utilities/utilities.constants';
import { LogPX, LogOgg, LogBank, CharacterSheetData } from '../models/data/application.data';
import { Functions } from '../modules/utilities/functions/utilities.functions';

import * as fromRoot from '../reducers';
import { Store } from '@ngrx/store';

import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

@Injectable()
export class DebugLoggerService {

  public isAuditing: boolean = false;
  private debugLogger: AngularFireList<any>;

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<fromRoot.State>
  ) {
    this.debugLogger = this.afdb.list('debugLog');
  }


  public logRead(isCP: boolean, message: string, component: string, collection: string, number: number) {
    const logID: string = Functions.CreateGuid();
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;

    if (Functions.IsNullOrUndefined(myCharacterSheet) == true) {
      return;
    }

    const logEntry: any = {
      userName: myCharacterSheet.name || "",
      userID: myCharacterSheet.uid || "",
      isCP: isCP || false,
      message: message || "",
      component: component || "",
      collection: collection || "",
      number: number || 0
    };
    this.debugLogger.set(logID, logEntry);
    // console.log(logEntry);
  }

}
