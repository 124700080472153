import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import * as layout from './../../../actions/layout';
import { LayoutState } from '../../../modules/utilities/utilities.constants';
import { AuthenticationService } from '../../../services/authentication.service';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';

@Component({
  selector: 'home-esterna-component',
  templateUrl: './home-esterna.component.html',
  styleUrls: ['./home-esterna.component.less']
})

export class HomeEsternaComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly moduleName: string = "LoadingComponent";
  public layoutUIState$: Observable<LayoutState>;
  public regolamentoEsternoOpen: boolean = false;
  public recuperoPasswordOpen: boolean = false;
  public creditsOpen: boolean = false;

  homeEsternaMaxWidth: number = 0;
  homeEsternaHeight: number = 0;
  homeEsternaPaddingTop: number = 0;
  @ViewChild('homeEsterna', { static: false }) private homeEsternaElem: ElementRef;

  subscription: Subscription;

  constructor(private store: Store<fromRoot.State>,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private firestoreService: FirestoreService
  ) {
    // this.preloadMainImages();
  }

  public ngOnInit() {
    this.layoutUIState$ = this.store.select(layoutSelector.getLayoutUIState);

    // this.firestoreService.getRules();
    // this.subscription = this.layoutUIState$.subscribe((asd) => {
    //   console.log(asd);
    // })
  }

  public ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

  public ngAfterViewInit() {
    setTimeout((that: this) => {
      that.onResize();
    }, 0, this);
  }

  public iscriviti() {
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Signup));
  }

  public effettuaLogin() {
    this.authenticationService.loginOrProceed();
    // this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Login));
  }

  public toggleMenuEsterno() {
    this.regolamentoEsternoOpen = !this.regolamentoEsternoOpen;
    if (this.regolamentoEsternoOpen) {
      this.firestoreService.getRules();
    }
  }

  public toggleRecPassword() {
    this.recuperoPasswordOpen = !this.recuperoPasswordOpen;
  }

  public toggleCredits() {
    this.creditsOpen = !this.creditsOpen;
  }

  public onResize(event?: any) {
    let windowsHeight = window.innerHeight;
    let windowsWidth = window.innerWidth;

    // let height: number = this.mainMapContainer.nativeElement.offsetHeight;
    let height: number = (windowsWidth / 100) * 60;
    // let width: number = this.mainMapContainer.nativeElement.offsetWidth;
    let parentHeight: number = this.homeEsternaElem.nativeElement.parentElement.offsetHeight;
    let parentPadding = (parentHeight / 100) * 14;
    parentHeight = parentHeight - parentPadding;

    if (height > parentHeight)
      height = parentHeight;

    if (height > 500)
      height = 500;

    if (height < 200)
      height = 200;

    let maxWidth = height * 1.6;
    if (maxWidth > 800)
      maxWidth = 800;

    this.homeEsternaMaxWidth = maxWidth;
    this.homeEsternaHeight = height;
    this.homeEsternaPaddingTop = parentPadding;
    this.cdr.detectChanges();
  }

}
