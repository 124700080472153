import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BackupFileService {

  constructor(
    private httpClient: HttpClient
  ) {

  }

  public getBackupFile(fileName: string): Observable<any> {
    const jsonUrl: string = "https://firebasestorage.googleapis.com/v0/b/unveiled-gdr.appspot.com/o/" + fileName + "?alt=media";
    return this.httpClient.get<any>(jsonUrl).pipe(map((response: any) => {
      return response;
    }));
  }

}
