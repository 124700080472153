import { createSelector } from 'reselect';
import { State } from '../reducers/index';
import * as fromDatas from '../reducers/datas';
import { LocationRoot, LocationArea, chatGroups, LocationChat, uiLocation } from '../models/data/application.data';
import { MapState } from '../modules/utilities/utilities.constants';
import * as layoutSelector from './layout.selectors';
import * as chatSelector from './chat.selectors';
import { Functions } from '../modules/utilities/functions/utilities.functions';

/**
 * Layout
 */
export const ModuleName: string = "DatasSelector";
export const getDatasState: (state: State) => fromDatas.State = (state: State) => state.datas;
export const getSkills: any = createSelector(getDatasState, fromDatas.getSkills);
export const getItems: any = createSelector(getDatasState, fromDatas.getItems);
export const getBMs: any = createSelector(getDatasState, fromDatas.getBMs);
export const getPendingCPUpdates: any = createSelector(getDatasState, fromDatas.getPendingCPUpdates);
export const getUsersList: any = createSelector(getDatasState, fromDatas.getUsersList);
export const getRulesList: any = createSelector(getDatasState, fromDatas.getRulesList);
export const getVoltiList: any = createSelector(getDatasState, fromDatas.getVoltiList);
export const getClansList: any = createSelector(getDatasState, fromDatas.getClansList);
export const getCorpsList: any = createSelector(getDatasState, fromDatas.getCorpsList);
export const getStaffNews: any = createSelector(getDatasState, fromDatas.getStaffNews);
export const getEvents: any = createSelector(getDatasState, fromDatas.getEvents);
export const getMeteo: any = createSelector(getDatasState, fromDatas.getMeteo);
export const getMeteoNote: any = createSelector(getDatasState, fromDatas.getMeteoNote);
export const getCitazioni: any = createSelector(getDatasState, fromDatas.getCitazioni);
export const getLocations: any = createSelector(getDatasState, fromDatas.getLocations);
export const getFirstEntranceMsg: any = createSelector(getDatasState, fromDatas.getFirstEntranceMsg);
export const getRaceIcons: any = createSelector(getDatasState, fromDatas.getRaceIcons);

// export const getComputedLocation: any = createSelector(getLocations, layoutSelector.getMapUIState, layoutSelector.getMapSelectedArea, chatSelector.getSelectedChat,
//   (rootLocation: LocationRoot, mapState: MapState, mapSelectedArea: chatGroups, selectedChat: string) => {

//     if (Functions.IsNullOrUndefined(rootLocation))
//       return [];

//     const allLocations: LocationArea[] = rootLocation.locationsAreas;


//     if (Functions.IsStringEmpty(selectedChat) == true && Functions.IsNullOrUndefined(mapSelectedArea) == true) {
//       // not in a chat
//       switch (mapState) {
//         case MapState.mainMap:
//           const losAngeles: LocationArea = allLocations.find((anArea: LocationArea) => anArea.numericID == -1);
//           return [new uiLocation(losAngeles.name, 0, losAngeles.text)];

//         case MapState.eastLA:
//           let eastLA: LocationArea[] = allLocations.filter((anArea: LocationArea) => anArea.numericID >= 4 && anArea.numericID <= 7);
//           eastLA = eastLA.sort((a: LocationArea, b: LocationArea) => {
//             if (a.sequence < b.sequence)
//               return 1;
//             if (a.sequence > b.sequence)
//               return -1;
//             return 0;
//           })
//           return eastLA;

//         case MapState.westLA:
//           let westLA: LocationArea[] = allLocations.filter((anArea: LocationArea) => anArea.numericID >= 0 && anArea.numericID <= 3);
//           westLA = eastLA.sort((a: LocationArea, b: LocationArea) => {
//             if (a.sequence < b.sequence)
//               return 1;
//             if (a.sequence > b.sequence)
//               return -1;
//             return 0;
//           })
//           return westLA;

//       }
//     } else if (Functions.IsStringEmpty(selectedChat) == true && Functions.IsNullOrUndefined(mapSelectedArea) == false) {
//       // in a chat area
//       const selectedArea: LocationArea = allLocations.find((anArea: LocationArea) => anArea.numericID == mapSelectedArea);
//       if (Functions.IsNullOrUndefined(selectedArea))
//         return [];

//       const valueToReturn: uiLocation[] = Functions.normalizeSelectedAreaLocations(selectedArea, selectedArea.subLocations);
//       return valueToReturn;

//     } else if (Functions.IsStringEmpty(selectedChat) == false) {
//       // in a chat
//       const selectedArea: LocationArea = allLocations.find((anArea: LocationArea) => anArea.numericID == mapSelectedArea);
//       if (Functions.IsNullOrUndefined(selectedArea))
//         return [];

//       const selectedChatArea: LocationChat = selectedArea.subLocations.find((aSubLocation: LocationChat) => aSubLocation.chatUid == selectedChat);
//       if (Functions.IsNullOrUndefined(selectedChatArea))
//         return [];

//       const valueToReturn: any[] = Functions.normalizeSelectedChatLocations(selectedChatArea, selectedChatArea.chatInsideLocations);
//       return valueToReturn;
//     }

//     return [];
//   });
