import { CharacterSheetData } from './../../models/data/application.data';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Functions } from '../../modules/utilities/functions/utilities.functions';
import { Observable, of } from 'rxjs';
import { Role } from '../../models/data/application.data';
import { Location } from '@angular/common';
import { FirestoreService } from '../firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { DebugLoggerService } from '../debug-logger.service';

@Injectable()
export class AdminGuardService implements CanActivate {

  private moduleName: string = "adminGuard";

  constructor(
    private afAuth: AngularFireAuth,
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    public routerService: Router,
    public location: Location,
    public firestoreService: FirestoreService,
    private debugLogger: DebugLoggerService
  ) { }

  public canActivate(): Observable<boolean> {
    let self = this;

    return this.afAuth.authState.pipe(switchMap((userAuth: User) => {
      const uid: string = userAuth.uid;
      // return this.auth.uid().switchMap((uid: string) => {
      if (Functions.IsStringEmpty(uid) == false) {
        const characterSheetDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
        const characterSheetDocObservable = characterSheetDoc.valueChanges();
        return characterSheetDocObservable.pipe(map((aSheet: CharacterSheetData) => {
          // return this.firestore.read('users/' + uid).skip(1).map((aSheet: CharacterSheetData) => {

          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(false, "READ user admin guard", self.moduleName, "users", 1);
          }

          if (aSheet.role != Role.player) {
            return true;
          } else {
            return false;
          }
        }));
      } else {
        return of(false);
      }
    }));

    // return this.auth.uid().map((uid: string) => {
    //   if (Functions.IsStringEmpty(uid) == false) {

    //     // return this.firestore.read('users/' + uid).skip(1).map((userData) => {
    //     //   return true;
    //     // });

    //     this.firestore.read('users/' + uid).skip(1).map(() => {
    //       return true;
    //     });

    //   } else {
    //     return false;
    //   }
    // });

    // return this.auth.Access.pipe(map(res => {
    //   if (!res) { this.router.navigate(['/noaccess']); }
    //   return !!res;
    // }));


    // let store: State = fromRoot.getState(this.store).character;
    // // return Observable.of(true);
    // if (store.isAuthenticated && Functions.IsNullOrUndefined(store.myUID) == false) {
    //   if (store.myCharacterData.role != Role.player) {
    //     return Observable.of(true);
    //   }
    // }
    // return Observable.of(false);
  }
}
