import { CharacterSheetData } from './../../../../models/data/application.data';
import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { ChatMessage, OnlineUser, ChatSelectStatsUI, CharacterState, DiceType, DiceDmgData } from '../../models/chat-message.models';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as chatSelector from './../../../../selectors/chat.selectors';
import * as layoutSelector from './../../../../selectors/layout.selectors';
import * as layout from './../../../../actions/layout';
import { Functions } from '../../../utilities/functions/utilities.functions';
import { map } from 'rxjs/operators';


@Component({
  selector: 'chat-dice-box',
  templateUrl: './chat-dice-box.component.html',
  styleUrls: ['./chat-dice-box.component.css']
})
export class ChatDiceBoxComponent implements OnInit {

  getChatOnlineUsers$: Observable<OnlineUser[]>;
  getChatCharactersState$: Observable<ChatSelectStatsUI[]>;
  public isFateEnabled$: Observable<boolean>;

  public standardTarget: OnlineUser[];
  public standardStats: ChatSelectStatsUI;
  public standardCalcDanno: boolean = false;
  public standardDannoType: string = "0";
  public standardCommento: string = "";
  public standardError: boolean = false;

  public freeTarget: OnlineUser[];
  public freeFaces: number = undefined;
  public freeCalcDanno: boolean = false;
  public freeDannoType: string = "0";
  public freeCommento: string = "";
  public freeError: boolean = false;

  public fateTarget: OnlineUser[];
  public fateName: string;
  public fateStats: any;
  public fateFaces: number;
  public fateCalcDanno: boolean = false;
  public fateDannoType: string = "0";
  public fateCommento: string = "";
  public fateError: boolean = false;

  public isConfirmBoxVisible: boolean = false;
  public diceType: DiceType = undefined;


  constructor(private chat: ChatService, private store: Store<fromRoot.State>) { }

  ngOnInit() {
    this.isFateEnabled$ = this.store.select(layoutSelector.getisChatFateEnable);
    this.getChatOnlineUsers$ = this.store.select(chatSelector.getChatOnlineUsers).pipe(map((allUsers: OnlineUser[]) => {
      const usersToReturn: OnlineUser[] = [];
      const myUID: string = fromRoot.getState(this.store).character.myUID;
      for (let index: number = 0; index < allUsers.length; index++) {
        const currentUser: OnlineUser = allUsers[index];
        if (currentUser.uid != myUID)
          usersToReturn.push(currentUser);
      }
      return usersToReturn;
    }));

    this.getChatCharactersState$ = this.store.select(chatSelector.getChatCharactersState).pipe(map((states: CharacterState[]) => {
      const toReturn: ChatSelectStatsUI[] = [];
      const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
      let myState: CharacterState = undefined;
      if (states.length > 0) {
        myState = states.find((aState: any) => aState.uid == characterSheet.uid);
      }

      if (Functions.IsNullOrUndefined(myState)) {
        toReturn.push(new ChatSelectStatsUI("str", "Forza (" + characterSheet.stats.str + ")", characterSheet.stats.str));
        toReturn.push(new ChatSelectStatsUI("agl", "Agilità (" + characterSheet.stats.agl + ")", characterSheet.stats.agl));
        toReturn.push(new ChatSelectStatsUI("res", "Resistenza (" + characterSheet.stats.res + ")", characterSheet.stats.res));
        toReturn.push(new ChatSelectStatsUI("wsd", "Saggezza (" + characterSheet.stats.wsd + ")", characterSheet.stats.wsd));
        toReturn.push(new ChatSelectStatsUI("per", "Percezione (" + characterSheet.stats.per + ")", characterSheet.stats.per));
        toReturn.push(new ChatSelectStatsUI("wil", "Volontà (" + characterSheet.stats.wil + ")", characterSheet.stats.wil));
      } else {
        const str: number = characterSheet.stats.str + myState.skillsBonusStats.str + myState.itemsBonusStats.str;
        toReturn.push(new ChatSelectStatsUI("str", "Forza (" + str + ")", str));
        const agl: number = characterSheet.stats.agl + myState.skillsBonusStats.agl + myState.itemsBonusStats.agl;
        toReturn.push(new ChatSelectStatsUI("agl", "Agilità (" + agl + ")", agl));
        const res: number = characterSheet.stats.res + myState.skillsBonusStats.res + myState.itemsBonusStats.res;
        toReturn.push(new ChatSelectStatsUI("res", "Resistenza (" + res + ")", res));
        const wsd: number = characterSheet.stats.wsd + myState.skillsBonusStats.wsd + myState.itemsBonusStats.wsd;
        toReturn.push(new ChatSelectStatsUI("wsd", "Saggezza (" + wsd + ")", wsd));
        const per: number = characterSheet.stats.per + myState.skillsBonusStats.per + myState.itemsBonusStats.per;
        toReturn.push(new ChatSelectStatsUI("per", "Percezione (" + per + ")", per));
        const wil: number = characterSheet.stats.wil + myState.skillsBonusStats.wil + myState.itemsBonusStats.wil;
        toReturn.push(new ChatSelectStatsUI("wil", "Volontà (" + wil + ")", wil));
      }

      return toReturn;
    }));
  }

  confirmStandardDice() {

    if (Functions.IsNullOrUndefined(this.standardStats) || (this.standardCalcDanno && (this.standardDannoType == "-1"))) {
      this.standardError = true;
      return;
    }

    this.standardError = false;

    this.freeTarget = undefined;
    this.freeFaces = undefined;
    this.freeCalcDanno = false;
    this.freeDannoType = "0";
    this.freeCommento = "";
    this.freeError = false;

    this.fateTarget = undefined;
    this.fateName = "";
    this.fateStats = undefined;
    this.fateFaces = undefined;
    this.fateCalcDanno = false;
    this.fateDannoType = "0";
    this.fateCommento = "";
    this.fateError = false;

    this.diceType = DiceType.standard;
    this.isConfirmBoxVisible = true;
  }

  confirmFreeDice() {

    if (Functions.IsNullOrUndefined(this.freeFaces) || this.freeFaces <= 0 || (this.freeCalcDanno && (this.freeDannoType == "-1"))) {
      this.freeError = true;
      return;
    }

    this.freeError = false;

    this.standardTarget = undefined;
    this.standardStats = undefined;
    this.standardCalcDanno = false;
    this.standardDannoType = "0";
    this.standardCommento = "";
    this.standardError = false;

    this.fateTarget = undefined;
    this.fateName = "";
    this.fateStats = undefined;
    this.fateFaces = undefined;
    this.fateCalcDanno = false;
    this.fateDannoType = "0";
    this.fateCommento = "";
    this.fateError = false;

    this.diceType = DiceType.free;
    this.isConfirmBoxVisible = true;
  }

  confirmFateDice() {
    if (Functions.IsStringEmpty(this.fateName) || Functions.IsNullOrUndefined(this.fateFaces) || this.fateFaces <= 0 || Functions.IsStringEmpty(this.fateStats) || (this.fateCalcDanno && (this.fateDannoType == "-1"))) {
      this.fateError = true;
      return;
    }

    this.fateError = false;

    this.standardTarget = undefined;
    this.standardStats = undefined;
    this.standardCalcDanno = false;
    this.standardDannoType = "0";
    this.standardCommento = "";
    this.standardError = false;

    this.freeTarget = undefined;
    this.freeFaces = undefined;
    this.freeCalcDanno = false;
    this.freeDannoType = "0";
    this.freeCommento = "";
    this.freeError = false;

    this.diceType = DiceType.fate;
    this.isConfirmBoxVisible = true;
  }

  rollDice() {
    switch (this.diceType) {
      case DiceType.standard:
        this.chat.rollDice(this.standardStats.value, DiceType.standard, this.standardStats.label, undefined, this.standardTarget, new DiceDmgData(this.standardCalcDanno, parseInt(this.standardDannoType)), this.standardCommento);
        break;

      case DiceType.free:
        this.chat.rollDice(this.freeFaces, DiceType.free, undefined, undefined, this.freeTarget, new DiceDmgData(this.freeCalcDanno, parseInt(this.freeDannoType)), this.freeCommento);
        break;

      case DiceType.fate:
        this.chat.rollDice(this.fateFaces, DiceType.fate, this.fateStats, this.fateName, this.fateTarget, new DiceDmgData(this.fateCalcDanno, parseInt(this.fateDannoType)), this.fateCommento);
        break;
    }
    this.isConfirmBoxVisible = false;
    this.closeBox();
  }

  closeConfirmBox() {
    this.isConfirmBoxVisible = false;
  }

  closeBox() {
    this.store.dispatch(new layout.ToggleChatDiceBoxAction());
  }
}
