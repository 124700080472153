import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-istruzioni-chat',
  templateUrl: 'cPanel-istruzioni-chat.component.html',
  styleUrls: ['cPanel-istruzioni-chat.component.less'],
})
export class CPanelIstruzioniChatComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelIstruzioniChat";

  public istruzioniChat: any = { value: "" };
  private istruzioniChatDoc: AngularFirestoreDocument<any>;
  private istruzioniChatObservable: Observable<any>;
  public istruzioniChatSubscription: Subscription;
  public config: any = {
    height: 550,
    menubar: false,
    theme: 'modern',
    plugins: [
      'advlist autolink link image imagetools lists charmap hr',
      'wordcount visualblocks visualchars code',
      'table contextmenu textcolor paste colorpicker',
    ],
    toolbar1:
      'bold italic underline strikethrough | undo redo | blockquote numlist bullist | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect | forecolor backcolor',
    toolbar2:
      'cut copy paste | outdent indent | link unlink image table | hr removeformat | subscript superscript | charmap | code',
    image_advtab: true,
    imagetools_toolbar:
      'rotateleft rotateright | flipv fliph | editimage imageoptions',
  };

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self: this = this;
    this.istruzioniChatDoc = this.afs.doc<any>('settings/istruzioniChat');
    this.istruzioniChatObservable = this.istruzioniChatDoc.valueChanges();
    this.istruzioniChatSubscription = this.istruzioniChatObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ istruzioni chat cPanel", self.moduleName, "settings/istruzioniChat", (data as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.istruzioniChat = data;
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.istruzioniChatSubscription) == false) {
      this.istruzioniChatSubscription.unsubscribe();
    }
  }

  public salva() {
    if (Functions.IsNullOrUndefined(this.istruzioniChat) == false) {
      let self = this;

      //Removing p tags
      // this.istruzioniChat.value = (this.istruzioniChat.value as string).replace(/<p>/g, "").replace(/<\/p>/g, "");

      let newIstruzioniChat = JSON.parse(JSON.stringify(this.istruzioniChat));
      this.istruzioniChatDoc.update(newIstruzioniChat)
        .then(() => {
          self.toastr.success('Istruzioni salvate!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.write('settings/istruzioniChat', newIstruzioniChat).subscribe(() => {
      //   self.toastr.success('Istruzioni Salvate!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
  }
}
