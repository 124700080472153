import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Clan, AffiliationRole, RaceIcons, Race } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-archivio-razze',
  templateUrl: 'cPanel-archivio-razze.component.html',
  styleUrls: ['cPanel-archivio-razze.component.less'],
})
export class CPanelArchivioRazzeComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelArchivioRazze"

  //#region - clan variables
  private raceIconsDoc: AngularFirestoreDocument<any>;
  private raceIconsDocObservable: Observable<any>;
  private raceIconsSubscription: Subscription;
  private races: RaceIcons[] = [];

  public dataSource: LocalDataSource;
  public settings = {
    actions: {
      add: false,
      delete: false,
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    columns: {
      name: {
        title: 'Razza',
        editable: false,
      },
      iconUrlLvl1: {
        title: 'Icona livello 1',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
      iconUrlLvl2: {
        title: 'Icona livello 2',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
      iconUrlLvl3: {
        title: 'Icona livello 3',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
      iconUrlLvl4: {
        title: 'Icona livello 4',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
      iconUrlLvl5: {
        title: 'Icona livello 5',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      }
    }
  };
  //#endregion - clan variables


  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    this.raceIconsDoc = this.afs.doc<any>('settings/races');
    this.raceIconsDocObservable = this.raceIconsDoc.valueChanges();
    this.raceIconsSubscription = this.raceIconsDocObservable.subscribe((data) => {
      // this.creditsSubscription = this.firestore.read('settings/credits').subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ races cPanel", self.moduleName, "settings/races", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false) {
        self.races = data.value;
      } else {
        self.races = [];
        self.races.push(new RaceIcons(Race.Nephilim, "Nephilim"));
        self.races.push(new RaceIcons(Race.Warlock, "Warlock"));
        self.races.push(new RaceIcons(Race.Sidhe, "Sidhe"));
        self.races.push(new RaceIcons(Race.Lycan, "Lycan"));
        self.races.push(new RaceIcons(Race.Leech, "Leech"));
        self.races.push(new RaceIcons(Race.Mondano, "Mondano"));
        self.races.push(new RaceIcons(Race.Fantasma, "Fantasma"));
        self.races.push(new RaceIcons(Race.Demone, "Demone"));
        self.races.push(new RaceIcons(Race.Angelo, "Angelo"));
        self.races.push(new RaceIcons(Race.Dimenticato, "Dimenticato"));
      }

      self.dataSource.load(self.races);
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.raceIconsSubscription) == false)
      this.raceIconsSubscription.unsubscribe();
  }

  onEditConfirm(event) {
    event.confirm.resolve();
    const updatedData: RaceIcons = event.newData;
    const updatedRaces: RaceIcons[] = [];
    this.races.forEach((aRace: RaceIcons) => {
      if (aRace.raceID != updatedData.raceID) {
        updatedRaces.push(aRace);
      } else {
        updatedRaces.push(updatedData);
      }
    })

    let self = this;
    let normalizedRaceIcons: any = JSON.parse(JSON.stringify(updatedRaces));
    let raceIconsUpdated: any = { value: normalizedRaceIcons };
    this.raceIconsDoc.set(raceIconsUpdated)
      .then(() => {
        self.toastr.success('Icone aggiornate!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

    // //update on firebase
    // let newClanData: Clan = new Clan();
    // newClanData.uid = event.newData.uid;
    // newClanData.name = event.newData.name;
    // newClanData.iconUrl = event.newData.iconUrl;
    // newClanData.roles = event.newData.roles;

    // let newClanDataJSON = JSON.parse(JSON.stringify(newClanData));
    // this.cPanelService.writeOnDB("clans", newClanDataJSON.uid, newClanDataJSON);
  }
}
