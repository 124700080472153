import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { LayoutState } from '../../../utilities/utilities.constants';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as layout from './../../../../actions/layout';
import * as layoutSelector from './../../../../selectors/layout.selectors';
import * as chat from './../../../../actions/chat';
import { MessageType, OnlineUser, ChatMessage } from '../../models/chat-message.models';
import { Observable } from 'rxjs';
import * as chatSelector from './../../../../selectors/chat.selectors';
import * as characterSelector from './../../../../selectors/character.selectors';
import { Role } from '../../../../models/data/application.data';
import { PresenceService } from '../../../../services/presence.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.css']
})
export class ChatFormComponent implements OnInit {
  // public fateActive: boolean = false;
  public selectValue: any;
  public resetting: boolean = false;

  public isSussurraEnabled$: Observable<boolean>;
  public isFateEnabled$: Observable<boolean>;
  public getChatOnlineUsers$: Observable<OnlineUser[]>;
  public getRole$: Observable<Role>
  public getCanManagePrivateChat$: Observable<boolean>;

  public prevLocations: string[] = [];
  public filteredPrevLocations: string[] = [];
  public lastInteractionTime: number = 0;

  constructor(
    public chat: ChatService,
    private store: Store<fromRoot.State>,
    private presenceService: PresenceService
  ) {
    this.isSussurraEnabled$ = this.store.select(layoutSelector.getisChatSussurroEnable);
    this.isFateEnabled$ = this.store.select(layoutSelector.getisChatFateEnable);
    this.getRole$ = this.store.select(characterSelector.getUserRole);
    this.getCanManagePrivateChat$ = this.store.select(chatSelector.getCanManagePrivateChat);

  }


  ngOnInit() {
    this.getChatOnlineUsers$ = this.store.select(chatSelector.getChatOnlineUsers).pipe(map((allUsers: OnlineUser[]) => {
      const usersToReturn: OnlineUser[] = [];
      const myUserId: string = fromRoot.getState(this.store).character.myUID;
      for (let index: number = 0; index < allUsers.length; index++) {
        const currentUser: OnlineUser = allUsers[index];
        if (currentUser.uid != myUserId)
          usersToReturn.push(currentUser);
      }
      return usersToReturn;
    }));
  }

  toggleFate() {
    // spegno sussurro prima di attivare fato
    if (fromRoot.getState(this.store).layout.isSussuraActive) {
      this.store.dispatch(new layout.ToggleChatSussurroAction());
    }
    this.store.dispatch(new layout.ToggleChatFatoAction());
    // this.fateActive = !this.fateActive;
  }

  send() {
    // is player activated by staff
    if (fromRoot.getState(this.store).character.myCharacterData.isActive == false) {
      alert("Il tuo personaggio non è ancora stato attivato dallo staff. Richiedi allo staff di essere attivato per poter inviare messaggi in chat.");
      return;
    }
    // both location and action
    // if (Functions.IsStringEmpty(this.chat.newAction) == false && Functions.IsStringEmpty(this.chat.location) == false)
    //     return;

    // nothing
    if (Functions.IsStringEmpty(this.chat.newAction) && Functions.IsStringEmpty(this.chat.location))
      return;

    // too much characters
    if (this.chat.newAction.length > 5000 || this.chat.location.length > 1000) {
      alert("L'azione è troppo lunga.");
      return;
    }

    // with locations
    if (Functions.IsStringEmpty(this.chat.location) == false) {

      // check if location already present
      const locationFounded: string = this.prevLocations.find((aPrevLocation: string) => aPrevLocation == this.chat.location);
      if (Functions.IsNullOrUndefined(locationFounded) == true) {
        // new location
        this.prevLocations.push(this.chat.location);
      }

      this.chat.sendChatMessage(MessageType.location);
      return
    }

    // sussurro
    if (fromRoot.getState(this.store).layout.isSussuraActive) {

      if (Functions.IsNullOrUndefined(this.chat.sussurroTarget))
        return;

      this.chat.sendChatMessage(MessageType.sussurro);
      this.toggleSussurra();
      return
    }

    // action message
    if (fromRoot.getState(this.store).layout.isFateActive) {
      this.chat.sendChatMessage(MessageType.fato);
    } else {
      this.chat.sendChatMessage(MessageType.standard);
    }
  }

  backMap() {
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Maps));
    this.store.dispatch(new chat.StoreSelectedChat(""));
  }

  toggleChatActionBox() {
    this.store.dispatch(new layout.ToggleChatActionBoxAction());
  }

  toggleChatSkillBox() {
    this.store.dispatch(new layout.ToggleChatSkillBoxAction());
  }

  toggleChatItemBox() {
    this.store.dispatch(new layout.ToggleChatItemBoxAction());
  }

  toggleChatDiceBox() {
    const pendingAttacks: ChatMessage[] = fromRoot.getState(this.store).chat.pendingAttacks;
    const pendingReponse: ChatMessage = fromRoot.getState(this.store).chat.pendingAttackResponse;
    if (Functions.IsNullOrUndefined(pendingAttacks) == true || pendingAttacks.length <= 0) {
      // open classic box dice
      this.store.dispatch(new layout.ToggleChatDiceBoxAction());
    } else {
      // open response to a previous pending attack
      if (Functions.IsNullOrUndefined(pendingReponse))
        this.store.dispatch(new layout.ToggleChatDiceResponseBoxAction(true));
      else
        this.store.dispatch(new layout.ToggleChatDiceDmgBoxAction(true));
    }
  }

  toggleSussurra() {
    if (fromRoot.getState(this.store).layout.isFateActive)
      return;

    this.store.dispatch(new layout.ToggleChatSussurroAction());
    this.chat.location = "";
  }

  toggleIstruzioni() {
    this.store.dispatch(new layout.ToggleChatInstructionsBoxAction());
  }

  public selected(value: any): void {
    console.log('Selected value is: ', value);
  }

  selectedMasterAction($event) {
    switch ($event) {
      case "FT":
        //fine turno
        this.chat.endTurn();
        break;

      case "IQ":
        //inizio quest
        this.chat.startQuest();
        break;

      case "TQ":
        //termine quest
        this.chat.endQuest();
        break;
    }
    this.resetting = true;
    this.selectValue = undefined;

    const self: this = this;
    setTimeout(() => {
      self.resetting = false;
    }, 0);
  }

  changeValue(event: any) {
    if (this.chat.newAction.length > 5000) {
      this.chat.newAction = this.chat.newAction.slice(0, 5000);
    }
  }

  keyUp(event: any) {
    if (event.keyCode != 13)
      return;

    let sendEnter: boolean = fromRoot.getState(this.store).character.myCharacterData.sendEnter;
    if (Functions.IsNullOrUndefined(sendEnter)) {
      sendEnter = true;
    }

    if (sendEnter) {
      this.send();
    }
  }

  //filter country list based on user input
  filterPrevLocations() {
    if (Functions.IsStringEmpty(this.chat.location) == false) {
      let _term = this.chat.location.toLowerCase();
      this.filteredPrevLocations = this.prevLocations.filter(function (el: any) {
        return el.toLowerCase().indexOf(_term.toLowerCase()) > -1;
      });
    } else {
      this.filteredPrevLocations = Object.assign([], this.prevLocations);
    }

    const actualTimer: number = new Date().getUTCSeconds();
    this.lastInteractionTime = actualTimer;
    setTimeout((that) => {
      if (that.lastInteractionTime == actualTimer) {
        that.filteredPrevLocations = [];
        that.lastInteractionTime = 0;
      }
    }, 4000, this);
  }

  //set selected country
  selectLocation(location: string) {
    this.chat.location = location;
    this.filteredPrevLocations = [];
  }

  toggleGestisciChatPrivata() {
    this.store.dispatch(new layout.ToggleGestioneChatPrivataAction());
  }
}
