import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Race, BMType, BonusMalus } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-archivio-bm',
  templateUrl: 'cPanel-archivio-bm.component.html',
  styleUrls: ['cPanel-archivio-bm.component.less'],
})
export class CPanelArchivioBMComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelArchivioBonusMalus";
  private bmsCollection: AngularFirestoreCollection<BonusMalus>;
  private bmsCollectionObservable: Observable<BonusMalus[]>;
  private bmSubscription: Subscription;
  public dataSource: LocalDataSource;
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Bonus/Malus'
      },
      isNat: {
        title: 'Di Natura',
        valuePrepareFunction: (isNat: boolean) => {
          if (Functions.IsNullOrUndefined(isNat) || isNat == false)
            return `Falso`;
          else
            return `Vero`;
        },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: false, title: "Falso" },
              { value: true, title: "Vero" }
            ]
          }
        }
      },
      isBonus: {
        title: 'B/N',
        valuePrepareFunction: (isBonus: boolean) => {
          if (Functions.IsNullOrUndefined(isBonus) || isBonus == false)
            return `Malus`;
          else
            return `Bonus`;
        },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: false, title: "Malus" },
              { value: true, title: "Bonus" }
            ]
          }
        }
      },
      race: {
        title: 'Razza',
        valuePrepareFunction: (value: string) => { return Race[value]; },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: 0, title: "Nephilim" },
              { value: 1, title: "Warlock" },
              { value: 2, title: "Sidhe" },
              { value: 3, title: "Lycan" },
              { value: 4, title: "Leech" },
              { value: 5, title: "Mondano" },
              { value: 6, title: "Fantasma" },
              { value: 7, title: "Demone" },
              { value: 8, title: "Angelo" },
              { value: 9, title: "Dimenticato" }
            ]
          }
        }
      },
      ico: {
        title: 'Icona',
        filter: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      },
      type: {
        title: 'Tipo',
        valuePrepareFunction: (value: string) => { return BMType[value]; },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: 0, title: "Attivo" },
              { value: 1, title: "Passivo" }
            ]
          }
        }
      },
      description: {
        title: 'Descrizione',
        editor: {
          type: 'textarea'
        }
      },
      note: {
        title: 'Nota Bene',
        editor: {
          type: 'textarea'
        }
      }
    }
  };

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    this.bmsCollection = this.afs.collection<BonusMalus>('bonusMalus');
    this.bmsCollectionObservable = this.bmsCollection.valueChanges();
    this.bmSubscription = this.bmsCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all bms cpanel", self.moduleName, "bonusMalus", (data as any[]).length);
      }

      self.dataSource.load(data);
    });
  }

  public ngOnDestroy() {
    this.bmSubscription.unsubscribe();
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.cPanelService.removeDB("bonusMalus", event.data.uid);
      // let self = this;
      // this.firestore.remove('bonusMalus/' + event.data.uid).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event) {
    event.newData.uid = Functions.CreateGuid();
    event.confirm.resolve();

    let self = this;

    //save on firebase
    let newBMData: BonusMalus = new BonusMalus();
    newBMData.uid = event.newData.uid;
    newBMData.name = event.newData.name;
    newBMData.ico = event.newData.ico;
    newBMData.race = parseInt(event.newData.race);
    newBMData.description = event.newData.description;
    newBMData.type = parseInt(event.newData.type);
    newBMData.note = event.newData.note;

    if (event.newData.isNat == "true" || event.newData.isNat == "True" || event.newData.isNat == "TRUE") {
      newBMData.isNat = true;
    } else {
      newBMData.isNat = false;
    }

    if (event.newData.isBonus == "true" || event.newData.isBonus == "True" || event.newData.isBonus == "TRUE") {
      newBMData.isBonus = true;
    } else {
      newBMData.isBonus = false;
    }

    let newBMDataJSON = JSON.parse(JSON.stringify(newBMData));
    this.cPanelService.writeOnDB("bonusMalus", newBMDataJSON.uid, newBMDataJSON);
    // this.firestore.write('bonusMalus/' + newBMDataJSON.uid, newBMDataJSON).subscribe(() => {
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onEditConfirm(event) {
    event.confirm.resolve();

    let self = this;

    //update on firebase
    let newBMData: BonusMalus = new BonusMalus();
    newBMData.uid = event.newData.uid;
    newBMData.name = event.newData.name;
    newBMData.ico = event.newData.ico;
    newBMData.race = event.newData.race;
    newBMData.description = event.newData.description;
    newBMData.type = event.newData.type;
    newBMData.note = event.newData.note;

    if (event.newData.isNat == "true" || event.newData.isNat == "True" || event.newData.isNat == "TRUE") {
      newBMData.isNat = true;
    } else {
      newBMData.isNat = false;
    }

    if (event.newData.isBonus == "true" || event.newData.isBonus == "True" || event.newData.isBonus == "TRUE") {
      newBMData.isBonus = true;
    } else {
      newBMData.isBonus = false;
    }

    let newBMDataJSON = JSON.parse(JSON.stringify(newBMData));
    this.cPanelService.writeOnDB("bonusMalus", newBMDataJSON.uid, newBMDataJSON);
    // this.firestore.write('bonusMalus/' + newBMDataJSON.uid, newBMDataJSON).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

}
