import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Citazione, PresencePlayerStatus, UserPresence } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { FirestoreService } from '../../../../services/firestore.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'cPanel-ip-manager',
  templateUrl: 'cPanel-ip-manager.component.html',
  styleUrls: ['cPanel-ip-manager.component.less'],
})
export class CPanelIpManagerComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelIpManager";
  private ipBannatiDoc: AngularFirestoreDocument<any>;
  private ipBannatiObservable: Observable<any>;
  private ipBannatiSubscription: Subscription;

  public ipBannatiData: any = { value: [] };
  public ipBannatiDataSource: LocalDataSource;
  public settingsIpBannati = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      ip: {
        title: 'IP Bannati'
      }
    }
  };

  private usersPresence$: Observable<UserPresence[]>;
  private usersSubscription: Subscription;

  // public ipPGData: any = { value: [] };
  public ipPGDataSource: LocalDataSource;
  public settingsIpPG = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      playerUID: {
        title: 'UID'
      },
      name: {
        title: 'Nome PG'
      },
      ip: {
        title: 'Ultimo IP usato'
      }
    }
  };

  constructor(
    private firestoreService: FirestoreService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService,
    private store: Store<fromRoot.State>,
  ) { }

  public ngOnInit() {

    let self = this;
    this.ipBannatiDataSource = new LocalDataSource();
    this.ipPGDataSource = new LocalDataSource();

    this.ipBannatiDoc = this.afs.doc<any>('settings/ipban');
    this.ipBannatiObservable = this.ipBannatiDoc.valueChanges();
    this.ipBannatiSubscription = this.ipBannatiObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all ip bannati", self.moduleName, "settings/ipban", (data.value as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == false && Functions.IsNullOrUndefined(data.value) == false) {
        self.ipBannatiData = data;
        self.ipBannatiDataSource.load(data.value);
      }
    });


    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }))
      .subscribe((data: PresencePlayerStatus[]) => {
        self.ipPGDataSource.load(data);
      });

  }

  public ngOnDestroy() {
    this.ipBannatiSubscription.unsubscribe();
  }

  onStandardDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.ipBannatiData.value = (this.ipBannatiData.value as any[]).filter((anIp: any) => anIp.ip != event.data.ip);
      let newIpBannatiDataJSON = JSON.parse(JSON.stringify(this.ipBannatiData));
      this.ipBannatiDoc.update(newIpBannatiDataJSON);
      // this.firestore.write('settings/citazioni', newCitazioniDataJSON);
    } else {
      event.confirm.reject();
    }
  }

  onStandardCreateConfirm(event) {
    event.confirm.resolve();

    if (Functions.IsNullOrUndefined(this.ipBannatiData) || Functions.IsNullOrUndefined(this.ipBannatiData.value)) {
      this.ipBannatiData = { value: [] };
    }

    //update on firebase
    const normalizedIP: string = (event.newData.ip as string).trim();
    let newIpBannatiData: any = { ip: normalizedIP };

    (this.ipBannatiData.value as any[]).push(newIpBannatiData);

    let newIpBannatiDataJSON = JSON.parse(JSON.stringify(this.ipBannatiData));
    this.ipBannatiDoc.set(newIpBannatiDataJSON);
    // this.firestore.write('settings/citazioni', newCitazioniDataJSON);
  }

  onStandardEditConfirm(event) {
    event.confirm.resolve();

    //update on firebase
    const normalizedIP: string = (event.newData.ip as string).trim();
    let newIpBannatiData: any = { ip: normalizedIP };

    if (Functions.IsNullOrUndefined(this.ipBannatiData) == false && Functions.IsNullOrUndefined(this.ipBannatiData.value) == false) {
      this.ipBannatiData.value = (this.ipBannatiData.value as any[]).filter((anIp: any) => anIp.ip != newIpBannatiData);
      (this.ipBannatiData.value as any[]).push(newIpBannatiData);
    } else {
      this.ipBannatiData = { value: [] };
      (this.ipBannatiData.value as any[]).push(newIpBannatiData);
    }

    let newIpBannatiDataJSON = JSON.parse(JSON.stringify(this.ipBannatiData));
    this.ipBannatiDoc.update(newIpBannatiDataJSON);
    // this.firestore.write('settings/citazioni', newCitazioniDataJSON);
  }

}
