import { DamageType, DiceDmgData, BHealth } from './../models/chat-message.models';
import { CharacterSheetData, Role, Stats, CharacterItems, Skill, Item, UserPresence } from './../../../models/data/application.data';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from "@angular/fire/database";
import { Observable, Subscription } from "rxjs";
import { ChatMessage, MessageType, OnlineUser, CharacterState, CharacterActivatedSkill, ChatSkill, DiceType, ChatItem, CharacterActivatedItem, ChatStatus } from "../models/chat-message.models";

import { Functions } from '../../utilities/functions/utilities.functions';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as chatAction from './../../../actions/chat';
import * as layoutAction from './../../../actions/layout';
import * as chatSelector from './../../../selectors/chat.selectors';

import { Random, MersenneTwister19937 } from "random-js";
import { FirestoreService } from '../../../services/firestore.service';
import { IncrementalService } from '../../../services/incremental.service';
import { first, skip, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable()
export class ChatService {

  //#region - support variables
  public newAction: string = "";
  public location: string = "";
  public sussurroTarget: OnlineUser;
  public boxNotImportedAction: string = "";
  //#endregion - support variables

  //#region - chat subscriptions
  public selectedChat$: Observable<string>;
  private selectedChatSubscription: Subscription;

  private chatMessagesCollections: AngularFireList<ChatMessage>;
  private chatMessagesCollectionsObservable: Observable<ChatMessage[]>;
  private chatMessagesSubscription: Subscription;
  private allChatMessagesID: Set<string> = new Set();

  private chatOnlineUsersCollections: AngularFireList<OnlineUser>;
  private chatOnlineUsersCollectionsObservable: Observable<OnlineUser[]>;
  private chatOnlineUsersSubscription: Subscription;

  private chatCharactersStateCollections: AngularFireList<CharacterState>;
  private chatCharactersStateCollectionsObservable: Observable<CharacterState[]>;
  private chatCharactersStateSubscription: Subscription;

  private chatStatusDoc: AngularFireObject<ChatStatus>;
  private chatStatusDocObservable: Observable<ChatStatus>;
  private chatStatusSubscription: Subscription;
  //#endregion - chat subscriptions

  //#region - sound and random generator
  private random: Random;
  public standardSound: HTMLAudioElement = new Audio();
  public fatoSound: HTMLAudioElement = new Audio();
  //#endregion - sound and random generator

  constructor(
    private store: Store<fromRoot.State>,
    private afdb: AngularFireDatabase,
    private firestoreService: FirestoreService,
    private incrementalService: IncrementalService
  ) {
    let self = this;
    this.selectedChat$ = this.store.select(chatSelector.getSelectedChat);
    this.selectedChatSubscription = this.selectedChat$.subscribe((selectedChat: string) => {
      if (Functions.IsStringEmpty(selectedChat) && Functions.IsNullOrUndefined(self.chatMessagesSubscription) == false) {
        self.unsubscribeFromEverything();
      }

      if (Functions.IsStringEmpty(selectedChat) == false) {
        this.allChatMessagesID.clear();
        this.subscribeMessagesOnChat();
        // this.updateOnDisconnect();
        this.subscribeOnlineUsersOnChat();
        this.subscribeCharactersStateOnChat();
        this.subscribeChatStatus();
      }
    });

    this.random = new Random(MersenneTwister19937.autoSeed());

    // set-up sounds
    this.standardSound.src = "../../../../assets/audio/action.mp3";
    this.standardSound.load();
    this.fatoSound.src = "../../../../assets/audio/fato.wav";
    this.fatoSound.load();
  }



  private subscribeMessagesOnChat() {
    let self: this = this;
    if (Functions.IsNullOrUndefined(this.chatMessagesSubscription) == false) {
      this.chatMessagesSubscription.unsubscribe();
      this.store.dispatch(new chatAction.StoreChatMessages([]));
    }


    //#region - get my chracter additional info if needed in order to populate skill and item
    const myCharacterState: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    this.incrementalService.getAdditionalInfoFromSheet(myCharacterState);
    //#endregion - get my chracter additional info if needed in order to populate skill and item

    // const before24Hour = new Date().getTime() - (24 * 3600 * 1000);
    const before2Hour = new Date().getTime() - (2 * 3600 * 1000);
    //#region - initial messages
    const startingChatMessageCollections: AngularFireList<ChatMessage> = this.afdb.list<ChatMessage>('/chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages', ref => ref.orderByChild('time').startAt(before2Hour));
    const startingChatCollectionsObservable: Observable<ChatMessage[]> = startingChatMessageCollections.valueChanges();
    let startingChatMessagesSubscription: Subscription = startingChatCollectionsObservable.pipe(first()).subscribe((messages: ChatMessage[]) => {
      // let startingChatMessagesSubscription: Subscription = this.realTimeDB.query('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages').orderByChild('time').startAt(before2Hour).on('value')
      //   .skip(1).subscribe((messages: ChatMessage[]) => {
      if (Functions.IsNullOrUndefined(messages)) {
        self.store.dispatch(new chatAction.StoreChatMessages([]));
      } else {
        let filteredMessages: ChatMessage[] = messages;
        // filteredMessages = filteredMessages.map((aMessage: any) => aMessage[0]);
        const myCharacterSheet: CharacterSheetData = fromRoot.getState(self.store).character.myCharacterData;

        // safety check
        if (Functions.IsNullOrUndefined(myCharacterSheet) == true) {
          return;
        }

        if (Functions.IsNullOrUndefined(myCharacterSheet) == false && myCharacterSheet.role > Role.master) {
          const myUID: string = fromRoot.getState(self.store).character.myUID;
          filteredMessages = filteredMessages.filter((aMessage: ChatMessage) => (
            aMessage.type != MessageType.sussurro) ||
            (aMessage.type == MessageType.sussurro && new Set(aMessage.targets).has(myUID)) ||
            (aMessage.type == MessageType.sussurro && aMessage.userID == myUID)
          );
        }

        // add ids to the collection to avoi multiple messages showed in chat
        for (const aMessage of filteredMessages) {
          self.allChatMessagesID.add(aMessage.msgID);
        }

        filteredMessages = filteredMessages.sort(function (a, b) {
          var timeA = a.time, timeB = b.time;
          if (timeA < timeB) //sort string ascending
            return -1;
          if (timeA > timeB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.store.dispatch(new chatAction.StoreChatMessages(filteredMessages));


        //ADDED - remove all character and chat status in case the are no messages (enough time passed from last game)
        if (filteredMessages.length <= 0) {
          //call the function that is called when a quest is ended
          self.resetChatState(false);
        }
      }

      startingChatMessagesSubscription.unsubscribe();
      // set as online in this chat
      // self.updateStatus(true);
    });
    //#endregion

    //#region - delta messages
    this.chatMessagesCollections = this.afdb.list<ChatMessage>('/chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages', ref => ref.orderByChild('time').startAt(new Date().getTime()));
    this.chatMessagesCollectionsObservable = this.chatMessagesCollections.stateChanges(["child_added"])
      .pipe(map((aChange: any) => {
        return [aChange.payload.val()];
      }));
    // this.chatMessagesCollections = this.afdb.list<ChatMessage>('/chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages', ref => ref.orderByChild('time').startAt(before2Hour).limitToLast(1));
    // this.chatMessagesCollectionsObservable = this.chatMessagesCollections.valueChanges();
    // this.chatMessagesSubscription = this.chatMessagesCollectionsObservable.pipe(skip(1)).subscribe((messages: any) => {
    this.chatMessagesSubscription = this.chatMessagesCollectionsObservable.subscribe((messages: any) => {

      // this.chatMessagesSubscription = this.realTimeDB.query('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages').orderByChild('time').startAt(before2Hour).limitToLast(1).on('value').skip(2).subscribe((messages: any) => {
      if (!Functions.IsNullOrUndefined(messages) && messages.length > 0) {

        // to set me as online if i'm not online
        // self.checkMyStatusOnline();

        // check and handle new message if necessary
        const myUID: string = fromRoot.getState(this.store).character.myUID;
        let filteredMessages: ChatMessage[] = messages;
        // filteredMessages = filteredMessages.map((aMessage: any) => aMessage[0]);
        const myCharacterSheet: CharacterSheetData = fromRoot.getState(self.store).character.myCharacterData;

        // safety check
        if (Functions.IsNullOrUndefined(myCharacterSheet) == true) {
          return;
        }

        if (Functions.IsNullOrUndefined(myCharacterSheet) == false && (myCharacterSheet.role > Role.master && myCharacterSheet.isPng == false)) {
          filteredMessages = filteredMessages.filter((aMessage: ChatMessage) => (
            (aMessage.type != MessageType.sussurro) ||
            (aMessage.type == MessageType.sussurro && new Set(aMessage.targets).has(myUID)) ||
            (aMessage.type == MessageType.sussurro && aMessage.userID == myUID))
            && (self.allChatMessagesID.has(aMessage.msgID) == false)
          );
        }

        // add ids to the collection to avoi multiple messages showed in chat
        for (const aMessage of filteredMessages) {
          self.allChatMessagesID.add(aMessage.msgID);
        }

        self.store.dispatch(new chatAction.StoreNewChatMessages(filteredMessages));

        // updating pending attacks
        let diceMessages: ChatMessage[] = filteredMessages.filter((aMessage: ChatMessage) => aMessage.type == MessageType.diceNotification || aMessage.type == MessageType.diceFatoNotification);
        diceMessages = diceMessages.filter((aMessage: ChatMessage) => new Set(aMessage.targets).has(myUID));

        if (diceMessages.length > 0) {
          self.store.dispatch(new chatAction.StoreNewChatAttacks(diceMessages));
          if (fromRoot.getState(this.store).layout.isChatDiceResponseBoxOpen == false && fromRoot.getState(this.store).layout.isChatDiceDmgBoxOpen == false)
            self.store.dispatch(new layoutAction.ToggleChatDiceResponseBoxAction());
        }

        //#region - managing sounds
        let isSoundEnabled: boolean = fromRoot.getState(this.store).character.myCharacterData.soundEnabled;
        if (Functions.IsNullOrUndefined(isSoundEnabled)) {
          isSoundEnabled = true;
        }

        let isMessageSoundDisabled: boolean = fromRoot.getState(this.store).character.myCharacterData.messageSoundDisabled;
        if (Functions.IsNullOrUndefined(isMessageSoundDisabled)) {
          isMessageSoundDisabled = false;
        }

        if (filteredMessages.length > 0 && isSoundEnabled == true && isMessageSoundDisabled == false) {
          const theMessage: ChatMessage = filteredMessages[0];
          if (theMessage.type == MessageType.sussurro)
            return; // no sound

          if (theMessage.type == MessageType.fato || theMessage.type == MessageType.diceFatoNotification || theMessage.type == MessageType.endOFTurn || theMessage.type == MessageType.endQuest || theMessage.type == MessageType.startQuest) {
            // sound fato
            self.fatoSound.play();
          } else {
            // sound standard
            if (theMessage.userID != myUID) {
              self.standardSound.play();
            }
          }
        }
        //#endregion

      }
    });
    //#endregion
  }

  private subscribeOnlineUsersOnChat() {
    let self: this = this;
    if (Functions.IsNullOrUndefined(this.chatOnlineUsersSubscription) == false) {
      this.chatOnlineUsersSubscription.unsubscribe();
      this.store.dispatch(new chatAction.StoreChatOnlineUsers([]));
    }

    this.chatOnlineUsersCollections = this.afdb.list<OnlineUser>('/chats/' + fromRoot.getState(this.store).chat.selectedChat + '/onlineUsersID');
    this.chatOnlineUsersCollectionsObservable = this.chatOnlineUsersCollections.valueChanges();
    this.chatOnlineUsersSubscription = this.chatOnlineUsersCollectionsObservable.subscribe((onlineUsers: any) => {
      // this.chatOnlineUsersSubscription = this.realTimeDB.query('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/onlineUsersID').on('value').subscribe((onlineUsers: any) => {
      if (Functions.IsNullOrUndefined(onlineUsers)) {
        self.store.dispatch(new chatAction.StoreChatOnlineUsers([]));
      } else {
        // let fixedonlineUsers: any[] = onlineUsers.map((aUser: any[]) => aUser[0]);
        self.store.dispatch(new chatAction.StoreChatOnlineUsers(onlineUsers));
        // self.checkMyStatusOnline();
      }
    }, (error: any) => {
      console.log("Errore OU: " + error);
    });
  }

  private subscribeCharactersStateOnChat() {
    let self: this = this;
    if (Functions.IsNullOrUndefined(this.chatCharactersStateSubscription) == false) {
      this.chatCharactersStateSubscription.unsubscribe();
      this.store.dispatch(new chatAction.StoreChatCharactersState([]));
    }

    this.chatCharactersStateCollections = this.afdb.list<CharacterState>('/chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState');
    this.chatCharactersStateCollectionsObservable = this.chatCharactersStateCollections.valueChanges();
    this.chatCharactersStateSubscription = this.chatCharactersStateCollectionsObservable.subscribe((charactersState: any[]) => {
      // this.chatCharactersStateSubscription = this.realTimeDB.query('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState').on('value').subscribe((charactersState: any[]) => {
      if (Functions.IsNullOrUndefined(charactersState) || charactersState.length <= 0) {
        self.store.dispatch(new chatAction.StoreChatCharactersState([]));
      } else {
        // let fixedCharacterState: CharacterState[] = charactersState.map((aState: any[]) => aState[0]);
        self.store.dispatch(new chatAction.StoreChatCharactersState(charactersState));
      }
    });
  }

  private subscribeChatStatus() {
    let self: this = this;
    if (Functions.IsNullOrUndefined(this.chatStatusSubscription) == false) {
      this.chatStatusSubscription.unsubscribe();
      this.store.dispatch(new chatAction.StoreChatStatus(new ChatStatus()));
    }

    this.chatStatusDoc = this.afdb.object<ChatStatus>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus');
    this.chatStatusDocObservable = this.chatStatusDoc.valueChanges();
    this.chatStatusSubscription = this.chatStatusDocObservable.subscribe((chatStatus: any) => {
      // this.chatStatusSubscription = this.realTimeDB.query('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus').on('value').skip(1).subscribe((chatStatus: any) => {
      if (Functions.IsNullOrUndefined(chatStatus)) {
        this.store.dispatch(new chatAction.StoreChatStatus(new ChatStatus()));
      } else {
        // let fixedStatus: any = chatStatus.map((status: any[]) => status[0]);
        // fixedStatus = fixedStatus[0];
        this.store.dispatch(new chatAction.StoreChatStatus(chatStatus));
        if (chatStatus.questModeActive)
          self.processNewTurnAction();
      }
    });
  }

  public unsubscribeFromEverything() {
    if (Functions.IsNullOrUndefined(this.chatMessagesSubscription) == false) {
      this.chatMessagesSubscription.unsubscribe();
      this.chatMessagesSubscription = undefined;
      this.allChatMessagesID.clear();
    }

    if (Functions.IsNullOrUndefined(this.chatOnlineUsersSubscription) == false) {
      this.chatOnlineUsersSubscription.unsubscribe();
      this.chatOnlineUsersSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.chatCharactersStateSubscription) == false) {
      this.chatCharactersStateSubscription.unsubscribe();
      this.chatCharactersStateSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.chatStatusSubscription) == false) {
      this.chatStatusSubscription.unsubscribe();
      this.chatStatusSubscription = undefined;
    }

    this.store.dispatch(new chatAction.StoreChatMessages([]));
    // this.updateStatus(false);
  }

  sendChatMessage(messageType: MessageType, message: string = "", messageTactical: string = "", dR: number = 0, dRMax: number = 0, actionTarget: string[] = [], calcDamage: boolean = false, damageType: DamageType = DamageType.none, nmeNPC: string = "") {
    const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    const timestamp = Date.now();

    // safety check
    if (Functions.IsNullOrUndefined(characterSheet) == true) {
      //TODO: check log out
      return;
    }

    let messageObject: ChatMessage;
    switch (messageType) {
      case MessageType.standard:
        messageObject = new ChatMessage(characterSheet.uid, this.newAction, timestamp, messageType, characterSheet.name, characterSheet.sex, characterSheet.isPng, characterSheet.race, characterSheet.lvl);
        // if (this.newAction.length > 800) {
        // this.firestoreService.addExperienceForAction();
        // } else {
        this.firestoreService.updateLastMessageSentDate();
        // }
        break;

      case MessageType.sussurro:
        messageObject = new ChatMessage(characterSheet.uid, this.newAction, timestamp, messageType, characterSheet.name, characterSheet.sex, characterSheet.isPng, characterSheet.race, characterSheet.lvl);
        messageObject.targets = [this.sussurroTarget.uid];

        const selectedUser: OnlineUser = fromRoot.getState(this.store).chat.chatOnlineUsers.find((aUser: OnlineUser) => aUser.uid == this.sussurroTarget.uid);
        if (Functions.IsNullOrUndefined(selectedUser) == false) {
          messageObject.nameT = selectedUser.name;
        } else {
          // if player goes offline search in presence the name
          const selectedUserPresence: UserPresence = fromRoot.getState(this.store).character.usersPresence.find((aUser: UserPresence) => (Functions.IsNullOrUndefined(aUser.state) == false && aUser.state.playerUID == this.sussurroTarget.uid));
          messageObject.nameT = selectedUserPresence.state.name;
        }
        break;

      case MessageType.fato:
        messageObject = new ChatMessage(characterSheet.uid, this.newAction, timestamp, messageType);
        break;

      case MessageType.skillNotification:
        messageObject = new ChatMessage(characterSheet.uid, message, timestamp, messageType);
        messageObject.textTactical = messageTactical;
        break;

      case MessageType.itemNotification:
        messageObject = new ChatMessage(characterSheet.uid, message, timestamp, messageType);
        messageObject.textTactical = messageTactical;
        break;

      case MessageType.location:
        let composedMessage: string = "[" + this.location + "]";
        if (Functions.IsStringEmpty(this.newAction) == false)
          composedMessage = composedMessage + " " + this.newAction;
        messageObject = new ChatMessage(characterSheet.uid, composedMessage, timestamp, messageType, characterSheet.name, characterSheet.sex, characterSheet.isPng, characterSheet.race, characterSheet.lvl);
        // if (this.newAction.length > 800) {
        // this.firestoreService.addExperienceForAction();
        // } else {
        this.firestoreService.updateLastMessageSentDate();
        // }
        break;

      case MessageType.diceNotification:
        messageObject = new ChatMessage(characterSheet.uid, message, timestamp, messageType, characterSheet.name, characterSheet.sex, characterSheet.isPng, characterSheet.race, characterSheet.lvl);
        messageObject.calcDamage = calcDamage;
        messageObject.damageType = damageType;
        messageObject.targets = actionTarget;
        messageObject.dR = dR;
        messageObject.dRM = dRMax;
        break;

      case MessageType.diceFatoNotification:
        messageObject = new ChatMessage(characterSheet.uid, message, timestamp, messageType, nmeNPC);
        messageObject.calcDamage = calcDamage;
        messageObject.damageType = damageType;
        messageObject.targets = actionTarget;
        messageObject.dR = dR;
        messageObject.dRM = dRMax;
        break;

      case MessageType.endOFTurn:
        messageObject = new ChatMessage(characterSheet.uid, '<span class="makeBold">Il fato ha dichiarato la fine del turno</span>', timestamp, messageType);
        break;

      case MessageType.startQuest:
        messageObject = new ChatMessage(characterSheet.uid, "<span class='makeBold'>Il fato ha dichiarato l'inizio della quest</span>", timestamp, messageType);
        break;

      case MessageType.endQuest:
        messageObject = new ChatMessage(characterSheet.uid, "<span class='makeBold'>Il fato ha dichiarato la fine della quest</span>", timestamp, messageType);
        break;

      case MessageType.dmgNotification:
        messageObject = new ChatMessage(characterSheet.uid, message, timestamp, messageType, characterSheet.name, characterSheet.sex, characterSheet.isPng, characterSheet.race, characterSheet.lvl);
        messageObject.calcDamage = calcDamage;
        messageObject.damageType = damageType;
        messageObject.targets = actionTarget;
        messageObject.dR = dR;
        messageObject.dRM = dRMax;
        messageObject.textTactical = messageTactical;
        break;


    }

    // if (messageType != MessageType.location) {
    //   //standard message
    //   messageObject = new ChatMessage(characterSheet.uid, characterSheet.name, this.newAction, timestamp, messageType, characterSheet.sex, characterSheet.race);
    // } else {
    //   //location message
    //   messageObject = new ChatMessage(characterSheet.uid, characterSheet.name, this.location, timestamp, messageType, characterSheet.sex, characterSheet.race);
    // }

    //NEW
    const msgID: string = Functions.CreateGuid();
    messageObject.msgID = msgID;
    const messagesCollection: AngularFireList<ChatMessage> = this.afdb.list<ChatMessage>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages/');
    messagesCollection.set(msgID, messageObject);
    // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages/' + msgID, messageObject);

    //OLD
    // this.realTimeDB.push('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/messages', messageObject);

    // do not clean action message if a dice action is performed
    if (messageType == MessageType.sussurro) {
      this.newAction = "";
      this.location = "";
      this.sussurroTarget = undefined;
    } else if (messageType != MessageType.diceNotification
      && messageType != MessageType.diceFatoNotification
      && messageType != MessageType.dmgNotification
      && messageType != MessageType.skillNotification
      && messageType != MessageType.itemNotification
    ) {
      this.newAction = "";
      this.boxNotImportedAction = "";
      this.location = "";
      this.sussurroTarget = undefined;
    }

    return messageObject;
  }

  activateChatSkill(skill: ChatSkill, timeOut: number) {
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);

    let myCharacterState: CharacterState = fromRoot.getState(this.store).chat.chatCharactersState
      .find((aCharacterState: any) => aCharacterState.uid == characterSheet.uid);
    // if (Functions.IsNullOrUndefined(myCharacterState) == false)
    //   myCharacterState = myCharacterState[0];

    // determine starting turn
    let startTurn: number = 0;
    if (fromRoot.getState(this.store).chat.chatStatus.questModeActive)
      startTurn = fromRoot.getState(this.store).chat.chatStatus.turnCounter;

    let activatedSkill: CharacterActivatedSkill = new CharacterActivatedSkill(skill.uid, startTurn, skill.duration, skill.bonusStats, skill.isSkillWithAdditingBuff);
    if (skill.isSkillWithAdditingBuff) {
      activatedSkill.bonusAddStats = skill.bonusAddStats;
      activatedSkill.bonusAddHealth = skill.bonusHealth;
    }

    if (Functions.IsNullOrUndefined(myCharacterState)) {
      myCharacterState = new CharacterState(characterSheet.uid, [activatedSkill], skill.bonusStats, [], new Stats());
      const characterStateCollection: AngularFireList<CharacterState> = this.afdb.list<CharacterState>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/');
      characterStateCollection.set(characterSheet.uid, myCharacterState);

      // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/' + characterSheet.uid, myCharacterState);
    } else {
      if (Functions.IsNullOrUndefined(myCharacterState.activatedSkills)) {
        myCharacterState.activatedSkills = [activatedSkill];
      } else {
        myCharacterState.activatedSkills.push(activatedSkill);
      }
      myCharacterState = this.updateSkillsBonus(myCharacterState, skill.bonusStats, true);
      this.updateCharacterState(characterSheet.uid, myCharacterState);
      // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/' + characterSheet.uid, myCharacterState);
    }


    //#region - update chracter health
    const bonusHealth: BHealth = skill.bonusHealth;
    if (Functions.IsNullOrUndefined(bonusHealth) == false) {
      if (Functions.IsNullOrUndefined(bonusHealth.sal) == false)
        characterSheet.health = characterSheet.health + bonusHealth.sal;

      if (Functions.IsNullOrUndefined(bonusHealth.salM) == false)
        characterSheet.mindHealth = characterSheet.mindHealth + bonusHealth.salM;

      if (characterSheet.health > 100)
        characterSheet.health = 100;

      if (characterSheet.mindHealth > 100)
        characterSheet.mindHealth = 100;
    }
    this.firestoreService.updateCharacterSheetItems(characterSheet.uid, characterSheet);
    //#endregion - update chracter health

    const message: string = "Il personaggio <span class='makeBold'>" + characterSheet.name + "</span> ha utilizzato l'abilità <span class='makeBold'>" + skill.name + "</span>";
    const messageTactical: string = "Attivata Abilità " + skill.name + " (" + skill.duration + ").";
    this.sendChatMessage(MessageType.skillNotification, message, messageTactical);

    // if duration <=0 disable istantly the item
    this.istantlyDisableSkill(skill);
  }

  disableChatSkill(skill: ChatSkill) {
    const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;

    let myCharacterState: CharacterState = fromRoot.getState(this.store).chat.chatCharactersState
      .find((aCharacterState: any) => aCharacterState.uid == characterSheet.uid);

    if (Functions.IsNullOrUndefined(myCharacterState))
      return;

    myCharacterState = Object.assign({}, myCharacterState);
    myCharacterState = this.endActivatedSkill(myCharacterState, skill);

    // myCharacterState.activatedSkills = myCharacterState.activatedSkills.filter((aSkill: CharacterActivatedSkill) => aSkill.sUid != skill.uid);

    // if (myCharacterState.activatedSkills.length > 0) {
    //   // there are more skills activate
    //   myCharacterState = this.updateSkillsBonus(myCharacterState, skill.bonusStats, false);
    // } else {
    //   // no more skills
    //   myCharacterState.skillsBonusStats = new Stats();
    // }

    this.updateCharacterState(characterSheet.uid, myCharacterState);
    // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/' + characterSheet.uid, myCharacterState);

    // if (fromRoot.getState(this.store).chat.chatStatus.questModeActive == false) {
    //   const message: string = "L'abilità <span class='makeBold'>" + skill.name + "</span> utilizzata dal personaggio <span class='makeBold'>" + characterSheet.name + "</span> ha esaurito il suo effetto";
    //   this.sendChatMessage(MessageType.skillNotification, message);
    // }
  }

  activateChatItem(item: ChatItem) {
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);

    let myCharacterState: CharacterState = fromRoot.getState(this.store).chat.chatCharactersState
      .find((aCharacterState: any) => aCharacterState.uid == characterSheet.uid);

    // determine starting turn
    let startTurn: number = 0;
    if (fromRoot.getState(this.store).chat.chatStatus.questModeActive)
      startTurn = fromRoot.getState(this.store).chat.chatStatus.turnCounter;

    let activatedItem: CharacterActivatedItem = new CharacterActivatedItem(item.uid, startTurn, item.duration, item.bonusStats, item.isItemWithAdditingBuff);
    if (item.isItemWithAdditingBuff) {
      activatedItem.bonusAddStats = item.bonusAddStats;
      activatedItem.bonusAddHealth = item.bonusHealth;
    }

    // update character state
    if (Functions.IsNullOrUndefined(myCharacterState)) {
      myCharacterState = new CharacterState(characterSheet.uid, [], new Stats(), [activatedItem], item.bonusStats);
      const characterStateCollection: AngularFireList<CharacterState> = this.afdb.list<CharacterState>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/');
      characterStateCollection.set(characterSheet.uid, myCharacterState);
      // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/' + characterSheet.uid, myCharacterState);
    } else {
      if (Functions.IsNullOrUndefined(myCharacterState.activatedItem)) {
        myCharacterState.activatedItem = [activatedItem];
      } else {
        myCharacterState.activatedItem.push(activatedItem);
      }
      myCharacterState = this.updateItemsBonus(myCharacterState, item.bonusStats, true);
      this.updateCharacterState(characterSheet.uid, myCharacterState);
      // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/' + characterSheet.uid, myCharacterState);
    }

    // decrement item available
    let characterItems: CharacterItems[] = characterSheet.userItems;
    const currentUsedItem: CharacterItems = characterItems.find((anItem: CharacterItems) => anItem.uid == item.uid);
    if (Functions.IsNullOrUndefined(item.maxUses) == false && item.maxUses > 0) {
      currentUsedItem.remainingUse--;
      if (currentUsedItem.remainingUse <= 0) {
        currentUsedItem.remainingUse = item.maxUses;
        currentUsedItem.qta--;
        if (currentUsedItem.qta <= 0) {
          // i have to remove the user item
          characterSheet.userItems = characterItems.filter((aCharacterItem: CharacterItems) => aCharacterItem.uid != currentUsedItem.uid);
        }
      }
    }

    //#region - update chracter health
    const bonusHealth: BHealth = item.bonusHealth;
    if (Functions.IsNullOrUndefined(bonusHealth) == false) {
      if (Functions.IsNullOrUndefined(bonusHealth.sal) == false)
        characterSheet.health = characterSheet.health + bonusHealth.sal;

      if (Functions.IsNullOrUndefined(bonusHealth.salM) == false)
        characterSheet.mindHealth = characterSheet.mindHealth + bonusHealth.salM;

      if (characterSheet.health > 100)
        characterSheet.health = 100;

      if (characterSheet.mindHealth > 100)
        characterSheet.mindHealth = 100;
    }
    //#endregion - update chracter health

    this.firestoreService.updateCharacterSheetItems(characterSheet.uid, characterSheet);

    const message: string = "Il personaggio <span class='makeBold'>" + characterSheet.name + "</span> ha utilizzato l'oggetto <span class='makeBold'>" + item.name + "</span>";
    const messageTactical: string = "Utilizzato oggetto " + item.name + ".";
    this.sendChatMessage(MessageType.itemNotification, message, messageTactical);

    // if duration <=0 disable istantly the item
    this.istantlyDisableItem(item);
  }

  disableChatItem(item: ChatItem) {
    const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;

    let myCharacterState: CharacterState = fromRoot.getState(this.store).chat.chatCharactersState
      .find((aCharacterState: any) => aCharacterState.uid == characterSheet.uid);

    if (Functions.IsNullOrUndefined(myCharacterState))
      return;

    myCharacterState = Object.assign({}, myCharacterState);

    myCharacterState = this.endActivatedItem(myCharacterState, item);

    // const occurrenceIndex: number = myCharacterState.activatedItem.findIndex((anItem: CharacterActivatedItem) => anItem.uid == item.uid);
    // if (occurrenceIndex == -1)
    //   return;

    // myCharacterState.activatedItem.splice(occurrenceIndex, 1);
    // if (myCharacterState.activatedItem.length > 0) {
    //   // there are more skills activate
    //   myCharacterState = this.updateItemsBonus(myCharacterState, item.bonusStats, false);
    // } else {
    //   // no more skills
    //   myCharacterState.itemsBonusStats = new Stats();
    // }

    this.updateCharacterState(characterSheet.uid, myCharacterState);
    // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/' + characterSheet.uid, myCharacterState);

    // const message: string = "Il personaggio <span class='makeBold'>" + characterSheet.name + "</span> ha disattivato l'effetto dell'oggetto <span class='makeBold'>" + item.name + "</span>";
    // this.sendChatMessage(MessageType.itemNotification, message);
  }

  rollDice(to: number, diceType: DiceType, stat: string = undefined, nmeNPC: string = undefined, targets: OnlineUser[] = undefined, diceDmgData: DiceDmgData = new DiceDmgData(), diceComment: string = "") {
    const randomValue = this.random.integer(1, to);

    let message: string;
    let actionTarget: string[] = undefined;
    switch (diceType) {
      case DiceType.standard:
        message = 'Lancia un dado su <span class="makeBold">' + stat + '</span> e ottiene ' + randomValue + '/' + to;
        if (Functions.IsNullOrUndefined(targets) == false && targets.length > 0) {
          message = message + ' puntando a ';
          actionTarget = [];
          for (let index: number = 0; index < targets.length; index++) {
            message = message + targets[index].name;
            actionTarget.push(targets[index].uid);
            if (index + 1 < targets.length)
              message = message + ', ';
          }
        }

        if(Functions.IsStringEmpty(diceComment) === false) {
          message = message + ' <span class="diceCommentText">[' + diceComment + ']</span>';
        }

        this.sendChatMessage(MessageType.diceNotification, message, "", randomValue, to, actionTarget, diceDmgData.calcDamage, diceDmgData.damageType);
        break;

      case DiceType.free:
        message = 'Lancia un dado libero e ottiene ' + randomValue + '/' + to;
        if (Functions.IsNullOrUndefined(targets) == false && targets.length > 0) {
          message = message + ' puntando a ';
          actionTarget = [];
          for (let index: number = 0; index < targets.length; index++) {
            message = message + targets[index].name;
            actionTarget.push(targets[index].uid);
            if (index + 1 < targets.length)
              message = message + ', ';
          }
        }

        if(Functions.IsStringEmpty(diceComment) === false) {
          message = message + ' <span class="diceCommentText">[' + diceComment + ']</span>';
        }

        this.sendChatMessage(MessageType.diceNotification, message, "", randomValue, to, actionTarget, diceDmgData.calcDamage, diceDmgData.damageType);
        break;

      case DiceType.fate:
        message = '<span class="makeBold">' + nmeNPC + '</span> lancia un dado su <span class="makeBold">' + stat + '</span> e ottiene ' + randomValue + '/' + to;
        if (Functions.IsNullOrUndefined(targets) == false && targets.length > 0) {
          message = message + ' puntando a ';
          actionTarget = [];
          for (let index: number = 0; index < targets.length; index++) {
            message = message + targets[index].name;
            actionTarget.push(targets[index].uid);
            if (index + 1 < targets.length)
              message = message + ', ';
          }
        }

        if(Functions.IsStringEmpty(diceComment) === false) {
          message = message + ' <span class="diceCommentText">[' + diceComment + ']</span>';
        }

        this.sendChatMessage(MessageType.diceFatoNotification, message, "", randomValue, to, actionTarget, diceDmgData.calcDamage, diceDmgData.damageType, nmeNPC);
        break;

      case DiceType.response:
        const currentPendingAttacks: ChatMessage[] = fromRoot.getState(this.store).chat.pendingAttacks;
        if (Functions.IsNullOrUndefined(currentPendingAttacks) == false && currentPendingAttacks.length > 0) {
          message = 'Lancia un dado su <span class="makeBold">' + stat + '</span> in risposta a ' + currentPendingAttacks[0].name + ' (' + currentPendingAttacks[0].dR + '/' + currentPendingAttacks[0].dRM + ') e ottiene ' + randomValue + '/' + to;
        } else {
          // safety version
          message = 'Lancia un dado su <span class="makeBold">' + stat + '</span> in risposta a ' + + ' e ottiene ' + randomValue + '/' + to;
        }
        // message = 'Lancia un dado su <span class="makeBold">' + stat + '</span> in risposta a ' + + ' e ottiene ' + randomValue + '/' + to;
        const myResponse: ChatMessage = this.sendChatMessage(MessageType.diceNotification, message, "", randomValue, to, actionTarget, diceDmgData.calcDamage, diceDmgData.damageType);
        if (diceDmgData.calcDamage == false || randomValue >= diceDmgData.valAtk) {
          this.store.dispatch(new chatAction.PopChatAttacks());
          if (fromRoot.getState(this.store).chat.pendingAttacks.length == 0)
            this.store.dispatch(new layoutAction.ToggleChatDiceResponseBoxAction(false));
        } else {
          this.store.dispatch(new chatAction.StoreResponsePendingAttack(myResponse));
          this.store.dispatch(new layoutAction.ToggleChatDiceDmgBoxAction(true));
        }
        break;

      case DiceType.calcDmg:
        //check critical
        if (diceDmgData.valAtk <= 1) {
          // maldestro attaccante
          this.store.dispatch(new chatAction.PopChatAttacks());
          this.store.dispatch(new layoutAction.ToggleChatDiceResponseBoxAction(false));
          if (fromRoot.getState(this.store).chat.pendingAttacks.length > 0)
            this.store.dispatch(new layoutAction.ToggleChatDiceResponseBoxAction(true));
        }

        let criticalBonus: number = 0;
        if (diceDmgData.valAtk == diceDmgData.maxValAtk) {
          // critico attaccante
          criticalBonus = 5;
        }

        if (randomValue <= 1) {
          // maldestro difesa
          criticalBonus = 5;
        }

        diceDmgData.damageSubiti = diceDmgData.valAtk - randomValue;
        diceDmgData.damageSubiti = diceDmgData.damageSubiti + criticalBonus;
        const pgName: string = fromRoot.getState(this.store).character.myCharacterData.name;

        const currentPendingAttacksDMG: ChatMessage[] = fromRoot.getState(this.store).chat.pendingAttacks;
        if (Functions.IsNullOrUndefined(currentPendingAttacksDMG) == false && currentPendingAttacksDMG.length > 0) {
          message = 'Lancia un dado su <span class="makeBold">' + stat + '</span> in risposta a ' + currentPendingAttacksDMG[0].name + ' (' + currentPendingAttacksDMG[0].dR + '/' + currentPendingAttacksDMG[0].dRM + ') e ottiene ' + randomValue + '/' + to;
        } else {
          // safety version
          message = 'Lancia un dado su <span class="makeBold">' + stat + '</span> in risposta e ottiene ' + randomValue + '/' + to;
        }
        // message = 'Lancia un dado su <span class="makeBold">' + stat + '</span> in risposta e ottiene ' + randomValue + '/' + to;
        let msgTattica: string = "";
        //calculate dmg
        if (diceDmgData.damageSubiti > 0) {
          message = message + ". " + pgName + " subisce " + diceDmgData.damageSubiti + " sulla Salute ";
          if (diceDmgData.damageType == DamageType.fisici) {
            message = message + "Fisica.";
          } else {
            message = message + "Mentale.";
          }


          //#region - aggiorna salute
          let sal: number = fromRoot.getState(this.store).character.myCharacterData.health;
          let salM: number = fromRoot.getState(this.store).character.myCharacterData.mindHealth;
          const uid: string = fromRoot.getState(this.store).character.myCharacterData.uid;
          if (diceDmgData.damageType == DamageType.fisici) {
            sal = sal - diceDmgData.damageSubiti;
          } else if (diceDmgData.damageType == DamageType.mentali) {
            salM = salM - diceDmgData.damageSubiti;
          }

          this.changeHealth(uid, sal, salM);
          //#endregion

          //messaggio scheda tattina
          msgTattica = "Ricevuto danno di " + diceDmgData.damageSubiti + " punti sulla Salute ";
          if (diceDmgData.damageType == DamageType.fisici) {
            msgTattica = msgTattica + "Fisica, che arriva a " + sal + "/100."
          } else {
            msgTattica = msgTattica + "Mentale, che arriva a " + salM + "/100."
          }

        } else {
          diceDmgData.damageSubiti = 0;
        }
        this.store.dispatch(new chatAction.PopChatAttacks());
        this.store.dispatch(new chatAction.StoreResponsePendingAttack(undefined));
        this.store.dispatch(new layoutAction.ToggleChatDiceResponseBoxAction(false));
        if (fromRoot.getState(this.store).chat.pendingAttacks.length > 0)
          this.store.dispatch(new layoutAction.ToggleChatDiceResponseBoxAction(true));
        this.sendChatMessage(MessageType.dmgNotification, message, msgTattica, randomValue, to, actionTarget, diceDmgData.calcDamage, diceDmgData.damageType);
        break;
    }
  }

  endTurn() {
    this.sendChatMessage(MessageType.endOFTurn);
    let updatedChatStatus: ChatStatus = Object.assign({}, fromRoot.getState(this.store).chat.chatStatus);
    updatedChatStatus.turnCounter++;
    const chatStatusDcc: AngularFireObject<ChatStatus> = this.afdb.object<ChatStatus>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus/');
    chatStatusDcc.set(updatedChatStatus);
    // this.realTimeDB.update('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus/', [updatedChatStatus]);
  }

  endQuest() {
    this.resetChatState();
    this.sendChatMessage(MessageType.endQuest);
  }

  startQuest() {
    this.resetChatState(true);
    this.sendChatMessage(MessageType.startQuest);
  }

  updateCharacterState(uid: string, characterState: CharacterState) {
    if (!Functions.IsNullOrUndefined(characterState)) {
      const characterStateCollection: AngularFireList<CharacterState> = this.afdb.list<CharacterState>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/');
      characterStateCollection.set(uid, characterState);
      // this.realTimeDB.write('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/' + uid, characterState);
    }
  }

  changeHealth(userId: string, newHealth: number, newMindHealth: number) {
    this.firestoreService.updateCharacterSheetHealth(userId, newHealth, newMindHealth);
  }

  private resetChatState(startingQuest: boolean = false) {
    const characterStateCollection: AngularFireList<CharacterState> = this.afdb.list<CharacterState>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/');
    characterStateCollection.remove();
    // this.realTimeDB.remove('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/charactersState/');
    if (startingQuest) {
      const newStatus: ChatStatus = new ChatStatus();
      newStatus.questModeActive = true;
      const chatStatusDcc: AngularFireObject<ChatStatus> = this.afdb.object<ChatStatus>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus/');
      chatStatusDcc.set(newStatus);
      // this.realTimeDB.update('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus/', [newStatus]);
    } else {
      const chatStatusDcc: AngularFireObject<ChatStatus> = this.afdb.object<ChatStatus>('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus/');
      chatStatusDcc.set(new ChatStatus());
      // this.realTimeDB.update('chats/' + fromRoot.getState(this.store).chat.selectedChat + '/chatStatus/', [new ChatStatus()]);
    }
  }

  public forceRefresh() {
    this.unsubscribeFromEverything();
    this.subscribeMessagesOnChat();
    this.subscribeOnlineUsersOnChat();
    this.subscribeCharactersStateOnChat();
    this.subscribeChatStatus();

  }


  // support functions
  private updateSkillsBonus(currentState: CharacterState, stat: Stats, addBonus: boolean) {
    if (addBonus) {
      // adding bonus
      let prevBonusState: Stats = currentState.skillsBonusStats;
      prevBonusState.str = prevBonusState.str + stat.str;
      prevBonusState.agl = prevBonusState.agl + stat.agl;
      prevBonusState.res = prevBonusState.res + stat.res;
      prevBonusState.per = prevBonusState.per + stat.per;
      prevBonusState.wsd = prevBonusState.wsd + stat.wsd;
      prevBonusState.wil = prevBonusState.wil + stat.wil;

      currentState.skillsBonusStats = prevBonusState;
      return currentState;
    } else {
      // substract bonus

      let prevBonusState: Stats = currentState.skillsBonusStats;
      prevBonusState.str = prevBonusState.str - stat.str;
      prevBonusState.agl = prevBonusState.agl - stat.agl;
      prevBonusState.res = prevBonusState.res - stat.res;
      prevBonusState.per = prevBonusState.per - stat.per;
      prevBonusState.wsd = prevBonusState.wsd - stat.wsd;
      prevBonusState.wil = prevBonusState.wil - stat.wil;

      currentState.skillsBonusStats = prevBonusState;
      return currentState;
    }
  }

  private updateItemsBonus(currentState: CharacterState, stat: Stats, addBonus: boolean) {
    if (addBonus) {
      // adding bonus
      let prevBonusState: Stats = currentState.itemsBonusStats;
      prevBonusState.str = prevBonusState.str + stat.str;
      prevBonusState.agl = prevBonusState.agl + stat.agl;
      prevBonusState.res = prevBonusState.res + stat.res;
      prevBonusState.per = prevBonusState.per + stat.per;
      prevBonusState.wsd = prevBonusState.wsd + stat.wsd;
      prevBonusState.wil = prevBonusState.wil + stat.wil;

      currentState.itemsBonusStats = prevBonusState;
      return currentState;
    } else {
      // substract bonus

      let prevBonusState: Stats = currentState.itemsBonusStats;
      prevBonusState.str = prevBonusState.str - stat.str;
      prevBonusState.agl = prevBonusState.agl - stat.agl;
      prevBonusState.res = prevBonusState.res - stat.res;
      prevBonusState.per = prevBonusState.per - stat.per;
      prevBonusState.wsd = prevBonusState.wsd - stat.wsd;
      prevBonusState.wil = prevBonusState.wil - stat.wil;

      currentState.itemsBonusStats = prevBonusState;
      return currentState;
    }
  }

  private updateHealthFromAdditive(bonusAddHealth: BHealth) {
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);

    //#region - update chracter health
    if (Functions.IsNullOrUndefined(bonusAddHealth) == false) {
      if (Functions.IsNullOrUndefined(bonusAddHealth.salAdd) == false)
        characterSheet.health = characterSheet.health + bonusAddHealth.salAdd;

      if (Functions.IsNullOrUndefined(bonusAddHealth.salMAdd) == false)
        characterSheet.mindHealth = characterSheet.mindHealth + bonusAddHealth.salMAdd;

      if (characterSheet.health > 100)
        characterSheet.health = 100;

      if (characterSheet.mindHealth > 100)
        characterSheet.mindHealth = 100;

    }
    //#endregion - update chracter health
    this.firestoreService.updateCharacterSheetItems(characterSheet.uid, characterSheet);
  }

  private istantlyDisableItem(item: ChatItem) {
    if (item.duration <= 0) {
      setTimeout((that) => {
        that.disableChatItem(item);
      }, 0, this);
    }
  }

  private istantlyDisableSkill(skill: ChatSkill) {
    if (skill.duration <= 0) {
      setTimeout((that) => {
        that.disableChatSkill(skill);
      }, 0, this);
    }
  }

  private endActivatedItem(characterState: CharacterState, item: ChatItem | CharacterActivatedItem) {
    const occurrenceIndex: number = characterState.activatedItem.findIndex((anItem: CharacterActivatedItem) => anItem.uid == item.uid);
    if (occurrenceIndex == -1)
      return;

    characterState.activatedItem.splice(occurrenceIndex, 1);
    if (characterState.activatedItem.length > 0) {
      // there are more skills activate
      characterState = this.updateItemsBonus(characterState, item.bonusStats, false);
    } else {
      // no more skills
      characterState.itemsBonusStats = new Stats();
    }

    // sending message
    const mySheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    const currentItem: Item = fromRoot.getState(this.store).datas.items
      .find((anItem: Item) => anItem.uid == item.uid);

    if (Functions.IsNullOrUndefined(currentItem) == false && Functions.IsNullOrUndefined(mySheet) == false) {
      const message: string = "Gli effetti dell'oggetto <span class='makeBold'>" + currentItem.name + "</span> di <span class='makeBold'>" + mySheet.name + "</span>, sono terminati";
      const messageTactical: string = "Terminato l'oggetto" + currentItem.name + ".";
      this.sendChatMessage(MessageType.itemNotification, message, messageTactical);
    }

    return characterState;
  }

  private endActivatedSkill(characterState: CharacterState, skill: ChatSkill | CharacterActivatedSkill) {
    characterState.activatedSkills = characterState.activatedSkills.filter((aSkill: CharacterActivatedSkill) => aSkill.uid != skill.uid);

    if (characterState.activatedSkills.length > 0) {
      // there are more skills activate
      characterState = this.updateSkillsBonus(characterState, skill.bonusStats, false);
    } else {
      // no more skills
      characterState.skillsBonusStats = new Stats();
    }

    // sending message
    const mySheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    const currentSkill: Skill = fromRoot.getState(this.store).datas.skills
      .find((aSkill: Skill) => aSkill.uid == skill.uid);

    if (Functions.IsNullOrUndefined(currentSkill) == false && Functions.IsNullOrUndefined(mySheet) == false) {
      const message: string = "Gli effetti dell'abilità <span class='makeBold'>" + currentSkill.name + "</span> di <span class='makeBold'>" + mySheet.name + "</span>, sono terminati";
      const messageTactical: string = "Terminata Abilità " + currentSkill.name + ".";
      this.sendChatMessage(MessageType.skillNotification, message, messageTactical);
    }

    return characterState;
  }

  private processNewTurnAction() {
    const currentState: fromRoot.State = fromRoot.getState(this.store);
    const myUID: string = currentState.character.myUID;
    const currentTurn: number = currentState.chat.chatStatus.turnCounter;
    let myCharacterState: CharacterState = currentState.chat.chatCharactersState
      .find((aChatState: CharacterState) => aChatState.uid == myUID);

    // no item or skill activated
    if (Functions.IsNullOrUndefined(myCharacterState))
      return;

    myCharacterState = Object.assign({}, myCharacterState);

    //#region - items evaluation
    const notAdditingItemsToDeactivate: CharacterActivatedItem[] = [];
    const additingItemsToDeactivate: CharacterActivatedItem[] = [];
    if (Functions.IsNullOrUndefined(myCharacterState.activatedItem) == false) {
      for (let index: number = 0; index < myCharacterState.activatedItem.length; index++) {
        const anActiveItem: CharacterActivatedItem = myCharacterState.activatedItem[index];
        if (currentTurn > anActiveItem.startTurn + anActiveItem.durata - 1) {
          // devo disattivare l'item
          if (anActiveItem.addBuff) {
            // è additivo
            additingItemsToDeactivate.push(anActiveItem);
          } else {
            // non è additivo devo solo sottrarre il bonus e disattivare l'item
            notAdditingItemsToDeactivate.push(anActiveItem);
          }
        } else {
          // item è ancora attivo, devo valutare i bonus additivi e aggiungere i potenziamenti
          if (anActiveItem.addBuff) {
            // è additivo quindi devo aggiungere il bonus degli items
            myCharacterState = this.updateItemsBonus(myCharacterState, anActiveItem.bonusAddStats, true);
            this.updateHealthFromAdditive(anActiveItem.bonusAddHealth);
          }
        }
      }
    }

    // disabilito e sistemo ciò che riguarda gli item NON additivi
    for (let index: number = 0; index < notAdditingItemsToDeactivate.length; index++) {
      const anActiveItem: CharacterActivatedItem = notAdditingItemsToDeactivate[index];
      myCharacterState = this.endActivatedItem(myCharacterState, anActiveItem);
    }

    // disabilito e sistemo ciò che riguarda gli item additivi
    for (let index: number = 0; index < additingItemsToDeactivate.length; index++) {
      const anActiveItem: CharacterActivatedItem = additingItemsToDeactivate[index];
      for (let inner_index: number = 0; inner_index < anActiveItem.durata; inner_index++) {
        myCharacterState = this.updateItemsBonus(myCharacterState, anActiveItem.bonusAddStats, false);
      }
      myCharacterState = this.endActivatedItem(myCharacterState, anActiveItem);
    }
    //#endregion

    //#region - skills evaluation
    const notAdditingSkillsToDeactivate: CharacterActivatedSkill[] = [];
    const additingSkillsToDeactivate: CharacterActivatedSkill[] = [];
    if (Functions.IsNullOrUndefined(myCharacterState.activatedSkills) == false) {
      for (let index: number = 0; index < myCharacterState.activatedSkills.length; index++) {
        const anActiveSkill: CharacterActivatedSkill = myCharacterState.activatedSkills[index];
        if (currentTurn > anActiveSkill.startTurn + anActiveSkill.durata - 1) {
          // devo disattivare la skill
          if (anActiveSkill.addBuff) {
            // è additiva
            additingSkillsToDeactivate.push(anActiveSkill);
          } else {
            // non è additiva devo solo sottrarre il bonus e disattivare la skill
            notAdditingSkillsToDeactivate.push(anActiveSkill);
          }
        } else {
          // item è ancora attivo, devo valutare i bonus additivi e aggiungere i potenziamenti
          if (anActiveSkill.addBuff) {
            // è additivo quindi devo aggiungere il bonus delle skill
            myCharacterState = this.updateSkillsBonus(myCharacterState, anActiveSkill.bonusAddStats, true);
            this.updateHealthFromAdditive(anActiveSkill.bonusAddHealth);
          }
        }
      }
    }

    // disabilito e sistemo ciò che riguarda le skill NON additive
    for (let index: number = 0; index < notAdditingSkillsToDeactivate.length; index++) {
      const anActiveSkill: CharacterActivatedItem = notAdditingSkillsToDeactivate[index];
      myCharacterState = this.endActivatedSkill(myCharacterState, anActiveSkill);
    }

    // disabilito e sistemo ciò che riguarda le skill additive
    for (let index: number = 0; index < additingSkillsToDeactivate.length; index++) {
      const anActiveSkill: CharacterActivatedItem = additingSkillsToDeactivate[index];
      for (let inner_index: number = 0; inner_index < anActiveSkill.durata; inner_index++) {
        myCharacterState = this.updateSkillsBonus(myCharacterState, anActiveSkill.bonusAddStats, false);
      }
      myCharacterState = this.endActivatedSkill(myCharacterState, anActiveSkill);
    }
    //#endregion

    this.updateCharacterState(myCharacterState.uid, myCharacterState);
  }

  /******************** */
}
