import { Skill, Item, BonusMalus, UserEntry, RulesSezione, Volto, Corp, Clan, FirebaseCalendarEvent, MeteoDay, Citazione, LocationRoot, RaceIcons } from './../models/data/application.data';
import * as datas from '../actions/datas';
import { Functions } from '../modules/utilities/functions/utilities.functions';

export interface State {
  skills: Skill[];
  skillsV: number;
  raceSkillFullFlag: number[]; //buyable skills
  items: Item[];
  itemsV: number;
  bms: BonusMalus[];
  bmsV: number;
  raceBMFullFlag: number[];

  pendingCPUpdates: boolean;
  staffNews: any,
  events: FirebaseCalendarEvent[];
  meteoDay: MeteoDay;
  meteoNote: string;
  citazioni: Citazione[];

  firstEntranceMsg: any;

  usersList: UserEntry[];

  locations: LocationRoot;
  locationsV: number;
  locationsFullCache: boolean;

  rulesList: RulesSezione[];
  rulesV: number;
  rulesFullCache: boolean;

  voltiList: Volto[];
  voltiV: number;
  voltiFullCache: boolean;
  clansList: Clan[];
  clansV: number;
  clanFullCache: boolean;
  corpsList: Corp[];
  corpsV: number;
  corpFullCache: boolean;

  raceIcons: RaceIcons[];
}

export const initialState: State = {
  skills: [],
  skillsV: 0,
  raceSkillFullFlag: [],
  items: [],
  itemsV: 0,
  bms: [],
  bmsV: 0,
  raceBMFullFlag: [],

  pendingCPUpdates: false,
  staffNews: undefined,
  events: [],
  meteoDay: undefined,
  meteoNote: "",

  firstEntranceMsg: undefined,

  citazioni: [],

  usersList: [],

  locations: undefined,
  locationsV: 0,
  locationsFullCache: false,
  rulesList: [],
  rulesV: 0,
  rulesFullCache: false,

  voltiList: [],
  voltiV: 0,
  voltiFullCache: false,
  clansList: [],
  clansV: 0,
  clanFullCache: false,
  corpsList: [],
  corpsV: 0,
  corpFullCache: false,

  raceIcons: []
};

export function reducer(state: State = initialState, action: datas.Actions): State {
  switch (action.type) {

    case datas.STORE_SKILLS:
      const sPayload: any = action.payload;
      const skills: any = sPayload.skills as Skill[];
      const sVer: any = sPayload.ver as number;
      const raceSkillFullFlag: any = sPayload.raceSkillFullFlag as number[];
      return Object.assign({}, state, {
        skills: skills,
        skillsV: sVer,
        raceSkillFullFlag: raceSkillFullFlag
      });

    case datas.STORE_ITEMS:
      const iPayload: any = action.payload;
      const items: any = iPayload.items as Item[];
      const iVer: any = iPayload.ver as number;
      return Object.assign({}, state, {
        items: items,
        itemsV: iVer
      });

    case datas.STORE_BMS:
      const bPayload: any = action.payload;
      const bms: any = bPayload.bms as BonusMalus[];
      const bVer: any = bPayload.ver as number;
      const raceBMFullFlag: any = bPayload.raceBMFullFlag as number[];
      return Object.assign({}, state, {
        bms: bms,
        bmsV: bVer,
        raceBMFullFlag: raceBMFullFlag
      });

    case datas.SET_PENDING_CP_UPDATES:
      const newValue: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        pendingCPUpdates: newValue
      });

    case datas.STORE_USERS_LIST:
      const usersList: UserEntry[] = action.payload as UserEntry[];
      return Object.assign({}, state, {
        usersList: usersList
      });

    case datas.STORE_RULES:
      const rPayload: any = action.payload;
      const rulesList: any = rPayload.rules as RulesSezione[];
      const rulesListVer: any = rPayload.ver as number;
      const rulesFullCache: any = rPayload.fullFlag as boolean;
      return Object.assign({}, state, {
        rulesList: rulesList,
        rulesV: rulesListVer,
        rulesFullCache: rulesFullCache
      });

    case datas.STORE_VOLTI:
      const voltiPayload: any = action.payload;
      const volti: any = voltiPayload.volti as Volto[];
      const voltiVer: any = voltiPayload.ver as number;
      const voltiFullCache: any = voltiPayload.fullFlag as boolean;
      return Object.assign({}, state, {
        voltiList: volti,
        voltiV: voltiVer,
        voltiFullCache: voltiFullCache
      });

    case datas.STORE_CLANS:
      const clansPayload: any = action.payload;
      const clans: any = clansPayload.clans as Clan[];
      const clansVer: any = clansPayload.ver as number;
      const clansFullCache: any = clansPayload.fullFlag as boolean;
      return Object.assign({}, state, {
        clansList: clans,
        clansV: clansVer,
        clanFullCache: clansFullCache
      });

    case datas.STORE_CORPS:
      const corpsPayload: any = action.payload;
      const corps: any = corpsPayload.corps as Corp[];
      const corpsVer: any = corpsPayload.ver as number;
      const corpsFullCache: any = corpsPayload.fullFlag as boolean;
      return Object.assign({}, state, {
        corpsList: corps,
        corpsV: corpsVer,
        corpsFullCache: corpsFullCache
      });

    case datas.STORE_STAFF_NEWS:
      const staffNews: any = action.payload as any;
      return Object.assign({}, state, {
        staffNews: staffNews
      });

    case datas.STORE_EVENTS:
      const events: any = action.payload as any;
      return Object.assign({}, state, {
        events: events
      });

    case datas.STORE_METEO:
      const meteoDay: MeteoDay = action.payload as any;
      return Object.assign({}, state, {
        meteoDay: meteoDay
      });

    case datas.STORE_METEO_NOTE:
      const meteoNote: any = action.payload as any;
      return Object.assign({}, state, {
        meteoNote: meteoNote
      });

    case datas.STORE_FIRST_ENTRACE_MSG:
      const firstEntranceMsg: any = action.payload as any;
      return Object.assign({}, state, {
        firstEntranceMsg: firstEntranceMsg
      });

    case datas.STORE_CITAZIONI:
      const citazioni: Citazione[] = action.payload as any;
      return Object.assign({}, state, {
        citazioni: citazioni
      });

    case datas.STORE_LOCATIONS:
      const lPayload: any = action.payload;
      const locations: any = lPayload.locations as any;
      const locationsVer: any = lPayload.ver as number;
      const locationsFullCache: any = lPayload.fullFlag as boolean;
      return Object.assign({}, state, {
        locations: locations,
        locationsV: locationsVer,
        locationsFullCache: locationsFullCache
      });

    case datas.STORE_RACE_ICONS:
      const raceIcons: RaceIcons[] = action.payload as any;
      return Object.assign({}, state, {
        raceIcons: raceIcons
      });

    default: {
      return state;
    }
  }
}

export const getSkills: (state: State) => Skill[] = (state: State) => state.skills;
export const getItems: (state: State) => Item[] = (state: State) => state.items;
export const getBMs: (state: State) => BonusMalus[] = (state: State) => state.bms;
export const getPendingCPUpdates: (state: State) => boolean = (state: State) => state.pendingCPUpdates;
export const getUsersList: (state: State) => UserEntry[] = (state: State) => state.usersList;
export const getRulesList: (state: State) => RulesSezione[] = (state: State) => state.rulesList;
export const getVoltiList: (state: State) => Volto[] = (state: State) => state.voltiList;
export const getClansList: (state: State) => Clan[] = (state: State) => state.clansList;
export const getCorpsList: (state: State) => Corp[] = (state: State) => state.corpsList;
export const getStaffNews: (state: State) => any = (state: State) => state.staffNews;
export const getEvents: (state: State) => FirebaseCalendarEvent[] = (state: State) => state.events;
export const getMeteo: (state: State) => MeteoDay = (state: State) => state.meteoDay;
export const getMeteoNote: (state: State) => string = (state: State) => state.meteoNote;
export const getCitazioni: (state: State) => Citazione[] = (state: State) => state.citazioni;
export const getLocations: (state: State) => LocationRoot = (state: State) => state.locations;
export const getFirstEntranceMsg: (state: State) => any = (state: State) => state.firstEntranceMsg;
export const getRaceIcons: (state: State) => any = (state: State) => state.raceIcons;
