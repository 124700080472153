import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable } from "rxjs";
import { ChatMessage } from '../../models/chat-message.models';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as chatSelector from './../../../../selectors/chat.selectors';
import * as layout from './../../../../actions/layout';
import { Functions } from '../../../utilities/functions/utilities.functions';

@Component({
  selector: 'chat-action-box',
  templateUrl: './chat-action-box.component.html',
  styleUrls: ['./chat-action-box.component.css']
})
export class ChatActionBoxComponent implements OnInit, OnDestroy {

  public action: string;
  constructor(private chat: ChatService, private store: Store<fromRoot.State>, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.action = this.chat.newAction.slice(0);
    if (Functions.IsStringEmpty(this.action)) {
      this.action = this.chat.boxNotImportedAction.slice(0);
    }
  }

  ngOnDestroy() {
    this.chat.boxNotImportedAction = this.action;
  }

  changeValue(event: any) {
    if (this.action.length > 5000) {
      let truncatedAction: string = this.action.slice(0, 5000);
      this.action = truncatedAction;
      this.chat.boxNotImportedAction = this.action;
      this.cd.markForCheck();
    }
  }

  importMessage() {
    this.chat.newAction = this.action;
    this.chat.boxNotImportedAction = "";
    this.store.dispatch(new layout.ToggleChatActionBoxAction());
  }

  closeBox() {
    this.chat.boxNotImportedAction = this.action;
    this.store.dispatch(new layout.ToggleChatActionBoxAction());
  }
}
