import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { first } from 'rxjs/operators';

@Component({
  selector: 'cPanel-logs',
  templateUrl: 'cPanel-logs.component.html',
  styleUrls: ['cPanel-logs.component.less'],
})
export class CPanelLogsComponent implements OnInit, OnDestroy {
  //#region - other variables
  @Input() type: string = "";
  private urlID: string = "";
  public settings = {};
  public settingsXP = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      logDT: {
        title: 'Data',
        editable: false
      },
      from: {
        title: 'Da chi',
        editable: false
      },
      to: {
        title: 'A chi',
        editable: false
      },
      qta: {
        title: 'Quantità Aggiunta',
        editable: false
      },
      usableBC: {
        title: 'Quantità Iniziale',
        editable: false
      },
      why: {
        title: 'Motivo',
        editable: false
      }
    }
  };
  public settingsOgg = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      logDT: {
        title: 'Data',
        editable: false
      },
      from: {
        title: 'Da chi',
        editable: false
      },
      to: {
        title: 'A chi',
        editable: false
      },
      what: {
        title: 'Oggetto',
        editable: false
      },
      where: {
        title: 'Tipo di scambio',
        editable: false
      }
    }
  };
  public settingsBank = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      logDT: {
        title: 'Data',
        editable: false
      },
      from: {
        title: 'Da chi',
        editable: false
      },
      to: {
        title: 'A chi',
        editable: false
      },
      qta: {
        title: 'Quantità',
        editable: false
      },
      what: {
        title: 'Descrizione',
        editable: false
      }
    }
  };
  public logsEntry: any[] = [];
  public dataSource: LocalDataSource;
  //#endregion - other variables

  //#region - get logs
  public logsCollections: AngularFireList<any>;
  public logsCollectionsObservable: Observable<any[]>
  private logsSubscription: Subscription;
  //#endregion - get logs

  constructor(
    private toastr: ToastrService,
    private afdb: AngularFireDatabase
  ) {

  }

  public ngOnInit() {
    if (this.type == "Esperienza") {
      this.settings = this.settingsXP;
      this.urlID = "logPX/";
    } else if (this.type == "Oggetti Scambiati") {
      this.settings = this.settingsOgg;
      this.urlID = "logOgg/";
    } else if (this.type == "Soldi e Banca") {
      this.settings = this.settingsBank;
      this.urlID = "logBank/";
    }

    if (Functions.IsStringEmpty(this.urlID))
      return;

    let self = this;
    this.dataSource = new LocalDataSource();

    this.logsCollections = this.afdb.list<any>('/' + this.urlID, ref => ref.orderByChild('logPTS'));
    this.logsCollectionsObservable = this.logsCollections.valueChanges();
    this.logsSubscription = this.logsCollectionsObservable.pipe(first()).subscribe((logs: any[]) => {
      if (Functions.IsNullOrUndefined(logs)) {
        self.logsEntry = [];
      } else {
        // let logEntry: any[] = logs.map((aLog: any[]) => aLog[0]);
        logs = logs.sort(function (a, b) {
          var timeA = a.logPTS, timeB = b.logPTS;
          if (timeA < timeB) //sort string ascending
            return 1;
          if (timeA > timeB)
            return -1;
          return 0; //default return value (no sorting)
        });

        self.logsEntry = logs;
        self.dataSource.load(self.logsEntry);
      }
    });


    // this.logsSubscription = this.realTimeDB.query(this.urlID).orderByChild('logPTS').once('value')
    //   .skip(1).subscribe((logs: any[]) => {

    //     if (Functions.IsNullOrUndefined(logs)) {
    //       self.logsEntry = [];

    //     } else {
    //       let logEntry: any[] = logs.map((aLog: any[]) => aLog[0]);
    //       logEntry = logEntry.sort(function (a, b) {
    //         var timeA = a.logPTS, timeB = b.logPTS;
    //         if (timeA < timeB) //sort string ascending
    //           return -1;
    //         if (timeA > timeB)
    //           return 1;
    //         return 0; //default return value (no sorting)
    //       });

    //       self.logsEntry = logEntry;
    //       self.dataSource.load(self.logsEntry);
    //     }
    //   });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.logsSubscription) == false)
      this.logsSubscription.unsubscribe();
  }

}
