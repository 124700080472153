
import * as fromRoot from '../reducers';
import { Store } from '@ngrx/store';
import * as character from '../actions/character';
import * as datas from '../actions/datas';
import * as characterSelector from '../selectors/character.selectors';

import { Injectable } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import 'firebase/database';

import { Functions } from '../modules/utilities/functions/utilities.functions';
import { UserPresence, CharacterSheetData, PresencePlayerStatus, State, UserEntry, StandardChat, SecretChat, SpecialChat, Race, Role } from '../models/data/application.data';
import { OnlineUser } from '../modules/chatRoom/models/chat-message.models';
import { LayoutState } from '../modules/utilities/utilities.constants';

import * as layout from './../actions/layout';
import * as chat from './../actions/chat';

@Injectable()
export class PresenceService {

  // maneging connection reference
  connectedRef: firebase.database.Reference;
  currentConnection: firebase.database.ThenableReference;

  // last access reference
  connectedUserDataState: firebase.database.Reference;

  // current area reference
  // currentAreaConnection: firebase.database.Reference;

  // chat selected
  chatConnectionsRef: firebase.database.Reference;

  // prev chat selected
  // prevChatConnectionsRef: firebase.database.Reference;

  //#region - online users variables
  private onlineUsersCollections: AngularFireList<UserPresence>;
  private onlineUsersCollectionsObservable: Observable<UserPresence[]>
  private onlineUsersSubscription: Subscription;
  //#endregion - online users variables

  public ip: string = "";

  constructor(
    private store: Store<fromRoot.State>,
    private afdb: AngularFireDatabase
  ) {
    let self: this = this;
    this.store.select(characterSelector.getCharacterSheet).subscribe((mySheet: CharacterSheetData) => {
      self.updateUserInfos();
    });

    // è una tabella che mostra i seguenti dati nell'ordine che ti mostro qui.
    // - La Razza del PG
    // - Scrivi al PG + Stato del personaggio (se occupato o pronto a giocare)
    // - L'immagine del Mini Avatar da 100x100 che viene inserito in scheda, ma ridotta al 70%
    // - Il nome completo del personaggio, anche con secondo nome se è stato inserito, o senza cognome, se serve al PG che sia così
    // (Sotto al nome del PG serve anche l'inserimento dello stato testuale, se si desidera metterlo, ma i caratteri massimi devono essere 50)
    // - Ruolo Off del PG


    // this.updateOnUser().subscribe();
    // this.updateOnDisconnect().subscribe();
    // this.updateOnAway();

    // this.setUpMyPresence();
    // this.subscribeUserStatus();

  }




  //NEW NEW

  public setUpMyPresence(myuid: string) {
    let self: this = this;
    if (Functions.IsNullOrUndefined(this.connectedRef) == true) {
      // not connected yet
      this.connectedRef = firebase.database().ref('.info/connected');
      this.connectedRef.on('value', function (snap) {
        if (snap.val() === true) {
          // console.log("connesso");
          if (Functions.IsStringEmpty(myuid) == false && Functions.IsNullOrUndefined(self.currentConnection) == true) {

            //#region - connectionArea log part
            var myConnectionsRef = firebase.database().ref('status/' + myuid + '/connections');
            self.currentConnection = myConnectionsRef.push();
            self.currentConnection.onDisconnect().remove();
            //#endregion

            //#region - last access part
            // self.lastAccessConnection = firebase.database().ref('status/' + myuid + '/state');
            // self.lastAccessConnection.onDisconnect().set(new PresencePlayerStatus(characterSheet.uid, characterSheet.race, characterSheet.MAvatar, characterSheet.name, characterSheet.sur, characterSheet.nick, Functions.GetDateHour(new Date())));
            // self.lastAccessConnection.set(Functions.GetDateHour(new Date()));
            //#endregion

            //#region - Current area computation and state part
            self.connectedUserDataState = firebase.database().ref('status/' + myuid + '/state');

            const characterSheet: CharacterSheetData = fromRoot.getState(self.store).character.myCharacterData;
            let presenceSatus: PresencePlayerStatus;
            if (Functions.IsNullOrUndefined(characterSheet) == false) {

              // set up the on disconnect
              self.connectedUserDataState.onDisconnect().set(new PresencePlayerStatus(characterSheet.uid, characterSheet.race, characterSheet.role, characterSheet.MAvatar, characterSheet.name, characterSheet.sur, characterSheet.nick, Functions.GetCurrentDate(), characterSheet.sex, (characterSheet.lvl || 1), (characterSheet.state || State.libero), (characterSheet.stateText || ""), characterSheet.isPng, characterSheet.isBanned, characterSheet.lastMessageSent, characterSheet.myPrivateChat, (characterSheet.clan || ""), characterSheet.clanLvl, (characterSheet.corp || ""), characterSheet.corpLvl, characterSheet.isActive, characterSheet.isCapoClan, characterSheet.isCapoCorp, characterSheet.isCapoRace, self.ip));

              presenceSatus = new PresencePlayerStatus(characterSheet.uid, characterSheet.race, characterSheet.role, characterSheet.MAvatar, characterSheet.name, characterSheet.sur, characterSheet.nick, Functions.GetCurrentDate(), characterSheet.sex, (characterSheet.lvl || 1), (characterSheet.state || State.libero), (characterSheet.stateText || ""), characterSheet.isPng, characterSheet.isBanned, characterSheet.lastMessageSent, characterSheet.myPrivateChat, (characterSheet.clan || ""), characterSheet.clanLvl, (characterSheet.corp || ""), characterSheet.corpLvl, characterSheet.isActive, characterSheet.isCapoClan, characterSheet.isCapoCorp, characterSheet.isCapoRace, self.ip);
              if (Functions.IsStringEmpty(fromRoot.getState(self.store).chat.selectedChat) == true) {
                // case starting new connection
                // self.currentAreaConnection.set("@@HOME@@");
                self.currentConnection.set("@@HOME@@");
                self.connectedUserDataState.set(presenceSatus);
                // self.currentConnection.set(presenceSatus);
              } else {
                // case reconnecting
                // self.currentAreaConnection.set(fromRoot.getState(self.store).chat.selectedChat);
                self.currentConnection.set(fromRoot.getState(self.store).chat.selectedChat);
                self.connectedUserDataState.set(presenceSatus);
                // self.currentConnection.set(presenceSatus);
                self.updateChatStatus(fromRoot.getState(self.store).chat.selectedChat, fromRoot.getState(self.store).chat.prevChat);
              }
            }

            self.subscribeUserStatus();
            //#endregion

          }
        } else {
          // console.log("disconnesso");
          self.logOutMyPresence(myuid);
        }
      });
    }
  }

  public logOutMyPresence(myuid: string) {
    if (Functions.IsNullOrUndefined(this.onlineUsersSubscription) == false) {
      this.onlineUsersSubscription.unsubscribe();
      this.onlineUsersSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.currentConnection) == false) {
      this.currentConnection.remove();
      this.currentConnection = undefined;
    }

    if (Functions.IsNullOrUndefined(this.connectedUserDataState) == false) {

      // remove previously on disonnect
      this.connectedUserDataState.onDisconnect().cancel();

      const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
      if (Functions.IsNullOrUndefined(characterSheet) == false && characterSheet.isBanned == false) {
        const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterSheet.uid, characterSheet.race, characterSheet.role, characterSheet.MAvatar, characterSheet.name, characterSheet.sur, characterSheet.nick, Functions.GetCurrentDate(), characterSheet.sex, (characterSheet.lvl || 1), (characterSheet.state || State.libero), (characterSheet.stateText || ""), characterSheet.isPng, characterSheet.isBanned, characterSheet.lastMessageSent, characterSheet.myPrivateChat, (characterSheet.clan || ""), characterSheet.clanLvl, (characterSheet.corp || ""), characterSheet.corpLvl, characterSheet.isActive, characterSheet.isCapoClan, characterSheet.isCapoCorp, characterSheet.isCapoRace, this.ip);
        this.connectedUserDataState.set(presenceSatus);
      }
      this.connectedUserDataState = undefined;
    }

    if (Functions.IsNullOrUndefined(this.chatConnectionsRef) == false) {
      this.chatConnectionsRef.remove();
      this.chatConnectionsRef = undefined;
    }

    // if (Functions.IsNullOrUndefined(this.currentAreaConnection) == false) {
    //   this.currentAreaConnection.remove();
    //   this.currentAreaConnection = undefined;
    // }

    if (Functions.IsNullOrUndefined(this.connectedRef) == false) {
      this.connectedRef = undefined;
    }
  }

  public updateMyCurrentArea(newAreaId?: string, prevAreaId?: string) {
    // const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    // let presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterSheet.uid, characterSheet.race, characterSheet.MAvatar, characterSheet.name, characterSheet.sur, characterSheet.nick);

    if (Functions.IsNullOrUndefined(newAreaId) == false) {
      // presenceSatus.currentArea = newAreaId;
      // this.currentConnection.set(presenceSatus);
      this.currentConnection.set(newAreaId);
    } else {
      // presenceSatus.currentArea = "@@HOME@@";
      // this.currentConnection.set(presenceSatus);
      this.currentConnection.set("@@HOME@@");
    }

    this.updateChatStatus(newAreaId, prevAreaId);
  }

  private updateChatStatus(newAreaId?: string, prevAreaId?: string) {
    const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (Functions.IsNullOrUndefined(characterSheet) == true || Functions.IsNullOrUndefined(characterSheet.uid) == true)
      return;

    if (Functions.IsStringEmpty(prevAreaId) == false && prevAreaId != "@@HOME@@") {
      const chatStatusToUpdate: AngularFireObject<any> = this.afdb.object<any>('chats/' + prevAreaId + '/onlineUsersID/' + characterSheet.uid);
      chatStatusToUpdate.remove();
      // this.realTimeDB.remove('chats/' + prevAreaId + '/onlineUsersID/' + characterSheet.uid);
    }

    if (Functions.IsStringEmpty(newAreaId) == false && newAreaId != "@@HOME@@") {
      let newOnlineUser: OnlineUser = new OnlineUser(characterSheet.uid, characterSheet.name, characterSheet.sex, characterSheet.isPng);
      this.chatConnectionsRef = firebase.database().ref('chats/' + newAreaId + '/onlineUsersID/' + characterSheet.uid);
      this.chatConnectionsRef.onDisconnect().remove();
      this.chatConnectionsRef.set(newOnlineUser);
    }
  }

  private updateUserInfos() {
    if (Functions.IsNullOrUndefined(this.connectedUserDataState))
      return;

    const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (Functions.IsNullOrUndefined(characterSheet)) {
      return;
    }


    const presenceStatus: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const myPresenceStatus: UserPresence = presenceStatus.find((aUser: UserPresence) => Functions.IsNullOrUndefined(aUser.state) === false && aUser.state.playerUID == characterSheet.uid);

    //safety check
    if (Functions.IsNullOrUndefined(myPresenceStatus) === true || Functions.IsNullOrUndefined(characterSheet) === true) {
      return;
    }

    //#region - check update needed
    // check if we need to update really the status
    let needToUpdate: boolean = false;
    if (myPresenceStatus.state.lvl != characterSheet.lvl) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.miniAvatarUrl != characterSheet.MAvatar) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.nick != characterSheet.nick) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.race != characterSheet.race) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.role != characterSheet.role) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.sex != characterSheet.sex) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.sur != characterSheet.sur) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.state != characterSheet.state) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.stateText != characterSheet.stateText) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.lastMessageSent != characterSheet.lastMessageSent) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.isBanned != characterSheet.isBanned) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.isPng != characterSheet.isPng) {
      needToUpdate = true;
    }
    if (needToUpdate == false && myPresenceStatus.state.name != characterSheet.name) {
      needToUpdate = true;
    }

    if (needToUpdate == false && Functions.IsNullOrUndefined(characterSheet.myPrivateChat) == false && Functions.IsNullOrUndefined(myPresenceStatus.state.myPrivateChat) == true) {
      needToUpdate = true;
    } else if (needToUpdate == false && Functions.IsNullOrUndefined(characterSheet.myPrivateChat) == true && Functions.IsNullOrUndefined(myPresenceStatus.state.myPrivateChat) == false) {
      needToUpdate = true;
    } else if (needToUpdate == false && Functions.IsNullOrUndefined(characterSheet.myPrivateChat) == false) {

      // check if there are changes in the name or on the allowed ID
      if (myPresenceStatus.state.myPrivateChat.uid != characterSheet.myPrivateChat.uid
        || myPresenceStatus.state.myPrivateChat.name != characterSheet.myPrivateChat.name
        || myPresenceStatus.state.myPrivateChat.location != characterSheet.myPrivateChat.location) {
        needToUpdate = true;
      }

      // avoid to compare allowed ID
      needToUpdate = true;
    }

    // if (Functions.IsNullOrUndefined(characterSheet.myPrivateChat) == false && myPresenceStatus.state.myPrivateChat.uid != characterSheet.myPrivateChat.uid) {
    //   needToUpdate = true;
    // }

    if (needToUpdate == false)
      return;
    //#endregion

    const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterSheet.uid, characterSheet.race, characterSheet.role, characterSheet.MAvatar, characterSheet.name, characterSheet.sur, characterSheet.nick, Functions.GetCurrentDate(), characterSheet.sex, (characterSheet.lvl || 1), (characterSheet.state || State.libero), (characterSheet.stateText || ""), characterSheet.isPng, characterSheet.isBanned, characterSheet.lastMessageSent, characterSheet.myPrivateChat, (characterSheet.clan || ""), characterSheet.clanLvl, (characterSheet.corp || ""), characterSheet.corpLvl, characterSheet.isActive, characterSheet.isCapoClan, characterSheet.isCapoCorp, characterSheet.isCapoRace, this.ip);
    this.connectedUserDataState.set(presenceSatus);
    this.connectedUserDataState.onDisconnect().set(presenceSatus);

  }

  // public updateUserStatus(presenceSatus: PresencePlayerStatus) {
  //   if (Functions.IsNullOrUndefined(this.connectedUserDataState))
  //     return;

  //   this.connectedUserDataState.set(presenceSatus);
  // }

  public subscribeUserStatus() {
    let self: this = this;
    const myUID: string = fromRoot.getState(this.store).character.myUID;
    if (Functions.IsNullOrUndefined(this.onlineUsersSubscription) == true) {

      this.onlineUsersCollections = this.afdb.list<UserPresence>('/status');
      this.onlineUsersCollectionsObservable = this.onlineUsersCollections.valueChanges();
      this.onlineUsersSubscription = this.onlineUsersCollectionsObservable.subscribe((usersPresence: UserPresence[]) => {

        // this.onlineUsersSubscription = this.realTimeDB.query('status/').on('value')
        //   .skip(1)
        //   .subscribe((usersPresence: any[]) => {
        if (Functions.IsNullOrUndefined(usersPresence) == false) {
          // let fixedUsersPresence: any[] = usersPresence.map((aUser: any[]) => aUser[0]);
          self.store.dispatch(new character.StoreUsersPresence(usersPresence));

          const myUserPresence: UserPresence = usersPresence.find((aUserPresence: UserPresence) => (aUserPresence.state != undefined && aUserPresence.state.playerUID == myUID));

          const usersList: UserEntry[] = [];
          for (let index: number = 0; index < usersPresence.length; index++) {
            const aUserPresence: UserPresence = usersPresence[index];

            if (Functions.IsNullOrUndefined(aUserPresence.state) == true || aUserPresence.state.playerUID == myUID)
              continue;

            const aUserEntry: UserEntry = new UserEntry();
            aUserEntry.uid = aUserPresence.state.playerUID;
            aUserEntry.name = aUserPresence.state.name;
            usersList.push(aUserEntry);
          }

          // adding all user entry if i'm the permission
          if (Functions.IsNullOrUndefined(myUserPresence) == false && Functions.IsNullOrUndefined(myUserPresence.state) == false && myUserPresence.state.role < 3) {
            const allEntry: UserEntry = new UserEntry();
            allEntry.uid = "@@@ALL@@@";
            allEntry.name = "Messaggio per Tutti";
            usersList.push(allEntry)
          }

          self.store.dispatch(new datas.StoreUsersList(usersList));

          //check if i was banned from secret chat
          const currentChat: string = fromRoot.getState(self.store).chat.selectedChat
          if (Functions.IsStringEmpty(currentChat) == false) {
            // i'm in a chat
            const standardChats: StandardChat[] = fromRoot.getState(self.store).layout.standardChatsList;
            const inAStandardChat: StandardChat = standardChats.find((aChat: StandardChat) => aChat.uid == currentChat);
            if (Functions.IsNullOrUndefined(inAStandardChat)) {
              // i'm in a secret chat
              const myCharacterSheet: CharacterSheetData = fromRoot.getState(self.store).character.myCharacterData;

              //#region - computing availableChat
              const userPresences: UserPresence[] = fromRoot.getState(self.store).character.usersPresence;
              const myUID: string = myCharacterSheet.uid;
              const imSuperUser: boolean = myCharacterSheet.role < 3;
              const availableSecretChats: SecretChat[] = [];
              for (let index: number = 0; index < userPresences.length; index++) {
                const currentUserPresence: PresencePlayerStatus = userPresences[index].state;
                if (Functions.IsNullOrUndefined(currentUserPresence) || Functions.IsNullOrUndefined(currentUserPresence.myPrivateChat) || Functions.IsStringEmpty(currentUserPresence.myPrivateChat.uid))
                  continue;

                const aPrivateChat: SecretChat = currentUserPresence.myPrivateChat;
                if (imSuperUser || aPrivateChat.ownerUID == myUID) {
                  //i'm owner or super user
                  availableSecretChats.push(aPrivateChat);
                } else {
                  if (Functions.IsNullOrUndefined(aPrivateChat.accessibleByUIDs))
                    continue;

                  //i was invited
                  const invited: string = aPrivateChat.accessibleByUIDs.find((anUID: string) => anUID == myUID);
                  if (Functions.IsNullOrUndefined(invited) == false)
                    availableSecretChats.push(aPrivateChat);
                }
              }
              //#endregion

              //#region - available special chat
              // se sono gestore vedo tutte le chat
              let availableSpecialChats: SpecialChat[] = [];
              const specialChats: SpecialChat[] = fromRoot.getState(self.store).layout.specialChatsList;
              const imGestore: boolean = myCharacterSheet.role < 2;
              if (imGestore) {
                availableSpecialChats = specialChats;
              } else {
                const myUID: string = myCharacterSheet.uid;
                const myRace: Race = myCharacterSheet.race;
                const myClan: string = myCharacterSheet.clan;
                const myCorp: string = myCharacterSheet.corp;
                const myLVL: number = myCharacterSheet.lvl;
                const myOffRole: Role = myCharacterSheet.role;

                for (let index: number = 0; index < specialChats.length; index++) {
                  const aSpecialChat: SpecialChat = specialChats[index];
                  //#region -  check if i satisfy the requirements

                  // excluded player
                  if (Functions.IsNullOrUndefined(aSpecialChat.excludeCharacters) == false && aSpecialChat.excludeCharacters.length > 0) {
                    const excludedPlayers: Set<string> = new Set(aSpecialChat.excludeCharacters);
                    if (excludedPlayers.has(myUID))
                      continue;
                  }

                  // included player
                  if (Functions.IsNullOrUndefined(aSpecialChat.includeCharacters) == false && aSpecialChat.includeCharacters.length > 0) {
                    const includededPlayers: Set<string> = new Set(aSpecialChat.includeCharacters);
                    if (includededPlayers.has(myUID)) {
                      availableSpecialChats.push(aSpecialChat);
                      continue;
                    }
                  }

                  // race
                  if (Functions.IsNullOrUndefined(aSpecialChat.allowedToRace) == false && aSpecialChat.allowedToRace.length > 0) {
                    const allowedRaceSet: Set<Race> = new Set(aSpecialChat.allowedToRace);
                    if (allowedRaceSet.has(myRace) == false) {
                      continue;
                    }
                  }

                  // clan
                  if (Functions.IsNullOrUndefined(aSpecialChat.allowedToClan) == false && aSpecialChat.allowedToClan.length > 0) {
                    const allowedClanSet: Set<string> = new Set(aSpecialChat.allowedToClan);
                    if (allowedClanSet.has(myClan) == false) {
                      continue;
                    }
                  }

                  // corp
                  if (Functions.IsNullOrUndefined(aSpecialChat.allowedToCorp) == false && aSpecialChat.allowedToCorp.length > 0) {
                    const allowedCorpSet: Set<string> = new Set(aSpecialChat.allowedToCorp);
                    if (allowedCorpSet.has(myCorp) == false) {
                      continue;
                    }
                  }

                  // role
                  if (Functions.IsNullOrUndefined(aSpecialChat.allowedOffRole) == false && aSpecialChat.allowedOffRole.length > 0) {
                    const allowedRoleSet: Set<Role> = new Set(aSpecialChat.allowedOffRole);
                    if (allowedRoleSet.has(myOffRole) == false) {
                      continue;
                    }
                  }

                  // lvl major or equal of
                  if (Functions.IsNullOrUndefined(aSpecialChat.lvlMajorOrEqual) == false) {
                    if (myLVL < aSpecialChat.lvlMajorOrEqual) {
                      continue;
                    }
                  }

                  // lvl minor or equal of
                  if (Functions.IsNullOrUndefined(aSpecialChat.lvlMinorOrEqual) == false) {
                    if (myLVL > aSpecialChat.lvlMinorOrEqual) {
                      continue;
                    }
                  }
                  //#endregion -  check if i satisfy the requirements

                  availableSpecialChats.push(aSpecialChat);
                }
              }

              //#endregion

              const needToExitFromChat: SecretChat = availableSecretChats.find((aChat: SecretChat) => aChat.uid == currentChat);
              const needToExitFromChatSecondCheck: SpecialChat = availableSpecialChats.find((aChat: SpecialChat) => aChat.uid == currentChat);
              if (Functions.IsNullOrUndefined(needToExitFromChat) && Functions.IsNullOrUndefined(needToExitFromChatSecondCheck)) {
                // i have to exit from chat because i do not have permission to stay here
                self.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Maps));
                self.store.dispatch(new chat.StoreSelectedChat(""));
              }

            }
          }

        }
      });
    }
  }

  public deletePresenceState(uid: string) {
    const chatStatusToUpdate: AngularFireObject<any> = this.afdb.object<any>('status/' + uid);
    chatStatusToUpdate.remove();
    // this.realTimeDB.remove('status/' + uid);
  }

  public updateAUserInfos(characterSheet: CharacterSheetData, overwrite: boolean = false) {
    //safety check
    if (Functions.IsNullOrUndefined(characterSheet) == true) {
      //TODO: check log out
      return;
    }

    const presenceStatus: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const myPresenceStatus: UserPresence = presenceStatus.find((aUser: UserPresence) => Functions.IsNullOrUndefined(aUser.state) === true && aUser.state.playerUID == characterSheet.uid);

    if (Functions.IsNullOrUndefined(myPresenceStatus) == false && overwrite == false)
      return;

    const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterSheet.uid, characterSheet.race, characterSheet.role, characterSheet.MAvatar, characterSheet.name, characterSheet.sur, characterSheet.nick, Functions.GetCurrentDate(), characterSheet.sex, (characterSheet.lvl || 1), (characterSheet.state || State.libero), (characterSheet.stateText || ""), characterSheet.isPng, characterSheet.isBanned, characterSheet.lastMessageSent, characterSheet.myPrivateChat, (characterSheet.clan || ""), characterSheet.clanLvl, (characterSheet.corp || ""), characterSheet.corpLvl, characterSheet.isActive, characterSheet.isCapoClan, characterSheet.isCapoCorp, characterSheet.isCapoRace, this.ip);
    const presenceSatusJSON: string = JSON.stringify(presenceSatus);

    if (overwrite) {
      const chatStatusToUpdate: AngularFireObject<any> = this.afdb.object<any>('status/' + characterSheet.uid + '/state');
      chatStatusToUpdate.set(presenceSatusJSON);

      // this.realTimeDB.write('status/' + characterSheet.uid + '/state', presenceSatus);
    } else {
      const chatStatusToUpdate: AngularFireObject<any> = this.afdb.object<any>('status/' + characterSheet.uid + '/state');
      chatStatusToUpdate.set(presenceSatusJSON);

      // this.realTimeDB.update('status/' + characterSheet.uid + '/state', presenceSatus);
    }
  }

  public forceRefresh() {
    this.updateUserInfos();
  }

  public updateAUserSecretChat(secretChat: SecretChat, userId: string) {
    const chatStatusToUpdate: AngularFireObject<any> = this.afdb.object<any>('status/' + userId + '/state');
    chatStatusToUpdate.update({ myPrivateChat: secretChat })
      .then(() => {
        // DO NOTHING
      })
      .catch((error: any) => {
        console.log("Update secret chat error");
      });
  }

  // public restartPresenceSystem() {
  //   const uid: string = fromRoot.getState(this.store).character.myUID;
  //   this.logOutMyPresence(uid);
  //   this.setUpMyPresence(uid);
  // }

}
