import { PresenceService } from './../services/presence.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import * as layout from '../actions/layout';
import * as chat from '../actions/chat';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as fromRoot from './../reducers';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { CharacterSheetData } from '../models/data/application.data';
import { first, catchError, map } from 'rxjs/operators';
import { DebugLoggerService } from '../services/debug-logger.service';


@Injectable()
export class ChatEffects {

  private readonly moduleName: string = "ChatEffect";

  constructor(private actions$: Actions,
    public store: Store<fromRoot.State>,
    public presenceService: PresenceService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }


  @Effect({ dispatch: false })
  public SelectChatPlayer$: Observable<any> = this.actions$
    .pipe(
      ofType(chat.SELECT_CHAT_PLAYER),
      map((payload: any) => {
        let self = this;
        let userId: string = payload.payload;

        const selectedCharacterDoc: AngularFirestoreDocument<CharacterSheetData> = this.afs.doc<CharacterSheetData>('users/' + userId);
        const selectedCharacterDocObservable: Observable<CharacterSheetData> = selectedCharacterDoc.valueChanges();
        let characterSheetSubscription = selectedCharacterDocObservable.pipe(first()).subscribe((userData) => {
          // let characterSheetSubscription = this.firestore.read('users/' + userId).skip(1).subscribe((userData) => {
            if (self.debugLogger.isAuditing) {
              self.debugLogger.logRead(false, "READ user chat effect", self.moduleName, "users", 1);
            }

          characterSheetSubscription.unsubscribe();
          self.store.dispatch(new chat.SetSelectedChatPlayer(userData));
          self.store.dispatch(new layout.ToggleChatSchedaTatticaAction(true));
        });
      }),
      catchError((err: any) => {
        return of() as Observable<any>;
      }));

  @Effect({ dispatch: false })
  public SelectChat$: Observable<any> = this.actions$
    .pipe(
      ofType(chat.STORE_SELECTED_CHAT),
      map((payload: any) => {
        let chatId: string = payload.payload;
        const prevChat: string = fromRoot.getState(this.store).chat.prevChat;
        if (Functions.IsStringEmpty(chatId)) {
          // back to home
          this.presenceService.updateMyCurrentArea("@@HOME@@", prevChat)
        } else {
          // entering in a chat
          this.presenceService.updateMyCurrentArea(chatId, prevChat);
        }
      }),
      catchError((err: any) => {
        //TODO: gestione errori
        // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
        return of() as Observable<any>;
      }));

  // @Effect({ dispatch: false })
  // public GoToHomeEsterna$: Observable<any> = this.actions$
  //   .ofType(layout.GOTO_HOME_ESTERNA)
  //   .map((payload: any) => {
  //     this.routerService.navigate(['/esterna'], { skipLocationChange: true, queryParams: {} });
  //     this.location.replaceState("");
  //   })
  //   .catch((err: any) => {
  //     //TODO: gestione errori
  //     // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
  //     return Observable.of() as Observable<any>;
  //   });

  // @Effect({ dispatch: false })
  // public GoToCPanel$: Observable<any> = this.actions$
  //   .ofType(layout.GOTO_C_PANEL)
  //   .map((payload: any) => {
  //     this.routerService.navigate(['/controlAdminPanel'], { skipLocationChange: true, queryParams: {} });
  //     this.location.replaceState("");
  //   })
  //   .catch((err: any) => {
  //     //TODO: gestione errori
  //     // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
  //     return Observable.of() as Observable<any>;
  //   });

  // @Effect({ dispatch: false })
  // public openWorkspacesMenu$: any = this.actions$
  //   .ofType(layout.OPEN_WORKSPACES_MENU)
  //   .map((payload: any) => {

  //     if (fromRoot.getState(this.store).configuration.ExcelSyncingMode) {
  //       const allModeler: WorkbookInstance[] = fromRoot.getState(this.store).manageWorkbook.workbookInstances;
  //       const allWorkbooks: Workbook[] = fromRoot.getState(this.store).manageWorkbook.workbooks;

  //       if (allModeler.length === 1 && allWorkbooks.length === 1) {
  //         const allCustomization: Customization[] = fromRoot.getState(this.store).manageWorkbook.customizations;

  //         // if i have only one customization, this customization is the modeler one
  //         if (allCustomization.length === 1) {

  //           // update workspace accordingly, in order to open this
  //           let updatedWorkspace: Workspace = Object.assign({}, fromRoot.getState(this.store).manageWorkbook.workspace);
  //           if (Functions.IsNullOrUndefined(updatedWorkspace))
  //             updatedWorkspace = new Workspace();

  //           let currentCustomizationId: string = "";
  //           if (allCustomization[0].modelerCustomization == true) {
  //             currentCustomizationId = allCustomization[0].customizationId;
  //           }
  //           updatedWorkspace.workbookId = allModeler[0].workbookId;
  //           updatedWorkspace.customizationId = currentCustomizationId;
  //           updatedWorkspace.workbookInstanceKey = allModeler[0].workbookInstanceKey;
  //           updatedWorkspace.customizationName = allModeler[0].instanceName;


  //           this.store.dispatch(new manageWorkbook.SetCurrentWorkspaceAction(updatedWorkspace));
  //           //reset edit to false after selecting a new costumization/modeler
  //           this.store.dispatch(new edit.ToggleGalleryEditAction(false));
  //           this.store.dispatch(new edit.ToggleGridEditAction(false));
  //           this.store.dispatch(new edit.TogglePVEditAction(false));
  //           this.store.dispatch(new manageWorkbook.SelectWorkspaceAction(fromRoot.getState(this.store).configuration.ExcelRunningTokenKey));
  //         }
  //       }
  //       this.store.dispatch(new manageWorkbook.SetNeedToSaveChangesAction({ needToSaveChangesExcelSync: true, needToSaveChangesMetadata: false, needToSaveChangesFilters: false }));
  //     }
  //   })
  //   .catch((err: any) => {
  //     Functions.processSelectorsEffetctsError(this.ModuleName, "openWorkspacesMenu$", err);
  //     return Observable.of() as Observable<any>;
  //   });

}
