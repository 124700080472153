import { FirestoreService } from './../../../services/firestore.service';
import { CharacterSheetData, Race, Role, UserEntry, CharacterSkill, Clan, Corp, UserPresence } from './../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as characterSelector from './../../../selectors/character.selectors';
import * as dataSelector from './../../../selectors/datas.selectors';
import { Subscription } from 'rxjs';
import { CharacterSheetUIState } from '../../../modules/utilities/utilities.constants';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import * as layout from './../../../actions/layout';
import * as character from './../../../actions/character';
import { LogsService } from '../../../services/logs.service';
import { IncrementalService } from '../../../services/incremental.service';
import { map } from 'rxjs/operators';

// Utilities
@Component({
  selector: 'scheda-personaggio-new-component',
  templateUrl: './scheda-personaggio-new.component.html',
  styleUrls: ['./scheda-personaggio-new.component.less']
})

export class SchedaPersonaggioNewComponent implements OnInit, OnDestroy {
  private readonly moduleName: string = "SchedaPersonaggioComponent";
  public characterSheetUIState: CharacterSheetUIState = CharacterSheetUIState.characterSheet;
  public selectedCharacterSheet$: Observable<any>;
  public myRole$: Observable<Role>;
  public allClans$: Observable<Clan[]>;
  public allCorps$: Observable<Corp[]>;

  public giveAwayItemUID: string[] = [];
  public selectedItemUID: string[] = [];
  public selectedBMUID: string[] = [];
  public selectedSkillUID: string[] = [];

  public bckAccordionOpen: boolean = false;
  public mNoteAccordionOpen: boolean = true;

  public myCharacterSubscription: Subscription;
  // public race: string;
  // public signDate: string = "";
  // public access: string = "";

  public allDestinatari$: Observable<UserEntry[]>;
  public destinatario: UserEntry;
  public qta: number = 1;

  sheetSound: HTMLAudioElement = new Audio();
  public musicOn: boolean = false;
  public musicCanBeActivated: boolean = false;

  public getBuyableSkils$: Observable<any[]>;
  public isBuySkillPanelOpen: boolean = false;

  constructor(
    private store: Store<fromRoot.State>,
    private firestoreService: FirestoreService,
    private logsService: LogsService,
    private incrementalService: IncrementalService
  ) { }

  public ngOnInit() {
    let self = this;
    this.allDestinatari$ = this.store.select(dataSelector.getUsersList).pipe(map((users: UserEntry[]) => {
      let filteredUsers: UserEntry[] = users.filter((aUser: UserEntry) => aUser.uid != "@@@ALL@@@");
      filteredUsers = filteredUsers.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });
      return filteredUsers;
    }));

    this.selectedCharacterSheet$ = this.store.select(characterSelector.getComputedSelectedPlayer)
      .pipe(map((computedCharacterSheet: any) => {
        // set up last access
        const usersPresence: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
        const currentPresence: UserPresence = usersPresence.find((aUSer: UserPresence) => Functions.IsNullOrUndefined(aUSer.state) == false && aUSer.state.playerUID == computedCharacterSheet.baseData.uid);
        if (Functions.IsNullOrUndefined(currentPresence) == false) {
          computedCharacterSheet.baseData.access = currentPresence.state.lastAccess;
        }

        return computedCharacterSheet;
      }));

    this.myRole$ = this.store.select(characterSelector.getUserRole);
    this.allClans$ = this.store.select(dataSelector.getClansList);
    this.allCorps$ = this.store.select(dataSelector.getCorpsList);

    this.getBuyableSkils$ = this.store.select(characterSelector.getBuyableSkils);

    this.myCharacterSubscription = this.selectedCharacterSheet$.subscribe((value: any) => {
      // if (this.characterSheetUIState == CharacterSheetUIState.edit) {
      //   this.characterSheetUIState = CharacterSheetUIState.characterSheet;
      // }



      const mySheet: CharacterSheetData = value.baseData;
      if (Functions.IsStringEmpty(mySheet.music) == false && fromRoot.getState(self.store).character.myCharacterData.soundEnabled && Functions.IsStringEmpty(self.sheetSound.src) == true) {
        self.musicOn = true;
        self.musicCanBeActivated = true;
        self.sheetSound.src = mySheet.music; //URL.createObjectURL(mySheet.music) || mySheet.music;
        self.sheetSound.loop = true;
        self.sheetSound.load();

        if (Functions.IsNullOrUndefined(self.sheetSound) == false) {
          self.sheetSound.play()
            .catch((error: any) => {
              // just catch errors
            });
        }
      }
    })
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.myCharacterSubscription) == false)
      this.myCharacterSubscription.unsubscribe();
  }

  public navigate(goToState: CharacterSheetUIState) {
    this.characterSheetUIState = goToState;
    this.isBuySkillPanelOpen = false;
  }

  public selectSkill(uid: string) {
    if (Functions.IsNullOrUndefined(this.selectedSkillUID) == false && this.selectedSkillUID[uid] == uid) {
      // this.selectedSkillUID = this.selectedSkillUID.filter((aValue: string) => aValue != uid);
      this.selectedSkillUID[uid] = undefined;
    } else {
      this.selectedSkillUID[uid] = uid;
    }
  }

  public selectItem(uid: string) {
    if (Functions.IsNullOrUndefined(this.selectedItemUID) == false && this.selectedItemUID[uid] == uid) {
      // this.selectedItemUID = this.selectedItemUID.filter((aValue: string) => aValue != uid);
      this.selectedItemUID[uid] = undefined;
    } else {
      this.selectedItemUID[uid] = uid;
    }
  }

  public giveAwayItem(uid: string) {
    if (Functions.IsNullOrUndefined(this.giveAwayItemUID) == false && this.giveAwayItemUID[uid] == uid) {
      // this.selectedItemUID = this.selectedItemUID.filter((aValue: string) => aValue != uid);
      this.giveAwayItemUID[uid] = undefined;
    } else {
      this.giveAwayItemUID[uid] = uid;
    }
  }

  public selectBM(uid: string) {
    if (Functions.IsNullOrUndefined(this.selectedBMUID) == false && this.selectedBMUID[uid] == uid) {
      // this.selectedBMUID = this.selectedBMUID.filter((aValue: string) => aValue != uid);
      this.selectedBMUID[uid] = undefined;
    } else {
      this.selectedBMUID[uid] = uid;
    }
  }

  public backToHomeInterna() {
    try {
      this.sheetSound.pause();
    } catch (err) {
      // audio not yet loaded, so just do nothing
    }

    this.sheetSound = new Audio();
    this.sheetSound.src = "";
    this.store.dispatch(new character.SetSelectedPlayer(new CharacterSheetData()));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: false, doNotClosePresence: true, doNotCloseAnagrafica: true, doNotCloseVolti: true, doNotCloseStaff: true, doNotCloseRazze: true, doNotCloseClan: true, doNotCloseCorp: true }));
  }

  public toggleAccordionMaster() {
    this.mNoteAccordionOpen = !this.mNoteAccordionOpen;
  }

  public toggleAccordionBck() {
    this.bckAccordionOpen = !this.bckAccordionOpen;
  }

  public giveAwayConfirm(item: any) {
    if (Functions.IsNullOrUndefined(this.destinatario) || Functions.IsNullOrUndefined(this.qta))
      return;

    this.firestoreService.transferObj(this.destinatario, item.uid, this.qta);
    this.destinatario = undefined;
    this.qta = 1;
  }

  public togleBuySkillPanel() {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getBuyableSkillsCollection(true);
    this.isBuySkillPanelOpen = !this.isBuySkillPanelOpen;
  }

  public buySkill(event: any, skill: any) {
    event.preventDefault();
    event.stopPropagation();

    const myCharacterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    const userSkills = Object.assign([], myCharacterSheet.userSkills || []);

    // updating exp
    let moundaineSkillLVL: string = "";
    const oldValueXP: number = myCharacterSheet.usablePx;
    myCharacterSheet.usablePx = myCharacterSheet.usablePx - skill.cost;

    // for mondano (can be a new skill or an old one updated)
    let updatedSkill: CharacterSkill = myCharacterSheet.userSkills.find((aCharacterSkill: CharacterSkill) => aCharacterSkill.uid == skill.uid);

    //check if it is a new skill
    if (Functions.IsNullOrUndefined(updatedSkill) == true) {
      // undefined so it's a new skill (if i'm not a mondano it's always a new skill)
      let newCharacterSkill: CharacterSkill = new CharacterSkill();
      newCharacterSkill.uid = skill.uid;
      newCharacterSkill.sLv = 1;
      userSkills.push(newCharacterSkill);

    } else {
      // not undefined so it's a skill already existing
      if (Functions.IsNullOrUndefined(updatedSkill.sLv) == true || Functions.IsStringEmpty(updatedSkill.sLv.toString()) == true || updatedSkill.sLv <= 0) {
        updatedSkill.sLv = 1;
      }

      updatedSkill.sLv = updatedSkill.sLv + 1;
      moundaineSkillLVL = "(livello " + updatedSkill.sLv + ")";
    }

    // close skill panel aftr buying
    this.selectedSkillUID[skill.uid] = undefined;

    // updating sheet
    myCharacterSheet.userSkills = JSON.parse(JSON.stringify(userSkills));
    this.firestoreService.updateCharacterSheet(myCharacterSheet.uid, myCharacterSheet, false);

    // updating logs
    this.logsService.logXP("", myCharacterSheet.name, (oldValueXP || 0).toString(), "-" + skill.cost, "Acquisto abilità - " + skill.name + moundaineSkillLVL);
  }

  public toggleMusic() {
    if (this.musicCanBeActivated == true) {
      this.musicOn = !this.musicOn;

      if (this.musicOn == true) {
        this.sheetSound.play();
      } else {
        this.sheetSound.pause();
      }
    }
  }
}
