import { StandardChat, UserPresence, CharacterSheetData, DBVersionType } from './../../../models/data/application.data';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import * as layout from './../../../actions/layout';
import * as chat from './../../../actions/chat';
import { MapState, LayoutState } from '../../../modules/utilities/utilities.constants';
import { chatGroups } from '../../../models/data/application.data';
import { FirestoreService } from '../../../services/firestore.service';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { PresenceService } from '../../../services/presence.service';
import { IncrementalService } from '../../../services/incremental.service';

// Utilities
@Component({
  selector: 'maps-component',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.less']
})

export class MapsComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly moduleName: string = "MapsComponent";

  public mapUIState$: Observable<MapState>;
  public standardChatsList$: Observable<StandardChat[]>;
  public mapSelectedArea$: Observable<chatGroups>;

  public mapImageClass: number = 0;
  public mapSubscription: Subscription;

  mainMapContainerMaxWidth: number = 0;
  mainMapContainerHeight: number = 0;
  @ViewChild('mainMapCont', { static: false }) private mainMapContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private firestoreService: FirestoreService,
    private cdr: ChangeDetectorRef,
    private presenceService: PresenceService,
    private incrementalService: IncrementalService
  ) {

  }

  public ngOnInit() {
    this.mapUIState$ = this.store.select(layoutSelector.getMapUIState);
    this.standardChatsList$ = this.store.select(layoutSelector.getStandardChatsList);
    this.mapSelectedArea$ = this.store.select(layoutSelector.getMapSelectedArea);
    // this.firestoreService.getStandardChatsList();
    // this.firestoreService.getRules();
    // this.firestoreService.getClans();
    // this.firestoreService.getCorps();
    this.firestoreService.getStaffNews();
    this.firestoreService.getRaceIcons();
    // this.firestoreService.getLocations();

    let self: this = this;
    this.mapSubscription = this.mapUIState$.subscribe((aNewState) => {
      self.mapImageClass = 0;
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.mapSubscription) == false)
      this.mapSubscription.unsubscribe();
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.onResize();
    }, 0);
  }

  goWest() {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.standardChats, true);
    this.store.dispatch(new layout.UpdateMapStateAction(MapState.westLA));
  }

  goEast() {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.standardChats, true);
    this.store.dispatch(new layout.UpdateMapStateAction(MapState.eastLA));
  }

  setSelection(selectedArea: number) {
    if (selectedArea == fromRoot.getState(this.store).layout.mapSelectedArea) {
      this.store.dispatch(new layout.SetMapSelectedAreaAction(undefined));
    } else {
      this.store.dispatch(new layout.SetMapSelectedAreaAction(selectedArea));
    }
  }

  goToChat(uid: string) {

    //#region - avoid double chat
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    const allPresenceStatus: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const myPresenceStatus: UserPresence = allPresenceStatus.find((aUser: UserPresence) => Functions.IsNullOrUndefined(aUser.state) === false && aUser.state.playerUID == characterSheet.uid);

    // Safety Check
    if (Functions.IsNullOrUndefined(myPresenceStatus) || Functions.IsNullOrUndefined(myPresenceStatus.connections))
      return;

    // let's normalize area
    let areasAsArray = Object.keys(myPresenceStatus.connections).map(function (key) {
      return myPresenceStatus.connections[key];
    });
    const removeDouble: Set<string> = new Set(areasAsArray);
    areasAsArray = Array.from(removeDouble.values());

    if (areasAsArray.length > 1) {
      // more then one tab open in different areas
      alert("Sei già in una chat, non puoi entrare in due chat contemporaneamente. Se pensi vi sia un errore contatta lo staff.");
      return;
    }
    //#endregion


    this.store.dispatch(new chat.StoreSelectedChat(uid));
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Chat));
  }

  over(area: number) {
    this.mapImageClass = area;
  }

  out() {
    this.mapImageClass = 0;
  }

  public onResize(event?: any) {
    let windowsHeight = window.innerHeight;
    let windowsWidth = window.innerWidth;

    // let height: number = this.mainMapContainer.nativeElement.offsetHeight;
    let height: number = (windowsWidth / 100) * 33;
    // let width: number = this.mainMapContainer.nativeElement.offsetWidth;
    let parentHeight: number = this.mainMapContainer.nativeElement.parentElement.offsetHeight;

    if (height > parentHeight)
      height = parentHeight;

    if (height > 600)
      height = 600;

    if (height < 200)
      height = 200;

    let maxWidth = height * 1.833;
    if (maxWidth > 1100)
      maxWidth = 1110;

    this.mainMapContainerMaxWidth = maxWidth;
    this.mainMapContainerHeight = height;
    this.cdr.detectChanges();
  }

}
