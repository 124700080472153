import { TacticalSheetData } from './../../models/chat-message.models';
import { CharacterSheetData, Stats, Role } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { Observable, Subscription } from "rxjs";
import { ChatMessage, OnlineUser, ChatSelectStatsUI, CharacterState, DiceType } from '../../models/chat-message.models';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as chatSelector from './../../../../selectors/chat.selectors';
import * as characterSelector from './../../../../selectors/character.selectors';
import * as layout from './../../../../actions/layout';
import * as character from './../../../../actions/character';
import { Functions } from '../../../utilities/functions/utilities.functions';


@Component({
  selector: 'chat-scheda-tattica',
  templateUrl: './chat-scheda-tattica.component.html',
  styleUrls: ['./chat-scheda-tattica.component.css']
})
export class ChatSchedaTatticaComponent implements OnInit, OnDestroy, AfterViewInit {

  // @ViewChild('stCont') private stContainer: ElementRef;

  tacticalSheetData$: Observable<TacticalSheetData>;
  isGm$: Observable<Role>;
  tacticalSheetSubscription: Subscription;
  tacticalSheetData: TacticalSheetData;
  isEditing: boolean = false;
  aggiornaForm = new FormGroup({});

  containerMaxWidth: number = 0;
  avatarHeight: number = 230;

  constructor(private chat: ChatService, private store: Store<fromRoot.State>, private fb: FormBuilder, private cd: ChangeDetectorRef) { }

  ngOnInit() {

    this.tacticalSheetData$ = this.store.select(chatSelector.getTactialSheetData);
    this.isGm$ = this.store.select(characterSelector.getUserRole);

    let self: this = this;
    this.tacticalSheetSubscription = this.tacticalSheetData$.subscribe((tacticalSheet: TacticalSheetData) => {
      this.tacticalSheetData = tacticalSheet;

      if (Functions.IsStringEmpty(self.tacticalSheetData.id)) {
        setTimeout(() => {
          self.closeBox();
          self.cd.detectChanges();
        }, 0);
      }
    });

  }

  public ngAfterViewInit() {
    this.onResize();
  }


  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.tacticalSheetSubscription) == false)
      this.tacticalSheetSubscription.unsubscribe();
  }


  closeBox() {
    this.store.dispatch(new layout.ToggleChatSchedaTatticaAction());
  }

  goToSchedaPG() {
    this.store.dispatch(new character.SelectplayerID(this.tacticalSheetData.id));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
    this.store.dispatch(new layout.ToggleChatSchedaTatticaAction(false));
  }

  saveSchedaTattica() {
    //check if something was changed
    if (this.aggiornaForm.dirty == false) {
      this.isEditing = false;
      return
    }

    if (this.aggiornaForm.valid == false) {
      Object.keys(this.aggiornaForm.controls).forEach(field => {
        const control = this.aggiornaForm.get(field);
        if (control.valid == false) {
          control.markAsDirty({ onlySelf: true });
        }
      });
    } else {
      const uid: string = this.tacticalSheetData.id;
      let myCharacterState: CharacterState = fromRoot.getState(this.store).chat.chatCharactersState.find((aCharacterState: CharacterState) => aCharacterState.uid == uid);
      if (Functions.IsNullOrUndefined(myCharacterState)) {
        // create a new state
        myCharacterState = new CharacterState(uid, [], new Stats(), [], new Stats());
      } else {
        myCharacterState = Object.assign({}, myCharacterState);
      }

      //#region - items
      if (Functions.IsStringEmpty(this.aggiornaForm.value['strI'])) {
        myCharacterState.itemsBonusStats.str = 0;
      } else {
        myCharacterState.itemsBonusStats.str = parseInt(this.aggiornaForm.value['strI']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['aglI'])) {
        myCharacterState.itemsBonusStats.agl = 0;
      } else {
        myCharacterState.itemsBonusStats.agl = parseInt(this.aggiornaForm.value['aglI']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['perI'])) {
        myCharacterState.itemsBonusStats.per = 0;
      } else {
        myCharacterState.itemsBonusStats.per = parseInt(this.aggiornaForm.value['perI']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['resI'])) {
        myCharacterState.itemsBonusStats.res = 0;
      } else {
        myCharacterState.itemsBonusStats.res = parseInt(this.aggiornaForm.value['resI']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['wsdI'])) {
        myCharacterState.itemsBonusStats.wsd = 0;
      } else {
        myCharacterState.itemsBonusStats.wsd = parseInt(this.aggiornaForm.value['wsdI']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['wilI'])) {
        myCharacterState.itemsBonusStats.wil = 0;
      } else {
        myCharacterState.itemsBonusStats.wil = parseInt((this.aggiornaForm.value['wilI']));
      }
      //#endregion

      //#region - skills
      if (Functions.IsStringEmpty(this.aggiornaForm.value['strS'])) {
        myCharacterState.skillsBonusStats.str = 0;
      } else {
        myCharacterState.skillsBonusStats.str = parseInt(this.aggiornaForm.value['strS']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['aglS'])) {
        myCharacterState.skillsBonusStats.agl = 0;
      } else {
        myCharacterState.skillsBonusStats.agl = parseInt(this.aggiornaForm.value['aglS']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['perS'])) {
        myCharacterState.skillsBonusStats.per = 0;
      } else {
        myCharacterState.skillsBonusStats.per = parseInt(this.aggiornaForm.value['perS']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['resS'])) {
        myCharacterState.skillsBonusStats.res = 0;
      } else {
        myCharacterState.skillsBonusStats.res = parseInt(this.aggiornaForm.value['resS']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['wsdS'])) {
        myCharacterState.skillsBonusStats.wsd = 0;
      } else {
        myCharacterState.skillsBonusStats.wsd = parseInt(this.aggiornaForm.value['wsdS']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['wilS'])) {
        myCharacterState.skillsBonusStats.wil = 0;
      } else {
        myCharacterState.skillsBonusStats.wil = parseInt(this.aggiornaForm.value['wilS']);
      }
      //#endregion

      //updateMyState
      this.chat.updateCharacterState(uid, myCharacterState);

      //#region - salute
      let sal: number = 0;
      let salM: number = 0;
      if (Functions.IsStringEmpty(this.aggiornaForm.value['sal'])) {
        sal = 0;
      } else {
        sal = parseInt(this.aggiornaForm.value['sal']);
      }

      if (Functions.IsStringEmpty(this.aggiornaForm.value['salM'])) {
        salM = 0;
      } else {
        salM = parseInt(this.aggiornaForm.value['salM']);
      }
      //#endregion
      this.chat.changeHealth(uid, sal, salM);
      this.isEditing = false;
    }
  }

  editSchedaTattica() {
    this.isEditing = true;
    this.buildForm();
  }

  // used to create form structure
  buildForm() {
    this.aggiornaForm = this.fb.group({
      'strS': [this.tacticalSheetData.statsBonusSkill.str.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'strI': [this.tacticalSheetData.statsBonusItems.str.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'aglS': [this.tacticalSheetData.statsBonusSkill.agl.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'aglI': [this.tacticalSheetData.statsBonusItems.agl.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'resS': [this.tacticalSheetData.statsBonusSkill.res.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'resI': [this.tacticalSheetData.statsBonusItems.res.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'wsdS': [this.tacticalSheetData.statsBonusSkill.wsd.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'wsdI': [this.tacticalSheetData.statsBonusItems.wsd.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'perS': [this.tacticalSheetData.statsBonusSkill.per.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'perI': [this.tacticalSheetData.statsBonusItems.per.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'wilS': [this.tacticalSheetData.statsBonusSkill.wil.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'wilI': [this.tacticalSheetData.statsBonusItems.wil.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]],
      'sal': [this.tacticalSheetData.health.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.max(100)
      ]],
      'salM': [this.tacticalSheetData.mindHealth.toString(), [
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.max(100)
      ]]
    });
  }

  public onResize(event?: any) {
    // let height = this.stContainer.nativeElement.offsetHeight;
    // let width = this.stContainer.nativeElement.offsetWidth;
    // let maxWidht = height * 6.175;

    const windowsWidth = window.innerWidth;
    let maxWidth: number = (windowsWidth / 100) * 40;

    // const windowsHeight = window.innerHeight;
    // let maxHeight: number = (windowsHeight / 100) * 37;
    if (maxWidth <= 510)
      maxWidth = 510;

    if (maxWidth > 755)
      maxWidth = 755;




    this.containerMaxWidth = maxWidth;
    let avatrWidth: number = ((maxWidth - 40) / 100) * 44;
    if (avatrWidth > 315)
      avatrWidth = 315;

    this.avatarHeight = avatrWidth / 1.37;
    this.cd.detectChanges();
  }

}
