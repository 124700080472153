import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { BaseAction } from '../actions/baseAction';
import * as layout from '../actions/layout';
import { Functions } from '../modules/utilities/functions/utilities.functions';
//import { GetConfigurationService } from '../services/api/get-configuration.service';
import * as fromRoot from './../reducers';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class LayoutEffects {

  private readonly ModuleName: string = "LayoutEffect";

  constructor(private actions$: Actions,
    public store: Store<fromRoot.State>,
    public routerService: Router,
    public location: Location
  ) { }


  @Effect({ dispatch: false })
  public GoToHomeInterna$: Observable<any> = this.actions$
    .pipe(
      ofType(layout.GOTO_HOME_INTERNA),
      map((payload: any) => {
        this.routerService.navigate(['/interna'], { skipLocationChange: true, queryParams: {} });
        this.location.replaceState("");
      }),
      catchError((err: any) => {
        //TODO: gestione errori
        // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
        return of() as Observable<any>;
      }));

  @Effect({ dispatch: false })
  public GoToHomeEsterna$: Observable<any> = this.actions$
    .pipe(
      ofType(layout.GOTO_HOME_ESTERNA),
      map((payload: any) => {
        this.routerService.navigate(['/esterna'], { skipLocationChange: true, queryParams: {} });
        this.location.replaceState("");
      }),
      catchError((err: any) => {
        //TODO: gestione errori
        // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
        return of() as Observable<any>;
      }));

  @Effect({ dispatch: false })
  public GoToLogOut$: Observable<any> = this.actions$
    .pipe(
      ofType(layout.GOTO_LOGOUT),
      map((payload: any) => {
        this.routerService.navigate(['/logout'], { skipLocationChange: true, queryParams: {} });
        this.location.replaceState("");
      }),
      catchError((err: any) => {
        //TODO: gestione errori
        // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
        return of() as Observable<any>;
      }));

  @Effect({ dispatch: false })
  public GoToCPanel$: Observable<any> = this.actions$
    .pipe(
      ofType(layout.GOTO_C_PANEL),
      map((payload: any) => {
        this.routerService.navigate(['/controlAdminPanel'], { skipLocationChange: true, queryParams: {} });
        this.location.replaceState("");
      }),
      catchError((err: any) => {
        //TODO: gestione errori
        // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
        return of() as Observable<any>;
      }));

  @Effect({ dispatch: false })
  public GoToRPanel$: Observable<any> = this.actions$
    .pipe(
      ofType(layout.GOTO_R_PANEL),
      map((payload: any) => {
        this.routerService.navigate(['/restoreBackupPanel'], { skipLocationChange: true, queryParams: {} });
        this.location.replaceState("");
      }),
      catchError((err: any) => {
        //TODO: gestione errori
        // Functions.processSelectorsEffetctsError(this.ModuleName, "openMenu$", err);
        return of() as Observable<any>;
      }));

  // @Effect({ dispatch: false })
  // public openWorkspacesMenu$: any = this.actions$
  //   .ofType(layout.OPEN_WORKSPACES_MENU)
  //   .map((payload: any) => {

  //     if (fromRoot.getState(this.store).configuration.ExcelSyncingMode) {
  //       const allModeler: WorkbookInstance[] = fromRoot.getState(this.store).manageWorkbook.workbookInstances;
  //       const allWorkbooks: Workbook[] = fromRoot.getState(this.store).manageWorkbook.workbooks;

  //       if (allModeler.length === 1 && allWorkbooks.length === 1) {
  //         const allCustomization: Customization[] = fromRoot.getState(this.store).manageWorkbook.customizations;

  //         // if i have only one customization, this customization is the modeler one
  //         if (allCustomization.length === 1) {

  //           // update workspace accordingly, in order to open this
  //           let updatedWorkspace: Workspace = Object.assign({}, fromRoot.getState(this.store).manageWorkbook.workspace);
  //           if (Functions.IsNullOrUndefined(updatedWorkspace))
  //             updatedWorkspace = new Workspace();

  //           let currentCustomizationId: string = "";
  //           if (allCustomization[0].modelerCustomization == true) {
  //             currentCustomizationId = allCustomization[0].customizationId;
  //           }
  //           updatedWorkspace.workbookId = allModeler[0].workbookId;
  //           updatedWorkspace.customizationId = currentCustomizationId;
  //           updatedWorkspace.workbookInstanceKey = allModeler[0].workbookInstanceKey;
  //           updatedWorkspace.customizationName = allModeler[0].instanceName;


  //           this.store.dispatch(new manageWorkbook.SetCurrentWorkspaceAction(updatedWorkspace));
  //           //reset edit to false after selecting a new costumization/modeler
  //           this.store.dispatch(new edit.ToggleGalleryEditAction(false));
  //           this.store.dispatch(new edit.ToggleGridEditAction(false));
  //           this.store.dispatch(new edit.TogglePVEditAction(false));
  //           this.store.dispatch(new manageWorkbook.SelectWorkspaceAction(fromRoot.getState(this.store).configuration.ExcelRunningTokenKey));
  //         }
  //       }
  //       this.store.dispatch(new manageWorkbook.SetNeedToSaveChangesAction({ needToSaveChangesExcelSync: true, needToSaveChangesMetadata: false, needToSaveChangesFilters: false }));
  //     }
  //   })
  //   .catch((err: any) => {
  //     Functions.processSelectorsEffetctsError(this.ModuleName, "openWorkspacesMenu$", err);
  //     return Observable.of() as Observable<any>;
  //   });

}
