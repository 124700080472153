import { CharacterSheetData, Role, UserPresence } from './../models/data/application.data';
import * as character from '../actions/character';
import { Functions } from '../modules/utilities/functions/utilities.functions';

export interface State {
  myCharacterData: CharacterSheetData;
  isAuthenticated: boolean;
  myUID: string;
  myAuthenticationData: any;

  currentSelectedCharacter: CharacterSheetData;

  usersPresence: UserPresence[];
}

export const initialState: State = {
  myCharacterData: undefined,
  isAuthenticated: false,
  myUID: "",
  myAuthenticationData: undefined,

  currentSelectedCharacter: undefined,

  usersPresence: []
};

export function reducer(state: State = initialState, action: character.Actions): State {
  switch (action.type) {

    case character.STORE_AUTH_DATA:
      const authPayload: any = action.payload as any;
      return Object.assign({}, state, {
        myAuthenticationData: authPayload
      });

    case character.STORE_USER_DATA:
      const payload: any = action.payload as any;
      return Object.assign({}, state, {
        myCharacterData: payload.myCharacter,
        isAuthenticated: payload.isAuthenticated,
        myUID: payload.uid
      });

    case character.LOG_OUT_CLEAN:
      return Object.assign({}, state, {
        myCharacterData: undefined,
        isAuthenticated: false,
        myUID: "",
        myAuthenticationData: undefined,
      });

    case character.SET_SELECTED_PLAYER:
      const selectedPlayer: CharacterSheetData = action.payload as CharacterSheetData;
      return Object.assign({}, state, {
        currentSelectedCharacter: selectedPlayer
      });

    case character.STORE_USERS_PRESENCE:
      const usersPresence: UserPresence[] = action.payload as UserPresence[];
      return Object.assign({}, state, {
        usersPresence: usersPresence
      });

    default: {
      return state;
    }
  }
}

export const getCharacterState: (state: State) => CharacterSheetData = (state: State) => state.myCharacterData;
export const getUserRole: (state: State) => Role = (state: State) => {
  if (Functions.IsNullOrUndefined(state.myCharacterData)) {
    return Role.player
  } else {
    return state.myCharacterData.role;
  }
};
export const getIsCapoClan: (state: State) => boolean = (state: State) => {
  if (Functions.IsNullOrUndefined(state.myCharacterData)) {
    return false;
  } else {
    return state.myCharacterData.isCapoClan;
  }
};
export const getIsCapoCorp: (state: State) => boolean = (state: State) => {
  if (Functions.IsNullOrUndefined(state.myCharacterData)) {
    return false;
  } else {
    return state.myCharacterData.isCapoCorp;
  }
};
export const getIsCapoRace: (state: State) => boolean = (state: State) => {
  if (Functions.IsNullOrUndefined(state.myCharacterData) || Functions.IsNullOrUndefined(state.myCharacterData.isCapoRace)) {
    return false;
  } else {
    return state.myCharacterData.isCapoRace.length > 0;
  }
};
export const getSelectedPlayer: (state: State) => CharacterSheetData = (state: State) => state.currentSelectedCharacter;
export const getUsersPresence: (state: State) => UserPresence[] = (state: State) => state.usersPresence;
export const getMyUID: (state: State) => string = (state: State) => state.myUID;
export const getMyAuthData: (state: State) => any = (state: State) => state.myAuthenticationData;
