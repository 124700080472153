import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { DraggableModule } from '../draggable';

@NgModule({
  imports: [
    CommonModule,
    DraggableModule,
  ],
  declarations: [
    ModalComponent,
  ],
  exports: [
    ModalComponent,
  ],
  providers: []
})
export class ModalModule { }
