import { VoltoUI, PresencePlayerStatus } from './../../../models/data/application.data';
import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';

@Pipe({ name: 'filterAnagraficaPipe' })
export class FilterAnagraficaPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allUserAnagrafica: any[], letterSelected: string) {
    if (Functions.IsNullOrUndefined(allUserAnagrafica) || allUserAnagrafica.length <= 0 || Functions.IsStringEmpty(letterSelected)) {
      return [];
    } else {

      let toReturn = allUserAnagrafica.filter((aUser: any) => (Functions.IsStringEmpty(aUser.name) == false));
      if (letterSelected != "#" && letterSelected != "PNG") {
        toReturn = toReturn.filter((aUser: any) => (aUser.isPng === false) && (Functions.IsStringEmpty(aUser.name) == false) && ((aUser.name as string)[0].toLowerCase() == letterSelected));
      }

      if (letterSelected === "PNG") {
        toReturn = toReturn.filter((aUser: any) => aUser.isPng === true);
      }

      if(letterSelected === "#") {
        toReturn = toReturn.filter((aUser: any) => aUser.isPng === false);
      }

      toReturn = toReturn.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return toReturn;
    }
  }
}



@Pipe({ name: 'filterAnagraficaVoltiPipe' })
export class FilterAnagraficaVoltiPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allVoltiAnagrafica: any, type: number, letterSelected: string, sortBy: number) {
    if (Functions.IsNullOrUndefined(allVoltiAnagrafica) || type <= 0 || Functions.IsStringEmpty(letterSelected)) {
      return [];
    } else {

      let toReturn: VoltoUI[] = [];
      if (type == 2)
        toReturn = allVoltiAnagrafica.voltiMaschili;
      else if (type == 1)
        toReturn = allVoltiAnagrafica.voltiFemminili;

      // remove empty volti
      toReturn = toReturn.filter((aVolto: VoltoUI) => (Functions.IsStringEmpty(aVolto.uidPG) == false || Functions.IsStringEmpty(aVolto.pgName) == false));

      if (letterSelected != "#") {
        if (sortBy == 0)
          toReturn = toReturn.filter((aVolto: VoltoUI) => (Functions.IsStringEmpty(aVolto.pgName)) || ((aVolto.pgName as string)[0].toLowerCase() == letterSelected));
        else if (sortBy == 1)
          toReturn = toReturn.filter((aVolto: VoltoUI) => (Functions.IsStringEmpty(aVolto.vName)) || ((aVolto.vName as string)[0].toLowerCase() == letterSelected));
        else if (sortBy == 2)
          toReturn = toReturn.filter((aVolto: VoltoUI) => (Functions.IsStringEmpty(aVolto.vSur)) || ((aVolto.vSur as string)[0].toLowerCase() == letterSelected));
      }

      toReturn = toReturn.sort(function (a, b) {
        if (sortBy == 0)
          var nameA = a.pgName.toLowerCase(), nameB = b.pgName.toLowerCase();
        else if (sortBy == 1)
          var nameA = a.vName.toLowerCase(), nameB = b.vName.toLowerCase();
        else if (sortBy == 2)
          var nameA = a.vSur.toLowerCase(), nameB = b.vSur.toLowerCase();

        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return toReturn;
    }
  }
}

@Pipe({ name: 'filterRacePipe' })
export class FilterRacePipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allUserAnagrafica: any[], selectedRace: any) {
    if (Functions.IsNullOrUndefined(allUserAnagrafica) || allUserAnagrafica.length <= 0 || Functions.IsNullOrUndefined(selectedRace)) {
      return [];
    } else {

      let toReturn = allUserAnagrafica.filter((aUser: any) => (Functions.IsNullOrUndefined(aUser.race) == false) && (aUser.race == selectedRace.key))
      toReturn = toReturn.sort(function (a, b) {
        // var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        var lvlA = a.lvl, lvlB = b.lvl;
        if (lvlA < lvlB) //sort string ascending
          return 1;
        if (lvlA > lvlB)
          return -1;
        return 0; //default return value (no sorting)
      });

      return toReturn;
    }
  }
}

@Pipe({ name: 'filterClanPipe' })
export class FilterClanPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allUserAnagrafica: PresencePlayerStatus[], selectedClan: string) {
    if (Functions.IsNullOrUndefined(allUserAnagrafica) || allUserAnagrafica.length <= 0 || Functions.IsNullOrUndefined(selectedClan)) {
      return [];
    } else {

      let toReturn = allUserAnagrafica.filter((aUser: any) => (Functions.IsNullOrUndefined(aUser.clan) == false) && (aUser.clan == selectedClan))
      toReturn = toReturn.sort(function (a, b) {
        // var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        var lvlA = a.clanLvl, lvlB = b.clanLvl;
        if (lvlA < lvlB) //sort string ascending
          return -1;
        if (lvlA > lvlB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return toReturn;
    }
  }
}

@Pipe({ name: 'filterCorpPipe' })
export class FilterCorpPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allUserAnagrafica: PresencePlayerStatus[], selectedCorp: string) {
    if (Functions.IsNullOrUndefined(allUserAnagrafica) || allUserAnagrafica.length <= 0 || Functions.IsNullOrUndefined(selectedCorp)) {
      return [];
    } else {

      let toReturn = allUserAnagrafica.filter((aUser: any) => (Functions.IsNullOrUndefined(aUser.corp) == false) && (aUser.corp == selectedCorp))
      toReturn = toReturn.sort(function (a, b) {
        // var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        var lvlA = a.corpLvl, lvlB = b.corpLvl;
        if (lvlA < lvlB) //sort string ascending
          return -1;
        if (lvlA > lvlB)
          return 1;
        return 0; //default return value (no sorting)
      });

      return toReturn;
    }
  }
}
