import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Volto } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { PresenceService } from '../../../../services/presence.service';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-cancella-personaggio',
  templateUrl: 'cPanel-cancella-personaggio.component.html',
  styleUrls: ['cPanel-cancella-personaggio.component.less'],
})
export class CPanelCancellaPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelCancellaPersonaggio";
  private usersCollection: AngularFirestoreCollection<CharacterSheetData>;
  private usersCollectionObservable: Observable<CharacterSheetData[]>;
  private usersSubscription: Subscription;
  public dataSource: LocalDataSource;
  public settings = {
    actions: {
      add: false,
      edit: false,
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    // add: false,
    // edit: false,
    // delete: {
    //   deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
    //   confirmDelete: true,
    // },
    columns: {
      name: {
        title: 'Nome Personaggio'
      },
      access: {
        title: 'Ultimo Accesso'
      },
      signDate: {
        title: 'Data Registrazione'
      },
      mail: {
        title: 'mail'
      },
      uid: {
        title: 'id'
      }
    }
  };


  constructor(
    private toastr: ToastrService,
    private presenceService: PresenceService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    this.usersCollection = this.afs.collection<CharacterSheetData>('users');
    this.usersCollectionObservable = this.usersCollection.valueChanges();
    this.usersSubscription = this.usersCollectionObservable.subscribe((data: CharacterSheetData[]) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all users cpanel", self.moduleName, "users", (data as any[]).length);
      }

      let tableData: any[] = [];
      for (let index: number = 0; index < data.length; index++) {
        tableData.push({ uid: data[index].uid, name: data[index].name, sur: data[index].sur, access: data[index].access, signDate: data[index].signDate, mail: data[index].mail });
      }
      self.dataSource.load(tableData);
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
      this.usersSubscription.unsubscribe();
    }
  }

  onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare il personaggio selezionato?')) {
      event.confirm.resolve();
      // remove on firebase
      // this.firestore.remove('items/' + event.data.uid);
      let self = this;
      const usedId: string = event.data.uid;
      const userName: string = event.data.name;
      const userMail: string = event.data.mail;

      //#region - updatind DB
      const playerToRemoveDoc: AngularFirestoreDocument<CharacterSheetData> = this.afs.doc<any>('users/' + usedId);
      playerToRemoveDoc.delete()
        .then(() => {
          self.presenceService.deletePresenceState(usedId);
          const usernameToRemoveDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('listOfUserNames/' + (userName as string).toLowerCase());
          usernameToRemoveDoc.delete();
          self.toastr.success('Personaggio Eliminato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });
      //#endregion - updatind DB

      //#region - delete volto if any
      const voltiCollection: AngularFirestoreCollection<Volto> = this.afs.collection<Volto>('volti', ref => ref.where('uidPG', '==', usedId));
      const voltiCollectionObservable: Observable<Volto[]> = voltiCollection.valueChanges();
      const voltiSubscription: Subscription = voltiCollectionObservable.pipe(first())
        .subscribe((data: Volto[]) => {
          // data is not empty the character had an assigned volto
          if (Functions.IsNullOrUndefined(data) == false && data.length > 0) {
            const voltoId: string = data[0].uid;

            const voltoToRemoveDoc: AngularFirestoreDocument<Volto> = self.afs.doc<any>('volti/' + voltoId);
            voltoToRemoveDoc.delete()
              .then(() => {
                // OK
              })
              .catch((error: any) => {
                console.log("Errore eliminato volto associato al personaggio");
              });
          }
        });
      //#endregion - delete volto if any

      //#region - add UID and EMAIL to the list of users to remove
      let userToDeleteInfo = JSON.parse(JSON.stringify({ uid: usedId, name: userName, mail: userMail }));
      const userToDeleteCollection = self.afs.collection<CharacterSheetData>('usersToDelete');
      userToDeleteCollection.doc(usedId).set(userToDeleteInfo);
      //#endregion - add UID and EMAIL to the list of users to remove


      // this.firestore.remove('users/' + usedId).subscribe(() => {
      //   // self.firestore.remove('listOfUser/' + event.data.uid);
      //   self.presenceService.deletePresenceState(usedId);
      //   self.firestore.remove('listOfUserNames/' + (userName as string).toLowerCase());
      //   self.toastr.success('Personaggio Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

}
