import { LayoutState } from './../modules/utilities/utilities.constants';
import { LogPX, LogOgg, LogBank } from './../models/data/application.data';
import { Functions } from './../modules/utilities/functions/utilities.functions';

import * as fromRoot from '../reducers';
import { Store } from '@ngrx/store';

import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

@Injectable()
export class LogsService {

  private logXPCollection: AngularFireList<LogPX>;
  private logOggCollection: AngularFireList<LogOgg>;
  private logBankCollection: AngularFireList<LogBank>;

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<fromRoot.State>
  ) {
    this.logXPCollection = this.afdb.list('logPX');
    this.logOggCollection = this.afdb.list('logOgg');
    this.logBankCollection = this.afdb.list('logBank');
  }


  public logXP(from: string, to: string, usableBC: string, qta: string, why: string) {
    const logID: string = Functions.CreateGuid();
    const logEntry: LogPX = new LogPX(logID, from, to, usableBC, qta, why);
    this.logXPCollection.set(logID, logEntry);
    // this.realTimeDB.write('logPX/' + logID, logEntry);
  }

  public logOgg(from: string, to: string, what: string, where: string) {
    const logID: string = Functions.CreateGuid();
    const logEntry: LogOgg = new LogOgg(logID, from, to, what, where);
    this.logOggCollection.set(logID, logEntry);
    // this.realTimeDB.write('logOgg/' + logID, logEntry);
  }

  public logBank(from: string, to: string, qta: string, what: string) {
    const logID: string = Functions.CreateGuid();
    const logEntry: LogBank = new LogBank(logID, from, to, qta, what);
    this.logBankCollection.set(logID, logEntry);
    // this.realTimeDB.write('logBank/' + logID, logEntry);
  }


}
