import { Role, RaceIcons } from './../../../../models/data/application.data';

import { Component, OnInit, Input } from '@angular/core';
import { ChatMessage, MessageType } from '../../models/chat-message.models';
import { Sex, Race } from '../../../../models/data/application.data';
import * as layout from './../../../../actions/layout';
import * as character from './../../../../actions/character';
import * as datasSelector from './../../../../selectors/datas.selectors';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  public raceIcons$: Observable<RaceIcons[]>;

  @Input('ChatMessage') chatMessage: ChatMessage;
  @Input('myUID') myUID: string;
  @Input('myRole') myRole: Role;
  @Input('canOpenSheet') canOpenSheet: boolean = true;
  public userID: string;
  public name: string;
  public text: string;
  public time: Date | number;
  public type: MessageType;
  public sex: Sex;
  public isPNG: boolean;
  public race: Race;
  public lvl: number;
  public nameT: string;
  public targets: string[];

  constructor(private store: Store<fromRoot.State>) {
    this.raceIcons$ = this.store.select(datasSelector.getRaceIcons);
  }

  ngOnInit(chatMessage = this.chatMessage) {
    this.userID = chatMessage.userID;
    this.name = chatMessage.name;
    this.text = chatMessage.text;
    this.time = chatMessage.time;
    this.type = chatMessage.type;
    this.sex = chatMessage.sex;
    this.isPNG = chatMessage.isPNG;
    this.race = chatMessage.race;
    this.lvl = chatMessage.lvl || 1;
    this.nameT = chatMessage.nameT;
    this.targets = chatMessage.targets;
  }

  public openSheet() {
    if (this.canOpenSheet == false)
      return;

    this.store.dispatch(new character.SelectplayerID(this.userID));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
  }
}
