import { Race, Stats } from './../../../models/data/application.data';
import { Sex } from "../../../models/data/application.data";

// export class ChatMessage {
//     public $key?: string;

//     constructor(
//         public email?: string, //store user's email
//         public userName?: string, // store user's name
//         public message?: string, // store message
//         public timeSent?: Date | number //time the message has been sent
//     ) { }
// }

export enum MessageType {
  standard = 0,
  sussurro = 1,
  fato = 2,
  location = 3,
  skillNotification = 4,
  diceNotification = 5,
  diceFatoNotification = 6,
  itemNotification = 7,
  endOFTurn = 8,
  startQuest = 9,
  endQuest = 10,
  dmgNotification = 11,
}

export enum DamageType {
  fisici = 0,
  mentali = 1,
  none = 2
}

export class ChatMessage {
  public msgID: string;
  public userID: string;
  public name: string;
  public text: string;
  public textTactical: string;
  public time: Date | number;
  public type: MessageType;
  public sex: Sex;
  public isPNG: boolean;
  public race: Race;
  public lvl: number;
  public nameT: string;
  public targets: string[];
  public dR: number;
  public dRM: number;
  public calcDamage: boolean = false;
  public damageType: DamageType = DamageType.none;

  constructor(id: string, text: string, time: any, type: MessageType, name?: string, sex?: Sex, isPNG?: boolean, race?: Race, lvl?: number) {
    this.userID = id;
    this.text = text;
    this.time = time;
    this.type = type;
    if (MiniFunctions.IsNullOrUndefined(name) == false)
      this.name = name;
    if (MiniFunctions.IsNullOrUndefined(sex) == false)
      this.sex = sex;
    if (MiniFunctions.IsNullOrUndefined(race) == false)
      this.race = race;
    if (MiniFunctions.IsNullOrUndefined(lvl) == false)
      this.lvl = lvl;
    if (MiniFunctions.IsNullOrUndefined(isPNG) == false)
      this.isPNG = isPNG;
  }
}

export class CharacterActivatedSkill {
  public uid: string;
  public startTurn: number;
  public durata: number;
  public bonusStats: Stats;
  public bonusAddStats: Stats;
  public bonusAddHealth: BHealth;
  public addBuff: boolean;

  constructor(sUid: string, startTurn: number, durata: number, bonusStats: Stats, addBuff: boolean, bonusAddStats?: Stats, bonusAddHealth?: BHealth) {
    this.uid = sUid;
    this.startTurn = startTurn;
    this.durata = durata;
    this.bonusStats = bonusStats;
    this.addBuff = addBuff;
    if (MiniFunctions.IsNullOrUndefined(bonusAddStats) == false)
      this.bonusAddStats = bonusAddStats;
    else
      this.bonusAddStats = null;
  }
}

export class CharacterActivatedItem {
  public uid: string;
  public startTurn: number;
  public durata: number;
  public bonusStats: Stats;
  public bonusAddStats: Stats;
  public bonusAddHealth: BHealth;
  public addBuff: boolean;


  constructor(iUid: string, startTurn: number, durata: number, bonusStats: Stats, addBuff: boolean, bonusAddStats?: Stats, bonusAddHealth?: BHealth) {
    this.uid = iUid;
    this.startTurn = startTurn;
    this.durata = durata;
    this.bonusStats = bonusStats;
    this.addBuff = addBuff;
    if (MiniFunctions.IsNullOrUndefined(bonusAddStats) == false)
      this.bonusAddStats = bonusAddStats;
    else
      this.bonusAddStats = null;
  }
}

export class CharacterState {
  public uid: string;

  public activatedSkills: CharacterActivatedSkill[];
  public skillsBonusStats: Stats;

  public activatedItem: CharacterActivatedItem[];
  public itemsBonusStats: Stats;

  constructor(uid: string, activatedSkills: CharacterActivatedSkill[], skillsBonusStats: Stats, activatedItem: CharacterActivatedItem[], itemsBonusStats: Stats) {
    this.uid = uid;
    this.activatedSkills = activatedSkills;
    this.skillsBonusStats = skillsBonusStats;
    this.activatedItem = activatedItem;
    this.itemsBonusStats = itemsBonusStats;
  }
}

export class ChatStatus {
  public turnCounter: number = 0;
  public questModeActive: boolean = false;
}

export class ChatData {
  public messages: ChatMessage[];
  public onlineUsersID: string[];
  public charactersState: CharacterState[];
  public chatStatus: ChatStatus;
}

export class OnlineUser {
  public uid: string;
  public name: string;
  public sex: Sex;
  public isPng: boolean;

  constructor(uid: string, name: string, sex: Sex, isPng: boolean) {
    this.uid = uid;
    this.name = name;
    this.sex = sex;
    this.isPng = isPng;
  }
}

export class BHealth {
  sal: number = 0;
  salM: number = 0;
  salAdd: number = 0;
  salMAdd: number = 0;
}

export class ChatSkill {
  uid: string;
  name: string;
  description: string;
  level: number;
  bonusStats: Stats;
  bonusAddStats: Stats;
  bonusHealth: BHealth;
  isSkillWithAdditingBuff: boolean;
  isActive: boolean;
  duration: number;

  grdDescr: string[];
  note: string;

  constructor(uid: string, name: string, description: string, level: number, bonusStats: Stats, bonusAddStats: Stats, bonusHealth: BHealth, isSkillWithAdditingBuff: boolean, isActive: boolean, duration: number) {
    this.uid = uid;
    this.name = name;
    this.description = description;
    this.level = level;
    this.bonusStats = bonusStats;
    this.bonusAddStats = bonusAddStats;
    this.bonusHealth = bonusHealth;
    this.isSkillWithAdditingBuff = isSkillWithAdditingBuff;
    this.isActive = isActive;
    this.duration = duration;
  }
}

export class ChatItem {
  uid: string;
  name: string;
  description: string;
  bonusStats: Stats;
  bonusAddStats: Stats;
  bonusHealth: BHealth;
  isItemWithAdditingBuff: boolean;
  duration: number;
  availableUses: number
  maxUses: number
  qta: number;

  constructor(uid: string, name: string, description: string, availableUses: number, maxUses: number, qta: number, bonusStats: Stats, bonusAddStats: Stats, bonusHealth: BHealth, isItemWithAdditingBuff: boolean, duration: number) {
    this.uid = uid;
    this.name = name;
    this.description = description;
    this.availableUses = availableUses;
    this.maxUses = maxUses;
    this.qta = qta;
    this.bonusStats = bonusStats;
    this.bonusAddStats = bonusAddStats;
    this.bonusHealth = bonusHealth;
    this.isItemWithAdditingBuff = isItemWithAdditingBuff;
    this.duration = duration;
  }
}

export class ChatSelectStatsUI {
  id: string;
  label: string;
  value: number;

  constructor(id: string, label: string, value: number) {
    this.id = id;
    this.label = label;
    this.value = value;
  }
}

export enum DiceType {
  standard = 0,
  free = 1,
  fate = 2,
  response = 3,
  calcDmg = 4
}

export class DiceDmgData {
  calcDamage: boolean = false;
  damageType: DamageType = DamageType.none;
  valAtk: number = 0;
  maxValAtk: number = 0;
  damageSubiti: number = 0;

  constructor(calcDamage: boolean = false, damageType: DamageType = DamageType.none, valAtk: number = 0, maxValAtk: number = 0, damageSubiti: number = 0) {
    this.calcDamage = calcDamage;
    this.damageType = damageType;
    this.valAtk = valAtk;
    this.maxValAtk = maxValAtk;
    this.damageSubiti = damageSubiti;
  }
}

export class TacticalSheetData {
  id: string;
  statsBase: Stats;
  statsBonusSkill: Stats;
  statsBonusItems: Stats;
  statsTotal: Stats;

  health: number;
  mindHealth: number;

  tacticalSheetImg: string;

  receivedDamage: ChatMessage[];
  usedSkillsAndItems: ChatMessage[];
}













//#region - mini functions
// used only here in order to remove the circular dependency between this files and the functions files
export class MiniFunctions {

  //Check if an obj is null, using the strict equals "===" comparer
  public static IsNull(obj: any): boolean {
    return obj === null;
  }

  //Check if an obj is undefined
  public static IsUndefined(obj: any): boolean {
    return (typeof obj) === 'undefined';
  }

  //Check if an obj is null or undefined
  public static IsNullOrUndefined(obj: any): boolean {
    return (MiniFunctions.IsNull(obj) || MiniFunctions.IsUndefined(obj));
  }
}

  //#endregion - mini functions
