import { RulesSezione, RulesSottoSezione, Rule, DBVersionType } from './../../../../models/data/application.data';
import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-regolamento',
  templateUrl: 'cPanel-regolamento.component.html',
  styleUrls: ['cPanel-regolamento.component.less'],
})
export class CPanelRegolamentoComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelRegolamento";

  //#region - sezioni variables
  public dataSource: LocalDataSource;
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Sezione'
      },
      sequence: {
        title: 'Sequenza'
      }
    }
  };
  public selectedAreaID: string;
  public selectedArea: RulesSezione;
  //#endregion - sezioni variables


  //#region - sottosezioni variables
  public dataSourceSottosezione: LocalDataSource;
  public settingsSottosezione = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Sottosezione'
      },
      imgUrl: {
        title: 'Immagine Sottosezione'
      },
      sequence: {
        title: 'Sequenza'
      },
      alsoParziale: {
        title: 'Anche in Esterno',
        valuePrepareFunction: (alsoParziale: any) => {
          if (Functions.IsNullOrUndefined(alsoParziale) || alsoParziale == true || ("" + alsoParziale) == "")
            return "Si";
          else
            return "No";
        },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: false, title: "No" },
              { value: true, title: "Si" }
            ]
          }
        }
      }
    }
  };
  public selectedSubAreaID: string;
  public selectedSubArea: RulesSottoSezione;
  //#endregion - sottosezioni variables

  //#region - rules variables
  public dataSourceRule: LocalDataSource;
  public settingsRules = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Paragrafo'
      },
      sequence: {
        title: 'Sequenza'
      },
      alsoParziale: {
        title: 'Anche in Esterno',
        valuePrepareFunction: (alsoParziale: any) => {
          if (Functions.IsNullOrUndefined(alsoParziale) || alsoParziale == true || ("" + alsoParziale) == "")
            return "Si";
          else
            return "No";
        },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: false, title: "No" },
              { value: true, title: "Si" }
            ]
          }
        }
      }
    }
  };
  public selectedRuleID: string;
  public selectedRule: Rule;
  public config: any = {
    height: 550,
    menubar: false,
    theme: 'modern',
    plugins: [
      'advlist autolink link image imagetools lists charmap hr',
      'wordcount visualblocks visualchars code',
      'table contextmenu textcolor paste colorpicker',
    ],
    toolbar1:
      'bold italic underline strikethrough | undo redo | blockquote numlist bullist | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect | forecolor backcolor',
    toolbar2:
      'cut copy paste | outdent indent | link unlink image table | hr removeformat | subscript superscript | charmap | code',
    image_advtab: true,
    imagetools_toolbar:
      'rotateleft rotateright | flipv fliph | editimage imageoptions',
  };
  //#endregion - rules variables

  //#region - firestore datas
  private rulesCollection: AngularFirestoreCollection<RulesSezione>;
  private rulesCollectionObservable: Observable<RulesSezione[]>;
  public rulesSubscription: Subscription;
  public areas: RulesSezione[] = [];

  //#endregion - firestore datas



  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();
    this.dataSourceSottosezione = new LocalDataSource();
    this.dataSourceRule = new LocalDataSource();

    //#region - get data from firestore
    this.rulesCollection = this.afs.collection<RulesSezione>('rules');
    this.rulesCollectionObservable = this.rulesCollection.valueChanges();
    this.rulesSubscription = this.rulesCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all rules chats cpanel", self.moduleName, "rules", (data as any[]).length);
      }

      self.areas = data;

      self.dataSource = new LocalDataSource();
      self.dataSourceSottosezione = new LocalDataSource();
      self.dataSourceRule = new LocalDataSource();

      self.dataSource.setSort([{ field: 'name', direction: 'asc' }]);
      self.dataSourceSottosezione.setSort([{ field: 'name', direction: 'asc' }]);
      self.dataSourceRule.setSort([{ field: 'name', direction: 'asc' }]);

      self.dataSource.load(data);

      if (Functions.IsNullOrUndefined(self.selectedArea) == false) {
        self.selectedArea = Object.assign({}, self.areas.find((anArea: RulesSezione) => anArea.uid == self.selectedAreaID));
        self.dataSourceSottosezione.load(self.selectedArea.sottosezioni);
      }

      if (Functions.IsNullOrUndefined(self.selectedSubArea) == false) {
        self.selectedSubArea = Object.assign({}, self.selectedArea.sottosezioni.find((aSubArea: RulesSottoSezione) => aSubArea.uid == self.selectedSubAreaID));
        self.dataSourceRule.load(self.selectedSubArea.rules);
      }

      if (Functions.IsNullOrUndefined(self.selectedRule) == false) {
        self.selectedRule = Object.assign({}, self.selectedSubArea.rules.find((aParagrafo: Rule) => aParagrafo.uid == self.selectedRuleID));
      }

    });
    //#endregion - get data from firestore
  }

  public ngOnDestroy() {
    this.rulesSubscription.unsubscribe();
  }

  //#region - sezione
  public onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la macro area selezionata e tutto il suo contenuto?')) {
      event.confirm.resolve();

      // remove on firebase
      let self = this;
      const ruleToBeRemoved: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + event.data.uid);
      ruleToBeRemoved.delete()
        .then(() => {
          self.cPanelService.addIdToDBVersionChanges(event.data.uid, DBVersionType.rules);
          //clear under
          this.selectedArea = undefined;
          this.selectedAreaID = undefined;
          this.selectedSubArea = undefined;
          this.selectedSubAreaID = undefined;
          this.selectedRule = undefined;
          this.selectedRuleID = undefined;
          self.toastr.success('Elemento Eliminato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });


      // this.firestore.remove('rules/' + event.data.uid).subscribe(() => {
      //   self.cPanelService.addIdToDBVersionChanges(event.data.uid, DBVersionType.rules);
      //   //clear under
      //   this.selectedArea = undefined;
      //   this.selectedAreaID = undefined;
      //   this.selectedSubArea = undefined;
      //   this.selectedSubAreaID = undefined;
      //   this.selectedRule = undefined;
      //   this.selectedRuleID = undefined;
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

  public onCreateConfirm(event) {
    event.confirm.resolve();

    let newMacroArea: RulesSezione = new RulesSezione();
    newMacroArea.name = event.newData.name;
    newMacroArea.sequence = parseInt(event.newData.sequence);
    // newMacroArea.rules = "";
    newMacroArea.uid = Functions.CreateGuid();

    let self = this;
    let newMacroAreaDataJSON = JSON.parse(JSON.stringify(newMacroArea));

    this.rulesCollection.doc(newMacroArea.uid).set(newMacroAreaDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(event.data.uid, DBVersionType.rules);
        //clear under
        this.selectedArea = undefined;
        this.selectedAreaID = undefined;
        this.selectedSubArea = undefined;
        this.selectedSubAreaID = undefined;
        this.selectedRule = undefined;
        this.selectedRuleID = undefined;
        self.toastr.success('Elemento Creato!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(event.data.uid, DBVersionType.rules);
    //   //clear under
    //   this.selectedArea = undefined;
    //   this.selectedAreaID = undefined;
    //   this.selectedSubArea = undefined;
    //   this.selectedSubAreaID = undefined;
    //   this.selectedRule = undefined;
    //   this.selectedRuleID = undefined;
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public onEditConfirm(event) {
    event.confirm.resolve();

    let newMacroArea: RulesSezione = new RulesSezione();
    newMacroArea.name = event.newData.name;
    newMacroArea.sequence = parseInt(event.newData.sequence);
    newMacroArea.uid = event.newData.uid;

    if (Functions.IsNullOrUndefined(event.newData.sottosezioni) == false) {
      newMacroArea.sottosezioni = event.newData.sottosezioni;
    }

    let self = this;
    let newMacroAreaDataJSON = JSON.parse(JSON.stringify(newMacroArea));

    const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + event.data.uid);
    ruleToBeUpdated.update(newMacroAreaDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(event.data.uid, DBVersionType.rules);
        //clear under
        this.selectedArea = undefined;
        this.selectedAreaID = undefined;
        this.selectedSubArea = undefined;
        this.selectedSubAreaID = undefined;
        this.selectedRule = undefined;
        this.selectedRuleID = undefined;
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(event.data.uid, DBVersionType.rules);
    //   //clear under
    //   this.selectedArea = undefined;
    //   this.selectedAreaID = undefined;
    //   this.selectedSubArea = undefined;
    //   this.selectedSubAreaID = undefined;
    //   this.selectedRule = undefined;
    //   this.selectedRuleID = undefined;
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public onAreaChange(area: RulesSezione) {
    if (Functions.IsNullOrUndefined(area) == false) {
      this.selectedArea = Object.assign({}, area);
      this.dataSourceSottosezione.load(area.sottosezioni || []);
      this.selectedRule = undefined;
    } else {
      this.selectedArea = undefined;
      this.selectedRule = undefined;
      this.dataSourceSottosezione = new LocalDataSource();
    }
  }
  //#endregion


  //#region - sottosezione
  onDeleteSottosezioneConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la sottosezione selezionata e tutto il suo contenuto?')) {
      event.confirm.resolve();

      this.selectedArea.sottosezioni = this.selectedArea.sottosezioni.filter((aSottosezione: RulesSottoSezione) => {
        aSottosezione.uid != event.data.uid;
      });

      let self = this;
      let newMacroAreaDataJSON = JSON.parse(JSON.stringify(this.selectedArea));
      const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + newMacroAreaDataJSON.uid);
      ruleToBeUpdated.update(newMacroAreaDataJSON)
        .then(() => {
          self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
          //clear under
          this.selectedSubArea = undefined;
          this.selectedSubAreaID = undefined;
          this.selectedRule = undefined;
          this.selectedRuleID = undefined;
          self.toastr.success('Elemento Creato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });

      // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
      //   self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
      //   //clear under
      //   this.selectedSubArea = undefined;
      //   this.selectedSubAreaID = undefined;
      //   this.selectedRule = undefined;
      //   this.selectedRuleID = undefined;
      //   self.toastr.success('Elemento Creato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

  onCreateSottosezioneConfirm(event) {
    event.confirm.resolve();

    let sottosezione: RulesSottoSezione = new RulesSottoSezione();
    sottosezione.name = event.newData.name;
    sottosezione.imgUrl = event.newData.imgUrl;
    sottosezione.sequence = parseInt(event.newData.sequence);
    sottosezione.rules = [];
    sottosezione.uid = Functions.CreateGuid();
    sottosezione.alsoParziale = (event.newData.alsoParziale === "true");

    if (Functions.IsNullOrUndefined(this.selectedArea.sottosezioni) || this.selectedArea.sottosezioni.length <= 0)
      this.selectedArea.sottosezioni = [];

    this.selectedArea.sottosezioni.push(sottosezione);

    let self = this;
    let newMacroAreaDataJSON = JSON.parse(JSON.stringify(this.selectedArea));
    const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + newMacroAreaDataJSON.uid);
    ruleToBeUpdated.update(newMacroAreaDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
        //clear under
        this.selectedSubArea = undefined;
        this.selectedSubAreaID = undefined;
        this.selectedRule = undefined;
        this.selectedRuleID = undefined;
        self.toastr.success('Elemento Creato!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
    //   //clear under
    //   this.selectedSubArea = undefined;
    //   this.selectedSubAreaID = undefined;
    //   this.selectedRule = undefined;
    //   this.selectedRuleID = undefined;
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onEditSottosezioneConfirm(event) {
    event.confirm.resolve();

    let sottosezione: RulesSottoSezione = new RulesSottoSezione();
    sottosezione.name = event.newData.name;
    sottosezione.imgUrl = event.newData.imgUrl;
    sottosezione.sequence = parseInt(event.newData.sequence);
    sottosezione.uid = event.newData.uid;
    sottosezione.alsoParziale = (event.newData.alsoParziale === "true");

    if (Functions.IsNullOrUndefined(event.newData.rules) == false) {
      sottosezione.rules = event.newData.rules;
    }

    const newSottosezioni: RulesSottoSezione[] = [];
    this.selectedArea.sottosezioni.forEach((aSottosezione: RulesSottoSezione) => {
      if (aSottosezione.uid == sottosezione.uid) {
        newSottosezioni.push(sottosezione);
      } else {
        newSottosezioni.push(aSottosezione);
      }
    });

    this.selectedArea.sottosezioni = newSottosezioni;

    let self = this;
    let newMacroAreaDataJSON = JSON.parse(JSON.stringify(this.selectedArea));
    const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + newMacroAreaDataJSON.uid);
    ruleToBeUpdated.update(newMacroAreaDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
        //clear under
        this.selectedSubArea = undefined;
        this.selectedSubAreaID = undefined;
        this.selectedRule = undefined;
        this.selectedRuleID = undefined;
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
    //   //clear under
    //   this.selectedSubArea = undefined;
    //   this.selectedSubAreaID = undefined;
    //   this.selectedRule = undefined;
    //   this.selectedRuleID = undefined;
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onSubAreaChange(subArea: RulesSottoSezione) {
    if (Functions.IsNullOrUndefined(subArea) == false) {
      this.selectedSubArea = Object.assign({}, subArea);
      this.dataSourceRule.load(subArea.rules || []);
      this.selectedRule = undefined;
    } else {
      this.selectedSubArea = undefined;
      this.selectedRule = undefined;
      this.dataSourceRule = new LocalDataSource();
    }
  }
  //#endregion


  //#region - paragrafo
  onDeleteRuleConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare il paragrafo selezionato e tutto il suo contenuto?')) {
      event.confirm.resolve();

      this.selectedSubArea.rules = this.selectedSubArea.rules.filter((aParagrafo: Rule) => {
        aParagrafo.uid != event.data.uid;
      });

      this.selectedArea.sottosezioni = this.selectedArea.sottosezioni
        .map((aSottosezione: RulesSottoSezione) => {
          if (aSottosezione.uid != this.selectedSubArea.uid)
            return aSottosezione;
          else
            return this.selectedSubArea;
        });

      let self = this;
      let newMacroAreaDataJSON = JSON.parse(JSON.stringify(this.selectedArea));
      const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + newMacroAreaDataJSON.uid);
      ruleToBeUpdated.update(newMacroAreaDataJSON)
        .then(() => {
          self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
          //clear under
          this.selectedRule = undefined;
          this.selectedRuleID = undefined;
          self.toastr.success('Elemento Creato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        });

      // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
      //   self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
      //   //clear under
      //   this.selectedRule = undefined;
      //   this.selectedRuleID = undefined;
      //   self.toastr.success('Elemento Creato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      event.confirm.reject();
    }
  }

  onCreateRuleConfirm(event) {
    event.confirm.resolve();

    let paragrafo: Rule = new Rule();
    paragrafo.name = event.newData.name;
    paragrafo.sequence = parseInt(event.newData.sequence);
    paragrafo.text = "";
    paragrafo.uid = Functions.CreateGuid();
    paragrafo.alsoParziale = (event.newData.alsoParziale === "true");

    if (Functions.IsNullOrUndefined(this.selectedSubArea.rules) || this.selectedSubArea.rules.length <= 0)
      this.selectedSubArea.rules = [];

    this.selectedSubArea.rules.push(paragrafo);

    this.selectedArea.sottosezioni = this.selectedArea.sottosezioni
      .map((aSottosezione: RulesSottoSezione) => {
        if (aSottosezione.uid != this.selectedSubArea.uid)
          return aSottosezione;
        else
          return this.selectedSubArea;
      });

    let self = this;
    let newMacroAreaDataJSON = JSON.parse(JSON.stringify(this.selectedArea));
    const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + newMacroAreaDataJSON.uid);
    ruleToBeUpdated.update(newMacroAreaDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
        //clear under
        this.selectedRule = undefined;
        this.selectedRuleID = undefined;
        self.toastr.success('Elemento Creato!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
    //   //clear under
    //   this.selectedRule = undefined;
    //   this.selectedRuleID = undefined;
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onEditRuleConfirm(event) {
    event.confirm.resolve();

    let paragrafo: Rule = new Rule();
    paragrafo.name = event.newData.name;
    paragrafo.sequence = parseInt(event.newData.sequence);
    paragrafo.text = event.newData.text;
    paragrafo.uid = event.newData.uid;
    paragrafo.alsoParziale = (event.newData.alsoParziale === "true");

    const newParagrafi: Rule[] = [];
    this.selectedSubArea.rules.forEach((aParagrafo: Rule) => {
      if (aParagrafo.uid == paragrafo.uid) {
        newParagrafi.push(paragrafo);
      } else {
        newParagrafi.push(aParagrafo);
      }
    });

    this.selectedSubArea.rules = newParagrafi;

    this.selectedArea.sottosezioni = this.selectedArea.sottosezioni
      .map((aSottosezione: RulesSottoSezione) => {
        if (aSottosezione.uid != this.selectedSubArea.uid)
          return aSottosezione;
        else
          return this.selectedSubArea;
      });

    let self = this;
    let newMacroAreaDataJSON = JSON.parse(JSON.stringify(this.selectedArea));
    const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + newMacroAreaDataJSON.uid);
    ruleToBeUpdated.update(newMacroAreaDataJSON)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
        //clear under
        this.selectedRule = undefined;
        this.selectedRuleID = undefined;
        self.toastr.success('Elemento Creato!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('rules/' + newMacroAreaDataJSON.uid, newMacroAreaDataJSON).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(newMacroAreaDataJSON.uid, DBVersionType.rules);
    //   //clear under
    //   this.selectedRule = undefined;
    //   this.selectedRuleID = undefined;
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  onRuleChange(rule: Rule) {
    if (Functions.IsNullOrUndefined(rule) == false) {
      this.selectedRule = Object.assign({}, rule);
    } else {
      this.selectedRule = undefined;
    }
  }
  //#endregion




  annulla() {
    this.selectedAreaID = undefined;
    this.selectedArea = undefined;
    this.selectedSubAreaID = undefined;
    this.selectedSubArea = undefined;
    this.selectedRuleID = undefined;
    this.selectedRule = undefined;

    this.dataSourceSottosezione = new LocalDataSource();
    this.dataSourceRule = new LocalDataSource();
  }

  ok() {

    this.selectedSubArea.rules = this.selectedSubArea.rules
      .map((aParagrafo: Rule) => {
        if (aParagrafo.uid != this.selectedRule.uid)
          return aParagrafo;
        else
          return this.selectedRule;
      });

    this.selectedArea.sottosezioni = this.selectedArea.sottosezioni
      .map((aSottosezione: RulesSottoSezione) => {
        if (aSottosezione.uid != this.selectedSubArea.uid)
          return aSottosezione;
        else
          return this.selectedSubArea;
      });

    let self = this;
    let ruleArea = JSON.parse(JSON.stringify(this.selectedArea));
    const ruleToBeUpdated: AngularFirestoreDocument<RulesSezione> = this.afs.doc<any>('rules/' + ruleArea.uid);
    ruleToBeUpdated.update(ruleArea)
      .then(() => {
        self.cPanelService.addIdToDBVersionChanges(ruleArea.uid, DBVersionType.rules);
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.write('rules/' + ruleArea.uid, ruleArea).subscribe(() => {
    //   self.cPanelService.addIdToDBVersionChanges(ruleArea.uid, DBVersionType.rules);
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

}
