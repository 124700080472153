import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Citazione } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { FirestoreService } from '../../../../services/firestore.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-citazioni',
  templateUrl: 'cPanel-citazioni.component.html',
  styleUrls: ['cPanel-citazioni.component.less'],
})
export class CPanelCitazioniComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelCitazioni";
  private citazioniChatDoc: AngularFirestoreDocument<any>;
  private citazioniChatObservable: Observable<any>;
  private citazioniSubscription: Subscription;

  public citazioniData: any = { value: [] };
  public citazioniDataSource: LocalDataSource;
  public settingsCitazioni = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome pg'
      },
      description: {
        title: 'Descrizione',
        editor: {
          type: 'textarea'
        }
      }
    }
  };

  constructor(
    private firestoreService: FirestoreService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {

    let self = this;
    this.citazioniDataSource = new LocalDataSource();

    this.citazioniChatDoc = this.afs.doc<any>('settings/citazioni');
    this.citazioniChatObservable = this.citazioniChatDoc.valueChanges();
    this.citazioniSubscription = this.citazioniChatObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all citazioni", self.moduleName, "settings/citazioni", (data.value as any[]).length);
      }

      if (Functions.IsNullOrUndefined(data) == false && Functions.IsNullOrUndefined(data.value) == false) {
        self.citazioniData = data;
        self.citazioniDataSource.load(data.value);
      }
    });

  }

  public ngOnDestroy() {
    this.citazioniSubscription.unsubscribe();
  }

  onStandardDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.citazioniData.value = (this.citazioniData.value as Citazione[]).filter((aCitaione: Citazione) => aCitaione.id != event.data.id);
      let newCitazioniDataJSON = JSON.parse(JSON.stringify(this.citazioniData));
      this.citazioniChatDoc.update(newCitazioniDataJSON);
      // this.firestore.write('settings/citazioni', newCitazioniDataJSON);
    } else {
      event.confirm.reject();
    }
  }

  onStandardCreateConfirm(event) {
    event.confirm.resolve();

    if (Functions.IsNullOrUndefined(this.citazioniData) || Functions.IsNullOrUndefined(this.citazioniData.value)) {
      this.citazioniData = { value: [] };
    }

    //update on firebase
    let newCitazioniData: Citazione = new Citazione();
    newCitazioniData.id = Functions.CreateGuid(); //(this.citazioniData.value as Citazione[]).length.toString();
    newCitazioniData.name = event.newData.name;
    newCitazioniData.description = event.newData.description;

    (this.citazioniData.value as Citazione[]).push(newCitazioniData);

    let newCitazioniDataJSON = JSON.parse(JSON.stringify(this.citazioniData));
    this.citazioniChatDoc.update(newCitazioniDataJSON);
    // this.firestore.write('settings/citazioni', newCitazioniDataJSON);
  }

  onStandardEditConfirm(event) {
    event.confirm.resolve();

    //update on firebase
    let newCitazioniData: Citazione = new Citazione();
    newCitazioniData.id = event.newData.id;
    newCitazioniData.name = event.newData.name;
    newCitazioniData.description = event.newData.description;

    if (Functions.IsNullOrUndefined(this.citazioniData) == false && Functions.IsNullOrUndefined(this.citazioniData.value) == false) {
      this.citazioniData.value = (this.citazioniData.value as Citazione[]).filter((aCitaione: Citazione) => aCitaione.id != newCitazioniData.id);
      (this.citazioniData.value as Citazione[]).push(newCitazioniData);
    } else {
      this.citazioniData = { value: [] };
      (this.citazioniData.value as Citazione[]).push(newCitazioniData);
    }

    let newCitazioniDataJSON = JSON.parse(JSON.stringify(this.citazioniData));
    this.citazioniChatDoc.update(newCitazioniDataJSON);
    // this.firestore.write('settings/citazioni', newCitazioniDataJSON);
  }

}
