import { CharacterSheetData } from './../../../../models/data/application.data';
import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { ChatMessage } from '../../models/chat-message.models';
import { ChatService } from '../../services/chat.service';
import { AngularFireList } from '@angular/fire/database';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as chatSelector from './../../../../selectors/chat.selectors';
import * as characterSelector from './../../../../selectors/character.selectors';
import { Role } from '../../../../models/data/application.data';
import { Functions } from '../../../utilities/functions/utilities.functions';

@Component({
  selector: 'app-chat-feed',
  templateUrl: './chat-feed.component.html',
  styleUrls: ['./chat-feed.component.css']
})
export class ChatFeedComponent implements OnInit, OnDestroy {
  // feed: Observable<any> = this.chat.messages$;
  getChatMessages$: Observable<ChatMessage[]>;
  myCharacterSheet$: Observable<CharacterSheetData>;
  myCharacterSheetSubscription: Subscription;
  myUID: string = "";
  myRole: Role;

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit() {
    this.getChatMessages$ = this.store.select(chatSelector.getChatMessages);

    this.myCharacterSheet$ = this.store.select(characterSelector.getCharacterSheet);
    this.myCharacterSheetSubscription = this.myCharacterSheet$.subscribe((sheet: CharacterSheetData) => {
      if (Functions.IsNullOrUndefined(sheet) == false) {
        this.myUID = sheet.uid;
        this.myRole = sheet.role;
      }
    });
    // this.myUID = fromRoot.getState(this.store).character.myUID;
    // this.myRole = fromRoot.getState(this.store).character.myCharacterData.role;
  }

  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.myCharacterSheetSubscription) == false)
      this.myCharacterSheetSubscription.unsubscribe();
    // this.feed = this.chat.getMessages();
  }
}
