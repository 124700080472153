import { CharacterSheetData } from './../models/data/application.data';
import { ChatMessage, CharacterState, ChatStatus, OnlineUser } from './../modules/chatRoom/models/chat-message.models';
import { BaseAction } from './baseAction';

export const STORE_CHAT_MESSAGES = "[Chat] Store Chat Messages";
export const STORE_NEW_CHAT_MESSAGES = "[Chat] Store New Chat Messages";
export const STORE_NEW_CHAT_ATTACKS = "[Chat] Store New Chat Attacks";
export const STORE_RESPONSE_PENDING_ATTACK = "[Chat] Store Response Pending Attack";
export const POP_CHAT_ATTACK = "[Chat] Pop Chat Attacks";
export const STORE_SELECTED_CHAT = "[Chat] Store Selected Chat";
export const STORE_CHAT_ONLINE_USERS = "[Chat] Store Chat Online Users";
export const STORE_CHAT_CHARACTERS_STATE = "[Chat] Store Chat Characters State";
export const STORE_CHAT_STATUS = "[Chat] Store Chat Status";
export const SET_SELECTED_CHAT_PLAYER = "[Chat] Set Selected Chat Player";
export const SELECT_CHAT_PLAYER = "[Chat] Select Chat Player";
export const LOG_OUT_CLEAN = "[Chat] Log Out Clean";
export const STORE_CHAT_INSTRUCTION = "[Chat] Store Chat Instruction";

export type Actions =
  StoreChatMessages
  | StoreNewChatMessages
  | StoreNewChatAttacks
  | StoreResponsePendingAttack
  | PopChatAttacks
  | StoreChatOnlineUsers
  | StoreChatCharactersState
  | StoreChatStatus
  | StoreSelectedChat
  | SetSelectedChatPlayer
  | SelectChatPlayer
  | LogOutClean
  | StoreChatInstruction;

export class StoreChatMessages implements BaseAction {
  readonly type = STORE_CHAT_MESSAGES;
  constructor(public payload: ChatMessage[]) { }
}

export class StoreNewChatMessages implements BaseAction {
  readonly type = STORE_NEW_CHAT_MESSAGES;
  constructor(public payload: ChatMessage[]) { }
}

export class StoreResponsePendingAttack implements BaseAction {
  readonly type = STORE_RESPONSE_PENDING_ATTACK;
  constructor(public payload: ChatMessage) { }
}

export class StoreNewChatAttacks implements BaseAction {
  readonly type = STORE_NEW_CHAT_ATTACKS;
  constructor(public payload: ChatMessage[]) { }
}

export class PopChatAttacks implements BaseAction {
  readonly type = POP_CHAT_ATTACK;
  constructor() { }
}

export class StoreChatOnlineUsers implements BaseAction {
  readonly type = STORE_CHAT_ONLINE_USERS;
  constructor(public payload: OnlineUser[]) { }
}

export class StoreChatCharactersState implements BaseAction {
  readonly type = STORE_CHAT_CHARACTERS_STATE;
  constructor(public payload: CharacterState[]) { }
}

export class StoreChatStatus implements BaseAction {
  readonly type = STORE_CHAT_STATUS;
  constructor(public payload: ChatStatus) { }
}

export class StoreSelectedChat implements BaseAction {
  readonly type = STORE_SELECTED_CHAT;
  constructor(public payload: string) { }
}

export class SelectChatPlayer implements BaseAction {
  readonly type = SELECT_CHAT_PLAYER;
  constructor(public payload: string) { }
}

export class SetSelectedChatPlayer implements BaseAction {
  readonly type = SET_SELECTED_CHAT_PLAYER;
  constructor(public payload: CharacterSheetData) { }
}

export class LogOutClean implements BaseAction {
  readonly type = LOG_OUT_CLEAN;
  constructor() { }
}

export class StoreChatInstruction implements BaseAction {
  readonly type = STORE_CHAT_INSTRUCTION;
  constructor(public payload: any) { }
}

