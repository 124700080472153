import { FirestoreService } from './../../../../services/firestore.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { UserEntry, UserPresence, PresencePlayerStatus, SecretChat, OnOff } from '../../../../models/data/application.data';
import { Functions } from '../../../utilities/functions/utilities.functions';
import * as layout from './../../../../actions/layout';
import * as chatSelector from './../../../../selectors/chat.selectors';
import { Observable } from 'rxjs';
import { PmService } from '../../../../services/pm.service';

@Component({
  selector: 'gestisci-chat-privata-box',
  templateUrl: './gestisci-chat-privata.component.html',
  styleUrls: ['./gestisci-chat-privata.component.css']
})
export class GestisciChatPrivataBoxComponent implements OnInit, OnDestroy {

  public getAllAvailableToInvite$: Observable<UserEntry[]>;
  public getAllAvailableToRemove$: Observable<UserEntry[]>;

  public invite: UserEntry[];
  public remove: UserEntry[];

  constructor(private chatService: ChatService, private store: Store<fromRoot.State>, private firestoreService: FirestoreService, private pmService: PmService) {
    this.getAllAvailableToInvite$ = this.store.select(chatSelector.getAllAvailableToInvite);
    this.getAllAvailableToRemove$ = this.store.select(chatSelector.getAllAvailableToRemove);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  closeBox() {
    this.store.dispatch(new layout.ToggleGestioneChatPrivataAction(false));
  }

  invita() {
    if (Functions.IsNullOrUndefined(this.invite) || this.invite.length <= 0)
      return;

    const usersPresence: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    let currentChat: SecretChat;
    let ownerName: string;
    for (let index: number = 0; index < usersPresence.length; index++) {
      const currentUserPresence: PresencePlayerStatus = usersPresence[index].state;
      if (Functions.IsNullOrUndefined(currentUserPresence.myPrivateChat) || Functions.IsStringEmpty(currentUserPresence.myPrivateChat.uid))
        continue;

      const aPrivateChat: SecretChat = currentUserPresence.myPrivateChat;
      if (aPrivateChat.uid == fromRoot.getState(this.store).chat.selectedChat) {
        currentChat = aPrivateChat;
        ownerName = currentUserPresence.name;
      }
    }

    if (Functions.IsNullOrUndefined(currentChat) == false) {
      const updatedSecretChat: SecretChat = Object.assign({}, currentChat);
      for (let index: number = 0; index < this.invite.length; index++) {
        const aNewMember: UserEntry = this.invite[index];

        if (Functions.IsNullOrUndefined(updatedSecretChat.accessibleByUIDs))
          updatedSecretChat.accessibleByUIDs = [];

        updatedSecretChat.accessibleByUIDs.push(aNewMember.uid);
      }

      this.firestoreService.updateSecretChatMembers(updatedSecretChat.ownerUID, updatedSecretChat);
      this.sendInviteMessage(this.invite, updatedSecretChat, ownerName);
      this.invite = [];
    }
  }

  caccia() {
    if (Functions.IsNullOrUndefined(this.remove) || this.remove.length <= 0)
      return;

    const usersPresence: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    let currentChat: SecretChat;
    let ownerName: string;
    for (let index: number = 0; index < usersPresence.length; index++) {
      const currentUserPresence: PresencePlayerStatus = usersPresence[index].state;
      if (Functions.IsNullOrUndefined(currentUserPresence.myPrivateChat) || Functions.IsStringEmpty(currentUserPresence.myPrivateChat.uid))
        continue;

      const aPrivateChat: SecretChat = currentUserPresence.myPrivateChat;
      if (aPrivateChat.uid == fromRoot.getState(this.store).chat.selectedChat)
        currentChat = aPrivateChat;
      ownerName = currentUserPresence.name;
    }

    if (Functions.IsNullOrUndefined(currentChat) == false) {
      const updatedSecretChat: SecretChat = Object.assign({}, currentChat);
      for (let index: number = 0; index < this.remove.length; index++) {
        const aNewMember: UserEntry = this.remove[index];
        updatedSecretChat.accessibleByUIDs = updatedSecretChat.accessibleByUIDs.filter((aUserID: string) => aUserID != aNewMember.uid);
      }

      this.firestoreService.updateSecretChatMembers(updatedSecretChat.ownerUID, updatedSecretChat);
      this.sendRemoveMessage(this.remove, updatedSecretChat, ownerName);
      this.remove = [];
      this.store.dispatch(new layout.ToggleGestioneChatPrivataAction(false));
    }
  }


  private sendInviteMessage(newMembers: UserEntry[], secretChat: SecretChat, chatOwner: string) {

    let targets: string[] = [];
    let destinatari: string[] = [];

    let onOffValue: OnOff = OnOff.off

    // not sending to all
    newMembers.forEach((aDestinario: UserEntry) => {
      targets.push(aDestinario.uid);
      destinatari.push(aDestinario.uid);
    });

    // adding my uid to targets
    const myUID: string = fromRoot.getState(this.store).character.myUID;
    targets.push(myUID);

    const titolo: string = "Invito a Chat Privata";
    const message: string = "Sei stato invitato alla chat privata: " + secretChat.location + " - " + secretChat.name + " di " + chatOwner;

    this.pmService.createNewConversation(titolo, onOffValue, destinatari, targets, message, false);
    this.store.dispatch(new layout.ToggleGestioneChatPrivataAction(false));
  }

  private sendRemoveMessage(removedMembers: UserEntry[], secretChat: SecretChat, chatOwner: string) {

    let targets: string[] = [];
    let destinatari: string[] = [];

    let onOffValue: OnOff = OnOff.off

    // not sending to all
    removedMembers.forEach((aDestinario: UserEntry) => {
      targets.push(aDestinario.uid);
      destinatari.push(aDestinario.uid);
    });

    // adding my uid to targets
    const myUID: string = fromRoot.getState(this.store).character.myUID;
    targets.push(myUID);

    const titolo: string = "Rimosso da Chat Privata";
    const message: string = "Sei stato rimosso dalla chat privata: " + secretChat.location + " - " + secretChat.name + " di " + chatOwner;

    this.pmService.createNewConversation(titolo, onOffValue, destinatari, targets, message, false);
    this.store.dispatch(new layout.ToggleGestioneChatPrivataAction(false));
  }
}
