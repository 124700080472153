import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, UserPresence, PresencePlayerStatus, State } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-assegna-cariche-staff',
  templateUrl: 'cPanel-assegna-cariche-staff.component.html',
  styleUrls: ['cPanel-assegna-cariche-staff.component.less'],
})
export class CPanelAssegnaCaricheStaffComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelAssegnaCaricheStaff";

  //#region - firestore users
  public usersPresence$: Observable<UserPresence[]>;
  private usersSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];

  private tempSubscription: Subscription = new Subscription();
  //#endregion - firestore users

  //#region - other variables
  public dataSource: LocalDataSource;
  public settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'change',
          title: '<i class="material-icons icon-del access-pg">security</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Personaggio',
        editable: false
      },
      role: {
        title: 'Ruolo',
        editable: false,
        valuePrepareFunction: (value: any) => {
          const castedValue: number = parseInt(value);
          switch (castedValue) {
            case 0:
              return "gestore";
            case 1:
              return "admin";
            case 2:
              return "master"
            case 3:
              return "staff";
            case 4:
              return "player";
            default:
              return "player";
          };
        },
      }
    }
  };
  //#region - other variables

  //#region - modal variables
  private playerToEditDoc: AngularFirestoreDocument<CharacterSheetData>;
  public isModalVisible: boolean = false;
  public modalUserData: CharacterSheetData;
  public modalUserRoleValue: string;
  //#endregion - modal variables

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private afdb: AngularFireDatabase,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    //#region - getu users
    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }))
      .subscribe((data: PresencePlayerStatus[]) => {
        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.dataSource.load(data);
      });
    //#endregion - getu users
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersSubscription) == false)
      this.usersSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.tempSubscription) == false)
      this.tempSubscription.unsubscribe();
  }

  public onCustom(event) {

    if (event.action == 'change') {
      let self = this;
      this.playerToEditDoc = this.afs.doc<any>('users/' + event.data.playerUID);
      const playerToEditDocObservable = this.playerToEditDoc.valueChanges();

      this.tempSubscription.add(
        playerToEditDocObservable.pipe(first())
          .subscribe((selectedCharacter: CharacterSheetData) => {
            if (self.debugLogger.isAuditing) {
              self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
            }

            self.modalUserData = Object.assign({}, selectedCharacter)
            self.modalUserRoleValue = self.modalUserData.role.toString();
            self.isModalVisible = true;
          })
      );

    }
  }

  public closeModal() {
    this.isModalVisible = false;
    this.modalUserData = undefined
  }

  public okModal() {
    if (Functions.IsNullOrUndefined(this.modalUserData) || Functions.IsNullOrUndefined(this.modalUserRoleValue))
      return;

    this.isModalVisible = false;
    let characterData: CharacterSheetData = this.modalUserData;
    characterData.role = parseInt(this.modalUserRoleValue);
    let characterDataJSON = JSON.parse(JSON.stringify(characterData));

    let self = this;
    this.playerToEditDoc.update(characterDataJSON)
      .then(() => {

        //#region - update presence status
        const oldPresenceStatus: PresencePlayerStatus = self.characters.find((aCharacterStatus: PresencePlayerStatus) => aCharacterStatus.playerUID == characterData.uid);
        let lastAccess: string = Functions.GetCurrentDate();
        let ip: string = "";
        if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
          lastAccess = (oldPresenceStatus.lastAccess || Functions.GetCurrentDate());
        }
        if (Functions.IsNullOrUndefined(oldPresenceStatus) == false) {
          ip = (oldPresenceStatus.ip || "");
        }
        const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(characterData.uid, characterData.race, characterData.role, characterData.MAvatar, characterData.name, characterData.sur, characterData.nick, lastAccess, characterData.sex, (characterData.lvl || 1), (characterData.state || State.libero), (characterData.stateText || ""), characterData.isPng, characterData.isBanned, characterData.lastMessageSent, characterData.myPrivateChat, (characterData.clan || ""), characterData.clanLvl, (characterData.corp || ""), characterData.corpLvl, characterData.isActive, characterData.isCapoClan, characterData.isCapoCorp, characterData.isCapoRace, ip);
        const presenceStatusJSON = JSON.parse(JSON.stringify(presenceSatus));

        const userPresenceDoc = self.afdb.object('status/' + characterData.uid + '/state');
        userPresenceDoc.update(presenceStatusJSON);
        //#endregion - update presence status

        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

    // this.firestore.write('users/' + characterDataJSON.uid, characterDataJSON).subscribe(() => {
    //   self.presenceService.updateAUserInfos(character, true);
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });

    this.modalUserData = undefined;
  }


}
