import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { LayoutState } from '../../../modules/utilities/utilities.constants';


@Component({
  selector: 'login-error-component',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.less'],
})
export class LoginErrorComponent implements OnInit, AfterViewInit, OnDestroy {

  loginMaxWidth: number = 0;
  loginHeight: number = 0;
  homeEsternaPaddingTop: number = 0;
  @ViewChild('login', { static: false }) private loginElem: ElementRef;


  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public ngOnDestroy() {
  }

  backToLogin() {
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Login));
  }


  public onResize(event?: any) {
    let windowsHeight = window.innerHeight;
    let windowsWidth = window.innerWidth;

    // let height: number = this.mainMapContainer.nativeElement.offsetHeight;
    let height: number = (windowsWidth / 100) * 70;
    // let width: number = this.mainMapContainer.nativeElement.offsetWidth;
    let parentHeight: number = this.loginElem.nativeElement.parentElement.offsetHeight;
    let parentPadding = (parentHeight / 100) * 10;
    parentHeight = parentHeight - parentPadding;

    if (height > parentHeight)
      height = parentHeight;

    if (height > 660)
      height = 660;

    if (height < 200)
      height = 200;

    let maxWidth = height * 1.075;
    if (maxWidth > 710)
      maxWidth = 710;

    this.loginMaxWidth = maxWidth;
    this.loginHeight = height;
    this.homeEsternaPaddingTop = parentPadding;
    this.cdr.detectChanges();
  }

} 