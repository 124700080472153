import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { first } from 'rxjs/operators';

@Component({
  selector: 'cPanel-debug-log',
  templateUrl: 'cPanel-debug-log.component.html',
  styleUrls: ['cPanel-debug-log.component.less'],
})
export class CPanelDebugLogComponent implements OnInit, OnDestroy {
  //#region - other variables
  public settings = {
    actions: {
      add: false,
      edit: false,
      delete: true,
    },
    columns: {
      // userID: {
      //   title: 'User ID',
      //   editable: false
      // },
      userName: {
        title: 'User',
        editable: false
      },
      isCP: {
        title: 'da cPanel',
        editable: false
      },
      component: {
        title: 'Component',
        editable: false
      },
      collection: {
        title: 'Collection',
        editable: false
      },
      number: {
        title: 'Qta',
        editable: false
      },
      message: {
        title: 'Messaggio',
        editable: false
      }
    }
  };
  public logsEntry: any[] = [];
  public dataSource: LocalDataSource;
  //#endregion - other variables

  //#region - get logs
  public logsCollections: AngularFireList<any>;
  public logsCollectionsObservable: Observable<any[]>
  private logsSubscription: Subscription;
  //#endregion - get logs

  constructor(
    private toastr: ToastrService,
    private afdb: AngularFireDatabase
  ) {

  }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    this.logsCollections = this.afdb.list<any>('/debugLog');
    this.logsCollectionsObservable = this.logsCollections.valueChanges();
    this.logsSubscription = this.logsCollectionsObservable.pipe(first()).subscribe((logs: any[]) => {
      if (Functions.IsNullOrUndefined(logs)) {
        self.logsEntry = [];
      } else {
        self.logsEntry = logs;
        self.dataSource.load(self.logsEntry);
      }
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.logsSubscription) == false)
      this.logsSubscription.unsubscribe();
  }

}
