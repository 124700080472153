import { CharacterSheetData, Role, UserPresence, PrivateConversation } from './../models/data/application.data';
import * as pm from '../actions/pm';
import { Functions } from '../modules/utilities/functions/utilities.functions';

export interface State {
  pms: PrivateConversation[];
  selectedPM: PrivateConversation;
  convWithNewMessages: string[];
  showOnPMs: boolean;
}

export const initialState: State = {
  pms: undefined,
  selectedPM: undefined,
  convWithNewMessages: [],
  showOnPMs: true
};

export function reducer(state: State = initialState, action: pm.Actions): State {
  switch (action.type) {

    case pm.STORE_PMS:
      const allPMs: PrivateConversation[] = action.payload as PrivateConversation[];
      const updatedPMs: PrivateConversation[] = Object.assign([], state.pms).concat(allPMs);
      return Object.assign({}, state, {
        pms: updatedPMs
      });

    case pm.UPDATE_STORED_PMS:
      const anUpdatedPM: PrivateConversation = action.payload as PrivateConversation;

      let actualPMs: PrivateConversation[] = Object.assign([], state.pms);
      actualPMs = actualPMs.filter((aConv: PrivateConversation) => aConv.cnvUID != anUpdatedPM.cnvUID);

      let PMsAfterUpdate: PrivateConversation[] = [anUpdatedPM];
      PMsAfterUpdate = PMsAfterUpdate.concat(actualPMs);

      return Object.assign({}, state, {
        pms: PMsAfterUpdate
      });

    case pm.DELETE_STORED_PM:
      const PMToDelete: string = action.payload as string;

      let allActualPMs: PrivateConversation[] = Object.assign([], state.pms);
      allActualPMs = allActualPMs.filter((aConv: PrivateConversation) => aConv.cnvUID != PMToDelete);

      return Object.assign({}, state, {
        pms: allActualPMs
      });

    case pm.SELECT_PM:
      const selectedPM: PrivateConversation = action.payload as PrivateConversation;
      return Object.assign({}, state, {
        selectedPM: selectedPM,
      });

    case pm.STORE_CONVS_WITH_NEW_PMS:
      const storeConvsWithNewPMs: string[] = action.payload as string[];
      return Object.assign({}, state, {
        convWithNewMessages: storeConvsWithNewPMs
      });

    case pm.STORE_SHOW_ON_PMS:
      const showOn: boolean = action.payload as boolean;
      return Object.assign({}, state, {
        showOnPMs: showOn
      });

    case pm.RESET_PM_STORE:
      return Object.assign({}, state, {
        pms: [],
        selectedPM: undefined,
        convWithNewMessages: [],
        showOnPMs: true
      });

    default: {
      return state;
    }
  }
}

export const getPMs: (state: State) => PrivateConversation[] = (state: State) => state.pms;
export const getSelectedPM: (state: State) => PrivateConversation = (state: State) => state.selectedPM;
export const getNewPM: (state: State) => string[] = (state: State) => state.convWithNewMessages;
export const getShowOnPMs: (state: State) => boolean = (state: State) => state.showOnPMs;
