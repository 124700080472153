import { CPanelService } from './../../services/cpanel.service';
import { Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CPanelState } from '../../../utilities/utilities.constants';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as layoutSelector from './../../../../selectors/layout.selectors';
import * as characterSelector from './../../../../selectors/character.selectors';
import * as layout from './../../../../actions/layout';
import { Role, CharacterSheetData } from '../../../../models/data/application.data';
import { Functions } from '../../../utilities/functions/utilities.functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { User } from 'firebase/app';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-sidenav-menu',
  templateUrl: 'cPanel-sidenav.component.html',
  styleUrls: ['cPanel-sidenav.component.less'],
})
export class CPanelSidenavComponent implements OnDestroy {

  private moduleName: string = "cPanelSidenav";

  public cPanelState$: Observable<CPanelState>;
  public myRole$: Observable<Role>;
  public isCapoClan$: Observable<boolean>;
  public isCapoCorp$: Observable<boolean>;
  public isCapoRace$: Observable<boolean>;
  public permissionSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    private cPanelService: CPanelService,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
    this.cPanelState$ = this.store.select(layoutSelector.getCPanelState);
    this.myRole$ = this.store.select(characterSelector.getUserRole);
    this.isCapoClan$ = this.store.select(characterSelector.getIsCapoClan);
    this.isCapoCorp$ = this.store.select(characterSelector.getIsCapoCorp);
    this.isCapoRace$ = this.store.select(characterSelector.getIsCapoRace);
  }

  changeCPanelState(cPanelState: CPanelState) {
    if (this.cPanelService.waiting == true)
      return;

    if (Functions.IsNullOrUndefined(this.permissionSubscription) == false) {
      this.permissionSubscription.unsubscribe();
    }

    const self: this = this;
    this.afAuth.authState.pipe(first())
      .subscribe((userAuth: User) => {

        const playerDoc: AngularFirestoreDocument<CharacterSheetData> = self.afs.doc<any>('users/' + userAuth.uid);
        const playerDocObservable: Observable<CharacterSheetData> = playerDoc.valueChanges();
        playerDocObservable.pipe(first())
          .subscribe((aSheet: CharacterSheetData) => {

            const roleRequirement: any = self.cPanelService.permissionLevelRequired(cPanelState);
            if (self.debugLogger.isAuditing) {
              self.debugLogger.logRead(true, "READ user permission cPanel", self.moduleName, "users", 1);
            }

            if (Functions.IsNullOrUndefined(aSheet) || Functions.IsNullOrUndefined(aSheet.role)) {
              self.goToLand();
              return;
            }

            if (aSheet.role <= roleRequirement.roleRequired) {
              self.store.dispatch(new layout.ChangeCPanelStateAction(cPanelState));
            } else {

              if (roleRequirement.capoClan && aSheet.isCapoClan == true) {
                self.store.dispatch(new layout.ChangeCPanelStateAction(cPanelState));
                return;
              }

              if (roleRequirement.capoCorp && aSheet.isCapoCorp == true) {
                self.store.dispatch(new layout.ChangeCPanelStateAction(cPanelState));
                return;
              }

              if (roleRequirement.capoRace && Functions.IsNullOrUndefined(aSheet.isCapoRace) == false && aSheet.isCapoRace.length > 0) {
                self.store.dispatch(new layout.ChangeCPanelStateAction(cPanelState));
                return;
              }

              self.goToLand();
            }
          })

      })


    // this.permissionSubscription = this.cPanelService.checkPermissions(cPanelState).subscribe((permissionAccepted: boolean) => {
    //   if (permissionAccepted == true)
    //     self.store.dispatch(new layout.ChangeCPanelStateAction(cPanelState));
    //   else
    //     self.goToLand();
    // })
    // this.store.dispatch(new layout.ChangeCPanelStateAction(cPanelState));
  }

  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.permissionSubscription) == false)
      this.permissionSubscription.unsubscribe();
  }

  goToLand() {
    if (this.cPanelService.waiting == true)
      return;

    if (fromRoot.getState(this.store).datas.pendingCPUpdates) {
      this.cPanelService.updateDBVersion();
      this.store.dispatch(new layout.GoToHomeInternaAction());
    } else {
      this.store.dispatch(new layout.GoToHomeInternaAction());
    }
  }

}
