import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import * as layout from './../../../actions/layout';
import { LayoutState } from '../../../modules/utilities/utilities.constants';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'loading-component',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less']
})

export class LoadingComponent implements AfterViewInit {
  private readonly moduleName: string = "LoadingComponent";
  public layoutUIState$: Observable<LayoutState>;

  constructor(private store: Store<fromRoot.State>,
    private authenticationService: AuthenticationService
  ) {
  }

  public ngAfterViewInit() {
    let self: this = this;
    setTimeout(() => {
      self.store.dispatch(new layout.GoToHomeEsternaAction());
    }, 0);
    // this.layoutUIState$ = this.store.select(layoutSelector.getLayoutUIState);
  }

}
