import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, PrivateConversation, UserPresence, UserEntry, PMUI, PrivateMessage, PrivateMessageUI } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import * as firebase from 'firebase/app';

@Component({
  selector: 'cPanel-log-messaggi',
  templateUrl: 'cPanel-log-messaggi.component.html',
  styleUrls: ['cPanel-log-messaggi.component.less'],
})
export class CPanelLogMessaggiComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelLogMessaggi";

  //#region - PMs variables
  public selectedConv: PMUI;
  public currentPMs: PrivateConversation[] = [];
  public openMessages: any = {};

  private PMsCollection: AngularFirestoreCollection<PrivateConversation>;
  private PMsCollectionObservable: Observable<PrivateConversation[]>;
  private getPMsSubscription: Subscription;
  //#endregion - PMs variables

  //#region - datepicker variables
  public myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    inline: true
  };
  public locale: string = "it";
  public date: IMyDateModel = null;
  //#endregion - datepicker variables

  //#region - other variables
  public usernameValue: string;
  public isChecked: string[] = [];
  public searchingUser: string = "";
  public searchingStartDate: any = undefined;
  public searchingEndDate: any = undefined;
  //#endregion - other variables


  private downloadingFlag: boolean = false;

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private afdb: AngularFireDatabase,
    private debugLogger: DebugLoggerService
  ) {

  }


  public ngOnInit() {

    // this.getMyConversation();

    // this.myUID = fromRoot.getState(this.store).character.myCharacterData.uid;
    // this.myRole = fromRoot.getState(this.store).character.myCharacterData.role;

    // this.standardChatsList$ = this.store.select(layoutSelector.getStandardChatsList).map((chats: StandardChat[]) => {
    //   const sortedChat: StandardChat[] = chats.sort(function (a, b) {
    //     var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
    //     if (nameA < nameB) //sort string ascending
    //       return -1;
    //     if (nameA > nameB)
    //       return 1;
    //     return 0; //default return value (no sorting)
    //   });

    //   return sortedChat;
    // });


  }

  public ngOnDestroy() {
    if(Functions.IsNullOrUndefined(this.getPMsSubscription) == false) {
      this.getPMsSubscription.unsubscribe();
    }
  }

  public delete() {
    if (window.confirm('Sei sicuro di voler cancellare i messaggi selezionati?')) {
      if (this.isChecked.length <= 0)
        return;

      for (let index: number = 0; index < this.isChecked.length; index++) {
        const currentPMiD: string = this.isChecked[index];
        if (Functions.IsStringEmpty(currentPMiD))
          continue;

        const self: this = this;
        const convToRemove: AngularFirestoreDocument<PrivateMessage> = this.afs.doc<any>('privateConversations/' + currentPMiD);
        convToRemove.delete()
          .then(() => {

          })
          .catch((error: any) => {
            self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
          });

        // this.firestore.remove("privateConversations/" + currentPMiD);
        this.currentPMs = this.currentPMs.filter((aPM: PrivateConversation) => aPM.cnvUID != currentPMiD);
      }

      this.isChecked = [];
    } else {
      //do nothing;
    }
  }

  public onScroll() {
    this.getMyConversation();
  }

  public getMyConversation() {
    const self: this = this;
    let queryType: number = 0; // base query without searching

    // searching for a user
    if (Functions.IsStringEmpty(this.usernameValue)) {
      this.searchingUser = "";
    } else {
      const foundedUserEntry: UserEntry = fromRoot.getState(this.store).datas.usersList.find((aUser: UserEntry) => aUser.name.toLowerCase().includes(this.usernameValue.toLowerCase()));
      if (Functions.IsNullOrUndefined(foundedUserEntry) == false) {
        this.searchingUser = foundedUserEntry.uid;
        queryType = 1; // query searching for user
      } else {
        const mySheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
        if (mySheet.name.toLowerCase().includes(this.usernameValue.toLowerCase())) {
          this.searchingUser = mySheet.uid;
          queryType = 1; // query searching for user
        }
      }
    }

    // searching for a date
    if (Functions.IsNullOrUndefined(this.date)) {
      this.searchingStartDate = undefined;
    } else {
      let startingDate: Date = this.date.singleDate.jsDate;
      startingDate.setDate(startingDate.getDate() + 1);
      const newxtDate = new Date(startingDate);
      newxtDate.setDate(startingDate.getDate() - 1);

      this.searchingStartDate = Functions.ToOADate(new Date(startingDate));
      this.searchingEndDate = Functions.ToOADate(new Date(newxtDate));
      if (queryType == 1)
        queryType = 3; // query searching for user and date
      else
        queryType = 2; // query searching for date
    }

    switch (queryType) {
      case 0:
        this.getConversationWithoutSearching();
        break;

      case 1:
        this.getConversationWithUser();
        break;

      case 2:
        this.getConversationWithDate();
        break;

      case 3:
        this.getConversationWithUserAndDate();
        break;

      default:
        break;
    }
  }

  public getConversationWithoutSearching() {
    if (this.downloadingFlag == true)
      return;
    else
      this.downloadingFlag = true;

    const self: this = this;
    if (Functions.IsNullOrUndefined(this.currentPMs) || this.currentPMs.length == 0) {
      // // no pms in memory
      this.downloadingFlag = true;
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgPTS", "desc").limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });

    } else {
      this.currentPMs = Functions.sortPMs(this.currentPMs);
      const lastLocalPmTS: any = this.currentPMs[this.currentPMs.length - 1].lMsgPTS;

      this.downloadingFlag = true;
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgPTS", "desc").startAfter(lastLocalPmTS).limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });
    }
  }

  public getConversationWithUser() {
    if (this.downloadingFlag == true)
      return;
    else
      this.downloadingFlag = true;

    const self: this = this;
    if (Functions.IsNullOrUndefined(this.currentPMs) || this.currentPMs.length == 0) {
      // no pms in memory
      this.downloadingFlag = true;
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", this.searchingUser).orderBy("lMsgPTS", "desc").limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });

    } else {
      this.currentPMs = Functions.sortPMs(this.currentPMs);
      const lastLocalPmTS: any = this.currentPMs[this.currentPMs.length - 1].lMsgPTS;

      this.downloadingFlag = true;
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", this.searchingUser).orderBy("lMsgPTS", "desc").startAfter(lastLocalPmTS).limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });
    }
  }

  public getConversationWithDate() {
    if (this.downloadingFlag == true)
      return;
    else
      this.downloadingFlag = true;

    const self: this = this;
    // const convertedStartDate: any = firebase.firestore.Timestamp.fromDate(Functions.FromOADate(this.searchingStartDate));
    // const convertedEndDate: any = firebase.firestore.Timestamp.fromDate(Functions.FromOADate(this.searchingEndDate));

    const convertedStartDate: any = Functions.ToOADate(Functions.FromOADate(this.searchingStartDate));
    const convertedEndDate: any = Functions.ToOADate(Functions.FromOADate(this.searchingEndDate));


    if (Functions.IsNullOrUndefined(this.currentPMs) || this.currentPMs.length == 0) {
      // no pms in memory
      this.downloadingFlag = true;

      // this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgTS", "desc").startAt(convertedStartDate).limit(10));
      // this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgTS", "desc").startAt(convertedStartDate).endBefore(convertedEndDate));
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgPTS", "desc").startAt(convertedStartDate).endBefore(convertedEndDate).limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });

    } else {
      this.currentPMs = Functions.sortPMs(this.currentPMs);
      const lastLocalPmTS: any = this.currentPMs[this.currentPMs.length - 1].lMsgPTS;

      const endDate: Date = Functions.FromOADate(this.searchingStartDate);
      endDate.setDate(endDate.getDate() - 4);
      const fixedEndDate: any = firebase.firestore.Timestamp.fromDate(endDate);

      this.downloadingFlag = true;
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgPTS", "desc").startAfter(lastLocalPmTS).limit(10));
      // this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgTS", "desc").startAfter(lastLocalPmTS).endBefore(fixedEndDate).limit(10));
      // this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.orderBy("lMsgTS", "desc").startAfter(lastLocalPmTS).endBefore(convertedEndDate).limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });

    }
  }

  public getConversationWithUserAndDate() {
    if (this.downloadingFlag == true)
      return;
    else
      this.downloadingFlag = true;


    const self: this = this;
    // const convertedStartDate: any = firebase.firestore.Timestamp.fromDate(Functions.FromOADate(this.searchingStartDate));
    // const convertedEndDate: any = firebase.firestore.Timestamp.fromDate(Functions.FromOADate(this.searchingEndDate));
    const convertedStartDate: any = Functions.ToOADate(Functions.FromOADate(this.searchingStartDate));
    const convertedEndDate: any = Functions.ToOADate(Functions.FromOADate(this.searchingEndDate));

    if (Functions.IsNullOrUndefined(this.currentPMs) || this.currentPMs.length == 0) {
      // no pms in memory
      this.downloadingFlag = true;
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", this.searchingUser).orderBy("lMsgPTS", "desc").startAt(convertedStartDate).endBefore(convertedEndDate).limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });

    } else {
      this.currentPMs = Functions.sortPMs(this.currentPMs);
      const lastLocalPmTS: any = this.currentPMs[this.currentPMs.length - 1].lMsgPTS;

      const endDate: Date = Functions.FromOADate(this.searchingStartDate);
      endDate.setDate(endDate.getDate() - 2);
      const fixedEndDate: any = firebase.firestore.Timestamp.fromDate(endDate);

      this.downloadingFlag = true;
      this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", this.searchingUser).orderBy("lMsgPTS", "desc").startAfter(lastLocalPmTS).limit(10));
      // this.PMsCollection = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", this.searchingUser).orderBy("lMsgTS", "desc").startAfter(lastLocalPmTS).endBefore(fixedEndDate).limit(10));
      this.PMsCollectionObservable = this.PMsCollection.valueChanges();
      this.getPMsSubscription = this.PMsCollectionObservable.pipe(first()).subscribe((values) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ all conversation cpanel", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        this.currentPMs = this.currentPMs.concat(values);
        self.downloadingFlag = false;
      });

    }
  }

  public changeValue(event: any, cnvUID: string) {
    if (event.checked) {
      this.isChecked.push(cnvUID);
    } else {
      this.isChecked = this.isChecked.filter((aValue: string) => aValue != cnvUID);
    }
  }

  public openConversation(cnv: PrivateConversation) {
    if (Functions.IsNullOrUndefined(cnv) == true)
      return;

    const usersPresence: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const userPresenceMap: Map<string, UserPresence> = new Map();
    for (let index: number = 0; index < usersPresence.length; index++) {
      const aUser: UserPresence = usersPresence[index];
      if (Functions.IsNullOrUndefined(aUser.state) == false) {
        userPresenceMap.set(aUser.state.playerUID, aUser);
      }
    }

    const mittente: UserEntry = new UserEntry();
    const mittentePresence: UserPresence = userPresenceMap.get(cnv.ownUID);
    mittente.uid = cnv.ownUID;
    mittente.name = (Functions.IsNullOrUndefined(mittentePresence) == false) ? mittentePresence.state.name : "Utente Cancellato";

    const newCNVUI: PMUI = new PMUI(cnv.cnvUID, cnv.onOff, cnv.titolo, mittente, [], cnv.targetsUID, cnv.conRiposta);

    for (let index: number = 0; index < cnv.destUID.length; index++) {
      const aDestUID: string = cnv.destUID[index];
      const aPresent: UserPresence = userPresenceMap.get(aDestUID);

      if (Functions.IsNullOrUndefined(aPresent) || Functions.IsNullOrUndefined(aPresent.state))
        continue;

      const userEntry: UserEntry = new UserEntry();
      userEntry.uid = aPresent.state.playerUID;
      userEntry.name = aPresent.state.name;
      newCNVUI.dest.push(userEntry);
    }

    newCNVUI.messages = [];

    for (let index: number = 0; index < cnv.messages.length; index++) {
      const aMessage: PrivateMessage = cnv.messages[index];
      if (Functions.IsNullOrUndefined(aMessage))
      continue;

      const aPresent: UserPresence = userPresenceMap.get(aMessage.ownUID);

      if (Functions.IsNullOrUndefined(aPresent))
        continue;

      const owner: UserEntry = new UserEntry();
      owner.uid = aMessage.ownUID;
      owner.name = aPresent.state.name;
      owner.img = aPresent.state.miniAvatarUrl;

      const aMessageUI: PrivateMessageUI = new PrivateMessageUI(aMessage.msgUID, owner, aMessage.dateHour, aMessage.message);
      newCNVUI.messages.push(aMessageUI);
    }

    //sorting messages
    newCNVUI.messages = newCNVUI.messages.reverse();

    this.selectedConv = newCNVUI;
  }

  openAMessage(pmUID: string) {
    if (this.openMessages[pmUID] == undefined) {
      this.openMessages[pmUID] = pmUID;
    } else {
      this.openMessages[pmUID] = undefined;
    }

  }

  onDateChanged(event: IMyDateModel): void {
    // date selected
    this.date = event;
  }

  public startSearch() {
    this.currentPMs = [];
    this.isChecked = [];
    this.openMessages = {};
    this.selectedConv = undefined;

    if (Functions.IsNullOrUndefined(this.getPMsSubscription) == false)
      this.getPMsSubscription.unsubscribe();

    this.getMyConversation();
  }

  public resetField() {
    this.usernameValue = "";
    this.date = null;
    this.searchingUser = "";
    this.searchingStartDate = undefined;
    this.searchingEndDate = undefined;

    this.currentPMs = [];
    this.isChecked = [];
    this.openMessages = {};
    this.selectedConv = undefined;

    if (Functions.IsNullOrUndefined(this.getPMsSubscription) == false)
      this.getPMsSubscription.unsubscribe();
  }
}
