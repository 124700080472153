import { LocalDataSource } from 'ng2-smart-table';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { Item, CharacterSheetData, CharacterItems, UserPresence, PresencePlayerStatus } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-assegna-oggetto-a-personaggio',
  templateUrl: 'cPanel-assegna-oggetto-a-personaggio.component.html',
  styleUrls: ['cPanel-assegna-oggetto-a-personaggio.component.less'],
})
export class CPanelAssegnaOggettoAPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelAssegnaOggetto"
  //#region - firestore get users
  public usersPresence$: Observable<UserPresence[]>;
  private usersPresenceSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  //#endregion - firestore get users

  //#region - firestore get BM
  private itemsCollection: AngularFirestoreCollection<Item>;
  private itemsCollectionObservable: Observable<Item[]>;
  private itemsSubscription: Subscription;
  private itemsMap: Map<string, Item>;
  //#endregion - firestore get BM

  //#region - selected character variables
  private playerToEditDoc: AngularFirestoreDocument<CharacterSheetData>;
  private playerToEditDocObservable: Observable<CharacterSheetData>;
  private playerToEditDocSubscription: Subscription;
  public selectedCharacterID: string;
  public selectedCharacter: CharacterSheetData;
  //#region - selected character variables

  //#region - other variables
  public mergedDataSource: LocalDataSource;
  public mergedData: any[] = [];
  public settings = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      qta: {
        title: 'Quantità'
      },
      name: {
        title: 'Nome Oggetto',
        editable: false,
        valuePrepareFunction: (uid: string) => {
          if (Functions.IsNullOrUndefined(this.itemsMap.get(uid)))
            return ""
          else
            return this.itemsMap.get(uid).name;
        },
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      },
      remainingUse: {
        title: 'Utilizzi Rimanenti',
        defaultValue: 0,
        valuePrepareFunction: (value: number) => {
          if (value == 0)
            return "infiniti"
          else
            return value;
        },
      },
      maxUse: {
        title: 'Numero Massimo di Utilizzi',
        defaultValue: 0,
        valuePrepareFunction: (value: number) => {
          if (value == 0)
            return "infiniti"
          else
            return value;
        },
        editable: false,
        addable: false
      },
      image: {
        title: 'Immagine Oggetto',
        filter: false,
        editable: false,
        addable: false,
        type: 'html',
        editor: {
          type: 'textarea'
        },
        valuePrepareFunction: (picture: string) => { return `<img width="50px" src="${picture}" />`; },
      }
    }
  };
  //#ENDregion - other variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }


  public ngOnInit() {
    let self = this;
    this.mergedDataSource = new LocalDataSource();

    //#region - get users
    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersPresenceSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }),
        first()
      ).subscribe((data: PresencePlayerStatus[]) => {
        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.characters = data;
        self.createMergedUserItemsData();
      });

    //#endregion - get users

    //#region - get BMs
    this.itemsCollection = this.afs.collection<Item>('items');
    this.itemsCollectionObservable = this.itemsCollection.valueChanges();
    this.itemsSubscription = this.itemsCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all items cpanel", self.moduleName, "items", (data as any[]).length);
      }

      self.itemsMap = new Map();
      for (let index: number = 0; index < data.length; index++) {
        self.itemsMap.set(data[index].uid, data[index]);
      }
    });
    //#endregion - get BMs
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersPresenceSubscription) == false) {
      this.usersPresenceSubscription.unsubscribe();
    }
    if (Functions.IsNullOrUndefined(this.itemsSubscription) == false) {
      this.itemsSubscription.unsubscribe();
    }
  }

  public onUserChange(character: PresencePlayerStatus) {
    if (Functions.IsNullOrUndefined(character) == true) {
      this.selectedCharacter = undefined;
      this.createMergedUserItemsData();
      return;
    }

    const self: this = this;
    this.playerToEditDoc = this.afs.doc<any>('users/' + character.playerUID);
    this.playerToEditDocObservable = this.playerToEditDoc.valueChanges();
    this.playerToEditDocSubscription = this.playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
        }

        self.selectedCharacter = selectedCharacter;
        self.createMergedUserItemsData();
      });
    // this.selectedCharacter = character;
    // this.createMergedUserItemsData();
  }

  private createMergedUserItemsData() {

    if (Functions.IsNullOrUndefined(this.selectedCharacter))
      return;

    if (Functions.IsNullOrUndefined(this.itemsMap) || this.itemsMap.size == 0)
      return;

    let selectedCharacterItems: CharacterItems[] = this.selectedCharacter.userItems || [];
    let itemsToBeDisplayed: any[] = [];

    for (let index: number = 0; index < selectedCharacterItems.length; index++) {
      const existingItem: Item = this.itemsMap.get(selectedCharacterItems[index].uid);
      if (Functions.IsNullOrUndefined(existingItem) == false) {
        itemsToBeDisplayed.push(
          {
            uid: selectedCharacterItems[index].uid,
            qta: selectedCharacterItems[index].qta,
            remainingUse: selectedCharacterItems[index].remainingUse,
            // name: this.itemsMap.get(selectedCharacterItems[index].uid).name,
            name: selectedCharacterItems[index].uid,
            maxUse: existingItem.maxUse,
            image: existingItem.image
          }
        )
      }
    }
    this.mergedData = itemsToBeDisplayed;
    this.mergedDataSource.load(itemsToBeDisplayed);

    this.setUpItemNameEditorList();
  }

  private setUpItemNameEditorList() {
    let displayedItemIDsSet: Set<string> = new Set();

    for (let index: number = 0; index < this.mergedData.length; index++) {
      displayedItemIDsSet.add(this.mergedData[index].uid);
    }

    //available list add
    let allItems: Item[] = Array.from(this.itemsMap.values());
    let filteredItems: Item[] = allItems.filter((anItem: Item) => displayedItemIDsSet.has(anItem.uid) == false);
    let filteredItemsEditList: any[] = [];

    for (let index: number = 0; index < filteredItems.length; index++) {
      filteredItemsEditList.push(
        { value: filteredItems[index].uid, title: filteredItems[index].name }
      )
    }

    // sorting alphabetically
    filteredItemsEditList = filteredItemsEditList.sort(function (a, b) {
      var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settings.columns.name.editor.config.list = filteredItemsEditList;
    this.settings = Object.assign({}, this.settings);
  }

  public onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      let selectedCharacterItems: CharacterItems[] = this.selectedCharacter.userItems || [];
      let newList: CharacterItems[] = selectedCharacterItems.filter((aCharacterItem: CharacterItems) => aCharacterItem.uid != event.data.uid);
      this.selectedCharacter.userItems = newList;
      let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));

      let self = this;
      this.playerToEditDoc.update(updatedUserJsonData)
        .then(() => {
          self.toastr.success('Elemento Eliminato!');

          self.playerToEditDocSubscription = self.playerToEditDocObservable.pipe(first())
            .subscribe((selectedCharacter: CharacterSheetData) => {
              if (self.debugLogger.isAuditing) {
                self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
              }

              self.selectedCharacter = selectedCharacter;
              self.createMergedUserItemsData();
            });
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });

    } else {
      event.confirm.reject();
    }
  }

  public onCreateConfirm(event) {
    event.confirm.resolve();

    let selectedCharacterItems: CharacterItems[] = this.selectedCharacter.userItems || [];
    let newCharacterItem: CharacterItems = new CharacterItems();
    newCharacterItem.qta = (parseInt(event.newData.qta) || 1);
    if (Functions.IsNullOrUndefined(event.newData.remainingUse) == true || event.newData.remainingUse == 0 || event.newData.remainingUse > event.newData.maxUse) {
      newCharacterItem.remainingUse = (this.itemsMap.get(event.newData.name).maxUse || 0);
    } else {
      newCharacterItem.remainingUse = parseInt(event.newData.remainingUse);
    }
    newCharacterItem.uid = event.newData.name;
    selectedCharacterItems.push(newCharacterItem);
    this.selectedCharacter.userItems = selectedCharacterItems;

    let self = this;
    let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));
    this.playerToEditDoc.update(updatedUserJsonData)
      .then(() => {
        self.toastr.success('Elemento Creato!');
        self.playerToEditDocSubscription = self.playerToEditDocObservable.pipe(first())
          .subscribe((selectedCharacter: CharacterSheetData) => {
            if (self.debugLogger.isAuditing) {
              self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
            }

            self.selectedCharacter = selectedCharacter;
            self.createMergedUserItemsData();
          });

      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

    // this.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });

  }

  public onEditConfirm(event) {
    event.confirm.resolve();

    let selectedCharacterItems: CharacterItems[] = this.selectedCharacter.userItems || [];
    let updatedItem: CharacterItems = selectedCharacterItems.find((aCharacterItem: CharacterItems) => aCharacterItem.uid == event.data.uid);
    updatedItem.qta = (parseInt(event.newData.qta) || 1);
    if (Functions.IsNullOrUndefined(event.newData.remainingUse) == true || event.newData.remainingUse == 0 || event.newData.remainingUse > event.newData.maxUse) {
      updatedItem.remainingUse = (this.itemsMap.get(event.newData.name).maxUse || 0);
    } else {
      updatedItem.remainingUse = parseInt(event.newData.remainingUse);
    }
    let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));

    let self = this;
    this.playerToEditDoc.update(updatedUserJsonData)
      .then(() => {
        self.toastr.success('Modifica Salvata!');
        self.playerToEditDocSubscription = self.playerToEditDocObservable.pipe(first())
          .subscribe((selectedCharacter: CharacterSheetData) => {
            if (self.debugLogger.isAuditing) {
              self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
            }

            self.selectedCharacter = selectedCharacter;
            self.createMergedUserItemsData();
          });

      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

    // this.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });

  }

}
