import { OnOff, PrivateConversation, PMUI } from './../../../models/data/application.data';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable } from "rxjs";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as pmSelector from './../../../selectors/pm.selectors';
import * as characterSelector from './../../../selectors/character.selectors';
import * as dataSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { UserEntry } from '../../../models/data/application.data';
import { PmService } from '../../../services/pm.service';

@Component({
  selector: 'leggi-messaggio-box',
  templateUrl: './leggi-messaggio.component.html',
  styleUrls: ['./leggi-messaggio.component.less']
})
export class LeggiMessaggioBoxComponent implements OnInit, OnDestroy {

  myUID$: Observable<string>;
  selectedConv$: Observable<PMUI>;
  replyMessage: string;
  showReply: boolean = false;
  openMessages: any = {};

  public sending: boolean = false;
  private tapedTwice: string = "";

  constructor(private store: Store<fromRoot.State>, private cd: ChangeDetectorRef, public pmService: PmService) {
    this.selectedConv$ = this.store.select(pmSelector.getSelectedPM);
    this.myUID$ = this.store.select(characterSelector.getMyUID);
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  openAMessage(pmUID: string) {
    if (this.openMessages[pmUID] == undefined) {
      this.openMessages[pmUID] = pmUID;
    } else {
      this.openMessages[pmUID] = undefined;
    }
  }

  sendPM() {
    if (Functions.IsStringEmpty(this.replyMessage))
      return;


    this.pmService.sending = true;

    this.pmService.replyConversation(this.replyMessage);
    this.replyMessage = "";
    this.showReply = false;
  }

  toggleReply() {
    this.showReply = true;
  }

  doubleTapHandler(event: any, msgUID: string) {
    if (this.tapedTwice != msgUID) {
      this.tapedTwice = msgUID;
      setTimeout((that: this) => {
        that.tapedTwice = "";
      }, 300, this);
      // return false;
    } else {
      // event.preventDefault();
      this.openAMessage(msgUID);
      this.tapedTwice = "";
    }
  }


}
