import { LogsService } from './../../../../services/logs.service';
import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

export enum ModalType {
  add = 0,
  remove = 1
}

export class ModalData {
  character: CharacterSheetData;
  type: ModalType;
  value: number;
  valueBank: number;
  title: string;
  why: string = "";
}


@Component({
  selector: 'cPanel-assegna-money',
  templateUrl: 'cPanel-assegna-money.component.html',
  styleUrls: ['cPanel-assegna-money.component.less'],
})
export class CPanelAssegnaMoneyComponent implements OnInit, OnDestroy {

  private moduleName: string = "cPanelAssegnaMoney";

  //#region - get users
  private usersCollection: AngularFirestoreCollection<CharacterSheetData>;
  private usersCollectionObservable: Observable<CharacterSheetData[]>;
  private usersSubscription: Subscription;
  //#endregion - get users

  //#region - other variables
  public dataSource: LocalDataSource;
  public settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'add',
          title: '<i class="material-icons icon-del access-pg">add</i> ',
        },
        {
          name: 'remove',
          title: '<i class="material-icons icon-edit block-pg">remove</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Personaggio',
        editable: false
      },
      money: {
        title: 'Soldi',
        editable: false
      },
      bank: {
        title: 'Soldi in banca',
        editable: false
      },
    }
  };
  //#endregion - other variables

  //#region - modal variables
  public isModalVisible: boolean = false;
  public modalUserData: ModalData;
  //#endregion - modal variables

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private logsService: LogsService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    //#region - get users
    this.usersCollection = this.afs.collection<CharacterSheetData>('users');
    this.usersCollectionObservable = this.usersCollection.valueChanges();
    this.usersSubscription = this.usersCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all users cpanel", self.moduleName, "users", (data as any[]).length);
      }
      // self.characters = data;
      self.dataSource.load(data);
    });
    //#endregion - get users
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersSubscription) == false)
      this.usersSubscription.unsubscribe();
  }

  public onCustom(event) {
    if (event.action == 'add') {
      let characterData: CharacterSheetData = event.data as CharacterSheetData;
      this.modalUserData = new ModalData();
      this.modalUserData.character = Object.assign({}, characterData);
      this.modalUserData.type = ModalType.add;
      this.modalUserData.value = 0;
      this.modalUserData.valueBank = 0;
      this.modalUserData.why = "";
      this.modalUserData.title = "Aggiungi Soldi";
      this.isModalVisible = true;

    } else if (event.action == 'remove') {
      let characterData: CharacterSheetData = event.data as CharacterSheetData;
      this.modalUserData = new ModalData();
      this.modalUserData.character = Object.assign({}, characterData);
      this.modalUserData.type = ModalType.remove;
      this.modalUserData.value = 0;
      this.modalUserData.valueBank = 0;
      this.modalUserData.why = "";
      this.modalUserData.title = "Rimuovi Soldi";
      this.isModalVisible = true;

    }
  }

  public closeModal() {
    this.isModalVisible = false;
    this.modalUserData = new ModalData();
  }

  public okModal() {
    if (Functions.IsNullOrUndefined(this.modalUserData) || Functions.IsNullOrUndefined(this.modalUserData.character))
      return;

    if (Functions.IsNullOrUndefined(this.modalUserData.type) || (Functions.IsNullOrUndefined(this.modalUserData.value) && Functions.IsNullOrUndefined(this.modalUserData.valueBank)))
      return;

    if (this.modalUserData.type == ModalType.add) {
      this.isModalVisible = false;

      let character: CharacterSheetData = this.modalUserData.character;
      let oldMoneyValue: string = (character.money || 0).toString();
      let oldBankValue: string = (character.bank || 0).toString();
      character.money = parseInt(oldMoneyValue) + this.modalUserData.value;
      character.bank = parseInt(oldBankValue) + this.modalUserData.valueBank;
      let characterDataJSON = JSON.parse(JSON.stringify(character));

      let self = this;
      const why: string = this.modalUserData.why;
      const value: number = this.modalUserData.value;
      const valueBank: number = this.modalUserData.valueBank;

      //#region - update value
      const playerToEditDoc = this.afs.doc<any>('users/' + characterDataJSON.uid);
      playerToEditDoc.update(characterDataJSON)
        .then(() => {
          const myname: string = fromRoot.getState(self.store).character.myCharacterData.name;
          if (Functions.IsNullOrUndefined(value) == false && value > 0)
            self.logsService.logBank(myname, character.name, value.toString(), "[CP] aggiunti ai soldi - " + why);

          if (Functions.IsNullOrUndefined(valueBank) == false && valueBank > 0)
            self.logsService.logBank(myname, character.name, valueBank.toString(), "[CP] aggiunti ai soldi in banca - " + why);

          self.toastr.success('Modifica Salvata!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })
      //#endregion - update value

      // this.firestore.write('users/' + characterDataJSON.uid, characterDataJSON).subscribe(() => {
      //   const myname: string = fromRoot.getState(self.store).character.myCharacterData.name;
      //   if (Functions.IsNullOrUndefined(value) == false && value > 0)
      //     self.logsService.logBank(myname, character.name, value.toString(), "[CP] aggiunti ai soldi - " + why);

      //   if (Functions.IsNullOrUndefined(valueBank) == false && valueBank > 0)
      //     self.logsService.logBank(myname, character.name, valueBank.toString(), "[CP] aggiunti ai soldi in banca - " + why);

      //   self.toastr.success('Modifica Salvata!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });

    } else {
      this.isModalVisible = false;

      let character: CharacterSheetData = this.modalUserData.character;
      let oldMoneyValue: string = (character.money || 0).toString();
      let oldBankValue: string = (character.bank || 0).toString();
      character.money = parseInt(oldMoneyValue) - this.modalUserData.value;
      character.bank = parseInt(oldBankValue) - this.modalUserData.valueBank;
      if (character.money < 0)
        character.money = 0;

      if (character.bank < 0)
        character.bank = 0;

      let characterDataJSON = JSON.parse(JSON.stringify(character));

      let self = this;
      const why: string = this.modalUserData.why;
      const value: number = this.modalUserData.value;
      const valueBank: number = this.modalUserData.valueBank;

      //#region - update value
      const playerToEditDoc = this.afs.doc<any>('users/' + characterDataJSON.uid);
      playerToEditDoc.update(characterDataJSON)
        .then(() => {
          const myname: string = fromRoot.getState(self.store).character.myCharacterData.name;
          if (Functions.IsNullOrUndefined(value) == false && value > 0)
            self.logsService.logBank(myname, character.name, value.toString(), "[CP] rimossi dai soldi - " + why);

          if (Functions.IsNullOrUndefined(valueBank) == false && valueBank > 0)
            self.logsService.logBank(myname, character.name, valueBank.toString(), "[CP] rimossi dai soldi in banca - " + why);

          self.toastr.success('Modifica Salvata!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })
      //#endregion - update value

      // this.firestore.write('users/' + characterDataJSON.uid, characterDataJSON).subscribe(() => {
      //   const myname: string = fromRoot.getState(self.store).character.myCharacterData.name;
      //   if (Functions.IsNullOrUndefined(value) == false && value > 0)
      //     self.logsService.logBank(myname, character.name, value.toString(), "[CP] rimossi dai soldi - " + why);

      //   if (Functions.IsNullOrUndefined(valueBank) == false && valueBank > 0)
      //     self.logsService.logBank(myname, character.name, valueBank.toString(), "[CP] rimossi dai soldi in banca - " + why);

      //   self.toastr.success('Modifica Salvata!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }

    this.modalUserData = new ModalData();
  }


}
