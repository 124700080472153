import { Functions } from './../../../utilities/functions/utilities.functions';
import { MeteoDay } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, Observable } from 'rxjs';

import { DAYS_OF_WEEK } from 'angular-calendar';
import { CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-meteo',
  templateUrl: 'cPanel-meteo.component.html',
  styleUrls: ['cPanel-meteo.component.less'],
})
export class CPanelMeteoComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelMeteo";

  //#region - calendar variables
  public meteoEvents: MeteoDay[] = []; // meteo days used by calendar
  public events: CalendarEvent[] = []; // fake because we do not use events
  public view: string = 'month';
  public viewDate: Date = new Date();
  public activeDayIsOpen: boolean = false;

  public locale: string = 'it';
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  public refresh: Subject<any> = new Subject();
  //#endregion - calendar variables

  //#region - modal variables
  public isModalVisible: boolean = false;
  public modalData: MeteoDay;
  //#endregion - modal variables

  //#region - meteo days collection variables
  private daysCollection: AngularFirestoreCollection<MeteoDay>;
  private daysCollectionObservable: Observable<MeteoDay[]>;
  private meteoSubscription: Subscription;
  //#endregion - meteo days collection variables

  //#region - meteo note variables
  private meteoNoteDoc: AngularFirestoreDocument<any>;
  private meteoNoteDocObservable: Observable<any>;
  private meteoNoteSubscription: Subscription;
  meteoText: any = { value: "" };
  config: any = {
    height: 550,
    menubar: false,
    theme: 'modern',
    plugins: [
      'advlist autolink link image imagetools lists charmap hr',
      'wordcount visualblocks visualchars code',
      'table contextmenu textcolor paste colorpicker',
    ],
    toolbar1:
      'bold italic underline strikethrough | undo redo | blockquote numlist bullist | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect | forecolor backcolor',
    toolbar2:
      'cut copy paste | outdent indent | link unlink image table | hr removeformat | subscript superscript | charmap | code',
    image_advtab: true,
    imagetools_toolbar:
      'rotateleft rotateright | flipv fliph | editimage imageoptions',
  };
  //#endregion - meteo note variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public ngOnInit() {
    let self = this;
    //#region - meteo days collection
    this.daysCollection = this.afs.collection<MeteoDay>('meteoDays');
    this.daysCollectionObservable = this.daysCollection.valueChanges();
    this.meteoSubscription = this.daysCollectionObservable.subscribe((data: MeteoDay[]) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all meteo days chats cpanel", self.moduleName, "meteoDays", (data as any[]).length);
      }

      self.meteoEvents = data;
    });
    //#endregion - meteo days collection

    //#region - meteo note
    this.meteoNoteDoc = this.afs.doc<any>('settings/meteoText');
    this.meteoNoteDocObservable = this.meteoNoteDoc.valueChanges();
    this.meteoNoteSubscription = this.meteoNoteDocObservable.subscribe((data: string) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ meteo text cPanel", self.moduleName, "settings/meteoText", 1);
      }


      if (Functions.IsNullOrUndefined(data) == false)
        self.meteoText = data;
    });
    //#endregion - meteo note
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.meteoSubscription) == false)
      this.meteoSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.meteoNoteSubscription) == false)
      this.meteoNoteSubscription.unsubscribe();
  }

  /**
   * Used by UI to compute meteo days
   * @param day a day
   */
  public getDayMeteo(day: any) {
    let dayNumber: string = new DatePipe(this.locale).transform(new Date(day.date), 'd', null, this.locale);
    let meteoDay: MeteoDay = this.meteoEvents.find((aMeteo: MeteoDay) => aMeteo.day == dayNumber);

    if (Functions.IsNullOrUndefined(meteoDay) == false)
      return meteoDay.meteoType;
    else
      return undefined;
  }

  /**
   * ui click event, openn modal
   */
  public dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    let dayNumber: string = new DatePipe(this.locale).transform(new Date(date), 'd', null, this.locale);
    let meteoDay: MeteoDay = this.meteoEvents.find((aMeteo: MeteoDay) => aMeteo.day == dayNumber);
    this.modalData = Object.assign({}, meteoDay);
    this.isModalVisible = true;
  }

  /**
   * ui click event
   */
  public closeModal() {
    this.isModalVisible = false;
    this.modalData = new MeteoDay();
  }

  /**
   * ui click event
   */
  public okModal() {
    if (Functions.IsNullOrUndefined(this.modalData.day) || Functions.IsStringEmpty(this.modalData.day))
      return;

    if (Functions.IsNullOrUndefined(this.modalData.meteoType))
      return;

    // if (Functions.IsNullOrUndefined(this.modalData.temp) || Functions.IsNullOrUndefined(this.modalData.wind) || Functions.IsNullOrUndefined(this.modalData.umid))
    //   return;

    let self: this = this;
    this.isModalVisible = false;
    let newMeteo: MeteoDay = new MeteoDay();
    newMeteo.day = this.modalData.day;
    newMeteo.meteoType = this.modalData.meteoType;
    newMeteo.temp = this.modalData.temp || "";
    newMeteo.wind = this.modalData.wind || "";
    newMeteo.umid = this.modalData.umid || "";
    newMeteo.marea = this.modalData.marea || "";

    let newMeteoDataJSON = JSON.parse(JSON.stringify(newMeteo));
    const newMeteoDataDoc: AngularFirestoreDocument<MeteoDay> = this.afs.doc<any>('meteoDays/' + newMeteoDataJSON.day);
    newMeteoDataDoc.update(newMeteoDataJSON)
      .then(() => {
        self.toastr.success('Modifica Salvata!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });
    this.modalData = new MeteoDay();

    // this.firestore.write('meteoDays/' + newMeteoDataJSON.day, newMeteoDataJSON).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
    // this.modalData = new MeteoDay();
  }

  /**
   * save meteo note
   */
  public salva() {
    if (Functions.IsNullOrUndefined(this.meteoText) == false && Functions.IsStringEmpty(this.meteoText.value) == false) {
      let self = this;
      let newMeteoText = JSON.parse(JSON.stringify(this.meteoText));
      this.meteoNoteDoc.update(newMeteoText)
        .then(() => {
          self.toastr.success('Testo Meteo Salvato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.write('settings/meteoText', newMeteoText).subscribe(() => {
      //   self.toastr.success('Testo Meteo Salvato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
  }

}
