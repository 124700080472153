import { CharacterSheetData, UserPresence, PresencePlayerStatus, SecretChat } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';

// Utilities
@Component({
  selector: 'uffici-crea-chat-component',
  templateUrl: './uffici-crea-chat.component.html',
  styleUrls: ['./uffici-crea-chat.component.less']
})

export class UfficiCreaChatComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiCreaChatComponent";

  cForm = new FormGroup({});
  subscription: Subscription;
  alreadyUsed: boolean = false;
  alreadyHaveAChat: boolean = false;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(private store: Store<fromRoot.State>, private cdr: ChangeDetectorRef, private fb: FormBuilder, private firestoreService: FirestoreService) {

  }

  public ngOnInit() {
    this.buildForm();

    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (Functions.IsNullOrUndefined(myCharacterSheet.myPrivateChat) || Functions.IsStringEmpty(myCharacterSheet.myPrivateChat.uid)) {
      this.alreadyHaveAChat = false;
    } else {
      this.alreadyHaveAChat = true;
    }

  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiCreaSecretChatAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

  // used to create form structure
  private buildForm() {
    this.cForm = this.fb.group({
      'cName': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)
      ]],
      'chatLocation': ['', [
        Validators.required,
        Validators.minLength(2)
      ]],
    });
  }

  public createChat() {
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (Functions.IsNullOrUndefined(myCharacterSheet.myPrivateChat) || Functions.IsStringEmpty(myCharacterSheet.myPrivateChat.uid)) {

      // check if male is right
      const name: string = this.cForm.value['cName'];
      const loc: string = this.cForm.value['chatLocation'];
      if (Functions.IsStringEmpty(name) || Functions.IsStringEmpty(loc))
        return;

      this.alreadyUsed = false;
      const presences: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
      for (let index: number = 0; index < presences.length; index++) {
        const aUserPresence: PresencePlayerStatus = presences[index].state;
        const aUserPresenceChat: SecretChat = aUserPresence.myPrivateChat;
        if (Functions.IsNullOrUndefined(aUserPresenceChat) == false && aUserPresenceChat.name.toLowerCase() == name.toLowerCase() && aUserPresenceChat.location.toLowerCase() == loc.toLowerCase()) {
          this.alreadyUsed = true;
        }
      }

      if (this.alreadyUsed == true)
        return;

      const myNewPrivateChat: SecretChat = new SecretChat();
      myNewPrivateChat.ownerUID = myCharacterSheet.uid;
      myNewPrivateChat.uid = Functions.CreateGuid();
      myNewPrivateChat.name = name;
      myNewPrivateChat.location = loc;
      myNewPrivateChat.accessibleByUIDs = [];

      let updatedCharacterData: CharacterSheetData = Object.assign({}, myCharacterSheet);
      updatedCharacterData.myPrivateChat = JSON.parse(JSON.stringify(myNewPrivateChat));
      this.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData, false);
      this.alreadyHaveAChat = true;
    }
  }

}
