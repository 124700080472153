import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-edit-credits',
  templateUrl: 'cPanel-edit-credits.component.html',
  styleUrls: ['cPanel-edit-credits.component.less'],
})
export class CPanelEditCreditsComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelEditCredits";

  credits: any = { value: "" };
  private creditsDoc: AngularFirestoreDocument<any>;
  private creditsObservable: Observable<any>;
  creditsSubscription: Subscription;
  config: any = {
    height: 550,
    menubar: false,
    theme: 'modern',
    plugins: [
      'advlist autolink link image imagetools lists charmap hr',
      'wordcount visualblocks visualchars code',
      'table contextmenu textcolor paste colorpicker',
    ],
    toolbar1:
      'bold italic underline strikethrough | undo redo | blockquote numlist bullist | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect | forecolor backcolor',
    toolbar2:
      'cut copy paste | outdent indent | link unlink image table | hr removeformat | subscript superscript | charmap | code',
    image_advtab: true,
    imagetools_toolbar:
      'rotateleft rotateright | flipv fliph | editimage imageoptions',
  };

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.creditsDoc = this.afs.doc<any>('settings/credits');
    this.creditsObservable = this.creditsDoc.valueChanges();
    this.creditsSubscription = this.creditsObservable.subscribe((data) => {
      // this.creditsSubscription = this.firestore.read('settings/credits').subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ credits cPanel", self.moduleName, "settings/credits", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.credits = data;
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.creditsSubscription) == false) {
      this.creditsSubscription.unsubscribe();
    }
  }

  salva() {
    if (Functions.IsNullOrUndefined(this.credits) == false) {
      let self = this;
      let newCredits = JSON.parse(JSON.stringify(this.credits));
      this.creditsDoc.update(newCredits)
        .then(() => {
          self.toastr.success('Elemento Creato!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.write('settings/credits', newCredits).subscribe(() => {
      //   self.toastr.success('Elemento Creato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }
  }
}
