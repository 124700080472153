import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { UserPresence, PresencePlayerStatus } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cPanel-esilia-personaggio',
  templateUrl: 'cPanel-esilia-personaggio.component.html',
  styleUrls: ['cPanel-esilia-personaggio.component.less'],
})
export class CPanelEsiliaPersonaggioComponent implements OnInit, OnDestroy {

  public dataSource: LocalDataSource;
  private usersPresence$: Observable<UserPresence[]>;
  private usersSubscription: Subscription;
  public settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'riammetti',
          title: '<i class="material-icons icon-del access-pg">accessibility_new</i> ',
        },
        {
          name: 'esilia',
          title: '<i class="material-icons icon-edit block-pg">block</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Personaggio'
      },
      isBanned: {
        title: 'Stato',
        filter: {
          type: 'list',
          config: {
            selectText: 'Seleziona...',
            list: [
              { value: 'False', title: 'Ammesso' },
              { value: 'True', title: 'Esiliato' }
            ],
          },
        },
        valuePrepareFunction: (isBanned: boolean) => {
          if (Functions.IsNullOrUndefined(isBanned) || isBanned == false)
            return `Ammesso`;
          else
            return `Esiliato`;
        },
      }
    }
  };

  tempSubscription: Subscription = new Subscription();

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private afdb: AngularFireDatabase
  ) { }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }))
      .subscribe((data: PresencePlayerStatus[]) => {
        self.dataSource.load(data);
      });
  }

  public ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  /**
   * Event of esilia or riammetti triggered by the table buttons
   * @param event table event
   */
  public onCustom(event) {
    let self = this;

    if (event.action == 'riammetti' && Functions.IsNullOrUndefined(event.data.isBanned) == false && event.data.isBanned == true) {
      let characterData: PresencePlayerStatus = event.data as PresencePlayerStatus;
      const characterDoc = this.afs.doc<any>('users/' + characterData.playerUID);
      characterDoc.update({ isBanned: false })
        .then(() => {
          const userPresenceDoc = self.afdb.object('status/' + characterData.playerUID + '/state');
          userPresenceDoc.update({ isBanned: false });
          self.toastr.success('Modifica Salvata!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

    } else if (event.action == 'esilia' && (Functions.IsNullOrUndefined(event.data.isBanned) == true || event.data.isBanned == false)) {
      let characterData: PresencePlayerStatus = event.data as PresencePlayerStatus;
      const characterDoc = this.afs.doc<any>('users/' + characterData.playerUID);
      characterDoc.update({ isBanned: true })
        .then(() => {
          const userPresenceDoc = self.afdb.object('status/' + characterData.playerUID + '/state');
          userPresenceDoc.update({ isBanned: true });
          self.removeFromPresence(characterData);
          self.toastr.success('Modifica Salvata!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })


    }
    // alert(`Custom event '${event.action}' fired on row №: ${event.data.id}`)
  }

  public removeFromPresence(characterData: PresencePlayerStatus) {
    const usersPresence: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const selectedUserPresence: UserPresence = usersPresence.find((aUserPresence: UserPresence) => Functions.IsNullOrUndefined(aUserPresence.state) === false && aUserPresence.state.playerUID == characterData.playerUID);

    // safety check
    if (
      Functions.IsNullOrUndefined(selectedUserPresence) == true
      || Functions.IsNullOrUndefined(selectedUserPresence.connections) == true
      || selectedUserPresence.connections.length <= 0
    )
      return;

    //#region - update chat status
    for (const aConnection in selectedUserPresence.connections) {
      const currentConnectionArea: string = selectedUserPresence.connections[aConnection];
      if (Functions.IsStringEmpty(currentConnectionArea) == true || currentConnectionArea == "@@HOME@@")
        continue;

      const chatStatusToUpdate: AngularFireObject<any> = this.afdb.object<any>('chats/' + currentConnectionArea + '/onlineUsersID/' + selectedUserPresence.state.playerUID);
      chatStatusToUpdate.remove();
    }
    //#endregion - update chat status

    //#region - remove connection from user presence
    const connectionToUpdate: AngularFireObject<any> = this.afdb.object<any>('status/' + selectedUserPresence.state.playerUID + '/connections');
    connectionToUpdate.remove();
    //#endregion - remove connection from user presence
  }

}
