import { BonusMalus, LocationRoot, Clan, Corp, Volto, RulesSezione, StandardChat, SpecialChat, DBVersionIndex, CharacterSheetData, CharacterSkill, CharacterItems, CharacterBM, UserPresence, Race } from './../models/data/application.data';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { Subscription, Observable } from 'rxjs';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as layout from '../actions/layout';
import * as datas from '../actions/datas';
import { NgForage } from 'ngforage';
import { Skill, Item, DBVersioning, DBVersionType, LocalCache } from '../models/data/application.data';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { CacheService } from './cache.service';
import { first } from 'rxjs/operators';
import { DebugLoggerService } from './debug-logger.service';

@Injectable()
export class IncrementalService {

  private moduleName: string = "incrementalService";

  constructor(
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private cacheService: CacheService,
    private debugLogger: DebugLoggerService
  ) { }

  /**
   * starting from a character sheet get skill/item/BM/volti/clan/corp if needed
   * @param userData chracter sheet
   */
  public getAdditionalInfoFromSheet(userData: CharacterSheetData) {
    // safety check
    if (Functions.IsNullOrUndefined(userData) == true) {
      return;
    }

    //#region - get skills if needed
    const requiredSkills: string[] = userData.userSkills.map((aUserSkill: CharacterSkill) => aUserSkill.uid);
    this.computeNeededElement(DBVersionType.skills, requiredSkills, userData);
    //#endregion - get skills if needed


    //#region - get items if needed
    const requiredItems: string[] = userData.userItems.map((aUserItem: CharacterItems) => aUserItem.uid);
    this.computeNeededElement(DBVersionType.items, requiredItems, userData);
    //#endregion - get items if needed


    //#region - get BMs if needed
    this.getBMsOfRaceCollection(userData.race, true);
    const requiredBMs: string[] = userData.userBMs.map((aUserBM: CharacterBM) => aUserBM.uid);
    this.computeNeededElement(DBVersionType.bm, requiredBMs, userData);
    //#endregion - get BMs if needed


    //#region - get clans if needed
    if (Functions.IsStringEmpty(userData.clan) == false) {
      const requiredClan: string = userData.clan;
      this.computeNeededElement(DBVersionType.clans, [requiredClan], userData);
    }
    //#endregion - get clans if needed


    //#region - get corps if needed
    if (Functions.IsStringEmpty(userData.corp) == false) {
      const requiredCorp: string = userData.corp;
      this.computeNeededElement(DBVersionType.corps, [requiredCorp], userData);
    }
    //#endregion - get corps if needed
  }

  /**
   * starting from a present list get clans and corps
   */
  public getAdditionalInfoFromPresenti() {
    let presenti: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    presenti = presenti.filter((aUserPresence: UserPresence) => (Functions.IsNullOrUndefined(aUserPresence.connections) == false) && (Functions.IsNullOrUndefined(aUserPresence.state) == false));

    //#region - get clans if needed
    let requiredClanIDs: string[] = presenti.map((aUserPresence: UserPresence) => aUserPresence.state.clan);
    requiredClanIDs = requiredClanIDs.filter((anID: string) => Functions.IsStringEmpty(anID) == false);
    this.computeNeededElement(DBVersionType.clans, requiredClanIDs, undefined);
    //#endregion - get clans if needed

    //#region - get corps if needed
    let requiredCorpIDs: string[] = presenti.map((aUserPresence: UserPresence) => aUserPresence.state.corp);
    requiredCorpIDs = requiredClanIDs.filter((anID: string) => Functions.IsStringEmpty(anID) == false);
    this.computeNeededElement(DBVersionType.corps, requiredCorpIDs, undefined);
    //#endregion - get corps if needed
  }

  /**
   * ask for a complete collection when needed if not completelly cched (used with volti, location, rules, std chats and special chats)
   * @param type the type
   * @param checkCacheBeforeAsk if false always ask for the collection, otherwise ask for it only if the cached version is not full for any reason
   */
  public getCompleteCollection(type: DBVersionType, checkCacheBeforeAsk: boolean = false) {
    let self = this;

    //check cache if needed
    if (checkCacheBeforeAsk == true) {
      const fullCachedVersion: boolean = this.cacheService.getCollectionCacheFullnes(type);
      if (fullCachedVersion == true)
        return;
    }

    const collectionIndex: string = this.cacheService.getCacheIndexString(type)
    const aCollection: AngularFirestoreCollection<any> = this.afs.collection<any>(collectionIndex);
    const aCollectionObservable: Observable<any[]> = aCollection.valueChanges();
    const aCollectionSubscription = aCollectionObservable.pipe(first()).subscribe((collectionData) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, 'READ ' + DBVersionType[type], DBVersionType[type], self.moduleName, (collectionData as any[]).length);
      }

      let raceBMFullFlag: number[] = [];
      if (type == DBVersionType.bm) {
        raceBMFullFlag = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      }

      let raceSkillFullFlag: number[] = [];
      if (type == DBVersionType.skills) {
        raceSkillFullFlag = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      }

      self.cacheService.overrideCacheAndStorage(type, collectionData, true, raceSkillFullFlag, raceBMFullFlag);
    });
  }

  /**
   * ask for a partial collection of skill that the player can buy
   * @param checkCacheBeforeAsk if false always ask for the collection, otherwise ask for it only if the cached version is not full for any reason
   */
  public getBuyableSkillsCollection(checkCacheBeforeAsk: boolean = false) {
    let self = this;

    //check cache if needed
    const myRace: Race = fromRoot.getState(this.store).character.myCharacterData.race;
    if (checkCacheBeforeAsk == true) {
      const raceSkillFullFlag: number[] = fromRoot.getState(this.store).datas.raceSkillFullFlag;
      const raceSkillFullFlagSet: Set<number> = new Set(raceSkillFullFlag);
      if (raceSkillFullFlagSet.has(myRace) == true)
        return;
    }

    const aCollection: AngularFirestoreCollection<any> = this.afs.collection<any>('skills', ref => ref.where("race", "==", myRace));
    const aCollectionObservable: Observable<any[]> = aCollection.valueChanges();
    const aCollectionSubscription = aCollectionObservable.pipe(first()).subscribe((collectionData) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, 'READ buyable skills', self.moduleName, "skills", (collectionData as any[]).length);
      }

      //#region - merge the two arrays of skills
      const currentLocalSkills: Skill[] = fromRoot.getState(self.store).datas.skills;
      const currentLocalSkillsMap: Map<string, Skill> = new Map();
      for (const aSkil of currentLocalSkills) {
        currentLocalSkillsMap.set(aSkil.uid, aSkil);
      }

      for (let index: number = 0; index < collectionData.length; index++) {
        currentLocalSkillsMap.set(collectionData[index].uid, collectionData[index]);
      }
      //#endregion - merge the two arrays of skills
      const mergedData: Skill[] = Array.from(currentLocalSkillsMap.values());

      const raceSkillFullFlag: number[] = Object.assign([], fromRoot.getState(this.store).datas.raceSkillFullFlag);
      raceSkillFullFlag.push(myRace);
      self.cacheService.overrideCacheAndStorage(DBVersionType.skills, mergedData, false, raceSkillFullFlag, []);
    });
  }

  /**
  * ask for a partial collection of skill that the player can buy
  * @param checkCacheBeforeAsk if false always ask for the collection, otherwise ask for it only if the cached version is not full for any reason
  */
  public getBMsOfRaceCollection(race: Race, checkCacheBeforeAsk: boolean = false) {
    let self = this;

    //check cache if needed
    const raceBMFullFlag: number[] = fromRoot.getState(this.store).datas.raceBMFullFlag;
    if (checkCacheBeforeAsk == true) {
      const raceBMFullFlagSet: Set<number> = new Set(raceBMFullFlag);
      if (raceBMFullFlagSet.has(race) == true)
        return;
    }

    const aCollection: AngularFirestoreCollection<any> = this.afs.collection<any>('bonusMalus', ref => ref.where("race", "==", race));
    const aCollectionObservable: Observable<any[]> = aCollection.valueChanges();
    const aCollectionSubscription = aCollectionObservable.pipe(first()).subscribe((collectionData) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, 'READ race BMs', self.moduleName, "bonusMalus", (collectionData as any[]).length);
      }

      //#region - merge the two arrays of BM
      const currentLocalBMs: BonusMalus[] = fromRoot.getState(self.store).datas.bms;
      const currentLocalBMsMap: Map<string, BonusMalus> = new Map();
      for (const aBM of currentLocalBMs) {
        currentLocalBMsMap.set(aBM.uid, aBM);
      }

      for (let index: number = 0; index < collectionData.length; index++) {
        currentLocalBMsMap.set(collectionData[index].uid, collectionData[index]);
      }
      //#endregion - merge the two arrays of BM
      const mergedData: BonusMalus[] = Array.from(currentLocalBMsMap.values());
      const updatedRaceBMFullFlag: number[] = Object.assign([], raceBMFullFlag);
      updatedRaceBMFullFlag.push(race);

      self.cacheService.overrideCacheAndStorage(DBVersionType.bm, mergedData, false, [], updatedRaceBMFullFlag);
    });
  }

  //#region - utilities
  /**
   * check if we already have the required additional informations
   * otherwise it's going to dnwload them
   * @param type type (skill, clan, volti ecc)
   * @param requiredIDs array of string IDs required by the character
   */
  private computeNeededElement(type: DBVersionType, requiredIDs: string[], userData: CharacterSheetData) {
    const localDataSet: Set<string> = this.cacheService.getCollectiondIDs(type);
    const downloadedValuesMap: Map<string, any> = new Map();
    const eliminatedRef: string[] = [];

    const IDstoAsk: string[] = [];
    for (let index: number = 0; index < requiredIDs.length; index++) {
      const anID: string = requiredIDs[index];
      // safety check
      if (Functions.IsStringEmpty(anID) == true) {
        continue;
      }

      if (localDataSet.has(anID) == true) {
        continue;
      } else {
        IDstoAsk.push(anID);
      }
    }

    for (let index: number = 0; index < IDstoAsk.length; index++) {
      const anID: string = IDstoAsk[index];

      // otherwise i need to ask for it
      this.getOnlineElement(type, anID, downloadedValuesMap, IDstoAsk.length, eliminatedRef, userData);
    }


    // for (let index: number = 0; index < requiredIDs.length; index++) {
    //   const anID: string = requiredIDs[index];

    //   // safety check
    //   if (Functions.IsStringEmpty(anID) == true) {
    //     continue;
    //   }

    //   const lastLoop: boolean = (index == (requiredIDs.length - 1));

    //   //#region - check if is not needed to donwload new values
    //   if (localDataSet.has(anID) == true && (lastLoop && downloadedValuesMap.size > 0)) {
    //     // if last loop we need to store the changes anyway
    //     const newValues: any[] = Array.from(downloadedValuesMap.values());
    //     this.storeAdditionalInformations(type, newValues);

    //     // remove ghost reference only if we are coming from character sheet
    //     if (Functions.IsNullOrUndefined(userData) == false) {
    //       this.cleanGhostReference(type, eliminatedRef, userData);
    //     }
    //     continue;
    //   } else if (localDataSet.has(anID) == true) {
    //     continue;
    //   }
    //   //#endregion - check if is not needed to donwload new values

    //   // otherwise i need to ask for it
    //   this.getOnlineElement(type, anID, downloadedValuesMap, (index == (requiredIDs.length - 1)), eliminatedRef, userData);
    // }
  }

  /**
 * utility function - retrive the updated value online
 * @param type type (skill, clan, volti ecc)
 * @param id as strng of the item to update
 * @param updatedValuesMap the map with all new updated values
 * @param true if this is the last value to be updated
 */
  private getOnlineElement(type: DBVersionType, id: string, updatedValuesMap: Map<string, any>, numberOfIDsToAsk: number, eliminatedRef: string[], userData: CharacterSheetData) {
    const self: this = this;
    const collectionID: string = this.cacheService.getCacheIndexString(type);

    // safety check
    if (Functions.IsStringEmpty(id) == true) {
      return;
    }

    const aDoc: AngularFirestoreDocument<any> = this.afs.doc<any>(collectionID + '/' + id);
    const aDocObservable: Observable<any> = aDoc.valueChanges();
    const sub: Subscription = aDocObservable.pipe(first()).subscribe((updatedDoc: any) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, 'READ a ' + DBVersionType[type] + ' for incremental update', self.moduleName, DBVersionType[type], 1);
      }

      // probably this reference was eliminated and need to be removed from the character sheet
      if (Functions.IsNullOrUndefined(updatedDoc) == true) {
        eliminatedRef.push(id);
      }

      // save the new updated values
      if (Functions.IsNullOrUndefined(updatedDoc) == false) {
        updatedValuesMap.set(updatedDoc.uid, updatedDoc);
      }

      // if this is the last loop we need to update store and cachec
      if ((updatedValuesMap.size + eliminatedRef.length) == numberOfIDsToAsk) {
        // this is the last subscription
        const newValues: any[] = Array.from(updatedValuesMap.values());
        self.storeAdditionalInformations(type, newValues);

        // remove ghost reference only if we are coming from character sheet
        if (Functions.IsNullOrUndefined(userData) == false) {
          self.cleanGhostReference(type, eliminatedRef, userData);
        }
      }
    })
  }

  /**
   * Save the additional informations retrived
   * @param type type (skill, clan, volti ecc)
   * @param newValues array of new information values
   */
  private storeAdditionalInformations(type: DBVersionType, newValues: any[]) {
    //safety check
    if (Functions.IsUndefined(newValues) == true || newValues.length <= 0)
      return;

    if (this.debugLogger.isAuditing)
      console.log('STORE ' + DBVersionType[type] + ' after incremental update)');

    let raceSkillFullFlag: number[] = [];
    let raceBMFullFlag: number[] = [];
    // check for buyable sill flag
    if (type == DBVersionType.skills) {
      raceSkillFullFlag = fromRoot.getState(this.store).datas.raceSkillFullFlag;
    }

    if (type == DBVersionType.bm) {
      raceBMFullFlag = fromRoot.getState(this.store).datas.raceBMFullFlag;
    }

    this.cacheService.addToCacheAndStorage(type, newValues, false, raceSkillFullFlag, raceBMFullFlag);
  }

  /**
   * remove reference of collection that do not point to any document because they where eliminated
   * @param type type (skill, clan, volti ecc)
   * @param ghostReference array of ghost idS
   * @param userData the character sheet
   */
  private cleanGhostReference(type: DBVersionType, ghostReference: string[], userData: CharacterSheetData) {
    //safety check
    if (ghostReference.length <= 0)
      return;

    const ghostReferenceSet: Set<string> = new Set(ghostReference);
    const updatedUserData: CharacterSheetData = Object.assign({}, userData);
    //#region - update section with ghost reference
    switch (type) {
      case DBVersionType.skills:
        updatedUserData.userSkills = updatedUserData.userSkills.filter((aUserSkill: CharacterSkill) => ghostReferenceSet.has(aUserSkill.uid) == false);
        break;

      case DBVersionType.items:
        updatedUserData.userItems = updatedUserData.userItems.filter((aUserItem: CharacterItems) => ghostReferenceSet.has(aUserItem.uid) == false);
        break;

      case DBVersionType.bm:
        updatedUserData.userBMs = updatedUserData.userBMs.filter((aUserBM: CharacterBM) => ghostReferenceSet.has(aUserBM.uid) == false);
        break;

      case DBVersionType.clans:
        updatedUserData.clan = "";
        break;

      case DBVersionType.corps:
        updatedUserData.corp = "";
        break;
    }
    //#endregion - update section with ghost reference

    let updatedUserJsonData = JSON.parse(JSON.stringify(updatedUserData));
    const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + updatedUserData.uid);
    playerToUpdateDoc.update(updatedUserJsonData)
      .then(() => {
        // DO NOTHING
      })
      .catch((error: any) => {
        console.log("Ghost reference update error");
      })
  }
  //#endregion - utilities
}
