import { LocalDataSource } from 'ng2-smart-table';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, CharacterSkill, Race, Skill, UserPresence, PresencePlayerStatus } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CPanelService } from '../../services/cpanel.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-assegna-skill-a-personaggio',
  templateUrl: 'cPanel-assegna-skill-a-personaggio.component.html',
  styleUrls: ['cPanel-assegna-skill-a-personaggio.component.less'],
})
export class CPanelAssegnaSkillAPersonaggioComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelAssegnaSkill";

  //#region - firestore get users
  public usersPresence$: Observable<UserPresence[]>;
  private usersPresenceSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  //#endregion - firestore get users

  //#region - firestore get BM
  private skillsCollection: AngularFirestoreCollection<Skill>;
  private skillsCollectionObservable: Observable<Skill[]>;
  private skillsSubscription: Subscription;
  public skillsMap: Map<string, Skill>;
  //#endregion - firestore get BM

  //#region - selected character variables
  private playerToEditDoc: AngularFirestoreDocument<CharacterSheetData>;
  private playerToEditDocObservable: Observable<CharacterSheetData>;
  private playerToEditDocSubscription: Subscription;
  public selectedCharacterID: string;
  public selectedCharacter: CharacterSheetData;
  //#region - selected character variables


  //#region - other variables
  public mergedDataSource: LocalDataSource;
  public mergedData: any[] = [];
  public settings: any = {};
  public settingsStandard = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Skill',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      },
      // sLv: {
      //   title: 'Livello Skill',
      //   editable: true
      //   // editor: {
      //   //   type: 'list',
      //   //   config: {
      //   //     list: []
      //   //   }
      //   // }
      // },
      race: {
        title: 'Razza',
        editable: false,
        addable: false,
        valuePrepareFunction: (value: string) => { return Race[value]; },
      },
    }
  };
  public settingsMondani = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Skill',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      },
      sLv: {
        title: 'Livello Skill',
        editable: true
        // editor: {
        //   type: 'list',
        //   config: {
        //     list: []
        //   }
        // }
      },
      race: {
        title: 'Razza',
        editable: false,
        addable: false,
        valuePrepareFunction: (value: string) => { return Race[value]; },
      },
    }
  };
  //#endregion - other variables

  constructor(
    private toastr: ToastrService,
    private cPanelService: CPanelService,
    private store: Store<fromRoot.State>,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.mergedDataSource = new LocalDataSource();

    //#region - get users
    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersPresenceSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }),
        first()
      ).subscribe((data: PresencePlayerStatus[]) => {
        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.characters = data;
        self.createMergedUserSkillsData();
      });
    //#endregion - get users

    //#region - get skills
    this.skillsCollection = this.afs.collection<Skill>('skills');
    this.skillsCollectionObservable = this.skillsCollection.valueChanges();
    this.skillsSubscription = this.skillsCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all skills cpanel", self.moduleName, "skills", (data as any[]).length);
      }

      self.skillsMap = new Map();
      for (let index: number = 0; index < data.length; index++) {
        self.skillsMap.set(data[index].uid, data[index]);
      }
    });
    //#endregion - get skills

  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersPresenceSubscription) == false) {
      this.usersPresenceSubscription.unsubscribe();
    }
    if (Functions.IsNullOrUndefined(this.skillsSubscription) == false) {
      this.skillsSubscription.unsubscribe();
    }
    if (Functions.IsNullOrUndefined(this.playerToEditDocSubscription) == false) {
      this.playerToEditDocSubscription.unsubscribe();
    }
  }

  public onUserChange(character: PresencePlayerStatus) {
    if (Functions.IsNullOrUndefined(character) == true) {
      this.settings = {};
      this.createMergedUserSkillsData();
    }

    const self: this = this;
    this.playerToEditDoc = this.afs.doc<any>('users/' + character.playerUID);
    this.playerToEditDocObservable = this.playerToEditDoc.valueChanges();
    this.playerToEditDocSubscription = this.playerToEditDocObservable.pipe(first())
      .subscribe((selectedCharacter: CharacterSheetData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
        }

        self.selectedCharacter = selectedCharacter;
        if (self.selectedCharacter.race == Race.Mondano) {
          self.settings = self.settingsMondani;
        } else {
          self.settings = self.settingsStandard;
        }
        self.createMergedUserSkillsData();
      });
    // this.selectedCharacter = character;
    // if (this.selectedCharacter.race == Race.Mondano) {
    //   this.settings = this.settingsMondani;
    // } else {
    //   this.settings = this.settingsStandard;
    // }
    // this.createMergedUserSkillsData();
  }

  private createMergedUserSkillsData() {

    if (Functions.IsNullOrUndefined(this.selectedCharacter))
      return;

    if (Functions.IsNullOrUndefined(this.skillsMap) || this.skillsMap.size == 0)
      return;

    let selectedCharacterSkills: CharacterSkill[] = this.selectedCharacter.userSkills || [];
    let skillsToBeDisplayed: any[] = [];

    for (let index: number = 0; index < selectedCharacterSkills.length; index++) {

      const getSkill: Skill = this.skillsMap.get(selectedCharacterSkills[index].uid);
      if (Functions.IsNullOrUndefined(getSkill))
        continue;

      skillsToBeDisplayed.push(
        {
          uid: selectedCharacterSkills[index].uid,
          name: getSkill.name,
          sLv: selectedCharacterSkills[index].sLv || 1,
          race: getSkill.race
        }
      )
    }
    this.mergedData = skillsToBeDisplayed;
    this.mergedDataSource = new LocalDataSource();
    this.mergedDataSource.load(skillsToBeDisplayed);

    this.setUpSkillNameEditorList();
  }

  private setUpSkillNameEditorList() {
    let displayedSkillIDsSet: Set<string> = new Set();

    for (let index: number = 0; index < this.mergedData.length; index++) {
      displayedSkillIDsSet.add(this.mergedData[index].uid);
    }

    //available list add
    let allSkills: Skill[] = Array.from(this.skillsMap.values());
    let filteredSkills: Skill[] = allSkills
      .filter((aSkill: Skill) => displayedSkillIDsSet.has(aSkill.uid) == false && aSkill.race == this.selectedCharacter.race);
    let filteredSkillsEditList: any[] = [];

    for (let index: number = 0; index < filteredSkills.length; index++) {
      filteredSkillsEditList.push(
        { value: filteredSkills[index].uid, title: filteredSkills[index].name }
      )
    }

    // sorting alphabetically
    filteredSkillsEditList = filteredSkillsEditList.sort(function (a, b) {
      var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settings.columns.name.editor.config.list = filteredSkillsEditList;
    this.settings = Object.assign({}, this.settings);
  }

  public onDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      let selectedCharacterSkills: CharacterSkill[] = this.selectedCharacter.userSkills || [];
      let newList: CharacterSkill[] = selectedCharacterSkills.filter((aCharacterSkill: CharacterSkill) => aCharacterSkill.uid != event.data.uid);
      this.selectedCharacter.userSkills = newList;
      let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));

      let self = this;
      this.playerToEditDoc.update(updatedUserJsonData)
        .then(() => {
          self.toastr.success('Elemento Eliminato!');

          self.playerToEditDocSubscription = self.playerToEditDocObservable.pipe(first())
            .subscribe((selectedCharacter: CharacterSheetData) => {
              if (self.debugLogger.isAuditing) {
                self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
              }

              self.selectedCharacter = selectedCharacter;
              setTimeout(() => {
                self.createMergedUserSkillsData();
              }, 0);
            });
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })

      // this.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
      //   self.toastr.success('Elemento Eliminato!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });

    } else {
      event.confirm.reject();
    }
  }

  public onCreateConfirm(event) {
    event.confirm.resolve();

    let selectedCharacterSkills: CharacterSkill[] = this.selectedCharacter.userSkills || [];
    let newCharacterSkill: CharacterSkill = new CharacterSkill();
    newCharacterSkill.uid = event.newData.name;
    newCharacterSkill.sLv = event.newData.sLv;

    if (Functions.IsNullOrUndefined(newCharacterSkill.sLv) == true || Functions.IsStringEmpty(newCharacterSkill.sLv.toString()) == true || newCharacterSkill.sLv <= 0) {
      newCharacterSkill.sLv = 1;
    }

    selectedCharacterSkills.push(newCharacterSkill);
    this.selectedCharacter.userSkills = selectedCharacterSkills;

    let self = this;
    let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));
    this.playerToEditDoc.update(updatedUserJsonData)
      .then(() => {
        self.toastr.success('Elemento Creato!');

        self.playerToEditDocSubscription = self.playerToEditDocObservable.pipe(first())
          .subscribe((selectedCharacter: CharacterSheetData) => {
            if (self.debugLogger.isAuditing)
              self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);

            self.selectedCharacter = selectedCharacter;
            setTimeout(() => {
              self.createMergedUserSkillsData();
            }, 0);
          });
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

    // this.firestore.update('users/' + this.selectedCharacter.uid, updatedUserJsonData).subscribe(() => {
    //   self.toastr.success('Elemento Creato!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });

  }

  public onEditConfirm(event) {
    event.confirm.resolve();
    let selectedCharacterSkills: CharacterSkill[] = this.selectedCharacter.userSkills || [];
    let updatedSkill: CharacterSkill = selectedCharacterSkills.find((aCharacterSkill: CharacterSkill) => aCharacterSkill.uid == event.data.uid);

    // let newCharacterSkill: CharacterSkill = new CharacterSkill();
    // newCharacterSkill.uid = event.newData.uid;

    updatedSkill.sLv = event.newData.sLv;

    if (Functions.IsNullOrUndefined(updatedSkill.sLv) == true || Functions.IsStringEmpty(updatedSkill.sLv.toString()) == true || updatedSkill.sLv <= 0) {
      updatedSkill.sLv = 1;
    }

    // selectedCharacterSkills.push(newCharacterSkill);
    // this.selectedCharacter.userSkills = selectedCharacterSkills;
    let updatedUserJsonData = JSON.parse(JSON.stringify(this.selectedCharacter));

    let self = this;
    this.playerToEditDoc.update(updatedUserJsonData)
      .then(() => {
        self.toastr.success('Modifica Salvata!');

        this.playerToEditDocSubscription = this.playerToEditDocObservable.pipe(first())
          .subscribe((selectedCharacter: CharacterSheetData) => {
            if (self.debugLogger.isAuditing) {
              self.debugLogger.logRead(true, "READ a user cPanel", self.moduleName, "users", 1);
            }

            self.selectedCharacter = selectedCharacter;
            setTimeout(() => {
              self.createMergedUserSkillsData();
            }, 0);
          });
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })
  }

}
