import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';

@Pipe({ name: 'toolTipPipe' })
export class ToolTipPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(value: number, type: number, race?: number) {
    if (Functions.IsNullOrUndefined(value) || Functions.IsNullOrUndefined(type))
      return "";
    else {
      let valueToReturn: string = "";
      switch (type) {
        case 0:
          switch (value) {
            case 0:
              valueToReturn = "Cerco Gioco";
              break;
            case 1:
              valueToReturn = "Occupato";
              break;
            case 2:
              valueToReturn = "Solo Messaggi";
              break;
          }
          break;
        case 1:
          if (Functions.IsNullOrUndefined(race))
            return "";
          valueToReturn = this.raceHelper(value, race);
          break;
        case 2:
          switch (value) {
            case 0:
              valueToReturn = "Gestore";
              break;
            case 1:
              valueToReturn = "Admin";
              break;
            case 2:
              valueToReturn = "Master";
              break;
          }
          break;
      }

      return valueToReturn;
    }
  }


  private raceHelper(value: number, race: number): string {
    let valueToReturn: string = "";
    switch (race) {
      case 0:
        //Nephilim
        switch (value) {
          case 1:
            valueToReturn = "Aumel - Nephilim Primo Livello";
            break;
          case 2:
            valueToReturn = "Sitael - Nephilim Secondo Livello";
            break;
          case 3:
            valueToReturn = "Reiyel - Nephilim Terzo Livello";
            break;
          case 4:
            valueToReturn = "Vehuel - Nephilim Quarto Livello";
            break;
          case 5:
            valueToReturn = "Yeratel - Nephilim Quinto Livello";
            break;
        }
        break;
      case 1:
        //Warlock
        switch (value) {
          case 1:
            valueToReturn = "Srit - Warlock Primo Livello";
            break;
          case 2:
            valueToReturn = "Zait - Warlock Secondo Livello";
            break;
          case 3:
            valueToReturn = "Serit - Warlock Terzo Livello";
            break;
          case 4:
            valueToReturn = "Kroh - Warlock Quarto Livello";
            break;
          case 5:
            valueToReturn = "Oiriz - Warlock Quinto Livello";
            break;
        }
        break;
      case 2:
        //Sidhe
        switch (value) {
          case 1:
            valueToReturn = "Niflhel - Sidhe Primo Livello";
            break;
          case 2:
            valueToReturn = "Vanaheim - Sidhe Secondo Livello";
            break;
          case 3:
            valueToReturn = "Asgaror - Sidhe Terzo Livello";
            break;
          case 4:
            valueToReturn = "Alfheim - Sidhe Quarto Livello";
            break;
          case 5:
            valueToReturn = "Muspell - Sidhe Quinto Livello";
            break;
        }
        break;
      case 3:
        //Lycan
        switch (value) {
          case 1:
            valueToReturn = "Cucciolo - Lycan Primo Livello";
            break;
          case 2:
            valueToReturn = "Intermedio - Lycan Secondo Livello";
            break;
          case 3:
            valueToReturn = "Specialista - Lycan Terzo Livello";
            break;
          case 4:
            valueToReturn = "Beta - Lycan Quarto Livello";
            break;
          case 5:
            valueToReturn = "Alfa - Lycan Quinto Livello";
            break;
        }
        break;
      case 4:
        //Leech
        switch (value) {
          case 1:
            valueToReturn = "Neonato - Leech Primo Livello";
            break;
          case 2:
            valueToReturn = "Seguace - Leech Secondo Livello";
            break;
          case 3:
            valueToReturn = "Nobile - Leech Terzo Livello";
            break;
          case 4:
            valueToReturn = "Erede - Leech Quarto Livello";
            break;
          case 5:
            valueToReturn = "Sire - Leech Quinto Livello";
            break;
        }
        break;
      case 5:
        //Mondani
        valueToReturn = "Mondano";
        break;
    }

    return valueToReturn;
  }
}

