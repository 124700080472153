import { CharacterSheetData, UserPresence, PrivateConversation } from './../models/data/application.data';
import { BaseAction } from './baseAction';

export const RESET_PMS_STORED = "[pm] Reset Stored PMs";
export const STORE_PMS = "[pm] Store PMs";
export const UPDATE_STORED_PMS = "[pm] Updated Stored PMs";
export const DELETE_STORED_PM = "[pm] Delete Stored PM";
export const SELECT_PM = "[pm] Select PM";
export const STORE_CONVS_WITH_NEW_PMS = "[pm] Store Convs With New PMs";
export const STORE_SHOW_ON_PMS = "[pm] Store Show On PMs";
export const RESET_PM_STORE = "[pm] Reset PM store";


export type Actions = StorePMs | SelectPM | StoreConvsWithNewPMs | UpdatedStoredPMs | DeleteStoredPM | StoreShowOnPMs | ResetPMStore;

export class StorePMs implements BaseAction {
  readonly type = STORE_PMS;
  constructor(public payload: PrivateConversation[]) { }
}

export class UpdatedStoredPMs implements BaseAction {
  readonly type = UPDATE_STORED_PMS;
  constructor(public payload: PrivateConversation) { }
}

export class DeleteStoredPM implements BaseAction {
  readonly type = DELETE_STORED_PM;
  constructor(public payload: string) { }
}

export class SelectPM implements BaseAction {
  readonly type = SELECT_PM;
  constructor(public payload: PrivateConversation) { }
}

export class StoreConvsWithNewPMs implements BaseAction {
  readonly type = STORE_CONVS_WITH_NEW_PMS;
  constructor(public payload: string[]) { }
}

export class StoreShowOnPMs implements BaseAction {
  readonly type = STORE_SHOW_ON_PMS;
  constructor(public payload: boolean) { }
}

export class ResetPMStore implements BaseAction {
  readonly type = RESET_PM_STORE;
  constructor() { }
}
