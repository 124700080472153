import { CharacterSheetData } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import * as characterSelector from './../../../selectors/character.selectors';
import * as layout from './../../../actions/layout';
import * as chat from './../../../actions/chat';
import * as character from './../../../actions/character';
import { MapState, LayoutState } from '../../../modules/utilities/utilities.constants';
import { AuthenticationService } from '../../../services/authentication.service';
import { Role } from '../../../models/data/application.data';

// Utilities
@Component({
  selector: 'uffici-gestione-component',
  templateUrl: './uffici-gestione.component.html',
  styleUrls: ['./uffici-gestione.component.less']
})

export class UfficiGestioneComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiGestioneComponent";

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  allLetters: string[] = ["#", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "PNG"];
  public allUsers$: Observable<any>;
  public selectedLetter: string = "#";


  constructor(private store: Store<fromRoot.State>, private cdr: ChangeDetectorRef) {
    this.allUsers$ = this.store.select(characterSelector.getUsersAnagrafica);
  }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleAnagrafeAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public goToScheda(uid: string) {
    this.store.dispatch(new character.SelectplayerID(uid));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: true, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
  }

  public clickLetter(letter: string) {
    this.selectedLetter = letter;
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
