import { FieldValue } from "@firebase/firestore-types";

export class UserData {
  $key: string;
  name: string;
  mail: string;
}

// ----------------------------------------- NEW

export class DBVersionIndex {
  versions: DBVersioning[];

  constructor(versions: DBVersioning[]) {
    this.versions = versions;
  }
}

export class DBVersioning {
  type: DBVersionType;
  version: number;
  vChanges: any;
}

export enum DBVersionType {
  skills = 0,
  items = 1,
  bm = 2,
  clans = 3,
  corps = 4,
  volti = 5,
  rules = 6,
  locations = 7,
  standardChats = 8,
  specialChats = 9
}

export class LocalCache {
  version: number;
  type: DBVersionType;
  fullCacheCopy: boolean;
  raceSkillFullFlag: number[] = []; // for skills
  raceBMFullFlag: number[] = [];
  data: Skill[] | Item[] | BonusMalus[] | Clan[] | Corp[] | Volto[] | RulesSezione[] | LocationRoot | StandardChat[] | SpecialChat[];

  constructor(ver: number, type: DBVersionType, data: any, fullCacheCopy: boolean = false) {
    this.version = ver;
    this.type = type;
    this.data = data;
    this.fullCacheCopy = fullCacheCopy;
  }
}


export enum Role {
  gestore = 0,
  admin = 1,
  master = 2,
  staff = 3,
  // png = 4,
  player = 4 //5
}

export enum Sex {
  male = 0,
  female = 1
  // NotBinary = 2
}

export enum Race {
  Nephilim = 0,
  Warlock = 1,
  Sidhe = 2,
  Lycan = 3,
  Leech = 4,
  Mondano = 5,
  Fantasma = 6,
  Demone = 7,
  Angelo = 8,
  Dimenticato = 9,
}

export class Stats {
  str: number = 0;
  agl: number = 0;
  res: number = 0;
  wsd: number = 0;
  per: number = 0;
  wil: number = 0;
}

export class MAXStats {
  str: number = 0;
  agl: number = 0;
  res: number = 0;
  wsd: number = 0;
  per: number = 0;
  wil: number = 0;

  constructor(max: number) {
    this.str = max;
    this.agl = max;
    this.res = max;
    this.wsd = max;
    this.per = max;
    this.wil = max;
  }
}


export class StatsBonusFormula {
  str: string = "";
  agl: string = "";
  res: string = "";
  wsd: string = "";
  per: string = "";
  wil: string = "";

  strAdd: string = "";
  aglAdd: string = "";
  resAdd: string = "";
  wsdAdd: string = "";
  perAdd: string = "";
  wilAdd: string = "";

  sal: string = "";
  salM: string = "";
  salAdd: string = "";
  salMAdd: string = "";

  durata: string = "1";
}

export class CharacterSheetData {
  uid: string;
  mail: string = "";
  role: Role = Role.player;
  lvl: number = 1;
  inited: boolean = false; // set up when the player edit for the first time the character sheet
  leftPoint: number = 5; // set up to 0 when the player edit for the first time the character sheet or wen a new lvl is added
  isActive: boolean = false; // staff set it active, if not active it can't write in chat
  isFirstEntrance: boolean = true;
  isBanned: boolean = false;
  isPng: boolean = false;

  isCapoClan: boolean = false;
  isCapoCorp: boolean = false;
  isCapoRace: number[] = [];

  name: string = ""; //NO EDIT
  sur: string = ""; //NO EDIT
  nick: string = "";
  sex: Sex = undefined; //NO EDIT
  race: Race = undefined; //NO EDIT
  nature: string = ""; //una volta da utente poi solo dallo staff
  allinment: string = ""; //una volta da utente poi solo dallo staff
  age: number = 0;
  realAge: number = 0; //una volta da utente poi solo dallo staff
  eyes: string = "";
  hair: string = "";
  weight: string = "";
  height: string = "";
  marStatus: string = ""; //stato civile
  job: string = "";
  offRole: string = ""; //NO EDIT
  HAvatar: string = "";
  VAvatar: string = "";
  MAvatar: string = "";
  TAvatar: string = "";
  music: string = "";
  stats: Stats = new Stats(); //una volta da utente, poi comprando punti esperienza poi solo dallo staff
  health: number = 100; //NO EDIT
  mindHealth: number = 100; //NO EDIT
  usablePx: number = 0; //NO EDIT
  px: number = 0; //NO EDIT
  signDate: string = ""; //NO EDIT
  access: string = ""; //NO EDIT
  DMNote: string = ""; //NO EDIT
  backg: string;
  birthdate: string = "";

  userItems: CharacterItems[] = [];
  userSkills: CharacterSkill[] = [];
  userBMs: CharacterBM[] = [];

  nameAlreadyChanged: boolean = false;
  soundEnabled: boolean = true;
  messageSoundDisabled: boolean = false;
  sendEnter: boolean = true;

  myPrivateChat: SecretChat;

  bank: number = 0;
  money: number = 0;
  jobMoney: number;

  clan: string = ""; //NO EDIT
  clanLvl: number = -1;
  clanMoney: number = 0;
  corp: string = ""; //NO EDIT
  corpLvl: number = -1;
  corpMoney: number = 0;

  state: State = State.libero;
  stateText: string = "";
  lastMessageSent: number;

  stasBought: number = 0;

  forceBuyableSkillDump: boolean = false;

  questionObject: string = "";
}

export class Item {
  uid: string;
  name: string;
  type: string;
  image: string;
  description: string;
  maxUse: number = -1;
  cost: number;
  statsBonus: StatsBonusFormula;
  note: string;
  itemCat: ItemCat;
}

export enum ItemCat {
  armi = 0,
  pozioni_artefatti = 1,
  varie = 2
}

export class CharacterItems {
  uid: string;
  qta: number;
  remainingUse: number;
}

export class Skill {
  uid: string;
  name: string;
  ico: string;
  race: Race;
  description: string;
  descrLv1: string;
  descrLv2: string;
  descrLv3: string;
  descrLv4: string;
  descrLv5: string;
  note: string;
  cost: number;
  statsBonus: StatsBonusFormula;
}

export class CharacterSkill {
  uid: string;
  sLv: number;
}

export class BonusMalus {
  uid: string;
  name: string;
  ico: string;
  race: Race;
  description: string;
  type: BMType;
  note: string;
  isNat: boolean;
  isBonus: boolean;
}

export enum BMType {
  Attivo = 0,
  Passivo = 1,
}

export class CharacterBM {
  uid: string;
}

export class Volto {
  uid: string;
  uidPG: string;
  vName: string;
  vSur: string;
  vNick: string;
  type: VoltoType;
}

export enum VoltoType {
  Maschio = 0,
  Femmina = 1,
}

export class FirebaseCalendarEvent {
  uid: string;
  title: string;
  date: Date;
  color: string;
}

export class MeteoDay {
  day: string; // 2 cifre da 01 a 31
  meteoType: MeteoType;
  temp: string = "";
  wind: string = "";
  umid: string = "";
  marea: string = "";
}

export enum MeteoType {
  sole = 0,
  nuvoloso = 1,
  pioggia = 2,
  neve = 3,
  // ventoso = 4,
  nebbia = 5,
  temporale = 6,
  tempestaSabbia = 7,
  tornado = 8,
  terremoto = 9,
  eclisseSolare = 10,
  eclisseLunare = 11,
  tempestaElettroMagnetica = 12
}

export class RulesSezione {
  uid: string;
  name: string;
  sequence: number;
  // rules: string;
  sottosezioni: RulesSottoSezione[];
}

export class RulesSottoSezione {
  uid: string;
  name: string;
  sequence: number;
  imgUrl: string;
  rules: Rule[];
  alsoParziale: boolean;
}

export class Rule {
  uid: string;
  name: string;
  sequence: number;
  text: string;
  alsoParziale: boolean;
}

export enum messageType {
  standard = 0,
  sussurro = 1,
  fato = 2
}


export class Message {
  uid: string;
  sName: string;
  // senderUID: string;
  sRaceId: string;
  sSex: Sex;
  time: Date | number;
  type: messageType;
  message: string;
  targetID: string;
}

export class onlineChatUser {
  uid: string;
  name: string;
  sex: Sex;
}

export class StandardChat {
  uid: string;
  name: string;
  description: string;
  group: chatGroups;
}

export class SpecialChat {
  uid: string;
  name: string;
  allowedToRace: Race[];
  allowedToClan: string[];
  allowedToCorp: string[];
  allowedOffRole: Role[];
  lvlMajorOrEqual: number;
  lvlMinorOrEqual: number;
  includeCharacters: string[];
  excludeCharacters: string[];
}

export class SecretChat {
  uid: string = "";
  name: string = "";
  location: string = "";
  ownerUID: string = "";
  accessibleByUIDs: string[] = [];

  constructor() {

  }
}

export enum chatGroups {
  The_Valley = 0,
  North_LA = 1,
  West_Side = 2,
  Hollywood = 3,
  Downtown = 4,
  Northeast = 5,
  South_LA = 6,
  South_Bay = 7
}

export enum chatType {
  public = 0,
  invite = 1
}

// export class SpecialChat {
//   uid: string;
//   name: string;
//   description: string;
//   type: chatType;
//   image: string;
// }

export class UserPresence {
  // id: string;
  state: PresencePlayerStatus;
  connections: any;
  // chatAreas: string[];
  // lastAccess: string;
}

export class PresencePlayerStatus {
  playerUID: string;
  race: Race;
  clan: string;
  corp: string;
  role: Role;
  state: State;
  miniAvatarUrl: string;
  name: string;
  sur: string;
  nick: string;
  lastAccess: string;
  sex: Sex;
  lvl: number;
  stateText: string;
  isPng: boolean;
  isCapoClan: boolean;
  isCapoCorp: boolean;
  isCapoRace: number[];
  isBanned: boolean;
  lastMessageSent: number;
  myPrivateChat: SecretChat;
  isActive: boolean;
  clanLvl: number;
  corpLvl: number;
  ip: string;
  // currentArea: string;
  //Ruoli off

  constructor(uid: string, race: Race, role: Role, miniAvatarUrl: string, name: string, sur: string, nick: string, lastAccess: string, sex: Sex, lvl: number, state: State, stateText: string, isPng: boolean, isBanned: boolean, lastMessageSent: number, myPrivateChat: SecretChat, clan: string, clanLvl: number, corp: string, corpLvl: number, isActive: boolean, isCapoClan: boolean, isCapoCorp: boolean, isCapoRace: number[], ip: string) {
    this.playerUID = uid;
    this.race = race;
    this.clan = clan;
    this.clanLvl = clanLvl || -1
    this.corp = corp;
    this.corpLvl = corpLvl || -1;
    this.role = role;
    this.miniAvatarUrl = miniAvatarUrl;
    this.name = name;
    this.sur = sur;
    this.nick = nick;
    this.lastAccess = lastAccess;
    this.state = state || State.libero;
    this.sex = sex;
    this.lvl = lvl;
    this.stateText = stateText;
    this.isPng = isPng || false;
    this.isBanned = isBanned || false;
    this.lastMessageSent = lastMessageSent || MiniFunctions.ToOADate(new Date());
    this.myPrivateChat = myPrivateChat || new SecretChat();
    this.isActive = isActive || false;
    this.isCapoClan = isCapoClan || false;
    this.isCapoCorp = isCapoCorp || false;
    this.isCapoRace = isCapoRace;
    this.ip = ip;

    // safety checks
    if (MiniFunctions.IsNullOrUndefined(this.isActive))
      this.isActive = false;

    if (MiniFunctions.IsNullOrUndefined(this.clan))
      this.clan = "";

    if (MiniFunctions.IsNullOrUndefined(this.corp))
      this.corp = "";

    if (MiniFunctions.IsNullOrUndefined(this.miniAvatarUrl))
      this.miniAvatarUrl = "";

    if (MiniFunctions.IsNullOrUndefined(this.name))
      this.name = "";

    if (MiniFunctions.IsNullOrUndefined(this.sur))
      this.sur = "";

    if (MiniFunctions.IsNullOrUndefined(this.nick))
      this.nick = "";

    if (MiniFunctions.IsNullOrUndefined(this.stateText))
      this.stateText = "";

    if (MiniFunctions.IsNullOrUndefined(this.isCapoRace))
      this.isCapoRace = [];

    if (MiniFunctions.IsNullOrUndefined(this.ip))
      this.ip = "";
  }
}

export enum State {
  libero = 0,
  occupato = 1,
  messaggi = 2
}

export class PresencePlayerUI {
  playerUID: string;
  state: State;
  race: Race;
  clan: string;
  corp: string;
  isPng: boolean;
  role: Role;
  miniAvatarUrl: string;
  name: string;
  sur: string;
  nick: string;
  sex: Sex;
  lvl: number;
  stateText: string;
  currentArea: string;
  lastMesageIsOld: boolean;
  clanLvl: number;
  corpLvl: number;
  isCapoClan: boolean;
  isCapoCorp: boolean;
  isCapoRace: boolean;

  constructor(userPresence: UserPresence) {
    this.playerUID = userPresence.state.playerUID;
    this.race = userPresence.state.race;
    this.clan = userPresence.state.clan;
    this.corp = userPresence.state.corp;
    this.role = userPresence.state.role;
    this.miniAvatarUrl = userPresence.state.miniAvatarUrl;
    this.name = userPresence.state.name;
    this.sur = userPresence.state.sur;
    this.nick = userPresence.state.nick;
    this.state = userPresence.state.state || State.libero;
    this.sex = userPresence.state.sex;
    this.lvl = userPresence.state.lvl;
    this.stateText = userPresence.state.stateText || "";
    this.isPng = userPresence.state.isPng;
    this.clanLvl = userPresence.state.clanLvl;
    this.corpLvl = userPresence.state.corpLvl;
    this.isCapoClan = userPresence.state.isCapoClan;
    this.isCapoCorp = userPresence.state.isCapoCorp;
    this.isCapoRace = (userPresence.state.isCapoRace || []).length > 0;

    const before3Month = new Date().getTime() - (3 * (24 * 3600 * 1000));
    const currentOADate = MiniFunctions.ToOADate(new Date());
    this.lastMesageIsOld = ((userPresence.state.lastMessageSent - currentOADate) >= before3Month)
    //state text
  }
}


export class UserEntry {
  uid: string;
  name: string;
  img: string;
}



//MESSAGES:
//inside firestore
export class PrivateConversation {
  cnvUID: string; //conversazione iD
  ownUID: string; //mittente ID
  onOff: OnOff;
  titolo: string;
  destUID: string[]; //destinatari IDs
  targetsUID: string[]; //destinatari + owner IDs (usati per quesry e modificato in caso di delete di un user)
  lMsgTS: FieldValue; //message timestamp in order to do database index
  lMsgDT: string; //message date time in ordet to show ui
  lMsgPTS: number;
  messages: PrivateMessage[]; //al messages
  conRiposta: boolean = true;

  constructor(cnvUID: string, ownUID: string, onOff: OnOff, titolo: string, destinatariUID: string[], targetsUID: string[], conRiposta: boolean) {
    this.cnvUID = cnvUID;
    this.ownUID = ownUID;
    this.onOff = onOff;
    this.titolo = titolo;
    this.destUID = destinatariUID;
    this.targetsUID = targetsUID;
    this.conRiposta = conRiposta;
  }
}

export class PMUI {
  cnvUID: string; //conversazione iD
  onOff: OnOff;
  titolo: string;
  mittente: UserEntry;
  dest: UserEntry[]; //destinatari IDs
  targetsUID: string[]; //destinatari + owner IDs (usati per quesry e modificato in caso di delete di un user)
  lMsgTS: FieldValue; //message timestamp in order to do database index
  lMsgDT: string; //message date time in ordet to show ui
  messages: PrivateMessageUI[]; //al messages
  conRisposta: boolean;

  constructor(cnvUID: string, onOff: OnOff, titolo: string, mittente: UserEntry, destinatari: UserEntry[], targetsUID: string[], conRisposta: boolean = true) {
    this.cnvUID = cnvUID;
    this.onOff = onOff;
    this.titolo = titolo;
    this.mittente = mittente;
    this.dest = destinatari;
    this.targetsUID = targetsUID;
    this.conRisposta = conRisposta;
  }
}



export class PrivateMessage {
  msgUID: string;
  ownUID: string;
  dateHour: string;
  message: string;

  constructor(msgUID: string, myUID: string, datehour: string, message: string) {
    this.msgUID = msgUID;
    this.ownUID = myUID;
    this.dateHour = datehour;
    this.message = message;
  }
}

export class PrivateMessageUI {
  msgUID: string;
  own: UserEntry;
  dateHour: string;
  message: string;

  constructor(msgUID: string, own: UserEntry, datehour: string, message: string) {
    this.msgUID = msgUID;
    this.own = own;
    this.dateHour = datehour;
    this.message = message;
  }
}

export enum OnOff {
  on = 0,
  off = 1
}

export class VoltoUI {
  uid: string;
  uidPG: string;
  pgName: string;
  vName: string;
  vSur: string;
  vNick: string;

  constructor(uid: string, vName: string, vSur: string, vNick: string, uidPG: string = "", pgName: string = "") {
    this.uid = uid;
    this.vName = vName;
    this.vSur = vSur;
    this.vNick = vNick;
    this.uidPG = uidPG;
    this.pgName = pgName;
  }
}

export class Clan {
  uid: string;
  name: string;
  iconUrl: string;
  roles: AffiliationRole[];
}

export class Corp {
  uid: string;
  name: string;
  iconUrl: string;
  roles: AffiliationRole[];
}

export class AffiliationRole {
  roleGrade: number;
  name: string = "";
  iconUrl: string = "";
  money: number = 0;
}

export class Citazione {
  id: string;
  name: string;
  description: string;
}

export class LogPX {
  id: string;
  logTS: FieldValue; //timestamp in order to do database index
  logDT: string; //date time in ordet to show ui
  logPTS: number; //sorting date time

  from: string;
  to: string;

  usableBC: string; // usable before current change
  qta: string; // number of exp (positivo o negativo)
  why: string; // exp from message, or description from cp, or buying experience

  constructor(id: string, from: string, to: string, usableBC: string, qta: string, why: string) {
    this.id = id;
    this.from = from;
    this.to = to;

    const dateHour: string = MiniFunctions.GetCurrentDate();
    this.logDT = dateHour;
    this.logPTS = Date.now();

    this.usableBC = usableBC;
    this.qta = qta;
    this.why = why;
  }
}

export class LogOgg {
  id: string;
  logTS: FieldValue; //timestamp in order to do database index
  logDT: string; //date time in ordet to show ui
  logPTS: number; //sorting date time

  from: string;
  to: string;

  what: string; // obj name
  where: string; // from cp o in game

  constructor(id: string, from: string, to: string, what: string, where: string) {
    this.id = id;
    this.from = from;
    this.to = to;

    const dateHour: string = MiniFunctions.GetCurrentDate();
    this.logDT = dateHour;
    this.logPTS = Date.now();

    this.what = what;
    this.where = where;
  }
}

export class LogBank {
  id: string;
  logTS: FieldValue; //timestamp in order to do database index
  logDT: string; //date time in ordet to show ui
  logPTS: number; //sorting date time

  from: string;
  to: string;

  qta: string; // cash
  what: string; // reposito, prelievo o trasferimento

  constructor(id: string, from: string, to: string, qta: string, what: string) {
    this.id = id;
    this.from = from;
    this.to = to;

    const dateHour: string = MiniFunctions.GetCurrentDate();
    this.logDT = dateHour;
    this.logPTS = Date.now();

    this.qta = qta;
    this.what = what;
  }
}

export class LocationRoot {
  uid: string;
  locationsAreas: LocationArea[];
}

export class LocationArea {
  numericID: number;
  name: string;
  sequence: number;
  text: string;
  subLocations: LocationChat[];
}

export class LocationChat {
  chatUid: string;
  name: string;
  sequence: number;
  text: string;
  chatInsideLocations: LocationInsideChat[];
}

export class LocationInsideChat {
  uid: string;
  name: string;
  sequence: number;
  text: string;
}

export class uiLocation {
  name: string;
  sequence: number;
  text: string;

  constructor(name: string, sequence: number, text: string) {
    this.name = name;
    this.sequence = sequence;
    this.text = text;
  }
}

export class RaceIcons {
  raceID: Race;
  name: string;

  iconUrlLvl1: string;
  iconUrlLvl2: string;
  iconUrlLvl3: string;
  iconUrlLvl4: string;
  iconUrlLvl5: string;

  constructor(raceID: Race, name: string) {
    this.raceID = raceID;
    this.name = name;
    this.iconUrlLvl1 = "";
    this.iconUrlLvl2 = "";
    this.iconUrlLvl3 = "";
    this.iconUrlLvl4 = "";
    this.iconUrlLvl5 = "";
  }
}

//#region - mini functions
// used only here in order to remove the circular dependency between this files and the functions files
export class MiniFunctions {

  //Check if an obj is null, using the strict equals "===" comparer
  public static IsNull(obj: any): boolean {
    return obj === null;
  }

  //Check if an obj is undefined
  public static IsUndefined(obj: any): boolean {
    return (typeof obj) === 'undefined';
  }

  //Check if an obj is null or undefined
  public static IsNullOrUndefined(obj: any): boolean {
    return (MiniFunctions.IsNull(obj) || MiniFunctions.IsUndefined(obj));
  }

  //Converts date to a float OLE Date
  public static ToOADate(jsDate: any) {
    jsDate = jsDate || new Date();
    const timezoneOffset: number = jsDate.getTimezoneOffset() / (60 * 24);
    const msDateObj: number = (jsDate.getTime() / 86400000) + (25569 - timezoneOffset);
    return msDateObj;
  }

  public static GetCurrentDate() {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var today = new Date();

    return today.toLocaleDateString("it-IT", options);
  }
}

//#endregion - mini functions


//#region - race forms

export class RaceForm {
  uid: string;
  name: string;
  form: any;
}

//#endregion -race forms
