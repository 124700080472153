import {
  Component, ElementRef, ViewChild, Input, Output, OnInit, AfterViewChecked,
  HostListener, HostBinding, EventEmitter, OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layoutSelector from './../../../selectors/layout.selectors';
import { Observable, Subscription } from 'rxjs';
import { Functions } from '../../utilities/functions/utilities.functions';
import * as layout from './../../../actions/layout';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.css']
})
export class ModalComponent implements OnInit, AfterViewChecked, OnDestroy {

  @Input() modalTitle: string;
  @Input() modalType: number;
  @Input() width: any;
  @Input() zIndex: number;
  @Input() minWidth: number = 260;
  @Input() minHeight: number = 200;
  @Input() scrollTop: boolean = true;
  @Input() maximizable: boolean;
  @Input() backdrop: boolean = true;

  @Output() close: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('modalRoot', { static: false }) modalRoot: ElementRef;
  @ViewChild('modalBody', { static: false }) modalBody: ElementRef;
  @ViewChild('modalHeader', { static: false }) modalHeader: ElementRef;
  @ViewChild('modalFooter', { static: false }) modalFooter: ElementRef;

  @HostBinding('class.app-modal') cssClass = true;

  visible: boolean = false;
  contentzIndex: number;
  executePostDisplayActions: boolean;
  maximized: boolean;
  preMaximizeRootWidth: number;
  preMaximizeRootHeight: number;
  preMaximizeBodyHeight: number;
  preMaximizePageX: number;
  preMaximizePageY: number;
  dragEventTarget: MouseEvent | TouchEvent;

  isModalShow$: Observable<boolean>;
  modalShowSubscription: Subscription;

  isLeggiMessaggioShown$: Observable<boolean>;
  isCreaMessaggioShown$: Observable<boolean>;

  constructor(private element: ElementRef, private store: Store<fromRoot.State>) { }

  ngOnInit() {
    if (!this.zIndex) {
      this.zIndex = this.getMaxModalIndex() + 1;
      this.zIndex = this.zIndex || 1100;
    }
    this.contentzIndex = this.zIndex + 1;

    let self: this = this;
    this.isLeggiMessaggioShown$ = this.store.select(layoutSelector.getIsLeggiMessaggioOpen);
    this.isCreaMessaggioShown$ = this.store.select(layoutSelector.getIsCreaMessaggioOpen);

    if (this.modalType == 1) {
      //CHAT
      this.isModalShow$ = this.store.select(layoutSelector.getIsChatModalOpen);
    } else {
      //HOME
      this.isModalShow$ = this.store.select(layoutSelector.getIsHomeModalOpen);
    }


    this.modalShowSubscription = this.isModalShow$.subscribe((value: boolean) => {
      if ((value == true) && (this.visible == false))
        self.show();
      else if ((value == false) && (this.visible == true))
        self.hide();
    });
  }

  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.modalShowSubscription) == false)
      this.modalShowSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    if (this.executePostDisplayActions) {
      this.center();
      this.executePostDisplayActions = false;
    }
  }

  @HostListener('keydown.esc', ['$event'])
  onKeyDown(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.hide();
  }

  show(): void {
    this.executePostDisplayActions = true;
    this.visible = true;
    setTimeout(() => {
      this.modalRoot.nativeElement.focus();
      if (this.scrollTop) {
        this.modalBody.nativeElement.scrollTop = 0;
      }
    }, 1);
  }

  backToMssaggi(): void {
    if (this.modalType != 1) {
      this.store.dispatch(new layout.ToggleMessaggiRicevutiBoxAction(true));
    }
  }

  hide(): void {
    this.visible = false;

    if (this.modalType == 1) {
      //CHAT
      const isActionOpen: boolean = fromRoot.getState(this.store).layout.isChatActionBoxOpen;
      const isDiceOpen: boolean = fromRoot.getState(this.store).layout.isChatDiceBoxOpen;
      const isDiceResponseOpen: boolean = fromRoot.getState(this.store).layout.isChatDiceResponseBoxOpen;
      const isDiceDmgOpen: boolean = fromRoot.getState(this.store).layout.isChatDiceDmgBoxOpen;
      const isIstruzioniOpen: boolean = fromRoot.getState(this.store).layout.isChatInstructionBoxOpen;
      const isChatSkillBoxOpen: boolean = fromRoot.getState(this.store).layout.isChatSkillBoxOpen;
      const isChatItemBoxOpen: boolean = fromRoot.getState(this.store).layout.isChatItemBoxOpen;

      if (isActionOpen) {
        this.store.dispatch(new layout.ToggleChatActionBoxAction());
      } else if (isDiceOpen) {
        this.store.dispatch(new layout.ToggleChatDiceBoxAction());
      } else if (isDiceResponseOpen) {
        this.store.dispatch(new layout.ToggleChatDiceResponseBoxAction(false));
      } else if (isDiceDmgOpen) {
        this.store.dispatch(new layout.ToggleChatDiceDmgBoxAction(false));
      } else if (isIstruzioniOpen) {
        this.store.dispatch(new layout.ToggleChatInstructionsBoxAction());
      } else if (isChatSkillBoxOpen) {
        this.store.dispatch(new layout.ToggleChatSkillBoxAction());
      } else if (isChatItemBoxOpen) {
        this.store.dispatch(new layout.ToggleChatItemBoxAction());
      }

    } else {
      //HOME
      const isCreaMessaggioOpen: boolean = fromRoot.getState(this.store).layout.isCreaMessaggioOpen;
      const isMessaggiRicevutiOpen: boolean = fromRoot.getState(this.store).layout.isMessaggiRicevutiOpen;
      const isLeggiMessaggioOpen: boolean = fromRoot.getState(this.store).layout.isLeggiMessaggioOpen;
      const isEventsCalendarOpen: boolean = fromRoot.getState(this.store).layout.isEventsCalendarOpen;
      const isMeteoOpen: boolean = fromRoot.getState(this.store).layout.isMeteoOpen;
      const isLocationsOpen: boolean = fromRoot.getState(this.store).layout.isLocationsOpen;
      const isRulesOpen: boolean = fromRoot.getState(this.store).layout.isRulesOpen;
      const isUfficiOpen: boolean = fromRoot.getState(this.store).layout.isUfficiOpen;
      const isBankOpen: boolean = fromRoot.getState(this.store).layout.isBankOpen;
      const isUfficiCreditsOpen: boolean = fromRoot.getState(this.store).layout.isUfficiCreditsOpen;
      const isUfficiMappaOpen: boolean = fromRoot.getState(this.store).layout.isUfficiMappaOpen;
      const isUfficiSecretChatsOpen: boolean = fromRoot.getState(this.store).layout.isUfficiSecretChatsOpen;
      const isUfficiCreaSecretChatOpen: boolean = fromRoot.getState(this.store).layout.isUfficiCreaSecretChatOpen;
      const isCambiaNomeOpen: boolean = fromRoot.getState(this.store).layout.isCambiaNomeOpen;
      const isCambiaVoltoOpen: boolean = fromRoot.getState(this.store).layout.isCambiaVoltoOpen;
      const isUfficiCorpOpen: boolean = fromRoot.getState(this.store).layout.isUfficiCorpOpen;
      const isUfficiClanOpen: boolean = fromRoot.getState(this.store).layout.isUfficiClanOpen;
      const isUfficiRazzeOpen: boolean = fromRoot.getState(this.store).layout.isUfficiRazzeOpen;
      const isUfficiStaffOpen: boolean = fromRoot.getState(this.store).layout.isUfficiStaffOpen;
      const isAnagrafeOpen: boolean = fromRoot.getState(this.store).layout.isAnagrafeOpen;
      const isVoltiTypeOpen: boolean = fromRoot.getState(this.store).layout.isVoltiTypeOpen;
      const isPresentiOpen: boolean = fromRoot.getState(this.store).layout.isPresentiOpen;

      if (isCreaMessaggioOpen) {
        this.store.dispatch(new layout.ToggleCreaMessaggioBoxAction(false));
      } else if (isMessaggiRicevutiOpen) {
        this.store.dispatch(new layout.ToggleMessaggiRicevutiBoxAction(false));
      } else if (isLeggiMessaggioOpen) {
        this.store.dispatch(new layout.ToggleLeggiMessaggioBoxAction(false));
      } else if (isEventsCalendarOpen) {
        this.store.dispatch(new layout.ToggleEventsCalendarAction(false));
      } else if (isMeteoOpen) {
        this.store.dispatch(new layout.ToggleMeteoBoxAction(false));
      } else if (isLocationsOpen) {
        this.store.dispatch(new layout.ToggleLocationsAction(false));
      } else if (isRulesOpen) {
        this.store.dispatch(new layout.ToggleRulesBoxAction(false));
      } else if (isUfficiOpen) {
        this.store.dispatch(new layout.ToggleUfficiAction(false));
      } else if(isBankOpen) {
        this.store.dispatch(new layout.ToggleUfficiBankAction(false));
      } else if(isUfficiCreditsOpen) {
        this.store.dispatch(new layout.ToggleUfficiCreditsAction(false));
      } else if(isUfficiMappaOpen) {
        this.store.dispatch(new layout.ToggleUfficiMappaAction(false));
      } else if(isUfficiSecretChatsOpen) {
        this.store.dispatch(new layout.ToggleUfficiSecretChatsAction(false));
      } else if(isUfficiCreaSecretChatOpen) {
        this.store.dispatch(new layout.ToggleUfficiCreaSecretChatAction(false));
      } else if(isCambiaNomeOpen) {
        this.store.dispatch(new layout.ToggleCambiaNomeAction(false));
      } else if(isCambiaVoltoOpen) {
        this.store.dispatch(new layout.ToggleCambiaVoltoAction(false));
      } else if(isUfficiCorpOpen) {
        this.store.dispatch(new layout.ToggleUfficiCorpAction(false));
      } else if(isUfficiClanOpen) {
        this.store.dispatch(new layout.ToggleUfficiClanAction(false));
      } else if(isUfficiRazzeOpen) {
        this.store.dispatch(new layout.ToggleUfficiRazzeAction(false));
      } else if(isUfficiStaffOpen) {
        this.store.dispatch(new layout.ToggleUfficiStaffAction(false));
      } else if(isAnagrafeOpen) {
        this.store.dispatch(new layout.ToggleAnagrafeAction(false));
      } else if(isVoltiTypeOpen) {
        this.store.dispatch(new layout.ToggleVoltiTypeAction(false));
      } else if(isPresentiOpen) {
        this.store.dispatch(new layout.TogglePresentiAction(false));
      }
    }

    this.close.emit(true);
    this.focusLastModal();
  }

  center() {
    let elementWidth = this.modalRoot.nativeElement.offsetWidth;
    let elementHeight = this.modalRoot.nativeElement.offsetHeight;

    if (elementWidth === 0 && elementHeight === 0) {
      this.modalRoot.nativeElement.style.visibility = 'hidden';
      this.modalRoot.nativeElement.style.display = 'block';
      elementWidth = this.modalRoot.nativeElement.offsetWidth;
      elementHeight = this.modalRoot.nativeElement.offsetHeight;
      this.modalRoot.nativeElement.style.display = 'none';
      this.modalRoot.nativeElement.style.visibility = 'visible';
    }

    const x = Math.max((window.innerWidth - elementWidth) / 2, 0);
    const y = Math.max((window.innerHeight - elementHeight) / 2, 0);

    this.modalRoot.nativeElement.style.left = x + 'px';
    this.modalRoot.nativeElement.style.top = y + 'px';
  }

  initDrag(event: MouseEvent | TouchEvent) {
    if (!this.maximized) {
      this.dragEventTarget = event;
    }
  }

  calcBodyHeight() {
    const diffHeight = this.modalHeader.nativeElement.offsetHeight + this.modalFooter.nativeElement.offsetHeight;
    const contentHeight = this.modalRoot.nativeElement.offsetHeight - diffHeight;
    this.modalBody.nativeElement.style.height = contentHeight + 'px';
    this.modalBody.nativeElement.style.maxHeight = 'none';
  }

  getMaxModalIndex() {
    let zIndex = 0;
    const modals = document.querySelectorAll('.ui-modal');
    [].forEach.call(modals, function (modal) {
      const indexCurrent = parseInt(modal.style.zIndex, 10);
      if (indexCurrent > zIndex) {
        zIndex = indexCurrent;
      }
    });
    return zIndex;
  }

  focusLastModal() {
    const modal = this.findAncestor(this.element.nativeElement, 'app-modal');
    if (modal && modal.children[1]) {
      modal.children[1].focus();
    }
  }

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls)) {
    }
    return el;
  }

  onCloseIcon(event: Event) {
    event.stopPropagation();
  }

  moveOnTop() {
    if (!this.backdrop) {
      const zIndex = this.getMaxModalIndex();
      if (this.contentzIndex <= zIndex) {
        this.contentzIndex = zIndex + 1;
      }
    }
  }

}
