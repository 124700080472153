import { BonusMalus, SecretChat, UserEntry, CharacterItems, LocationRoot, Clan, Corp, Volto, Rule, RulesSezione, StandardChat, SpecialChat, MeteoDay } from './../models/data/application.data';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { Subscription, Observable } from 'rxjs';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as character from '../actions/character';
import * as layout from '../actions/layout';
import * as datas from '../actions/datas';
import { NgForage } from 'ngforage';
import * as chat from './../actions/chat';
import { Skill, CharacterSheetData, Item, DBVersioning, DBVersionType, LocalCache } from '../models/data/application.data';
import { PresenceService } from './presence.service';
import { PmService } from './pm.service';
import { LogsService } from './logs.service';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { IncrementalService } from './incremental.service';
import { CacheService } from './cache.service';
import { first } from 'rxjs/operators';
import { DebugLoggerService } from './debug-logger.service';

@Injectable()
export class FirestoreService {
  private moduleName: string = "firestoreService";

  //#region - character sheet variables
  private characterSheetDoc: AngularFirestoreDocument<CharacterSheetData>;
  private characterSheetDocObservable: Observable<CharacterSheetData>;
  private characterSheetSubscription: Subscription;
  //#endregion - character sheet variables

  private onlineDBVersion: Subscription;
  private rulesListSubscription: Subscription;
  private citazioniSubscription: Subscription;

  private staffNewsSubscription: Subscription;
  private firstEntranceMsgSubscription: Subscription;
  private chatInstructionSubscription: Subscription;
  private eventsSubscription: Subscription;
  private meteoSubscription: Subscription;
  // private meteoAskedFor: string = "";
  private raceIconsSubscription: Subscription;

  private tempSubscription: Subscription = new Subscription();
  private tempUserSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    private presenceService: PresenceService,
    private ngf: NgForage,
    private pmService: PmService,
    private logsService: LogsService,
    private afs: AngularFirestore,
    private cacheService: CacheService,
    private incrementalService: IncrementalService,
    private debugLogger: DebugLoggerService
  ) {
    // // update data if needed
    // this.loadCachedData();
    //TODO: temp
    // this.getUsersList();
  }

  //#region - initial get
  public getMyCharacterSheet(uid: string) {
    let self = this;
    if (Functions.IsNullOrUndefined(this.characterSheetSubscription) == false)
      this.characterSheetSubscription.unsubscribe();

    // this.characterSheetSubscription = this.firestore.query('users').where("uid", "==", uid).on().skip(1).subscribe((userData) => {

    this.characterSheetDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
    this.characterSheetDocObservable = this.characterSheetDoc.valueChanges();
    this.characterSheetSubscription = this.characterSheetDocObservable.subscribe((userData: CharacterSheetData) => {
      // this.characterSheetSubscription = this.firestore.read('users/' + uid).skip(1).subscribe((userData: CharacterSheetData) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, "READ my character sheet", self.moduleName, "users", 1);
      }

      self.store.dispatch(new character.StoreUserData({ myCharacter: userData, isAuthenticated: true, uid: uid }));

      // check if the user has no sheet
      if (Functions.IsNullOrUndefined(userData) == true && Functions.IsStringEmpty(uid) == false) {
        self.store.dispatch(new character.StoreUserData({ myCharacter: null, isAuthenticated: true, uid: uid }));
        return;
      }


      //check if i need first entrance msg
      if (Functions.IsNullOrUndefined(userData) == false && Functions.IsStringEmpty(userData.uid) == false
        && (Functions.IsNullOrUndefined(userData.isFirstEntrance) || userData.isFirstEntrance == true)) {
        self.getFirstEntranceMessage();
      }

      const currentSelectedCharacter: CharacterSheetData = fromRoot.getState(self.store).character.currentSelectedCharacter;
      if (Functions.IsNullOrUndefined(currentSelectedCharacter) == false && currentSelectedCharacter.uid == uid) {
        self.store.dispatch(new character.SelectplayerID(uid));
      }

      setTimeout(() => {
        // set-up presence system
        self.presenceService.setUpMyPresence(uid);
        // self.presenceService.subscribeUserStatus();
        self.pmService.setUpPMService();
      }, 0);


      //#region - check for buyable skyll dump
      // if (Functions.IsNullOrUndefined(userData.forceBuyableSkillDump) == false && userData.forceBuyableSkillDump == true) {
      //   const allSkills: Skill[] = fromRoot.getState(this.store).datas.skills;
      //   self.cacheService.overrideCacheAndStorage(DBVersionType.skills, allSkills, false, [], []);

      //   const updatedUserData: CharacterSheetData = Object.assign({}, userData);
      //   updatedUserData.forceBuyableSkillDump = false;
      //   self.updateCharacterSheet(updatedUserData.uid, updatedUserData, true);
      // }
      //#endregion - check for buyable skyll dump

    });
  }

  // used only from home esterna
  public getRules() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.rulesListSubscription) == true) {

      const rulesCollection: AngularFirestoreCollection<RulesSezione> = this.afs.collection<RulesSezione>('rules');
      const rulesCollectionObservable: Observable<RulesSezione[]> = rulesCollection.valueChanges();
      this.rulesListSubscription = rulesCollectionObservable.pipe(first()).subscribe((rulesData) => {
        // this.rulesListSubscription = this.firestore.query('rules').on().skip(1).subscribe((rulesData) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ rules", self.moduleName, "rules", (rulesData as RulesSezione[]).length);
        }

        self.store.dispatch(new datas.StoreRules({ rules: rulesData as RulesSezione[], ver: 0, fullFlag: false }));

        setTimeout(() => {
          self.rulesListSubscription.unsubscribe();
        }, 0);
      });
    }
  }

  // used at beginning to retrive the race icons
  public getRaceIcons() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.raceIconsSubscription) == true) {
      const raceIconsDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/races');
      const raceIconsDocObservable: Observable<any> = raceIconsDoc.valueChanges();
      this.raceIconsSubscription = raceIconsDocObservable.subscribe((data) => {
        // this.staffNewsSubscription = this.firestore.read('settings/staffNews').subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ race icons", self.moduleName, "settings/races", 1);
        }

        if (Functions.IsNullOrUndefined(data) == false && Functions.IsNullOrUndefined(data.value) == false)
          self.store.dispatch(new datas.StoreRaceIcons(data.value));
      });
    }
  }

  // donwload everytime always 1 doc
  public getStaffNews() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.staffNewsSubscription) == true) {
      const staffNewsDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/staffNews');
      const staffNewsDocObservable: Observable<any> = staffNewsDoc.valueChanges();
      this.staffNewsSubscription = staffNewsDocObservable.subscribe((data) => {
        // this.staffNewsSubscription = this.firestore.read('settings/staffNews').subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ staff news", self.moduleName, "settings/staffNews", 1);
        }

        self.store.dispatch(new datas.StoreStaffNews(data));
      });
    }
  }

  // donwload only the first time
  public getFirstEntranceMessage() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.firstEntranceMsgSubscription) == true) {
      const firstEntranceMsgDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/startingMsg');
      const firstEntranceMsgObservable: Observable<any> = firstEntranceMsgDoc.valueChanges();
      this.firstEntranceMsgSubscription = firstEntranceMsgObservable.pipe(first()).subscribe((data) => {
        // this.firstEntranceMsgSubscription = this.firestore.read('settings/startingMsg').subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ first entrance msg", self.moduleName, "settings/startingMsg", 1);
        }

        self.store.dispatch(new datas.StoreFirstEntranceMsg(data));
      });
    }
  }

  // donwload always 1 doc if you went to instruction chat
  public getChatInstruction() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.chatInstructionSubscription) == true) {
      const chatInstructionDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/istruzioniChat');
      const chatInstructionObservable: Observable<any> = chatInstructionDoc.valueChanges();
      this.chatInstructionSubscription = chatInstructionObservable.subscribe((data) => {
        // this.chatInstructionSubscription = this.firestore.read('settings/istruzioniChat').subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ chat instruction", self.moduleName, "settings/istruzioniChat", 1);
        }

        self.store.dispatch(new chat.StoreChatInstruction(data));
      });
    }
  }

  // 1 to n events
  public getEvents() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.eventsSubscription) == true) {
      const eventsCollection: AngularFirestoreCollection<any> = this.afs.collection<any>('calendarEvents');
      const eventsCollectionObservable: Observable<any[]> = eventsCollection.valueChanges();
      this.eventsSubscription = eventsCollectionObservable.subscribe((data) => {
        // this.eventsSubscription = this.firestore.read('calendarEvents').subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ events", self.moduleName, "calendarEvents", (data as any[]).length);
        }

        self.store.dispatch(new datas.StoreEvents(data));
      });
    }
  }

  // donwload everytime always 1 doc / 31 if you went to cpnale
  public getMeteo() {
    let self = this;
    let today: string = new Date().getDate().toString();
    // if (today.length < 2)
    //   today = "0" + today;

    // if (Functions.IsStringEmpty(this.meteoAskedFor) == false && this.meteoAskedFor != today) {
    if (Functions.IsNullOrUndefined(this.meteoSubscription) == false) {
      this.meteoSubscription.unsubscribe();
      this.meteoSubscription = undefined;
    }

    // this.meteoAskedFor = "";
    // }

    if (Functions.IsNullOrUndefined(this.meteoSubscription) == true) {
      const meteoDoc = this.afs.doc<MeteoDay>('meteoDays/' + today);
      const meteoDocObservable = meteoDoc.valueChanges();
      this.meteoSubscription = meteoDocObservable.subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ meteo day", self.moduleName, "meteoDays", 1);
        }

        self.store.dispatch(new datas.StoreMeteo(data));
      });


      const meteoTextDoc = this.afs.doc<any>('settings/meteoText');
      const meteoTextDocObservable = meteoTextDoc.valueChanges();
      this.meteoSubscription.add(
        meteoTextDocObservable.subscribe((data) => {
          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(false, "READ meteo text", self.moduleName, "settings/meteoText", 1);
          }

          self.store.dispatch(new datas.StoreMeteoNote(data));
        })
      );

      // this.meteoAskedFor = today;
    }
  }

  // public killMeteoSubscription() {
  //   if (Functions.IsNullOrUndefined(this.meteoSubscription) == false) {
  //     this.meteoSubscription.unsubscribe();
  //     this.meteoAskedFor = "";
  //   }

  // }

  // donwload everytime always 1 doc
  public getCitazioni() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.citazioniSubscription) == true) {
      const citazioniDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/citazioni');
      const citazioniObservable: Observable<any> = citazioniDoc.valueChanges();
      this.citazioniSubscription = citazioniObservable.pipe(first()).subscribe((data) => {
        // this.citazioniSubscription = this.firestore.read('settings/citazioni').subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ citazioni", self.moduleName, "rules", (data as any[]).length);
        }

        if (Functions.IsNullOrUndefined(data) == false && Functions.IsNullOrUndefined(data.value) == false) {
          self.store.dispatch(new datas.StoreCitazioni(data.value));
        }
      });
    }
  }

  public forceRefresh() {
    // refreshing chatlist
    // if (Functions.IsNullOrUndefined(this.standardChatsListSubscription) == false) {
    //   this.standardChatsListSubscription.unsubscribe();
    //   this.standardChatsListSubscription = undefined;
    // }
    // this.getStandardChatsList();

    // refreshing staff news
    if (Functions.IsNullOrUndefined(this.staffNewsSubscription) == false) {
      this.staffNewsSubscription.unsubscribe();
      this.staffNewsSubscription = undefined;
    }
    this.getStaffNews();

    // just removing reference to the event, in this way we will donwload them again when the user will enter again in calendar
    if (Functions.IsNullOrUndefined(this.eventsSubscription) == false) {
      this.eventsSubscription.unsubscribe();
      this.eventsSubscription = undefined;
    }

  }
  //#endregion

  //#region - editing/updating
  public updateCharacterSheetItems(uid: string, userSheet: CharacterSheetData) {
    let updatedUserJsonData = JSON.parse(JSON.stringify(userSheet));
    const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
    playerToUpdateDoc.update(updatedUserJsonData)
      .then(() => {
        // DO NOTHING
      })
      .catch((error: any) => {
        console.log("Update error");
      })

    // let updateSubscription: Subscription = this.firestore.update('users/' + uid, userSheet).subscribe(() => {
    //   updateSubscription.unsubscribe();
    // }, (error) => {
    //   updateSubscription.unsubscribe();
    //   //TODO: errori
    //   // self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public updateCharacterSheetHealth(uid: string, newHealth: number, newMindHealth: number) {
    const self: this = this;
    if (uid == fromRoot.getState(this.store).character.myUID) {
      // devo aggiornare la mia scheda
      const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
      playerToUpdateDoc.update({ health: newHealth, mindHealth: newMindHealth })
        .then(() => {
          // DO NOTHING
          self.store.dispatch(new chat.SelectChatPlayer(uid));
        })
        .catch((error: any) => {
          console.log("Update error");
        })

      // let userSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
      // userSheet.health = newHealth;
      // userSheet.mindHealth = newMindHealth;
      // let updateSubscription: Subscription = this.firestore.update('users/' + uid, userSheet).subscribe(() => {
      //   updateSubscription.unsubscribe();
      // }, (error) => {
      //   updateSubscription.unsubscribe();
      //   //TODO: errori
      //   // self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      //devo aggiornare la scheda di altri
      if (fromRoot.getState(this.store).character.myCharacterData.role >= 3 || Functions.IsNullOrUndefined(fromRoot.getState(this.store).chat.seletedChatPlayer)) {
        throw new Error("update health altro pg non consentito. My charater ID: " + fromRoot.getState(this.store).character.myUID + "character selected: " + fromRoot.getState(this.store).chat.seletedChatPlayer);
      }
      const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
      playerToUpdateDoc.update({ health: newHealth, mindHealth: newMindHealth })
        .then(() => {
          // DO NOTHING
          self.store.dispatch(new chat.SelectChatPlayer(uid));
        })
        .catch((error: any) => {
          console.log("Update error");
        })

      // let characterSheetSubscription = this.firestore.read('users/' + uid).skip(1).subscribe((userData) => {
      // characterSheetSubscription.unsubscribe();
      // let userData: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).chat.seletedChatPlayer);
      // userData.health = newHealth;
      // userData.mindHealth = newMindHealth;
      // let updateSubscription: Subscription = this.firestore.update('users/' + uid, userData).subscribe(() => {
      //   this.store.dispatch(new chat.SetSelectedChatPlayer(userData));
      //   updateSubscription.unsubscribe();
      // }, (error) => {
      //   updateSubscription.unsubscribe();
      //   //TODO: errori
      //   // self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
      // });
    }
  }

  public updateSecretChatMembers(uid: string, secretChat: SecretChat) {
    const secretChatJSON = JSON.parse(JSON.stringify(secretChat));

    if (uid == fromRoot.getState(this.store).character.myUID) {
      // devo aggiornare la mia scheda
      const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
      playerToUpdateDoc.update({ myPrivateChat: secretChatJSON })
        .then(() => {
          // DO NOTHING
        })
        .catch((error: any) => {
          console.log("Update secret chat error");
        })

      // let userSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
      // userSheet.myPrivateChat = secretChatJSON;
      // let updateSubscription: Subscription = this.firestore.update('users/' + uid, userSheet).subscribe(() => {
      //   updateSubscription.unsubscribe();
      // }, (error) => {
      //   updateSubscription.unsubscribe();
      //   //TODO: errori
      //   // self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      //devo aggiornare la scheda di altri
      if (fromRoot.getState(this.store).character.myCharacterData.role >= 3) {
        throw new Error("update private chat non consentito");
      }

      let self: this = this;
      const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
      playerToUpdateDoc.update({ myPrivateChat: secretChatJSON })
        .then(() => {
          // DO NOTHING
          self.presenceService.updateAUserSecretChat(secretChat, uid)
        })
        .catch((error: any) => {
          console.log("Update secret chat error");
        })

    }
  }

  public updateCharacterSheet(uid: string = undefined, newSheet: CharacterSheetData, transfertToOtherPG: boolean, doNotSelectSheet: boolean = false) {
    let self: this = this;
    if (Functions.IsNullOrUndefined(uid) || uid == fromRoot.getState(this.store).character.myUID) {
      // devo aggiornare la mia scheda
      const myUID: string = fromRoot.getState(this.store).character.myUID;
      const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + myUID);
      playerToUpdateDoc.set(newSheet)
        .then(() => {
          // DO NOTHING
        })
        .catch((error: any) => {
          console.log("Update user");
        })

      // let updateSubscription: Subscription = this.firestore.update('users/' + myUID, newSheet).subscribe(() => {
      //   updateSubscription.unsubscribe();
      //   self.store.dispatch(new layout.ScrollToTopFloatingAction());
      // }, (error) => {
      //   updateSubscription.unsubscribe();
      //   //TODO: errori
      //   // self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    } else {
      if (fromRoot.getState(this.store).character.myCharacterData.role >= 3 && transfertToOtherPG == false)
        return;

      //devo aggiornare la scheda di altri
      const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + uid);
      playerToUpdateDoc.set(newSheet)
        .then(() => {
          if (doNotSelectSheet == false) {
            self.store.dispatch(new character.SelectplayerID(uid));
            self.store.dispatch(new layout.ScrollToTopFloatingAction());
          }
        })
        .catch((error: any) => {
          console.log("Update user");
        })

      // let updateSubscription: Subscription = this.firestore.update('users/' + uid, newSheet).subscribe(() => {
      //   updateSubscription.unsubscribe();
      //   if (doNotSelectSheet == false) {
      //     self.store.dispatch(new character.SelectplayerID(uid));
      //     self.store.dispatch(new layout.ScrollToTopFloatingAction());
      //   }
      // }, (error) => {
      //   updateSubscription.unsubscribe();
      //   //TODO: errori
      //   // self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
      // });
    }
  }

  public addExperienceForAction() {
    let character: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    let oldPxValue: string = character.px.toString();
    let oldUPxValue: string = character.usablePx.toString();
    character.px = parseInt(oldPxValue) + 2;
    character.usablePx = parseInt(oldUPxValue) + 2;

    let newLastMesageDate: number = Functions.ToOADate(new Date());
    character.lastMessageSent = newLastMesageDate;

    //this.logsService.logXP("", character.name, (character.usablePx || 0).toString(), "2", "XP azione chat");
    this.updateCharacterSheet(character.uid, character, false);
  }

  public updateLastMessageSentDate() {
    let character: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    let newLastMesageDate: number = Functions.ToOADate(new Date());
    character.lastMessageSent = newLastMesageDate;

    this.updateCharacterSheet(character.uid, character, false);
  }

  public transferDenaro(qta: number, targetUID: string) {
    const myCharacterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    myCharacterSheet.bank = myCharacterSheet.bank - qta;

    const self: this = this;
    const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + targetUID);
    const playerToUpdateDocObservable = playerToUpdateDoc.valueChanges();
    this.tempUserSubscription = playerToUpdateDocObservable.pipe(first()).subscribe((userData: CharacterSheetData) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, "READ a user because of transfert money", self.moduleName, "users", 1);
      }

      setTimeout(() => {
        self.tempUserSubscription.unsubscribe();
      }, 0);

      if (Functions.IsNullOrUndefined(userData) == false) {
        userData.bank = (userData.bank || 0) + qta;
        self.updateCharacterSheet(userData.uid, userData, true);
        self.updateCharacterSheet(myCharacterSheet.uid, myCharacterSheet, false);
        self.logsService.logBank(myCharacterSheet.name, userData.name, (qta || 0).toString(), "Trasferimento tra giocatori");

        // send pm to receiver
        const title: string = myCharacterSheet.name + " ha inviato denaro a " + userData.name;
        const message: string = myCharacterSheet.name + " ha inviato " + qta + "$ a " + userData.name;
        self.pmService.createMoneyTransfertPM([userData.uid], [userData.uid, myCharacterSheet.uid], title, message);
      }
    })
  }

  public transferObj(tagert: UserEntry, objUID: string, qta: number) {
    const myCharacterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    const itemIndexToGiveAway: number = myCharacterSheet.userItems.findIndex((aUserItem: CharacterItems) => aUserItem.uid == objUID);
    const itemToGiveAway: CharacterItems = myCharacterSheet.userItems[itemIndexToGiveAway];
    const itemData: Item = fromRoot.getState(this.store).datas.items.find((anItem: Item) => anItem.uid == objUID);

    // item not in the anagraphic
    if (Functions.IsNullOrUndefined(itemData))
      return;

    // updating my current character sheet
    if ((itemToGiveAway.qta || 1) > qta) {
      // remove some qta of the item
      myCharacterSheet.userItems[itemIndexToGiveAway].qta = (myCharacterSheet.userItems[itemIndexToGiveAway].qta || 1) - qta;
      myCharacterSheet.userItems[itemIndexToGiveAway].remainingUse = itemData.maxUse;
    } else {
      //remove item from list
      qta = (itemToGiveAway.qta || 1);
      myCharacterSheet.userItems = myCharacterSheet.userItems.filter((aUserItem: CharacterItems) => aUserItem.uid != objUID);
    }

    //SafetyCheck
    if (Functions.IsNullOrUndefined(myCharacterSheet.userItems[itemIndexToGiveAway]) == false && myCharacterSheet.userItems[itemIndexToGiveAway].uid == objUID && myCharacterSheet.userItems[itemIndexToGiveAway].qta <= 0) {
      myCharacterSheet.userItems = myCharacterSheet.userItems.filter((aUserItem: CharacterItems) => aUserItem.uid != objUID);
    }

    const self: this = this;
    const playerToUpdateDoc = this.afs.doc<CharacterSheetData>('users/' + tagert.uid);
    const playerToUpdateDocObservable = playerToUpdateDoc.valueChanges();
    this.tempUserSubscription = playerToUpdateDocObservable.pipe(first()).subscribe((userData: CharacterSheetData) => {
      // this.tempUserSubscription = this.firestore.read('users/' + tagert.uid).skip(1).subscribe((userData: CharacterSheetData) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, "READ a user because of transfert obj", self.moduleName, "users", 1);
      }

      setTimeout(() => {
        self.tempUserSubscription.unsubscribe();
      }, 0);

      // updating other player character sheet
      if (Functions.IsNullOrUndefined(userData) == false) {
        const itemAlreadyPresent: number = userData.userItems.findIndex((aUserItem: CharacterItems) => aUserItem.uid == objUID);
        if (itemAlreadyPresent != -1) {
          //already have item
          userData.userItems[itemAlreadyPresent].qta = (userData.userItems[itemAlreadyPresent].qta || 1) + qta;
        } else {
          //not have item
          const newCharacterItemEntry: CharacterItems = new CharacterItems();
          newCharacterItemEntry.uid = objUID;
          newCharacterItemEntry.qta = qta;
          newCharacterItemEntry.remainingUse = itemToGiveAway.remainingUse;
          userData.userItems.push(newCharacterItemEntry);
        }

        userData.userItems = JSON.parse(JSON.stringify(userData.userItems));
        myCharacterSheet.userItems = JSON.parse(JSON.stringify(myCharacterSheet.userItems));
        self.updateCharacterSheet(userData.uid, userData, true, true);
        self.updateCharacterSheet(myCharacterSheet.uid, myCharacterSheet, false);
        self.logsService.logOgg(myCharacterSheet.name, userData.name, itemData.name + " X " + (qta || 1).toString(), "Trasferimento oggetto tra giocatori");

        // send pm to receiver
        const title: string = myCharacterSheet.name + " ha inviato un oggetto a " + userData.name;
        const message: string = myCharacterSheet.name + " ha inviato l'oggetto " + itemData.name + " (x" + (qta || 1).toString() + ") a " + userData.name;
        self.pmService.createObjExchangePM([userData.uid], [userData.uid, myCharacterSheet.uid], title, message);
      }
    });
  }
  //#endregion

  //#region - unsubscribe/game over
  public logoutReset() {
    this.unsubscribeEverything();
    this.pmService.resetPMService();
  }

  public unsubscribeEverything() {
    if (Functions.IsNullOrUndefined(this.characterSheetSubscription) == false) {
      this.characterSheetSubscription.unsubscribe();
      this.characterSheetSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.rulesListSubscription) == false) {
      this.rulesListSubscription.unsubscribe();
      this.rulesListSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.staffNewsSubscription) == false) {
      this.staffNewsSubscription.unsubscribe();
      this.staffNewsSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.eventsSubscription) == false) {
      this.eventsSubscription.unsubscribe();
      this.eventsSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.onlineDBVersion) == false) {
      this.onlineDBVersion.unsubscribe();
      this.onlineDBVersion = undefined;
    }

    if (Functions.IsNullOrUndefined(this.citazioniSubscription) == false) {
      this.citazioniSubscription.unsubscribe();
      this.citazioniSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.firstEntranceMsgSubscription) == false) {
      this.firstEntranceMsgSubscription.unsubscribe();
      this.firstEntranceMsgSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.chatInstructionSubscription) == false) {
      this.chatInstructionSubscription.unsubscribe();
      this.chatInstructionSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.meteoSubscription) == false) {
      this.meteoSubscription.unsubscribe();
      this.meteoSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.tempSubscription) == false) {
      this.tempSubscription.unsubscribe();
      this.tempSubscription = new Subscription();;
    }

    if (Functions.IsNullOrUndefined(this.tempUserSubscription) == false) {
      this.tempUserSubscription.unsubscribe();
      this.tempUserSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.raceIconsSubscription) == false) {
      this.raceIconsSubscription.unsubscribe();
      this.raceIconsSubscription = undefined;
    }

  }
  //#endregion



}
