import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData, Stats, Race, Clan, Corp, UserPresence, PresencePlayerStatus, State, RaceForm } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-sign-up-form',
  templateUrl: 'cPanel-sign-up-form.component.html',
  styleUrls: ['cPanel-sign-up-form.component.less']
  // encapsulation: ViewEncapsulation.None
})
export class CPanelSignUpFormComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelSignUpForm";

  //#region - select variables
  public selectedRaceID: number;
  public selectedRace: any;

  // public usersPresence$: Observable<UserPresence[]>;
  // private usersSubscription: Subscription;
  // public characters: PresencePlayerStatus[] = [];
  //#endregion - select variables

  //#region - races variables
  public availableRaceIDsSet: Set<number>;
  public races: any[] = [{ uid: 0, name: "Nephilim" }, { uid: 2, name: "Sidhe" }, { uid: 3, name: "Lycan" }, { uid: 4, name: "Leech" }, { uid: 5, name: "Mondano" }];
  public racesMap: Map<string, Race>;
  //#endregion - races variables


  public styleEl: any;

  formValue: any;
  form: any = {
    components: []
  };

  options: any = {
    builder: {
      basic: false,
      advanced: false,
      data: false,
      premium: false,
      layout: false,
      customBasic: {
        title: 'Base',
        default: false,
        weight: 0,
        components: {
          textfield: true,
          textarea: true,
          number: true,
          checkbox: false,
          selectboxes: true,
          radio: true
        }
      }
    },
    // language: 'it',
    // i18n: {
    //   it: {
    //     'Label': 'Lable',
    //     'Label Position': 'Posizione label',
    //     'Placeholder': 'Placeholder',
    //     'Description': 'Descrizione',
    //     'Tooltip': 'Tooltip',
    //     'Prefix': 'Prefisso',
    //     'Suffix': 'Suffisso',
    //     'Widget': 'Widget',
    //     'Input Mask': 'Input Mask',
    //     'Custom CSS Class': 'Classe CSS personalizzata',
    //     'Tab Index': 'Tab Index',
    //     'Hidden ': 'Nascosto',
    //     'Hide Label': 'Nascondi label',
    //     'Show Word Counter': 'Mostra conteggio parole',
    //     'Show Character Counter': 'Mostra conteggio caratteri',
    //     'Hide Input': 'Nascondi Input',
    //     'Excellent': 'Eccellente',
    //     'Initial Focus': 'Focus iniziale',
    //     'Allow Spellcheck': 'Permetti check ortografico',
    //     'Disabled': 'Disabilitato',
    //     'Table View': 'Vista a tabella',
    //     'Modal Edit': 'Modifica modale',
    //     'Multiple Values': 'Valori multipli',
    //     'Persistent': 'Persistente',
    //     'Input Format': 'Formato de entrada',
    //     'Protected': 'Protegido',
    //     'Database Index': 'Índice de la base de datos',
    //     'Mixed (Allow upper and lower case)': 'Mezclado (Permitir mayúsculas y minúsculas)',
    //     'Uppercase': 'Mayúsculas',
    //     'Lowercase': 'Minúsculas',
    //     'Encrypted (Enterprise Only)': 'Cifrado (Sólo Empresa)',
    //     'Default Value': 'Valor por defecto',
    //     'Drag and Drop a form component': 'Arrastrar y soltar un componente',
    //     'Text Field': 'Campo de texto',
    //     'Email': 'Correo electrónico',
    //     'Text Area': 'Área de texto',
    //     'Panel': 'Panel',
    //     'Time': 'Tiempo',
    //     'Submit': 'Enviar',
    //     'Basic Components': 'Componentes básicos',
    //     'Layout Components': 'Componentes del diseño',
    //     'Advanced': 'Avanzado',
    //     'Hidden': 'Oculto',
    //     'Component': 'Componente',
    //     'Display': 'Mostrar',
    //     'Data': 'Datos',
    //     'Validation': 'Validación',
    //     'API': 'API',
    //     'Conditional': 'Condicional',
    //     'Logic': 'Lógica',
    //     'Layout': 'Diseño',
    //     'Allow Multiple Masks': 'Permitir varias máscaras',
    //     'Input Field': 'Campo de entrada',
    //     'Top': 'Arriba',
    //     'Left (Left-aligned)': 'Izquierda (alineado a la izquierda)',
    //     'Input Type': 'Tipo de entrada',
    //     'Collapsible': 'Colapsable',
    //     'Preview': 'Previsualización',
    //     'Text Case': 'Caso de texto',
    //     'Redraw On': 'Redraw On',
    //     'Clear Value When Hidden': 'Limpiar cuando se oculta',
    //     'Custom Default Value': 'Valor predeterminado',
    //     'Calculated Value': 'Valor calculado',
    //     'Calculate Value on server': 'Calcular el valor en el servidor',
    //     'Allow Manual Override of Calculated Value': 'Permitir la anulación manual del valor calculado',
    //     'Server': 'Servidor',
    //     'Client': 'Cliente',
    //     'None': 'Ninguno',
    //     'Validate On': 'Validar en',
    //     'Required': 'Requerido',
    //     'Unique': 'Único',
    //     'Minimum Length': 'Longitud mínima',
    //     'Maximum Length': 'Longitud máxima',
    //     'Minimum Word Length': 'Longitud mínima de la palabra',
    //     'Maximum Word Length': 'Longitud máxima de la palabra',
    //     'Regular Expression Pattern': 'Patrón de expresión regular',
    //     'Error Label': 'Etiqueta de error',
    //     'Custom Error Message': 'Mensaje de error personalizado',
    //     'Custom Validation': 'Validación personalizada',
    //     'JSONLogic Validation': 'Validación JSONLogic',
    //     'Property Name': 'Nombre de la propiedad',
    //     'Field Tags': 'Etiquetas Tags',
    //     'Custom Properties': 'Propiedades personalizadas',
    //     'Add Another': 'Agregar otro',
    //     'Save': 'Guardar',
    //     'Cancel': 'Cancelar',
    //     'Remove': 'Remover',
    //     'Rows': 'Filas',
    //     'Title': 'Título',
    //     'Theme': 'Tema',
    //     'Data Format': 'Formato de datos',
    //     'Advanced Logic': 'Lógica avanzada',
    //     'Advanced Conditions': 'Condiciones Avanzadas',
    //     'Simple': 'Simple',
    //     'HTML Attributes': 'Atributos HTML',
    //     'PDF Overlay': 'Overlay PDF',
    //     'Number': 'Número ',
    //     'Use Thousands Separator': 'Usar el separador de miles'
    //   }
    // }
  };

  private raceFormsCollection: AngularFirestoreCollection<RaceForm>;
  private raceFormsCollectionObservable: Observable<RaceForm[]>;
  private raceFormsSubscription: Subscription;
  private raceFormData: RaceForm[] = [];

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    public cPanelService: CPanelService,
    private afs: AngularFirestore,
    private afdb: AngularFireDatabase,
    private debugLogger: DebugLoggerService,
    private storage: AngularFireStorage
  ) { }


  public ngOnInit() {

    //add style
    this.styleEl = document.createElement('link');
    this.styleEl.rel = 'stylesheet';
    this.styleEl.type = 'text/css';
    this.styleEl.href = window.location.origin + '/assets/extra-styles/bootstrap.min.css';
    this.styleEl.media = 'all';
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(this.styleEl);

    let self: this = this;

    this.raceFormsCollection = this.afs.collection<RaceForm>('raceForms');
    this.raceFormsCollectionObservable = this.raceFormsCollection.valueChanges();
    this.raceFormsSubscription = this.raceFormsCollectionObservable.subscribe((data) => {

      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all race forms cpanel", self.moduleName, "raceForms", (data as any[]).length);
      }

      this.raceFormData = data;

      if (Functions.IsNullOrUndefined(this.selectedRaceID) === false) {
        this.onRaceChange(this.selectedRace);
      }

    });


  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.raceFormsSubscription) == false) {
      this.raceFormsSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.styleEl) === false) {
      document.head.removeChild(this.styleEl);
    }
  }

  public onRaceChange(race: any) {
    if (Functions.IsNullOrUndefined(race) == true) {
      this.selectedRaceID = undefined;
      this.selectedRace = undefined;

      this.form = {
        components: []
      };

    } else {
      this.selectedRaceID = race.uid;
      this.selectedRace = race;

      const foundedForm: RaceForm = this.raceFormData.find((aRaceForm: RaceForm) => aRaceForm.uid === this.selectedRace.name);
      if (Functions.IsNullOrUndefined(foundedForm) === false) {
        this.form = foundedForm.form || { components: [] };
      }
    }

  }

  public onChange(event: any) {
    const formValue: any = event.form;
    // if (Functions.IsNullOrUndefined(formValue) === false) {
    //   //TODO: REMOVE SUBMIT BUTTON!!!
    //   this.form = formValue;
    // }
  }

  /**
   * save changes
   */
  public ok() {
    //update on firebase
    let newRaceFormData: RaceForm = new RaceForm();
    newRaceFormData.uid = this.selectedRace.name;
    newRaceFormData.name = this.selectedRace.name;
    newRaceFormData.form = this.form;

    newRaceFormData.form.components = newRaceFormData.form.components.filter((aComponent: any) => aComponent.action != "submit");

    newRaceFormData.form.components = newRaceFormData.form.components.map((aComponent: any) => {
      if (aComponent.key === "selectBoxes") {
        aComponent.defaultValue = {};
        return aComponent;
      } else {
        return aComponent;
      }
    });

    let newRaceFormDataJSON = JSON.parse(JSON.stringify(newRaceFormData, (key, value) => {
      if (Functions.IsNullOrUndefined(value) === false && Functions.IsNullOrUndefined(key) === false)
        return value;
    }));
    this.cPanelService.writeOnDB("raceForms", newRaceFormDataJSON.uid, newRaceFormDataJSON);
  }

  /**
   * undo changes
   */
  public annulla() {
    this.selectedRaceID = undefined;
    this.selectedRace = undefined;
    this.form = {
      components: []
    };
  }

}
