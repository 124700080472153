import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserPresence, PresencePlayerStatus, CharacterSheetData, State } from '../../../../models/data/application.data';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from '../../../../selectors/character.selectors';
import { map, first } from 'rxjs/operators';
import { AngularFireList, SnapshotAction, AngularFireDatabase, AngularFireObject } from '@angular/fire/database';

@Component({
  selector: 'cPanel-sync',
  templateUrl: 'cPanel-sync.component.html',
  styleUrls: ['cPanel-sync.component.less'],
})
export class CPanelSyncComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelSync";

  //#region - select variables
  public selectedCharacterID: string;
  public selectedUserPresence: UserPresence;

  public usersPresence$: Observable<UserPresence[]>;
  private usersSubscription: Subscription;
  public characters: PresencePlayerStatus[] = [];
  //#endregion - select variables

  constructor(
    private toastr: ToastrService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService,
    private store: Store<fromRoot.State>,
    private afdb: AngularFireDatabase,
  ) {
  }

  public ngOnInit() {
    let self: this = this;

    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }))
      .subscribe((data: PresencePlayerStatus[]) => {
        // sorting alphabetically
        data = data.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        self.characters = data;
      });

  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
      this.usersSubscription.unsubscribe();
    }
  }


  public sync() {
    const self: this = this;
    const userPresenceCollection: AngularFireList<UserPresence> = this.afdb.list<UserPresence>('/status');
    const userPresenceCollectionObservable: Observable<SnapshotAction<UserPresence>[]> = userPresenceCollection.snapshotChanges();
    const userPresenceCollectionSubscription: Subscription = userPresenceCollectionObservable
      .pipe(first())
      .subscribe((data) => {
        if (Functions.IsNullOrUndefined(data) == true || data.length <= 0)
          return;

        const dataToAllign: any[] = [];
        data.forEach((a: SnapshotAction<UserPresence>) => {
          const entry: any = a.payload.val() as any;
          if (a.payload.key != entry.state.playerUID) {
            entry.connections = null;
            entry.key = a.payload.key;
            dataToAllign.push(entry);
          }
        });

        let taskCompleted: number = 0;
        for (const aUserToAlign of dataToAllign) {
          const playerToEditDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('users/' + aUserToAlign.key);
          const playerToEditDocObservable: Observable<any> = playerToEditDoc.valueChanges();
          const playerToEditDocSubscription: Subscription = playerToEditDocObservable
            .pipe(first())
            .subscribe((selectedCharacter: CharacterSheetData) => {
              // safety check
              if (Functions.IsNullOrUndefined(selectedCharacter) == true) {
                return;
              }

              if (self.debugLogger.isAuditing) {
                self.debugLogger.logRead(true, "READ a user cPanel sync", self.moduleName, "users", 1);
              }

              selectedCharacter.access = aUserToAlign.state.lastAccess;

              //#region - update presence status
              const presenceSatus: PresencePlayerStatus = new PresencePlayerStatus(selectedCharacter.uid, selectedCharacter.race, selectedCharacter.role, selectedCharacter.MAvatar, selectedCharacter.name, selectedCharacter.sur, selectedCharacter.nick, selectedCharacter.access, selectedCharacter.sex, (selectedCharacter.lvl || 1), (selectedCharacter.state || State.libero), (selectedCharacter.stateText || ""), selectedCharacter.isPng, selectedCharacter.isBanned, selectedCharacter.lastMessageSent, selectedCharacter.myPrivateChat, (selectedCharacter.clan || ""), selectedCharacter.clanLvl, (selectedCharacter.corp || ""), selectedCharacter.corpLvl, selectedCharacter.isActive, selectedCharacter.isCapoClan, selectedCharacter.isCapoCorp, selectedCharacter.isCapoRace, "");
              const presenceStatusJSON = JSON.parse(JSON.stringify(presenceSatus));

              const userPresenceDoc = self.afdb.object('status/' + selectedCharacter.uid + '/state');
              userPresenceDoc.update(presenceStatusJSON);
              //#endregion - update presence status

              taskCompleted = taskCompleted + 1;
              if (taskCompleted == dataToAllign.length) {
                this.toastr.success('Database riallineati');
              }
            });
        }
      });

  }

  /**
   * player selectio cnahged
   */
  public onUserChange(character: PresencePlayerStatus) {
    if(Functions.IsNullOrUndefined(character) === true) {
      // removing selection
      this.selectedCharacterID = undefined;
      this.selectedUserPresence = undefined;
    }

    const userPresences: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const currentUserPresence: UserPresence = userPresences.find((aUSerPresence: UserPresence) => aUSerPresence.state.playerUID == character.playerUID);
    if (Functions.IsNullOrUndefined(currentUserPresence) == true) {
      this.selectedCharacterID = undefined;
    }

    this.selectedUserPresence = currentUserPresence;
  }

  public setUpAccess() {
    // safety check
    if (
      Functions.IsNullOrUndefined(this.selectedUserPresence) == true
      || Functions.IsNullOrUndefined(this.selectedUserPresence.connections) == true
      || this.selectedUserPresence.connections.length <= 0
      || Functions.IsNullOrUndefined(this.selectedUserPresence.state) == true
    )
      return;

    //#region - update chat status
    for (const aConnection in this.selectedUserPresence.connections) {
      const currentConnectionArea: string = this.selectedUserPresence.connections[aConnection];
      if (Functions.IsStringEmpty(currentConnectionArea) == true || currentConnectionArea == "@@HOME@@")
        continue;

      const chatStatusToUpdate: AngularFireObject<any> = this.afdb.object<any>('chats/' + currentConnectionArea + '/onlineUsersID/' + this.selectedUserPresence.state.playerUID);
      chatStatusToUpdate.remove();
    }
    //#endregion - update chat status

    //#region - remove connection from user presence
    const connectionToUpdate: AngularFireObject<any> = this.afdb.object<any>('status/' + this.selectedUserPresence.state.playerUID + '/connections');
    connectionToUpdate.remove();
    //#endregion - remove connection from user presence

    this.toastr.success('Presenza utente riallineata');

  }

}
