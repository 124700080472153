import { BonusMalus, LocationRoot, Clan, Corp, Volto, RulesSezione, StandardChat, SpecialChat, DBVersionIndex } from './../models/data/application.data';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { Subscription, Observable } from 'rxjs';
import { Functions } from '../modules/utilities/functions/utilities.functions';
import * as layout from '../actions/layout';
import * as datas from '../actions/datas';
import { NgForage } from 'ngforage';
import { Skill, Item, DBVersioning, DBVersionType, LocalCache } from '../models/data/application.data';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { DebugLoggerService } from './debug-logger.service';

@Injectable()
export class CacheService {
  private moduleName: string = "cacheService";
  private onlineDBVersion: Subscription;
  private tempSubscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private ngf: NgForage,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) { }

  public logoutReset() {
    if (Functions.IsNullOrUndefined(this.tempSubscription) == false) {
      this.tempSubscription.unsubscribe();
      this.tempSubscription = new Subscription();
    }

    if (Functions.IsNullOrUndefined(this.onlineDBVersion) == false) {
      this.onlineDBVersion.unsubscribe();
      this.onlineDBVersion = undefined;
    }
  }

  /**
   * update cache and local storage with the new values downloaded
   * @param type of collection (skill, item, ecc)
   * @param newValues array of new values donwloaded
   */
  public addToCacheAndStorage(type: DBVersionType, newValues: any[], fullCacheCopy: boolean, raceSkillFullFlag: number[], raceBMFullFlag: number[]) {
    const localData: any[] = Object.assign([], this.getLocalCollection(type));
    const DBVersion: number = this.getCollectionVersion(type);

    const updatedData = localData.concat(newValues);
    this.alignStoreData(type, updatedData, DBVersion, fullCacheCopy, raceSkillFullFlag, raceBMFullFlag);
    this.setCacheData(type, DBVersion, updatedData, fullCacheCopy, raceSkillFullFlag, raceBMFullFlag);
  }

  /**
   * update cache and local storage with the new values downloaded
   * @param type of collection (skill, item, ecc)
   * @param newValues array of new values donwloaded
   */
  public overrideCacheAndStorage(type: DBVersionType, newValues: any[], fullCacheCopy: boolean, raceSkillFullFlag: number[], raceBMFullFlag: number[]) {
    const DBVersion: number = this.getCollectionVersion(type);

    //#region - handle location differently
    if (type == DBVersionType.locations) {
      const updatedValue: LocationRoot = newValues[0];
      this.alignStoreData(type, [updatedValue], DBVersion, true, raceSkillFullFlag, raceBMFullFlag);
      this.setCacheData(type, DBVersion, updatedValue, true, raceSkillFullFlag, raceBMFullFlag);
      return;
    }
    //#endregion - handle location differently

    this.alignStoreData(type, newValues, DBVersion, fullCacheCopy, raceSkillFullFlag, raceBMFullFlag);
    this.setCacheData(type, DBVersion, newValues, fullCacheCopy, raceSkillFullFlag, raceBMFullFlag);
  }

  /**
   * Load cache from rowser
   */
  public loadCachedData() {
    let self: this = this;
    //reading from local storage
    this.ngf.iterate((value: LocalCache, key: string, itNum: number): void => {
      switch (key) {
        case "skills":
          self.store.dispatch(new datas.StoreSkills({ skills: value.data as Skill[], ver: value.version, raceSkillFullFlag: value.raceSkillFullFlag }));
          break;

        case "items":
          self.store.dispatch(new datas.StoreItems({ items: value.data as Item[], ver: value.version }));
          break;

        case "bonusMalus":
          self.store.dispatch(new datas.StoreBMs({ bms: value.data as BonusMalus[], ver: value.version, raceBMFullFlag: value.raceBMFullFlag }));
          break;

        case "clans":
          self.store.dispatch(new datas.StoreClans({ clans: value.data as Clan[], ver: value.version, fullFlag: value.fullCacheCopy }));
          break;

        case "corps":
          self.store.dispatch(new datas.StoreCorps({ corps: value.data as Corp[], ver: value.version, fullFlag: value.fullCacheCopy }));
          break;

        case "volti":
          self.store.dispatch(new datas.StoreVolti({ volti: value.data as Volto[], ver: value.version, fullFlag: value.fullCacheCopy }));
          break;

        case "rules":
          self.store.dispatch(new datas.StoreRules({ rules: value.data as RulesSezione[], ver: value.version, fullFlag: value.fullCacheCopy }));
          break;

        case "locations":
          self.store.dispatch(new datas.StoreLocations({ locations: value.data as LocationRoot, ver: value.version, fullFlag: value.fullCacheCopy }));
          break;

        case "standardChat":
          self.store.dispatch(new layout.StoreChatsListDataAction({ standardChats: value.data as StandardChat[], ver: value.version, fullFlag: value.fullCacheCopy }));
          break;

        case "specialChat":
          self.store.dispatch(new layout.StoreSpecialChatsListDataAction({ specialChats: value.data as SpecialChat[], ver: value.version, fullFlag: value.fullCacheCopy }));
          break;
      }
    }).then(() => {
      //reading online db version
      this.getOnlineDBVersion();
    }).catch((err: any) => {
      console.log("Cache Read Error");
    });


  }

  public updateVoltiCache(id: string) {
    const dbVersioningIndexDoc: AngularFirestoreDocument<DBVersionIndex> = this.afs.doc('dbVersioning/dbVersionIndex');
    const dbVersioningIndexDocObservable: Observable<DBVersionIndex> = dbVersioningIndexDoc.valueChanges();
    const dbVersioningIndexDocSubscription: Subscription = dbVersioningIndexDocObservable.pipe(first())
      .subscribe((DBVersioning: DBVersionIndex) => {
        const currentDBVersioning: DBVersionIndex = Object.assign({}, DBVersioning);
        for (let index: number = 0; index < currentDBVersioning.versions.length; index++) {
          const aDBVersionArea: DBVersioning = currentDBVersioning.versions[index];

          if (aDBVersionArea.type != DBVersionType.volti)
            continue;

          aDBVersionArea.version = aDBVersionArea.version + 1;
          if (Functions.IsNullOrUndefined(aDBVersionArea.vChanges)) {
            aDBVersionArea.vChanges = {};
          }

          aDBVersionArea.vChanges[aDBVersionArea.version] = [id];
        }

        dbVersioningIndexDoc.set(currentDBVersioning)
          .then(() => {
            //OK!
          })
          .catch((error) => {
            console.log("Error cache volti version update");
          });
      });
  }

  //#region - utilities
  /**
   * get the online new db index (just one document)
   */
  private getOnlineDBVersion() {
    let self = this;
    if (Functions.IsNullOrUndefined(this.onlineDBVersion) == true) {
      const DBVersionsDoc: AngularFirestoreDocument<DBVersionIndex> = this.afs.doc<DBVersionIndex>('dbVersioning/dbVersionIndex');
      const DBVersionsDocObservable: Observable<DBVersionIndex> = DBVersionsDoc.valueChanges();
      this.onlineDBVersion = DBVersionsDocObservable.subscribe((dbV: DBVersionIndex) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ online DB version", self.moduleName, "dbVersioning/dbVersionIndex", 1);
        }

        if (Functions.IsNullOrUndefined(dbV) == false && Functions.IsNullOrUndefined(dbV.versions) == false && dbV.versions.length > 0) {
          // compute singular DB version type
          for (let index: number = 0; index < dbV.versions.length; index++) {
            self.handleDBVersion(dbV.versions[index]);
          }
        }
      });
    }
  }

  /**
   * compute new version decide how to move on
   * @param dbV the new db collection version retrived online
   */
  private handleDBVersion(dbV: DBVersioning) {
    let self: this = this;
    let currentVerion: number = -1;
    let forceDownload: boolean = false;

    //#region - get Version To Compare
    switch (dbV.type) {
      case DBVersionType.skills:
        currentVerion = fromRoot.getState(this.store).datas.skillsV;
        forceDownload = true;
        break;
      case DBVersionType.items:
        currentVerion = fromRoot.getState(this.store).datas.itemsV;
        break;
      case DBVersionType.bm:
        currentVerion = fromRoot.getState(this.store).datas.bmsV;
        forceDownload = true;
        break;
      case DBVersionType.clans:
        currentVerion = fromRoot.getState(this.store).datas.clansV;
        forceDownload = fromRoot.getState(this.store).datas.clanFullCache;
        break;
      case DBVersionType.corps:
        currentVerion = fromRoot.getState(this.store).datas.corpsV;
        forceDownload = fromRoot.getState(this.store).datas.corpFullCache;
        break;
      case DBVersionType.volti:
        currentVerion = fromRoot.getState(this.store).datas.voltiV;
        forceDownload = fromRoot.getState(this.store).datas.voltiFullCache;
        break;
      case DBVersionType.rules:
        currentVerion = fromRoot.getState(this.store).datas.rulesV;
        forceDownload = fromRoot.getState(this.store).datas.rulesFullCache;
        break;
      case DBVersionType.locations:
        currentVerion = fromRoot.getState(this.store).datas.locationsV;
        forceDownload = fromRoot.getState(this.store).datas.locationsFullCache;
        break;
      case DBVersionType.standardChats:
        currentVerion = fromRoot.getState(this.store).layout.standardChatsV;
        forceDownload = fromRoot.getState(this.store).layout.stdChatsFullCache;
        break;
      case DBVersionType.specialChats:
        currentVerion = fromRoot.getState(this.store).layout.specialChatsV;
        forceDownload = fromRoot.getState(this.store).layout.spcChatsFullCache;
        break;
    }
    //#endregion - get Version To Compare

    // rid off all the cached datas
    if (dbV.version == 0 || currentVerion > dbV.version) {
      self.alignStoreData(dbV.type, [], dbV.version, false, [], [])
      self.setCacheData(dbV.type, dbV.version, [], false, [], []);
    }

    // rid off only the updated values
    if (currentVerion < dbV.version) {
      self.computeDeltaChanges(dbV.type, currentVerion, dbV, forceDownload);
    }
  }

  /**
   * compute deltas
   * @param type cache type
   * @param localVersion the local not updated collection version
   * @param onlineVersion the online updated collection version
   */
  private computeDeltaChanges(type: DBVersionType, localVersion: number, onlineVersion: DBVersioning, forceDownload: boolean) {
    const versionDiff: number = onlineVersion.version - localVersion;

    //#region - pending changes computation
    const setOfChanges: Set<string> = new Set();
    let arrayOfChanges: string[] = [];
    let needToRidOffEverything: boolean = false;

    // compute all differences based on version disallignement
    for (let index: number = localVersion + 1; index <= localVersion + versionDiff; index++) {
      let value: string[] = onlineVersion.vChanges[index];
      if (Functions.IsNullOrUndefined(value) == false && value.length > 0)
        arrayOfChanges = arrayOfChanges.concat(value);
      else if (Functions.IsNullOrUndefined(value) || value.length <= 0)
        needToRidOffEverything = true;
    }
    //#endregion - pending changes computation


    if (needToRidOffEverything == false) {
      //#region - download updated versions
      for (let index: number = 0; index < arrayOfChanges.length; index++) {
        setOfChanges.add(arrayOfChanges[index]);
      }

      const updatedValuesMap: Map<string, any> = new Map();
      const valueToRemove: Set<string> = new Set();
      const currentValues: Set<string> = this.getCollectiondIDs(type);

      let fromSetToArray: string[] = Array.from(setOfChanges);
      for (let index: number = 0; index < fromSetToArray.length; index++) {
        const aDeltaKey: string = fromSetToArray[index];
        const lastLoop: boolean = index == (fromSetToArray.length - 1);

        if (currentValues.has(aDeltaKey) == false && forceDownload == false) {
          // not yet in local data so do noting
          if (lastLoop == true) {
            this.mergeDBChanges(onlineVersion, type, updatedValuesMap, valueToRemove);
          }
        } else {
          // in local data so do something
          this.getElementUpdatedVerions(onlineVersion, type, aDeltaKey, updatedValuesMap, lastLoop, valueToRemove);
        }
      }
      //#endregion
    } else {
      //#region - rid off everything
      this.alignStoreData(type, [], onlineVersion.version, false, [], []);
      this.setCacheData(type, onlineVersion.version, [], false, [], []);
      //#endregion
    }
  }

  /**
   * merged deltas with old local values
   * @param onlineVersion the online updated collection version
   * @param type cache type
   * @param updatedValuesMap the map with new values
   */
  private mergeDBChanges(onlineVersion: DBVersioning, type: DBVersionType, updatedValuesMap: Map<string, any>, valueToRemove: Set<string>) {
    //#region - handle location differently
    if (type == DBVersionType.locations) {
      let locations: LocationRoot = Object.assign({}, fromRoot.getState(this.store).datas.locations);
      const updatedValue: LocationRoot = updatedValuesMap.get(locations.uid);
      // updated store and cache
      this.alignStoreData(type, [updatedValue], onlineVersion.version, true, [], []);
      this.setCacheData(type, onlineVersion.version, [updatedValue], true, [], []);
      return;
    }
    //#endregion - handle location differently

    let localData: any[] = this.getLocalCollection(type);
    let updatedData: any[] = [];
    //#region - update local data
    for (let index: number = 0; index < localData.length; index++) {
      if (valueToRemove.has(localData[index].uid)) {
        // need to be deleted because no more available
        continue;
      }

      const getUpdatedValue: any = updatedValuesMap.get(localData[index].uid);
      if (Functions.IsNullOrUndefined(getUpdatedValue) == true) {
        updatedData.push(localData[index]);
      } else {
        updatedData.push(getUpdatedValue);
        updatedValuesMap.delete(localData[index].uid);
      }
    }

    //#region - check if there are forced values to be added
    // if forced new valus aren't inside the local array so those are going to be ignored
    if (updatedValuesMap.size > 0) {
      updatedValuesMap.forEach((element: any, uid: string) => {
        updatedData.push(element);
      });
    }
    //#endregion - check if there are forced values to be added

    // updated store and cache
    const fullCache: boolean = this.getCollectionCacheFullnes(type);
    let raceSkillFullFlag: number[] = [];
    let raceBMFullFlag: number[] = [];
    // check for buyable sill flag
    if (type == DBVersionType.skills) {
      raceSkillFullFlag = fromRoot.getState(this.store).datas.raceSkillFullFlag;
    }

    if (type == DBVersionType.bm) {
      raceBMFullFlag = fromRoot.getState(this.store).datas.raceBMFullFlag;
    }

    this.alignStoreData(type, updatedData, onlineVersion.version, fullCache, raceSkillFullFlag, raceBMFullFlag);
    this.setCacheData(type, onlineVersion.version, updatedData, fullCache, raceSkillFullFlag, raceBMFullFlag);
    //#endregion - update local data
  }

  /**
   * utility function - Set new cache in browser
   * @param type type (skill, clan, volti ecc)
   * @param ver new version
   * @param data the data to be setted
   */
  private setCacheData(type: DBVersionType, ver: number, data: any, fullCacheCopy: boolean, raceSkillFullFlag: number[], raceBMFullFlag: number[]) {

    if (type == DBVersionType.locations)
      data = data[0];

    let dataToSave: LocalCache = new LocalCache(ver, type, data, fullCacheCopy);
    if (type == DBVersionType.skills) {
      dataToSave.raceSkillFullFlag = raceSkillFullFlag;
    }

    if (type == DBVersionType.bm) {
      dataToSave.raceBMFullFlag = raceBMFullFlag;
    }

    let self: this = this;
    let indexID: string = this.getCacheIndexString(type);

    //safety check
    if (Functions.IsStringEmpty(indexID) == true)
      return;

    this.ngf.removeItem(indexID)
      .then(() =>
        self.ngf.setItem(indexID, dataToSave)
          .then(() => { })
          .catch((err: any) => {
            console.log("Cache set error");
          })
      ).catch((err: any) => {
        console.log("Cache remove error");
      });
  }

  /**
   * utility function - detele a cached collection
   * @param type
   */
  private deleteCacheCollection(type: DBVersionType) {
    let indexID: string = this.getCacheIndexString(type);

    //safety check
    if (Functions.IsStringEmpty(indexID) == true)
      return;

    this.ngf.removeItem(indexID)
      .then(() => { })
      .catch((err: any) => {
        console.log("Cache remove error");
      });
  }

  /**
   * utility function - return cache collection index string based on cache type
   * @param type cache type
   * @return string - the cache collection index string
   */
  public getCacheIndexString(type: DBVersionType): string {
    switch (type) {
      case DBVersionType.skills:
        return "skills";
      case DBVersionType.items:
        return "items";
      case DBVersionType.bm:
        return "bonusMalus";
      case DBVersionType.clans:
        return "clans";
      case DBVersionType.corps:
        return "corps";
      case DBVersionType.volti:
        return "volti";
      case DBVersionType.rules:
        return "rules";
      case DBVersionType.locations:
        return "locations";
      case DBVersionType.standardChats:
        return "standardChat";
      case DBVersionType.specialChats:
        return "specialChat";
      default:
        return "";
    }
  }

  /**
   * utility function - update store accordingly with cache engine
   * @param type cache type
   * @param data new data to store
   * @param version new version
   */
  private alignStoreData(type: DBVersionType, data: any[], version: number, fullCacheCopy: boolean, raceSkillFullFlag: number[], raceBMFullFlag: number[]) {
    switch (type) {
      case DBVersionType.skills:
        this.store.dispatch(new datas.StoreSkills({ skills: data, ver: version, raceSkillFullFlag: raceSkillFullFlag }));
        break;
      case DBVersionType.items:
        this.store.dispatch(new datas.StoreItems({ items: data, ver: version }));
        break;
      case DBVersionType.bm:
        this.store.dispatch(new datas.StoreBMs({ bms: data, ver: version, raceBMFullFlag: raceBMFullFlag }));
        break;
      case DBVersionType.clans:
        this.store.dispatch(new datas.StoreClans({ clans: data, ver: version, fullFlag: fullCacheCopy }));
        break;
      case DBVersionType.corps:
        this.store.dispatch(new datas.StoreCorps({ corps: data, ver: version, fullFlag: fullCacheCopy }));
        break;
      case DBVersionType.volti:
        this.store.dispatch(new datas.StoreVolti({ volti: data, ver: version, fullFlag: fullCacheCopy }));
        break;
      case DBVersionType.rules:
        this.store.dispatch(new datas.StoreRules({ rules: data, ver: version, fullFlag: fullCacheCopy }));
        break;
      case DBVersionType.locations:
        const normalizedLocations: any = data[0];
        this.store.dispatch(new datas.StoreLocations({ locations: normalizedLocations, ver: version, fullFlag: fullCacheCopy }));
        break;
      case DBVersionType.standardChats:
        this.store.dispatch(new layout.StoreChatsListDataAction({ standardChats: data, ver: version, fullFlag: fullCacheCopy }));
        break;
      case DBVersionType.specialChats:
        this.store.dispatch(new layout.StoreSpecialChatsListDataAction({ specialChats: data, ver: version, fullFlag: fullCacheCopy }));
        break;
      default:
        console.log("Error allign store and cache");
        break;
    }
  }

  /**
   * utility function - compute a set of current collection ids
   * @param type cache type
   * @return a set of string of all the id currently on the clien
   */
  public getCollectiondIDs(type: DBVersionType): Set<string> {
    let data: any[] = [];
    let IDs: string[] = [];

    //TODO: IDS of undefined

    switch (type) {
      case DBVersionType.skills:
        data = fromRoot.getState(this.store).datas.skills;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.items:
        data = fromRoot.getState(this.store).datas.items;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.bm:
        data = fromRoot.getState(this.store).datas.bms;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.clans:
        data = fromRoot.getState(this.store).datas.clansList;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.corps:
        data = fromRoot.getState(this.store).datas.corpsList;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.volti:
        data = fromRoot.getState(this.store).datas.voltiList;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.rules:
        data = fromRoot.getState(this.store).datas.rulesList;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.locations:
        const locations: LocationRoot = fromRoot.getState(this.store).datas.locations;
        if (Functions.IsNullOrUndefined(locations) == false) {
          return new Set(locations.uid);
        } else {
          return new Set();
        }
      case DBVersionType.standardChats:
        data = fromRoot.getState(this.store).layout.standardChatsList;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      case DBVersionType.specialChats:
        data = fromRoot.getState(this.store).layout.specialChatsList;
        IDs = data.map((aData: any) => aData.uid);
        return new Set(IDs);
      default:
        return new Set();
    }
  }

  /**
   * utility function - retrive the updated value online
   * @param onlineVersion the DBVersioning item
   * @param type type (skill, clan, volti ecc)
   * @param id as strng of the item to update
   * @param updatedValuesMap the map with all new updated values
   * @param true if this is the last value to be updated
   */
  private getElementUpdatedVerions(onlineVersion: DBVersioning, type: DBVersionType, id: string, updatedValuesMap: Map<string, any>, isLastLoop: boolean, valueToRemove: Set<string>) {
    const self: this = this;
    const collectionID: string = this.getCacheIndexString(type);

    const aDoc: AngularFirestoreDocument<any> = this.afs.doc<any>(collectionID + '/' + id);
    const aDocObservable: Observable<any> = aDoc.valueChanges();
    const sub: Subscription = aDocObservable.pipe(first()).subscribe((updatedDoc: any) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(false, "READ a " + DBVersionType[type] + " for cache update)", self.moduleName, DBVersionType[type], 1);
      }

      if (Functions.IsNullOrUndefined(updatedDoc) == false) {
        // if undefined the element has been deleted
        updatedValuesMap.set(updatedDoc.uid, updatedDoc);
        if (isLastLoop == true) {
          // this is the last subscription
          self.mergeDBChanges(onlineVersion, type, updatedValuesMap, valueToRemove);
        }
      } else {
        valueToRemove.add(id);
        if (isLastLoop == true) {
          // this is the last subscription
          self.mergeDBChanges(onlineVersion, type, updatedValuesMap, valueToRemove);
        }
      }
    });
  }

  /**
   * return the current collection as array
   * @param type type (skill, clan, volti ecc)
   */
  private getLocalCollection(type: DBVersionType): any[] {
    let localData: any[] = [];
    switch (type) {
      case DBVersionType.skills:
        localData = Object.assign([], fromRoot.getState(this.store).datas.skills);
        break;

      case DBVersionType.items:
        localData = Object.assign([], fromRoot.getState(this.store).datas.items);
        break;

      case DBVersionType.bm:
        localData = Object.assign([], fromRoot.getState(this.store).datas.bms);
        break;

      case DBVersionType.clans:
        localData = Object.assign([], fromRoot.getState(this.store).datas.clansList);
        break;

      case DBVersionType.corps:
        localData = Object.assign([], fromRoot.getState(this.store).datas.corpsList);
        break;

      case DBVersionType.volti:
        localData = Object.assign([], fromRoot.getState(this.store).datas.voltiList);
        break;

      case DBVersionType.rules:
        localData = Object.assign([], fromRoot.getState(this.store).datas.rulesList);
        break;

      case DBVersionType.standardChats:
        localData = Object.assign([], fromRoot.getState(this.store).layout.standardChatsList);
        break;

      case DBVersionType.specialChats:
        localData = Object.assign([], fromRoot.getState(this.store).layout.specialChatsList);
        break;
    }

    return localData;
  }

  /**
 * return the current collection version
 * @param type type (skill, clan, volti ecc)
 */
  private getCollectionVersion(type: DBVersionType): number {
    let version: number = -1;
    switch (type) {
      case DBVersionType.skills:
        version = fromRoot.getState(this.store).datas.skillsV;
        break;

      case DBVersionType.items:
        version = fromRoot.getState(this.store).datas.itemsV;
        break;

      case DBVersionType.bm:
        version = fromRoot.getState(this.store).datas.bmsV;
        break;

      case DBVersionType.clans:
        version = fromRoot.getState(this.store).datas.clansV;
        break;

      case DBVersionType.corps:
        version = fromRoot.getState(this.store).datas.corpsV;
        break;

      case DBVersionType.volti:
        version = fromRoot.getState(this.store).datas.voltiV;
        break;

      case DBVersionType.rules:
        version = fromRoot.getState(this.store).datas.rulesV;
        break;

      case DBVersionType.standardChats:
        version = fromRoot.getState(this.store).layout.standardChatsV;
        break;

      case DBVersionType.specialChats:
        version = fromRoot.getState(this.store).layout.specialChatsV;
        break;
    }

    return version;
  }

  /**
   * return if the current collection as cache is full copy or not
   * @param type type (skill, clan, volti ecc)
   */
  public getCollectionCacheFullnes(type: DBVersionType): boolean {
    switch (type) {
      case DBVersionType.clans:
        return fromRoot.getState(this.store).datas.clanFullCache;

      case DBVersionType.corps:
        return fromRoot.getState(this.store).datas.corpFullCache;

      case DBVersionType.volti:
        return fromRoot.getState(this.store).datas.voltiFullCache;

      case DBVersionType.rules:
        return fromRoot.getState(this.store).datas.rulesFullCache;

      case DBVersionType.standardChats:
        return fromRoot.getState(this.store).layout.stdChatsFullCache;

      case DBVersionType.specialChats:
        return fromRoot.getState(this.store).layout.spcChatsFullCache;

      default:
        return false;
    }
  }

  //#endregion - utilities

}
