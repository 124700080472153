import { CharacterSheetData } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { ChatMessage, OnlineUser, ChatSelectStatsUI, CharacterState, DiceType, DiceDmgData } from '../../models/chat-message.models';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as chatSelector from './../../../../selectors/chat.selectors';
import * as layoutSelector from './../../../../selectors/layout.selectors';
import * as layout from './../../../../actions/layout';
import * as chat from './../../../../actions/chat';
import { Functions } from '../../../utilities/functions/utilities.functions';
import { map } from 'rxjs/operators';


@Component({
  selector: 'chat-dice-response',
  templateUrl: './chat-dice-response.component.html',
  styleUrls: ['./chat-dice-response.component.css']
})
export class ChatDiceResponseComponent implements OnInit, OnDestroy {

  getChatOnlineUsers$: Observable<OnlineUser[]>;
  getChatCharactersState$: Observable<ChatSelectStatsUI[]>;

  isReagisciSelected: boolean = true;
  public currentActionToBeRespond: ChatMessage;
  public actionsToBeRespond$: Observable<ChatMessage[]>;
  private actionsToBeRespondSubscription: Subscription;

  public reStats: ChatSelectStatsUI;
  public reError: boolean = false;

  public dmgStats: ChatSelectStatsUI;
  public dmgError: boolean = false;

  public isConfirmBoxVisible: boolean = false;

  constructor(private chat: ChatService, private store: Store<fromRoot.State>) { }

  ngOnInit() {
    const self: this = this;

    this.actionsToBeRespond$ = this.store.select(chatSelector.getChatPendingattacks);

    this.actionsToBeRespondSubscription = this.actionsToBeRespond$.subscribe((allPendingActions: ChatMessage[]) => {
      if (Functions.IsNullOrUndefined(allPendingActions) == false && allPendingActions.length > 0)
        self.currentActionToBeRespond = allPendingActions[0];
      // return allPendingActions;
    });

    this.getChatCharactersState$ = this.store.select(chatSelector.getChatCharactersState).pipe(map((states: CharacterState[]) => {
      const toReturn: ChatSelectStatsUI[] = [];
      const characterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
      let myState: CharacterState = undefined;
      if (states.length > 0) {
        myState = states.find((aState: any) => aState.uid == characterSheet.uid);
      }

      if (Functions.IsNullOrUndefined(myState)) {
        toReturn.push(new ChatSelectStatsUI("str", "Forza (" + characterSheet.stats.str + ")", characterSheet.stats.str));
        toReturn.push(new ChatSelectStatsUI("agl", "Agilità (" + characterSheet.stats.agl + ")", characterSheet.stats.agl));
        toReturn.push(new ChatSelectStatsUI("res", "Resistenza (" + characterSheet.stats.res + ")", characterSheet.stats.res));
        toReturn.push(new ChatSelectStatsUI("wsd", "Saggezza (" + characterSheet.stats.wsd + ")", characterSheet.stats.wsd));
        toReturn.push(new ChatSelectStatsUI("per", "Percezione (" + characterSheet.stats.per + ")", characterSheet.stats.per));
        toReturn.push(new ChatSelectStatsUI("wil", "Volontà (" + characterSheet.stats.wil + ")", characterSheet.stats.wil));
      } else {
        const str: number = characterSheet.stats.str + myState.skillsBonusStats.str + myState.itemsBonusStats.str;
        toReturn.push(new ChatSelectStatsUI("str", "Forza (" + str + ")", str));
        const agl: number = characterSheet.stats.agl + myState.skillsBonusStats.agl + myState.itemsBonusStats.agl;
        toReturn.push(new ChatSelectStatsUI("agl", "Agilità (" + agl + ")", agl));
        const res: number = characterSheet.stats.res + myState.skillsBonusStats.res + myState.itemsBonusStats.res;
        toReturn.push(new ChatSelectStatsUI("res", "Resistenza (" + res + ")", res));
        const wsd: number = characterSheet.stats.wsd + myState.skillsBonusStats.wsd + myState.itemsBonusStats.wsd;
        toReturn.push(new ChatSelectStatsUI("wsd", "Saggezza (" + wsd + ")", wsd));
        const per: number = characterSheet.stats.per + myState.skillsBonusStats.per + myState.itemsBonusStats.per;
        toReturn.push(new ChatSelectStatsUI("per", "Percezione (" + per + ")", per));
        const wil: number = characterSheet.stats.wil + myState.skillsBonusStats.wil + myState.itemsBonusStats.wil;
        toReturn.push(new ChatSelectStatsUI("wil", "Volontà (" + wil + ")", wil));
      }

      return toReturn;
    }));
  }

  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.actionsToBeRespondSubscription) == false)
      this.actionsToBeRespondSubscription.unsubscribe();
  }

  confirmReDice() {

    if (Functions.IsNullOrUndefined(this.reStats)) {
      this.reError = true;
      return;
    }

    this.reError = false;
    this.dmgStats = undefined;
    this.dmgError = false;

    this.isConfirmBoxVisible = true;
  }

  confirmDmgDice() {

    if (Functions.IsNullOrUndefined(this.dmgStats)) {
      this.dmgError = true;
      return;
    }

    this.dmgError = false;
    this.reStats = undefined;
    this.reError = false;

    this.isConfirmBoxVisible = true;
  }

  rollDice() {
    if (this.isReagisciSelected) {
      this.chat.rollDice(this.reStats.value, DiceType.response, this.reStats.label, "", undefined, new DiceDmgData(this.currentActionToBeRespond.calcDamage, this.currentActionToBeRespond.damageType, this.currentActionToBeRespond.dR, this.currentActionToBeRespond.dRM));
    } else {
      this.chat.rollDice(this.dmgStats.value, DiceType.calcDmg, this.dmgStats.label, "", undefined, new DiceDmgData(this.currentActionToBeRespond.calcDamage, this.currentActionToBeRespond.damageType, this.currentActionToBeRespond.dR, this.currentActionToBeRespond.dRM));
    }

    this.isConfirmBoxVisible = false;
    // this.closeBox();
  }

  closeConfirmBox() {
    this.isConfirmBoxVisible = false;
  }

  closeBox() {
    this.store.dispatch(new layout.ToggleChatDiceResponseBoxAction(false));
    this.store.dispatch(new chat.PopChatAttacks());
  }

  setReagisci() {
    this.isReagisciSelected = true;
  }

  setCalcolaDanni() {
    this.isReagisciSelected = false;
  }
}
