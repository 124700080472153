import { Component, EventEmitter, Output, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth"
import { Functions } from 'src/app/modules/utilities/functions/utilities.functions';

@Component({
  selector: 'recupero-password-componet',
  templateUrl: './recupero-password.component.html',
  styleUrls: ['./recupero-password.component.less']
})
export class RecuperoPasswordComponent implements AfterViewInit {

  @ViewChild('recContainer', { static: false }) private recContainer: ElementRef;
  @Output() emitClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public recContainerMinHeight: number = 0;
  public recContainerWidth: number = 0;
  public emailValue: string = "";

  public emailRecoverSent: boolean = false;

  constructor(
    private afAuth: AngularFireAuth,
    private cdr: ChangeDetectorRef
  ) {

  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeEsterna() {
    this.emitClose.emit(true);
  }

  public proceed() {
    if (Functions.IsStringEmpty(this.emailValue) == true)
      return;

    const self: this = this;
    this.afAuth.auth.sendPasswordResetEmail(this.emailValue)
      .then(data => {
        self.emailRecoverSent = true;
      }).catch(error => {
        console.log("failed reset password" + error);
        self.backToHomeEsterna();
      });
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.recContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 400)
      minHeight = 400;

    if (minHeight > 611)
      minHeight = 611;

    let width = minHeight * 0.931;
    if (width > 569)
      width = 569;

    this.recContainerMinHeight = minHeight;
    this.recContainerWidth = width;
    this.cdr.detectChanges();
  }

}
