//NEW

export enum LayoutState {
  HomeEsterna = 0,
  Login = 1,
  Signup = 2,
  Maps = 3,
  CPanel = 4,
  Chat = 5,
  loginError = 6,
  Logout = 7
}

export enum CPanelState {
  CPanelHome = 0,
  LogChat = 1,
  LogMessaggi = 2,
  ModificaCredits = 3,
  ModificaExp = 4,
  ModificaMeteo = 5,
  GestioneStaffNews = 6,
  CaricheStaff = 7,
  EventiCalendario = 8,
  ModificaDisclaimerSignup = 9,
  GestioneBacheca = 10, //REMOVED
  GestioneRegolamento = 11,
  GestioneChat = 12,
  CreaPersonaggio = 13,
  ModificaPersonaggio = 14,
  GestioneVoltoPersonaggioMaschile = 15,
  GestioneVoltoPersonaggioFemminile = 16,
  EsiliaRiammettiPersonaggio = 17,
  EliminaPersonaggio = 18,
  GestisciOggetti = 19,
  AssegnaOggetto = 20,
  GestisciSkill = 21,
  AssegnaSkill = 22,
  GestisciBM = 23,
  AssegnaBM = 24,
  GestisciClan = 25,
  AssegnaClan = 26,
  GestisciCorp = 27,
  AssegnaCorp = 28,
  AssegnaRazza = 29,

  AggiungiRimuoviMoney = 94,
  logPX = 95,
  logOgg = 96,
  logBank = 97,
  Startingmsg = 98,
  Citazioni = 99,
  ComandiAvanzati = 100,
  Location = 101,
  GestioneChatSpeciali = 102,
  IstruzioniChat = 103,
  DebugLog = 104,
  JobMoneyManager = 105,
  TriggerManager = 106,
  SyncManager = 107,
  GestisciIconeRazza = 108,
  BanIP = 109,
  GestisciSignUpForm = 110
}

export enum MapState {
  mainMap = 0,
  westLA = 1,
  eastLA = 2
}

export enum CharacterSheetUIState {
  characterSheet = 0,
  inventory = 1,
  bonusAndMalus = 2,
  skilks = 3,
  edit = 4
}
