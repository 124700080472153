import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as dataSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import { AuthenticationService } from '../../../services/authentication.service';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { Citazione } from '../../../models/data/application.data';
import { Random, MersenneTwister19937 } from "random-js";
import { Functions } from '../../../modules/utilities/functions/utilities.functions';

@Component({
  selector: 'logout-component',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.less']
})

export class LogoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly moduleName: string = "LogoutComponent";
  random: Random;

  logoutMaxWidth: number = 0;
  logoutHeight: number = 0;
  logoutPaddingTop: number = 0;
  @ViewChild('logout', { static: false }) private logoutElem: ElementRef;

  citazione$: Observable<Citazione[]>;
  citazioniSubscription: Subscription;

  selectedCitazione: Citazione = undefined;

  constructor(private store: Store<fromRoot.State>,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private firestoreService: FirestoreService
  ) {
    this.random = new Random(MersenneTwister19937.autoSeed());
  }

  public ngOnInit() {
    const self: this = this;
    this.citazione$ = this.store.select(dataSelector.getCitazioni);


    this.citazioniSubscription = this.citazione$.subscribe((citazioni: Citazione[]) => {
      if (Functions.IsNullOrUndefined(citazioni) || citazioni.length <= 0)
        return undefined;

      if (Functions.IsNullOrUndefined(self.selectedCitazione)) {
        const randomNumber: number = self.random.integer(0, (citazioni.length - 1));
        self.selectedCitazione = citazioni[randomNumber];
      }
    });

    this.firestoreService.getCitazioni();
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.citazioniSubscription) == false) {
      this.citazioniSubscription.unsubscribe();
    }
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public goToHomeEsterna() {
    window.location.href = window.location.href;
    // location.reload(false);
    // this.store.dispatch(new layout.GoToHomeEsternaAction());
  }

  // iscriviti() {
  //   this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Signup));
  // }

  // effettuaLogin() {
  //   this.authenticationService.loginOrProceed();
  //   // this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Login));
  // }

  // toggleMenuEsterno() {
  //   this.regolamentoEsternoOpen = !this.regolamentoEsternoOpen;
  // }

  // test(text: string) {
  //   console.log(text);
  //   alert(text);
  // }

  public onResize(event?: any) {
    let windowsHeight = window.innerHeight;
    let windowsWidth = window.innerWidth;

    // let height: number = this.mainMapContainer.nativeElement.offsetHeight;
    let height: number = (windowsWidth / 100) * 60;
    // let width: number = this.mainMapContainer.nativeElement.offsetWidth;
    let parentHeight: number = this.logoutElem.nativeElement.parentElement.offsetHeight;
    let parentPadding = (parentHeight / 100) * 14;
    parentHeight = parentHeight - parentPadding;

    if (height > parentHeight)
      height = parentHeight;

    if (height > 500)
      height = 500;

    if (height < 200)
      height = 200;

    let maxWidth = height * 1.6;
    if (maxWidth > 800)
      maxWidth = 800;

    this.logoutMaxWidth = maxWidth;
    this.logoutHeight = height;
    this.logoutPaddingTop = parentPadding;
    this.cdr.detectChanges();
  }

}
