import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserPresence, PresencePlayerStatus, LocationRoot, PrivateConversation } from '../../../../models/data/application.data';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { AngularFireList, AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { FirestoreBackup, FirebaseBackup } from 'src/app/models/data/backup.data';
import { ChatData } from 'src/app/modules/chatRoom/models/chat-message.models';
import { BackupFileService } from '../../services/backup-file.service';
import * as layout from './../../../../actions/layout';
import * as firebase from 'firebase/app';

@Component({
  selector: 'cPanel-restore-bck',
  templateUrl: 'cPanel-restore-bck.component.html',
  styleUrls: ['cPanel-restore-bck.component.less'],
})
export class CPanelRestoreBckComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelSync";

  private restoreCompleted: Set<string> = new Set();
  private restoreFBCompleted: Set<string> = new Set();
  public wait: boolean = false;

  constructor(
    private cPanelService: CPanelService,
    private toastr: ToastrService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService,
    private store: Store<fromRoot.State>,
    private afdb: AngularFireDatabase,
    private backupFileService: BackupFileService
  ) {
  }

  public ngOnInit() {

  }

  public ngOnDestroy() {

  }

  goToLand() {
    if (this.cPanelService.waiting == true)
      return;

    if (fromRoot.getState(this.store).datas.pendingCPUpdates) {
      this.cPanelService.updateDBVersion();
      this.store.dispatch(new layout.GoToHomeInternaAction());
    } else {
      this.store.dispatch(new layout.GoToHomeInternaAction());
    }
  }

  public restoreDB1() {
    if (window.confirm("Sei sicuro di voler ripristinare il database 1? Tutti i dati verrano sovrascritti, l'operazione non si può interrompere nè abortire.")) {
      // keep going
    } else {
      return;
    }


    // safety check
    const authData: firebase.auth.UserCredential = fromRoot.getState(this.store).character.myAuthenticationData;
    if (authData.user.email != "fitzroy013@gmail.com" && authData.user.email != "reginadellacorte@outlook.it" && authData.user.email != "reddomino92@gmail.com") {
      return;
    }

    //already restoring
    if (this.wait == true) {
      return;
    }

    const self: this = this;
    let dirty: boolean = false;
    this.wait = true;
    this.backupFileService.getBackupFile("bck%2Ffs.json").subscribe((reponse: FirestoreBackup) => {
      // safety check
      if (Functions.IsNullOrUndefined(Response) == true || dirty == true)
        return;

      dirty = true;
      self.restoreDB1Procede(reponse);
    })
  }

  private async restoreDB1Procede(firestoreBackup: FirestoreBackup) {
    this.restoreCompleted = new Set();

    //#region - backup locations
    if (Functions.IsNullOrUndefined(firestoreBackup.locations) == false && Functions.IsStringEmpty(firestoreBackup.locations.uid) == false) {
      await this.clearEntireCollectionBeforeRestore('locations');

      const locationDoc: AngularFirestoreDocument<LocationRoot> = this.afs.doc<any>('locations/' + firestoreBackup.locations.uid);
      locationDoc.set(firestoreBackup.locations);
      this.restoreCompleted.add("locations");
    }
    //#endregion - backup locations

    //#region - backup rules
    if (Functions.IsNullOrUndefined(firestoreBackup.rules) == false && firestoreBackup.rules.length > 0) {
      await this.clearEntireCollectionBeforeRestore('rules');
      this.restoreCollection('rules', firestoreBackup.rules);
    }
    //#endregion - backup rules

    //#region - backup volti
    if (Functions.IsNullOrUndefined(firestoreBackup.volti) == false && firestoreBackup.volti.length > 0) {
      await this.clearEntireCollectionBeforeRestore('volti');
      this.restoreCollection('volti', firestoreBackup.volti);
    }
    //#endregion - backup volti

    //#region - backup settings
    if (Functions.IsNullOrUndefined(firestoreBackup.settings) == false && firestoreBackup.settings.length > 0) {
      await this.clearEntireCollectionBeforeRestore('settings');
      this.restoreCollectionWithKey('settings', firestoreBackup.settings);
    }
    //#endregion - backup settings

    //#region - backup character sheets
    if (Functions.IsNullOrUndefined(firestoreBackup.users) == false && firestoreBackup.users.length > 0) {
      await this.clearEntireCollectionBeforeRestore('users');
      this.restoreCollection('users', firestoreBackup.users);
    }
    //#endregion - backup character sheets

    //#region - backup clans
    if (Functions.IsNullOrUndefined(firestoreBackup.clans) == false && firestoreBackup.clans.length > 0) {
      await this.clearEntireCollectionBeforeRestore('clans');
      this.restoreCollection('clans', firestoreBackup.clans);
    }
    //#endregion - backup clans

    //#region - backup corps
    if (Functions.IsNullOrUndefined(firestoreBackup.corps) == false && firestoreBackup.corps.length > 0) {
      await this.clearEntireCollectionBeforeRestore('corps');
      this.restoreCollection('corps', firestoreBackup.corps);
    }
    //#endregion - backup corps

    //#region - backup listOfUserNames
    if (Functions.IsNullOrUndefined(firestoreBackup.listOfUserNames) == false && firestoreBackup.listOfUserNames.length > 0) {
      await this.clearEntireCollectionBeforeRestore('listOfUserNames');
      this.restoreListUserNameCollection('listOfUserNames', firestoreBackup.listOfUserNames);
    }
    //#endregion - backup listOfUserNames

    //#region - backup bonusMalus
    if (Functions.IsNullOrUndefined(firestoreBackup.bonusMalus) == false && firestoreBackup.bonusMalus.length > 0) {
      await this.clearEntireCollectionBeforeRestore('bonusMalus');
      this.restoreCollection('bonusMalus', firestoreBackup.bonusMalus);
    }
    //#endregion - backup bonusMalus

    //#region - backup skills
    if (Functions.IsNullOrUndefined(firestoreBackup.skills) == false && firestoreBackup.skills.length > 0) {
      await this.clearEntireCollectionBeforeRestore('skills');
      this.restoreCollection('skills', firestoreBackup.skills);
    }
    //#endregion - backup skills

    //#region - backup items
    if (Functions.IsNullOrUndefined(firestoreBackup.items) == false && firestoreBackup.items.length > 0) {
      await this.clearEntireCollectionBeforeRestore('items');
      this.restoreCollection('items', firestoreBackup.items);
    }
    //#endregion - backup items

    //#region - backup haveNewMessages
    if (Functions.IsNullOrUndefined(firestoreBackup.haveNewMessages) == false && firestoreBackup.haveNewMessages.length > 0) {
      await this.clearEntireCollectionBeforeRestore('haveNewMessages');
      this.restoreCollectionWithKey('haveNewMessages', firestoreBackup.haveNewMessages);
    }
    //#endregion - backup haveNewMessages

    //#region - backup meteoDays
    if (Functions.IsNullOrUndefined(firestoreBackup.meteoDays) == false && firestoreBackup.meteoDays.length > 0) {
      await this.clearEntireCollectionBeforeRestore('meteoDays');
      this.restoreMeteoCollection('meteoDays', firestoreBackup.meteoDays);
    }
    //#endregion - backup meteoDays

    //#region - backup privateConversations
    if (Functions.IsNullOrUndefined(firestoreBackup.privateConversations) == false && firestoreBackup.privateConversations.length > 0) {
      await this.clearEntireCollectionBeforeRestore('privateConversations');
      this.restorePMCollection('privateConversations', firestoreBackup.privateConversations);
    }
    //#endregion - backup privateConversations

    //#region - backup special chats
    if (Functions.IsNullOrUndefined(firestoreBackup.specialChat) == false && firestoreBackup.specialChat.length > 0) {
      await this.clearEntireCollectionBeforeRestore('specialChat');
      this.restoreCollection('specialChat', firestoreBackup.specialChat);
    }
    //#endregion - backup special chats

    //#region - backup chats
    if (Functions.IsNullOrUndefined(firestoreBackup.standardChat) == false && firestoreBackup.standardChat.length > 0) {
      await this.clearEntireCollectionBeforeRestore('standardChat');
      this.restoreCollection('standardChat', firestoreBackup.standardChat);
    }
    //#endregion - backup chats

    // this.toastr.success('Primo database ripristinato!');
  }

  public restoreDB2() {
    if (window.confirm("Sei sicuro di voler ripristinare il database 2? Tutti i dati verrano sovrascritti, l'operazione non si può interrompere nè abortire.")) {
      // keep going
    } else {
      return;
    }

    // safety check
    const authData: firebase.auth.UserCredential = fromRoot.getState(this.store).character.myAuthenticationData;
    if (authData.user.email != "fitzroy013@gmail.com" && authData.user.email != "reginadellacorte@outlook.it" && authData.user.email != "reddomino92@gmail.com") {
      return;
    }

    //already restoring
    if (this.wait == true) {
      return;
    }

    const self: this = this;
    let dirty: boolean = false;
    this.wait = true
    this.backupFileService.getBackupFile("bck%2Ffb.json").subscribe((reponse: FirebaseBackup) => {
      // safety check
      if (Functions.IsNullOrUndefined(Response) == true || dirty == true)
        return;

      dirty = true;
      self.restoreDB2Procede(reponse);
    })
  }


  private restoreDB2Procede(firebaseBackup: FirebaseBackup) {
    this.restoreFBCompleted = new Set();

    //#region - backup player status
    const userPresenceCollection: AngularFireList<UserPresence> = this.afdb.list<UserPresence>('/status');
    userPresenceCollection.remove();

    this.restoreFBCollectionStatusWithKey('/status', firebaseBackup.status);
    //#endregion - backup player status

    //#region - backup chat status
    const chatsCollection: AngularFireList<ChatData> = this.afdb.list<ChatData>('/chats');
    chatsCollection.remove();

    this.restoreFBCollectionWithKey('/chats', firebaseBackup.chats);
    //#endregion - backup chat status

    // this.toastr.success('Secondo database ripristinato!');
  }




  //UTILITY METHODS
  private async clearEntireCollectionBeforeRestore(collectionId: string) {
    const locationCollection: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = this.afs.collection<any>(collectionId).ref.get({ source: 'server' });
    await locationCollection.then((data) => {
      data.forEach(doc => {
        doc.ref.delete();
      });
    });
  }

  private restoreCollection(collectionId: string, collectionItems: any[]) {
    for (const anItem of collectionItems) {
      if (Functions.IsNullOrUndefined(anItem) == true)
        continue;

      const itemDoc: AngularFirestoreDocument<any> = this.afs.doc<any>(collectionId + '/' + anItem.uid);
      itemDoc.set(anItem);
    }

    this.restoreCompleted.add(collectionId);
    if (this.restoreCompleted.size == 16) {
      this.wait = false;
      this.toastr.success('Primo database ripristinato!');
    }
  }

  private restoreCollectionWithKey(collectionId: string, collectionItems: any[]) {
    for (const anItem of collectionItems) {
      if (Functions.IsNullOrUndefined(anItem) == true)
        continue;

      const itemDoc: AngularFirestoreDocument<any> = this.afs.doc<any>(collectionId + '/' + anItem.key);
      anItem.key = undefined;
      const normalizedData: any = JSON.parse(JSON.stringify(anItem));
      itemDoc.set(normalizedData);
    }

    this.restoreCompleted.add(collectionId);
    if (this.restoreCompleted.size == 16) {
      this.wait = false;
      this.toastr.success('Primo database ripristinato!');
    }
  }

  private restoreMeteoCollection(collectionId: string, collectionItems: any[]) {
    for (const anItem of collectionItems) {
      if (Functions.IsNullOrUndefined(anItem) == true)
        continue;

      const itemDoc: AngularFirestoreDocument<any> = this.afs.doc<any>(collectionId + '/' + anItem.day);
      itemDoc.set(anItem);
    }

    this.restoreCompleted.add(collectionId);
    if (this.restoreCompleted.size == 16) {
      this.wait = false;
      this.toastr.success('Primo database ripristinato!');
    }
  }

  private restorePMCollection(collectionId: string, collectionItems: any[]) {
    for (const anItem of collectionItems) {
      if (Functions.IsNullOrUndefined(anItem) == true)
        continue;

      const itemDoc: AngularFirestoreDocument<any> = this.afs.doc<any>(collectionId + '/' + anItem.cnvUID);
      itemDoc.set(anItem);
    }

    this.restoreCompleted.add(collectionId);
    if (this.restoreCompleted.size == 16) {
      this.wait = false;
      this.toastr.success('Primo database ripristinato!');
    }
  }

  private restoreListUserNameCollection(collectionId: string, collectionItems: any[]) {
    for (const anItem of collectionItems) {
      if (Functions.IsNullOrUndefined(anItem) == true)
        continue;

      const itemDoc: AngularFirestoreDocument<any> = this.afs.doc<any>(collectionId + '/' + anItem.key);
      const normalizedData: any = JSON.parse(JSON.stringify({}));
      itemDoc.set(normalizedData);
    }

    this.restoreCompleted.add(collectionId);
    if (this.restoreCompleted.size == 16) {
      this.wait = false;
      this.toastr.success('Primo database ripristinato!');
    }
  }

  private restoreFBCollectionStatusWithKey(collectionId: string, collectionItems: any[]) {
    for (const anItem of collectionItems) {
      if (Functions.IsNullOrUndefined(anItem) == true)
        continue;

      const itemDoc: AngularFireObject<any> = this.afdb.object<any>(collectionId + '/' + anItem.key);
      anItem.key = undefined;
      anItem.connections = undefined;
      const normalizedData: any = JSON.parse(JSON.stringify(anItem));
      itemDoc.set(normalizedData);
    }

    this.restoreFBCompleted.add(collectionId);
    if (this.restoreFBCompleted.size == 2) {
      this.wait = false;
      this.toastr.success('Secondo database ripristinato!');
    }

  }

  private restoreFBCollectionWithKey(collectionId: string, collectionItems: any[]) {
    for (const anItem of collectionItems) {
      if (Functions.IsNullOrUndefined(anItem) == true)
        continue;

      const itemDoc: AngularFireObject<any> = this.afdb.object<any>(collectionId + '/' + anItem.key);
      anItem.key = undefined;
      const normalizedData: any = JSON.parse(JSON.stringify(anItem));
      itemDoc.set(normalizedData);
    }

    this.restoreFBCompleted.add(collectionId);
    if (this.restoreFBCompleted.size == 2) {
      this.wait = false;
      this.toastr.success('Secondo database ripristinato!');
    }

  }




  public ricreaTimestampPM() {
      const privateConversationsCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations');
      const privateConversationsCollectionObservable: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> = privateConversationsCollection.ref.get({ source: 'server' });
      privateConversationsCollectionObservable.then((data) => {

        if (Functions.IsNullOrUndefined(data) == true || data.docs.length <= 0)
          return;

        let aPMConv: PrivateConversation;
        data.forEach(a => {
          const entry: PrivateConversation = a.data() as PrivateConversation;
          aPMConv = Object.assign({}, entry);

          const aPMConvJSON = JSON.parse(JSON.stringify(aPMConv));

          const date: Date = Functions.FromOADate(aPMConv.lMsgPTS);
          aPMConvJSON.lMsgTS = firebase.firestore.Timestamp.fromDate(date);

          const itemDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('privateConversations/' + aPMConvJSON.cnvUID);
          itemDoc.set(aPMConvJSON);
        });


      });
  }

}
