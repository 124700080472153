import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Functions } from '../../modules/utilities/functions/utilities.functions';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase/app';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private afAuth: AngularFireAuth,
    public routerService: Router,
    public location: Location
  ) { }

  public canActivate(): Observable<boolean> {

    return this.afAuth.authState.pipe(map((userAuth: User) => {
      const isAuthenticated: boolean = (Functions.IsNullOrUndefined(userAuth) == false);
      // return this.auth.isAuthenticated().map((isAuthenticated: any) => {
      if (isAuthenticated)
        return true;
      else {
        let self = this;
        this.routerService.navigate(['/esterna'], { skipLocationChange: true, queryParams: {} });
        setTimeout(() => {
          self.location.replaceState("");
        }, 0);
        // this.location.replaceState("");
        return false;
        // return false;
      }
    }));
  }
}
