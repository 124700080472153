// Angular & Co.
import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material'; // used to import and trust svg of custom icons
import { DomSanitizer } from '@angular/platform-browser'; // used to import and trust svg of custom icons

@Component({
  selector: 'unveiled-web',
  template: `<router-outlet></router-outlet>`,
  viewProviders: [MatIconRegistry],
})

export class AppComponent {
  constructor(matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

    // add our custom icons
    matIconRegistry.addSvgIconInNamespace('viewIcons', 'test', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/test.svg'));

  }
}
