import { UserEntry, PrivateConversation, OnOff, CharacterSheetData } from './../../../models/data/application.data';
import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';

@Pipe({ name: 'hourPipe' })
export class HourPipe implements PipeTransform {
  constructor() { }

  public transform(dateHour: string) {
    if (Functions.IsStringEmpty(dateHour))
      return "";
    else {
      const splitted: string[] = dateHour.split(",");
      return splitted[1];
    }
  }
}

@Pipe({ name: 'pmDatePipe' })
export class PMDatePipe implements PipeTransform {
  constructor() { }

  public transform(dateHour: string) {
    if (Functions.IsStringEmpty(dateHour))
      return "";
    else {
      const splitted: string[] = dateHour.split(",");
      return splitted[0];
    }
  }
}

@Pipe({ name: 'pmNamePipe' })
export class PMNamePipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(uid: string) {
    if (Functions.IsStringEmpty(uid))
      return "";
    else {
      const usersList: UserEntry[] = fromRoot.getState(this.store).datas.usersList;
      if (Functions.IsNullOrUndefined(usersList) || usersList.length <= 0)
        return "";

      const userFound: UserEntry = usersList.find((aUser: UserEntry) => aUser.uid == uid);
      if (Functions.IsNullOrUndefined(userFound))
        return "";
      else
        return userFound.name;
    }
  }
}

@Pipe({ name: 'pmNamesPipe' })
export class PMNamesPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(uids: string[], owner: string) {
    if (Functions.IsNullOrUndefined(uids) || uids.length <= 0)
      return "";
    else {
      const usersList: UserEntry[] = fromRoot.getState(this.store).datas.usersList;
      if (Functions.IsNullOrUndefined(usersList) || usersList.length <= 0)
        return "";

      const myCharacter: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
      let stringToReturn: string = "";
      for (let index: number = 0; index < uids.length; index++) {
        const userFound: UserEntry = usersList.find((aUser: UserEntry) => aUser.uid == uids[index]);
        if (Functions.IsNullOrUndefined(userFound) == false) {
          stringToReturn = stringToReturn + userFound.name;

          // if (uids[index] != myCharacter.uid) {
          stringToReturn = stringToReturn + ", ";
          // }

          // if (index != (uids.length) - 1)
          //   stringToReturn = stringToReturn + ", ";


        } else {
          if (uids[index] == myCharacter.uid) {
            stringToReturn = stringToReturn + myCharacter.name + ", ";
          }
        }

        // if (uids[index] == myCharacter.uid) {
        //   stringToReturn = stringToReturn + myCharacter.name;

        //   if (index != (uids.length) - 1)
        //     stringToReturn = stringToReturn + ", ";
        // }
      }

      const userFound: UserEntry = usersList.find((aUser: UserEntry) => aUser.uid == owner);
      if (Functions.IsNullOrUndefined(userFound) == true) {
        if (owner == myCharacter.uid) {
          // i'm the sender
          stringToReturn = stringToReturn + myCharacter.name;
        } else {
          // rimuovo la virgola finale - l'utente probabilmente è stato eliminato
          stringToReturn.slice(0, stringToReturn.length - 1);
        }

      } else {
        stringToReturn = stringToReturn + userFound.name;
      }
      return stringToReturn;
    }
  }
}


@Pipe({ name: 'pmsFilterPipe' })
export class PMsFilterPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allPMs: PrivateConversation[], showOn: boolean) {
    if (Functions.IsNullOrUndefined(allPMs) || allPMs.length <= 0)
      return [];
    else {
      let toReturn: PrivateConversation[] = []

      if (showOn)
        toReturn = allPMs.filter((aPM: PrivateConversation) => aPM.onOff == OnOff.on);
      else
        toReturn = allPMs.filter((aPM: PrivateConversation) => aPM.onOff == OnOff.off);

      return toReturn;

    }
  }
}

@Pipe({ name: 'pmCutPipe' })
export class PMCutPipe implements PipeTransform {
  constructor() { }

  public transform(message: string) {
    if (Functions.IsStringEmpty(message))
      return "";
    else {

      if (message.includes("\n")) {
        // contiene degli a capo
        let messageToReturn: string = "";
        let charsCount: number = 0;
        const newLineSpace: number = 57;

        const textRows: string[] = message.split("\n");
        for (const aRow of textRows) {

          let lineSpace: number = 57;
          if (Functions.IsStringEmpty(aRow) == false && aRow.length > lineSpace) {
            lineSpace = aRow.length;
          }

          if (charsCount + lineSpace > 300) {
            messageToReturn = messageToReturn + "... (doppio click per continuare a leggere)";
            return messageToReturn;
          } else {

            if (Functions.IsStringEmpty(aRow) == false) {
              messageToReturn = messageToReturn + aRow + "\n";
              charsCount = charsCount + lineSpace;
            } else {
              messageToReturn = messageToReturn + "\n";
              charsCount = charsCount + newLineSpace;
            }
          }
        }
        return messageToReturn;

      } else if (message.length > 300) {
        // cut it
        let messageToReturn: string = message.slice(0, 300);
        messageToReturn = messageToReturn + "... (doppio click per continuare a leggere)";
        return messageToReturn;
      } else {
        return message;
      }
    }
  }
}

@Pipe({ name: 'pmCountPipe' })
export class PMCountPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allPMs: PrivateConversation[], showOn: boolean) {
    if (Functions.IsNullOrUndefined(allPMs) || allPMs.length <= 0)
      return "(0)";
    else {
      let toReturn: PrivateConversation[] = []
      const convWithNew: string[] = fromRoot.getState(this.store).pm.convWithNewMessages;
      if (convWithNew.length <= 0)
        return "(0)";

      const convWithNewSet: Set<string> = new Set(convWithNew);
      if (showOn)
        toReturn = allPMs.filter((aPM: PrivateConversation) => convWithNewSet.has(aPM.cnvUID) && aPM.onOff == OnOff.on);
      else
        toReturn = allPMs.filter((aPM: PrivateConversation) => convWithNewSet.has(aPM.cnvUID) && aPM.onOff == OnOff.off);

      if (toReturn.length <= 0)
        return "(0)";

      return "(" + toReturn.length + ")";

    }
  }
}
