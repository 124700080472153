import { LayoutState } from './../modules/utilities/utilities.constants';
import { OnOff, PrivateMessage } from './../models/data/application.data';
import { Functions } from './../modules/utilities/functions/utilities.functions';
import { first } from 'rxjs/operators';
import * as fromRoot from '../reducers';
import { Store } from '@ngrx/store';

import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { PrivateConversation } from '../models/data/application.data';
import { Subscription, Observable } from 'rxjs';
import * as pmAction from './../actions/pm';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { DebugLoggerService } from './debug-logger.service';
import * as layout from './../actions/layout';

@Injectable()
export class PmService {
  private moduleName: string = "pmService";
  //#region - has new messages
  private hasNewMessageDoc: AngularFirestoreDocument<any>;
  private hasNewMessageDocObservable: Observable<any[]>;
  private getHasNewPMsSubscription: Subscription;
  //#endregion - has new messages

  private getPMsSubscription: Subscription;
  private tempUpdateSubscription: Subscription[] = [];

  public sendToUID: string = "";
  public pmSound: HTMLAudioElement = new Audio();

  public gettingMessages: boolean = false;
  public sending: boolean = false;

  constructor(
    private afs: AngularFirestore,
    // private firestoreDB: AngularFirestore,
    private store: Store<fromRoot.State>,
    private debugLogger: DebugLoggerService
  ) { }

  public setUpPMService() {
    const self: this = this;
    this.pmSound.src = "../../assets/audio/pm.wav";
    this.pmSound.load();

    if (Functions.IsNullOrUndefined(this.getHasNewPMsSubscription) == true) {
      // download hasNewMessages
      const myUID: string = fromRoot.getState(this.store).character.myUID;

      // safety check
      if (Functions.IsStringEmpty(myUID)) {
        return;
      }

      this.hasNewMessageDoc = this.afs.doc<any>('haveNewMessages/' + myUID);
      this.hasNewMessageDocObservable = this.hasNewMessageDoc.valueChanges();
      this.getHasNewPMsSubscription = this.hasNewMessageDocObservable.subscribe((value: any) => {
        // this.getHasNewPMsSubscription = this.firestore.read('haveNewMessages/' + myUID).skip(1).subFscribe((value: any) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ has new messages", self.moduleName, "haveNewMessages", 1);
        }

        if (Functions.IsNullOrUndefined(value))
          return;

        const updatedConveUID: string[] = value.newMsg;
        // const selectedConv: PrivateConversation = fromRoot.getState(self.store).pm.selectedPM;

        // // if reading a conversation do not set new messages in the conversation
        // if (Functions.IsNullOrUndefined(selectedConv) == false) {
        //   updatedConveUID = updatedConveUID.filter((aConvID: string) => aConvID != selectedConv.cnvUID);
        // }
        self.store.dispatch(new pmAction.StoreConvsWithNewPMs(updatedConveUID));

        if (Functions.IsNullOrUndefined(updatedConveUID) || updatedConveUID.length <= 0)
          return;

        let isSoundEnabled: boolean = fromRoot.getState(self.store).character.myCharacterData.soundEnabled;
        if (Functions.IsNullOrUndefined(isSoundEnabled)) {
          isSoundEnabled = true;
        }

        let isMessageSoundDisabled: boolean = fromRoot.getState(self.store).character.myCharacterData.messageSoundDisabled;
        if (Functions.IsNullOrUndefined(isMessageSoundDisabled)) {
          isMessageSoundDisabled = true;
        }

        if (isSoundEnabled && isMessageSoundDisabled == false && (fromRoot.getState(self.store).layout.layoutState != LayoutState.HomeEsterna)) {
          self.pmSound.play();
        }

        if (Functions.IsNullOrUndefined(fromRoot.getState(self.store).pm.pms) == true)
          return;

        // get delta updates
        const hasNewMessagesToRemove: Set<string> = new Set();
        const selectedConv: PrivateConversation = fromRoot.getState(self.store).pm.selectedPM;
        for (let index: number = 0; index < updatedConveUID.length; index++) {

          const aPrivateConversationDoc: AngularFirestoreDocument<PrivateConversation> = this.afs.doc<PrivateConversation>('privateConversations/' + updatedConveUID[index]);
          const aPrivateConversationDocObservable: Observable<PrivateConversation> = aPrivateConversationDoc.valueChanges();
          let subscription = aPrivateConversationDocObservable.pipe(first())
            .subscribe((conv: PrivateConversation) => {
              if (self.debugLogger.isAuditing) {
                self.debugLogger.logRead(false, "READ a conversation update", self.moduleName, "privateConversations", 1);
              }

              if (Functions.IsNullOrUndefined(conv) == false) {
                self.store.dispatch(new pmAction.UpdatedStoredPMs(conv));
                self.tempUpdateSubscription.push(subscription);

                if (Functions.IsNullOrUndefined(selectedConv) == false && selectedConv.cnvUID == conv.cnvUID)
                  self.store.dispatch(new pmAction.SelectPM(conv));
              } else {
                // message doesn't exist no more. We need to remove it from new messages
                hasNewMessagesToRemove.add(updatedConveUID[index]);
              }

              if (index == updatedConveUID.length - 1 && hasNewMessagesToRemove.size > 0) {
                // update new messages
                let hasMessagesUpdated: string[] = Object.assign([], fromRoot.getState(self.store).pm.convWithNewMessages);
                hasMessagesUpdated = hasMessagesUpdated.filter((aConv: string) => hasNewMessagesToRemove.has(aConv) == false);

                const newMessagesDoc: AngularFirestoreDocument<any> = self.afs.doc<any>('haveNewMessages/' + myUID);
                newMessagesDoc.set({
                  newMsg: hasMessagesUpdated,
                  timeStamp: Functions.ToOADate(new Date())
                });
              }
            });
        }

        // clean subscription
        self.tempUpdateSubscription.forEach((aSubscription: Subscription) => aSubscription.unsubscribe());
        self.tempUpdateSubscription = [];
      });
    }
  }

  public resetPMService() {
    if (Functions.IsNullOrUndefined(this.getPMsSubscription) == false) {
      this.getPMsSubscription.unsubscribe();
      this.getPMsSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.getHasNewPMsSubscription) == false) {
      this.getHasNewPMsSubscription.unsubscribe();
      this.getHasNewPMsSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.tempUpdateSubscription) == false) {
      for (let index: number = 0; index < this.tempUpdateSubscription.length; index++) {
        this.tempUpdateSubscription[index].unsubscribe();
      }
      this.tempUpdateSubscription = [];
    }

    this.store.dispatch(new pmAction.ResetPMStore());
    // this.store.dispatch(new pmAction.StoreConvsWithNewPMs([]));
    this.sendToUID = "";
  }

  //send a pm
  public createNewConversation(titolo: string, onOff: OnOff, destinatari: string[], targetsUID: string[], message: string, conRisposta: boolean) {

    //register message inside firebase messages collection
    const myUID: string = fromRoot.getState(this.store).character.myUID;

    // creating message
    const newMsgUID: string = Functions.CreateGuid();
    const dateHour: string = Functions.GetCurrentDate();
    const pm: PrivateMessage = new PrivateMessage(newMsgUID, myUID, dateHour, message);

    // creating conversation
    if (Functions.IsStringEmpty(titolo))
      titolo = "Senza Titolo";

    const newConversationUID: string = Functions.CreateGuid();
    const newConversation: PrivateConversation = new PrivateConversation(newConversationUID, myUID, onOff, titolo, destinatari, targetsUID, conRisposta);
    newConversation.messages = [pm];
    newConversation.lMsgDT = dateHour;
    newConversation.lMsgPTS = Functions.ToOADate(new Date());

    // normalizing data
    const newConversationJSON = JSON.parse(JSON.stringify(newConversation));

    // adding timestamp
    newConversationJSON.lMsgTS = firebase.firestore.Timestamp.fromDate(new Date());
    // newConversationJSON.lMsgTS = firebase.firestore.FieldValue.serverTimestamp(); // used to make firestore timestamp work

    // saving
    //this.firestoreDB.collection('privateConversations').doc(newConversationUID).set(newConversationJSON);
    // this.firestore.write('privateConversations/' + newConversationUID, newConversationJSON);
    const privateConversationCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations');
    privateConversationCollection.doc(newConversationUID).set(newConversationJSON);




    // alzare il flag di nuovi messaggi sui destinatari
    const self: this = this;
    for (let index: number = 0; index < destinatari.length; index++) {
      const curentDestinatario: string = destinatari[index];

      if (curentDestinatario == myUID) {
        if (index == destinatari.length - 1) {
          setTimeout(() => {
            self.sending = false;
          }, 2000);
        }

        continue;
      }

      const newMessagesDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('haveNewMessages/' + curentDestinatario);
      const newMessagesDocObservable: Observable<any> = newMessagesDoc.valueChanges();
      let subscription = newMessagesDocObservable.pipe(first())
        .subscribe((hasNew: any) => {
          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(false, "READ has new conversation", self.moduleName, "haveNewMessages", 1);
          }

          if (Functions.IsNullOrUndefined(hasNew)) {
            hasNew = {
              newMsg: [],
              timeStamp: Functions.ToOADate(new Date())
            };
          }

          let messagesToBeRed: string[] = hasNew.newMsg;
          messagesToBeRed = messagesToBeRed.filter((aMessage: string) => aMessage != newConversationUID);
          messagesToBeRed.push(newConversationUID);
          newMessagesDoc.set({
            newMsg: messagesToBeRed,
            timeStamp: Functions.ToOADate(new Date())
          });

          // self.firestore.write('haveNewMessages/' + curentDestinatario, {
          //   newMsg: messagesToBeRed,
          //   timeStamp: Functions.ToOADate(new Date())
          // });
          self.tempUpdateSubscription.push(subscription);

          // go back to messaggi ricevuti
          if (index == destinatari.length - 1) {
            self.sending = false;
            self.store.dispatch(new layout.ToggleMessaggiRicevutiBoxAction());
          }

        });
      // this.firestore.write('haveNewMessages/' + curentDestinatario, { newMsg: [newConversationUID] });
      //this.firestoreDB.collection('haveNewMessages').doc(curentDestinatario).set({ newMsg: [newConversationUID] });
    }

    // clean subscription
    self.tempUpdateSubscription.forEach((aSubscription: Subscription) => aSubscription.unsubscribe());
    self.tempUpdateSubscription = [];


    // updating local message for me
    self.store.dispatch(new pmAction.UpdatedStoredPMs(newConversation));

  }

  public getMyConversation() {
    const self: this = this;
    this.gettingMessages = true;
    const myUID: string = fromRoot.getState(this.store).character.myUID;

    const currentPMs: PrivateConversation[] = fromRoot.getState(this.store).pm.pms || [];
    const showingOn: boolean = fromRoot.getState(this.store).pm.showOnPMs;

    let filteredCurrent: PrivateConversation[] = [];
    let searchFor: OnOff = OnOff.on;
    if (showingOn) {
      filteredCurrent = currentPMs.filter((aPM: PrivateConversation) => aPM.onOff == OnOff.on);
      searchFor = OnOff.on;
    } else {
      filteredCurrent = currentPMs.filter((aPM: PrivateConversation) => aPM.onOff == OnOff.off);
      searchFor = OnOff.off;
    }


    if (Functions.IsNullOrUndefined(currentPMs) || currentPMs.length == 0) {
      // no pms in memory

      const myPMCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", myUID).where("onOff", "==", searchFor).orderBy("lMsgPTS", "desc").limit(10));
      const myPMCollectionObservable: Observable<PrivateConversation[]> = myPMCollection.valueChanges();
      this.getPMsSubscription = myPMCollectionObservable.pipe(first()).subscribe((values: any) => {
        // this.getPMsSubscription = this.firestore.query("privateConversations").where("targetsUID", "array-contains", myUID).where("onOff", "==", searchFor).orderBy("lMsgTS", "desc").limit(10)
        //   .on().skip(1).pipe(first()).subscribe((values: any) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ all my conv", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        const firstGroupOfValues: PrivateConversation[] = values;
        if (searchFor == OnOff.on)
          searchFor = OnOff.off;
        else
          searchFor = OnOff.on;

        const myPMCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", myUID).where("onOff", "==", searchFor).orderBy("lMsgPTS", "desc").limit(10));
        const myPMCollectionObservable: Observable<PrivateConversation[]> = myPMCollection.valueChanges();
        self.getPMsSubscription = myPMCollectionObservable.pipe(first()).subscribe((values: any) => {
          // self.getPMsSubscription = self.firestore.query("privateConversations").where("targetsUID", "array-contains", myUID).where("onOff", "==", searchFor).orderBy("lMsgTS", "desc").limit(10)
          //   .on().skip(1).pipe(first()).subscribe((values: any) => {
          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(false, "READ all my conv", self.moduleName, "privateConversations", (values as any[]).length);
          }

          if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
            self.getPMsSubscription.unsubscribe();

          const mergedValues: PrivateConversation[] = firstGroupOfValues.concat(values);
          self.store.dispatch(new pmAction.StorePMs(mergedValues));
          self.gettingMessages = false;
        });

        // self.store.dispatch(new pmAction.StorePMs(values));
      });
    } else {
      filteredCurrent = Functions.sortPMs(filteredCurrent);
      if (Functions.IsNullOrUndefined(filteredCurrent) == true || filteredCurrent.length <= 0) {
        self.gettingMessages = false;
        return;
      }

      const lastLocalPmTS: any = filteredCurrent[filteredCurrent.length - 1].lMsgPTS;
      const myPMCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations', ref => ref.where("targetsUID", "array-contains", myUID).where("onOff", "==", searchFor).orderBy("lMsgPTS", "desc").startAfter(lastLocalPmTS).limit(10));
      const myPMCollectionObservable: Observable<PrivateConversation[]> = myPMCollection.valueChanges();
      this.getPMsSubscription = myPMCollectionObservable.pipe(first()).subscribe((values: any) => {
        // this.getPMsSubscription = this.firestore.query("privateConversations").where("targetsUID", "array-contains", myUID).where("onOff", "==", searchFor).orderBy("lMsgTS", "desc").startAfter(lastLocalPmTS).limit(10)
        //   .on().skip(1).pipe(first()).subscribe((values: any) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ all my conv", self.moduleName, "privateConversations", (values as any[]).length);
        }

        if (Functions.IsNullOrUndefined(self.getPMsSubscription) == false)
          self.getPMsSubscription.unsubscribe();

        self.store.dispatch(new pmAction.StorePMs(values));
        self.gettingMessages = false;
      });
    }

  }

  public replyConversation(message: string) {
    const myUID: string = fromRoot.getState(this.store).character.myUID;
    const currentSelectedConv: PrivateConversation = Object.assign({}, fromRoot.getState(this.store).pm.selectedPM);

    if (Functions.IsNullOrUndefined(currentSelectedConv) == true)
      return;

    // creating the reply message
    const newMsgUID: string = Functions.CreateGuid();
    const dateHour: string = Functions.GetCurrentDate();
    const pm: PrivateMessage = new PrivateMessage(newMsgUID, myUID, dateHour, message);

    // updating data
    // currentSelectedConv.messages.push(pm);
    // currentSelectedConv.lMsgDT = dateHour;
    // currentSelectedConv.lMsgPTS = Functions.ToOADate(new Date());

    // resetting targets
    const setOfTargets: Set<string> = new Set(Object.assign([], currentSelectedConv.destUID));
    setOfTargets.add(myUID);
    setOfTargets.add(currentSelectedConv.ownUID);
    // currentSelectedConv.targetsUID = Array.from(setOfTargets.values());
    // currentSelectedConv.targetsUID = Object.assign([], currentSelectedConv.destUID);
    // currentSelectedConv.targetsUID.push(myUID);

    // normalizing data
    // const currentSelectedConvJSON = JSON.parse(JSON.stringify(currentSelectedConv));
    // adding timestamp
    // currentSelectedConvJSON.lMsgTS = firebase.firestore.Timestamp.fromDate(new Date());
    // currentSelectedConvJSON.lMsgTS = firebase.firestore.FieldValue.serverTimestamp(); // used to make firestore timestamp work

    const pmNormalized: any = JSON.parse(JSON.stringify(pm));

    // saving
    // this.firestore.write('privateConversations/' + currentSelectedConv.cnvUID, currentSelectedConvJSON);
    const privateConversationCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations');
    // privateConversationCollection.doc(currentSelectedConv.cnvUID).set(currentSelectedConvJSON);
    const privateConversationDocument: AngularFirestoreDocument<unknown> = privateConversationCollection.doc(currentSelectedConv.cnvUID);
    privateConversationDocument.update({
      lMsgDT: dateHour,
      lMsgPTS: Functions.ToOADate(new Date()),
      lMsgTS: firebase.firestore.Timestamp.fromDate(new Date()),
      targetsUID: Array.from(setOfTargets.values()),
      messages: firebase.firestore.FieldValue.arrayUnion(pmNormalized)
    }).then((value: any) => {
      const updatedPV: Observable<unknown> = privateConversationDocument.valueChanges();
      updatedPV.pipe(first()).subscribe((updateConv: PrivateConversation) => {
        self.store.dispatch(new pmAction.UpdatedStoredPMs(updateConv));
        self.store.dispatch(new pmAction.SelectPM(updateConv));
      });
    })

    // alzare il flag di nuovi messaggi sui destinatari
    const self: this = this;
    const sendTo: string[] = Object.assign([], currentSelectedConv.destUID);
    sendTo.push(currentSelectedConv.ownUID);
    for (let index: number = 0; index < sendTo.length; index++) {
      const curentDestinatario: string = sendTo[index];

      if (curentDestinatario == myUID) {
        if (index == sendTo.length - 1) {
          setTimeout(() => {
            self.sending = false;
          }, 2000);
        }

        continue;
      }

      const newMessagesDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('haveNewMessages/' + curentDestinatario);
      const newMessagesDocObservable: Observable<any> = newMessagesDoc.valueChanges();
      let subscription = newMessagesDocObservable.pipe(first())
        .subscribe((hasNew: any) => {

          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(false, "READ has new reply conversation", self.moduleName, "haveNewMessages", 1);
          }

          if (Functions.IsNullOrUndefined(hasNew)) {
            hasNew = {
              newMsg: [],
              timeStamp: Functions.ToOADate(new Date())
            };
          }

          let messagesToBeRed: string[] = hasNew.newMsg;
          messagesToBeRed = messagesToBeRed.filter((aMessage: string) => aMessage != currentSelectedConv.cnvUID);
          messagesToBeRed.push(currentSelectedConv.cnvUID);
          newMessagesDoc.set({
            newMsg: messagesToBeRed,
            timeStamp: Functions.ToOADate(new Date())
          });
          // self.firestore.write('haveNewMessages/' + curentDestinatario, {
          //   newMsg: messagesToBeRed,
          //   timeStamp: Functions.ToOADate(new Date())
          // });
          self.tempUpdateSubscription.push(subscription);

          if (index == sendTo.length - 1) {
            self.sending = false;
          }
        });
    }

    // clean subscription
    self.tempUpdateSubscription.forEach((aSubscription: Subscription) => aSubscription.unsubscribe());
    self.tempUpdateSubscription = [];

    // updating local message for me
    // update local version of curent conversation
    // setTimeout(() => {
    //   const updatedPV: Observable<PrivateConversation> = privateConversationCollection.doc<PrivateConversation>(currentSelectedConv.cnvUID).valueChanges();
    //   updatedPV.pipe(first()).subscribe((updateConv: PrivateConversation) => {
    //     self.store.dispatch(new pmAction.UpdatedStoredPMs(updateConv));
    //     self.store.dispatch(new pmAction.SelectPM(updateConv));
    //   });
    // }, 1000);

    // self.store.dispatch(new pmAction.UpdatedStoredPMs(currentSelectedConv));
    // self.store.dispatch(new pmAction.SelectPM(currentSelectedConv));
  }

  public setMessageAsReaded(cnvID: string) {
    const myUid: string = fromRoot.getState(this.store).character.myUID;
    let hasMessagesUpdated: string[] = Object.assign([], fromRoot.getState(this.store).pm.convWithNewMessages);
    hasMessagesUpdated = hasMessagesUpdated.filter((aConv: string) => aConv != cnvID);

    const newMessagesDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('haveNewMessages/' + myUid);
    newMessagesDoc.set({
      newMsg: hasMessagesUpdated,
      timeStamp: Functions.ToOADate(new Date())
    });

    // this.firestore.write('haveNewMessages/' + myUid, {
    //   newMsg: hasMessagesUpdated,
    //   timeStamp: Functions.ToOADate(new Date())
    // });
  }

  public deleteMessagesForMe(cnvID: string[]) {
    let self: this = this;
    const newMessagesSet: Set<string> = new Set(fromRoot.getState(this.store).pm.convWithNewMessages);

    const myUID: string = fromRoot.getState(this.store).character.myUID;
    for (let index: number = 0; index < cnvID.length; index++) {
      const aCnvID: string = cnvID[index];

      // remove it from new messages
      if (newMessagesSet.has(aCnvID) == true) {
        newMessagesSet.delete(aCnvID);
      }

      const conversationDoc: AngularFirestoreDocument<PrivateConversation> = this.afs.doc<PrivateConversation>('privateConversations/' + aCnvID);
      const conversationDocObservable: Observable<PrivateConversation> = conversationDoc.valueChanges();
      let subscription = conversationDocObservable.pipe(first()).subscribe((conv: PrivateConversation) => {
        // let subscription = this.firestore.read('privateConversations/' + aCnvID).skip(1).pipe(first())
        //   .subscribe((conv: PrivateConversation) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ delete a message", self.moduleName, "privateConversations", 1);
        }

        if (Functions.IsNullOrUndefined(conv) == false) {
          conv.targetsUID = conv.targetsUID.filter((aUID: string) => aUID != myUID);

          // if (conv.targetsUID.length > 0) {
          const currentSelectedConvJSON = JSON.parse(JSON.stringify(conv));

          currentSelectedConvJSON.lMsgTS = conv.lMsgTS;
          conversationDoc.set(currentSelectedConvJSON)
            .then(() => {
              self.store.dispatch(new pmAction.DeleteStoredPM(aCnvID));
            });
          // self.firestore.write('privateConversations/' + conv.cnvUID, currentSelectedConvJSON);
          // } else {
          // self.firestore.remove('privateConversations/' + conv.cnvUID);
          // }

          self.tempUpdateSubscription.push(subscription);
        }
      });
    }

    // clean subscription
    self.tempUpdateSubscription.forEach((aSubscription: Subscription) => aSubscription.unsubscribe());
    self.tempUpdateSubscription = [];

    // update new messages
    const updatedNewMessages: string[] = Array.from(newMessagesSet.values());
    const newMessagesDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('haveNewMessages/' + myUID);
    newMessagesDoc.set({
      newMsg: updatedNewMessages,
      timeStamp: Functions.ToOADate(new Date())
    });

  }

  public createObjExchangePM(destinatari: string[], targetsUID: string[], titolo: string, message: string) {
    //register message inside firebase messages collection
    const myUID: string = fromRoot.getState(this.store).character.myUID;

    // creating message
    const newMsgUID: string = Functions.CreateGuid();
    const dateHour: string = Functions.GetCurrentDate();
    const pm: PrivateMessage = new PrivateMessage(newMsgUID, myUID, dateHour, message);

    // creating conversation
    if (Functions.IsStringEmpty(titolo))
      titolo = "Senza Titolo";

    const newConversationUID: string = Functions.CreateGuid();
    const newConversation: PrivateConversation = new PrivateConversation(newConversationUID, myUID, OnOff.off, titolo, destinatari, targetsUID, false);
    newConversation.messages = [pm];
    newConversation.lMsgDT = dateHour;
    newConversation.lMsgPTS = Functions.ToOADate(new Date());

    // normalizing data
    const newConversationJSON = JSON.parse(JSON.stringify(newConversation));

    // adding timestamp
    newConversationJSON.lMsgTS = firebase.firestore.Timestamp.fromDate(new Date());
    // newConversationJSON.lMsgTS = firebase.firestore.FieldValue.serverTimestamp(); // used to make firestore timestamp work

    // saving
    //this.firestoreDB.collection('privateConversations').doc(newConversationUID).set(newConversationJSON);
    // this.firestore.write('privateConversations/' + newConversationUID, newConversationJSON);
    const privateConversationCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations');
    privateConversationCollection.doc(newConversationUID).set(newConversationJSON);

    // alzare il flag di nuovi messaggi sui destinatari
    const self: this = this;
    for (let index: number = 0; index < destinatari.length; index++) {
      const curentDestinatario: string = destinatari[index];

      if (curentDestinatario == myUID)
        continue;

      const newMessagesDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('haveNewMessages/' + curentDestinatario);
      const newMessagesDocObservable: Observable<any> = newMessagesDoc.valueChanges();
      let subscription = newMessagesDocObservable.pipe(first())
        // let subscription = this.firestore.read('haveNewMessages/' + curentDestinatario).skip(1).pipe(first())
        .subscribe((hasNew: any) => {

          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(false, "READ has new messages trade obj", self.moduleName, "haveNewMessages", 1);
          }

          if (Functions.IsNullOrUndefined(hasNew)) {
            hasNew = {
              newMsg: [],
              timeStamp: Functions.ToOADate(new Date())
            };
          }

          let messagesToBeRed: string[] = hasNew.newMsg;
          messagesToBeRed = messagesToBeRed.filter((aMessage: string) => aMessage != newConversationUID);
          messagesToBeRed.push(newConversationUID);
          // self.firestore.write('haveNewMessages/' + curentDestinatario, {
          //   newMsg: messagesToBeRed,
          //   timeStamp: Functions.ToOADate(new Date())
          // });
          newMessagesDoc.set({
            newMsg: messagesToBeRed,
            timeStamp: Functions.ToOADate(new Date())
          });
          self.tempUpdateSubscription.push(subscription);
        });
      // this.firestore.write('haveNewMessages/' + curentDestinatario, { newMsg: [newConversationUID] });
      //this.firestoreDB.collection('haveNewMessages').doc(curentDestinatario).set({ newMsg: [newConversationUID] });
    }

    // clean subscription
    self.tempUpdateSubscription.forEach((aSubscription: Subscription) => aSubscription.unsubscribe());
    self.tempUpdateSubscription = [];


    // updating local message for me
    self.store.dispatch(new pmAction.UpdatedStoredPMs(newConversation));
  }

  public createMoneyTransfertPM(destinatari: string[], targetsUID: string[], titolo: string, message: string) {
    //register message inside firebase messages collection
    const myUID: string = fromRoot.getState(this.store).character.myUID;

    // creating message
    const newMsgUID: string = Functions.CreateGuid();
    const dateHour: string = Functions.GetCurrentDate();
    const pm: PrivateMessage = new PrivateMessage(newMsgUID, myUID, dateHour, message);

    // creating conversation
    if (Functions.IsStringEmpty(titolo))
      titolo = "Senza Titolo";

    const newConversationUID: string = Functions.CreateGuid();
    const newConversation: PrivateConversation = new PrivateConversation(newConversationUID, myUID, OnOff.off, titolo, destinatari, targetsUID, false);
    newConversation.messages = [pm];
    newConversation.lMsgDT = dateHour;
    newConversation.lMsgPTS = Functions.ToOADate(new Date());

    // normalizing data
    const newConversationJSON = JSON.parse(JSON.stringify(newConversation));

    // adding timestamp
    newConversationJSON.lMsgTS = firebase.firestore.Timestamp.fromDate(new Date());
    // newConversationJSON.lMsgTS = firebase.firestore.FieldValue.serverTimestamp(); // used to make firestore timestamp work

    // saving
    //this.firestoreDB.collection('privateConversations').doc(newConversationUID).set(newConversationJSON);
    // this.firestore.write('privateConversations/' + newConversationUID, newConversationJSON);
    const privateConversationCollection: AngularFirestoreCollection<PrivateConversation> = this.afs.collection<PrivateConversation>('privateConversations');
    privateConversationCollection.doc(newConversationUID).set(newConversationJSON);

    // alzare il flag di nuovi messaggi sui destinatari
    const self: this = this;
    for (let index: number = 0; index < destinatari.length; index++) {
      const curentDestinatario: string = destinatari[index];

      if (curentDestinatario == myUID)
        continue;

      const newMessagesDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('haveNewMessages/' + curentDestinatario);
      const newMessagesDocObservable: Observable<any> = newMessagesDoc.valueChanges();
      let subscription = newMessagesDocObservable.pipe(first())
        // let subscription = this.firestore.read('haveNewMessages/' + curentDestinatario).skip(1).pipe(first())
        .subscribe((hasNew: any) => {

          if (self.debugLogger.isAuditing) {
            self.debugLogger.logRead(false, "READ has new messages transfer money obj", self.moduleName, "haveNewMessages", 1);
          }

          if (Functions.IsNullOrUndefined(hasNew)) {
            hasNew = {
              newMsg: [],
              timeStamp: Functions.ToOADate(new Date())
            };
          }

          let messagesToBeRed: string[] = hasNew.newMsg;
          messagesToBeRed = messagesToBeRed.filter((aMessage: string) => aMessage != newConversationUID);
          messagesToBeRed.push(newConversationUID);
          // self.firestore.write('haveNewMessages/' + curentDestinatario, {
          //   newMsg: messagesToBeRed,
          //   timeStamp: Functions.ToOADate(new Date())
          // });
          newMessagesDoc.set({
            newMsg: messagesToBeRed,
            timeStamp: Functions.ToOADate(new Date())
          });
          self.tempUpdateSubscription.push(subscription);
        });
      // this.firestore.write('haveNewMessages/' + curentDestinatario, { newMsg: [newConversationUID] });
      //this.firestoreDB.collection('haveNewMessages').doc(curentDestinatario).set({ newMsg: [newConversationUID] });
    }

    // clean subscription
    self.tempUpdateSubscription.forEach((aSubscription: Subscription) => aSubscription.unsubscribe());
    self.tempUpdateSubscription = [];


    // updating local message for me
    self.store.dispatch(new pmAction.UpdatedStoredPMs(newConversation));
  }
}
