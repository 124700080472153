import { Component, OnInit, Input } from '@angular/core';
import { OnlineUser } from '../../models/chat-message.models';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.css']
})
export class UserItemComponent implements OnInit {

  @Input() user: OnlineUser;

  constructor() { }

  ngOnInit() {
  }


}