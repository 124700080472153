import { OnOff } from './../../../models/data/application.data';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable } from "rxjs";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as dataSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { UserEntry } from '../../../models/data/application.data';
import { PmService } from '../../../services/pm.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'crea-messaggio-box',
  templateUrl: './crea-messaggio.component.html',
  styleUrls: ['./crea-messaggio.component.less']
})
export class CreaMessaggioBoxComponent implements OnInit, OnDestroy {

  allDestinatari$: Observable<UserEntry[]>;
  public message: string = "";
  public titolo: string = "";
  public isOff: boolean = true;
  public destinatari: UserEntry[] = [];
  public conRisposta: boolean = true;

  constructor(private store: Store<fromRoot.State>, private cd: ChangeDetectorRef, public pmService: PmService) { }

  ngOnInit() {
    this.allDestinatari$ = this.store.select(dataSelector.getUsersList)
      .pipe(map((destinatari: UserEntry[]) => {
        return destinatari.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });
      }));

    if (Functions.IsStringEmpty(this.pmService.sendToUID) == false) {
      const allUsers: UserEntry[] = fromRoot.getState(this.store).datas.usersList;
      const selectedUser: UserEntry = allUsers.find((aUser: UserEntry) => aUser.uid == this.pmService.sendToUID);
      if (Functions.IsNullOrUndefined(selectedUser) == false) {
        this.destinatari.push(selectedUser);
      }
      this.pmService.sendToUID = "";
    }
  }

  ngOnDestroy() {

  }

  isForAll() {
    const founded: UserEntry = this.destinatari.find((aDestinatario: UserEntry) => aDestinatario.uid == "@@@ALL@@@");
    if (Functions.IsNullOrUndefined(founded)) {
      return false;
    } else {
      return true;
    }
  }

  toggleType() {
    this.isOff = !this.isOff;
  }

  sendPM() {

    if (Functions.IsStringEmpty(this.message)) {
      alert("Il Messaggio è vuoto.");
      return;
    }

    if (Functions.IsNullOrUndefined(this.destinatari) || this.destinatari.length <= 0) {
      alert("Seleziona almeno un destinatario");
      return;
    }

    this.pmService.sending =  true;

    let targets: string[] = [];
    let destinatari: string[] = [];

    let onOffValue: OnOff = OnOff.on
    if (this.isOff)
      onOffValue = OnOff.off;

    // chesk if sending to all
    let sendingToAll: boolean = false;
    for (let index: number = 0; index < this.destinatari.length; index++) {
      const currentDest: UserEntry = this.destinatari[index];
      if (currentDest.uid == "@@@ALL@@@")
        sendingToAll = true;
    }

    if (sendingToAll == false) {
      // not sending to all
      this.destinatari.forEach((aDestinario: UserEntry) => {
        targets.push(aDestinario.uid);
        destinatari.push(aDestinario.uid);
      });
    } else {
      // sendingToAll
      const myUID: string = fromRoot.getState(this.store).character.myUID;
      const allUsers: UserEntry[] = fromRoot.getState(this.store).datas.usersList;
      for (let index: number = 0; index < allUsers.length; index++) {
        const aUser: UserEntry = allUsers[index];
        if (aUser.uid == myUID || aUser.uid == "@@@ALL@@@")
          continue;

        targets.push(aUser.uid);
        destinatari.push(aUser.uid);
      }
    }

    // adding my uid to targets
    const myUID: string = fromRoot.getState(this.store).character.myUID;
    targets.push(myUID);

    this.pmService.createNewConversation(this.titolo, onOffValue, destinatari, targets, this.message, this.conRisposta);
    // this.store.dispatch(new layout.ToggleMessaggiRicevutiBoxAction());
  }

}
