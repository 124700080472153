import { Component, EventEmitter, Output, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { Functions } from 'src/app/modules/utilities/functions/utilities.functions';

@Component({
  selector: 'credits-component',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.less']
})
export class CreditsComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild('creContainer', { static: false }) private creContainer: ElementRef;
  @Output() emitClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public creContainerMinHeight: number = 0;
  public creContainerWidth: number = 0;

  private creditsDoc: AngularFirestoreDocument<any>;
  private creditsObservable: Observable<any>;
  private creditsSubscription: Subscription;
  public credits: string = "";

  constructor(
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore
  ) {

  }

  public ngOnInit() {
    const self: this = this;
    this.creditsDoc = this.afs.doc<any>('settings/credits');
    this.creditsObservable = this.creditsDoc.valueChanges();
    this.creditsSubscription = this.creditsObservable.subscribe((data) => {

      if (Functions.IsNullOrUndefined(data) == false)
        self.credits = data.value;
        self.cdr.markForCheck();
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.creditsSubscription) == false) {
      this.creditsSubscription.unsubscribe();
    }
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeEsterna() {
    this.emitClose.emit(true);
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.creContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 400)
      minHeight = 400;

    if (minHeight > 450)
      minHeight = 450;

    let width = minHeight;
    // if (width > 569)
    //   width = 569;

    this.creContainerMinHeight = minHeight;
    this.creContainerWidth = width;
    this.cdr.detectChanges();
  }

}
