import { LogsService } from './../../../services/logs.service';
import { FirestoreService } from './../../../services/firestore.service';
import { UserEntry, CharacterSheetData, Clan, Corp, AffiliationRole, DBVersionType } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import * as dataSelector from './../../../selectors/datas.selectors';
import * as characterSelector from './../../../selectors/character.selectors';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { map } from 'rxjs/operators';
import { IncrementalService } from 'src/app/services/incremental.service';

// Utilities
@Component({
  selector: 'uffici-bank-component',
  templateUrl: './uffici-bank.component.html',
  styleUrls: ['./uffici-bank.component.less']
})

export class UfficiBankComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly moduleName: string = "UfficiBankComponent";

  bForm = new FormGroup({});
  subscription: Subscription;
  allDestinatari$: Observable<UserEntry[]>;

  public characterSheet$: Observable<CharacterSheetData>;
  private characterSheet: CharacterSheetData;
  private characterSheetSubscription: Subscription;

  private allClans$: Observable<Clan[]>;
  private allClansSubscription: Subscription;
  private allClans: Clan[] = [];
  public clanMoney: number = 0;

  private allClorps$: Observable<Corp[]>;
  private allCorpsSubscription: Subscription;
  private allCorps: Corp[] = [];
  public corpMoney: number = 0;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private firestoreService: FirestoreService,
    private logsService: LogsService,
    private incrementalService: IncrementalService
  ) {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.clans, true);

    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.corps, true);
  }

  public ngOnInit() {
    this.characterSheet$ = this.store.select(characterSelector.getCharacterSheet);
    this.allDestinatari$ = this.store.select(dataSelector.getUsersList)
      .pipe(map((users: UserEntry[]) => {
        let filtered: UserEntry[] = users.filter((aUser: UserEntry) => aUser.uid != "@@@ALL@@@");
        filtered = filtered.sort(function (a, b) {
          var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });

        return filtered;
      }));

    const self: this = this;
    this.characterSheetSubscription = this.characterSheet$.subscribe((characterSheet: CharacterSheetData) => {
      self.characterSheet = characterSheet;
      self.mergeStipendi();
    });

    this.allClans$ = this.store.select(dataSelector.getClansList);
    this.allClansSubscription = this.allClans$.subscribe((allClans: Clan[]) => {
      self.allClans = allClans;
      self.mergeStipendi();
    });

    this.allClorps$ = this.store.select(dataSelector.getCorpsList);
    this.allCorpsSubscription = this.allClorps$.subscribe((allCorps: Corp[]) => {
      self.allCorps = allCorps;
      self.mergeStipendi();
    });

    this.buildForm();
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.characterSheetSubscription) == true) {
      this.characterSheetSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.allClansSubscription) == true) {
      this.allClansSubscription.unsubscribe();
    }

    if (Functions.IsNullOrUndefined(this.allCorpsSubscription) == true) {
      this.allCorpsSubscription.unsubscribe();
    }
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  private mergeStipendi() {
    if (Functions.IsNullOrUndefined(this.characterSheet))
      return;
    if (Functions.IsNullOrUndefined(this.allClans))
      return;
    if (Functions.IsNullOrUndefined(this.allCorps))
      return;

    const myClan: Clan = this.allClans.find((aClan: Clan) => aClan.uid == this.characterSheet.clan);
    const myCorp: Clan = this.allCorps.find((aCorp: Corp) => aCorp.uid == this.characterSheet.corp);

    if (Functions.IsNullOrUndefined(myClan) == false) {
      const myClanRole: AffiliationRole = myClan.roles.find((aRole: AffiliationRole) => aRole.roleGrade == this.characterSheet.clanLvl);
      this.clanMoney = myClanRole.money || 0;
    }

    if (Functions.IsNullOrUndefined(myCorp) == false) {
      const myCorpRole: AffiliationRole = myCorp.roles.find((aRole: AffiliationRole) => aRole.roleGrade == this.characterSheet.corpLvl);
      this.corpMoney = myCorpRole.money || 0;
    }
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleCambiaNomeAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

  // used to create form structure
  private buildForm() {
    this.bForm = this.fb.group({
      'depos': ['', [
        Validators.min(1)
      ]],
      'prel': ['', [
        Validators.min(1)
      ]],
      'trans': ['', [
        Validators.min(1)
      ]],
      'destinatario': [undefined]
    });
  }

  public trasferisci() {
    const qta: string = this.bForm.value['trans'];
    let parsedQta: number = parseInt(qta.toString());

    if (Functions.IsNullOrUndefined(this.bForm.value['destinatario']) == true || this.bForm.value['destinatario'].lenght <= 0) {
      return;
    }

    if (Functions.IsNullOrUndefined(parsedQta) || Number.isNaN(parsedQta) || parsedQta <= 0)
      return;

    const target: UserEntry = this.bForm.value['destinatario'][0];
    const myCharacterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    if (myCharacterSheet.bank < parsedQta) {
      parsedQta = myCharacterSheet.bank;
    }

    if (Functions.IsNullOrUndefined(parsedQta) || parsedQta == NaN || parsedQta <= 0 || Functions.IsNullOrUndefined(target))
      return;

    this.firestoreService.transferDenaro(parsedQta, target.uid);
    this.buildForm();
  }

  public preleva() {
    const qta: string = this.bForm.value['prel'];
    let parsedQta: number = parseInt(qta.toString());

    if (Functions.IsNullOrUndefined(parsedQta) || Number.isNaN(parsedQta))
      return;

    const myCharacterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    if (myCharacterSheet.bank > parsedQta) {
      myCharacterSheet.bank = myCharacterSheet.bank - parsedQta;
      myCharacterSheet.money = (myCharacterSheet.money || 0) + parsedQta;
    } else {
      parsedQta = myCharacterSheet.bank;
      myCharacterSheet.bank = 0;
      myCharacterSheet.money = (myCharacterSheet.money || 0) + parsedQta;
    }
    this.firestoreService.updateCharacterSheet(myCharacterSheet.uid, myCharacterSheet, false);
    this.logsService.logBank("", myCharacterSheet.name, parsedQta.toString(), "Prelievo");
    this.buildForm();
  }

  public deposita() {
    const qta: string = this.bForm.value['depos'];
    let parsedQta: number = parseInt(qta.toString());

    if (Functions.IsNullOrUndefined(parsedQta) || Number.isNaN(parsedQta))
      return;

    const myCharacterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    if (myCharacterSheet.money > parsedQta) {
      myCharacterSheet.money = myCharacterSheet.money - parsedQta;
      myCharacterSheet.bank = (myCharacterSheet.bank || 0) + parsedQta;
    } else {
      parsedQta = myCharacterSheet.money;
      myCharacterSheet.money = 0;
      myCharacterSheet.bank = (myCharacterSheet.bank || 0) + parsedQta;
    }
    this.firestoreService.updateCharacterSheet(myCharacterSheet.uid, myCharacterSheet, false);
    this.logsService.logBank("", myCharacterSheet.name, parsedQta.toString(), "Deposito");
    this.buildForm();
  }

}
