import { Functions } from './../../../utilities/functions/utilities.functions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DBVersionType } from '../../../../models/data/application.data';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-comandi-avanzati',
  templateUrl: 'cPanel-comandi-avanzati.component.html',
  styleUrls: ['cPanel-comandi-avanzati.component.less'],
})
export class CPanelComandiAvanzatiComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelComandiAvanzati";
  private regDoc: AngularFirestoreDocument<any>;
  private regObservable: Observable<any>;
  private regSubscription: Subscription;
  public regValue: boolean;

  constructor(
    private cPanelService: CPanelService,
    private toastr: ToastrService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
    const self: this = this;
    this.regDoc = this.afs.doc<any>('settings/regBlocked');
    this.regObservable = this.regDoc.valueChanges();
    this.regSubscription = this.regObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ block cPanel", self.moduleName, "settings/regBlocked", 1);
      }

      if (Functions.IsNullOrUndefined(data) == false)
        self.regValue = data.value;
    });
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.regSubscription) == false) {
      this.regSubscription.unsubscribe();
    }
  }

  invalid(type: DBVersionType) {
    this.cPanelService.invalidCacheOnClients(type);
  }

  registrazioniBloccateChange(event: any) {
    const newValue: boolean = event.currentTarget.checked; //returnValue;

    let self = this;
    let regBlockValue = JSON.parse(JSON.stringify({ value: newValue }));
    this.regDoc.update(regBlockValue)
      .then(() => {
        if (newValue)
          self.toastr.success('Registrazioni Bloccate!');
        else
          self.toastr.success('Registrazioni Aperte!');
      })
      .catch((error: any) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      })

    // this.firestore.write('settings/regBlocked', regBlockValue).subscribe(() => {
    //   if (newValue)
    //     self.toastr.success('Registrazioni Bloccate!');
    //   else
    //     self.toastr.success('Registrazioni Aperte!');
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }
}
