import { FirestoreService } from './../../../services/firestore.service';
import { CharacterSheetData, Race, Role, UserPresence, Stats } from './../../../models/data/application.data';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as characterSelector from './../../../selectors/character.selectors';
import { Subscription } from 'rxjs';
import { CharacterSheetUIState } from '../../../modules/utilities/utilities.constants';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import * as layout from './../../../actions/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogsService } from '../../../services/logs.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, first } from 'rxjs/operators';


// Utilities
@Component({
  selector: 'edit-scheda-personaggio-component',
  templateUrl: './edit-scheda-personaggio.component.html',
  styleUrls: ['./edit-scheda-personaggio.component.less']
})

export class EditSchedaPersonaggioComponent implements OnInit, OnDestroy {
  private readonly moduleName: string = "EditSchedaPersonaggioComponent";
  public selectedCharacterSheet$: Observable<any>;
  public selectedCharacterSheetSubscription: Subscription;
  public selectedCharacter: CharacterSheetData;
  pgForm = new FormGroup({});

  public pointLeft: number = 0;
  public canNatureBeChnaged: boolean = false;
  public canDMBeChnaged: boolean = false;

  public newPass: string = "";
  public confNewPass: string = "";
  public newPassErr: string = "";

  public stats: Stats = new Stats();

  @Output() saveEmit: EventEmitter<any> = new EventEmitter();

  public changingMusic: boolean = false;
  public musicFileToUpload: File;
  public uploadingMusic: boolean = false;

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private firestoreService: FirestoreService,
    private logsService: LogsService,
    private authenticationService: AuthenticationService,
    private storage: AngularFireStorage
  ) { }

  public ngOnInit() {
    this.buildForm();
    let self = this;

    this.selectedCharacterSheet$ = this.store.select(characterSelector.getComputedSelectedPlayer);
    this.selectedCharacterSheetSubscription = this.selectedCharacterSheet$.subscribe((selectedCharacter: any) => {

      // set up last access
      const usersPresence: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
      const currentPresence: UserPresence = usersPresence.find((aUSer: UserPresence) => Functions.IsNullOrUndefined(aUSer.state) == false && aUSer.state.playerUID == selectedCharacter.baseData.uid);
      if (Functions.IsNullOrUndefined(currentPresence) == false) {
        selectedCharacter.baseData.access = currentPresence.state.lastAccess;
      }

      self.selectedCharacter = selectedCharacter.baseData;

      if (self.selectedCharacter.inited == false) {
        self.pointLeft = self.selectedCharacter.leftPoint || 5;
      } else {
        self.pointLeft = self.selectedCharacter.leftPoint;
      }

      self.setUpForm();
    });
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.selectedCharacterSheetSubscription) == false)
      this.selectedCharacterSheetSubscription.unsubscribe();
  }

  // used to create form structure
  private buildForm() {
    this.pgForm = this.fb.group({
      'cognome': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'nick': ['', [
        Validators.minLength(0),
        Validators.maxLength(20)
      ]],
      'natura': [''],
      'allineamento': [''],
      'marStatus': [''],
      'age': ['', [
        Validators.required
      ]],
      'realAge': ['', [
        Validators.required
      ]],
      'gcomp': ['', [
        Validators.required
      ]],
      'mcomp': ['', [
        Validators.required
      ]],
      'acomp': ['', [
        Validators.required
      ]],
      'eyes': [''],
      'peso': ['', [
        Validators.required,
      ]],
      'altezza': ['', [
        Validators.required,
      ]],
      'hair': [''],
      'job': [''],
      'HAvatar': [''],
      'VAvatar': [''],
      'MAvatar': [''],
      'TAvatar': [''],
      'musica': [''],
      'DMNote': [''],
      'allSoundDisabled': [''],
      'onlyMessageSoundDisable': [''],
      'sendEnter': [''],
      // 'str': ['', [
      //   Validators.required
      // ]],
      // 'agl': ['', [
      //   Validators.required
      // ]],
      // 'res': ['', [
      //   Validators.required
      // ]],
      // 'wsd': ['', [
      //   Validators.required
      // ]],
      // 'per': ['', [
      //   Validators.required
      // ]],
      // 'wil': ['', [
      //   Validators.required
      // ]],
      'bck': [''],
    });
  }

  // used to create form structure
  private setUpForm() {

    if (Functions.IsNullOrUndefined(this.selectedCharacter))
      return;

    if (Functions.IsNullOrUndefined(this.selectedCharacter.stats)
      || Functions.IsNullOrUndefined(this.selectedCharacter.sex)
      || Functions.IsNullOrUndefined(this.selectedCharacter.race)
      || Functions.IsNullOrUndefined(this.selectedCharacter.role)
    )
      return;


    let gcomp: string = "";
    let mcomp: string = "";
    let acomp: string = "";
    const birtdate: string = this.selectedCharacter.birthdate;
    if (Functions.IsStringEmpty(birtdate) == false) {
      let splitted: string[] = birtdate.split("/");
      gcomp = splitted[0];
      mcomp = splitted[1];
      acomp = splitted[2];
    }

    if (Functions.IsStringEmpty(this.selectedCharacter.nature))
      this.canNatureBeChnaged = true;

    if (fromRoot.getState(this.store).character.myCharacterData.role <= Role.master)
      this.canDMBeChnaged = true

    let isSoundEnabled: boolean = fromRoot.getState(this.store).character.myCharacterData.soundEnabled;
    if (Functions.IsNullOrUndefined(isSoundEnabled)) {
      isSoundEnabled = true;
    }

    let messageSoundDisabled: boolean = fromRoot.getState(this.store).character.myCharacterData.messageSoundDisabled;
    if (Functions.IsNullOrUndefined(messageSoundDisabled)) {
      messageSoundDisabled = false;
    }

    let isSendEnter: boolean = fromRoot.getState(this.store).character.myCharacterData.sendEnter;
    if (Functions.IsNullOrUndefined(isSendEnter)) {
      isSendEnter = true;
    }

    this.pgForm = this.fb.group({
      'cognome': [this.selectedCharacter.sur, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ]],
      'nick': [this.selectedCharacter.nick, [
        Validators.maxLength(20)
      ]],
      'natura': [{ value: this.selectedCharacter.nature, disabled: this.canNatureBeChnaged == false }],
      'allineamento': [this.selectedCharacter.allinment],
      'marStatus': [this.selectedCharacter.marStatus],
      'age': [this.selectedCharacter.age, [
        Validators.required
      ]],
      'realAge': [this.selectedCharacter.realAge, [
        Validators.required
      ]],
      'gcomp': [gcomp, [
        Validators.min(1),
        Validators.max(31)
      ]],
      'mcomp': [mcomp, [
        Validators.min(1),
        Validators.max(12)
      ]],
      'acomp': [acomp, [
        Validators.maxLength(4)
      ]],
      'eyes': [this.selectedCharacter.eyes],
      'peso': [this.selectedCharacter.weight],
      'altezza': [this.selectedCharacter.height],
      'hair': [this.selectedCharacter.hair],
      'job': [this.selectedCharacter.job],
      'HAvatar': [this.selectedCharacter.HAvatar],
      'VAvatar': [this.selectedCharacter.VAvatar],
      'MAvatar': [this.selectedCharacter.MAvatar],
      'TAvatar': [this.selectedCharacter.TAvatar],
      'musica': [{ value: (this.selectedCharacter.music || ""), disabled: false }],
      // 'soundEnabled': [isSoundEnabled],
      'allSoundDisabled': [!isSoundEnabled],
      'messageSoundDisabled': [messageSoundDisabled],
      'sendEnter': [isSendEnter],
      'DMNote': [{ value: this.selectedCharacter.DMNote, disabled: fromRoot.getState(this.store).character.myCharacterData.role > Role.master }],
      // 'str': [this.selectedCharacter.stats.str, [
      //   Validators.required
      // ]],
      // 'agl': [this.selectedCharacter.stats.agl, [
      //   Validators.required
      // ]],
      // 'res': [this.selectedCharacter.stats.res, [
      //   Validators.required
      // ]],
      // 'wsd': [this.selectedCharacter.stats.wsd, [
      //   Validators.required
      // ]],
      // 'per': [this.selectedCharacter.stats.per, [
      //   Validators.required
      // ]],
      // 'wil': [this.selectedCharacter.stats.wil, [
      //   Validators.required
      // ]],
      'bck': [this.selectedCharacter.backg]
    });

    this.stats = new Stats();
    this.stats.str = this.selectedCharacter.stats.str;
    this.stats.agl = this.selectedCharacter.stats.agl;
    this.stats.res = this.selectedCharacter.stats.res;
    this.stats.wsd = this.selectedCharacter.stats.wsd;
    this.stats.per = this.selectedCharacter.stats.per;
    this.stats.wil = this.selectedCharacter.stats.wil;
  }


  confermaTutto() {
    let updatedSheet: CharacterSheetData = Object.assign({}, this.selectedCharacter);
    updatedSheet.stats = Object.assign({}, updatedSheet.stats);

    // updatedSheet.stats.str = this.pgForm.value['str'];
    // updatedSheet.stats.agl = this.pgForm.value['agl'];
    // updatedSheet.stats.res = this.pgForm.value['res'];
    // updatedSheet.stats.wsd = this.pgForm.value['wsd'];
    // updatedSheet.stats.per = this.pgForm.value['per'];
    // updatedSheet.stats.wil = this.pgForm.value['wil'];
    updatedSheet.stats.str = this.stats.str;
    updatedSheet.stats.agl = this.stats.agl;
    updatedSheet.stats.res = this.stats.res;
    updatedSheet.stats.wsd = this.stats.wsd;
    updatedSheet.stats.per = this.stats.per;
    updatedSheet.stats.wil = this.stats.wil;
    updatedSheet.leftPoint = this.pointLeft;
    if (this.pointLeft <= 0 && updatedSheet.inited == false) {
      updatedSheet.inited = true;
    }

    updatedSheet.HAvatar = this.pgForm.value['HAvatar'];
    updatedSheet.VAvatar = this.pgForm.value['VAvatar'];
    updatedSheet.MAvatar = this.pgForm.value['MAvatar'];
    updatedSheet.TAvatar = this.pgForm.value['TAvatar'];
    updatedSheet.music = this.pgForm.value['musica'];
    updatedSheet.soundEnabled = !this.pgForm.value['allSoundDisabled'];
    updatedSheet.messageSoundDisabled = this.pgForm.value['messageSoundDisabled'];
    updatedSheet.sendEnter = this.pgForm.value['sendEnter'];

    updatedSheet.sur = this.pgForm.value['cognome'];
    updatedSheet.nick = this.pgForm.value['nick'];
    if (this.canNatureBeChnaged)
      updatedSheet.nature = this.pgForm.value['natura'];
    updatedSheet.allinment = this.pgForm.value['allineamento'];
    updatedSheet.marStatus = this.pgForm.value['marStatus'];
    updatedSheet.age = this.pgForm.value['age'];
    updatedSheet.realAge = this.pgForm.value['realAge'];
    updatedSheet.eyes = this.pgForm.value['eyes'];
    updatedSheet.weight = this.pgForm.value['peso'];
    updatedSheet.height = this.pgForm.value['altezza'];
    updatedSheet.hair = this.pgForm.value['hair'];
    updatedSheet.job = this.pgForm.value['job'];

    let computedgcomp: string;
    let computedMcomp: string;
    let computedAcomp: string;
    if (Functions.IsNullOrUndefined(this.pgForm.value['gcomp']) || this.pgForm.value['gcomp'] == "null") {
      computedgcomp = "";
    } else {
      computedgcomp = this.pgForm.value['gcomp'].toString();
      if (computedgcomp.length == 1) {
        computedgcomp = "0" + computedgcomp;
      }
    }
    if (Functions.IsNullOrUndefined(this.pgForm.value['mcomp']) || this.pgForm.value['mcomp'] == "null") {
      computedMcomp = "";
    } else {
      computedMcomp = this.pgForm.value['mcomp'].toString();
      if (computedMcomp.length == 1) {
        computedMcomp = "0" + computedMcomp;
      }
    }
    if (Functions.IsNullOrUndefined(this.pgForm.value['acomp']) || this.pgForm.value['acomp'] == "null") {
      computedAcomp = "";
    } else {
      computedAcomp = this.pgForm.value['acomp'].toString();
    }

    updatedSheet.birthdate = computedgcomp + "/" + computedMcomp + "/" + computedAcomp;

    updatedSheet.backg = this.pgForm.value['bck'];

    if (this.canDMBeChnaged)
      updatedSheet.DMNote = this.pgForm.value['DMNote'];

    this.firestoreService.updateCharacterSheet(updatedSheet.uid, updatedSheet, false);
    this.saveEmit.emit(); // to go back to base sheet
  }

  addPoint(statsId: string) {
    if (this.pointLeft <= 0)
      return;

    this.stats[statsId] = this.stats[statsId] + 1;
    // this.pgForm.value[statsId] = this.pgForm.value[statsId] + 1;
    this.pointLeft--;
  }

  removePoint(statsId: string) {
    let currentStats: number = 0;
    switch (statsId) {
      case "str":
        currentStats = this.selectedCharacter.stats.str;
        break;

      case "agl":
        currentStats = this.selectedCharacter.stats.agl;
        break;

      case "res":
        currentStats = this.selectedCharacter.stats.res;
        break;

      case "wsd":
        currentStats = this.selectedCharacter.stats.wsd;
        break;

      case "per":
        currentStats = this.selectedCharacter.stats.per;
        break;

      case "wil":
        currentStats = this.selectedCharacter.stats.wil;
        break;

      default:
        return;
    }

    if ((this.selectedCharacter.inited == true) && (this.stats[statsId] - 1) < currentStats)
      return

    if ((this.stats[statsId] - 1) <= 0)
      return

    this.stats[statsId] = this.stats[statsId] - 1;
    this.pointLeft++;

  }

  buyPoint() {
    let updatedSheet: CharacterSheetData = Object.assign({}, this.selectedCharacter);
    const oldValueXP: number = updatedSheet.usablePx;

    // updating sheet
    updatedSheet.usablePx = parseInt(updatedSheet.usablePx.toString()) - 400;
    updatedSheet.leftPoint = parseInt((updatedSheet.leftPoint || 0).toString()) + 1;
    updatedSheet.stasBought = (updatedSheet.stasBought || 0) + 1;
    this.firestoreService.updateCharacterSheet(updatedSheet.uid, updatedSheet, false);

    // updating logs
    this.logsService.logXP("", updatedSheet.name, (oldValueXP || 0).toString(), "-" + 400, "Acquisto punto statistica");
  }

  cambiaPassword() {
    // safety check
    if (Functions.IsStringEmpty(this.newPass) || Functions.IsStringEmpty(this.confNewPass)) {
      this.newPassErr = "La nuova password non può essere nulla.";
      return;
    }

    // safety check
    if (this.newPass != this.confNewPass) {
      this.newPassErr = "La nuova password è diversa dalla conferma password.";
      return;
    }

    // check validators
    if (this.newPass.length < 8 || this.newPass.length > 20) {
      this.newPassErr = "La nuova password deve essere di almeno 8 caratterti e non più di 20 caratteri.";
      return;
    }

    if (/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(this.newPass) == false) {
      this.newPassErr = "La nuova password deve contenere lettere maiuscole, minuscole e numeri";
      return;
    }

    // change password
    this.authenticationService.changePassword(this.newPass);

    this.newPass = "";
    this.confNewPass = "";
    this.newPassErr = "";

    this.saveEmit.emit(); // to go back to base sheet
  }

  public handleFileInput(files: FileList) {
    this.musicFileToUpload = files.item(0);
  }

  public confirmUpload() {
    if (Functions.IsNullOrUndefined(this.musicFileToUpload) == true)
      return;

    //#region - check if format and size is ok
    const fileSize = this.musicFileToUpload.size / 1024 / 1024; // in MB
    if (fileSize > 15) {
      alert("Il file eccede il limite consentito (15 MB), scegliere un file meno pesante.");
      return;
    }

    if (this.musicFileToUpload.type.includes("audio") == false) {
      alert("Il formato del file non è valido, scegliere un file diverso.");
      return;
    }
    //#endregion - check if format and size is ok

    const self: this = this;
    this.uploadingMusic = true;
    const filePath: string = '/music/' + this.selectedCharacter.uid;
    const fileRef = this.storage.ref(filePath);
    const metadata: firebase.storage.UploadMetadata = {
      cacheControl: 'max-age=172800'
    }

    const uploadTask = fileRef.put(this.musicFileToUpload, metadata)
      .then((value: any) => {
        const subscription = fileRef.getDownloadURL()
          .pipe(first())
          .subscribe((value) => {
            self.pgForm.patchValue({ musica: value });
            self.changingMusic = false;
            self.musicFileToUpload = undefined;
            setTimeout(() => {
              self.uploadingMusic = false;
            }, 2000);
          });
      });
  }

}
