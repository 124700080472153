import { DBVersionIndex } from './../../../models/data/application.data';
import { Functions } from './../../utilities/functions/utilities.functions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as datas from '../../../actions/datas';
import { Subscription, Observable } from 'rxjs';
import { NgForage } from 'ngforage';
import { DBVersioning, DBVersionType } from '../../../models/data/application.data';
import { ToastrService } from 'ngx-toastr';
import { CPanelState } from '../../utilities/utilities.constants';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';

@Injectable()
export class CPanelService {

  //#region - cache variables
  public pendingSkillsChanges: string[] = [];
  public pendingItemsChanges: string[] = [];
  public pendingBMChanges: string[] = [];

  public pendingClansChanges: string[] = [];
  public pendingCorpsChanges: string[] = [];
  public pendingVoltiChanges: string[] = [];

  public pendingRulesChanges: string[] = [];
  public pendingLocationsChanges: string[] = [];

  public pendingSpecialChatsChanges: string[] = [];
  public pendingStandardChatsChanges: string[] = [];
  //#endregion - cache variables

  //#region - get data
  private usersSubscription: Subscription;
  private tempSubscription: Subscription;
  //#endregion - get data

  public waiting: boolean = false;

  constructor(
    private store: Store<fromRoot.State>,
    private ngf: NgForage,
    private toastr: ToastrService,
    private afs: AngularFirestore
  ) {

  }

  public logoutReset() {
    if (Functions.IsNullOrUndefined(this.tempSubscription) == false) {
      this.tempSubscription.unsubscribe();
      this.tempSubscription = undefined;
    }

    if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
      this.usersSubscription.unsubscribe();
      this.usersSubscription = undefined;
    }
  }

  // public checkPermissions(cPanelState: CPanelState): Observable<boolean> {
  //   if (Functions.IsNullOrUndefined(this.tempSubscription) == false)
  //     this.tempSubscription.unsubscribe();

  //   const roleRequirement: any = this.permissionLevelRequired(cPanelState);
  //   const self: this = this;

  //   return this.afAuth.authState
  //     // return this.auth.uid()
  //     .pipe(mergeMap((userAuth: User) => self.afs.doc<any>('users/' + userAuth.uid).valueChanges()),
  //     // .mergeMap(uid => self.firestore.read('users/' + uid))
  //     // .skip(1)
  //     map((aSheet: CharacterSheetData) => {

  //       if (self != undefined && self.isAuditing)
  //         console.log('READ user permission cPanel');

  //       if (Functions.IsNullOrUndefined(aSheet) || Functions.IsNullOrUndefined(aSheet.role)) {
  //         return false;
  //       }

  //       if (aSheet.role <= roleRequirement.roleRequired) {
  //         return true;
  //       } else {

  //         if (roleRequirement.capoClan) {
  //           return aSheet.isCapoClan;
  //         }

  //         if (roleRequirement.capoCorp) {
  //           return aSheet.isCapoCorp;
  //         }

  //         if (roleRequirement.capoRace && Functions.IsNullOrUndefined(aSheet.isCapoRace)) {
  //           return aSheet.isCapoRace.length > 0;
  //         }
  //         return false;
  //       }
  //     }));
  //   // .subscribe(res => this.contract = res);

  //   // this.tempSubscription = this.auth.uid().subscribe((uid: string) => {
  //   //   if (Functions.IsStringEmpty(uid) == false) {

  //   //     self.firestore.read('users/' + uid).skip(1).subscribe((aSheet: CharacterSheetData) => {
  //   //       if (aSheet.role <= roleRequirement.roleRequired) {
  //   //         return true;
  //   //       } else {

  //   //         if (roleRequirement.capoClan) {
  //   //           return aSheet.isCapoClan;
  //   //         }

  //   //         if (roleRequirement.capoCorp) {
  //   //           return aSheet.isCapoCorp;
  //   //         }

  //   //         if (roleRequirement.capoRace && Functions.IsNullOrUndefined(aSheet.isCapoRace)) {
  //   //           return aSheet.isCapoRace.length > 0;
  //   //         }
  //   //         return false;
  //   //       }
  //   //     });
  //   //     return Observable.empty();

  //   //   } else {
  //   //     return Observable.of(false);
  //   //   }
  //   // });

  //   // return Observable.empty();
  // }

  public permissionLevelRequired(cPanelState: CPanelState) {
    switch (cPanelState) {
      case CPanelState.LogChat:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.LogMessaggi:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.ModificaCredits:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.ModificaExp:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.ModificaMeteo:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestioneStaffNews:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.CaricheStaff:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.EventiCalendario:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.ModificaDisclaimerSignup:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestioneRegolamento:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestioneChat:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.CreaPersonaggio:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.ModificaPersonaggio:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestioneVoltoPersonaggioMaschile:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestioneVoltoPersonaggioFemminile:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.EsiliaRiammettiPersonaggio:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.EliminaPersonaggio:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestisciOggetti:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.AssegnaOggetto:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestisciSkill:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.AssegnaSkill:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestisciBM:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.AssegnaBM:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: true };
      case CPanelState.AssegnaRazza:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: true };
      case CPanelState.GestisciClan:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.AssegnaClan:
        return { roleRequired: 0, capoClan: true, capoCorp: false, capoRace: false };
      case CPanelState.GestisciCorp:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.AssegnaCorp:
        return { roleRequired: 0, capoClan: false, capoCorp: true, capoRace: false };
      case CPanelState.ComandiAvanzati:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.AggiungiRimuoviMoney:
        return { roleRequired: 2, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.logPX:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.logOgg:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.logBank:
        return { roleRequired: 1, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.Startingmsg:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.Citazioni:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.Location:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.JobMoneyManager:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.TriggerManager:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
      case CPanelState.GestisciSignUpForm:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };

      default:
        return { roleRequired: 0, capoClan: false, capoCorp: false, capoRace: false };
    }
  }

  public writeOnDB(url: string, uid: string, object: any) {
    const self: this = this;

    this.afs.doc(url + '/' + uid).set(object)
      .then(() => {
        self.toastr.success('Modifica Salvata!');
        if (url == "skills") {
          self.addIdToDBVersionChanges(uid, DBVersionType.skills);
        } else if (url == "items") {
          self.addIdToDBVersionChanges(uid, DBVersionType.items);
        } else if (url == "bonusMalus") {
          self.addIdToDBVersionChanges(uid, DBVersionType.bm);
        } else if (url == "clans") {
          self.addIdToDBVersionChanges(uid, DBVersionType.clans);
        } else if (url == "corps") {
          self.addIdToDBVersionChanges(uid, DBVersionType.corps);
        } else if (url == "volti") {
          self.addIdToDBVersionChanges(uid, DBVersionType.volti);
        } else if (url == "rules") {
          self.addIdToDBVersionChanges(uid, DBVersionType.rules);
        } else if (url == "locations") {
          self.addIdToDBVersionChanges(uid, DBVersionType.locations);
        } else if (url == "standardChat") {
          self.addIdToDBVersionChanges(uid, DBVersionType.standardChats);
        } else if (url == "specialChat") {
          self.addIdToDBVersionChanges(uid, DBVersionType.specialChats);
        }
      })
      .catch((error) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });


    // this.firestore.write(url + '/' + uid, object).subscribe(() => {
    //   self.toastr.success('Modifica Salvata!');
    //   if (url == "skills") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.skills);
    //   } else if (url == "items") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.items);
    //   } else if (url == "bonusMalus") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.bm);
    //   } else if (url == "clans") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.clans);
    //   } else if (url == "corps") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.corps);
    //   } else if (url == "volti") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.volti);
    //   } else if (url == "rules") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.rules);
    //   } else if (url == "locations") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.locations);
    //   } else if (url == "standardChat") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.standardChats);
    //   } else if (url == "specialChat") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.specialChats);
    //   }
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public removeDB(url: string, uid: string) {
    const self: this = this;

    this.afs.doc(url + '/' + uid).delete()
      .then(() => {
        self.toastr.success('Elemento Eliminato!');
        if (url == "skills") {
          // self.deleteSkillReferenceFromAllPlayer(uid);
          self.addIdToDBVersionChanges(uid, DBVersionType.skills);
        } else if (url == "items") {
          self.addIdToDBVersionChanges(uid, DBVersionType.items);
        } else if (url == "bonusMalus") {
          self.addIdToDBVersionChanges(uid, DBVersionType.bm);
        } else if (url == "clans") {
          self.addIdToDBVersionChanges(uid, DBVersionType.clans);
        } else if (url == "corps") {
          self.addIdToDBVersionChanges(uid, DBVersionType.corps);
        } else if (url == "volti") {
          self.addIdToDBVersionChanges(uid, DBVersionType.volti);
        } else if (url == "rules") {
          self.addIdToDBVersionChanges(uid, DBVersionType.rules);
        } else if (url == "locations") {
          self.addIdToDBVersionChanges(uid, DBVersionType.locations);
        } else if (url == "standardChat") {
          self.addIdToDBVersionChanges(uid, DBVersionType.standardChats);
        } else if (url == "specialChat") {
          self.addIdToDBVersionChanges(uid, DBVersionType.specialChats);
        }
      })
      .catch((error) => {
        self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      });

    // this.firestore.remove(url + '/' + uid).subscribe(() => {
    //   self.toastr.success('Elemento Eliminato!');
    //   if (url == "skills") {
    //     self.deleteSkillReferenceFromAllPlayer(uid);
    //     self.addIdToDBVersionChanges(uid, DBVersionType.skills);
    //   } else if (url == "items") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.items);
    //   } else if (url == "bonusMalus") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.bm);
    //   } else if (url == "clans") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.clans);
    //   } else if (url == "corps") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.corps);
    //   } else if (url == "volti") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.volti);
    //   } else if (url == "rules") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.rules);
    //   } else if (url == "locations") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.locations);
    //   } else if (url == "standardChat") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.standardChats);
    //   } else if (url == "specialChat") {
    //     self.addIdToDBVersionChanges(uid, DBVersionType.specialChats);
    //   }
    // }, (error) => {
    //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
    // });
  }

  public addIdToDBVersionChanges(uid: string, type: DBVersionType) {
    switch (type) {
      case DBVersionType.skills:
        this.pendingSkillsChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        // this.needCommit = true;
        break;
      case DBVersionType.items:
        this.pendingItemsChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        // this.needCommit = true;
        break;
      case DBVersionType.bm:
        this.pendingBMChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
      case DBVersionType.clans:
        this.pendingClansChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
      case DBVersionType.corps:
        this.pendingCorpsChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
      case DBVersionType.volti:
        this.pendingVoltiChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
      case DBVersionType.rules:
        this.pendingRulesChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
      case DBVersionType.locations:
        this.pendingLocationsChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
      case DBVersionType.standardChats:
        this.pendingStandardChatsChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
      case DBVersionType.specialChats:
        this.pendingSpecialChatsChanges.push(uid);
        this.store.dispatch(new datas.SetPendingCpUpdates(true));
        break;
    }
  }

  public updateDBVersion() {
    const self: this = this;

    const dbVersioningIndexDoc: AngularFirestoreDocument<DBVersionIndex> = this.afs.doc('dbVersioning/dbVersionIndex');
    const dbVersioningIndexDocObservable: Observable<DBVersionIndex> = dbVersioningIndexDoc.valueChanges();
    const dbVersioningIndexDocSubscription: Subscription = dbVersioningIndexDocObservable.pipe(first())
      .subscribe((DBVersioning: DBVersionIndex) => {

        const currentDBVersioning: DBVersionIndex = Object.assign({}, DBVersioning);
        for (let index: number = 0; index < currentDBVersioning.versions.length; index++) {
          const aDBVersionArea: DBVersioning = currentDBVersioning.versions[index];
          const areaPendingChanges: string[] = this.getPendingsFromType(aDBVersionArea.type);

          if (areaPendingChanges.length <= 0)
            continue;

          aDBVersionArea.version = aDBVersionArea.version + 1;
          if (Functions.IsNullOrUndefined(aDBVersionArea.vChanges)) {
            aDBVersionArea.vChanges = {};
          }

          const setPendingElements: Set<string> = new Set(areaPendingChanges);
          aDBVersionArea.vChanges[aDBVersionArea.version] = Array.from(setPendingElements);
        }

        self.pendingSkillsChanges = [];
        self.pendingItemsChanges = [];
        self.pendingBMChanges = [];
        self.pendingClansChanges = [];
        self.pendingCorpsChanges = [];
        self.pendingVoltiChanges = [];
        self.pendingRulesChanges = [];
        self.pendingLocationsChanges = [];
        self.pendingStandardChatsChanges = [];
        self.pendingSpecialChatsChanges = [];

        dbVersioningIndexDoc.set(currentDBVersioning)
          .then(() => {
            //OK!
            self.toastr.success("Modifiche rilasciate con successo");
            self.store.dispatch(new datas.SetPendingCpUpdates(false));
          })
          .catch((error) => {
            console.log("Error cache version update");
          });
      });

    //   //#region - items
    //   if (this.pendingItemsChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.items;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.itemsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingItems: Set<string> = new Set(this.pendingItemsChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingItems);
    //     // newDbVersion.vChanges = new Map();
    //     // newDbVersion.vChanges.set(newDbVersion.version, this.pendingItemsChanges);
    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     // let newDbVersionJSON = JSON.parse(JSON.stringify(newDbVersion));

    //     this.afs.doc('dbVersioning/items').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingItemsChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });


    //     // this.firestore.write('dbVersioning/items', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingItemsChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - skills
    //   if (this.pendingSkillsChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.skills;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.skillsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingSkills: Set<string> = new Set(this.pendingSkillsChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingSkills);

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);

    //     this.afs.doc('dbVersioning/skills').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingSkillsChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });


    //     // this.firestore.write('dbVersioning/skills', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingSkillsChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - bonus malus
    //   if (this.pendingBMChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.bm;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.bmsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingBM: Set<string> = new Set(this.pendingBMChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingBM);
    //     // newDbVersion.vChanges[newDbVersion.version] = this.pendingBMChanges;

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url
    //     this.afs.doc('dbVersioning/bms').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingBMChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });


    //     // this.firestore.write('dbVersioning/bms', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingBMChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - clans
    //   if (this.pendingClansChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.clans;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.clansV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingClans: Set<string> = new Set(this.pendingClansChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingClans);
    //     // newDbVersion.vChanges[newDbVersion.version] = this.pendingClansChanges;

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url
    //     this.afs.doc('dbVersioning/clans').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingClansChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });


    //     // this.firestore.write('dbVersioning/clans', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingClansChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - corps
    //   if (this.pendingCorpsChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.corps;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.corpsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingCorps: Set<string> = new Set(this.pendingCorpsChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingCorps);
    //     // newDbVersion.vChanges[newDbVersion.version] = this.pendingCorpsChanges;

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url
    //     this.afs.doc('dbVersioning/corps').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingCorpsChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/corps', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingCorpsChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - volti
    //   if (this.pendingVoltiChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.volti;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.voltiV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingVolti: Set<string> = new Set(this.pendingVoltiChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingVolti);
    //     // newDbVersion.vChanges[newDbVersion.version] = this.pendingVoltiChanges;

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url
    //     this.afs.doc('dbVersioning/volti').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingVoltiChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });


    //     // this.firestore.write('dbVersioning/volti', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingVoltiChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - rules
    //   if (this.pendingRulesChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.rules;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.rulesV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingRules: Set<string> = new Set(this.pendingRulesChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingRules);
    //     // newDbVersion.vChanges[newDbVersion.version] = this.pendingRulesChanges;

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url
    //     this.afs.doc('dbVersioning/rules').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingRulesChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });


    //     // this.firestore.write('dbVersioning/rules', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingRulesChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - locations
    //   if (this.pendingLocationsChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.locations;
    //     newDbVersion.version = fromRoot.getState(this.store).datas.locationsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingLocations: Set<string> = new Set(this.pendingLocationsChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingLocations);
    //     // newDbVersion.vChanges[newDbVersion.version] = this.pendingLocationsChanges;

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url
    //     this.afs.doc('dbVersioning/locations').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingLocationsChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/locations', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingLocationsChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - standard Chats
    //   if (this.pendingStandardChatsChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.standardChats;
    //     newDbVersion.version = fromRoot.getState(this.store).layout.standardChatsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingStandardChats: Set<string> = new Set(this.pendingStandardChatsChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingStandardChats);

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url
    //     this.afs.doc('dbVersioning/standardChats').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingStandardChatsChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/standardChats', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingStandardChatsChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion

    //   //#region - special Chats
    //   if (this.pendingSpecialChatsChanges.length > 0) {
    //     const newDbVersion: DBVersioning = new DBVersioning();
    //     newDbVersion.type = DBVersionType.specialChats;
    //     newDbVersion.version = fromRoot.getState(this.store).layout.specialChatsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersion.vChanges)) {
    //       newDbVersion.vChanges = {};
    //     }

    //     const setPendingSpecialChats: Set<string> = new Set(this.pendingSpecialChatsChanges);
    //     newDbVersion.vChanges[newDbVersion.version] = Array.from(setPendingSpecialChats);

    //     let newDbVersionOBJ = Object.assign({}, newDbVersion);
    //     //TODO: controllare url

    //     this.afs.doc('dbVersioning/specialChats').set(newDbVersionOBJ)
    //       .then(() => {
    //         //OK!
    //         self.pendingSpecialChatsChanges = [];
    //         if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //           && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //           && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //           && self.pendingSpecialChatsChanges.length <= 0
    //         ) {
    //           // self.needCommit = false;
    //           self.toastr.success("Modifiche rilasciate con successo");
    //           self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //         }
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/specialChats', newDbVersionOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.pendingSpecialChatsChanges = [];
    //     //   if (self.pendingSkillsChanges.length <= 0 && self.pendingItemsChanges.length <= 0 && self.pendingBMChanges.length <= 0
    //     //     && self.pendingClansChanges.length <= 0 && self.pendingCorpsChanges.length <= 0 && self.pendingVoltiChanges.length <= 0
    //     //     && self.pendingRulesChanges.length <= 0 && self.pendingLocationsChanges.length <= 0 && self.pendingStandardChatsChanges.length <= 0
    //     //     && self.pendingSpecialChatsChanges.length <= 0
    //     //   ) {
    //     //     // self.needCommit = false;
    //     //     self.toastr.success("Modifiche rilasciate con successo");
    //     //     self.store.dispatch(new datas.SetPendingCpUpdates(false));
    //     //   }
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //   }
    //   //#endregion
  }

  public invalidCacheOnClients(type: DBVersionType) {
    const self: this = this;

    const dbVersioningIndexDoc: AngularFirestoreDocument<DBVersionIndex> = this.afs.doc('dbVersioning/dbVersionIndex');
    const dbVersioningIndexDocObservable: Observable<DBVersionIndex> = dbVersioningIndexDoc.valueChanges();
    const dbVersioningIndexDocSubscription: Subscription = dbVersioningIndexDocObservable.pipe(first())
      .subscribe((DBVersioning: DBVersionIndex) => {

        const currentDBVersioning: DBVersionIndex = Object.assign({}, DBVersioning);
        for (let index: number = 0; index < currentDBVersioning.versions.length; index++) {
          const aDBVersionArea: DBVersioning = currentDBVersioning.versions[index];

          if (aDBVersionArea.type != type)
            continue;

          aDBVersionArea.version = aDBVersionArea.version + 1;
          if (Functions.IsNullOrUndefined(aDBVersionArea.vChanges)) {
            aDBVersionArea.vChanges = {};
          }

          aDBVersionArea.vChanges[aDBVersionArea.version] = [];
        }
        dbVersioningIndexDoc.set(currentDBVersioning)
          .then(() => {
            //OK!
            self.toastr.success("Cache Abilità eliminata con successo");
          })
          .catch((error) => {
            console.log("Error cache version update");
          });
      });




    // switch (type) {
    //   case DBVersionType.skills:
    //     //#region - skills case
    //     const newDbVersionSkill: DBVersioning = new DBVersioning();
    //     newDbVersionSkill.type = DBVersionType.skills;
    //     newDbVersionSkill.version = fromRoot.getState(this.store).datas.skillsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionSkill.vChanges)) {
    //       newDbVersionSkill.vChanges = {};
    //     }
    //     newDbVersionSkill.vChanges[newDbVersionSkill.version] = [];
    //     let newDbVersionSkillOBJ = Object.assign({}, newDbVersionSkill);

    //     this.afs.doc('dbVersioning/skills').set(newDbVersionSkillOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Abilità eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/skills', newDbVersionSkillOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Abilità eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.items:
    //     //#region - items case
    //     const newDbVersionItem: DBVersioning = new DBVersioning();
    //     newDbVersionItem.type = DBVersionType.items;
    //     newDbVersionItem.version = fromRoot.getState(this.store).datas.itemsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionItem.vChanges)) {
    //       newDbVersionItem.vChanges = {};
    //     }
    //     newDbVersionItem.vChanges[newDbVersionItem.version] = [];
    //     let newDbVersionItemOBJ = Object.assign({}, newDbVersionItem);

    //     this.afs.doc('dbVersioning/items').set(newDbVersionItemOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Oggetti eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/items', newDbVersionItemOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Oggetti eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.bm:
    //     //#region - bonus/malus case
    //     const newDbVersionBM: DBVersioning = new DBVersioning();
    //     newDbVersionBM.type = DBVersionType.bm;
    //     newDbVersionBM.version = fromRoot.getState(this.store).datas.bmsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionBM.vChanges)) {
    //       newDbVersionBM.vChanges = {};
    //     }
    //     newDbVersionBM.vChanges[newDbVersionBM.version] = [];
    //     let newDbVersionBMOBJ = Object.assign({}, newDbVersionBM);

    //     this.afs.doc('dbVersioning/bms').set(newDbVersionBMOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Bonus/Malus eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/bms', newDbVersionBMOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Bonus/Malus eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.clans:
    //     //#region - clans case
    //     const newDbVersionClans: DBVersioning = new DBVersioning();
    //     newDbVersionClans.type = DBVersionType.clans;
    //     newDbVersionClans.version = fromRoot.getState(this.store).datas.clansV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionClans.vChanges)) {
    //       newDbVersionClans.vChanges = {};
    //     }
    //     newDbVersionClans.vChanges[newDbVersionClans.version] = [];
    //     let newDbVersionClansOBJ = Object.assign({}, newDbVersionClans);

    //     this.afs.doc('dbVersioning/clans').set(newDbVersionClansOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache clans eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/clans', newDbVersionClansOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache clans eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.corps:
    //     //#region - corps case
    //     const newDbVersionCorpss: DBVersioning = new DBVersioning();
    //     newDbVersionCorpss.type = DBVersionType.corps;
    //     newDbVersionCorpss.version = fromRoot.getState(this.store).datas.corpsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionCorpss.vChanges)) {
    //       newDbVersionCorpss.vChanges = {};
    //     }
    //     newDbVersionCorpss.vChanges[newDbVersionCorpss.version] = [];
    //     let newDbVersionCorpsOBJ = Object.assign({}, newDbVersionCorpss);

    //     this.afs.doc('dbVersioning/corps').set(newDbVersionCorpsOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache corporazioni eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/corps', newDbVersionCorpsOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache corporazioni eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.volti:
    //     //#region - volti case
    //     const newDbVersionVolti: DBVersioning = new DBVersioning();
    //     newDbVersionVolti.type = DBVersionType.volti;
    //     newDbVersionVolti.version = fromRoot.getState(this.store).datas.voltiV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionVolti.vChanges)) {
    //       newDbVersionVolti.vChanges = {};
    //     }
    //     newDbVersionVolti.vChanges[newDbVersionVolti.version] = [];
    //     let newDbVersionVoltiOBJ = Object.assign({}, newDbVersionVolti);

    //     this.afs.doc('dbVersioning/volti').set(newDbVersionVoltiOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Volti eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/volti', newDbVersionVoltiOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Volti eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.rules:
    //     //#region - rules case
    //     const newDbVersionRules: DBVersioning = new DBVersioning();
    //     newDbVersionRules.type = DBVersionType.rules;
    //     newDbVersionRules.version = fromRoot.getState(this.store).datas.rulesV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionRules.vChanges)) {
    //       newDbVersionRules.vChanges = {};
    //     }
    //     newDbVersionRules.vChanges[newDbVersionRules.version] = [];
    //     let newDbVersionRulesOBJ = Object.assign({}, newDbVersionRules);

    //     this.afs.doc('dbVersioning/rules').set(newDbVersionRulesOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Regolamento eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/rules', newDbVersionRulesOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Regolamento eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.locations:
    //     //#region - locations case
    //     const newDbVersionLocations: DBVersioning = new DBVersioning();
    //     newDbVersionLocations.type = DBVersionType.locations;
    //     newDbVersionLocations.version = fromRoot.getState(this.store).datas.locationsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionLocations.vChanges)) {
    //       newDbVersionLocations.vChanges = {};
    //     }
    //     newDbVersionLocations.vChanges[newDbVersionLocations.version] = [];
    //     let newDbVersionLocationsOBJ = Object.assign({}, newDbVersionLocations);

    //     this.afs.doc('dbVersioning/locations').set(newDbVersionLocationsOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Location eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/locations', newDbVersionLocationsOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Location eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.standardChats:
    //     //#region - standard case
    //     const newDbVersionStandardChats: DBVersioning = new DBVersioning();
    //     newDbVersionStandardChats.type = DBVersionType.standardChats;
    //     newDbVersionStandardChats.version = fromRoot.getState(this.store).layout.standardChatsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionStandardChats.vChanges)) {
    //       newDbVersionStandardChats.vChanges = {};
    //     }
    //     newDbVersionStandardChats.vChanges[newDbVersionStandardChats.version] = [];
    //     let newDbVersionStandardChatsOBJ = Object.assign({}, newDbVersionStandardChats);

    //     this.afs.doc('dbVersioning/standardChats').set(newDbVersionStandardChatsOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Standard Chat eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/standardChats', newDbVersionStandardChatsOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Standard Chat eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;

    //   case DBVersionType.specialChats:
    //     //#region - special case
    //     const newDbVersionSpecialChats: DBVersioning = new DBVersioning();
    //     newDbVersionSpecialChats.type = DBVersionType.specialChats;
    //     newDbVersionSpecialChats.version = fromRoot.getState(this.store).layout.specialChatsV + 1;
    //     if (Functions.IsNullOrUndefined(newDbVersionSpecialChats.vChanges)) {
    //       newDbVersionSpecialChats.vChanges = {};
    //     }
    //     newDbVersionSpecialChats.vChanges[newDbVersionSpecialChats.version] = [];
    //     let newDbVersionSpecialChatsOBJ = Object.assign({}, newDbVersionSpecialChats);

    //     this.afs.doc('dbVersioning/specialChats').set(newDbVersionSpecialChatsOBJ)
    //       .then(() => {
    //         //OK!
    //         self.toastr.success("Cache Special Chat eliminata con successo");
    //       })
    //       .catch((error) => {
    //         //TODO: errore
    //       });

    //     // this.firestore.write('dbVersioning/specialChats', newDbVersionSpecialChatsOBJ).subscribe(() => {
    //     //   //OK!
    //     //   self.toastr.success("Cache Special Chat eliminata con successo");
    //     // }, (error) => {
    //     //   //TODO: errore
    //     // });
    //     //#endregion
    //     break;
    // }
  }




  private getPendingsFromType(type: DBVersionType): string[] {
    switch (type) {
      case DBVersionType.items:
        return this.pendingItemsChanges;
      case DBVersionType.skills:
        return this.pendingSkillsChanges;
      case DBVersionType.bm:
        return this.pendingBMChanges;
      case DBVersionType.clans:
        return this.pendingClansChanges;
      case DBVersionType.corps:
        return this.pendingCorpsChanges;
      case DBVersionType.volti:
        return this.pendingVoltiChanges;
      case DBVersionType.rules:
        return this.pendingRulesChanges;
      case DBVersionType.locations:
        return this.pendingLocationsChanges;
      case DBVersionType.standardChats:
        return this.pendingStandardChatsChanges;
      case DBVersionType.specialChats:
        return this.pendingSpecialChatsChanges;
      default:
        return [];
    }
  }

  // public deleteSkillReferenceFromAllPlayer(skillUid: string) {
  //   if (Functions.IsNullOrUndefined(this.usersSubscription) == false)
  //     this.usersSubscription.unsubscribe();

  //   const self: this = this;
  //   this.usersSubscription = this.firestore.query('users').on().skip(1).subscribe((data) => {
  //     if (self.isAuditing)
  //       console.log('READ all users delete skills cpanel (n° ' + (data as any[]).length + ')');

  //     const allCharacters: CharacterSheetData[] = data;

  //     for (let index: number = 0; index < allCharacters.length; index++) {
  //       const aCharacter: CharacterSheetData = allCharacters[index];

  //       if (Functions.IsNullOrUndefined(aCharacter.userSkills) == false) {
  //         const skillfounded: CharacterSkill = aCharacter.userSkills.find((aSkill: CharacterSkill) => aSkill.uid == skillUid);
  //         if (Functions.IsNullOrUndefined(skillfounded) == false) {
  //           // l'utente ha la skill e va eliminata
  //           let filteredSkills: CharacterSkill[] = aCharacter.userSkills.filter((aSkill: CharacterSkill) => aSkill.uid != skillUid) || [];
  //           aCharacter.userSkills = filteredSkills;

  //           // now i have to save the updated user sheet
  //           let updatedUserJsonData = JSON.parse(JSON.stringify(aCharacter));
  //           this.firestore.update('users/' + aCharacter.uid, updatedUserJsonData).subscribe(() => {
  //             //ok!
  //           }, (error) => {
  //             //TODO: error
  //           });
  //         }
  //       }
  //     }
  //     if (Functions.IsNullOrUndefined(this.usersSubscription) == false)
  //       this.usersSubscription.unsubscribe();
  //   });
  // }

  // public addDBVersionNoDeltaChanges(updateArea: DBVersionType) {
  //   switch (updateArea) {
  //     // case DBVersionType.loc:

  //     //   break;
  //   }
  //   this.store.dispatch(new datas.SetPendingCpUpdates(true));
  // }

}
