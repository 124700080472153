import { Component, Renderer2 } from '@angular/core';
import { CPanelState } from '../../../utilities/utilities.constants';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as layoutSelector from './../../../../selectors/layout.selectors';
import * as datasSelector from './../../../../selectors/datas.selectors';
import { CPanelService } from '../../services/cpanel.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'cPanel-home',
  templateUrl: 'cPanel-home.component.html',
  styleUrls: ['cPanel-home.component.less'],
})
export class CPanelHomeComponent {

  public cPanelState$: Observable<CPanelState>;
  public cPanelPendingUpdates$: Observable<boolean>;
  public nomePg: string = "";

  private globalListeners: () => void;

  constructor(private renderer: Renderer2, private store: Store<fromRoot.State>, public cPanelService: CPanelService, private toastr: ToastrService) {
    this.renderer.addClass(document.body, 'cPanel-theme');
    this.cPanelState$ = this.store.select(layoutSelector.getCPanelState);
    this.cPanelPendingUpdates$ = this.store.select(datasSelector.getPendingCPUpdates);

    this.nomePg = fromRoot.getState(this.store).character.myCharacterData.name;

    const self: this = this;
    // register a listener attached to window: when the main component is going to be unloaded we disconnect from the server
    this.globalListeners = this.renderer.listen('window', 'beforeunload', (evt: any) => {

      // self.toastr.error('Prima di uscire devi rilasciare le modifiche', 'Errore');
      // in order to show the pop up when back, close, next ecc is pressed
      if (fromRoot.getState(this.store).datas.pendingCPUpdates) {
        evt.returnValue = true;
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'cPanel-theme');
  }
}
