import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { RulesSezione, RulesSottoSezione, Rule } from "../../../models/data/application.data";


@Pipe({ name: 'rulesSortingPipe' })
export class RulesSortingPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(allRules: any) {
    if (Functions.IsNullOrUndefined(allRules) || allRules.length <= 0)
      return [];
    else {

      let sorted = allRules.sort(function (a: any, b: any) {
        if (a.sequence > b.sequence)
          return 1;

        if (a.sequence < b.sequence)
          return -1;

        return 0; //default return value (no sorting)
      });

      return sorted;
    }
  }
}