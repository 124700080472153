import { PmService } from './../../../services/pm.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as characterSelector from './../../../selectors/character.selectors';
import * as datasSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import * as chat from './../../../actions/chat';
import * as character from './../../../actions/character';
import { UserPresence, PresencePlayerUI, CharacterSheetData, PresencePlayerStatus, State, SecretChat, Clan, Corp, DBVersionType, SpecialChat, Race, Role, RaceIcons } from '../../../models/data/application.data';
import { Observable } from 'rxjs';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { LayoutState } from '../../../modules/utilities/utilities.constants';
import { FirestoreService } from '../../../services/firestore.service';
import { IncrementalService } from '../../../services/incremental.service';


// Utilities
@Component({
  selector: 'presenti-component',
  templateUrl: './presenti.component.html',
  styleUrls: ['./presenti.component.less']
})

export class PresentiComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "PresentiComponent";

  @ViewChild('presContainer', { static: false }) private presentiContainer: ElementRef;

  public usersPresence$: Observable<PresencePlayerUI[]>;
  public myUID$: Observable<string>;
  // public onlineUsersCount$: Observable<number>;

  public allClans$: Observable<Clan[]>;
  public allCorps$: Observable<Corp[]>;
  public raceIcons$: Observable<RaceIcons[]>;

  presentiContainerMinHeight: number = 0;

  public editStateTextToggle: boolean = false;
  public stateText: string = "";

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private firestoreService: FirestoreService,
    private pmService: PmService,
    private incrementalService: IncrementalService
  ) {
    this.usersPresence$ = this.store.select(characterSelector.getComputedUsersPresence);
    this.myUID$ = this.store.select(characterSelector.getMyUID);
    // this.onlineUsersCount$ = this.store.select(characterSelector.getOnlineUsersCount);

    this.allClans$ = this.store.select(datasSelector.getClansList);
    this.allCorps$ = this.store.select(datasSelector.getCorpsList);
    this.raceIcons$ = this.store.select(datasSelector.getRaceIcons);

    // this.incrementalService.getAdditionalInfoFromPresenti();
    this.incrementalService.getCompleteCollection(DBVersionType.standardChats, true);
    this.incrementalService.getCompleteCollection(DBVersionType.specialChats, true);

    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.clans, true);

    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.corps, true);
  }

  public ngOnInit() { }

  public ngAfterViewInit() {
    this.onResize();
  }

  backToHomeInterna() {
    this.store.dispatch(new layout.TogglePresentiAction(false));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;

    const floatingPaddingTop = this.presentiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 490)
      minHeight = 490;

    if (minHeight > 522)
      minHeight = 522;

    // let minHeight: number = (windowsHeight / 100) * 56;
    // // const floatingPaddingTop = this.presentiContainer.nativeElement.offsetTop;
    // // let minHeight = windowsHeight - floatingPaddingTop - 80;
    // if (minHeight <= 350)
    //   minHeight = 350;

    // if (minHeight > 522)
    //   minHeight = 522;

    this.presentiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

  public goToChat(area: any) {
    if (Functions.IsNullOrUndefined(area) || Functions.IsStringEmpty(area.uid))
      return;

    // i'm trying to go in a chat
    this.store.dispatch(new chat.StoreSelectedChat(""));

    //#region - avoid double chat
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    const allPresenceStatus: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const myPresenceStatus: UserPresence = allPresenceStatus.find((aUser: UserPresence) => Functions.IsNullOrUndefined(aUser.state) === false && aUser.state.playerUID == characterSheet.uid);

    // Safety Check
    if (Functions.IsNullOrUndefined(myPresenceStatus) || Functions.IsNullOrUndefined(myPresenceStatus.connections))
      return;

    // let's normalize area
    let areasAsArray = Object.keys(myPresenceStatus.connections).map(function (key) {
      return myPresenceStatus.connections[key];
    });
    const removeDouble: Set<string> = new Set(areasAsArray);
    areasAsArray = Array.from(removeDouble.values());

    if (areasAsArray.length > 1) {
      // more then one tab open in different areas
      alert("Sei già in una chat, non puoi entrare in due chat contemporaneamente. Se pensi vi sia un errore contatta lo staff.");
      return;
    }
    //#endregion

    //#region - check secret and special chat permissions
    const mySheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (area.isPrivate && mySheet.role >= 3 && Functions.IsStringEmpty(area.owner) == false && area.owner != mySheet.uid) {
      // secret chat
      const userPresence: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
      const chatOwner: UserPresence = userPresence.find((aUserPresence: UserPresence) => Functions.IsNullOrUndefined(aUserPresence.state) == false && aUserPresence.state.playerUID == area.owner);
      if (Functions.IsNullOrUndefined(chatOwner) == false && Functions.IsNullOrUndefined(chatOwner.state.myPrivateChat)) {
        const thePrivateChat: SecretChat = chatOwner.state.myPrivateChat;
        if (thePrivateChat.uid == area.uid) {
          const canIAccess: string = thePrivateChat.accessibleByUIDs.find((aUID: string) => aUID == mySheet.uid);

          // if is string empty i'm not allowed to access
          if (Functions.IsStringEmpty(canIAccess) == false)
            return;
        }
      }
    } else if (area.isPrivate && mySheet.role >= 3 && Functions.IsStringEmpty(area.owner) == true) {
      // special chat
      const specialChat: SpecialChat[] = fromRoot.getState(this.store).layout.specialChatsList;
      const currentSpecialChat: SpecialChat = specialChat.find((aSpecialChat: SpecialChat) => aSpecialChat.uid == area.uid);
      if (Functions.IsNullOrUndefined(currentSpecialChat) == false) {
        // excluded player
        if (Functions.IsNullOrUndefined(currentSpecialChat.excludeCharacters) == false && currentSpecialChat.excludeCharacters.length > 0) {
          const excludedPlayers: Set<string> = new Set(currentSpecialChat.excludeCharacters);
          if (excludedPlayers.has(mySheet.uid))
            return;
        }

        // included player
        if (Functions.IsNullOrUndefined(currentSpecialChat.includeCharacters) == false && currentSpecialChat.includeCharacters.length > 0) {
          const includededPlayers: Set<string> = new Set(currentSpecialChat.includeCharacters);
          if (includededPlayers.has(mySheet.uid)) {
            this.store.dispatch(new chat.StoreSelectedChat(area.uid));
            this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Chat));
            this.store.dispatch(new layout.TogglePresentiAction(false));
            return;
          }
        }

        // race
        if (Functions.IsNullOrUndefined(currentSpecialChat.allowedToRace) == false && currentSpecialChat.allowedToRace.length > 0) {
          const allowedRaceSet: Set<Race> = new Set(currentSpecialChat.allowedToRace);
          if (allowedRaceSet.has(mySheet.race) == false) {
            return;
          }
        }

        // clan
        if (Functions.IsNullOrUndefined(currentSpecialChat.allowedToClan) == false && currentSpecialChat.allowedToClan.length > 0) {
          const allowedClanSet: Set<string> = new Set(currentSpecialChat.allowedToClan);
          if (allowedClanSet.has(mySheet.clan) == false) {
            return;
          }
        }

        // corp
        if (Functions.IsNullOrUndefined(currentSpecialChat.allowedToCorp) == false && currentSpecialChat.allowedToCorp.length > 0) {
          const allowedCorpSet: Set<string> = new Set(currentSpecialChat.allowedToCorp);
          if (allowedCorpSet.has(mySheet.corp) == false) {
            return;
          }
        }

        // role
        if (Functions.IsNullOrUndefined(currentSpecialChat.allowedOffRole) == false && currentSpecialChat.allowedOffRole.length > 0) {
          const allowedRoleSet: Set<Role> = new Set(currentSpecialChat.allowedOffRole);
          if (allowedRoleSet.has(mySheet.role) == false) {
            return;
          }
        }

        // lvl major or equal of
        if (Functions.IsNullOrUndefined(currentSpecialChat.lvlMajorOrEqual) == false) {
          if (mySheet.lvl < currentSpecialChat.lvlMajorOrEqual) {
            return;
          }
        }

        // lvl minor or equal of
        if (Functions.IsNullOrUndefined(currentSpecialChat.lvlMinorOrEqual) == false) {
          if (mySheet.lvl > currentSpecialChat.lvlMinorOrEqual) {
            return;
          }
        }
      }

    }
    //#endregion

    this.store.dispatch(new chat.StoreSelectedChat(area.uid));
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Chat));
    this.store.dispatch(new layout.TogglePresentiAction(false));
  }

  public openSheet(uid: string) {
    this.store.dispatch(new character.SelectplayerID(uid));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: true, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: false }));
  }

  public updateStato(user: PresencePlayerUI) {
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);

    if (user.playerUID != characterSheet.uid)
      return;
    // const allPresenceStatus: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    // const myPresenceStatus: UserPresence = allPresenceStatus.find((aUser: UserPresence) => aUser.state.playerUID == characterSheet.uid);

    // const presenceStatus: PresencePlayerStatus = Object.assign({}, myPresenceStatus.state);

    if (Functions.IsNullOrUndefined(characterSheet.state)) {
      characterSheet.state = State.libero;
    }

    characterSheet.state++;
    if (characterSheet.state > 2) {
      characterSheet.state = State.libero;
    }

    this.firestoreService.updateCharacterSheet(characterSheet.uid, characterSheet, false);
    // this.presenceService.updateUserStatus(presenceStatus);
  }

  public editStateText(oldState?: string) {
    if (this.editStateTextToggle == false) {
      // entering in edit
      this.stateText = oldState;
    } else {
      // exiting from edit
      const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
      if (this.stateText != characterSheet.stateText) {
        characterSheet.stateText = this.stateText;
        this.firestoreService.updateCharacterSheet(characterSheet.uid, characterSheet, false);
      }

      this.stateText = "";
    }

    this.editStateTextToggle = !this.editStateTextToggle;
  }

  public keyDown(event: any) {
    // pressing enter
    if (event.keyCode == 13) {
      this.editStateText();
    }
  }

  public sendPM(user: PresencePlayerUI) {
    this.pmService.sendToUID = user.playerUID;
    this.store.dispatch(new layout.ToggleCreaMessaggioBoxAction(true));
  }

}
