import { CharacterSheetData, UserPresence } from './../models/data/application.data';
import { BaseAction } from './baseAction';

export const STORE_AUTH_DATA = "[Character] Store Auth Data";
export const STORE_USER_DATA = "[Character] Store User Data";
export const LOG_OUT_CLEAN = "[Character] Log Out Clean";
export const SET_SELECTED_PLAYER = "[Character] Set Selected Player";
export const SELECT_PLAYER_ID = "[Character] Select Player ID";

export const STORE_USERS_PRESENCE = "[Character] Store Users Presence";


export type Actions = StoreAuthData | StoreUserData | LogOutClean | SelectplayerID | SetSelectedPlayer | StoreUsersPresence;

export class StoreAuthData implements BaseAction {
  readonly type = STORE_AUTH_DATA;
  constructor(public payload: any) { }
}

export class StoreUserData implements BaseAction {
  readonly type = STORE_USER_DATA;
  constructor(public payload: { myCharacter: CharacterSheetData, isAuthenticated: boolean, uid: string }) { }
}

export class LogOutClean implements BaseAction {
  readonly type = LOG_OUT_CLEAN;
  constructor() { }
}

export class SelectplayerID implements BaseAction {
  readonly type = SELECT_PLAYER_ID;
  constructor(public payload: string) { }
}

export class SetSelectedPlayer implements BaseAction {
  readonly type = SET_SELECTED_PLAYER;
  constructor(public payload: CharacterSheetData) { }
}

export class StoreUsersPresence implements BaseAction {
  readonly type = STORE_USERS_PRESENCE;
  constructor(public payload: UserPresence[]) { }
}

