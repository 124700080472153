import { ChatService } from './../../services/chat.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../../../reducers';
import * as layoutSelector from './../../../../selectors/layout.selectors';
import { ChatMessage } from '../../models/chat-message.models';
import * as chatSelector from './../../../../selectors/chat.selectors';
import { Functions } from '../../../utilities/functions/utilities.functions';
import { FirestoreService } from '../../../../services/firestore.service';

@Component({
  selector: 'chatroom-component',
  templateUrl: './chatroom.component.html',
  styleUrls: ['./chatroom.component.css']
})
export class ChatroomComponent implements OnInit, OnDestroy {
  @ViewChild('scroller', { static: false }) private feedContainer: ElementRef;

  // private firstScroll: boolean = true;
  private getChatMessages$: Observable<ChatMessage[]>;
  private getChatMessagesSubscription: Subscription;

  public chatName: string = "";
  private selectedChatNAmeSubscription: Subscription;

  public isUserListOpen: boolean = true;
  public isActionBoxOpen$: Observable<boolean>;
  public isInstructionBoxOpen$: Observable<boolean>;
  public isSkillBoxOpen$: Observable<boolean>;
  public isItemBoxOpen$: Observable<boolean>;
  public isDiceBoxOpen$: Observable<boolean>;
  public isDiceResponseBoxOpen$: Observable<boolean>;
  public isDiceDmgBoxOpen$: Observable<boolean>;
  public isSchedaTatticaBoxOpen$: Observable<boolean>;
  public selectedChatName$: Observable<string>;
  public getisGestisciChatPrivataOpen$: Observable<boolean>;


  constructor(private store: Store<fromRoot.State>, public firestoreService: FirestoreService, public chatService: ChatService, private cd: ChangeDetectorRef) {
    this.isActionBoxOpen$ = this.store.select(layoutSelector.getisChatActionBoxOpen);
    this.isInstructionBoxOpen$ = this.store.select(layoutSelector.getisChatInstructionBoxOpen);
    this.isSkillBoxOpen$ = this.store.select(layoutSelector.getisChatSkillBoxOpen);
    this.isItemBoxOpen$ = this.store.select(layoutSelector.getisChatItemBoxOpen);
    this.isDiceBoxOpen$ = this.store.select(layoutSelector.getisChatDiceBoxOpen);
    this.isDiceResponseBoxOpen$ = this.store.select(layoutSelector.getisChatDiceResponseBoxOpen);
    this.isDiceDmgBoxOpen$ = this.store.select(layoutSelector.getIsChatDiceDmgBoxOpen);
    this.isSchedaTatticaBoxOpen$ = this.store.select(layoutSelector.getisChatSchedaTatticaOpen);
    this.selectedChatName$ = this.store.select(chatSelector.getSelectedChatName);
    this.getisGestisciChatPrivataOpen$ = this.store.select(layoutSelector.getisGestisciChatPrivataOpen);

    // firestoreService.getSKillsList();
    // firestoreService.getItemsList();
  }

  ngOnInit() {
    const self: this = this;
    this.getChatMessages$ = this.store.select(chatSelector.getChatMessages);

    this.getChatMessagesSubscription = this.getChatMessages$.subscribe((messages: any[]) => {
      if (Functions.IsNullOrUndefined(messages) == false && messages.length > 0) {
        setTimeout(() => {
          self.scrollToBottom();
        }, 100);
      }
    });

    this.selectedChatNAmeSubscription = this.selectedChatName$.subscribe((value: string) => {
      setTimeout(() => {
        self.chatName = value;
        self.cd.markForCheck();
      }, 0);
    });

    window.addEventListener('beforeunload', this.preventBackButton);
  }

  ngOnDestroy() {
    this.getChatMessagesSubscription.unsubscribe();
    this.selectedChatNAmeSubscription.unsubscribe();

    window.removeEventListener('beforeunload', this.preventBackButton);
  }

  scrollToBottom(): void {
    this.feedContainer.nativeElement.scrollTop = this.feedContainer.nativeElement.scrollHeight;
    // this.firstScroll = false;
  }

  toggleUserList() {
    this.isUserListOpen = !this.isUserListOpen;
    // this.chatService.checkMyStatusOnline();
  }

  actionUpdate(event: any) {

  }

  preventBackButton(e: any) {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "";
    return "";
  }

}
