import { Skill, Item, BonusMalus, UserEntry, RulesSezione, Volto, Clan, Corp, FirebaseCalendarEvent, MeteoDay, Citazione, LocationRoot, RaceIcons } from './../models/data/application.data';
import { BaseAction } from './baseAction';

export const STORE_SKILLS = "[Datas] Store Skills";
export const STORE_ITEMS = "[Datas] Store Items";
export const STORE_BMS = "[Datas] Store Bonus Malus";
export const SET_PENDING_CP_UPDATES = "[Datas] Set Pending CP Updates";
export const STORE_USERS_LIST = "[Datas] Store Users List";
export const STORE_RULES = "[Datas] Store Rules";
export const STORE_VOLTI = "[Datas] Store Volti";
export const STORE_CLANS = "[Datas] Store Clans";
export const STORE_CORPS = "[Datas] Store Corps";
export const STORE_STAFF_NEWS = "[Datas] Store Staff News";
export const STORE_EVENTS = "[Datas] Store Events";
export const STORE_METEO = "[Datas] Store Meteo";
export const STORE_METEO_NOTE = "[Datas] Store Meteo Note";
export const STORE_FIRST_ENTRACE_MSG = "[Datas] Store First Entrance Msg"
export const STORE_CITAZIONI = "[Datas] Store Citazioni";
export const STORE_LOCATIONS = "[Datas] Store Locations";
export const STORE_RACE_ICONS = "[Datas] Store Race Icons";

export type Actions = StoreSkills
  | StoreItems
  | SetPendingCpUpdates
  | StoreBMs
  | StoreUsersList
  | StoreRules
  | StoreVolti
  | StoreClans
  | StoreCorps
  | StoreStaffNews
  | StoreEvents
  | StoreMeteo
  | StoreMeteoNote
  | StoreCitazioni
  | StoreLocations
  | StoreFirstEntranceMsg
  | StoreRaceIcons;

export class StoreSkills implements BaseAction {
  readonly type = STORE_SKILLS;
  constructor(public payload: { skills: Skill[], ver: number, raceSkillFullFlag: number[] }) { }
}

export class StoreItems implements BaseAction {
  readonly type = STORE_ITEMS;
  constructor(public payload: { items: Item[], ver: number }) { }
}

export class StoreBMs implements BaseAction {
  readonly type = STORE_BMS;
  constructor(public payload: { bms: BonusMalus[], ver: number, raceBMFullFlag: number[] }) { }
}

export class SetPendingCpUpdates implements BaseAction {
  readonly type = SET_PENDING_CP_UPDATES;
  constructor(public payload: boolean) { }
}

export class StoreUsersList implements BaseAction {
  readonly type = STORE_USERS_LIST;
  constructor(public payload: UserEntry[]) { }
}

export class StoreRules implements BaseAction {
  readonly type = STORE_RULES;
  constructor(public payload: { rules: RulesSezione[], ver: number, fullFlag: boolean }) { }
}

export class StoreVolti implements BaseAction {
  readonly type = STORE_VOLTI;
  constructor(public payload: { volti: Volto[], ver: number, fullFlag: boolean }) { }
}

export class StoreClans implements BaseAction {
  readonly type = STORE_CLANS;
  constructor(public payload: { clans: Clan[], ver: number, fullFlag: boolean }) { }
}

export class StoreCorps implements BaseAction {
  readonly type = STORE_CORPS;
  constructor(public payload: { corps: Corp[], ver: number, fullFlag: boolean }) { }
}

export class StoreStaffNews implements BaseAction {
  readonly type = STORE_STAFF_NEWS;
  constructor(public payload: any) { }
}

export class StoreEvents implements BaseAction {
  readonly type = STORE_EVENTS;
  constructor(public payload: FirebaseCalendarEvent[]) { }
}

export class StoreMeteo implements BaseAction {
  readonly type = STORE_METEO;
  constructor(public payload: MeteoDay) { }
}

export class StoreMeteoNote implements BaseAction {
  readonly type = STORE_METEO_NOTE;
  constructor(public payload: string) { }
}

export class StoreFirstEntranceMsg implements BaseAction {
  readonly type = STORE_FIRST_ENTRACE_MSG;
  constructor(public payload: any) { }
}

export class StoreCitazioni implements BaseAction {
  readonly type = STORE_CITAZIONI;
  constructor(public payload: Citazione[]) { }
}

export class StoreLocations implements BaseAction {
  readonly type = STORE_LOCATIONS;
  constructor(public payload: { locations: LocationRoot, ver: number, fullFlag: boolean }) { }
}

export class StoreRaceIcons implements BaseAction {
  readonly type = STORE_RACE_ICONS;
  constructor(public payload: RaceIcons[]) { }
}
