import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as layout from './../../../../actions/layout';
import * as chatSelector from './../../../../selectors/chat.selectors';
import { FirestoreService } from '../../../../services/firestore.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'chat-insctruction-box',
  templateUrl: './chat-instruction-box.component.html',
  styleUrls: ['./chat-instruction-box.component.css']
})
export class ChatInstrutionBoxComponent implements OnInit, OnDestroy {

  public chatInstruction$: Observable<any>;

  constructor(private firestoreService: FirestoreService, private store: Store<fromRoot.State>) {
    this.firestoreService.getChatInstruction();
  }

  ngOnInit() {

    this.chatInstruction$ = this.store.select(chatSelector.getChatInstruction);

  }

  ngOnDestroy() {

  }

  // closeBox() {
  //   this.store.dispatch(new layout.ToggleChatInstructionsBoxAction());
  // }
}
