export class MoonPhase {

  private static getJulianDayNumber(year: number, month: number, day: number) {
    var a = Math.floor((14 - month) / 12),
      y = year + 4800 - a,
      m = month + (12 * a) - 3;

    return day + Math.floor(((153 * m) + 2) / 5) + (365 * y) + Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400) - 32045;
  }

  private static getFractionalPart(floatingPointNumber: number) {
    return (Math.abs(floatingPointNumber) - Math.floor(floatingPointNumber));
  }

  private static degreesToRadians(degrees: number) {
    return degrees * (Math.PI / 180);
  }

  // http://www.ben-daglish.net/moon.shtml

  private static getMoonPhase(year: number, month: number, day: number) {
    if (typeof year !== "number" || year < 0) {
      throw new TypeError("Year must be a number greater or equal to 0");
    }

    if (typeof month !== "number" || month < 1 || month > 12) {
      throw new TypeError("Month must be a number between 1 and 12");
    }

    if (typeof day !== "number" || day < 1 || day > 31) {
      throw new TypeError("Month must be a number between 1 and 31");
    }

    var thisJD = this.getJulianDayNumber(year, month, day),
      k0 = Math.floor((year - 1900) * 12.3685),
      t = (year - 1899.5) / 100,
      t2 = Math.pow(t, 2),
      t3 = Math.pow(t, 3),
      j0 = 2415020 + (29 * k0),
      f0 = (0.0001178 * t2) - (0.000000155 * t3) + 0.75933 + (0.53058868 * k0) - (0.000837 * t) + (0.000335 * t2),
      m0 = 360 * this.getFractionalPart(k0 * 0.08084821133) + 359.2242 - (0.0000333 * t2) - (0.00000347 * t3),
      m1 = 360 * this.getFractionalPart(k0 * 0.07171366128) + 306.0253 + (0.0107306 * t2) + (0.00001236 * t3),
      b1 = 360 * this.getFractionalPart(k0 * 0.08519585128) + 21.2964 - (0.0016528 * t2) - (0.00000239 * t3),
      phase = 0,
      jday = 0,
      oldJ,
      f,
      m5,
      m6,
      b6;

    while (jday < thisJD) {
      f = f0 + (1.530588 * phase);
      m5 = this.degreesToRadians(m0 + (phase * 29.10535608));
      m6 = this.degreesToRadians(m1 + (phase * 385.81691806));
      b6 = this.degreesToRadians(b1 + (phase * 390.67050646));
      f -= (0.4068 * Math.sin(m6)) + ((0.1734 - (0.000393 * t)) * Math.sin(m5));
      f += (0.0161 * Math.sin(2 * m6)) + (0.0104 * Math.sin(2 * b6));
      f -= (0.0074 * Math.sin(m5 - m6)) - (0.0051 * Math.sin(m5 + m6));
      f += (0.0021 * Math.sin(2 * m5)) + (0.0010 * Math.sin((2 * b6) - m6));
      f += 0.5 / 1440;
      oldJ = jday;
      jday = j0 + (28 * phase) + Math.floor(f);
      phase += 1;
    }

    return (thisJD - oldJ) % 30;
  }

  private static getNextFullMoon(moonAge) {
    let daysToGo = 16 - moonAge;
    while (daysToGo < 1) {
      daysToGo = daysToGo + 30;
    }

    return Math.ceil(daysToGo);
  }

  private static getNextNewMoon(moonAge) {
    let daysToGo = 1 - moonAge;
    while (daysToGo < 1) {
      daysToGo = daysToGo + 30;
    }

    return Math.ceil(daysToGo);
  }

  public static getCurrentMoonPhase() {

    const currentDate: Date = new Date();
    const currentMonth: number = currentDate.getMonth() + 1;
    const currentYear: number = currentDate.getFullYear();
    const currentDay: number = currentDate.getDate();

    var currentPhase = this.getMoonPhase(currentYear, currentMonth, currentDay);

    const nextFullDays: number = this.getNextFullMoon(currentPhase);
    const nextNewDays: number = this.getNextNewMoon(currentPhase);

    switch (currentPhase) {
      case 1:
        return {
          name: "Luna Nuova",
          icon: 'wi-moon-alt-full',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 2:
      case 3:
        return {
          name: "Luna Crescente",
          icon: 'wi-moon-alt-waning-gibbous-1',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 4:
      case 5:
        return {
          name: "Luna Crescente",
          icon: 'wi-moon-alt-waning-gibbous-3',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 6:
      case 7:
        return {
          name: "Luna Crescente",
          icon: 'wi-moon-alt-waning-gibbous-5',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 8:
        return {
          name: "Luna Crescente",
          icon: 'wi-moon-alt-waning-gibbous-6',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 9:
        return {
          name: "Primo Quarto",
          icon: 'wi-moon-alt-third-quarter',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 10:
      case 11:
        return {
          name: "Gibbosa Crescente",
          icon: 'wi-moon-alt-waning-crescent-2',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };
      case 12:
      case 13:
        return {
          name: "Gibbosa Crescente",
          icon: 'wi-moon-alt-waning-crescent-4',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };
      case 14:
      case 15:
        return {
          name: "Gibbosa Crescente",
          icon: 'wi-moon-alt-waning-crescent-6',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 16:
        return {
          name: "Luna Piena",
          icon: 'wi-moon-alt-new',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 17:
      case 18:
        return {
          name: "Gibbosa Calante",
          icon: 'wi-moon-alt-waxing-crescent-1',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 19:
      case 20:
        return {
          name: "Gibbosa Calante",
          icon: 'wi-moon-alt-waxing-crescent-4',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 21:
      case 22:
        return {
          name: "Gibbosa Calante",
          icon: 'wi-moon-alt-waxing-crescent-6',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 23:
        return {
          name: "Ultimo Quarto",
          icon: 'wi-moon-alt-first-quarter',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 24:
      case 25:
        return {
          name: "Luna Calante",
          icon: 'wi-moon-alt-waxing-gibbous-1',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 26:
      case 27:
        return {
          name: "Luna Calante",
          icon: 'wi-moon-alt-waxing-gibbous-3',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 28:
      case 29:
        return {
          name: "Luna Calante",
          icon: 'wi-moon-alt-waxing-gibbous-5',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };

      case 30:
      case 0:
        return {
          name: "Luna Calante",
          icon: 'wi-moon-alt-waxing-gibbous-6',
          nextFull: nextFullDays,
          nextNew: nextNewDays
        };
    }
  }

}
