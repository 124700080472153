import { Pipe, PipeTransform } from "@angular/core";
import { Functions } from "../functions/utilities.functions";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';

@Pipe({ name: 'speechPipe' })
export class SpeechPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(messageContent: string) {
    if (Functions.IsNullOrUndefined(messageContent) || messageContent == "")
      return "";
    else {
      if (messageContent.includes("<") && messageContent.includes(">")) {
        messageContent = messageContent.split("<").join(' <span class="speech"*?@?*?@&#171;');
        messageContent = messageContent.split(">").join('&#187;</span> ');
        messageContent = messageContent.split("*?@?*?@").join('>');
      }

      const myName: string = fromRoot.getState(this.store).character.myCharacterData.name;
      messageContent = Functions.replaceAll(messageContent, myName, '<span class="underlineText">' + myName + '</span>');

      return messageContent;

    }
  }
}



@Pipe({ name: 'locationPipe' })
export class LocationPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) { }

  public transform(messageContent: string) {
    if (Functions.IsNullOrUndefined(messageContent) || messageContent == "")
      return "";
    else {


      let locationText: string = "";
      if (messageContent.includes("[") && messageContent.includes("]")) {

        const locationAreaStart: number = messageContent.indexOf("[");
        const locationAreaEnd: number = messageContent.indexOf("]");
        const locationTextToReplace: string = messageContent.slice(locationAreaStart, locationAreaEnd + 1);
        locationText = messageContent.slice(locationAreaStart, locationAreaEnd + 1);

        locationText = locationText.replace("[", '<span class="speech"*?@?*?@[');
        locationText = locationText.replace("]", ']</span> ');
        locationText = locationText.replace("*?@?*?@", '>');
        messageContent = messageContent.replace(locationTextToReplace, "@@##@@");
      }

      if (messageContent.includes("<") && messageContent.includes(">")) {
        messageContent = messageContent.split("<").join(' <span class="speech"*?@?*?@&#171;');
        messageContent = messageContent.split(">").join('&#187;</span> ');
        messageContent = messageContent.split("*?@?*?@").join('>');
      }

      const myName: string = fromRoot.getState(this.store).character.myCharacterData.name;
      messageContent = Functions.replaceAll(messageContent, myName, '<span class="underlineText">' + myName + '</span>');

      if (Functions.IsStringEmpty(locationText) == false)
        messageContent = messageContent.replace("@@##@@", locationText);

      return messageContent;
    }
  }
}

