import { isSameMonth, isSameDay } from 'date-fns';
import { FirebaseCalendarEvent } from './../../../models/data/application.data';
import { FirestoreService } from './../../../services/firestore.service';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as datasSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { DAYS_OF_WEEK, CalendarEvent } from 'calendar-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Utilities
@Component({
  selector: 'events-calendar-component',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.less']
})

export class EventsCalendarComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "EventsCalendarComponent";

  public eventsContainerMinHeight: number = 0;
  events$: Observable<CalendarEvent[]>;

  view: string = 'month';
  viewDate: Date = new Date();
  activeDayIsOpen: boolean = false;

  locale: string = 'it';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  selectedDay: any;
  // refresh: Subject<any> = new Subject();
  // actions: CalendarEventAction[] = [
  //   {
  //     label: '<i class="calendar-event-action-icon material-icons icon-edit">edit</i>',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.handleEvent('Edited', event);
  //     }
  //   },
  //   {
  //     label: '<i class="calendar-event-action-icon material-icons icon-delete">delete</i>',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       // this.events = this.events.filter(iEvent => iEvent !== event);
  //       this.handleEvent('Deleted', event);
  //     }
  //   }
  // ];

  constructor(private store: Store<fromRoot.State>, private cdr: ChangeDetectorRef, private firestoreService: FirestoreService) {
    this.firestoreService.getEvents();
    this.events$ = this.store.select(datasSelector.getEvents).pipe(map((events: FirebaseCalendarEvent[]) => {
      if (Functions.IsNullOrUndefined(events) || events.length <= 0)
        return [];

      const formattedEvents: CalendarEvent[] = this.setUpCalendarEvents(events);
      // this.refresh.next();
      return formattedEvents;
    }));
  }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  backToHomeInterna() {
    this.store.dispatch(new layout.ToggleEventsCalendarAction(false));
  }

  public dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  private setUpCalendarEvents(calendarEvents: FirebaseCalendarEvent[]) {
    let events: CalendarEvent[] = [];
    for (let index: number = 0; index < calendarEvents.length; index++) {
      const currentCalendarEvent: FirebaseCalendarEvent = calendarEvents[index];
      let formattedDate: Date = new Date(currentCalendarEvent.date);
      let hour: string = formattedDate.getHours().toString();
      let minutes: string = formattedDate.getMinutes().toString();
      if (minutes.length == 1) {
        minutes = "0" + minutes
      }

      let aCalendarEvent = {
        title: hour + "." + minutes + " - " + currentCalendarEvent.title,
        color: {
          primary: currentCalendarEvent.color,
          secondary: '#ffffff'
        },
        start: formattedDate,
        draggable: false,
        uid: currentCalendarEvent.uid
      };
      events.push(aCalendarEvent);
    }
    return events;
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    let minHeight: number = (windowsHeight / 100) * 60;
    if (minHeight <= 350)
      minHeight = 350;

    if (minHeight > 450)
      minHeight = 450;

    this.eventsContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

  public dayClickedEvent(event: any) {
    this.selectedDay = event.day;
  }
}
