import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { Subscription, Observable } from 'rxjs';
import * as characterSelector from './../../../selectors/character.selectors';
import { UserPresence } from '../../../models/data/application.data';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';

// Utilities
@Component({
  selector: 'uffici-credits-component',
  templateUrl: './uffici-credits.component.html',
  styleUrls: ['./uffici-credits.component.less']
})

export class UfficiCreditsComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiCreditsComponent";

  private creditsSubscription: Subscription;
  credits: any = "";

  public allUsers$: Observable<any>;
  private allUsersPresence$: Observable<UserPresence[]>;
  private presenceSubscription: Subscription;

  public bannedPlayers: number = 0;
  public inactivePlayers: number = 0;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore
  ) {
    const self: this = this;
    this.allUsers$ = this.store.select(characterSelector.getUsersAnagrafica);
    this.allUsersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.presenceSubscription = this.allUsersPresence$.subscribe((usersPresence: UserPresence[]) => {
      // .pipe(map((usersPresence: UserPresence[]) => {

      self.bannedPlayers = usersPresence.filter((aUserPresence: UserPresence) => aUserPresence.state.isBanned == true).length;
      self.inactivePlayers = usersPresence.filter((aUserPresence: UserPresence) => aUserPresence.state.isActive == false).length;
      return usersPresence;
    });
  }

  public ngOnInit() {
    const self: this = this;
    const staffNewsDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('settings/credits');
    const staffNewsDocObservable: Observable<any> = staffNewsDoc.valueChanges();
    this.creditsSubscription = staffNewsDocObservable.pipe(first()).subscribe((data) => {
      if (Functions.IsNullOrUndefined(data) == false)
        self.credits = data.value;
    });


  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.creditsSubscription) == false)
      this.creditsSubscription.unsubscribe();

    if (Functions.IsNullOrUndefined(this.presenceSubscription) == false)
      this.presenceSubscription.unsubscribe();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiCreditsAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
