import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { UserPresence, SpecialChat, Race, Role, Clan, Corp } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import * as fromRoot from '../../../../reducers';
import { Store } from '@ngrx/store';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Component({
  selector: 'cPanel-gestione-special-chat',
  templateUrl: 'cPanel-gestione-special-chat.component.html',
  styleUrls: ['cPanel-gestione-special-chat.component.less'],
})
export class CPanelGestioneSpecialChatComponent implements OnInit, OnDestroy {

  //#region - oher variables
  public isModalVisible: boolean = false;
  public modalData: SpecialChat;
  public specialDataSource: LocalDataSource;
  // public specialChatsList$: Observable<SpecialChat[]>
  public settingsSpecial = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    actions: {
      add: false,
      edit: false,
      custom: [
        {
          name: 'edit',
          title: '<i class="material-icons icon-del access-pg">edit</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Chat'
      },
      allowedToRace: {
        title: 'Razze ammesse',
        valuePrepareFunction: (value: Race[]) => {
          let valueToReturn: string = "";
          for (const aRace of value) {
            valueToReturn = valueToReturn + Race[aRace] + ", ";
          }

          if (Functions.IsStringEmpty(valueToReturn) == true) {
            return "Tutte";
          }

          return valueToReturn;
        }
      },
      allowedToClan: {
        title: 'Clan ammessi',
        valuePrepareFunction: (value: string[]) => {
          let valueToReturn: string = "";
          for (const aClanID of value) {
            const aClanName: string = this.clanMap.get(aClanID);
            if (Functions.IsNullOrUndefined(aClanName) == false)
              valueToReturn = valueToReturn + aClanName + ", ";
          }

          if (Functions.IsStringEmpty(valueToReturn) == true) {
            return "Tutti";
          }

          return valueToReturn;
        }
      },
      allowedToCorp: {
        title: 'Corporazioni ammesse',
        valuePrepareFunction: (value: string[]) => {
          let valueToReturn: string = "";
          for (const aCorpID of value) {
            const aCorpName: string = this.corpMap.get(aCorpID);
            if (Functions.IsNullOrUndefined(aCorpName) == false)
              valueToReturn = valueToReturn + aCorpName + ", ";
          }

          if (Functions.IsStringEmpty(valueToReturn) == true) {
            return "Tutte";
          }

          return valueToReturn;
        }
      },
      allowedOffRole: {
        title: 'Ruoli ammessi',
        valuePrepareFunction: (value: Role[]) => {
          let valueToReturn: string = "";
          for (const aRole of value) {
            valueToReturn = valueToReturn + Role[aRole] + ", ";
          }

          if (Functions.IsStringEmpty(valueToReturn) == true) {
            return "Tutti";
          }

          return valueToReturn;
        }
      },
      lvlMajorOrEqual: {
        title: 'Ammessi livelli maggiori o uguali a'
      },
      lvlMinorOrEqual: {
        title: 'Ammessi livelli minori o uguali a'
      },
      includeCharacters: {
        title: 'Personaggi ammessi (eccezioni)',
        valuePrepareFunction: (value: string[]) => {
          let valueToReturn: string = "";
          for (const aUserID of value) {
            const aUserName: string = this.userMap.get(aUserID);
            if (Functions.IsNullOrUndefined(aUserName) == false)
              valueToReturn = valueToReturn + aUserName + ", ";
          }

          if (Functions.IsStringEmpty(valueToReturn) == true) {
            return "Nessuna eccezione";
          }

          return valueToReturn;
        }
      },
      excludeCharacters: {
        title: 'Personaggi esclusi (eccezioni)',
        valuePrepareFunction: (value: string[]) => {
          let valueToReturn: string = "";
          for (const aUserID of value) {
            const aUserName: string = this.userMap.get(aUserID);
            if (Functions.IsNullOrUndefined(aUserName) == false)
              valueToReturn = valueToReturn + aUserName + ", ";
          }

          if (Functions.IsStringEmpty(valueToReturn) == true) {
            return "Nessuna eccezione";
          }

          return valueToReturn;
        }
      }
    }
  };
  //#endregion - oher variables


  //#region - get special chats
  private specialChatCollection: AngularFirestoreCollection<SpecialChat>;
  private specialChatCollectionObservable: Observable<SpecialChat[]>;
  private specialChatSubscription: Subscription;
  public specialChats: SpecialChat[];
  //#endregion - get special chats

  //#region - other datas
  public clanList: Clan[] = [];
  public clanMap: Map<string, string> = new Map(); //uid, name
  public corpList: Corp[] = [];
  public corpMap: Map<string, string> = new Map(); //uid, name
  public usersPresenceList: UserPresence[] = [];
  public userList: any[] = [];
  public userMap: Map<string, string> = new Map(); //uid, name
  //#endregion - other datas

  constructor(
    private store: Store<fromRoot.State>,
    private cPanelService: CPanelService,
    private afs: AngularFirestore
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.specialDataSource = new LocalDataSource();

    // clan
    this.clanList = fromRoot.getState(this.store).datas.clansList;
    for (const aClan of this.clanList) {
      this.clanMap.set(aClan.uid, aClan.name);
    }

    // corp
    this.corpList = fromRoot.getState(this.store).datas.corpsList;
    for (const aCorp of this.corpList) {
      this.corpMap.set(aCorp.uid, aCorp.name);
    }

    // users
    this.usersPresenceList = fromRoot.getState(this.store).character.usersPresence;
    for (const aUserPresence of this.usersPresenceList) {
      this.userList.push(aUserPresence.state);
      this.userMap.set(aUserPresence.state.playerUID, aUserPresence.state.name);
    }

    // special chat

    // this.specialChatsList$ = this.store.select(layoutSelector.getSpecialChatsList);
    this.specialChatCollection = this.afs.collection<SpecialChat>('specialChat');
    this.specialChatCollectionObservable = this.specialChatCollection.valueChanges();
    this.specialChatSubscription = this.specialChatCollectionObservable.subscribe((data) => {
      // this.specialChatSubscription = this.specialChatsList$.subscribe((data) => {
      if (Functions.IsNullOrUndefined(data) == false) {
        self.specialChats = data;
        self.specialDataSource.load(data);
      }
    });

  }

  public ngOnDestroy() {
    this.specialChatSubscription.unsubscribe();
  }

  onSpecialDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.cPanelService.removeDB('specialChat', event.data.uid);
      // this.firestore.remove('standardChat/' + event.data.uid);
    } else {
      event.confirm.reject();
    }
  }

  /**
 * Event of custom edit
 * @param event table event
 */
  public onCustom(event) {
    const chatID: string = event.data.uid;
    const chat = this.specialChats.find((aSpecialChat: SpecialChat) => aSpecialChat.uid == chatID);

    this.isModalVisible = true;
    this.modalData = Object.assign({}, chat);
  }

  public openModal() {
    this.isModalVisible = true;
    this.modalData = new SpecialChat();
  }

  public closeModal() {
    this.isModalVisible = false;
    this.modalData = new SpecialChat();
  }

  public okModal() {
    if (Functions.IsNullOrUndefined(this.modalData.name))
      return;

    const specialChatToSave: SpecialChat = Object.assign({}, this.modalData);

    // casting
    if (Functions.IsNullOrUndefined(specialChatToSave.lvlMajorOrEqual) == false) {
      specialChatToSave.lvlMajorOrEqual = parseInt(specialChatToSave.lvlMajorOrEqual.toString());
    }

    // casting
    if (Functions.IsNullOrUndefined(specialChatToSave.lvlMinorOrEqual) == false) {
      specialChatToSave.lvlMinorOrEqual = parseInt(specialChatToSave.lvlMinorOrEqual.toString());
    }

    // casting
    if (Functions.IsNullOrUndefined(specialChatToSave.allowedToRace) == false) {
      specialChatToSave.allowedToRace = specialChatToSave.allowedToRace
        .map((aValue: any) => { return parseInt(aValue.toString()) });
    }

    // casting
    if (Functions.IsNullOrUndefined(specialChatToSave.allowedOffRole) == false) {
      specialChatToSave.allowedToRace = specialChatToSave.allowedOffRole
        .map((aValue: any) => { return parseInt(aValue.toString()) });
    }


    if (Functions.IsStringEmpty(specialChatToSave.uid) == true) {
      // creating a new chat
      specialChatToSave.uid = Functions.CreateGuid();
      let newSpecialChatDataJSON = JSON.parse(JSON.stringify(specialChatToSave));
      this.cPanelService.writeOnDB("specialChat", newSpecialChatDataJSON.uid, newSpecialChatDataJSON);
    } else {
      // updating an existing chat
      let newSpecialChatDataJSON = JSON.parse(JSON.stringify(specialChatToSave));
      this.cPanelService.writeOnDB("specialChat", newSpecialChatDataJSON.uid, newSpecialChatDataJSON);
    }

    this.isModalVisible = false;
    this.modalData = new SpecialChat();
  }

}
