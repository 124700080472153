import { LogsService } from './../../../../services/logs.service';
import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { CharacterSheetData } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

export enum ModalType {
  add = 0,
  remove = 1
}

export class ModalData {
  character: CharacterSheetData;
  type: ModalType;
  value: number;
  title: string;
  why: string = "";
}


@Component({
  selector: 'cPanel-assegna-exp',
  templateUrl: 'cPanel-assegna-exp.component.html',
  styleUrls: ['cPanel-assegna-exp.component.less'],
})
export class CPanelAssegnaExpComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPAnelAssegnaExp";
  //#region - get users
  private usersCollection: AngularFirestoreCollection<CharacterSheetData>;
  private usersCollectionObservable: Observable<CharacterSheetData[]>;
  private usersSubscription: Subscription;
  //#endregion - get users

  //#region - other variables
  public dataSource: LocalDataSource;
  public settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'add',
          title: '<i class="material-icons icon-del access-pg">add</i> ',
        },
        {
          name: 'remove',
          title: '<i class="material-icons icon-edit block-pg">remove</i> ',
        }
      ],
    },
    columns: {
      name: {
        title: 'Nome Personaggio',
        editable: false
      },
      usablePx: {
        title: 'Punti Esperienza Utilizzabili',
        editable: false
      },
      px: {
        title: 'Punti Esperienza',
        editable: false
      },
    }
  };
  //#endregion - other variables

  //#region - modal variables
  public isModalVisible: boolean = false;
  public modalUserData: ModalData;
  //#endregion - modal variables

  constructor(
    private toastr: ToastrService,
    private store: Store<fromRoot.State>,
    private logsService: LogsService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    let self = this;
    this.dataSource = new LocalDataSource();

    //#region - get users
    this.usersCollection = this.afs.collection<CharacterSheetData>('users');
    this.usersCollectionObservable = this.usersCollection.valueChanges();
    this.usersSubscription = this.usersCollectionObservable.subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all users cpanel", self.moduleName, "users", (data as any[]).length);
      }
      // self.characters = data;
      self.dataSource.load(data);
    });
    //#endregion - get users
  }

  public ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.usersSubscription) == false) {
      this.usersSubscription.unsubscribe();
    }
  }

  public onCustom(event) {
    if (event.action == 'add') {
      let characterData: CharacterSheetData = event.data as CharacterSheetData;
      this.modalUserData = new ModalData();
      this.modalUserData.character = Object.assign({}, characterData);
      this.modalUserData.type = ModalType.add;
      this.modalUserData.value = 0;
      this.modalUserData.title = "Assegna Punti Esperienza";
      this.isModalVisible = true;

    } else if (event.action == 'remove') {
      let characterData: CharacterSheetData = event.data as CharacterSheetData;
      this.modalUserData = new ModalData();
      this.modalUserData.character = Object.assign({}, characterData);
      this.modalUserData.type = ModalType.remove;
      this.modalUserData.value = 0;
      this.modalUserData.title = "Rimuovi Punti Esperienza";
      this.isModalVisible = true;

    }
  }

  public closeModal() {
    this.isModalVisible = false;
    this.modalUserData = new ModalData();
  }

  public okModal() {
    if (Functions.IsNullOrUndefined(this.modalUserData) || Functions.IsNullOrUndefined(this.modalUserData.character))
      return;

    if (Functions.IsNullOrUndefined(this.modalUserData.type) || Functions.IsNullOrUndefined(this.modalUserData.value))
      return;

    if (this.modalUserData.type == ModalType.add) {
      this.isModalVisible = false;

      let character: CharacterSheetData = this.modalUserData.character;
      let oldPxValue: string = (character.px || 0).toString();
      let oldUPxValue: string = (character.usablePx || 0).toString();
      character.px = parseInt(oldPxValue) + this.modalUserData.value;
      character.usablePx = parseInt(oldUPxValue) + this.modalUserData.value;
      let characterDataJSON = JSON.parse(JSON.stringify(character));

      let self = this;
      const why: string = this.modalUserData.why;
      const value: number = (this.modalUserData.value || 0);

      //#region - update value
      const playerToEditDoc = this.afs.doc<any>('users/' + characterDataJSON.uid);
      playerToEditDoc.update(characterDataJSON)
        .then(() => {
          const myname: string = fromRoot.getState(this.store).character.myCharacterData.name;
          self.logsService.logXP(myname, character.name, oldUPxValue, value.toString(), "[CP] " + why);
          self.toastr.success('Modifica Salvata!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })
      //#endregion - update value

      // this.firestore.write('users/' + characterDataJSON.uid, characterDataJSON).subscribe(() => {
      //   const myname: string = fromRoot.getState(this.store).character.myCharacterData.name;
      //   self.logsService.logXP(myname, character.name, oldUPxValue, value.toString(), "[CP] " + why);
      //   self.toastr.success('Modifica Salvata!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });

    } else {
      this.isModalVisible = false;

      let character: CharacterSheetData = this.modalUserData.character;
      let oldPxValue: string = (character.px || 0).toString();
      let oldUPxValue: string = (character.usablePx || 0).toString();
      character.px = parseInt(oldPxValue) - this.modalUserData.value;
      character.usablePx = parseInt(oldUPxValue) - this.modalUserData.value;
      if (character.px < 0)
        character.px = 0;

      if (character.usablePx < 0)
        character.usablePx = 0;

      let self = this;
      const why: string = this.modalUserData.why;
      const value: number = (this.modalUserData.value || 0);
      let characterDataJSON = JSON.parse(JSON.stringify(character));

      //#region - update value
      const playerToEditDoc = this.afs.doc<any>('users/' + characterDataJSON.uid);
      playerToEditDoc.update(characterDataJSON)
        .then(() => {
          const myname: string = fromRoot.getState(this.store).character.myCharacterData.name;
          self.logsService.logXP(myname, character.name, oldUPxValue, "-" + value.toString(), "[CP] " + why);
          self.toastr.success('Modifica Salvata!');
        })
        .catch((error: any) => {
          self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
        })
      //#endregion - update value

      // this.firestore.write('users/' + characterDataJSON.uid, characterDataJSON).subscribe(() => {
      //   const myname: string = fromRoot.getState(this.store).character.myCharacterData.name;
      //   self.logsService.logXP(myname, character.name, oldUPxValue, "-" + value.toString(), "[CP] " + why);
      //   self.toastr.success('Modifica Salvata!');
      // }, (error) => {
      //   self.toastr.error('Ops, prova a riavviare la pagina e riprovare.', 'Errore');
      // });
    }

    this.modalUserData = new ModalData();
  }


}
