import { CharacterSheetData } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import { Subscription, Observable } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

// Utilities
@Component({
  selector: 'uffici-cambia-nome-component',
  templateUrl: './uffici-cambia-nome.component.html',
  styleUrls: ['./uffici-cambia-nome.component.less']
})

export class UfficiCambiaNomeComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiCambiaNomeComponent";

  nForm = new FormGroup({});
  subscription: Subscription;
  alreadyUsed: boolean = false;
  alreadyChanged: boolean = false;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private firestoreService: FirestoreService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {

  }

  public ngOnInit() {
    this.buildForm();

    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (Functions.IsNullOrUndefined(myCharacterSheet.nameAlreadyChanged) || myCharacterSheet.nameAlreadyChanged == false) {
      this.alreadyChanged = false;
    } else {
      this.alreadyChanged = true;
    }

  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleCambiaNomeAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

  // used to create form structure
  private buildForm() {
    this.nForm = this.fb.group({
      'newName': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)
      ]],
      'rMail': ['', [
        Validators.required,
        Validators.email,
        Validators.minLength(2)
      ]],
    });
  }

  public changeName() {
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (Functions.IsNullOrUndefined(myCharacterSheet.nameAlreadyChanged) || myCharacterSheet.nameAlreadyChanged == false) {

      // check if male is right
      const name: string = this.nForm.value['newName'];
      const mail: string = this.nForm.value['rMail'];
      if (mail != myCharacterSheet.mail || Functions.IsStringEmpty(mail) || Functions.IsStringEmpty(name))
        return;

      if (Functions.IsNullOrUndefined(this.subscription) == false)
        this.subscription.unsubscribe();

      const self: this = this;
      // check if name alread in use
      const nameDoc: AngularFirestoreDocument<any> = this.afs.doc<any>('listOfUserNames/' + name.toLowerCase());
      const nameDocObservable: Observable<any> = nameDoc.valueChanges();
      let nameSubscription: Subscription = nameDocObservable.pipe(first()).subscribe((data) => {
        // this.subscription = this.firestore.read('listOfUserNames/' + name.toLowerCase()).skip(1).pipe(first())
        //   .subscribe((data) => {
        if (self.debugLogger.isAuditing) {
          self.debugLogger.logRead(false, "READ a userName", self.moduleName, "listOfUserNames", 1);
        }

        if (Functions.IsNullOrUndefined(data)) {
          // name not already in use
          nameDoc.delete();
          // self.firestore.remove('listOfUserNames/' + (myCharacterSheet.name as string).toLowerCase());
          const userNameCollection = this.afs.collection<any>('listOfUserNames');
          userNameCollection.doc((name as string).toLowerCase()).set({});
          // self.firestore.write('listOfUserNames/' + (name as string).toLowerCase(), {});

          let updatedCharacterData: CharacterSheetData = Object.assign({}, myCharacterSheet);
          updatedCharacterData.name = name;
          updatedCharacterData.nameAlreadyChanged = true;
          self.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData, false);
          self.alreadyChanged = true;

        } else {
          // name in use
          self.alreadyUsed = true;
        }

      });
    }
  }

}
