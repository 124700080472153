import { UtilitiesModule } from './../utilities/utilities.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

// import { AppComponent } from './app.component';
import { ChatFormComponent } from './components/chat-form/chat-form.component';
import { ChatroomComponent } from './components/chatroom/chatroom.component';
import { ChatFeedComponent } from './components/chat-feed/chat-feed.component';
import { MessageComponent } from './components/message/message.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserItemComponent } from './components/user-item/user-item.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChatService } from './services/chat.service';

import { AngularFireModule } from '@angular/fire';

import { AngularFireDatabaseModule } from '@angular/fire/database';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { ChatActionBoxComponent } from './components/chat-action-box/chat-action-box.component';
import { ChatSkillBoxComponent } from './components/chat-skill-box/chat-skill-box.component';
import { ChatItemBoxComponent } from './components/chat-item-box/chat-item-box.component';
import { ChatDiceBoxComponent } from './components/chat-dice-box/chat-dice-box.component';
import { ChatSchedaTatticaComponent } from './components/chat-scheda-tattica/chat-scheda-tattica.component';

import {
  MatCheckboxModule,
} from "@angular/material";
import { ChatDiceResponseComponent } from './components/chat-dice-response/chat-dice-response.component';
import { ChatDiceDmgComponent } from './components/chat-dice-dmg/chat-dice-dmg.component';
import { ModalModule } from '../lib/modal';
import { GestisciChatPrivataBoxComponent } from './components/gestisci-chat-privata/gestisci-chat-privata.component';
import { ChatInstrutionBoxComponent } from './components/chat-instruction-box/chat-instruction-box.component';


const MaterialModule: any[] = [
  MatCheckboxModule
];

@NgModule({
  declarations: [
    // AppComponent,
    ChatFormComponent,
    ChatroomComponent,
    ChatFeedComponent,
    MessageComponent,
    UserListComponent,
    UserItemComponent,
    ChatActionBoxComponent,
    ChatSkillBoxComponent,
    ChatItemBoxComponent,
    ChatDiceBoxComponent,
    ChatSchedaTatticaComponent,
    ChatDiceResponseComponent,
    ChatDiceDmgComponent,
    GestisciChatPrivataBoxComponent,
    ChatInstrutionBoxComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    UtilitiesModule,
    NgSelectModule,
    MaterialModule,
    ModalModule
  ],
  exports: [
    ChatroomComponent,
    MessageComponent
  ],
  providers: [ChatService],
  // bootstrap: [AppComponent]
})
export class ChatRoomModule { }
