import { StatsBonusFormula, Stats, CharacterSheetData, CharacterSkill, Skill, Item, CharacterItems, BonusMalus, CharacterBM, Race, PrivateConversation, LocationArea, LocationChat, uiLocation, LocationInsideChat } from './../../../models/data/application.data';
import * as math from 'mathjs';
import { State } from './../../../reducers';
import { BHealth } from '../../chatRoom/models/chat-message.models';

// Utilities
export class Functions {

  // Get parameter value from query string
  public static GetParameterByName(name: string) {
    name = name.replace(/[\[]/, "\\[")
      .replace(/[\]]/, "\\]")
      .toLowerCase();
    const regex: RegExp = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results: RegExpExecArray = regex.exec(location.search.toLowerCase());
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  //Check if an obj is null, using the strict equals "===" comparer
  public static IsNull(obj: any): boolean {
    return obj === null;
  }

  //Check if an obj is undefined
  public static IsUndefined(obj: any): boolean {
    return (typeof obj) === 'undefined';
  }

  //Check if an obj is null or undefined
  public static IsNullOrUndefined(obj: any): boolean {
    return (Functions.IsNull(obj) || Functions.IsUndefined(obj));
  }

  public static IsStringEmpty(value: string) {
    if (Functions.IsNullOrUndefined(value))
      return true;
    else
      return value.length > 0 ? false : true;
  }

  //Format the inner exception to be shown in console
  public static FormatInnerException(exceptionMessage: string, exceptionStackTrace: string): string {
    let error: string = "";
    error = exceptionMessage + "\n";
    if (exceptionStackTrace != null)
      error += exceptionStackTrace;
    return error;
  }

  // calculate the elapsed time, from a specific start date to now
  public static GetElapsedTime(startDateTime: number) {
    const endTime: number = performance.now();
    return endTime - startDateTime;
  }

  //Converts float OLE Date to a date
  public static FromOADate(msDate: any) {
    const currentDate: Date = new Date(((msDate - 25569) * 86400000));
    const timeZone: number = currentDate.getTimezoneOffset();
    const adjustedDate: Date = new Date(((msDate - 25569 + (timeZone / (60 * 24))) * 86400000));
    return adjustedDate;
  }

  //Converts date to a float OLE Date
  public static ToOADate(jsDate: any) {
    jsDate = jsDate || new Date();
    const timezoneOffset: number = jsDate.getTimezoneOffset() / (60 * 24);
    const msDateObj: number = (jsDate.getTime() / 86400000) + (25569 - timezoneOffset);
    return msDateObj;
  }

  //Used for filter, intersect two set
  public static Intersect(a: any, b: any) {
    const result: any[] = new Array();
    for (let i: number = 0, alength: number = a.length; i < alength; i++) {
      for (let j: number = 0, blength: number = b.length; j < blength; j++) {
        if (a[i] === b[j]) {
          result.push(a[i]);
          break;
        }
      }
    }
    return result;
  }

  public static MultipleIntersection(arrays: string[][]): string[] {
    if (arrays.length == 0)
      return [];
    const result: string[] = arrays.shift()
      .filter(function (v: string) {
        return arrays.every(function (a: string[]) {
          return a.indexOf(v) !== -1;
        });
      });
    return result;
  }

  public static CreateGuid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16)
        .substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4()
      .substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
  }

  public static CreateRandomString() {
    let text: string = "";
    const possible: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (let i: number = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  public static FromStringToNumber(value: string) {
    if (this.IsStringEmpty(value))
      return undefined;
    else
      if (value == "yes" || value == "si" || value == "y" || value == "ye" || value == "s") {
        return 1;
      }

    if (value == "no" || value == "n") {
      return 0;
    }

    if (value == "-" || value == "+") {
      return 0;
    }

    return parseFloat(value.replace(',', '.'));
  }

  public static GetCurrentDate() {
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var today = new Date();

    return today.toLocaleDateString("it-IT", options);
  }

  public static GetFormattedDate(date: Date) {
    var options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString("it-IT", options);
  }

  public static GetDateHour(date: Date) {
    var options = { hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString("it-IT", options);
  }

  public static computeStatsBonus(statsBonusEquations: StatsBonusFormula, context: any): any {

    if (Functions.IsNullOrUndefined(statsBonusEquations)) {
      return { stats: new Stats(), statsAdd: new Stats(), durata: 0, health: new BHealth() };
    }

    let computedBonus: Stats = new Stats();
    let computedAddingBonus: Stats = new Stats();
    let computedHealthBonus: BHealth = new BHealth();
    let durata: number = 0;

    let scope = {
      lvl: context.lvl,
      str: context.str,
      agl: context.agl,
      res: context.res,
      per: context.per,
      wsd: context.wsd,
      wil: context.wil,
      sal: context.sal,
      salM: context.salM
    }

    // trasform , to .
    statsBonusEquations.str = statsBonusEquations.str.replace(",", ".");
    statsBonusEquations.agl = statsBonusEquations.agl.replace(",", ".");
    statsBonusEquations.res = statsBonusEquations.res.replace(",", ".");
    statsBonusEquations.wsd = statsBonusEquations.wsd.replace(",", ".");
    statsBonusEquations.per = statsBonusEquations.per.replace(",", ".");
    statsBonusEquations.wil = statsBonusEquations.wil.replace(",", ".");
    statsBonusEquations.strAdd = statsBonusEquations.strAdd.replace(",", ".");
    statsBonusEquations.aglAdd = statsBonusEquations.aglAdd.replace(",", ".");
    statsBonusEquations.resAdd = statsBonusEquations.resAdd.replace(",", ".");
    statsBonusEquations.wsdAdd = statsBonusEquations.wsdAdd.replace(",", ".");
    statsBonusEquations.perAdd = statsBonusEquations.perAdd.replace(",", ".");
    statsBonusEquations.wilAdd = statsBonusEquations.wilAdd.replace(",", ".");

    statsBonusEquations.sal = statsBonusEquations.sal.replace(",", ".");
    statsBonusEquations.salM = statsBonusEquations.salM.replace(",", ".");
    statsBonusEquations.salAdd = statsBonusEquations.salAdd.replace(",", ".");
    statsBonusEquations.salMAdd = statsBonusEquations.salMAdd.replace(",", ".");
    statsBonusEquations.durata = statsBonusEquations.durata.replace(",", ".");

    // variables can be read from the scope
    try {
      computedBonus.str = Math.floor(math.evaluate(statsBonusEquations.str || "", scope)) || 0;
      computedBonus.agl = Math.floor(math.evaluate(statsBonusEquations.agl || "", scope)) || 0;
      computedBonus.res = Math.floor(math.evaluate(statsBonusEquations.res || "", scope)) || 0;
      computedBonus.wsd = Math.floor(math.evaluate(statsBonusEquations.wsd || "", scope)) || 0;
      computedBonus.per = Math.floor(math.evaluate(statsBonusEquations.per || "", scope)) || 0;
      computedBonus.wil = Math.floor(math.evaluate(statsBonusEquations.wil || "", scope)) || 0;

      computedAddingBonus.str = Math.floor(math.evaluate(statsBonusEquations.strAdd || "", scope)) || 0;
      computedAddingBonus.agl = Math.floor(math.evaluate(statsBonusEquations.aglAdd || "", scope)) || 0;
      computedAddingBonus.res = Math.floor(math.evaluate(statsBonusEquations.resAdd || "", scope)) || 0;
      computedAddingBonus.wsd = Math.floor(math.evaluate(statsBonusEquations.wsdAdd || "", scope)) || 0;
      computedAddingBonus.per = Math.floor(math.evaluate(statsBonusEquations.perAdd || "", scope)) || 0;
      computedAddingBonus.wil = Math.floor(math.evaluate(statsBonusEquations.wilAdd || "", scope)) || 0;

      computedHealthBonus.sal = Math.floor(math.evaluate(statsBonusEquations.sal || "", scope)) || 0;
      computedHealthBonus.salM = Math.floor(math.evaluate(statsBonusEquations.salM || "", scope)) || 0;
      computedHealthBonus.salAdd = Math.floor(math.evaluate(statsBonusEquations.salAdd || "", scope)) || 0;
      computedHealthBonus.salMAdd = Math.floor(math.evaluate(statsBonusEquations.salMAdd || "", scope)) || 0;

      durata = Math.floor(math.evaluate(statsBonusEquations.durata, scope)) || 0;

    } catch (error) {
      return { stats: new Stats(), statsAdd: new Stats(), durata: 0, health: new BHealth() };
    }

    return { stats: computedBonus, statsAdd: computedAddingBonus, durata: durata, health: computedHealthBonus };
  }

  public static computeUserSkills(aCharacterSheet: CharacterSheetData, allSkills: Skill[]) {
    if (Functions.IsNullOrUndefined(allSkills) || allSkills.length <= 0 || Functions.IsNullOrUndefined(aCharacterSheet.userSkills) || aCharacterSheet.userSkills.length <= 0)
      return [];

    // character skills
    const characterSkills: CharacterSkill[] = aCharacterSheet.userSkills || [];

    // All skills
    const skillsMap: Map<string, Skill> = new Map();
    for (let index: number = 0; index < allSkills.length; index++) {
      skillsMap.set(allSkills[index].uid, allSkills[index]);
    }

    const skillsToBeDisplayed: any[] = [];
    for (let index: number = 0; index < characterSkills.length; index++) {
      const aSkill: Skill = skillsMap.get(characterSkills[index].uid);

      if (Functions.IsNullOrUndefined(aSkill) == false) {

        // skill grade
        let skillGrade: number = 1;
        let labelSkill: string = " Livello di Razza: ";
        if (aCharacterSheet.race != Race.Mondano) {
          // non mondano
          if (Functions.IsNullOrUndefined(aCharacterSheet.lvl) == false)
            skillGrade = parseInt(aCharacterSheet.lvl.toString());
        } else {
          //mondano
          const level: any = characterSkills[index].sLv || 1;
          skillGrade = parseInt(level.toString());
          labelSkill = " Livello: ";
        }

        let skillGradeDescription: string[] = [];
        switch (skillGrade) {
          case 5:
            skillGradeDescription.push("V" + labelSkill + aSkill.descrLv5);
          case 4:
            skillGradeDescription.push("IV" + labelSkill + aSkill.descrLv4);
          case 3:
            skillGradeDescription.push("III" + labelSkill + aSkill.descrLv3);
          case 2:
            skillGradeDescription.push("II" + labelSkill + aSkill.descrLv2);
          case 1:
            skillGradeDescription.push("I" + labelSkill + aSkill.descrLv1);
        }

        if (skillGradeDescription.length <= 0) {
          skillGradeDescription.push("I" + labelSkill + aSkill.descrLv1);
        }

        skillGradeDescription = skillGradeDescription.reverse();

        const aSkillToDisplay: any = { uid: aSkill.uid, name: aSkill.name, descr: aSkill.description, grdDescr: skillGradeDescription, nb: aSkill.note };
        skillsToBeDisplayed.push(aSkillToDisplay);
      }
    }

    return skillsToBeDisplayed;
  }

  public static computeUserItems(aCharacterSheet: CharacterSheetData, allItems: Item[]) {
    if (Functions.IsNullOrUndefined(allItems) || allItems.length <= 0 || Functions.IsNullOrUndefined(aCharacterSheet.userItems) || aCharacterSheet.userItems.length <= 0)
      return [];


    // character skills
    const characterItems: CharacterItems[] = aCharacterSheet.userItems || [];

    // All items
    const itemsMap: Map<string, Item> = new Map();
    for (let index: number = 0; index < allItems.length; index++) {
      itemsMap.set(allItems[index].uid, allItems[index]);
    }

    const itemsToBeDisplayed: any[] = [];
    for (let index: number = 0; index < characterItems.length; index++) {
      const anItem: Item = itemsMap.get(characterItems[index].uid);

      if (Functions.IsNullOrUndefined(anItem))
        continue;

      // Available uses
      const availableUses: number = characterItems[index].remainingUse;
      const maxUses: number = anItem.maxUse;
      const qta: number = characterItems[index].qta;

      const anItemToDisplay: any = { uid: anItem.uid, name: anItem.name, itemCat: anItem.itemCat, img: anItem.image, descr: anItem.description, note: anItem.note, avUse: availableUses, maxUse: maxUses, qta: qta };
      itemsToBeDisplayed.push(anItemToDisplay);
    }
    return itemsToBeDisplayed;
  }

  public static computeUserBMs(aCharacterSheet: CharacterSheetData, allBMs: BonusMalus[]) {
    if (Functions.IsNullOrUndefined(allBMs) || allBMs.length <= 0 || Functions.IsNullOrUndefined(aCharacterSheet.userBMs) || aCharacterSheet.userBMs.length < 0)
      return [];


    // character Bonus malus
    const characterBMs: CharacterBM[] = aCharacterSheet.userBMs || [];

    // All bonus/malus
    const bmsToBeDisplayed: any[] = [];
    const bmsMap: Map<string, BonusMalus> = new Map();


    for (let index: number = 0; index < allBMs.length; index++) {
      const currentBM: BonusMalus = allBMs[index];
      if (currentBM.race == aCharacterSheet.race) {
        if (currentBM.isNat) {
          bmsMap.set(currentBM.uid, currentBM);
        } else {
          const anBMToDisplay: any = { uid: currentBM.uid, name: currentBM.name, descr: currentBM.description, note: currentBM.note, isBonus: currentBM.isBonus };
          bmsToBeDisplayed.push(anBMToDisplay);
        }
      }
    }

    for (let index: number = 0; index < characterBMs.length; index++) {
      const aBM: BonusMalus = bmsMap.get(characterBMs[index].uid);

      if (Functions.IsNullOrUndefined(aBM))
        continue;

      const anBMToDisplay: any = { uid: aBM.uid, name: aBM.name, descr: aBM.description, note: aBM.note, isBonus: aBM.isBonus };
      bmsToBeDisplayed.push(anBMToDisplay);
    }
    return bmsToBeDisplayed;
  }

  public static replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'gi'), replace);
  }

  public static escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }

  public static sortPMs(pms: PrivateConversation[]): PrivateConversation[] {
    // sorting pm
    let toReturn: PrivateConversation[] = pms.sort(function (a: PrivateConversation, b: PrivateConversation) {
      const timePA: number = a.lMsgPTS;
      const timePB: number = b.lMsgPTS;

      if (Functions.IsNullOrUndefined(timePA) || Functions.IsNullOrUndefined(timePB)) {
        const timeA: any = a.lMsgTS;
        const timeB: any = b.lMsgTS;

        if (timeA.seconds > timeB.seconds)
          return -1

        if (timeA.seconds < timeB.seconds)
          return 1;

        if (timeA.nanoseconds > timeB.nanoseconds)
          return -1;

        if (timeA.nanoseconds < timeB.nanoseconds)
          return 1;

      } else {
        if (timePA > timePB)
        return -1

      if (timePA < timePA)
        return 1;
      }

      return 0; //default return value (no sorting)
    });

    return toReturn;
  }


  // public static calculateMoonPhase(day: number, month: number, year: number) {
  //   let c = 0;
  //   let e = 0;
  //   let jd = 0;
  //   let b = 0;

  //   if (month < 3) {
  //     year--;
  //     month += 12;
  //   }

  //   ++month;
  //   c = 365.25 * year;
  //   e = 30.6 * month;
  //   jd = c + e + day - 694039.09; // jd is total days elapsed
  //   jd /= 29.5305882; // divide by the moon cycle
  //   b = parseInt(jd.toString()); // int(jd) -> b, take integer part of jd
  //   jd -= b; // subtract integer part to leave fractional part of original jd
  //   b = Math.round(jd * 8); // scale fraction from 0-8 and round

  //   if (b >= 8) {
  //     b = 0; // 0 and 8 are the same so turn 8 into 0
  //   }

  //   const getMoonAge: number = this.getMoonAge(day, month, year);
  //   const nextFullDays: number = this.getNextFullMoon(getMoonAge);
  //   const nextNewDays: number = this.getNextNewMoon(getMoonAge);

  //   switch (b) {
  //     case 0:
  //       return {
  //         name: "Luna Nuova",
  //         icon: 'wi-moon-alt-full',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //       };
  //     case 1:
  //       return {
  //         name: "Luna Crescente",
  //         // icon: 'wi-moon-alt-waxing-crescent-4'
  //         icon: 'wi-moon-alt-waxing-gibbous-4',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //       };
  //     case 2:
  //       return {
  //         name: "Primo Quarto",
  //         icon: 'wi-moon-alt-first-quarter',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //       };
  //     case 3:
  //       return {
  //         name: "Gibbosa Crescente",
  //         icon: 'wi-moon-alt-waxing-crescent-4',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //         // icon: 'wi-moon-alt-waxing-gibbous-4'
  //       };
  //     case 4:
  //       return {
  //         name: "Luna Piena",
  //         icon: 'wi-moon-alt-new',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //       };
  //     case 5:
  //       return {
  //         name: "Gibbosa Calante",
  //         // icon: 'wi-moon-alt-waning-gibbous-4'
  //         icon: 'wi-moon-alt-waning-crescent-4',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //       };
  //     case 6:
  //       return {
  //         name: "Ultimo Quarto",
  //         icon: 'wi-moon-alt-third-quarter',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //       };
  //     case 7:
  //       return {
  //         name: "Luna Calante",
  //         // icon: 'wi-moon-alt-waning-crescent-4'
  //         icon: 'wi-moon-alt-waning-gibbous-4',
  //         nextFull: nextFullDays,
  //         nextNew: nextNewDays
  //       };
  //   }
  // }

  // public static getMoonAge(day: number, month: number, year: number) {
  //   const d = Math.floor(year / 20)
  //   let r = year - (d * 20) //r is the remainder of (year/20)
  //   while (r > 9) {
  //     r = r - 19;
  //   }
  //   r = r * 11;

  //   while (r > 29) {
  //     r = r - 30;
  //   }

  //   if (month < 3) {
  //     month = month + 2;
  //   }

  //   r = r + month + day;
  //   if (year < 100) {
  //     r = r - 4;
  //   } else {
  //     r = r - 8.3;
  //   }

  //   while (r > 29) {
  //     r = r - 30;
  //   }

  //   while (r < 0) {
  //     r = r + 30;
  //   }

  //   return r
  // }

  // public static getNextFullMoon(moonAge) {
  //   const currMilSecs = (new Date()).getTime();
  //   let daysToGo = 15 - moonAge;
  //   while (daysToGo < 2) {
  //     daysToGo = daysToGo + 29;
  //   }

  //   // const milSecsToGo = daysToGo * 24 * 60 * 60 * 1000;
  //   // const nextMoonTime = currMilSecs + milSecsToGo;
  //   // const nextMoonDate = new Date(nextMoonTime);

  //   // // To calculate the time difference of two dates
  //   // var Difference_In_Time = nextMoonDate.getTime() - (new Date()).getTime();

  //   // // To calculate the no. of days between two dates
  //   // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  //   return Math.ceil(daysToGo);
  // }

  // public static getNextNewMoon(moonAge) {
  //   const currMilSecs = (new Date()).getTime();
  //   let daysToGo = 29 - moonAge;
  //   while (daysToGo < 2) {
  //     daysToGo = daysToGo + 29;
  //   }

  //   // const milSecsToGo = daysToGo * 24 * 60 * 60 * 1000;
  //   // const nextMoonTime = currMilSecs + milSecsToGo;
  //   // const nextMoonDate = new Date(nextMoonTime);
  //   return Math.ceil(daysToGo);
  // }


  public static normalizeSelectedAreaLocations(selectedArea: LocationArea, subLocations: LocationChat[]) {
    const toReturn: uiLocation[] = [];
    toReturn.push(new uiLocation(selectedArea.name, 0, selectedArea.text));

    const sortedSubLocations: LocationChat[] = subLocations.sort((a: LocationChat, b: LocationChat) => {
      if (a.sequence < b.sequence)
        return -1;
      if (a.sequence > b.sequence)
        return 1;
      return 0;
    })

    for (let index: number = 0; index < sortedSubLocations.length; index++) {
      const aSubLocation: LocationChat = sortedSubLocations[index];
      const aValueToReturn: uiLocation = new uiLocation(aSubLocation.name, index + 1, aSubLocation.text);
      toReturn.push(aValueToReturn);
    }

    return toReturn;
  }

  public static normalizeSelectedChatLocations(selectedChat: LocationChat, chatInsideLocations: LocationInsideChat[]) {
    const toReturn: uiLocation[] = [];
    toReturn.push(new uiLocation(selectedChat.name, 0, selectedChat.text));

    const sortedChatInsideLocations: LocationInsideChat[] = chatInsideLocations.sort((a: LocationInsideChat, b: LocationInsideChat) => {
      if (a.sequence < b.sequence)
        return -1;
      if (a.sequence > b.sequence)
        return 1;
      return 0;
    })

    for (let index: number = 0; index < sortedChatInsideLocations.length; index++) {
      const aChatInsideLocation: LocationInsideChat = sortedChatInsideLocations[index];
      const aValueToReturn: uiLocation = new uiLocation(aChatInsideLocation.name, index + 1, aChatInsideLocation.text);
      toReturn.push(aValueToReturn);
    }

    return toReturn;
  }
}
