import { CharacterSheetData, UserPresence, DBVersionType } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as layout from './../../../actions/layout';
import * as chat from './../../../actions/chat';
import { Functions } from '../../../modules/utilities/functions/utilities.functions';
import * as characterSelector from './../../../selectors/character.selectors';
import { Observable } from 'rxjs';
import { LayoutState } from '../../../modules/utilities/utilities.constants';
import { IncrementalService } from '../../../services/incremental.service';

// Utilities
@Component({
  selector: 'uffici-chat-private-component',
  templateUrl: './uffici-chat-private.component.html',
  styleUrls: ['./uffici-chat-private.component.less']
})

export class UfficiChatPrivateComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiChatPrivateComponent";

  alreadyHaveAChat: boolean = false;
  selectedChat: any;
  availableNotStandardChats$: Observable<any[]>;

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private incrementalService: IncrementalService
  ) {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.specialChats, true);

    this.availableNotStandardChats$ = this.store.select(characterSelector.getAvailableNotStandardChats);
  }

  public ngOnInit() {

    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    if (Functions.IsNullOrUndefined(myCharacterSheet.myPrivateChat) || Functions.IsStringEmpty(myCharacterSheet.myPrivateChat.uid)) {
      this.alreadyHaveAChat = false;
    } else {
      this.alreadyHaveAChat = true;
    }


  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiSecretChatsAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

  public goToCreaChat() {
    this.store.dispatch(new layout.ToggleUfficiCreaSecretChatAction(true));
  }

  public goToPrivateChat() {
    if (Functions.IsNullOrUndefined(this.selectedChat))
      return;

    //#region - avoid double chat
    const characterSheet: CharacterSheetData = Object.assign({}, fromRoot.getState(this.store).character.myCharacterData);
    const allPresenceStatus: UserPresence[] = fromRoot.getState(this.store).character.usersPresence;
    const myPresenceStatus: UserPresence = allPresenceStatus.find((aUser: UserPresence) => Functions.IsNullOrUndefined(aUser.state) === false && aUser.state.playerUID == characterSheet.uid);

    // Safety Check
    if (Functions.IsNullOrUndefined(myPresenceStatus) || Functions.IsNullOrUndefined(myPresenceStatus.connections))
      return;

    // let's normalize area
    let areasAsArray = Object.keys(myPresenceStatus.connections).map(function (key) {
      return myPresenceStatus.connections[key];
    });
    const removeDouble: Set<string> = new Set(areasAsArray);
    areasAsArray = Array.from(removeDouble.values());

    if (areasAsArray.length > 1) {
      // more then one tab open in different areas
      alert("Sei già in una chat, non puoi entrare in due chat contemporaneamente. Se pensi vi sia un errore contatta lo staff.");
      return;
    }
    //#endregion

    const uid: string = this.selectedChat.uid;
    this.store.dispatch(new chat.StoreSelectedChat(uid));
    this.store.dispatch(new layout.UpdateLayoutStateAction(LayoutState.Chat));
    this.backToHomeInterna();
  }

}
