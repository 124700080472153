import { Component, OnDestroy, OnInit } from '@angular/core';




@Component({
  selector: 'main-component',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})

export class MainComponent implements OnInit, OnDestroy {
  private readonly moduleName: string = "MainComponent";

  // used to disable animations on IE and EDGE
  // private areAnimationsDisabled: boolean = false;
  // private globalListeners: () => void;

  constructor(
    // public browserService: BrowserService,
    // public renderer: Renderer2,
    // public store: Store<fromRoot.State>,
    // private authenticationService: AuthenticationService,
  ) {
    const self: this = this;

    // // register a listener attached to window: when the main component is going to be unloaded we disconnect from the server
    // this.globalListeners = this.renderer.listen('window', 'beforeunload', (evt: any) => {

    // });
  }

  public ngOnDestroy() {
    // Removes the registered global listeners
    // this.globalListeners();
  }

  public ngOnInit() {
  }

  // public logout() {
  //   this.authenticationService.logout();
  // }

  // public test() {
  //   Functions.GetCurrentDate();
  // }
}
