import { Corp, DBVersionType, UserPresence, PresencePlayerStatus } from './../../../models/data/application.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as characterSelector from './../../../selectors/character.selectors';
import * as dataSelector from './../../../selectors/datas.selectors';
import * as layout from './../../../actions/layout';
import * as character from './../../../actions/character';
import { IncrementalService } from 'src/app/services/incremental.service';
import { map } from 'rxjs/operators';

// Utilities
@Component({
  selector: 'uffici-corp-component',
  templateUrl: './uffici-corp.component.html',
  styleUrls: ['./uffici-corp.component.less']
})

export class UfficiCorpComponent implements OnInit, AfterViewInit {
  private readonly moduleName: string = "UfficiCorpComponent";

  ufficiContainerMinHeight: number = 0;
  @ViewChild('uffContainer', { static: false }) private ufficiContainer: ElementRef;

  public allUsers$: Observable<PresencePlayerStatus[]>;
  public selectedCorp: any;
  public availableCorps$: Observable<Corp[]>;

  constructor(
    private store: Store<fromRoot.State>,
    private cdr: ChangeDetectorRef,
    private incrementalService: IncrementalService
  ) {
    // ask for complete collection only if not yet in cache
    this.incrementalService.getCompleteCollection(DBVersionType.corps, true);

    this.allUsers$ = this.store.select(characterSelector.getUsersPresence).pipe(
      map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };
        return dataToReturn;
      }));

    this.availableCorps$ = this.store.select(dataSelector.getCorpsList)
      .pipe(
        map((availableCorps: Corp[]) => {
          return availableCorps.sort(function (a, b) {
            var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
            if (nameA < nameB) //sort string ascending
              return -1;
            if (nameA > nameB)
              return 1;
            return 0; //default return value (no sorting)
          });
        })
      );
  }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    this.onResize();
  }

  public backToHomeInterna() {
    this.store.dispatch(new layout.ToggleUfficiCorpAction(false));
  }

  public backToUffici() {
    this.store.dispatch(new layout.ToggleUfficiAction(true));
  }

  public goToScheda(uid: string) {
    this.store.dispatch(new character.SelectplayerID(uid));
    this.store.dispatch(new layout.ToggleCharacterSheetAction({ newState: true, doNotClosePresence: false, doNotCloseAnagrafica: false, doNotCloseVolti: false, doNotCloseStaff: false, doNotCloseRazze: false, doNotCloseClan: false, doNotCloseCorp: true }));
  }

  public onResize(event?: any) {
    const windowsHeight = window.innerHeight;
    const floatingPaddingTop = this.ufficiContainer.nativeElement.offsetTop;
    let minHeight = windowsHeight - floatingPaddingTop - 80;
    if (minHeight <= 516)
      minHeight = 516;

    if (minHeight > 522)
      minHeight = 522;

    this.ufficiContainerMinHeight = minHeight;
    this.cdr.detectChanges();
  }

}
