import { Subscription, Observable } from 'rxjs';
import { Functions } from './../../../utilities/functions/utilities.functions';
import { StandardChat, SecretChat, CharacterSheetData, UserPresence, PresencePlayerStatus } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import * as fromRoot from '../../../../reducers';
import * as characterSelector from './../../../../selectors/character.selectors';
import { Store } from '@ngrx/store';
import { FirestoreService } from '../../../../services/firestore.service';
import { CPanelService } from '../../services/cpanel.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { DebugLoggerService } from 'src/app/services/debug-logger.service';

@Component({
  selector: 'cPanel-gestione-chat',
  templateUrl: 'cPanel-gestione-chat.component.html',
  styleUrls: ['cPanel-gestione-chat.component.less'],
})
export class CPanelGestioneChatComponent implements OnInit, OnDestroy {
  private moduleName: string = "cPanelGestioneChat";

  tempSubscription: Subscription = new Subscription();
  usersPresence$: Observable<UserPresence[]>;
  usersSubscription: Subscription;
  charactersMap: Map<string, PresencePlayerStatus>; //uid, name
  mergedData: any[] = [];

  //#region - secret chat variables
  public privateDataSource: LocalDataSource;
  private privateChatSubscription: Subscription;
  public secretChats: SecretChat[] = [];
  public availableSecretChats$: Observable<SecretChat[]>;
  public settingsprivate = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Chat'
      },
      location: {
        title: 'Location'
      },
      pgName: {
        title: 'Proprietario',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: []
          }
        }
      }

    }
  };
  //#endregion - secret chat variables

  //#region - standard chat variables
  private standardChatCollection: AngularFirestoreCollection<StandardChat>;
  private standardChatCollectionObservable: Observable<StandardChat[]>;
  public standardDataSource: LocalDataSource;
  private standardChatSubscription: Subscription;
  public settingsStandard = {
    add: {
      addButtonContent: '<i class="material-icons icon-add">add</i>',
      createButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmCreate: true
    },
    edit: {
      editButtonContent: '<i class="material-icons icon-edit">edit</i>',
      saveButtonContent: '<i class="material-icons icon-check">check</i>',
      cancelButtonContent: '<i class="material-icons icon-close">close</i>',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: '<i class="material-icons icon-del">deletes</i>',
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nome Chat'
      },
      // type: {
      //   title: 'Tipo di Chat'
      // },
      description: {
        title: 'Descrizione',
        editor: {
          type: 'textarea'
        }
      },
      group: {
        title: 'Gruppo Chat',
        valuePrepareFunction: (value: string) => {
          const castedInt: number = parseInt(value);
          switch (castedInt) {
            case 0:
              return "The Valley";
            case 1:
              return "North L.A.";
            case 2:
              return "West Side";
            case 3:
              return "Hollywood";
            case 4:
              return "Downtown";
            case 5:
              return "Northeast";
            case 6:
              return "South L.A.";
            case 7:
              return "South Bay";
          }
        },
        editor: {
          type: 'list',
          config: {
            list: [
              { value: 0, title: "The Valley" },
              { value: 1, title: "North L.A." },
              { value: 2, title: "West Side" },
              { value: 3, title: "Hollywood" },
              { value: 4, title: "Downtown" },
              { value: 5, title: "Northeast" },
              { value: 6, title: "South L.A." },
              { value: 7, title: "South Bay" }
            ]
          }
        }
      }
    }
  };
  //#endregion - standard chat variables

  constructor(
    private store: Store<fromRoot.State>,
    private firestoreService: FirestoreService,
    private cPanelService: CPanelService,
    private afs: AngularFirestore,
    private debugLogger: DebugLoggerService
  ) {
  }

  public ngOnInit() {
    this.availableSecretChats$ = this.store.select(characterSelector.getAvailableSecretChats);

    let self = this;
    this.standardDataSource = new LocalDataSource();
    this.privateDataSource = new LocalDataSource();

    // standard chat
    this.standardChatCollection = this.afs.collection<StandardChat>('standardChat');
    this.standardChatCollectionObservable = this.standardChatCollection.valueChanges();
    this.standardChatSubscription = this.standardChatCollectionObservable.subscribe((data) => {
      // this.standardChatSubscription = this.firestore.query('standardChat').on().subscribe((data) => {
      if (self.debugLogger.isAuditing) {
        self.debugLogger.logRead(true, "READ all standard chats cpanel", self.moduleName, "standardChat", (data as any[]).length);
      }

      self.standardDataSource.load(data);
    });

    // private chat
    this.privateChatSubscription = this.availableSecretChats$.subscribe((data) => {
      self.secretChats = data;
      self.createMergedUserSecretChats();
      // self.privateDataSource.load(data);
    });


    this.usersPresence$ = this.store.select(characterSelector.getUsersPresence);
    this.usersSubscription = this.usersPresence$
      .pipe(map((data: UserPresence[]) => {
        const dataToReturn: PresencePlayerStatus[] = [];
        for (let index: number = 0; index < data.length; index++) {
          dataToReturn.push(data[index].state);
        };

        return dataToReturn;
      }),
        first()
      )
      .subscribe((data: PresencePlayerStatus[]) => {
        self.charactersMap = new Map();
        for (let index: number = 0; index < data.length; index++) {
          self.charactersMap.set(data[index].playerUID, data[index]);
        }

        self.createMergedUserSecretChats();
      });

  }

  public ngOnDestroy() {
    this.standardChatSubscription.unsubscribe();
    this.privateChatSubscription.unsubscribe();
  }

  onStandardDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      this.cPanelService.removeDB('standardChat', event.data.uid);
      // this.firestore.remove('standardChat/' + event.data.uid);
    } else {
      event.confirm.reject();
    }
  }

  onStandardCreateConfirm(event) {
    event.newData.uid = Functions.CreateGuid();
    event.confirm.resolve();

    //save on firebase
    let newStandardChatData: StandardChat = new StandardChat();
    newStandardChatData.uid = event.newData.uid;
    newStandardChatData.name = event.newData.name;
    newStandardChatData.description = event.newData.description;
    newStandardChatData.group = event.newData.group;

    let newStandardChatDataJSON = JSON.parse(JSON.stringify(newStandardChatData));

    this.cPanelService.writeOnDB("standardChat", newStandardChatDataJSON.uid, newStandardChatDataJSON);
    // this.firestore.write('standardChat/' + newStandardChatDataJSON.uid, newStandardChatDataJSON);
  }

  onStandardEditConfirm(event) {
    event.confirm.resolve();

    //update on firebase
    let newStandardChatData: StandardChat = new StandardChat();
    newStandardChatData.uid = event.newData.uid;
    newStandardChatData.name = event.newData.name;
    newStandardChatData.description = event.newData.description;
    newStandardChatData.group = event.newData.group;

    let newStandardChatDataJSON = JSON.parse(JSON.stringify(newStandardChatData));
    this.cPanelService.writeOnDB("standardChat", newStandardChatDataJSON.uid, newStandardChatDataJSON);
    // this.firestore.write('standardChat/' + newStandardChatDataJSON.uid, newStandardChatDataJSON);
  }

  onprivateDeleteConfirm(event): void {
    if (window.confirm('Sei sicuro di voler cancellare la riga selezionata?')) {
      event.confirm.resolve();
      // remove on firebase
      //TODO:
      //this.firestore.remove('privateChat/' + event.data.uid);
      // const self: this = this;

      this.firestoreService.updateSecretChatMembers(event.data.ownerUID, new SecretChat());

      // const playerToEditDoc = this.afs.doc<any>('users/' + event.data.ownerUID);
      // const playerToEditDocObservable = playerToEditDoc.valueChanges();
      // // this.playerToEditDocSubscription = this.playerToEditDocObservable.pipe(first())
      // //   .subscribe((selectedCharacter: CharacterSheetData) => {

      // this.tempSubscription.add(
      //   playerToEditDocObservable.pipe(first()).subscribe((selectedCharacter: CharacterSheetData) => {
      //     if (self.debugLogger.isAuditing) {
      //       self.debugLogger.logRead(true, "READ user cPanel", self.moduleName, "users", 1);
      //     }

      //     // const myCharacterSheet: CharacterSheetData = this.charactersMap.get(event.newData.ownerUID);
      //     let updatedCharacterData: CharacterSheetData = Object.assign({}, selectedCharacter);
      //     updatedCharacterData.myPrivateChat = JSON.parse(JSON.stringify(new SecretChat()));
      //     this.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData);
      //   })
      // );


      // const myCharacterSheet: CharacterSheetData = this.charactersMap.get(event.newData.ownerUID);
      // let updatedCharacterData: CharacterSheetData = Object.assign({}, myCharacterSheet);
      // updatedCharacterData.myPrivateChat = JSON.parse(JSON.stringify(new SecretChat()));
      // this.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData);
    } else {
      event.confirm.reject();
    }
  }

  onprivateCreateConfirm(event) {
    event.newData.uid = Functions.CreateGuid();
    event.confirm.resolve();

    //save on firebase
    let newSecretChatData: SecretChat = new SecretChat();
    newSecretChatData.uid = event.newData.uid;
    newSecretChatData.name = event.newData.name;
    newSecretChatData.location = event.newData.location;
    newSecretChatData.ownerUID = event.newData.pgName;
    newSecretChatData.accessibleByUIDs = [];
    this.firestoreService.updateSecretChatMembers(event.newData.pgName, newSecretChatData);

    // const self: this = this;
    // const playerToEditDoc = this.afs.doc<any>('users/' + event.newData.pgName);
    // const playerToEditDocObservable = playerToEditDoc.valueChanges();

    // this.tempSubscription.add(
    //   playerToEditDocObservable.pipe(first()).subscribe((selectedCharacter: CharacterSheetData) => {
    //     if (self.debugLogger.isAuditing) {
    //       self.debugLogger.logRead(true, "READ user cPanel", self.moduleName, "users", 1);
    //     }

    //     // const myCharacterSheet: CharacterSheetData = this.charactersMap.get(event.newData.pgName);
    //     let updatedCharacterData: CharacterSheetData = Object.assign({}, selectedCharacter);
    //     updatedCharacterData.myPrivateChat = JSON.parse(JSON.stringify(newSecretChatData));
    //     this.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData);
    //   })
    // );

    // const myCharacterSheet: CharacterSheetData = this.charactersMap.get(event.newData.pgName);
    // let updatedCharacterData: CharacterSheetData = Object.assign({}, myCharacterSheet);
    // updatedCharacterData.myPrivateChat = JSON.parse(JSON.stringify(newSecretChatData));
    // this.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData);
  }

  onprivateEditConfirm(event) {
    event.confirm.resolve();

    //update on firebase
    let newSecretChatData: SecretChat = new SecretChat();
    newSecretChatData.uid = event.newData.uid;
    newSecretChatData.name = event.newData.name;
    newSecretChatData.location = event.newData.location;
    newSecretChatData.ownerUID = event.newData.ownerUID;
    newSecretChatData.accessibleByUIDs = event.newData.accessibleByUIDs;

    this.firestoreService.updateSecretChatMembers(event.newData.ownerUID, newSecretChatData);

    // const self: this = this;
    // const playerToEditDoc = this.afs.doc<any>('users/' + event.newData.ownerUID);
    // const playerToEditDocObservable = playerToEditDoc.valueChanges();

    // this.tempSubscription.add(
    //   playerToEditDocObservable.pipe(first()).subscribe((selectedCharacter: CharacterSheetData) => {
    //     if (self.debugLogger.isAuditing) {
    //       self.debugLogger.logRead(true, "READ user cPanel", self.moduleName, "users", 1);
    //     }

    //     // const myCharacterSheet: CharacterSheetData = this.charactersMap.get(event.newData.ownerUID);
    //     let updatedCharacterData: CharacterSheetData = Object.assign({}, selectedCharacter);
    //     updatedCharacterData.myPrivateChat = JSON.parse(JSON.stringify(newSecretChatData));
    //     this.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData);
    //   })
    // );

    // const myCharacterSheet: CharacterSheetData = this.charactersMap.get(event.newData.ownerUID);
    // let updatedCharacterData: CharacterSheetData = Object.assign({}, myCharacterSheet);
    // updatedCharacterData.myPrivateChat = JSON.parse(JSON.stringify(newSecretChatData));
    // this.firestoreService.updateCharacterSheet(updatedCharacterData.uid, updatedCharacterData);
  }

  createMergedUserSecretChats() {

    if (Functions.IsNullOrUndefined(this.secretChats))
      return;

    if (Functions.IsNullOrUndefined(this.charactersMap) || this.charactersMap.size == 0)
      return;

    let secrtChatToBeDisplayed: any[] = [];
    for (let index: number = 0; index < this.secretChats.length; index++) {

      let pgName: string = "";
      let character: PresencePlayerStatus = this.charactersMap.get(this.secretChats[index].ownerUID);
      if (Functions.IsNullOrUndefined(character) == false) {
        pgName = character.name;
      }
      secrtChatToBeDisplayed.push(
        {
          uid: this.secretChats[index].uid,
          name: this.secretChats[index].name,
          ownerUID: this.secretChats[index].ownerUID,
          pgName: pgName,
          location: this.secretChats[index].location,
          accessibleByUIDs: this.secretChats[index].accessibleByUIDs
        }
      )
    }
    this.mergedData = secrtChatToBeDisplayed;
    this.privateDataSource.load(secrtChatToBeDisplayed);

    this.setUpBMNameEditorList();
  }

  private setUpBMNameEditorList() {

    let pgWithChatSet: Set<string> = new Set();

    for (let index: number = 0; index < this.mergedData.length; index++) {
      let uidPG: string = this.mergedData[index].ownerUID;
      if (Functions.IsNullOrUndefined(uidPG) == false && Functions.IsStringEmpty(uidPG) == false)
        pgWithChatSet.add(this.mergedData[index].ownerUID);
    }

    //available pg name list volti add
    let filteredNameList: any[] = [];
    this.charactersMap.forEach((character: PresencePlayerStatus, uid: string) => {
      if (pgWithChatSet.has(uid) == false) {
        filteredNameList.push(
          { value: uid, title: character.name }
        )
      }
    });

    // sorting alphabetically
    filteredNameList = filteredNameList.sort(function (a, b) {
      var nameA = a.title.toLowerCase(), nameB = b.title.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
      return 0; //default return value (no sorting)
    });

    this.settingsprivate.columns.pgName.editor.config.list = filteredNameList;
    this.settingsprivate = Object.assign({}, this.settingsprivate);
  }


}
