import { CharacterSheetData, Race } from './../../../../models/data/application.data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { Skill, CharacterSkill, Stats } from '../../../../models/data/application.data';
import { CharacterState, ChatSkill, CharacterActivatedSkill, BHealth } from '../../models/chat-message.models';
import { Functions } from '../../../utilities/functions/utilities.functions';
import * as layout from './../../../../actions/layout';
import * as chatSelector from './../../../../selectors/chat.selectors';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'chat-skill-box',
  templateUrl: './chat-skill-box.component.html',
  styleUrls: ['./chat-skill-box.component.css']
})
export class ChatSkillBoxComponent implements OnInit, OnDestroy {

  private chatCharactersState$: Observable<CharacterState[]>;
  private chatCharactersStateSubscription: Subscription;
  skills: ChatSkill[];
  selectedSkill: ChatSkill = undefined;

  private questActive$: Observable<boolean>;

  constructor(
    private chatService: ChatService,
    private store: Store<fromRoot.State>
  ) {
    this.chatCharactersState$ = this.store.select(chatSelector.getChatCharactersState);
    this.questActive$ = this.store.select(chatSelector.getIsQuestActive);
  }

  ngOnInit() {
    let self: this = this;
    this.chatCharactersStateSubscription = this.chatCharactersState$.subscribe((chatCharactersState: CharacterState[]) => {
      self.createMergedUserSkillsData();
    })
  }

  ngOnDestroy() {
    if (Functions.IsNullOrUndefined(this.chatCharactersStateSubscription) == false)
      this.chatCharactersStateSubscription.unsubscribe();
  }

  createMergedUserSkillsData() {
    // character skills
    const myCharacterSheet: CharacterSheetData = fromRoot.getState(this.store).character.myCharacterData;
    const characterSkills: CharacterSkill[] = myCharacterSheet.userSkills || [];

    // chat active skills
    const chatActiveSkills: CharacterState[] = fromRoot.getState(this.store).chat.chatCharactersState || [];

    let myactivatedSkillState: CharacterState = undefined;
    if (chatActiveSkills.length > 0) {
      myactivatedSkillState = chatActiveSkills.find((aCharacterSkills: any) => aCharacterSkills.uid == fromRoot.getState(this.store).character.myUID);
    }

    let activatedSkill: CharacterActivatedSkill[] = [];
    if (Functions.IsNullOrUndefined(myactivatedSkillState) == false) {
      activatedSkill = myactivatedSkillState.activatedSkills;
    }

    // All skills
    const allSkills: Skill[] = fromRoot.getState(this.store).datas.skills;
    const skillsMap: Map<string, Skill> = new Map();
    for (let index: number = 0; index < allSkills.length; index++) {
      skillsMap.set(allSkills[index].uid, allSkills[index]);
    }

    const skillsToBeDisplayed: ChatSkill[] = [];
    for (let index: number = 0; index < characterSkills.length; index++) {
      const aSkill: Skill = skillsMap.get(characterSkills[index].uid);

      if (Functions.IsNullOrUndefined(aSkill) == false) {
        // check if skill is active
        let skillIsActive: boolean = false;
        if (Functions.IsNullOrUndefined(activatedSkill) == false) {
          let skillFounded: CharacterActivatedSkill = activatedSkill
            .find((anActiveSkill: CharacterActivatedSkill) => anActiveSkill.uid == aSkill.uid);

          // skill founded so it is active
          if (Functions.IsNullOrUndefined(skillFounded) == false) {
            skillIsActive = true;
          }
        }

        // skill grade
        // const skillGrade: number = characterSkills[index].sLv || 1;


        const lvlToUse: number = (myCharacterSheet.race != Race.Mondano) ? myCharacterSheet.lvl : characterSkills[index].sLv;
        // compute skill stats bonus from formula
        const context: any = {
          lvl: lvlToUse || 1,
          str: myCharacterSheet.stats.str,
          agl: myCharacterSheet.stats.agl,
          res: myCharacterSheet.stats.res,
          per: myCharacterSheet.stats.per,
          wsd: myCharacterSheet.stats.wsd,
          wil: myCharacterSheet.stats.wil,
          sal: myCharacterSheet.health,
          salM: myCharacterSheet.mindHealth
        }
        const computed: any = Functions.computeStatsBonus(aSkill.statsBonus, context);
        const skillBonus: Stats = computed.stats;
        const skillAddBonus: Stats = computed.statsAdd;
        const healthBonus: BHealth = computed.health;
        let isSkillWithAdditingBuff: boolean = false;

        // check if the skill has an additing buff
        if (skillAddBonus.str != 0 || skillAddBonus.agl != 0 || skillAddBonus.per != 0 || skillAddBonus.res != 0 || skillAddBonus.wsd != 0 || skillAddBonus.wil != 0 || healthBonus.salAdd != 0 || healthBonus.salMAdd != 0) {
          isSkillWithAdditingBuff = true;
        }

        const aSkillToDisplay: ChatSkill = new ChatSkill(aSkill.uid, aSkill.name, aSkill.description, myCharacterSheet.lvl, skillBonus, skillAddBonus, healthBonus, isSkillWithAdditingBuff, skillIsActive, computed.durata);
        aSkillToDisplay.note = aSkill.note;

        //#region - skill grade
        let skillGrade: number = 5;
        if (Functions.IsNullOrUndefined(myCharacterSheet.lvl) == false)
          skillGrade = parseInt(myCharacterSheet.lvl.toString());

        let skillGradeDescription: string[] = [];
        switch (skillGrade) {
          case 5:
            skillGradeDescription.push("V Livello di Razza: " + aSkill.descrLv5);
          case 4:
            skillGradeDescription.push("IV Livello di Razza: " + aSkill.descrLv4);
          case 3:
            skillGradeDescription.push("III Livello di Razza: " + aSkill.descrLv3);
          case 2:
            skillGradeDescription.push("II Livello di Razza: " + aSkill.descrLv2);
          case 1:
            skillGradeDescription.push("I Livello di Razza: " + aSkill.descrLv1);
        }

        if (skillGradeDescription.length <= 0) {
          skillGradeDescription.push("I Livello di Razza: " + aSkill.descrLv1);
        }

        skillGradeDescription = skillGradeDescription.reverse();
        aSkillToDisplay.grdDescr = skillGradeDescription;
        //#endregion

        skillsToBeDisplayed.push(aSkillToDisplay);
      }
    }

    this.skills = skillsToBeDisplayed;
  }

  selectSkill(skill: ChatSkill) {
    if (Functions.IsNullOrUndefined(this.selectedSkill) == false && this.selectedSkill.uid == skill.uid) {
      this.selectedSkill = undefined;
    } else {
      this.selectedSkill = skill;
    }
  }

  activateSkill() {
    if (Functions.IsNullOrUndefined(this.selectedSkill) == true) {
      return;
    }

    // const currentSelectedSkill: ChatSkill = this.skills.find((aSkill: ChatSkill) => aSkill.uid == this.selectedSkill.uid);
    if (Functions.IsNullOrUndefined(this.selectedSkill) || this.selectedSkill.isActive) {
      return;
    }

    this.chatService.activateChatSkill(this.selectedSkill, 0);
    this.closeBox();
  }

  disableSkill() {
    if (Functions.IsNullOrUndefined(this.selectedSkill) == true) {
      return;
    }

    if (Functions.IsNullOrUndefined(this.selectedSkill) || this.selectedSkill.isActive == false) {
      return;
    }

    this.chatService.disableChatSkill(this.selectedSkill);
    this.closeBox();
  }

  closeBox() {
    this.store.dispatch(new layout.ToggleChatSkillBoxAction());
  }

}
